import React from  'react';
import { AccessDenied } from './svg/CustomGraphics';

export class IEExplorerBlocker extends React.Component<{}, {}> {

 

    public render() {
        return <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'           
        }}>
            <div className={['panel', 'panel-default', 'row'].join(' ')} style={{ backgroundColor:'#f4f6f9' }}>
                <div className="col-lg-5">
                    <AccessDenied width={200} height={200} />
                </div>
                <div className={['panel-body', ' col-lg-7'].join(' ')}>
                    <h2>Browser Not Supported!!</h2>
                    <br/>
                    <div>
                        <div >SafeSend Returns cannot be used with this browser. Please use Google Chrome, FireFox or Microsoft Edge</div>             
                    </div>
                </div>
            </div>
        </div>
    }
}