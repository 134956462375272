import React, { FC, useEffect } from 'react'
import { Store } from 'redux'
import { userExpired, userFound, userRenewed, userSignedOut } from '../store/auth/actions'
import { userManager } from './userService'

type Props = {
    store: Store
}

const AuthProvider: FC<Props> = ({ store, children }) => {
    useEffect(() => {
        const onUserLoaded = (user: any) => {
            store.dispatch(userFound(user))
        }

        const onAccessTokenExpiring = () => {
            userManager.signinSilent()
                .then((user) => {
                    store.dispatch(userRenewed(user))
                })
                .catch((error) => {
                    userManager.getUser().then((user) => store.dispatch(userRenewed(user)))
                })
        }

        const onAccessTokenExpired = () => {
            store.dispatch(userExpired())
        }

        const onUserSignedOut = () => {
            store.dispatch(userSignedOut())
        }

        // events for user
        userManager.events.addUserLoaded(onUserLoaded)
        userManager.events.addAccessTokenExpiring(onAccessTokenExpiring)
        userManager.events.addAccessTokenExpired(onAccessTokenExpired)
        userManager.events.addUserSignedOut(onUserSignedOut)

        // Specify how to clean up after this effect:
        return function cleanup() {
            userManager.events.removeUserLoaded(onUserLoaded)
            userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring)
            userManager.events.removeAccessTokenExpired(onAccessTokenExpired)
            userManager.events.removeUserSignedOut(onUserSignedOut)
        }
    }, [store])

    return <>{children}</>
}

export default AuthProvider