import React from 'react'
import { useLocation } from 'react-router';
import '../assets/custom/restrict-page.css';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';

interface LocationState {
    errorMessage : string
}

const RestrictedAccess: React.FC<WithLogoutProps> = ({
    onLogout
}) => {
    const location = useLocation<LocationState>();
    return (
        <div className='restrict-page-body'>
            <div className="restrict-access-text">
                <h1>
                    Restricted access
                </h1>
            </div>

            <div className="restrict-access-text">
                <h4>
                    {location.state.errorMessage ?? "This Client's IP address is not allowed to access the server."}
                </h4>
            </div>

            <div>
                <a
                    className="login-link"
                    onClick={onLogout}
                >
                    Click here to login.
                </a>
            </div>
        </div>
    )
}

export default WithLogout(RestrictedAccess);
