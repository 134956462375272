import React from  'react';
import * as  ReactDOM from 'react-dom';
import * as Bootstrap from 'react-bootstrap';
import * as IClientMessageStoreState from '../../../../store/settings/ClientMessageStore';

interface IClientMessageListProps {
    clientMessages: IClientMessageStoreState.IClientMessageDictionary;
    selectedMessage: number;
    defaultMessage: number;
    onListItemClick: (messageId: number) => void;
}


export class ClientMessageList extends React.Component<IClientMessageListProps, {}>{
    constructor(props: IClientMessageListProps) {
        super(props);
    }

    public render() {
        let messageId: number = this.props.selectedMessage == 0 ? parseInt(Object.keys(this.props.clientMessages)[0]) : this.props.selectedMessage
        return (
            <div>
                <ul className="list-group">
                    {
                        Object.keys(this.props.clientMessages).map((key, index) => (
                            <li
                                className={this.props.clientMessages[parseInt(key)].clientMessage.id == messageId ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                                title={this.props.clientMessages[parseInt(key)].clientMessage.name}
                                onClick={() => this.props.onListItemClick(parseInt(key))}
                            >
                                <div className="width100">
                                    <div style={{ display: 'inline-block' }}
                                        className={this.props.clientMessages[parseInt(key)].clientMessage.id == this.props.defaultMessage ? "col-md-10 nopadding overflowHidden" : "overflowHidden"}>
                                        {this.props.clientMessages[parseInt(key)].clientMessage.name}
                                    </div>
                                {
                                        this.props.clientMessages[parseInt(key)].clientMessage.id == this.props.defaultMessage ?
                                            <div className="col-md-2" style={{ float: 'right' }}>
                                                <span className={"badge"}
                                                    style={{ marginLeft: 10, float: 'initial', backgroundColor: '#777', color: 'white' }}>Default</span>
                                            </div>
                                            : <span></span>
                                    }
                                    </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}