import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';
import { handleResponse } from '../../Library';
import { ITaxReturn, DocumentStatus } from '../../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import * as Constants from '../../../components/helper/Constants';
import { IReportProblemDetails } from 'src/components/common/ReportProblem/ReportProblemModel';
import {
    MarkAsReadyForDeliveryAction,
    ReceiveGroupedReturnsDocumentsAction, ReceiveGroupedTaxDocumentAction, ReceiveSelectedDocumentForDeliveryAction,
    ReceiveSendGroupedReturnsAction, ReceiveSendGroupedReturnsPagesAction,
    RemoveSelectedDocumentForDeliveryAction, RequestGroupedReturnsDocumentsAction,
    RequestSendGroupedReturnsAction, RequestSendGroupedReturnsPagesAction,
    ResetSendGroupedReturnsAction,
    SendGroupedExtensionsStoreState,
    SendGroupedExtensionsTableModel,
    unloadedSendGroupedExtensionsStoreState,
    UpdateSendGroupedReturnsDocumentAssignUpdate,
    UpdateSendGroupedReturnsDocumentStatusUpdate
} from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

const NO_INDEX = -1;

type KnownAction = RequestSendGroupedReturnsAction
    | ReceiveSendGroupedReturnsAction
    | RequestSendGroupedReturnsPagesAction
    | ReceiveSendGroupedReturnsPagesAction
    | RequestGroupedReturnsDocumentsAction
    | ReceiveGroupedReturnsDocumentsAction
    | NotificationAction
    | ReceiveGroupedTaxDocumentAction
    | ReceiveSelectedDocumentForDeliveryAction
    | RemoveSelectedDocumentForDeliveryAction
    | ResetSendGroupedReturnsAction;

type DispatchAction = RequestSendGroupedReturnsAction
    | ReceiveSendGroupedReturnsAction
    | RequestGroupedReturnsDocumentsAction
    | ReceiveGroupedReturnsDocumentsAction
    | ReceiveGroupedTaxDocumentAction
    | ReceiveSelectedDocumentForDeliveryAction
    | RemoveSelectedDocumentForDeliveryAction
    | MarkAsReadyForDeliveryAction
    | ResetSendGroupedReturnsAction
    | UpdateSendGroupedReturnsDocumentStatusUpdate
    | UpdateSendGroupedReturnsDocumentAssignUpdate;

let sendGroupedExtensionsAbortController = new AbortController();

export const actionCreators = {
    requestSendGroupedReturns: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const state = getState();
        if (reload || query !== state.sendGroupedExtensionsStore.query) {

            const page = state.sendGroupedExtensionsPagesStore[query];
            if (!reload && page) {
                dispatch({
                    type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS,
                    query: query,
                    table: page.sendGroupedExtensionsTableModel
                });

                return;
            }

            sendGroupedExtensionsAbortController.abort();
            sendGroupedExtensionsAbortController = new AbortController();

            const fetchTask = fetch(`${API_BASE_URL}api/SendGroupedExtensions/GetSendGroupedExtensionsAsync` + query, {
                method: 'GET',
                credentials: 'include',
                signal: sendGroupedExtensionsAbortController.signal
            })
                .then(handleResponse)
                .then(response => response as Promise<SendGroupedExtensionsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS, query: query, table: data });
                    dispatch({ type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES, query: query, table: data, totalRowCount: data.count });
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error })
                    logger.trackError(`requestSendGroupedReturns failed for query: ${query}, with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_SEND_GROUPED_RETURNS, query: query });
            dispatch({ type: actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES, query: query });
        }
    },
    requestGroupedReturnDocuments: (groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const fetchTask = fetch(`${API_BASE_URL}api/SendGroupedExtensions/GetGroupedExtensionsDocumentsQueryAsync?groupId=` + groupId, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<ITaxReturn[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS, groupId: groupId, data: data });
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error })
                logger.trackError(`requestGroupedReturnDocuments failed for groupId: ${groupId}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS, groupId: groupId });
    },
    exportGroupedReturnsAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/ExportExcelSendGroupedExtensionsAsync` + query, { credentials: 'include' })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;

                const fileName = 'GroupedExtensions.xlsx';
                link.setAttribute('download', fileName);
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();

                const parentNode = link.parentNode;

                if (parentNode) {
                    parentNode.removeChild(link);
                }

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`exportGroupedReturnsAsExcel failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    ChangeStatusGroupedTaxDocument: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        const taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        const options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/UpdateChangeStatus`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, isEditClientInfoRequest: false });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error })
                logger.trackError(`ChangeStatusGroupedTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestSelectedDocumentsForDelivery: (groupId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/GetSelectedForDeliveryDocumentListAsync/` + groupId, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<number[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, documentIds: data });
            })
            .catch(function (error) {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: StatusType.Error })
                logger.trackError(`requestSelectedDocumentsForDelivery failed for groupId: ${groupId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    selectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, documentIds: [documentId] });
    },

    selectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY, documentIds: documentIds });
    },

    unselectForDelivery: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, documentIds: [documentId] });
    },

    unselectAllForDelivery: (documentIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY, documentIds: documentIds });
    },

    resetSendGroupedReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
    },

    reportDocumentGroupProblem: (problemDetails: IReportProblemDetails, groupId: number, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var url = `${API_BASE_URL}api/SupportGroupIssue/SaveTaxReturnReportProblem/` + groupId;
        const fetchTask = fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(problemDetails),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.SuccessMessage + response,
                    statusType: StatusType.Success
                });
                if (successCallback) {
                    successCallback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ReportProblemConstants.ErrorMessage,
                    statusType: StatusType.Error
                });
                logger.trackError(`reportDocumentGroupProblem failed for problemDetails: ${problemDetails}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<SendGroupedExtensionsStoreState> =
    (state: SendGroupedExtensionsStoreState = unloadedSendGroupedExtensionsStoreState, incomingAction: Action) => {
        const action = incomingAction as DispatchAction;
        let index = -1;
        switch (action.type) {
            case actionTypes.REQUEST_SEND_GROUPED_RETURNS:
                return ({
                    ...unloadedSendGroupedExtensionsStoreState,
                    query: action.query,
                    loading: true
                });
            case actionTypes.RECEIVE_SEND_GROUPED_RETURNS:
                return {
                    query: action.query,
                    sendGroupedExtensionsTableModel: action.table,
                    totalRowCount: action.table.count,
                    loading: false
                };
            case actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS:
                index = state.sendGroupedExtensionsTableModel.sendGroupedExtensions.findIndex(x => x.groupId === action.groupId);
                if (index != -1) {
                    state.sendGroupedExtensionsTableModel.sendGroupedExtensions[index].taxReturns = [];
                }
                return state;
            case actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS:
                index = state.sendGroupedExtensionsTableModel.sendGroupedExtensions.findIndex(x => x.groupId === action.groupId);
                if (index != -1) {
                    state.sendGroupedExtensionsTableModel.sendGroupedExtensions[index].taxReturns = action.data;
                }
                return state;
            case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
                const tableData: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {
                        if (taxReturn.id === action.taxDocument.id) {
                            newGroup.taxReturns.push({ ...action.taxDocument });
                        }
                        else {
                            newGroup.taxReturns.push({ ...taxReturn });
                        }
                    });
                    tableData.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableData,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };
            case actionTypes.MARK_AS_READY_FOR_DELIVERY:

                const tableData1: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {
                        if (taxReturn.id === action.id) {
                            taxReturn.documentStatus = DocumentStatus.READYFORDELIVERY;
                        }
                        newGroup.taxReturns.push({ ...taxReturn });
                    });
                    tableData1.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableData1,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };

            case actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY:

                const tableData2: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {
                        if (taxReturn.documentStatus !== DocumentStatus.RECALLED) {
                            action.documentIds.map((id, k) => {
                                if (taxReturn.id === id) {
                                    taxReturn.isSelectedForDelivery = true;
                                }
                            })
                        }
                        newGroup.taxReturns.push({ ...taxReturn });
                    });
                    tableData2.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableData2,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };

            case actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY:

                const tableData3: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {
                        action.documentIds.map((id, k) => {
                            if (taxReturn.id === id) {
                                taxReturn.isSelectedForDelivery = false;
                            }
                        })
                        newGroup.taxReturns.push({ ...taxReturn });
                    });
                    tableData3.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableData3,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };

            case actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS:
                const tableData4: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {

                        const docIndex = action.ids.findIndex(x => x == taxReturn.id);

                        if (docIndex != NO_INDEX) {
                            taxReturn.documentStatus = action.status;
                        }
                        newGroup.taxReturns.push({ ...taxReturn });
                    });
                    tableData4.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableData4,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };

            case actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN:
                const tableDataAssign: SendGroupedExtensionsTableModel = {
                    count: state.sendGroupedExtensionsTableModel.count,
                    sendGroupedExtensions: []
                };

                state.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group, i) => {
                    const newGroup = { ...group };
                    newGroup.taxReturns = [];
                    group.taxReturns.map((taxReturn, j) => {

                        const docIndex = action.ids.findIndex(x => x == taxReturn.id);

                        if (docIndex != NO_INDEX) {
                            taxReturn.assignedUser = action.assignedUser;
                            taxReturn.assignTo = action.assignTo;
                            taxReturn.documentStatus = action.status;
                        }
                        newGroup.taxReturns.push({ ...taxReturn });
                    });
                    tableDataAssign.sendGroupedExtensions.push({ ...newGroup });
                });
                return {
                    query: state.query,
                    sendGroupedExtensionsTableModel: tableDataAssign,
                    totalRowCount: state.totalRowCount,
                    loading: state.loading
                };

            case actionTypes.RESET_SEND_GROUPED_RETURNS:
                return clearGroupedTaxReturn(state);
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
        return state || unloadedSendGroupedExtensionsStoreState;
    }

function clearGroupedTaxReturn(state: SendGroupedExtensionsStoreState): SendGroupedExtensionsStoreState {
    return {
        ...unloadedSendGroupedExtensionsStoreState,
        query: state.query,
        loading: true
    } as SendGroupedExtensionsStoreState;
}