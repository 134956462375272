import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { EngagementType } from '../../../components/common/TaxReturn';
import { handleResponse, extractDocumentIds, handleBlob } from '../../Library';
import * as Constants from '../../../components/helper/Constants';
import { actionTypes } from '../../ActionTypes';
import { NotificationAction, StatusType } from '../../../store/common/NotificationStore';
import { IDocumentData } from '../../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { DisplayDownloadFile } from '../../../components/common/DisplayDownloadFile';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';

export interface IBlobFile {
    sas: string;
    guid: string;
}

export interface RequestAdditionalEsignDocumentAction {
    type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT;
    message: string;
}

export interface ReceiveAdditionalEsignDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK;
    sasUrl: string;
    documentGuid: string;
}

const unloadedState: IBlobFile =
    {
        sas: '',
        guid: '',
    } as IBlobFile;

type DispatchAction = RequestAdditionalEsignDocumentAction |
    ReceiveAdditionalEsignDocumentUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();

        const fetchTask = fetch(`${API_BASE_URL}${url}`, 
        { 
            credentials: 'include',
            headers : { 
                'X-Resource-Id': resourceId
             }
         })
         .then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                    statusType: StatusType.Error
                })
                logger.trackError(`getAddtionalEsignDocumentUploadLink failed for url: ${url}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();
        
        const fetchTask = fetch(`${API_BASE_URL}${url}`, 
        { 
            credentials: 'include',
            headers : { 
                'X-Resource-Id': resourceId
             }
         })
        .then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                    statusType: StatusType.Error
                })
                logger.trackError(`getAddtionalEsignDocumentDownLoadLink failed for url: ${url}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();
        const fetchTask = fetch(`${API_BASE_URL}${url}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        }).then(() => {
            const params = url.split("?");
            fetch(`${API_BASE_URL}api/Upload/GetDocToPdfDocumentLinkAsync?${params[1]}`, 
            { 
                credentials: 'include',
                headers : { 'X-Resource-Id': resourceId }
             })
                .then((resp) => resp.json())
                .then((data) => {
                    if (callback) {
                        data ? callback(data) : callback();
                    }
                    dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK, sasUrl: data.sas, documentGuid: data.guid });
                })
        }).catch(error => {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: Constants.AdditionalEsignDocuments.fetchDocumentError,
                statusType: StatusType.Error
            })
            logger.trackError(`convertDocToPdfArtifactAsync failed for url: ${url}, with error ${error.message}`);
        });
        addTask(fetchTask);
    },
    deleteUploadedAdditionalDocumentBlobFile: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/DeleteAdditionalEsignDocumentBlobFileAsync/` + documentGuid + '/' + fileName + '/' + taxYear, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId,
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.deleteDocument,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`deleteUploadedAdditionalDocumentBlobFile failed for documentGuid: ${documentGuid}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/DeleteAdditionalEsignDocumentAsync/` + documentId + '/' + fileName, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((data) => {
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.AdditionalEsignDocuments.deleteDocument,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`deleteAdditionalDocument failed for documentId: ${documentId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    downloadAdditionalEsignDocuments: (documentGuid: string, documentId: number, taxYear: number, clientId: string, taxPayerName: string,
        engagementType: EngagementType, additionalEsignDocuments: IDocumentData[], resourceId: string,
        isSigned?: boolean, onPageReload?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let fileName: string = "";
            fetch(`${API_BASE_URL}api/Download/DownloadAdditionalEsignDocumentsAsync`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Resource-Id': resourceId
                },
                body: JSON.stringify({
                    documentGuid: documentGuid,
                    taxYear: taxYear,
                    id: documentId,
                    clientId: clientId,
                    taxPayerName: taxPayerName,
                    engagementType: engagementType,
                    additionalEsignDocuments: additionalEsignDocuments,
                    isSigned: isSigned
                })
            }).then(response => {
                if (additionalEsignDocuments && additionalEsignDocuments.length > 1) {
                    return handleBlob(response)
                }
                else {
                    const contentDisposition = response.headers.get("content-disposition");
                    const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                    if (fileNameMatch && fileNameMatch.length > 1) {
                        fileName = fileNameMatch[1];
                    }
                    return response.blob();
                }
            }).then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                if (additionalEsignDocuments && additionalEsignDocuments.length == 1) {
                    displayDownloadFile.showFile(data, fileName);
                }
                else {
                    displayDownloadFile.showFile(data,
                        isSigned ? Constants.DownloadSignedAdditionalDocuments(clientId, taxYear, engagementType, taxPayerName) :
                            Constants.DownloadAdditionalDocuments(clientId, taxYear, engagementType, taxPayerName));
                }
            })
        }
};

export const reducer: Reducer<IBlobFile> = (state: IBlobFile = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_ADDITIONAL_ESIGN_DOCUMENT:
            return {
                ...unloadedState
            } as IBlobFile;
        case actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state || unloadedState;
}