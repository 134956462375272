import React from  'react';
import 'isomorphic-fetch';
import { RouteComponentProps } from 'react-router';
import { ClientInstructionHeader } from './ClientInstructions/ClientInstructionHeader';
import { EmailMessagePopUp } from './ClientInstructions/EmailMessage/EmailMessagePopUp';
import { ClientMessagePopUp } from './ClientInstructions/ClientMessage/ClientMessagePopUp';
import * as bootbox from 'bootbox';
import { ICompanySettings, initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings, initialExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import {
    IClientMessage, IEmailTemplateMessage, IWelcomeMessage, clientInstructions,
    initialClientMessage, initialEmailMessage, initialWelcomeMessage
} from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { VenusNotifier } from '../helper/VenusNotifier';
import * as ClientMessageStore from '../../store/settings/ClientMessageStore';
import * as EmailMessageStore from '../../store/settings/EmailMessageStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as ExtensionStoreModel from '../../Core/ViewModels/Extension/ExtensionStoreModels';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import * as Constants from '../helper/Constants';
import { EmailMessage } from './ClientInstructions/EmailMessage/EmailMessage';
import { ClientMessage } from './ClientInstructions/ClientMessage/ClientMessage';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import { decodeText } from '../helper/HelperFunctions';
import { logClientEvent } from '../helper/LoggerHelper';
import { LogEventConstants } from '../helper/LogEventConstants';

export interface state {
    showEmailMessagePopup: boolean;
    showClientMessagePopup: boolean;

    defaultMessageId: number;
    headerTitle: string;
    typeId: number;
    settings: ICompanySettings;
    extensionSettings: ExtensionCompanySettings;
    buttonText: string;
    title: string;
    isUpdateMessage: boolean;

    selectedInitialWelcomeMessage: number;
    selectedRecalledReturnWelcomeMessage: number;
    selectedVoucherReminderMessage: number;
    selectedDownloadEsignMessage: number;
    selectedPaymentInstruction: number;
    selectedSigningReminder: number;
    defaultInitialWelcomeMessage: number;
    defaultRecalledReturnWelcomeMessage: number;
    defaultVoucherReminderMessage: number;
    defaultDownloadEsignMessage: number;
    defaultPaymentInstruction: number;
    defaultSigningReminder: number;
    selectedMessageId: number;
    showLoader: boolean;
    loaderText: string;
    showPopUpLoader: boolean;
}

export type ClientInstructionProps = {
    clientMessageList: ClientMessageStore.IClientMessageStoreState,
    emailMessageList: EmailMessageStore.IEmailMessageStoreState,
    companyData: CompanyStore.ICompanyData,
    extensionCompanyData: ExtensionStoreModel.ExtensionSettingsStoreState
}
    & typeof ClientMessageStore.ActionCreators
    & typeof EmailMessageStore.ActionCreators
    & typeof CompanyStore.actionCreators
    & typeof ExtensionCompanySettingsStore.ActionCreators
    & typeof UserSettingsStore.actionCreators
    & RouteComponentProps<{}>;

const pageTitle = "ClientInstruction";
export class ClientInstruction extends React.Component<ClientInstructionProps, state> {
    constructor(props: ClientInstructionProps) {
        super(props);
        this.state = {
            showEmailMessagePopup: false,
            showClientMessagePopup: false,

            defaultMessageId: 0,
            headerTitle: '',
            typeId: 0,
            settings: initialCompanySettings,
            extensionSettings: initialExtensionCompanySettings,
            buttonText: "",
            title: "",
            isUpdateMessage: false,
            selectedInitialWelcomeMessage: 0,
            selectedVoucherReminderMessage: 0,
            selectedDownloadEsignMessage: 0,
            selectedPaymentInstruction: 0,
            selectedSigningReminder: 0,
            defaultInitialWelcomeMessage: 0,
            defaultVoucherReminderMessage: 0,
            defaultDownloadEsignMessage: 0,
            defaultPaymentInstruction: 0,
            defaultSigningReminder: 0,
            selectedMessageId: 0,
            showLoader: false,
            loaderText: "",
            showPopUpLoader: false,
            selectedRecalledReturnWelcomeMessage : 0,
            defaultRecalledReturnWelcomeMessage : 0,
        }
    }

    public UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestExtensionCompanySettings();
        this.props.requestUserSettings(true);
        this.props.requestEmailMessages(true);
        this.props.requestClientMessages();
        this.setState({ showLoader: true, loaderText: "Loading..." });

        logClientEvent(
                `${LogEventConstants.Common.Settings.Open} ${pageTitle}`,
                { count: 1, page: pageTitle }
            
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ClientInstructionProps) {
        this.setState({
            selectedInitialWelcomeMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.initialEmail : 0,

            selectedRecalledReturnWelcomeMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.recalledReturnMail : 0,

            selectedVoucherReminderMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.voucherReminders : 0,


            selectedDownloadEsignMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.downloadSignedForms : 0,


            selectedPaymentInstruction: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.paymentInstruction : 0,

            selectedSigningReminder: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.signingReminder : 0,

            defaultInitialWelcomeMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.initialEmail : 0,

            defaultRecalledReturnWelcomeMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.recalledReturnMail : 0,

            defaultVoucherReminderMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.voucherReminders : 0,


            defaultDownloadEsignMessage: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.downloadSignedForms : 0,


            defaultPaymentInstruction: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.paymentInstruction : 0,

            defaultSigningReminder: nextProps.extensionCompanyData.companySettings ?
                nextProps.extensionCompanyData.companySettings.defaultSettings.signingReminder : 0,
        });
        this.setState({
            showLoader:
                !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading ?
                    false : true,

            showPopUpLoader:
                !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading ?
                    false : true,
        }, () => {
            if (!this.state.showPopUpLoader) {
                this.setState({
                    showEmailMessagePopup: false,
                    showClientMessagePopup: false,
                });
            }
        });
    }

    public render() {
        return <div>
            <ClientInstructionHeader />
            <EmailMessage
                heading={'Initial Email'}
                description={'Initial email sent to clients'}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.initialEmail
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultInitialWelcomeMessage}
                selectedMessage={this.state.selectedInitialWelcomeMessage}
                onListItemClick={this.onInitialMessageListItemClick}
                typeId={clientInstructions.initialEmail}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessage
                heading={'Review and Sign Extension Reminder'}
                description={'Signing reminder sent to client'}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.signingReminder
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultSigningReminder}
                selectedMessage={this.state.selectedSigningReminder}
                onListItemClick={this.onSigningReminderListItemClick}
                typeId={clientInstructions.signingReminder}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessage
                heading={'Voucher Reminders'}
                description={'Quarterly voucher reminders sent to clients'}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.voucherReminder
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultVoucherReminderMessage}
                selectedMessage={this.state.selectedVoucherReminderMessage}
                onListItemClick={this.onVoucherReminderListItemClick}
                typeId={clientInstructions.voucherReminder}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <hr></hr>

            <EmailMessage
                heading={'Download E-Signed Forms'}
                description={'Once all parties have e-signed all documents'}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.downloadEsignedFroms
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultDownloadEsignMessage}
                selectedMessage={this.state.selectedDownloadEsignMessage}
                onListItemClick={this.onDownloadEsignListItemClick}
                typeId={clientInstructions.downloadEsignedFroms}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <hr></hr>
            <EmailMessage
                heading={'Recalled Return Instructions'}
                description={'Recalled Return email sent to clients'}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.recalledReturnEmail
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultRecalledReturnWelcomeMessage}
                selectedMessage={this.state.selectedRecalledReturnWelcomeMessage}
                onListItemClick={this.onRecalledReturnEmailListItemClick}
                typeId={clientInstructions.recalledReturnEmail}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr/>

            <hr/>

            <ClientMessage
                heading={'Payment Instruction'}
                description={'Header in payment step client side'}
                clientMessages={
                    Object.keys(this.props.clientMessageList.data)
                        .filter((k, i) => (
                            this.props.clientMessageList.data[parseInt(k)].clientMessage.type == clientInstructions.paymentInstruction
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})
                }
                defaultMessage={this.state.defaultPaymentInstruction}
                selectedMessage={this.state.selectedPaymentInstruction}
                onListItemClick={this.onPaymentInstructionListItemClick}
                typeId={clientInstructions.paymentInstruction}
                onAddButtonClick={this.onClientInstructionAddButtonClick}
                onEditButtonClick={this.onClientInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteClientMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessagePopUp
                showState={this.state.showEmailMessagePopup}
                onHide={this.onCancelButtonClick}
                submitButtonClick={
                    this.state.isUpdateMessage ?
                        this.editEmailMessage :
                        this.saveEmailMessage
                }
                buttonText={this.state.buttonText}
                selectedMessageDetail={
                    this.state.isUpdateMessage ?
                        this.props.emailMessageList.data[this.state.selectedMessageId] ?
                            this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage :
                            initialEmailMessage :
                        initialEmailMessage
                }
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                loaderText={"Saving..."}
                showLoader={this.state.showPopUpLoader}
                emailMessages={
                    Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => (
                            this.props.emailMessageList.data[parseInt(k)].emailMessage.type == this.state.typeId
                        ))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})
                }
            />
            <ClientMessagePopUp
                onHide={this.onCancelButtonClick}
                showState={this.state.showClientMessagePopup}
                submitButtonClick={
                    this.state.isUpdateMessage ?
                        this.editClientMessage :
                        this.saveClientMessage
                }
                buttonText={this.state.buttonText}
                selectedMessageDetail={
                    this.state.isUpdateMessage ?
                        this.props.clientMessageList.data[this.state.selectedMessageId] ?
                            this.props.clientMessageList.data[this.state.selectedMessageId].clientMessage :
                            initialClientMessage :
                        initialClientMessage
                }
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                loaderText={"Saving..."}
                showLoader={this.state.showPopUpLoader}
                clientMessages={
                    Object.keys(this.props.clientMessageList.data)
                        .filter((k, i) => (this.props.clientMessageList.data[parseInt(k)].clientMessage.type == this.state.typeId))
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})
                }
            />
        </div>;
    }

    private onInitialMessageListItemClick = (messageId: number) => {
        this.setState({ selectedInitialWelcomeMessage: messageId });
    }

    private onVoucherReminderListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherReminderMessage: messageId });
    }


    private onDownloadEsignListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadEsignMessage: messageId });
    }

    private onRecalledReturnEmailListItemClick = (messageId: number) => {
        this.setState({ selectedRecalledReturnWelcomeMessage: messageId });
    }

    private onPaymentInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedPaymentInstruction: messageId });
    }

    private onSigningReminderListItemClick = (messageId: number) => {
        this.setState({ selectedSigningReminder: messageId });
    }

    private onClientInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Add",
            title: "Add Client Instruction",
            isUpdateMessage: false,
            showClientMessagePopup: true,
            typeId: typeId,
        });
    }

    private onClientInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Save",
            title: "Edit Client Instruction",
            isUpdateMessage: true,
            showClientMessagePopup: true,
            typeId: typeId,
        });
    }

    private onEmailInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Client Instruction",
            typeId: typeId,
        });
    }

    private onEmailInstructionEditButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Client Instruction",
            typeId: typeId
        })
    }

    private saveEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: any = this.props.extensionCompanyData.companySettings ? this.props.extensionCompanyData.companySettings : initialExtensionCompanySettings;
        message.type = this.state.typeId;
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.saveEmailMessageDetail(message, companyData, isSetDefault, this.state.typeId);
        this.setState({
            showPopUpLoader: true
        });

        logClientEvent(
                `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
                { count: 1, page: pageTitle }
        );

    }

    private editEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.updateEmailMessageDetail(message);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id)

            logClientEvent(
                    `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
                    { count: 1, page: pageTitle }
            );
        }
        this.setState({
            showPopUpLoader: true
        })
    }

    private deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number) => {
        if (this.props.emailMessageList.data[selectedMessageId].emailMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "")
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure, You want to delete this Instruction ?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessageDetail
                            (_currentObject.props.emailMessageList.data[selectedMessageId].emailMessage);
                        _currentObject.setState({ showLoader: true })
                    }
                }
            });
        }
    }

    private deleteClientMessage = (selectedMessageId: number, defaultMessageId: number) => {
        if (this.props.clientMessageList.data[selectedMessageId].clientMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "")
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure, You want to delete this Instruction ?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteClientMessageDetail
                            (_currentObject.props.clientMessageList.data[selectedMessageId].clientMessage)
                        _currentObject.setState({ showLoader: true })
                    }
                }
            });
        }
    }

    private saveClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: any = this.props.extensionCompanyData.companySettings ? this.props.extensionCompanyData.companySettings : initialExtensionCompanySettings;
        message.type = this.state.typeId;
        message.name = decodeText(message.name);
        this.props.saveClientMessageDetail(message, companyData, isSetDefault, this.state.typeId);
        this.setState({
            showPopUpLoader: true
        })
    }

    private editClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.name = decodeText(message.name);
        this.props.updateClientMessageDetail(message);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id)
        }
        this.setState({
            showPopUpLoader: true
        })
    }

    private onCancelButtonClick = () => {
        this.setState({
            showClientMessagePopup: false,
            showEmailMessagePopup: false,
        })
    }

    private saveDefaultMessage = (messageId: number) => {
        const companyData: ExtensionCompanySettings = this.props.extensionCompanyData.companySettings ?
            this.props.extensionCompanyData.companySettings :
            initialExtensionCompanySettings;

        if (this.state.typeId == clientInstructions.initialEmail) {
            companyData.defaultSettings.initialEmail = messageId;
        } else if (this.state.typeId == clientInstructions.voucherReminder) {
            companyData.defaultSettings.voucherReminders = messageId;
        }  else if (this.state.typeId == clientInstructions.downloadEsignedFroms) {
            companyData.defaultSettings.downloadSignedForms = messageId;
        }  else if (this.state.typeId == clientInstructions.paymentInstruction) {
            companyData.defaultSettings.paymentInstruction = messageId;
        } else if (this.state.typeId == clientInstructions.signingReminder) {
            companyData.defaultSettings.signingReminder = messageId;
        } else if (this.state.typeId == clientInstructions.recalledReturnEmail) {
            companyData.defaultSettings.recalledReturnMail = messageId;
        }

        this.props.updateExtensionCompanySettings(companyData);
    }


}
