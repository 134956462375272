import React from  'react';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import Select from 'react-select';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface ISigningReminderNotification {
    companySettings: ExtensionCompanySettings;
    handleSigningReminderReminder(event: any): void;
    handleSettingSigningReminderDays(event: any): void;
}

export class SigningReminderNotification extends React.Component<ISigningReminderNotification, {}>{
    constructor(props: any) {
        super(props);
    }
    buildOptions() {
        var arr = [];
        for (let i = 1; i <= 30; i++) {
            if (i == 1) {
                arr.push({
                    value: i,
                    label: i + " day"
                })
            }
            else {
                arr.push({
                    value: i,
                    label: i + " days"
                })
            }
        }

        return arr;
    }


    public render() {

        return <div id="divPaymentVoucherNotifications" className="new-main-content">
            <h3>Automatic Signing Reminders</h3>
            <div className="marL30">
                <CheckBoxComponent
                    id={"EnableSigningReminder"}
                    text={"Enable Automatic Signing Reminders"}
                    checked={this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings.enableSigningReminder}
                    onChange={this.props.handleSigningReminderReminder}
                    datatestAuto={"8BAE0132-3B3C-492A-8399-B26C6F7582E8"}
                    resourceId={RBACKeys.Settings.settings}
                />
                <div className="row marT20">
                    <div className="padT07 voucher-value">Set default reminder to</div>
                    <div className="select-reminder-control" data-resource-id={RBACKeys.Settings.settings}>
                        <Select
                            name="form-field-name"
                            value={this.props.companySettings.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder}
                            onChange={this.props.handleSettingSigningReminderDays}
                            options={this.buildOptions()}
                            data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                            clearable={false}
                        />
                        </div>
                    <div className="padT07 ml-5">since last reminder/initial email</div>
                </div>
            </div>
        </div>;
    }
}

export default SigningReminderNotification;
