let moment = require('moment');
import * as bootbox from 'bootbox';
import React from 'react';
import {
    BootstrapTable, CustomSelectProps, DateFilter, SelectFilter,
    SelectFilterOptionsType, TableHeaderColumn, TextFilter, ToolBarProps, CustomFilter
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as InUseExtensionsState from '../../../store/reports/InUseExtensionsState';
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { engagementType, DocumentStatus, DaysRangeOptionsList, IOfficeLocation } from '../../common/TaxReturn';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { IShowExportExcelState } from '../../reports/DeliveredReturns/DeliveredReturnsTable';
import { Filters, IFilters, ReportFilterType } from '../Filters';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { Button } from 'react-bootstrap';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { EngagementList } from 'src/Core/Common/Common';

interface TableData {
    inUse: InUseExtensionsState.InUseExtensionsState
    saveFilterShow: boolean,
    onPageChange: any,
    onSortChange: any,
    onSearchChange: any,
    onFilterChange: any,
    pageNo: number,
    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;
    totalRows: number;
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[],
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;
    currentFilter: IFilters,
    onMakeAvailable: (rowIndex: number) => void;
    isLoading: boolean,
    pageSize: number,
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    taxYearList: any;
    proxyFilter: string;
    filterType: ReportFilterType,
    onPageReload: () => void;
    refreshDelay?: boolean;
    userLocation: IOfficeLocation[]
    clearFilter:()=>void
}

export class InUseExtensionsTable extends React.Component<TableData, IShowExportExcelState> {
    private filterChanged: boolean = false;
    private customMultiSelect: any;
    public isAppliedFilter: boolean = false;
    private customMultiOfficeSelect: any;
    constructor(props: TableData) {
        super(props);
        this.state = {
            isActive: true,
            isHiddenExportExcel: true
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter makeAvailableReturn={() => this.props.onMakeAvailable(row)} disabled={false} data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {

        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private defaultType(cell: any, row: any) {
        return cell;
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppliedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }

    public onClearFilter() {
        this.filterChanged = true;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        (this.refs.uploadedOn as TableHeaderColumn)?.cleanFiltered();
        this.customMultiSelect?.cleanFiltered();
        this.filterChanged = false;
        (this.refs.taxYear as TableHeaderColumn)?.cleanFiltered();
        this.customMultiOfficeSelect?.cleanFiltered();
        this.props.clearFilter();
    }
    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }

    private getMultiOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (
                this.customMultiOfficeSelect = ref
            )} filterHandler={filterHandler} 
            options={options} 
            placeholder={placeholder} 
            className={"filter select-filter multi-select-widthauto-office-location"} 
            optionClassName={"office-location-custom-multiselect-status"}/>
        );
    }

    private onFilterSaveClick(filterName: string) {
        if (!Helper.validateFilter(this.props.currentFilter)) {
            ((this.props.currentFilter.searchText.length > 0) ? "" : (VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null)));
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.confirm({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: DeliveredReturnsTableConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: DeliveredReturnsTableConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.props.onSaveFilterShow();
                    }
                    else {
                        this.props.onFilterUpdate(filterName);
                    }
                }
            });
        }
    }

    public onLoadSelectedFilter(filter?: IFilters) {
        this.isAppliedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        (this.refs.taxDocumentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        (this.refs.partner as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentStatus':
                        this.customMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'uploadedOn':
                        let uploadedDateFilter: any = filter.fields[field];
                        if (uploadedDateFilter.date) {
                            (this.refs.uploadedOn as TableHeaderColumn)?.applyFilter(uploadedDateFilter);
                        }
                        break;
                    case 'engagementType':
                        (this.refs.engagementType as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;

                    case 'testColumn':
                        (this.refs.testColumn as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        (this.refs.taxYear as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'officeLocation':
                        this.customMultiOfficeSelect?.applyFilter(filter.fields[field]);
                        break;
                }
            }
            this.filterChanged = false;
            this.isAppliedFilter = false;
            this.onFilterChange(filter.fields);
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"inuse-extensions-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    private createCustomToolBar = () => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                <Filters ref='Filters'
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={this.state.isHiddenExportExcel}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                />
            </div>
        );
    }


    public render() {
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;

        const DocumentStatusType =
            [
                { label: 'UPLOADED', value: 1 },
                { label: 'ERROR', value: 2 },
                { label: 'PROCESSING', value: 4 },
                { label: 'REVIEW', value: 5 },
                { label: 'APPROVED FOR DELIVERY', value: 8 },
                { label: 'PREPARING FOR DELIVERY', value: 9 },
                { label: 'DELIVERY FAILED', value: 10 },
                { label: 'NON SUPPORTED', value: 11 },
                { label: 'RECALLED', value: 12 }
            ];

        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {

            for (let rowIndex of this.props.selectedRows) {
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };


        const searchEnable = true;

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'NAME', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Type...', options: EngagementList } as SelectFilter
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'officeLocation',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '130px',
                filter: {
                    type: 'CustomFilter', getElement: this.getMultiOfficeSelectDropDown, customFilterParameters: {
                        options: [...this.props.userLocation.map(location => { return { "label": location.locationName, "value": location.locationId } }),{"label":"Blanks","value":-100}],
                        enableAllFilter: false,
                        placeholder: 'Select Office Location...'
                    } as any
                } as CustomFilter
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Status',
                key: 'documentStatus', // String-based value accessors!
                isKey: false,
                dataFormat: "DocumentStatus",
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'CustomFilter', getElement: this.getMultiSelectDropDown, customFilterParameters: { options: DocumentStatusType, enableAllFilter: false, placeholder: 'Select Status...' } as any } as CustomFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Tax Year...', options: this.props.taxYearList } as SelectFilter
            },
            {
                header: '',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: '',
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        let data: any = [];

        if (this.props.inUse.InUseExtensionTableModel.inUseExtensionModel) {
            data = this.props.inUse.InUseExtensionTableModel.inUseExtensionModel.map((model, index) => {
                return {
                    taxDocumentName: model.clientName,
                    clientId: model.clientId,
                    engagementType: engagementType(model.engagementType),
                    partner: model.eroSignerFirstName ? model.eroSignerFirstName + " " + model.eroSignerLastName : "",
                    uploadedOn: moment(model.uploadedOn).format('MM/DD/YYYY'),
                    documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
                    downloded: 0,
                    button: 'Unlock Extension',
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    signedCount: 0,
                    id: model.documentId,
                    documentKey: model.documentGuid,
                    taxYear: model.taxYear,
                    rowIndex: index,
                    officeLocation: model.locationName
                }
            });
        }
        return <div className="pos-relative">

            <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    search={searchEnable}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            dataFormat={(cell, row) => {
                                return value.dataFormat ? <div title={cell} className="ellipsis">{cell}</div> :
                                    <ButtonFormatter makeAvailableReturn={() => this.props.onMakeAvailable(row.rowIndex)} disabled={false} data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
                            }}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            width={value.width}
                            filter={value.filter}
                            dataSort={value.dataSort}>
                            <span title={value.header} className='table-text-sort' style={{ width: "80%" }}>{value.header}</span>
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

interface IButtonFormatterProps {
    makeAvailableReturn: any,
    disabled: boolean
}

class ButtonFormatter extends React.Component<IButtonFormatterProps, {}> {
    render() {
        return (
            <button
                type="button"
                className="btn btn-info unlock-button ellipsis"
                disabled={this.props.disabled}
                value="Unlock Extension"
                onClick={this.props.makeAvailableReturn}
                data-resource-id={RBACKeys.lockedExtension.unlockButton}
                title='Unlock Extension'
            >
                <i className="fas fa-lock-open" data-test-auto="C166BDDC-ADE5-46F2-8B94-7ABAFFC4BED7"></i>
                <span>Unlock Extension</span>
            </button>
        );
    }
}

export default InUseExtensionsTable