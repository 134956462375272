import React from  'react';
import 'react-select/dist/react-select.css';
import { DropdownComponent } from '../../common/controls/DropdownComponent'
import { Accordion, Card } from 'react-bootstrap';
import { DefaultDownloadInfoType, DefaultSenderInfoType, IExtensionDefaultSettings } from 'src/store/userManagement/ExtensionUserSettingStore';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { RadioButtonComponent } from 'src/components/common/RadioButtonComponent';
import { ICustomColumn } from 'src/components/settings/GeneralSettings';

interface Props {
	DefaultSettings: IExtensionDefaultSettings;
    onChangeEROStamp: (event: any) => void;
    onChangeContactPerson: (event: any) => void;
    onChangeSenderName: (event: any) => void;
    onChangeNotifySigningEvents: (event: any) => void;
    onChangeDefaultSender: (event: any) => void;
    onChangeCustomColumnValue: (value: any) => void;
    onChangeDefaultDownload: (event: any) => void;
    extensionUsers:  IUserModel[];
    customColumnData: ICustomColumn;
    partnerUsers:  IUserModel[];
}

interface DefaultState {
    customColOptions: any;
    selectedCustomColValue: number;
    DownloadOption: any;
    selectedDownloadOption: DefaultDownloadInfoType;
}

export class MyDefaults extends React.Component<Props, DefaultState> {
    constructor(props: any) {
        super(props);
        this.state = {
            customColOptions: [],
            selectedCustomColValue: 0,
            DownloadOption: [],
            selectedDownloadOption: DefaultDownloadInfoType.PromptMeEveryTime,
        }
    }

    componentWillMount() {
        this.setCustomColumnOptions();
        this.setDownloadOption();
    }

    setCustomColumnOptions = () => {
        let _customColOptions: any = [];
        let selectedValue: number = 0;
        Object.keys(this.props.customColumnData.customColumnValues).map((key, index) => {
            let custCol = this.props.customColumnData.customColumnValues[parseInt(key)];
            if (custCol.id == this.props.DefaultSettings.customColumnValue) {
                selectedValue = custCol.id;
            }
            _customColOptions.push({
                value: custCol.id,
                label: custCol.value
            })
        })

        this.setState({
            customColOptions: _customColOptions,
            selectedCustomColValue: selectedValue
        });
        if (selectedValue == 0) {
            this.props.onChangeCustomColumnValue(0);
        }
    }

    setDownloadOption = () => {
        let _DownloadOption: any = [];

        _DownloadOption.push({
            value: DefaultDownloadInfoType.DownloadNow,
            label: "Download Now"
        })
        _DownloadOption.push({
            value: DefaultDownloadInfoType.DownloadToMyDownloads,
            label: "Download to \"My Downloads\""
        })
        _DownloadOption.push({
            value: DefaultDownloadInfoType.PromptMeEveryTime,
            label: "Prompt me Every Time"
        })

        if (!this.props.DefaultSettings?.download?.downloadType) {
            this.setState({
                DownloadOption: _DownloadOption,
                selectedDownloadOption: DefaultDownloadInfoType.PromptMeEveryTime
            });
        }
        else {
            this.setState({
                DownloadOption: _DownloadOption,
                selectedDownloadOption: this.props.DefaultSettings.download.downloadType
            });
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setDownloadValue(nextProps?.DefaultSettings?.download?.downloadType);
    }

    contactPersonsList = () => {
        var columnValues: any = [];
        this.props.extensionUsers.map((person: IUserModel) => {
            columnValues.push({
                value: person.id,
                label: person.firstName + " " + person.lastName,
            })
        });
        return columnValues;
    }

    eroUserList = () => {
        var listValues: any = [];
        this.props.partnerUsers.map((ERO: IUserModel) => {
            listValues.push({
                value: ERO.id,
                label: ERO.firstName + " " + ERO.lastName
            });
        });
        return listValues;
    };

    private handleCustomColumnChange = (selectedOption: any) => {
        this.props.onChangeCustomColumnValue(selectedOption);
        this.setState({
            selectedCustomColValue: selectedOption
        });
    }

    private setDownloadValue = (downloadType: DefaultDownloadInfoType) => {
        if (downloadType) {
            this.setState({
                selectedDownloadOption: downloadType
            });
        }
    }
    private handleDefaultDownloadChange = (selectedOption: any) => {
        this.props.onChangeDefaultDownload(selectedOption);
        this.setState({
            selectedDownloadOption: selectedOption
        });
    }


    public render() {
        let customColumnPresent: boolean = true;
        if (this.props.customColumnData == null || this.props.customColumnData.header == "") {
            customColumnPresent = false;
        }

        return <Accordion defaultActiveKey="0">
            <Card id="collapsible-panel-defaults" className="marB20">
                <Accordion.Toggle eventKey="0" as={Card.Header} variant="link">
                    <Card.Title className="text-left-align ">
                        <i className='fa fa-star'></i>My Defaults
                    </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default ERO/Signer:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30`}>
                                    <DropdownComponent
                                            id="ddlEROList"
                                            options={this.eroUserList()}
                                            onChange={this.props.onChangeEROStamp}
                                            selectedValue={
                                                this.props.DefaultSettings.eroUser !== 0 ? this.props.DefaultSettings.eroUser : ""
                                            }
                                            data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Contact Person:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30`} >
                                        <DropdownComponent
                                            id="ddlContactPersonList"
                                            options={this.contactPersonsList()}
                                            onChange={this.props.onChangeContactPerson}
                                            selectedValue={this.props.DefaultSettings.contactPerson}
                                            data-test-auto="D96F597E-56F3-4179-AEAB-A12A8B7308E2"
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Sender:</div>
                                </div>
                                <div className={`col-sm-3 col-md-2 col-xl-2`}>
                                    <RadioButtonComponent
                                        id={"Company"}
                                        checked={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.Company}
                                        onChange={this.props.onChangeDefaultSender}
                                        value={"company"}
                                        dataTestAuto="EDE9EF76-CB65-476D-8CB8-0AE9E815384A"
                                        name="rdbDefaultSender"
                                        text={"Company"}
                                    />
                                </div>
                                <div className="col-sm-3 col-md-2 col-xl-2">
                                    <RadioButtonComponent
                                        id={"Users"}
                                        text={"Users"}
                                        checked={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.User}
                                        onChange={this.props.onChangeDefaultSender}
                                        value={"users"}
                                        dataTestAuto={"ADE9EF76-CB65-476D-8CB8-0AE9E815477A"}
                                        name="rdbDefaultSender"
                                    />
                                </div>
                            </div>

                            <div className={this.props.DefaultSettings.sender.senderType == DefaultSenderInfoType.Company ? 'hidden' : 'row marB03 padT07'}>
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Select Default Sender Name:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30`} >
                                        <DropdownComponent
                                            id="ddlDefaultSender"
                                            options={this.contactPersonsList()}
                                            onChange={this.props.onChangeSenderName}
                                            selectedValue={this.props.DefaultSettings.sender.senderId}
                                            data-test-auto="A8E1183B-863F-4649-83BA-787DC6A1FACD"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row marB03 padT07">
                                <div className="col-sm-6 col-md-5 col-xl-5">
                                    <div className="marB10 padT07">Default Notify About Signing Events:</div>
                                </div>
                                <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                    <div className={`padR30`}>
                                        <DropdownComponent
                                            id="ddlDefaultSigningEvents"
                                            options={this.contactPersonsList()}
                                            onChange={this.props.onChangeNotifySigningEvents}
                                            selectedValue={this.props.DefaultSettings.notifyUser}
                                            data-test-auto="A97096D1-35C5-4428-AB53-BCBEA46B12DA"
                                        />
                                    </div>
                                </div>
                            </div>
                            {customColumnPresent ?
                                <div className="row marB03 padT07">
                                    <div className="col-sm-6 col-md-5 col-xl-5">
                                        <div className="marB10 padT07">Default Custom Column Value ({this.props.customColumnData.header}):</div>
                                    </div>
                                    <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                        <div className={`padR30`}>
                                            <DropdownComponent
                                                id="ddlDefaultCustomColumn"
                                                options={this.state.customColOptions}
                                                onChange={this.handleCustomColumnChange}
                                                selectedValue={this.state.selectedCustomColValue}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Download Option:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="DownloadOptionChange"
                                        options={this.state.DownloadOption}
                                        selectedValue={this.state.selectedDownloadOption}
                                        onChange={this.handleDefaultDownloadChange}
                                        data-test-auto="CC1D0849-C531-4A43-8A88-CFB97C57D971"
                                    />
                                </div>
                            </div>
                        </div>

                    </Card.Body>
                </Accordion.Collapse>

            </Card>
        </Accordion>
    }
}

export default MyDefaults;