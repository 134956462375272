import React from  'react';
import { IDocumentTypeValue } from '../Signatures';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { DocumentTypeConstants } from '../../helper/Constants';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { NavLink } from 'react-bootstrap';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { EditDoumentTypeValue } from '../SignaturteComponents/EditDoumentTypeValue';
import { RBACKeys } from 'src/components/helper/RbacConstants';


interface IAdditionalDocumentTypeProps {
    overlayLoading: boolean;
    overlayMessage: string;
    documentType: IDocumentTypeValue[];
    HandleCustomizeDeliveredreportsEditButton: (event: any) => void;
    updateDocumentTypeValue(documentTypeValue: IDocumentTypeValue): void;
    addDocumentTypeValue(documentTypeValue: IDocumentTypeValue): void;
    deleteDocumentTypeValue(documentTypeValue: IDocumentTypeValue): void;

}
interface IAdditionalDocumentTypeState {

    disableUpdateButton: boolean;
    disableAddButton: boolean;
    selectedId: number;
    selectedValue: string;
    showEditDocumentTypeValuePopup: boolean;
    documentTypeValue: IDocumentTypeValue;
    newDocumentTypeValue: IDocumentTypeValue;
}
export const initialDocumentTypeValues: IDocumentTypeValue = {
    id: 0,
    inUseCount: 0,
    value: "",
}
export class AdditionalDocumentType extends React.Component<IAdditionalDocumentTypeProps, IAdditionalDocumentTypeState>{
    constructor(props: any) {
        super(props);
        this.state = {
            disableUpdateButton: true,
            disableAddButton: true,
            selectedId: 0,
            selectedValue: "",
            showEditDocumentTypeValuePopup: false,
            documentTypeValue: initialDocumentTypeValues,
            newDocumentTypeValue: initialDocumentTypeValues
        }
        ///////////////////////// Add Document Type functions//////////////////
        this.onDocumentValueChange = this.onDocumentValueChange.bind(this);
        this.addDocumentTypeValue = this.addDocumentTypeValue.bind(this);
        ///////////////////////// Edit Document Type  functions//////////////////
        this.openDocumentValueEditPopup = this.openDocumentValueEditPopup.bind(this);
        this.onNewDocumentValueChange = this.onNewDocumentValueChange.bind(this);
        this.updateDocumentTypeValue = this.updateDocumentTypeValue.bind(this);
        this.onCancelEditDocumentValuePopup = this.onCancelEditDocumentValuePopup.bind(this);
        ///////////////////////// Delete Document Type  functions//////////////////
        this.deleteDocumentTypeValue = this.deleteDocumentTypeValue.bind(this);
        this.proceedToDeleteValue = this.proceedToDeleteValue.bind(this);
    }

    componentWillReceiveProps() {
        this.setState({
            newDocumentTypeValue: {
                id: 0,
                inUseCount: 0,
                value: "",
            },
            disableAddButton: true,
        })
    }


    ///////////////////////// Add Document Type value  start//////////////////
    onDocumentValueChange(event: any) {
        let temp = this.state.newDocumentTypeValue;
        temp.value = event.target.value;
        this.setState({
            newDocumentTypeValue: temp,
            disableAddButton: (event.target.value.trim() != "" || temp.value.trim() != "") ? false : true
        })
    }

    addDocumentTypeValue() {
        const docTypeLength = 40;
        if (this.state.newDocumentTypeValue.value.length > docTypeLength) {
            VenusNotifier.Warning(DocumentTypeConstants.ValidationMessages.DocumentTypeValueLength, null);
        }
        else {
            if (this.DocumentTypeAlreadyExistsValidation(this.state.newDocumentTypeValue.value)) {
                this.props.addDocumentTypeValue(this.state.newDocumentTypeValue);
            }
        }
    }
    ///////////////////////// Add Document Type value value end//////////////////

    ///////////////////////// Delete Document Type value   start//////////////////
    deleteDocumentTypeValue(documentTypeValue: IDocumentTypeValue) {
        this.setState({
            documentTypeValue: documentTypeValue
        })
    }
    proceedToDeleteValue() {
        this.props.deleteDocumentTypeValue(this.state.documentTypeValue)
        document.body.click();
    }
    ///////////////////////// Delete Document Type value end//////////////////

    ///////////////////////// Edit Document Type value start//////////////////
    openDocumentValueEditPopup(documentTypeValue: IDocumentTypeValue) {
        this.setState({
            showEditDocumentTypeValuePopup: true,
            selectedValue: documentTypeValue.value,
            documentTypeValue: {
                id: documentTypeValue.id,
                inUseCount: documentTypeValue.inUseCount,
                value: "",
            }
        })
    }

    onCancelEditDocumentValuePopup() {
        this.setState({
            showEditDocumentTypeValuePopup: false,
        })
    }

    onNewDocumentValueChange(event: any) {
        let temp = this.state.documentTypeValue;
        temp.value = event.target.value;
        this.setState({ documentTypeValue: temp })
    }

    updateDocumentTypeValue() {
        if (this.state.documentTypeValue.value.trim() == "") {
            VenusNotifier.Warning(DocumentTypeConstants.ValidationMessages.DocumentTypeValueWarning, null);
            return false;
        }
        if (this.DocumentTypeAlreadyExistsValidation(this.state.documentTypeValue.value)) {
            this.props.updateDocumentTypeValue(this.state.documentTypeValue);
            this.setState({ showEditDocumentTypeValuePopup: false })
        }
    }
    ///////////////////////// Edit Document Type value end//////////////////

    public closePopHover() {
        document.body.click();
    }

    DocumentTypeAlreadyExistsValidation(columnValue: string) {
        let isValid: boolean = true;
        this.props.documentType.map((list, index) => {
            if (list.value.toLowerCase() == columnValue.trim().toLowerCase()) {
                VenusNotifier.Warning(DocumentTypeConstants.ValidationMessages.DocumentTypeAlreadyExist, null);
                isValid = false;
            }
        });
        return isValid;
    }

    public render() {
        const popover = (
            <Popover id="popover-basic"
                title="Are you sure?"
                style={{ fontSize: '14px' }}>
                <Popover.Title as="h3" className="popover-title">Are you sure?</Popover.Title>
                <Popover.Content className="popover-content">
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.proceedToDeleteValue}
                        title="Yes"
                        data-test-auto="2813EEBF-E0EF-47A2-8441-4C3C6129EF69"
                        style={{ marginLeft: "5px" }}>
                        Yes
                    </button>
                    <button
                        onClick={this.closePopHover}
                        title="No"
                        data-test-auto="1C4AA039-5A68-4864-AC82-FC0CF21E7BFA"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                </button>
                </Popover.Content>
            </Popover>
        );
        return <div id="divAdditionalDocumentType" className="new-main-content">
            <h3>Additional Document for E-Sign</h3>
            <div className="marL20">
                <div className="row marB10" >
                    <div className="col-lg-2" style={{ paddingTop: "8px" }}>
                        <label>Document Type Values </label>
                    </div>
                    <div className="col-lg-3" style={{ paddingTop: "5px", paddingBottom: '5px', marginLeft: '-2%' }}>
                        <input className="form-control input-sm"
                            type="text"
                            value={this.state.newDocumentTypeValue.value}
                            placeholder="Column Value"
                            onChange={this.onDocumentValueChange}
                            data-test-auto="E1581D86-39E6-4EDF-8964-99F2A6F78EF1"
                            data-resource-id={RBACKeys.Settings.settings}>
                        </input>
                    </div>
                    <div className="col-lg-3" style={{ paddingTop: "5px", paddingBottom: '5px' }}>
                        <button
                            data-resource-id={RBACKeys.Settings.settings}
                            style={{ padding: "8px 12px" }}
                            onClick={this.addDocumentTypeValue}
                            title="Add"
                            data-test-auto="FFECCAD1-6707-4910-9564-F2F2214C2709"
                            disabled={this.state.disableAddButton}
                            className="btn btn-success btn-lg">
                            <span className="fa fa-plus"></span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5" style={{ marginLeft: '16%', paddingLeft: '0px' }}>
                        <div className="list-group" style={{
                            height: "150px", overflowY: "scroll",
                            borderBottom: "#ebebec 1px solid", borderTop: "#ebebec 1px solid"
                        }}>
                            <div>
                                <OverlayLoader show={this.props.overlayLoading} text={this.props.overlayMessage} />
                                {
                                    this.props.documentType.map((value, index) => {
                                        return <li className="list-group-item" style={{ fontSize: '14px' }} key={index}>
                                            {value.value}
                                            <NavLink href='javascript:' className="list-group-item-action" style={{ display: 'contents', pointerEvents: value.inUseCount > 0 ? "none" : "auto" }}data-resource-id={RBACKeys.Settings.settings}>
                                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover} data-resource-id={RBACKeys.Settings.settings}>
                                                    <span className={value.inUseCount > 0 ? "fas fa-trash-alt text-muted pull-right delete-document-type-modal"
                                                        : "fas fa-trash-alt text-danger pull-right delete-document-type-modal"}
                                                        onClick={() => { this.deleteDocumentTypeValue(value) }} ></span>
                                                </OverlayTrigger>


                                            </NavLink>
                                            <NavLink href='javascript:' className="list-group-item-action" style={{
                                                display: 'contents'}} data-resource-id={RBACKeys.Settings.settings}>
                                                <span className="fas fa-edit pull-right edit-document-value-modal" style={{ color: "#337ab7" } }
                                                    onClick={() => { this.openDocumentValueEditPopup(value) }}></span>
                                            </NavLink>
                                            <span className="badge badge-icon">{value.inUseCount > 0 ? value.inUseCount : "unused"}</span>
                                        </li>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <EditDoumentTypeValue
                    showState={this.state.showEditDocumentTypeValuePopup}
                    onHide={this.onCancelEditDocumentValuePopup}
                    onNewDocumentValueChange={this.onNewDocumentValueChange}
                    onSaveButtonClick={this.updateDocumentTypeValue}
                    documentTypeValue={this.state.documentTypeValue}
                    selectListItem={this.state.selectedValue}
                />
            }
        </div>;
    }
}
export default AdditionalDocumentType;
