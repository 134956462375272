import fetchIntercept from 'fetch-intercept';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import * as Loader from '../../components/helper/Loader';
import store from '../../store';
import history from '../../routes/history';
import { Product } from '../ViewModels/User/UserViewModel';
import { userAutoLogout } from '../../store/auth/actions';
import { LogoutCause } from '../../store/auth/reducer';
import {  setAutoLogout } from '../../oidcClient/helpers';
import { UserAutoLogoutConstants } from '../../components/helper/Constants';

export const unregister = fetchIntercept.register({
    request: function (url, config) {
        const user = store.getState().auth.user
        const configDefaults = {
            ...config,
            headers: {
                ...(config.credentials === 'include' && {
                    'X-Requested-With': 'XMLHttpRequest',
                    ...(user.access_token && { authorization: `Bearer ${user.access_token}` }),
                    'product-type': Product.Extensions
                }),
            },
        }

        const headerEntries = config?.headers?.entries?.() //temporary fix for bug: 67699
        if (headerEntries) {
            for (var pair of headerEntries) {
                configDefaults.headers[pair[0]] = pair[1]
            }
        }

        const correctedUrl = url.replace(/([^:]\/)\/+/g, "$1"); //code added for removing extra slash from url before api
        return [correctedUrl, configDefaults]
    },
    response: function (response) {
        if (response.status == 401) {
            response
                .clone()
                .json()
                .then(response => {
                    Loader.HideLoader()
                    VenusNotifier.Info(response.message, '')
                    window.location.reload()
                })
            return response
        } else if (response.status == 512) {
            response
                .clone()
                .json()
                .then(data => {
                    history.push(
                        {
                            pathname: '/restricted-access', 
                            state: {errorMessage: data?.message}
                        })
                })

            return response
        }
        else if (response.status == 419 && isURLExcluded(response.request.url)) {            
            const user = store.getState().auth;
            if (!user?.userPrivilegesChanged) {
                //force setting userAutoLogout
                setAutoLogout("1");
                response.clone().json().then(response => {
                    Loader.HideLoader();
                    store.dispatch(userAutoLogout(response.logoutCause as LogoutCause));
                });
            }            
        }
        else {
            return response
        }
    }
})
//This specific url excluded because of Telemetry logger auto intialization
const isURLExcluded = (url: string): boolean => {
    return !(
        (url.toLowerCase().includes(UserAutoLogoutConstants.ExcludedGetBasicDetails.toLowerCase()))
    );
}