import React from 'react'
import { IDragAndDropData } from '../DragAndDrop.model'
import GripVertical from 'src/components/svg/GripVertical';
import "./DraggableList.css"

interface DraggableList{
    listData:IDragAndDropData[]
    displayError?:boolean;
    errorText?:string;
    disabledTitle?:string;
    dndUniqueId:string;
    selectedList:IDragAndDropData[];
    setSelectedList:(selectedList:IDragAndDropData[])=>void;
    disableDragAndDrop: boolean;
    disableDestinationItems?: number[];
}

const DraggableList = ({
    listData=[],
    displayError=false,
    errorText="",
    disabledTitle="",
    dndUniqueId,
    selectedList,
    setSelectedList,
    disableDragAndDrop,
    disableDestinationItems
} :DraggableList) => {
    
   
    

    const putAllorEveryOneAtTop=(data:IDragAndDropData[])=>{
        let indexOfEveryoneOrAll= data.findIndex( datum => datum.value===0 )

        if(indexOfEveryoneOrAll>0)
        {
            const everyoneOrAll= data[indexOfEveryoneOrAll];
            data.splice(indexOfEveryoneOrAll,1);
            data.splice(0,0,everyoneOrAll)
        }
        return data;
    }

    const sortAlphabetically=(a:IDragAndDropData,b:IDragAndDropData)=>{
        if(a.title.toLowerCase()<b.title.toLowerCase()) return -1;
        if(b.title.toLowerCase()>b.title.toLowerCase())return 1;
        return 0; 
    }

    const checkDisablity = (datum: IDragAndDropData) => disableDestinationItems?.includes(Number(datum.value));
    
    const dragstart=(e:any,data:IDragAndDropData)=>{
        const newSelectedList:IDragAndDropData[]=[...selectedList,data];
        setSelectedList([...newSelectedList]);
        e.dataTransfer.setData("text/plain",JSON.stringify(newSelectedList.map((selectedItem)=>selectedItem.value)));
        e.dataTransfer.setData("dndUniqueId",dndUniqueId);
    }

    const dragEnd=()=>{
        setSelectedList([]);
    }

    const handleClick = (data:IDragAndDropData)=>{
        let newSelectedList:IDragAndDropData[] =[];
        if(selectedList.some((datum:IDragAndDropData)=>datum.value===data.value))
        {
            newSelectedList=selectedList.filter((datum:IDragAndDropData)=>datum.value!==data.value)
        }
        else{
            newSelectedList = [...selectedList,data]            
        }
        setSelectedList([...newSelectedList])
    }

  return (
    <div className='draggable-list-container'>
        {displayError?
            (<div className='error_div'>{errorText}</div>)
            :(
                putAllorEveryOneAtTop(listData.sort(sortAlphabetically)).map(
                    (datum:IDragAndDropData,index:number)=>{
                    const isDisabled=checkDisablity(datum)||disableDragAndDrop;
                    return (
                        <div className={`draggable-list-card
                        ${selectedList.findIndex((listItem: IDragAndDropData) => datum.value === listItem.value)!==-1?
                            "selected-card"
                            :""} 
                        ${isDisabled?"disabled-card":""}`  }
                        onDragStart={(e)=>dragstart(e,datum)}
                        onDragEnd={dragEnd}
                        onClick={()=>isDisabled? null: handleClick(datum)}
                        data-test-id={`${datum.title}${datum.value}`}
                        key={`${datum.value}${index}`}
                        draggable={!isDisabled}
                        title={isDisabled ? disabledTitle : datum.title}
                        data-dnduniqueid={dndUniqueId}
                        >
                            
                            <div className="grip-container">
                                    <GripVertical />
                                </div>
                                <div className="content-container"  >  
                                    {datum.name}
                                </div>
                        </div>
                    )
                })
            )
        }

    </div>
  )
}

export default DraggableList;