import React from  'react';
import { /*Panel,*/ Row, Col, Button, FormLabel } from 'react-bootstrap';
import { Card, Accordion } from 'react-bootstrap';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as TaxDocument from '../../../common/TaxReturn';

interface ISendToERO {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    sendToERO: () => void;
}

export class SendToERO extends React.Component<ISendToERO, {}> {
    public render() {
        return <div className="padT04">
            {
                <Accordion defaultActiveKey={"0"}>
                    <Card className="marR20 border-radius-04">
                            <Card.Body>
                        <Row className="marB15">
                            <Col sm={12}>
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-left" sm={12} as={FormLabel}>
                                ERO : {this.props.taxreturn.partner.firstName + " " + this.props.taxreturn.partner.lastName}
                            </Col>
                        </Row>
                        <div style={{ paddingTop: "70px" }}>
                            <Col sm={12}>
                                <Button type="button" variant="info" className="pull-right" data-test-auto="7F975ABC-E4A0-48FD-906C-DE6BF280D764"
                                    onClick={this.props.sendToERO}
                                ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                                    <Button type="button" variant="light" className="pull-right marR05" data-test-auto="E627BB57-5B04-482A-8EF1-8B8D0E7D8EA7"
                                    onClick={this.props.cancelFinish}
                                ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                            </Col>
                        </div>
                            </Card.Body>
                    </Card>
                </Accordion>
            }
        </div>
    }
}

export default SendToERO;