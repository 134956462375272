import React from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { CompanySettingConstants } from '../../helper/Constants';
import moment from "moment";
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface ISettingsHeader {
    handleApplyButton(event: any): void;
    header: string;
    showPopOver: boolean;
    expiryDate?: Date;
    handleDummyApplyButton?(event: any, callback: (count: number) => void): void;
    buttonLoader?: boolean;
    setButtonLoader?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISettingsHeaderState {
    showClosePopover: boolean;
    count: number
}

export class SettingsHeader extends React.Component<ISettingsHeader, ISettingsHeaderState>
{
    private closePopoverRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            showClosePopover: false,
            count: 0
        }
    }

    onApply = (event: any) => {
        this.props.handleApplyButton(event);
        this.setState({ showClosePopover: false });
    }

    onDummyApply = (e: any) => {
        if (this.props.handleDummyApplyButton) {
            this.props.handleDummyApplyButton(e, (count: number) => {
                this.showPopoverMessage(count);
                this.props.setButtonLoader && this.props.setButtonLoader(false);
            });
        }
    }

    showPopoverMessage = (count: number) => {
        this.setState({
            showClosePopover: true,
            count: count
        });
        this.props.setButtonLoader && this.props.setButtonLoader(false);
    }

    render() {

        const popover = (
            <Popover id="popover-basic" className="confirmation-popover">
                <Popover.Title as="h3">Confirm Apply</Popover.Title>
                <Popover.Content>
                    {CompanySettingConstants.closePopoverMessage.replace("%COUNT%", this.state.count.toString()).replace("%DATE%", this.props.expiryDate ? moment(this.props.expiryDate).format('MM-DD-YYYY') : "")}
                </Popover.Content>
                <div className="popover-footer">
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.onApply}
                        title="Yes"
                        data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                        style={{ marginLeft: "5px" }}>
                        Yes
                    </button>
                    <button
                        onClick={() => { this.setState({ showClosePopover: false }); }}
                        title="No"
                        data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                    </button>
                </div>
            </Popover>
        );

        const popoverApply = <div className={!this.props.header ? 'row' : 'row settings-info-header'}>
            <div className="col-md-8">
                <h3>{this.props.header}</h3>
            </div>
            <div className="col-md-4 txt-ar ">
                <Button
                    variant="info"
                    className=" marR05"
                    onClick={this.onDummyApply}
                    ref={(reference: any) => { this.closePopoverRef = reference }}
                    data-test-auto="39E31156-A0C2-40A9-8E52-AC85A456D14D">
                    <i className={this.props.buttonLoader ? "fas fa-spinner fa-spin text-white bg-transparent" : "fas fa-check"}></i> <span>Apply</span>
                </Button>
                <Overlay rootClose={true} placement="left"
                    onHide={() => { this.setState({ showClosePopover: false }) }}
                    target={this.closePopoverRef} show={this.state.showClosePopover}>
                    {popover}
                </Overlay>
            </div>
        </div>;

        const defaultApply = <div className={!this.props.header ? 'row' : 'row settings-info-header'}>
            <div className="col-md-8">
                <h3>{this.props.header}</h3>
            </div>
            <div className="col-md-4 txt-ar">
                <Button
                    variant="info"
                    className=" marR05"
                    onClick={this.props.handleApplyButton}
                    data-test-auto="39E31156-A0C2-40A9-8E52-AC85A456D14D"
                    data-resource-id={RBACKeys.Settings.settings}>
                    <i className="fas fa-check"></i>
                    Apply
                </Button>
            </div>
        </div>;

        return this.props.showPopOver ? popoverApply : defaultApply;
    }
}

export default SettingsHeader;
