import React from  'react';
import {    FormGroup, FormControl, Row, Col, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';




export interface IOrderList {
    id: number;
    value: string;
}

export const initialselectedOption: IOrderList = {
    id: 0,
    value: "None"
}


export interface IReorderListProps {
    listContent: IOrderList[];
    reorderList: (updatedList: IOrderList[]) => void;    
}


const Up = -1;
const Down = 1;


interface IReorderListState {
    selectedOption: IOrderList

}

export class ReorderList extends React.Component<IReorderListProps, IReorderListState> {

    constructor(props:any) {
        super(props);
        this.state = {
            selectedOption : initialselectedOption
        }

    }

    private onSelect = (selectedOption: IOrderList) => {
              this.setState({ selectedOption: selectedOption });
    }

    private handleMove = (direction: number) => {
        let items = this.props.listContent;

        if (this.state.selectedOption.value == "None")
        {
            // select value first.
            return;
        }

        let position = items.findIndex(m => m.id == this.state.selectedOption.id);

        let lastPosition = items.length - 1;

        if (position < 0)
        {
            //not found
        }
        else if (position === 0 && direction === Up || position === lastPosition && direction === Down)
        {
            //Invalid Move
        }
        else
        {
            let itemToBeMoved = items[position];
            let newItems = items.filter(m => m.id !== this.state.selectedOption.id);
            newItems.splice(position + direction, 0, itemToBeMoved);
            this.props.reorderList(newItems);
        }



    }


    public render() {
        return (
            <div className="row">
                <Col sm={5}>
                    <FormGroup>
                        <ul>
                            {this.props.listContent.map((value,index) => {
                                return <li key={value.id}
                                    className={this.state.selectedOption.id === value.id ? "activeSelected list-group-item " : "list-group-item "}
                                    style={{ padding: "4px 15px" }}
                                    onClick={() => this.onSelect(value)}> {value.value} </li>
                                })
                            }
                        </ul>
                    </FormGroup>
                </Col>  
                <Col sm={4}>
                    <NavLink href="javascript:" id="move-up-link" style={{ color: "#337ab7" }} onClick={(e: any) => { this.handleMove(Up) }}>
                        <i className='fa fa-caret-up fa-2x padR12' style={{ color: "#337ab7" }}></i>
                       Move Up
                    </NavLink>
                    <NavLink href="javascript:" id="move-down-link" style={{ color: "#337ab7", marginTop: "-10px" }} onClick={(e:any) => { this.handleMove(Down) }}>
                        <i className='fa fa-caret-down fa-2x padR12' style={{ color: "#337ab7"  }}></i>
                        Move Down
                    </NavLink>                   
                </Col>
                <Col sm={3}>
                    <Link to={'#'}
                        className="help-icon marL10 marT15"
                        data-placement="right"
                        data-toggle="tooltip"
                        title="Select a document type in the window. Use the Move Up and Move Down arrow keys to change the order of the documents ">
                        <i className="glyphicon glyphicon-question-sign" style={{ color: '#DAA520'}}></i>
                    </Link>
                </Col>
            </div>
        )
    }
}