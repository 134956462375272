import { ApplicationState } from "../../../store";
import * as CustomColumnStore from '../../../store/common/CustomColumnStore';
import * as ExtensionCompanyStore from '../../../store/settings/ExtensionCompanySettingsStore';
import { GeneralSettings } from "../GeneralSettings";
import { connect } from 'react-redux';



export default connect(
    (state: ApplicationState) => ({
        customColumnData: state.customColumn,
        extensionCompanyData : state.extensionCompanyData

    }),
    {
        ...CustomColumnStore.actionCreators,
        ...ExtensionCompanyStore.ActionCreators
    }
)(GeneralSettings as any);