var htmlencode = require('htmlencode');
import React from  'react';
import { MessageDetails } from '../../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { ReplaceHelperVariable } from '../../helper/HelperFunctions';
import { container } from '../../../Startup/inversify.config';
import { TYPES } from "../../../Startup/types";
import { IHtmlUtilities } from '../../../Core/Utilities/HtmlUtilities';

interface IMessageViewProps {
    selectedMessageDetails: MessageDetails;
    defaultMessageId: number;
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

export class MessageView extends React.Component<IMessageViewProps, {}>{
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div>
                <div className="form-group">
                    <label>Message Name{this.props.selectedMessageDetails.id == this.props.defaultMessageId ?
                        <span className="badge" style={{ marginLeft: 10, marginTop: -1 }}>Default</span> : <span></span>}
                    </label>
                    <div className="form-control" style={{ backgroundColor: '#f1f1f1' }}
                        dangerouslySetInnerHTML={{ __html: (this.props.selectedMessageDetails.name) }} />
                </div>
                <div className="form-group">
                    <label >Message Body</label>
                    <div className="form-control" style={{ overflowY: 'auto', height: 100, backgroundColor: '#f1f1f1' }}
                        dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(ReplaceHelperVariable(htmlencode.htmlDecode(this.props.selectedMessageDetails.body))) }} />
                </div>
                {this.props.selectedMessageDetails.isAllowToEdit ? <div className="form-group">
                    <i className="fas fa-check" style={{ color: '#337ab7' }}></i>
                    <label style={{ marginLeft: 5 }} >Allow users to edit before sending</label>
                </div> : ""}
            </div>
        )
    }
}