import React from 'react';
import { RouteComponentProps } from 'react-router';
import { VenusNotifier } from '../../helper/VenusNotifier';
import {
    SendGroupedReturnsConstants, ProcessReturnConstants, AssignUserConstants,
    SendGroupedReturnsHeaderConstants,
    ReProcessReturnConstants,
    ChangeStatusConstants,
    DeleteTaxReturnConstants,
    GroupExtensionConstants
} from '../../helper/Constants';
import { SendGroupedExtensionsHeader } from './GroupedExtensionsComponents/SendGroupedExtensionsHeader';
import { SendGroupedExtensionsTable } from './GroupedExtensionsComponents/SendGroupedExtensionsTable';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { initialCompanySettings, ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as FilterStore from '../../../store/reports/FilterStore';
import {
    DocumentStatus,
    ITaxReturn, initialTaxReturnState
} from '../../common/TaxReturn';
import * as SendGroupedReturnsStore from '../../../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsStore';
import * as Helper from '../../helper/HelperFunctions';
import * as GroupedExtensionsStore from './../../../store/groupedExtensions/GroupedExtensionsStore';
import * as GroupedExtensionsProcessStore from '../../../store/groupedExtensions/GroupedExtensionsProcessStore';
//import { GroupProcessReturnModal, IGroupProcessReturnProps } from '../../common/GroupLevelReturnProcess/GroupProcessReturnModal';
import * as bootbox from 'bootbox';
import * as SendGroupedReturnsHeaderStore from '../../../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsHeaderStore';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { AssignModal } from '../../common/AssignModal';
import * as GroupedTaxDocumentStore from '../../../store/groupedExtensions/GroupedTaxDocumentStore';
import * as AttachmentsStore from '../../../store/ProcessReturn/AttachmentsStore';
import * as AttachmentsState from '../../../store/ProcessReturn/AttachmentsState';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as UserSignatureStore from '../../../store/common/UserSignatureStore';
import * as UploadStore from '../../../store/common/Upload';
import * as VoucherStore from '../../../store/ProcessReturn/voucher/VoucherStore';
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import * as DeliveredReturnsStore from '../../../store/reports/DeliveredReturnsStore';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';
import * as DownloadStore from '../../../store/common/Download';
import * as BusinessReturnsStore from '../../../store/settings/BusinessReturnsStore';
import * as CustomAuthorityStore from '../../../store/common/CustomAuthorityStore';
import { ICustomAuthorityState } from '../../../store/common/CustomAuthorityStore';
import * as AdditionalEsignDocumentStore from '../../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as AdditionalDocumentTypeStore from '../../../store/common/AdditionalDocumentTypeStore';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ReportProblemModal } from '../../common/ReportProblemModal';
import ClientInfo from '../../common/ClientInfo';
import { GroupedIndividualChangeStatusModal } from './GroupedExtensionsComponents/GroupedIndividualChangeStatusModal';
import { errorMsg, successMsg } from './../../assignments/Assignments';
import UploadRecalledDocumentModal from '../../common/UploadDocument/UploadRecalledDocumentModal';
import { IReportProblemDetails, ReportedStep, ReportProblemTypes } from '../../common/ReportProblem/ReportProblemModel';
import * as TaxDocument from '../../common/TaxReturn';
import { HideLoader, ShowLoader } from '../../helper/Loader';
import {
    GroupConfigurationStateMultiple,
    GroupedReturnsModel,
    GroupInfo,
    GroupReturnPopupState,
    initialGroupInfo,
    PopupState,
    PopupStateGroupController,
    PopupStateGroupInfo,
    PopupStateMultiple,
    PopupStateTaxReturnSingle,
    SendGroupedReturnsModel,
    SetAccessType,
    SelectOptions,
    GroupedTaxDocumentDictionary,
    HeaderGroupAccess,
    initialGroupedReturnsModel
} from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { GetEnableEsignatureForBusinessReturns } from '../../common/ProcessReturnModal/SignatureControls/SignatureHelper';
import { IFilters, ReportFilterType, SortDirections } from '../../reports/Filters';
import ControllerInfoComponent from '../Common/ControllerInfo';
import { EditGroupInfoModal } from './GroupedExtensionsComponents/EditGroupInfoModal';
import { SetAccessModal } from './GroupedExtensionsComponents/SetAccessModal';
import { GroupedExtensionsProcessStoreState, GroupedExtensionStoreState, SendGroupedExtensionsHeaderStoreState, SendGroupedExtensionsStoreState } from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import { initialSuiteTaxSoftwareSettings } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import * as TaxSoftwareStore from '../../../store/SuiteCompany/TaxSoftwareStore'
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import * as ExtensionUserSettingsStore from '../../../store/userManagement/ExtensionUserSettingStore';
import { IAuthState } from 'src/store/auth/reducer';
import { RBACKeys } from 'src/components/helper/RbacConstants';

const pageSize = 20;
const NO_INDEX = -1;
let interval: any;

type SendGroupedExtensionsProps =
    {
        sendGroupedReturns: SendGroupedExtensionsStoreState;
        usersStore: UserStore.UsersStore;
        reportFilters: FilterStore.IFilterState;
        userSettings: UserSettingStore.UserSettings;
        company: CompanyStore.ICompanyData;
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
        pageTitle: string;
        groupedReturnStore: GroupedExtensionStoreState;
        sendGroupedReturnsHeaderData: SendGroupedExtensionsHeaderStoreState;
        profile: IUserProfile;
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
        groupedReturnProcessState: GroupedExtensionsProcessStoreState;
        groupedTaxDocuments: GroupedTaxDocumentDictionary;
        attachments: AttachmentsState.AttachmentsState;
        userSignatures: UserSignatureStore.IUserSignatures;
        pdfDocuments: PdfStore.IPdfDocumentDictionary;
        uploadStore: UploadStore.IUploadState;
        voucherStore: VoucherStore.IVoucherState;
        mailingReturnAddresses: MailingReturnAddressStore.IMailingReturnAddressStoreState;
        savedMessages: SavedMessageStore.SavedMessageStoreState;
        downloadStore: DownloadStore.IDownloadState;
        businessReturnInstructions: BusinessReturnsStore.IBusinessReturnsState;
        customAuthorities: ICustomAuthorityState;
        additionalEsignDocument: AdditionalEsignDocumentStore.IBlobFile;
        additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
        taxSoftwareData: SuiteTaxSoftwareSettingsStoreState;
        extensionUserSettings: ExtensionUserSettingsStore.IExtensionUserSettings;
        auth: IAuthState;
    }
    & typeof SendGroupedReturnsStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof FilterStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UploadTaxReturnStore.actionCreators
    & typeof GroupedExtensionsStore.actionCreators
    & typeof GroupedExtensionsProcessStore.actionCreators
    & typeof SendGroupedReturnsHeaderStore.actionCreators
    & typeof PdfStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof GroupedTaxDocumentStore.actionCreators
    & typeof AttachmentsStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSignatureStore.actionCreators
    & typeof UploadStore.actionCreators
    & typeof VoucherStore.actionCreators
    & typeof MailingReturnAddressStore.actionCreators
    & typeof DeliveredReturnsStore.actionCreators
    & typeof SavedMessageStore.actionCreators
    & typeof DownloadStore.actionCreators
    & typeof BusinessReturnsStore.actionCreators
    & typeof CustomAuthorityStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof AdditionalDocumentTypeStore.actionCreators
    & typeof TaxSoftwareStore.actionCreators
    & typeof ExtensionUserSettingsStore.actionCreators
    & RouteComponentProps<{ page: string }>;

export interface SendGroupedExtensionsPageState {

    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: IFilters;
    searchString: string;
    sortName: string;
    sortOrder: string;
    saveFilterShow: boolean;
    deSelectedRows: number[];
    isSelectAllChecked: boolean;
    defaultGroup: string;

    filterGroupName: string;
    filterSoftwareType: string;
    filterCreatedBy: string;
    filterSetAccess: SetAccessType;
    filterCreatedOn?: Date;

    isBulkSelectionEnabled: boolean;
    showBulkSelectionMessage: boolean;

    refreshDelay: boolean;

    selectedDocuments: number[];

    //Popups
    setAccessState: PopupStateMultiple;
    showAssignModal: boolean;
    onAssignSuccessDocumentList: number[];
    reportProblemState: PopupStateTaxReturnSingle;
    documentSignatureType: string;
    processReturnWindows: PopupState;
    processReturnModalLoader: boolean;
    reProcessReturnModalLoader: boolean;
    groupProcessReturnModalLoader: boolean;
    processingDocumentId: number;

    editGroupControllerInfo: GroupReturnPopupState;
    groupId: number;
    groupProcessReturnWindow: {
        selectedGroupId: number;
        selectedGroupName: string;
        taxReturns: ITaxReturn[];
        show: boolean;
    }
    editClientInfoState: PopupStateTaxReturnSingle;
    changeStatusState: PopupStateTaxReturnSingle;
    showLoader: boolean;
    defaultAssignUser: number;

    isGroupedReportProblem: boolean;
    groupReportModel: ITaxReturn;
    editControllerInfoState: PopupStateGroupController;
    recallReturnState: PopupStateTaxReturnSingle;
    editGroupInfoState: PopupStateGroupInfo;

    groupConfigurationState: GroupConfigurationStateMultiple;
    addedGroup: GroupInfo;
    selectedGroupId: number;
    previewReadyForDeliverySelection: SelectOptions[];
    selectedReturnDocumentIds: number[];
}

export class SendGroupedExtensions extends React.Component<SendGroupedExtensionsProps, SendGroupedExtensionsPageState> {
    private proxyFilter: any;
    constructor(props: SendGroupedExtensionsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: ({
                name: '',
                searchText: '',
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.SendGroupedExtensions,
                isMasterFilter: false,
            }),
            selectedRows: [],
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            saveFilterShow: false,
            deSelectedRows: [],
            isSelectAllChecked: false,

            filterCreatedBy: "",
            filterGroupName: "",
            filterSetAccess: SetAccessType.None,
            filterSoftwareType: "",

            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,

            refreshDelay: false,
            defaultGroup: '',
            editGroupControllerInfo: {
                groupId: -1,
                show: false,
            },
            groupProcessReturnWindow: {
                selectedGroupId: -1,
                selectedGroupName: '',
                taxReturns: [initialTaxReturnState],
                show: false
            },
            selectedDocuments: [],

            //SetAccess States
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false
            },
            //Report a Problem states
            reportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },

            showAssignModal: false,
            onAssignSuccessDocumentList: [],

            processReturnWindows: {
                show: false
            },
            processReturnModalLoader: false,
            reProcessReturnModalLoader: false,
            groupProcessReturnModalLoader: false,
            processingDocumentId: 0,

            //EditClientInfo states
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //ChangeStatus states
            changeStatusState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            showLoader: false,
            defaultAssignUser: 0,
            groupId: 0,
            isGroupedReportProblem: false,
            groupReportModel: { id: 0, clientId: "" } as ITaxReturn,
            editControllerInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            editGroupInfoState: {
                show: false,
                model: undefined
            },
            //Recall Return
            recallReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            groupConfigurationState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            addedGroup: Object.assign({}, initialGroupInfo),
            selectedGroupId: 0,
            previewReadyForDeliverySelection: [],
            documentSignatureType: "",
            selectedReturnDocumentIds: []
        }
        this.handleProcessReturnOpen = this.handleProcessReturnOpen.bind(this);
        this.processReturnModalSetUp = this.processReturnModalSetUp.bind(this);
        this.handleRequest = this.handleRequest.bind(this);
        this.handleProcessReturnCancel = this.handleProcessReturnCancel.bind(this);

        this.onGroupConfigurationOpen = this.onGroupConfigurationOpen.bind(this);
        this.onGroupCreateNew = this.onGroupCreateNew.bind(this);
        this.onGroupConfigurationCancel = this.onGroupConfigurationCancel.bind(this);
        this.onGroupConfigurationSaveCompletion = this.onGroupConfigurationSaveCompletion.bind(this);
        this.mapReturntoGroup = this.mapReturntoGroup.bind(this);
        this.onAddtoExistingGroup = this.onAddtoExistingGroup.bind(this);
        this.UnMapGroupedReturns = this.UnMapGroupedReturns.bind(this);
    }

    componentWillMount() {
        this.props.requestAllUsers(true);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.props.getAllReportFilter(true, ReportFilterType.SendGroupedExtensions);
        this.props.requestTaxingAuthorities();
        this.props.requestCompanySettings();
        this.props.requestSendGroupedReturns(this.buildQuery(this.state.page, pageSize), true);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestCompanySignatureUploadLink();
        this.props.requestSuiteTaxSoftwareSettings();
        this.props.requestExtensionUserSettings();
        //this.props.requestPaperFiledMessages(true);
    }

    componentWillReceiveProps(nextProps: SendGroupedExtensionsProps) {
        this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps);
    }

    onRequestUserSettingsSuccess = () => {
        const defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == '') {
            this.loadGroupedReturns();
        }
    }


    //======================Filter Operation Starts here=================================

    private applyDefaultFilter = (filter: IFilters) => {
        this.setState({ filter: filter },
            () => this.fetchSendGroupedReturns(1, pageSize))
    }



    private onFilterChange = (dataField: any, filterType: ReportFilterType) => {

        const newState: SendGroupedExtensionsPageState = {
            filterCreatedBy: "",
            filterGroupName: "",
            filterSetAccess: SetAccessType.None,
            filterSoftwareType: "",
            filterCreatedOn: undefined,
        } as SendGroupedExtensionsPageState;

        let isClearFilter = true;
        var dict: { [columnName: string]: string; } = {};

        for (const field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data;

            switch (field) {
                case 'createdBy':
                    newState.filterCreatedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'groupName':
                    newState.filterGroupName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'setAccess':
                    newState.filterSetAccess = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxSoftware':
                    newState.filterSoftwareType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'createdOn':
                    newState.filterCreatedOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
            }
        }
        const tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize, selectedRows: [], selectedDocuments: [], deSelectedRows: [], selectedReturnDocumentIds: [] },
                () => {
                    const sendGroupedReturnsTable: any = this.refs.SendGroupedReturnsTable;
                    if (!sendGroupedReturnsTable.isAppliedFilter)
                        this.props.requestSendGroupedReturns(this.buildQuery(this.state.page, this.state.pageSize))
                })
        }
    }

    private isFilterChanged = (newState: SendGroupedExtensionsPageState): boolean => {
        return (
            newState.filterCreatedBy !== this.state.filterCreatedBy ||
            newState.filterCreatedOn !== this.state.filterCreatedOn ||
            newState.filterGroupName !== this.state.filterGroupName ||
            newState.filterSetAccess !== this.state.filterSetAccess ||
            newState.filterSoftwareType !== this.state.filterSoftwareType);

    }

    onFilterSave = (onApplyFilter: (filter: IFilters) => void) => {
        if (this.state.filter.name.trim() != '') {
            const filter = this.props.reportFilters.filters.find((x: any) => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(SendGroupedReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });

            }
        }
        else {
            VenusNotifier.Warning(SendGroupedReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate = (name: string) => {
        const filter = this.props.reportFilters.filters.find((x: any) => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(SendGroupedReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }

    onSetDefaultFilterSuccess = () => {
        VenusNotifier.Success(SendGroupedReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onSetDefaultFilter = (name: string, filterType: ReportFilterType) => {
        this.props.addDefaultFilter(name, filterType, () => { this.onSetDefaultFilterSuccess(); });
    }

    onRemoveDefaultFilterSuccess = () => {
        VenusNotifier.Success(SendGroupedReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter = (name: string, filterType: ReportFilterType) => {
        this.props.removeDefaultFilter(name, filterType, () => { this.onRemoveDefaultFilterSuccess(); });
        const sendGroupedReturnsTable: any = this.refs.SendGroupedReturnsTable;
        sendGroupedReturnsTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload();
    }

    onFilterDelete = (filterName: string, filterType: ReportFilterType) => {
        this.props.deleteReportFilter(filterName, filterType);
    }

    onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true
        });
    }

    private getDefaultFilter = (filters: IFilters[]): string | undefined => {

        const existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        const existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            const mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        }
        else if (existsDefaultFilter) {
            const dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    }

    private onFilterNameChange = (event: any) => {
        const temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }


    //======================Filter Operation Ends here=================================

    //private getDocumentSignatureType = (id: number, selectedDocumentIds: number[]): any => {
    //    this.setState({ selectedReturnDocumentIds: selectedDocumentIds });
    //    if (id === -1) {
    //        this.updateDocumentSignatureType(selectedDocumentIds);
    //        return;
    //    }
    //    var returnIndex = this.state.groupProcessReturnWindow.taxReturns.findIndex(x => x.id === id);
    //    if (returnIndex != NO_INDEX && this.state.groupProcessReturnWindow.taxReturns[returnIndex].documentSettings == undefined) {
    //        ShowLoader();
    //        this.props.requestGrouLevelTaxDocument(id, this.documentSignatureTypeCallback);
    //    }
    //    else {
    //        this.updateDocumentSignatureType(selectedDocumentIds);
    //    }
    //}

    //private documentSignatureTypeCallback = (data: ITaxReturn): any => {
    //    HideLoader();
    //    this.state.groupProcessReturnWindow.taxReturns.forEach(
    //        (taxreturnItem) => {
    //            if (data.id == taxreturnItem.id) {
    //                taxreturnItem.documentSettings = data.documentSettings;
    //            }
    //        });

    //    this.updateDocumentSignatureType(this.state.selectedReturnDocumentIds);
    //}

    //private updateDocumentSignatureType = (selectedDocumentIds: number[]): void => {
    //    const isManualSignReturnExist: boolean = false;
    //    for (const taxReturn of this.state.groupProcessReturnWindow.taxReturns) {
    //        const index = selectedDocumentIds.findIndex(x => x == taxReturn.id);
    //        if (index != NO_INDEX && taxReturn.documentSettings) {
    //            if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ManualSign) {
    //                isManualSignReturnExist = true;
    //                break;
    //            }
    //            else if (Helper.isIndividualEngagementType(taxReturn.engagementType) &&
    //                taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed) {

    //                const enableESignature = GetEnableEsignatureForBusinessReturns(
    //                    this.props.extensionCompanySettings,
    //                    Helper.isBusinessReturn(taxReturn));

    //                const eFileInfo = new EfileInfoView(taxReturn);

    //                const efileBookmarks = eFileInfo.getBookmarks(
    //                    this.props.company.taxingAuthorities,
    //                    taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType,
    //                    enableESignature);

    //                if (efileBookmarks[0].pages.some(i => i.pageTitle == 'New York')) {
    //                    isManualSignReturnExist = true;
    //                    break;
    //                }
    //            }
    //        }
    //    }


    //    const signatureType = isManualSignReturnExist ? SignatureType[SignatureType.ManualSign] : SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed];
    //    if (signatureType != this.state.documentSignatureType) {
    //        this.setState({
    //            documentSignatureType: signatureType,
    //            groupProcessReturnWindow: this.state.groupProcessReturnWindow
    //        });
    //        var groupReturnModel: GroupedReturnsModel = this.getGroupedReturnsModel(this.state.groupProcessReturnWindow.selectedGroupId);
    //        groupReturnModel.groupInfo.groupSettings.groupSignatureSettings.
    //            signatureFormSelectionType = isManualSignReturnExist ? SignatureType.ManualSign : SignatureType.ESignWhenAllIncludedReturnsAllowed;
    //    }
    //}

    //private setDocumentSignatureType = (documentSinagtureType: string): any => {
    //    this.setState({ documentSignatureType: documentSinagtureType });
    //}

    private selectedGroupIds = () => {
        const ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            const selectedGroup = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[row];
            if (selectedGroup) {
                ids.push(selectedGroup.groupId);
            }
        });
        return ids;
    }

    private selectedGroups = () => {
        const selectedGroups: SendGroupedReturnsModel[] = [];
        this.state.selectedRows.map((row, i) => {
            const selectedGroup = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[row];
            if (selectedGroup) {
                selectedGroups.push(selectedGroup);
            }
        });
        return selectedGroups;
    }

    private selectedDocuments = () => {
        const selectedDocuments: ITaxReturn[] = [];
        this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions?.forEach((groupedReturn: any) => {
            groupedReturn.taxReturns.forEach((taxReturn: any) => {
                const index = this.state.selectedDocuments.findIndex(x => x == taxReturn.id);
                if (index != NO_INDEX) {
                    selectedDocuments.push(taxReturn);
                }
            });
        });
        return selectedDocuments;
    }

    private onPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[rowIndex].groupId];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedGroupIds();
    }

    loadGroupedReturns = () => {
        const queryString = this.buildQuery(this.state.page, this.state.pageSize);

        this.setState({
            selectedDocuments: [],
            deSelectedRows: [],
            selectedReturnDocumentIds: []
        },
            () => { this.props.requestSendGroupedReturns(queryString); });
    }

    buildQuery = (page: number, pageSize: number) => {
        return '?PageNo=' + page
            + '&PageSize=' + pageSize
            + '&SortBy=' + this.state.sortName
            + '&SortOrder=' + this.state.sortOrder
            + '&SearchString=' + this.state.searchString
            + '&filterCreatedBy=' + this.state.filterCreatedBy
            + '&filterCreatedOn=' + (this.state.filterCreatedOn || "")
            + '&filterGroupName=' + this.state.filterGroupName
            + '&filterSetAccess=' + this.state.filterSetAccess
            + '&filterSoftwareType=' + this.state.filterSoftwareType;
    }

    //======================Grid Operation Starts here===============================

    private onExportToExcel = (onExportToExcelComplete: () => void) => {
        const queryString = this.buildQuery(this.state.page, pageSize);
        this.props.exportGroupedReturnsAsExcel(queryString, onExportToExcelComplete);
    }

    private onRowSelect = (row: any, isSelected: any, e: any) => {
        const newList = [...this.state.selectedRows];
        const deSelectedRows = this.state.deSelectedRows;
        const selectedDocuments = [...this.state.selectedDocuments];

        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {

                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    index = deSelectedRows.indexOf(row.id);
                    deSelectedRows.splice(index, 1);
                }
                const documentIds = this.getTaxDocumentIds(row.groupId as number);
                if (documentIds && documentIds.length > 0) {
                    documentIds.map((id, i) => {
                        const docIndex = selectedDocuments.findIndex(x => x === id);
                        if (docIndex === NO_INDEX) {
                            selectedDocuments.push(id);
                        }
                    });
                }
            }
            else {

                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.id);
                }
                const documentIds = this.getTaxDocumentIds(row.groupId as number);
                if (documentIds && documentIds.length > 0) {
                    documentIds.map((id, i) => {
                        const docIndex = selectedDocuments.findIndex(x => x === id);
                        if (docIndex !== NO_INDEX) {
                            selectedDocuments.splice(docIndex, 1);
                        }
                    });
                }
            }
            this.state.isBulkSelectionEnabled ?
                this.setState({
                    deSelectedRows: deSelectedRows, selectedRows: newList,
                    selectedDocuments: selectedDocuments
                }) :
                this.setState({
                    selectedRows: newList,
                    selectedDocuments: selectedDocuments
                });
        }
        this.forceUpdate();
    }

    private onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;

        if (isSelected) {
            const count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());

            showBulkSelectionMessage = true;
        }

        this.setState({ isSelectAllChecked: isSelected });

        this.setState({
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false
        });
    }

    private onPageChange = (page: number, sizePerPage: number) => {
        if (this.state.isBulkSelectionEnabled) {

            this.setState({
                page: page
            }, this.loadGroupedReturns)
        }
        else {
            this.setState({
                page: page,
                selectedRows: [],
                showBulkSelectionMessage: false,
            }, this.loadGroupedReturns);
        }

    }

    private onSortChange = (sortName: string, sortOrder: string, column: number) => {
        const temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchSendGroupedReturns(1, pageSize));
    }

    private onSearchChange = (searchString: string) => {
        const temp: IFilters = { ...this.state.filter };
        temp.searchText = searchString;
        this.clearSelection();
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchSendGroupedReturns(1, pageSize));
    }

    private onPageReload = () => {
        const query = this.buildQuery(this.state.page, this.state.pageSize);

        this.setState({
            selectedDocuments: [],
            deSelectedRows: [],
            selectedReturnDocumentIds: []
        },
            () => { this.props.requestSendGroupedReturns(query, true); });

        this.clearSelection();
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        const showBulkSelectionMessage = false;

        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    private onClearFilter = () => {
        this.setState({
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            filterCreatedBy: "",
            filterGroupName: "",
            filterSetAccess: SetAccessType.None,
            filterSoftwareType: "",
            filterCreatedOn: undefined,
        }, this.onPageReload);
    }

    private handleGroupLevelReturnOpen = (rowIndex: number) => {
        if (this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions) {
            const selectedValue = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[rowIndex];

            this.setState({
                groupProcessReturnModalLoader: true,
            });
            this.props.requestGroupLevelData(selectedValue.groupId, true, () => {
                const taxReturns = this.props.groupedReturnProcessState[selectedValue.groupId].groupLevelReturnsData.taxReturns as ITaxReturn[];
                this.setState({
                    groupProcessReturnWindow: {
                        selectedGroupId: selectedValue.groupId,
                        selectedGroupName: selectedValue.groupName,
                        taxReturns: taxReturns,
                        show: true,
                    }
                }, () => {
                    this.processGroupedReturnModalSetUp();
                })
            });
        }
    }
    private onMarkReadyForDelivery = (returnId: number) => {
        ShowLoader();
        this.props.requestGroupedTaxDocument(returnId, true, undefined, undefined, this.markReadyForDelivery);
    }

    markReadyForDelivery = (taxReturn: ITaxReturn) => {
        if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.profile.userId) {
            // locked, assigned to other user 
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
            HideLoader();
            return;
        }
        else {
            this.props.markAsReadyForDelivery(taxReturn.id, this.updateGroupReturn);
        }
    }
    private updateGroupReturn = (returnId: number) => {
        var groupReturnModel: GroupedReturnsModel = this.getGroupedReturnsModel(this.state.groupProcessReturnWindow.selectedGroupId);

        if (this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions) {
            var returnIndex = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.findIndex((x: any) => x.groupId === groupReturnModel.groupInfo.id);
            const selectedValue = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[returnIndex];

            if (selectedValue != undefined) {
                this.setState({
                    groupProcessReturnWindow: {
                        selectedGroupId: groupReturnModel.groupInfo.id,
                        selectedGroupName: groupReturnModel.groupInfo.name,
                        taxReturns: selectedValue.taxReturns,
                        show: true,
                    }
                })
            }
        }
        this.SetTaxReturnSelectOptions();
        HideLoader();
    }

    SetTaxReturnSelectOptions = () => {
        var taxSelection: SelectOptions[] = [];
        this.state.groupProcessReturnWindow.taxReturns.map((v, i) => {
            if (v.documentStatus === DocumentStatus.READYFORDELIVERY || v.documentStatus === DocumentStatus.REVIEW || v.documentStatus === DocumentStatus.APPROVEDFORDELIVERY) {
                taxSelection.push({
                    label: TaxDocument.getClientName(v),
                    value: v.id,
                })
            }
        });
        this.setState({
            previewReadyForDeliverySelection: taxSelection
        });
    }

    processGroupedReturnModalSetUp = () => {
        this.props.requestSavedMessages();
        this.setState({
            groupProcessReturnModalLoader: false
        });
    }

    private handleSendGroupReturnCancel = (id: number) => {
        this.setState({
            groupProcessReturnModalLoader: false,
            groupProcessReturnWindow: {
                selectedGroupId: -1,
                selectedGroupName: '',
                taxReturns: [],
                show: false
            },
            previewReadyForDeliverySelection: []
        });
    }

    private handleSendGroupReturnSaveAndClose = (groupedReturnsModel: GroupedReturnsModel) => {
        //this.props.saveGroupLevelReturnData(groupedReturnsModel);
        this.setState({
            ...this.state,
            groupProcessReturnWindow: {
                selectedGroupId: -1,
                selectedGroupName: '',
                taxReturns: [],
                show: false
            },
            previewReadyForDeliverySelection: []
        });

    }

    private handleSendGroupReturnClose = () => {
        this.setState({
            ...this.state,
            groupProcessReturnWindow: {
                selectedGroupId: -1,
                selectedGroupName: '',
                taxReturns: [],
                show: false
            },
            previewReadyForDeliverySelection: []
        });
    }

    private clearSelection = () => {
        this.setState({ selectedRows: [], deSelectedRows: [], isBulkSelectionEnabled: false, showBulkSelectionMessage: false });
    }

    fetchSendGroupedReturns(page: number, sizePerPage: number) {
        const queryString = this.buildQuery(page, sizePerPage);
        this.setState({
            page: page,
            pageSize: sizePerPage,
            selectedDocuments: [],
            deSelectedRows: [],
            selectedReturnDocumentIds: []
        },
            () => { this.props.requestSendGroupedReturns(queryString) });
    }

    onDocumentSelect = (Ids: number[]) => {
        const newList = [...this.state.selectedDocuments];
        Ids.map((value, index) => {
            newList.push(value);
        });
        this.setState({
            selectedDocuments: newList
        });
    }

    onDocumentUnSelect = (Ids: number[]) => {
        const newList = [...this.state.selectedDocuments];
        Ids.map((value, index) => {
            var docIndex = newList.indexOf(value);
            if (docIndex > -1) {
                newList.splice(docIndex, 1);
            }
        });
        this.setState({
            selectedDocuments: newList
        });
    }

    private getGroupedReturnsModel = (groupId: number): GroupedReturnsModel => {
        if (this.props.groupedReturnProcessState[groupId]
            && this.props.groupedReturnProcessState[groupId].groupLevelReturnsData)
            return this.props.groupedReturnProcessState[groupId].groupLevelReturnsData;
        return initialGroupedReturnsModel;
    }

    //======================Grid Operation Ends here=================================

    //======================Grid Header Action Starts here===========================
    private onUnMapGroupedReturnsOpen = (rowIndex: number = NO_INDEX) => {
        bootbox.confirm({
            title: SendGroupedReturnsConstants.HeaderActions.UnGroup.Title,
            message: SendGroupedReturnsConstants.HeaderActions.UnGroup.ConfirmationMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.UnMapGroupedReturns();
                }
            }
        });
    }



    //======================Grid Header Action Ends here===========================

    //====================SetAccess Starts Here=======================================

    private onSetAccessOpen = (rowIndex: number = NO_INDEX) => {
        const ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select group(s) to set access for other users!", null);
            return;
        }
        this.props.requestAllUsers(false);
        this.props.requestGroupAccessByGroupIds(ids);

        this.setState({
            setAccessState: {
                show: true,
                selected: this.selectedGroups(),
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private onSetAccessSave = (userIds: number[]) => {
        const ids = this.selectedGroupIds();
        const accessMaps: HeaderGroupAccess[] = [];
        ids.forEach((id, i) => {
            accessMaps.push({
                groupId: id,
                userId: userIds,
            } as HeaderGroupAccess);
        });
        this.props.updateGroupAccess(accessMaps, this.onSetAccessSuccess);
    }

    private onSetAccessCancel = () => {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            }
        });
    }

    private onSetAccessSuccess = () => {
        this.props.requestGroupAccess(true);
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false
            },
            selectedRows: []
        }, this.onPageReload);
    }

    //====================SetAccess Ends Here=======================================

    //====================Assign Ends Here=======================================

    onAssignOpen = (rowIndex: number = NO_INDEX) => {
        const ids = this.state.selectedDocuments;
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        }
        else {
            this.handleAssignOpenRequest(ids);
        }
    }

    handleAssignOpenRequest = (ids: number[]) => {

        if (ids.length == 0) {
            VenusNotifier.Warning(AssignUserConstants.SelectDocumentWarning, null);
            return;
        } else if (this.selectedDocuments().find(x => x.documentStatus === DocumentStatus.RECALLED)
            && this.selectedDocuments().some(x => x.assignTo != this.props.profile.userId)
            && !Helper.isAdmin(this.props.auth.user)) {
            VenusNotifier.Warning(AssignUserConstants.RecalledReturnWarning, null);
            return;
        }

        this.setState({ showAssignModal: true });
    }

    getDefaultAssignUser = () => {
        const selectedDocuments = this.selectedDocuments();
        if (selectedDocuments && selectedDocuments.length == 1) {
            selectedDocuments[0].assignTo;
        }
        return 0;
    }

    checkIfReturnIsInUse = (ids: number[]) => {
        let result = false;
        const listItem: number[] = [];
        ids.forEach((id) => {
            this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.forEach((groupedReturn: any) => {
                groupedReturn.taxReturns.forEach((taxReturn: any) => {
                    if (taxReturn.id === id) {
                        if (this.handleIfReturnIsInUseRequest(taxReturn, this.props.profile)) {
                            listItem.push(1);
                        }
                        else {
                            listItem.push(0);
                        }
                    }
                });
            });

        })
        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }

    private checkIfReturnIsNotAssignToLoggedinUser = (ids: number[]) => {
        let result = false;
        const listItem: number[] = [];
        ids.forEach((id) => {
            this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.forEach((groupedReturn: any) => {
                groupedReturn.taxReturns.forEach((taxReturn: any) => {
                    if (taxReturn.id === id) {
                        if (taxReturn.documentStatus === DocumentStatus.RECALLED) {
                            if (Helper.isAdmin(this.props.auth.user)) {
                                listItem.push(0);
                            } else if (this.props.profile.userId == taxReturn.assignTo) {
                                listItem.push(0);
                            }
                            else {
                                listItem.push(1);
                            }
                        }
                        else if (this.handleIfReturnIsNotAssignToLoggedinUserRequest(taxReturn, this.props.profile)) {
                            listItem.push(1);
                        }
                        else {
                            listItem.push(0);
                        }
                    }
                });
            });
        })

        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }

    handleIfReturnIsNotAssignToLoggedinUserRequest = (taxReturn: ITaxReturn, profile: IUserProfile) => {
        if (taxReturn.documentStatus !== DocumentStatus.READY &&
            taxReturn.lockedBy <= 0 &&
            taxReturn.assignTo !== profile.userId &&
            !Helper.isAdmin(this.props.auth.user)) {
            return true;
        }
        else if (taxReturn.documentStatus !== DocumentStatus.READY &&
            taxReturn.lockedBy > 0 &&
            taxReturn.assignTo !== profile.userId &&
            !Helper.isAdmin(this.props.auth.user)) {
            return true;
        }
        return false;
    }

    handleIfReturnIsInUseRequest = (taxReturn: ITaxReturn, profile: IUserProfile) => {
        if (taxReturn.documentStatus !== DocumentStatus.READY &&
            taxReturn.lockedBy > 0 &&
            taxReturn.assignTo !== profile.userId &&
            Helper.isAdmin(this.props.auth.user)) {
            return true;
        }
        else if (taxReturn.documentStatus !== DocumentStatus.READY &&
            taxReturn.lockedBy > 0 &&
            taxReturn.assignTo === profile.userId) {
            return true;
        }

        return false;
    }

    private onAssignCancel = () => {
        this.setState({
            showAssignModal: false
        });
    }

    private onAssignSave = (userId: number) => {
        const taxDocuments = this.selectedDocuments();
        const user = this.getUserModel(userId);
        taxDocuments.forEach((taxDocument, i) => {
            taxDocument.assignTo = userId;
            taxDocument.assignedUser = user;
            if (taxDocument.documentStatus !== DocumentStatus.PROCESSING
                && taxDocument.documentStatus !== DocumentStatus.RECALLED
                && taxDocument.documentStatus !== DocumentStatus.READY) {

                taxDocument.documentStatus = DocumentStatus.PROCESSING;
            }
            taxDocument.isModified = true;
            this.props.updateDocumentInitialStatus(taxDocument, "", () => { this.onAssignSaveSuccess(taxDocument.id) }, this.onAssignSaveError);
        });
    }

    private onAssignSaveSuccess = (documentId: number) => {
        const assignedDocumentIds = [...this.state.onAssignSuccessDocumentList];
        assignedDocumentIds.push(documentId);

        if (assignedDocumentIds.length < this.state.selectedDocuments.length) {
            this.setState({ onAssignSuccessDocumentList: assignedDocumentIds });
        }
        else {
            this.setState({
                onAssignSuccessDocumentList: [],
                showAssignModal: false
            }, () => {
                VenusNotifier.Success(SendGroupedReturnsHeaderConstants.StatusMessage.AssignUserSuccess, null);
            });
        }
    }

    private onAssignSaveError = () => {
        this.setState({
            onAssignSuccessDocumentList: [],
        }, () => {
            VenusNotifier.Error(SendGroupedReturnsHeaderConstants.StatusMessage.AssignUserError, null);
        });
    }

    public getUserModel = (id: number) => {

        var user = this.props.usersStore.allUsers.find(x => x.id == id);
        if (user) {
            return {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.emailAddress
            }
        }
        throw ("no user found with id:" + id);
    }
    //====================Assign Ends Here=======================================

    //====================Delete Starts Here=======================================

    private onDeleteGroupAndReturnsOpen = (rowIndex: number = NO_INDEX) => {
        bootbox.confirm({
            title: SendGroupedReturnsConstants.HeaderActions.Delete.Title,
            message: SendGroupedReturnsConstants.HeaderActions.Delete.ConfirmationMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.deleteGroupAndReturns();
                }
            }
        });
    }

    private deleteGroupAndReturns = () => {

        const selectedDocument = [...this.state.selectedDocuments];
        const selectedGroupIds: number[] = [];
        this.state.selectedRows.map((rowIndex, index) => {
            const groupedReturn = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[rowIndex];
            if (groupedReturn) {
                selectedGroupIds.push(groupedReturn.groupId);
                groupedReturn.taxReturns.map((taxReturn: any) => {
                    if (taxReturn) {
                        const taxDocumentIndex = selectedDocument.findIndex(x => x === taxReturn.id);
                        if (taxDocumentIndex === NO_INDEX) {
                            selectedDocument.push(taxReturn.id);
                        }
                    }
                });
            }
        });

        const totalItem = this.props.sendGroupedReturns.totalRowCount;

        let pageNo = this.state.page;

        if ((totalItem - selectedGroupIds.length) <= (this.state.pageSize * (this.state.page - 1))) {
            pageNo = this.state.page - 1;
        }

        this.props.deleteGroupAndReturns(selectedGroupIds, selectedDocument, () => {
            this.onDeleteGroupAndReturnsCompletion(selectedGroupIds, pageNo);
        });
    }

    private onDeleteGroupAndReturnsCompletion = (selectedGroupIds: number[], pageNo: number) => {

        this.setState({
            selectedDocuments: [],
            selectedRows: [],
            page: pageNo,
            filter: ({
                name: '',
                searchText: '',
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.SendGroupedExtensions,
                isMasterFilter: false,
            })
        }, this.onPageReload);
        this.forceUpdate();
        this.resetSendGroupedReturnTable();
    }

    private resetSendGroupedReturnTable = () => {
        const sendGroupedReturnsTableRefs: any = this.refs.SendGroupedReturnsTable;
        if (sendGroupedReturnsTableRefs && sendGroupedReturnsTableRefs.refs.table) {
            sendGroupedReturnsTableRefs.refs.table.reset();
            sendGroupedReturnsTableRefs.refs.table.setState({ sizePerPage: this.state.pageSize, currPage: 1 });
        }
    }

    //====================Delete Ends Here=======================================

    //====================ProcessReturn Starts Here=======================================

    private getTaxDocument = (taxDocumentId: number) => {
        let taxReturn: ITaxReturn = {} as ITaxReturn;
        this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group: any) => {
            group.taxReturns.map((value: any) => {
                if (taxDocumentId === value.id) {
                    taxReturn = value;
                }
            })
        });
        return taxReturn;
    }

    private getTaxDocumentIds = (groupId: number) => {
        const documentIds: number[] = [];
        this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group: any) => {
            if (group.groupId === groupId) {
                group.taxReturns.map((taxReturn: any) => {
                    documentIds.push(taxReturn.id);
                });
            }
        });
        return documentIds;
    }

    private handleGroupLevelRowDoubleClick = (rowIndex: number) => {
        this.handleGroupLevelReturnOpen(rowIndex);
    }

    private handleReturnLevelRowDoubleClick = (taxDocumentId: number, groupId: number) => {
        let taxReturn: ITaxReturn = {} as ITaxReturn;
        const item = this.props.groupedTaxDocuments[taxDocumentId];
        if (item) {
            taxReturn = item.taxReturn;
        }
        else {
            taxReturn = this.getTaxDocument(taxDocumentId);
        }
        if (Helper.isLocked(taxReturn) || (taxReturn.documentStatus === DocumentStatus.RECALLED || taxReturn.documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION)) {
            return;
        }
        this.handleProcessReturnOpen(taxDocumentId, groupId);
    }

    private handleProcessReturnOpen = (taxDocumentId: number, groupId: number, callback?: () => void) => {
        let taxReturn: ITaxReturn = {} as ITaxReturn;
        const item = this.props.groupedTaxDocuments[taxDocumentId];
        if (item) {
            taxReturn = item.taxReturn;
        }
        else {
            taxReturn = this.getTaxDocument(taxDocumentId);
        }
        this.setState(prevState => { return { ...prevState, groupId: groupId } }, () => {

            this.props.checkDuplicateTaxReturn(taxReturn.id, taxReturn.engagementType, taxReturn.taxYear, (result: any) => {
                if (result && taxReturn.documentStatus == 'READY') {
                    bootbox.confirm({
                        message: ProcessReturnConstants.DuplicateReturnMessage,
                        buttons: {
                            cancel: {
                                label: '<i class="fas fa-times"></i> Cancel',
                                className: 'btn-white btn-default'
                            },
                            confirm: {
                                label: '<i class="fas fa-check"></i> OK',
                                className: 'btn-info'
                            }
                        },
                        callback: (result: boolean) => {
                            if (result == true) {
                                if (this.state.processingDocumentId === taxReturn.id) {
                                    VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                                    return;
                                }

                                //resolved bug - 54122
                                this.setState({ processReturnModalLoader: true });
                                this.props.requestGroupedTaxDocument(taxReturn.id, true, undefined, undefined, this.handleRequest);
                                return;

                            }
                            else {
                                taxReturn.documentStatus = DocumentStatus.DUPLICATE;
                                this.props.updateDocumentInitialStatus(taxReturn, "");
                            }
                        }
                    });
                }
                else {
                    if (this.state.processingDocumentId === taxReturn.id) {
                        VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                        return;
                    }

                    //resolved bug - 54122
                    this.setState({ processReturnModalLoader: true });
                    this.props.requestGroupedTaxDocument(taxReturn.id, true, undefined, undefined, this.handleRequest);
                    return;

                }
            });
            if (callback) {
                callback();
            }
        });
    }

    handleRequest = (taxReturn: ITaxReturn) => {
        if (taxReturn.documentStatus !== DocumentStatus.READY) {
            if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.profile.userId) {
                // locked, assigned to other user 
                this.setState({ processReturnModalLoader: false });
                VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
                return;
            }
            else if (!Helper.isProcessable(taxReturn)) {
                this.setState({ processReturnModalLoader: false });
                VenusNotifier.Error(ProcessReturnConstants.ReturnAlreadyProcessed, null);
                return;
            }
            else if (taxReturn.lockedBy <= 0 &&
                taxReturn.assignTo !== this.props.profile.userId) {
                taxReturn.assignTo = this.props.profile.userId != undefined ? this.props.profile.userId : 0;
                taxReturn.assignedUser.email = this.props.profile.emailAddress;
                taxReturn.assignedUser.firstName = this.props.profile.firstName;
                taxReturn.assignedUser.lastName = this.props.profile.lastName;
                taxReturn.assignedUser.userId = this.props.profile.userId || 0;
                this.processReturnModalSetUp(taxReturn);
            }
            else {
                this.processReturnModalSetUp(taxReturn);
            }
        }
        else {
            this.processReturnModalSetUp(taxReturn);
        }
    }

    processReturnModalSetUp = (taxReturn: ITaxReturn) => {
        this.props.requestTaxReturnPdf(taxReturn, true);
        this.props.requestUserProfile();
        this.props.requestTaxingAuthorities();
        this.props.requestAllUsers(false);
        this.props.requestCompanySettings(false);
        this.props.requestSavedMessages();
        this.props.requestMailingReturnAddressList();
        this.props.requestSelectedDocumentsForDelivery(this.state.groupId);

        // download delgated signers
        if (this.props.profile.userId) {
            const userId = this.props.profile.userId as number;
            this.props.requestDelegatedSigners(userId);
        }

        // download the signature default ero signer
        if (taxReturn.partner.userId) {
            this.props.requestDownloadPath(taxReturn.partner.userId);
        }
        this.setState({
            processReturnModalLoader: false,
            processReturnWindows: {
                show: true
            },
            processingDocumentId: taxReturn.id
        });
    }

    private handleProcessReturnCancel = (taxDocumentId: number) => {
        this.setState({
            processReturnWindows: {
                show: false
            },
            processingDocumentId: 0
        });
        this.props.resetTaxDocument(taxDocumentId);
    }

    updateDocumentInitialStatus = (taxReturn: ITaxReturn) => {
        this.props.updateDocumentInitialStatus(taxReturn, "", () => this.props.requestGroupedTaxDocument(taxReturn.id, true));
    }

    private getGroupedTaxDocuments = () => {
        for (let i = 0; i < this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.length; i++) {
            const group = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[i];
            if (group && group.taxReturns && group.taxReturns.length > 0) {
                for (let j = 0; j < group.taxReturns.length; j++) {
                    if (group.taxReturns[j] && group.taxReturns[j].id === this.state.processingDocumentId) {
                        const taxReturns: ITaxReturn[] = [];
                        group.taxReturns.map((taxReturn: any) => {
                            if (taxReturn.documentStatus != DocumentStatus.ERROR && taxReturn.documentStatus != DocumentStatus.NONSUPPORTED
                                && taxReturn.documentStatus != DocumentStatus.PREPARINGFORDELIVERY) {
                                taxReturns.push(taxReturn);
                            }
                        });
                        return taxReturns;
                    }
                }
            }
        }
        return [];
    }

    //====================ProcessReturn Ends Here=======================================

    //=======================================================================================

    private onReportProblemOpen = (id: number) => {
        let taxReturn: ITaxReturn;
        if (!this.state.isGroupedReportProblem) {
            taxReturn = { ...this.props.groupedTaxDocuments[id].taxReturn };
            if (!Helper.isClientInfoLoaded(taxReturn)) {
                this.props.requestGroupedTaxDocument(taxReturn.id, true);
            }
            this.setState({
                reportProblemState: {
                    show: true,
                    model: taxReturn,
                    saveCheckId: this.state.isGroupedReportProblem ? id : undefined,

                }
            });
        }
        else {
            this.setState({
                reportProblemState: {
                    show: true,
                    model: this.state.groupReportModel,
                    saveCheckId: id,

                }
            });
        }
    }

    private onReportProblemCancel = () => {
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            isGroupedReportProblem: false
        });
    }
    private onReportProblemSave = (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => {
        let groupId = 0;
        if (problemDetails.description.trim() === '') {
            VenusNotifier.Warning("Please enter problem description to report.", null)
        }
        else {
            if (!this.state.isGroupedReportProblem) {
                this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((group: any) => {
                    group.taxReturns.map((value: any) => {
                        if (id === value.id) {
                            groupId = group.groupId;
                        }
                    })
                });
                if (callback)
                    this.props.reportDocumentGroupProblem(problemDetails, groupId, callback);
                else
                    this.props.reportDocumentGroupProblem(problemDetails, groupId);

                this.setState({
                    reportProblemState: {
                        show: true,
                        model: undefined,
                        saveCheckId: id
                    }
                });
            }
            else {
                groupId = this.state.reportProblemState.saveCheckId ? this.state.reportProblemState.saveCheckId : 0;
                if (callback)
                    this.props.reportDocumentGroupProblem(problemDetails, groupId, callback);
                else
                    this.props.reportDocumentGroupProblem(problemDetails, groupId);
                this.setState({
                    reportProblemState: {
                        show: false,
                        model: undefined,
                        saveCheckId: undefined
                    },
                    isGroupedReportProblem: false
                });
            }
        }
    }
    //=======================================================================================

    public assignedUser = (selectedAssignments: ITaxReturn[]) => {
        return this.props.profile.userId as number;
    }

    //--------------------------------Edit Client Info Start--------------------------------
    private onEditClientInfoOpen = (taxDocumentId: number) => {
        const item = this.props.groupedTaxDocuments[taxDocumentId].taxReturn;
        const taxReturn: ITaxReturn = item;
        const ids = [taxReturn.id];
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        }
        else {
            this.handleEditClientInfoOpenRequest(taxReturn);
        }
    }
    private handleEditClientInfoOpenRequest = (taxReturn: ITaxReturn) => {
        if (!Helper.isClientInfoLoaded(taxReturn)) {
            this.props.requestGroupedTaxDocument(taxReturn.id, true);
        }
        this.setState({
            editClientInfoState: {
                show: true,
                model: taxReturn ? taxReturn : undefined,
                saveCheckId: undefined
            }
        });
    }
    private onEditClientInfoCancel = (id: number) => {
        const item = this.state.editClientInfoState.model as ITaxReturn;
        this.props.requestGroupedTaxDocument(item.id, true);
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    private onEditClientInfoSave = (id: number, isMailSend: boolean, clientGUIDs: string[], isEmailUpdated: boolean, resetLoadingMessageState?: any) => {

        const taxReturn: ITaxReturn = this.props.groupedTaxDocuments[id].taxReturn;
        if (Helper.validateClientInfo(taxReturn)) {
            this.props.saveTaxDocument(taxReturn, "", undefined, () => {
                resetLoadingMessageState();
            });
            this.setState({
                editClientInfoState: {
                    show: true,
                    model: taxReturn,
                    saveCheckId: id
                }
            });
        }
    }
    //--------------------------------Edit Client Info End--------------------------------
    //--------------------------------Change Status Start--------------------------------
    private onChangeStatusOpen = (taxDocumentId: number = NO_INDEX) => {
        const item = this.props.groupedTaxDocuments[taxDocumentId].taxReturn;
        const taxReturn: ITaxReturn = item;
        const ids = [taxReturn.id];
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        }
        else {
            this.handleChangeStatusOpenRequest(taxReturn);
        }
    }
    private handleChangeStatusOpenRequest = (taxDocument: ITaxReturn) => {
        if (taxDocument.documentStatus === DocumentStatus.RECALLED ||
            taxDocument.documentStatus == DocumentStatus.PREPARINGFORDELIVERY ||
            taxDocument.documentStatus == DocumentStatus.DELIVERYFAILED) {
            VenusNotifier.Error(ChangeStatusConstants.Error, ChangeStatusConstants.Title);
            return;
        }
        this.props.requestGroupedTaxDocument(taxDocument.id, true);

        const tempReturnDoc = this.props.groupedTaxDocuments[taxDocument.id].taxReturn;
        const defaultUser = this.assignedUser([tempReturnDoc]);
        this.setState({
            changeStatusState: {
                show: true,
                model: tempReturnDoc,
                saveCheckId: undefined
            },
            defaultAssignUser: defaultUser,
            showLoader: false
        });
    }
    private onChangeStatusCancel = () => {
        this.setState({
            changeStatusState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }
    private onChangeStatusSaveCallback = (docId: number, userId: number, status: DocumentStatus) => {
        const taxDocument = this.state.changeStatusState.model as ITaxReturn;
        const companySettings: ICompanySettings = this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings;
        //if (status == DocumentStatus.READYFORDELIVERY && companySettings.displaySettingsModel.isEnableInvoice && !Helper.validateInvoice(taxDocument, companySettings)) {
        //    VenusNotifier.Error(FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
        //    return;
        //}
        taxDocument.documentStatus = status;
        taxDocument.isModified = true;

        taxDocument.assignTo = userId;
        taxDocument.assignedUser = this.getUserModel(userId);
        this.setState({
            changeStatusState: {
                show: true,
                model: undefined,
                saveCheckId: docId
            },
            showLoader: true
        }, () => {
            this.props.ChangeStatusGroupedTaxDocument(taxDocument);
        });
    }

    //--------------------------------Change Status End--------------------------------

    //--------------------------------Delete Tax Return Start--------------------------------
    private onDeleteTaxReturnOpen = (taxDocumentId: number = NO_INDEX) => {
        const item = this.props.groupedTaxDocuments[taxDocumentId].taxReturn;
        const taxReturn: ITaxReturn = item;
        const ids = [taxReturn.id];
        if (ids.length == 0) {
            VenusNotifier.Warning(DeleteTaxReturnConstants.SelectDocumentWarning, null);
            return;
        }

        if (!this.checkIfReturnIsStuckInUploadedOrNonSupported(ids, taxReturn)) {

            if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: ProcessReturnConstants.ReturnNotAssignedToLoggedInUser,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: 'btn-info'
                        }
                    }
                });
                return;
            }
            if (this.checkIfReturnIsInUse(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: DeleteTaxReturnConstants.InUseReturnWarning,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: 'btn-info'
                        }
                    }
                });
                return;
            }
        }
        const obj = this;
        bootbox.confirm({
            title: DeleteTaxReturnConstants.Title,
            message: DeleteTaxReturnConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onDeleteTaxReturnSave(ids);
                }
            }
        });
    }

    private onDeleteTaxReturnSave = (ids: number[]) => {
        const selectedDocument = ids;
        const selectedGroupIds: number[] = [];

        this.props.deleteGroupAndReturns(selectedGroupIds, selectedDocument, () => {
            this.onDeleteGroupAndReturnsCompletion(selectedGroupIds, this.state.page);
        });
    }

    private checkIfReturnIsStuckInUploadedOrNonSupported = (ids: number[], taxReturn: ITaxReturn) => {
        let result = false;
        const listItem: number[] = [];
        ids.forEach((id) => {
            if (taxReturn.documentStatus === DocumentStatus.UPLOADED ||
                taxReturn.documentStatus === DocumentStatus.NONSUPPORTED ||
                taxReturn.documentStatus === DocumentStatus.ERROR) {
                listItem.push(1);
            }
            else {
                listItem.push(0);
            }
        })
        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }
    //--------------------------------Delete Tax Return End--------------------------------

    //--------------------------------Delete Group & Returns Start--------------------------------
    private onDeleteGroupOpen = (rowIndex: number) => {
        bootbox.confirm({
            title: SendGroupedReturnsConstants.HeaderActions.Delete.Title,
            message: SendGroupedReturnsConstants.HeaderActions.Delete.ConfirmationMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.deleteSingleGroup(rowIndex);
                }
            }
        });
    }
    private deleteSingleGroup = (rowIndex: number) => {

        const selectedDocument: number[] = [];
        const selectedGroupIds: number[] = [];

        const groupedReturn = this.props.sendGroupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions[rowIndex];
        if (groupedReturn) {
            selectedGroupIds.push(groupedReturn.groupId);
            groupedReturn.taxReturns.map((taxReturn: any) => {
                if (taxReturn) {
                    selectedDocument.push(taxReturn.id);
                }
            });
        }

        const totalItem = this.props.sendGroupedReturns.totalRowCount;

        let pageNo = this.state.page;

        if ((totalItem - selectedGroupIds.length) <= (this.state.pageSize * (this.state.page - 1))) {
            pageNo = this.state.page - 1;
        }

        this.props.deleteGroupAndReturns(
            selectedGroupIds,
            selectedDocument,
            () => {
                this.onDeleteGroupAndReturnsCompletion(selectedGroupIds, pageNo);
            });
    }
    //--------------------------------Delete Group & Returns End--------------------------------

    //--------------------------------Set Group Access Start--------------------------------
    private onSetGroupAccessOpen = (rowIndex: number) => {
        const newList = [...this.state.selectedRows];
        newList.push(rowIndex);
        this.setState({ selectedRows: newList });
        this.onSetAccessOpen(rowIndex);
    }
    //--------------------------------Set Group Access End--------------------------------

    //--------------------------------Report Grouped Probem Start--------------------------------
    private onReportGroupProblemOpen = (groupId: number) => {
        this.setState({
            isGroupedReportProblem: true
        }, () => { this.onReportProblemOpen(groupId); });
    }
    //--------------------------------Report Grouped Probem End--------------------------------


    //--------------------------------Edit Controller Info Start-------------------------------------
    public GROUPID: number = 0;

    private onEditControllerInfoOpen = (groupId: number) => {
        this.GROUPID = groupId;
        this.props.requestGroupLevelData(groupId, true, this.handleEditControllerInfoOpenRequest);
    }

    private handleEditControllerInfoOpenRequest = () => {
        const controllerData = this.getGroupedReturnsModel(this.GROUPID);
        this.setState({
            editControllerInfoState: {
                show: true,
                model: controllerData.groupInfo,
                saveCheckId: undefined
            }
        });
    }
    private onEditControllerInfoCancel = (id: number) => {
        this.setState({
            editControllerInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onEditControllerInfoSave = (id: number, isMailSend: boolean, clientGUIDs: string[], isEmailUpdated: boolean, resetLoadingMessageState?: any) => {

        const groupedReturnData: GroupedReturnsModel = this.props.groupedReturnProcessState[id].groupLevelReturnsData;
        if (Helper.validateControllerInfo(groupedReturnData.groupInfo.controllerInfo)) {
            this.props.saveGroupLevelReturnData(groupedReturnData, () => {
                resetLoadingMessageState();
                if (isMailSend) {
                    this.onControllerEmailUpdated(groupedReturnData.groupInfo);
                }
            });
            this.setState({
                editControllerInfoState: {
                    show: true,
                    model: groupedReturnData.groupInfo,
                    saveCheckId: this.GROUPID
                }
            });
        }
    }
    private onControllerEmailUpdated = (groupInfo: GroupInfo) => {
        this.props.sendControllertInfoMail(groupInfo);
    };
    //--------------------------------Edit Controller Info End-------------------------------------

    //--------------------------------Edit Group Name Start-------------------------------------
    private onEditGroupInfoOpen = (groupId: number) => {
        this.GROUPID = groupId;
        ShowLoader();
        this.props.requestGroupInfo(groupId, this.handleEditGroupInfoOpenRequest);
    }
    private handleEditGroupInfoOpenRequest = (groupInfo: GroupInfo) => {
        HideLoader();
        if (groupInfo) {
            this.setState({
                editGroupInfoState: {
                    show: true,
                    model: groupInfo
                }
            });
        }
    }
    private onEditGroupInfoCancel = () => {
        this.setState({
            editGroupInfoState: {
                show: false,
                model: undefined
            }
        });
    }
    private onGroupInfoUpdate = (groupInfo: GroupInfo, resetLoadingMessageState: () => void, failureCallback: () => void) => {
        this.props.updateGroup(groupInfo,
            () => {
                resetLoadingMessageState();
                this.setState({
                    editGroupInfoState: {
                        show: false,
                        model: undefined
                    }
                });
                this.props.requestGroupConfiguration(true);
                this.onPageReload();
            },
            () => {
                failureCallback();
            }
        );
    }
    //--------------------------------Edit Group Name End-------------------------------------

    //------------------------------------------------------------------------------------
    private checkForPopupCompletion = (props: SendGroupedExtensionsProps) => {

        this.onUpdateCompletion(props.groupedTaxDocuments, "editClientInfoState", this.state.editClientInfoState);
        this.onUpdateCompletion(props.groupedTaxDocuments, "changeStatusState", this.state.changeStatusState);
        this.onUpdateCompletion(props.groupedTaxDocuments, "reportProblemState", this.state.reportProblemState);

        this.onUpdateGroupLevelCompletion(props.groupedReturnProcessState, "editControllerInfoState", this.state.editControllerInfoState);
    }
    private onUpdateCompletion = (taxDocuments: GroupedTaxDocumentDictionary, stateKey: string, state: PopupStateTaxReturnSingle) => {
        if (state.saveCheckId &&
            isLoaded(taxDocuments, [state.saveCheckId])) {
            if (stateKey != "reportProblemState") {
                if (taxDocuments[state.saveCheckId].error) {
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
                } else {
                    VenusNotifier.Success(successMsg[stateKey], stateKey);
                }
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }
    private onUpdateGroupLevelCompletion = (groupLevelModel: GroupedExtensionsProcessStoreState, stateKey: string, state: PopupStateGroupController) => {
        if (state.saveCheckId && isControllerLoaded(groupLevelModel, state.saveCheckId)) {
            if (stateKey != "editControllerInfoState") {
                if (groupLevelModel[state.saveCheckId].error) {
                    VenusNotifier.Error("Failed to update controller info for the group", stateKey);
                } else {
                    VenusNotifier.Success("Successfully updated controller info for the group", stateKey);
                }
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }
    private updateStates = (props: SendGroupedExtensionsProps) => {
        if (this.state.editClientInfoState.model) {
            const tempReturnDoc = props.groupedTaxDocuments[this.state.editClientInfoState.model.id].taxReturn;

            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    model: tempReturnDoc
                }
            });
        }
        if (this.state.changeStatusState.model) {
            const tempReturnDoc = props.groupedTaxDocuments[this.state.changeStatusState.model.id].taxReturn;

            this.setState({
                changeStatusState: {
                    ...this.state.changeStatusState,
                    model: tempReturnDoc
                }
            });
        }

        if (this.state.reportProblemState.model) {
            const tempReturnDoc = props.groupedTaxDocuments[this.state.reportProblemState.model.id].taxReturn;
            this.setState({
                reportProblemState: {
                    ...this.state.reportProblemState,
                    model: tempReturnDoc
                }
            });
        }

        if (this.state.recallReturnState.model && props.groupedTaxDocuments[this.state.recallReturnState.model.id]) {
            this.setState({
                recallReturnState: {
                    ...this.state.recallReturnState,
                    model: props.groupedTaxDocuments[this.state.recallReturnState.model.id].taxReturn
                }
            });
        }
    }
    //------------------------------------------------------------------------------------

    private onDocumentSelectedForDelivery = (taxReturn: ITaxReturn) => {
        if (taxReturn.isSelectedForDelivery && taxReturn.isSelectedForDelivery === true) {
            this.props.selectForDelivery(taxReturn.id);
        }
        else {
            this.props.unselectForDelivery(taxReturn.id);
        }
    }

    private onReprocessReturnOpen = (taxDocumentId: number) => {

        const item = this.props.groupedTaxDocuments[taxDocumentId];
        const taxReturn = item.taxReturn;

        if (!item.isFullyLoaded) {
            this.setState({ reProcessReturnModalLoader: true });
            this.props.requestGroupedTaxDocument(taxReturn.id, true, undefined, undefined, (e: any) => {
                this.handleReprocessOpen(e.id)
            });
            return;
        }

        this.handleReprocessOpen(taxReturn.id);
    }

    private handleReprocessOpen = (id: number) => {
        this.setState({ reProcessReturnModalLoader: true }, () => {
            const taxDocument = this.props.groupedTaxDocuments[id].taxReturn;
            taxDocument.documentStatus = DocumentStatus.REPROCESSING;
            this.props.reprocessTaxDocument(taxDocument, () => {
                this.setState({ reProcessReturnModalLoader: false });
                interval = setTimeout(this.getDocumentStatus(id), 10000);
            });
        });
    }

    private getDocumentStatus = (id: number): any => {
        const _self = this;
        this.props.requestDocumentStatus(id, () => {
            if (_self.props.taxDocuments[id].taxReturn.documentStatus != DocumentStatus.REPROCESSING) {

                clearTimeout(interval);
            }
            else {
                interval = setTimeout(this.getDocumentStatus(id), 10000);
            }
        });
    }


    private onRecallReturnOpen = (taxDocumentId: number) => {
        this.setState({
            recallReturnState: {
                show: true,
                model: this.props.groupedTaxDocuments[taxDocumentId].taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onRecallReturnCancel = () => {
        this.setState({
            recallReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onRecallUploadConfirmation = (documentKey: string, clientId: string) => {
        const taxDoc = this.state.recallReturnState.model;
        if (taxDoc && taxDoc.documentGuid == documentKey) {
            taxDoc.documentStatus = DocumentStatus.UPLOADED;
            taxDoc.clientId = clientId;
            this.props.updateGroupedTaxDocument(taxDoc);
        }
    }






    //=======================================================================================

    //Group Configuration

    private onGroupConfigurationOpen = (rowIndex: number = NO_INDEX) => {
        const ids = this.state.selectedDocuments;
        if (ids.length == 0) {
            VenusNotifier.Warning(GroupExtensionConstants.SelectDocumentWarning, null);
            return;
        }
        const taxDocuments = this.selectedDocuments();

        this.setState({
            groupConfigurationState: {
                show: true,
                selected: taxDocuments,
                saveCheckIds: undefined
            }
        });

    }
    private UnMapGroupedReturns = () => {
        this.props.unmapReturnstoGroup(this.state.selectedDocuments, this.onUnMapGroupedReturnsCompletion);
    }

    private onUnMapGroupedReturnsCompletion = () => {
        VenusNotifier.Success(SendGroupedReturnsHeaderConstants.StatusMessage.UnMapReturnToGroupSuccess, null);
        const ids = this.state.selectedDocuments;
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: [],
            selectedDocuments: []
        }, this.onPageReload);
    }

    private onGroupCreateNew = (groupInfo: GroupInfo) => {
        this.setState({ addedGroup: groupInfo });
        this.props.unmapReturnstoGroup(this.state.selectedDocuments, this.addtoNewGroup);
    }
    private addtoNewGroup = () => {
        this.props.addGroup(this.state.addedGroup, this.mapReturntoGroup, this.onGroupConfigurationFailure);
    }
    private mapReturntoGroup = (groupId: number) => {
        const ids = this.state.selectedDocuments;
        this.props.mapExtensionstoGroup(ids, groupId, this.onGroupConfigurationSaveCompletion, this.onGroupConfigurationFailure);
    }
    private onAddtoExistingGroup = (groupid: number) => {
        this.setState({ selectedGroupId: groupid });
        this.props.unmapReturnstoGroup(this.state.selectedDocuments, this.mapReturntoExistingGroup);
    }
    private mapReturntoExistingGroup = () => {
        const ids = this.state.selectedDocuments;
        this.props.mapExtensionstoGroup(ids, this.state.selectedGroupId, this.onGroupConfigurationSaveCompletion, this.onGroupConfigurationFailure);
    }
    private onGroupConfigurationSaveCompletion = () => {
        const ids = this.state.selectedDocuments;
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: []
        }, this.onPageReload);
    }
    private onGroupConfigurationFailure = () => {
        const ids = this.state.selectedDocuments;
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: []
        });
    }


    private onGroupConfigurationCancel = () => {
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    }

    //End of Group Configuration

    //=====================================================================================




    public render() {
        return <div className='user-assignment-content devilvered-archived-screen'>
            <SendGroupedExtensionsHeader
                pageTitle={this.props.pageTitle}
                company={this.props.company}
                companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                getUploadLink={(url: string) => {
                    this.props.getUploadLink(url, "");
                }}
                onAssignOpen={this.onAssignOpen}
                onDeleteGroupsOpen={this.onDeleteGroupAndReturnsOpen}
                onSetAccessOpen={this.onSetAccessOpen}
                onUnGroupOpen={this.onUnMapGroupedReturnsOpen}
                onPageReload={this.onPageReload}
                processTaxReturn={this.props.processTaxReturn}
                selectedERO={this.props.extensionUserSettings.defaultSettings ? this.props.extensionUserSettings.defaultSettings.eroUser : 0}
                selectedGroupCount={this.selectedGroupIds().length}
                selectedRows={this.state.selectedRows}
                extensionUsers={this.props.usersStore.extensionUsers}
                partners={this.props.usersStore.partners}
                submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                    this.props.submitTaxReturn(url, taxData, "", callback);
                }}
                userDefaultSettings={this.props.userSettings.settings}
                defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                groupInfo={this.props.groupedReturnStore.groupInfo}
                groupAccess={this.props.groupedReturnStore.groupAccess}
                selectedDocuments={this.state.selectedDocuments}
                updateGroupInfoState={this.props.requestGroupConfiguration}
                groupConfigurationData={this.state.groupConfigurationState}
                onGroupConfigurationCancel={this.onGroupConfigurationCancel}
                onGroupCreateNew={this.onGroupCreateNew}
                onAddtoExistingGroup={this.onAddtoExistingGroup}
                onGroupConfigurationOpen={this.onGroupConfigurationOpen}
                onUnMapReturn={this.UnMapGroupedReturns}
                taxSoftwareData={this.props.taxSoftwareData.taxSoftwareSettings}
            />
            <br />
            <SendGroupedExtensionsTable
                ref='SendGroupedReturnsTable'
                clearSelection={this.clearSelection}
                onClearFilter={this.onClearFilter}
                currentFilter={this.state.filter}
                defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                filterList={this.props.reportFilters.filters}
                filterType={ReportFilterType.SendGroupedExtensions}
                isLoading={this.props.sendGroupedReturns.loading}
                onProcessGroupOpen={this.handleGroupLevelReturnOpen}
                onEditControllerInfoOpen={this.onEditControllerInfoOpen}
                onExportToExcel={this.onExportToExcel}
                onFilterChange={this.onFilterChange}
                onFilterDelete={this.onFilterDelete}
                loadGrid={this.loadGroupedReturns}
                onFilterNameChange={this.onFilterNameChange}
                onFilterSave={this.onFilterSave}
                onFilterUpdate={this.onFilterUpdate}
                onPageChange={this.onPageChange}
                onPageReload={this.onPageReload}
                onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                onRowSelect={this.onRowSelect}
                onSaveFilterHide={this.onSaveFilterHide}
                onSaveFilterShow={this.onSaveFilterShow}
                onSearchChange={this.onSearchChange}
                onSelectAll={this.onSelectAll}
                onSetDefaultFilter={this.onSetDefaultFilter}
                onSortChange={this.onSortChange}
                pageNo={this.state.page}
                pageSize={pageSize}
                proxyFilter={this.proxyFilter}
                saveFilterShow={this.state.saveFilterShow}
                selectedRows={this.state.selectedRows}
                groupedReturns={this.props.sendGroupedReturns}
                totalRows={this.props.sendGroupedReturns.totalRowCount}
                onDocumentSelect={this.onDocumentSelect}
                onDocumentUnSelect={this.onDocumentUnSelect}
                selectedDocuments={this.state.selectedDocuments}
                onProcessReturnOpen={this.handleProcessReturnOpen}
                onEditClientInfoOpen={this.onEditClientInfoOpen}
                onChangeStatusOpen={this.onChangeStatusOpen}
                onReportProblemOpen={this.onReportProblemOpen}
                onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                onDeleteGroupOpen={this.onDeleteGroupOpen}
                onSetAccessOpen={this.onSetGroupAccessOpen}
                onReportGroupProblemOpen={this.onReportGroupProblemOpen}
                onReprocessReturnOpen={this.onReprocessReturnOpen}
                onRecallReturnOpen={this.onRecallReturnOpen}
                onGroupLevelRowDoubleClick={this.handleGroupLevelRowDoubleClick}
                onReturnLevelRowDoubleClick={this.handleReturnLevelRowDoubleClick}
                OnEditGroupInfoOpen={this.onEditGroupInfoOpen}
            />

            <SetAccessModal
                showEditAccess={this.state.setAccessState.show}
                onCancel={this.onSetAccessCancel}
                selectedGroups={this.selectedGroupIds()}
                count={this.state.selectedRows.length}
                users={this.props.usersStore.allUsers}
                onApplyAccess={this.onSetAccessSave}
                groupAccessList={this.props.sendGroupedReturnsHeaderData.setAccessData.groupAccessList}
                isLoading={this.props.sendGroupedReturnsHeaderData.setAccessData.isLoading}
            />

            <AssignModal
                show={this.state.showAssignModal}
                count={this.state.selectedDocuments.length}
                users={this.props.usersStore.allUsers}
                defaultAssignUser={this.getDefaultAssignUser()}
                onCancel={this.onAssignCancel}
                onAssign={this.onAssignSave}
            />

            <ReportProblemModal
                show={this.state.reportProblemState.show}
                onCancel={this.onReportProblemCancel}
                onReportProblemSave={this.onReportProblemSave}
                model={this.state.reportProblemState.model as ITaxReturn}
                loggedInCPA={this.props.profile}
                companyName={this.props.company.companyProfile.companyInfo.companyName}
                reportProblemType={this.state.isGroupedReportProblem ? ReportProblemTypes.Generic : ReportProblemTypes.ReturnSpecific}
                problemStep={ReportedStep.Assignments}
                isGroup={true}
            />
            <Loader loading={this.state.groupProcessReturnModalLoader} text={GroupExtensionConstants.GroupProcessReturnOpenMessage} />
            <Loader loading={this.state.processReturnModalLoader} text={ProcessReturnConstants.ProcessReturnOpenMessage} />
            <Loader loading={this.state.reProcessReturnModalLoader} text={ReProcessReturnConstants.ReProcessInitiatedModalLoader} />
            {/*{*/}
            {/*    this.state.processingDocumentId > 0 &&*/}
            {/*    <ProcessReturnModal key={"process-return-model" + this.state.processingDocumentId}*/}
            {/*        docId={this.state.processingDocumentId}*/}
            {/*        groupId={this.state.groupId}*/}
            {/*        attachments={{} as AttachmentsState.IAttachmentsDictionary}*/}
            {/*        approveForDelivery={this.props.approveForDelivery}*/}
            {/*        closePopoverRef={null}*/}
            {/*        deliverToController={this.props.deliverToController}*/}
            {/*        downloadAttachments={this.props.downloadAttachments}*/}
            {/*        getMultiVoucherUploadLink={this.props.requestMultiVoucherUploadLink}*/}
            {/*        getVoucherUploadLink={this.props.requestVoucherUploadLink}*/}
            {/*        loadPdf={this.props.requestTaxReturnPdf}*/}
            {/*        onCancel={this.handleProcessReturnCancel}*/}
            {/*        order={1}*/}
            {/*        requestAttachments={this.props.requestAttachments}*/}
            {/*        requestPaymentUrls={this.props.requestPaymentUrls}*/}
            {/*        requestVoucherDueDates={this.props.requestVoucherDueDates}*/}
            {/*        requestVoucherFormNames={this.props.requestVoucherFormNames}*/}
            {/*        saveTaxDocument={this.props.saveTaxDocument}*/}
            {/*        sendForReview={this.props.sendForReview}*/}
            {/*        sendToERO={this.props.sendToERO}*/}
            {/*        show={this.state.processReturnWindows.show}*/}
            {/*        updateTaxDocument={this.props.updateGroupedTaxDocument}*/}
            {/*        taxDocuments={this.props.groupedTaxDocuments}*/}
            {/*        pdfDocuments={this.props.pdfDocuments}*/}
            {/*        users={this.props.userStore.users}*/}
            {/*        companySettings={this.props.company.companySettings as ICompanySettings}*/}
            {/*        authorities={this.props.company.taxingAuthorities}*/}
            {/*        authorityLookup={this.props.company.authorityLookup}*/}
            {/*        userBasicProfile={this.props.profile}*/}
            {/*        requestManualAddressList={this.props.requestManualAddressList}*/}
            {/*        manualAddressList={this.props.manualAddresses == undefined ? [] : this.props.manualAddresses.data}*/}
            {/*        getK1InstructionFileName={this.props.getK1InstructionFileName}*/}
            {/*        deleteK1Instruction={this.props.deleteK1Instruction}*/}
            {/*        uploadK1Instruction={this.props.uploadK1Instruction}*/}
            {/*        userSettings={this.props.userSettings}*/}
            {/*        requestDelegatedSigners={this.props.requestDelegatedSigners}*/}
            {/*        company={this.props.company}*/}
            {/*        generateTaxPayerView={this.props.generateTaxpayerView}*/}
            {/*        savedMessages={this.props.savedMessages.data}*/}
            {/*        getAllSavedMessages={this.props.requestSavedMessages}*/}
            {/*        downloadK1DocumentLink={this.props.requestDownloadK1DocumentLink}*/}
            {/*        saveProcessReturnInfo={this.props.saveProcessGroupedTaxDocumentInfo}*/}
            {/*        userSignatures={this.props.userSignatures}*/}
            {/*        getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}*/}
            {/*        voucherStore={this.props.voucherStore}*/}
            {/*        isK1Restored={this.props.downloadStore.isK1Restored}*/}
            {/*        requestTaxDocument={this.props.requestGroupedTaxDocument}*/}
            {/*        restoreK1Instruction={this.props.restoreK1Instruction}*/}
            {/*        getAllTaxingAuthorities={this.props.company.taxingAuthorities}*/}
            {/*        businessInstructionStore={this.props.businessReturnInstructions}*/}
            {/*        updateDocumentInitialStatus={this.updateDocumentInitialStatus}*/}
            {/*        previewAttachments={this.props.previewAttachments}*/}
            {/*        onReportProblemSave={this.onReportProblemSave}*/}
            {/*        onReportProblemOpen={this.onReportProblemOpen}*/}
            {/*        onReportProblemCancel={this.onReportProblemCancel}*/}
            {/*        reportProblemState={this.state.reportProblemState}*/}
            {/*        saveTaxingAuthority={this.props.SaveTaxingAuthority}*/}
            {/*        requestCustomAuthority={this.props.requestCustomAuthority}*/}
            {/*        requestTaxingAuthorities={this.props.requestTaxingAuthorities}*/}
            {/*        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}*/}
            {/*        attachmentInstructions={this.props.attachmentInstructionList.attachmentInstructions}*/}
            {/*        requestEroSignaturePath={this.props.requestDownloadPath}*/}
            {/*        getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}*/}
            {/*        deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}*/}
            {/*        deleteAdditionalDocument={this.props.deleteAdditionalDocument}*/}
            {/*        getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}*/}
            {/*        convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}*/}
            {/*        requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}*/}
            {/*        additionalDocumentTypeData={this.props.additionalDocumentTypeData}*/}
            {/*        requestCompanyLogo={this.props.requestCompanyLogo}*/}
            {/*        watermarkList={this.props.watermarkStore.waterMark}*/}
            {/*        groupData={this.props.groupedReturnStore.groupData}*/}
            {/*        groupedTaxDocuments={this.getGroupedTaxDocuments()}*/}
            {/*        onMarkedAsReadyForDelivery={this.onMarkReadyForDelivery}*/}
            {/*        onProcessReturnOpen={this.handleProcessReturnOpen}*/}
            {/*        requestGroupInfo={this.props.requestGroupInfo}*/}
            {/*        onDocumentSelectedForDelivery={this.onDocumentSelectedForDelivery}*/}
            {/*        getPaperFileUploadLink={this.props.getPaperFileUploadLink}*/}
            {/*        getPaperFileDownLoadLink={this.props.getPaperFileDownLoadLink}*/}
            {/*        deleteUploadedPaperFileFromBlob={this.props.deleteUploadedPaperFileFromBlob}*/}
            {/*        paperFileMessage={this.props.paperFileMessage ? this.props.paperFileMessage.data : []}*/}
            {/*        convertToPdfAsync={this.props.convertToPdfAsync}*/}
            {/*        requestDocumentStatus={this.props.requestDocumentStatus}*/}
            {/*    />*/}
            {/*}*/}

            {/*<div>*/}
            {/*    {*/}
            {/*        this.state.groupProcessReturnWindow.show &&*/}
            {/*        <GroupProcessReturnModal*/}
            {/*            key={"group-process-return" + this.state.groupProcessReturnWindow.selectedGroupId}*/}
            {/*            show={this.state.groupProcessReturnWindow.show}*/}
            {/*            groupName={this.state.groupProcessReturnWindow.selectedGroupName}*/}
            {/*            groupId={this.state.groupProcessReturnWindow.selectedGroupId}*/}
            {/*            onCancel={this.handleSendGroupReturnCancel}*/}
            {/*            onSaveAndClose={this.handleSendGroupReturnSaveAndClose}*/}
            {/*            onClose={this.handleSendGroupReturnClose}*/}
            {/*            onReportProblemOpen={this.onReportGroupProblemOpen}*/}
            {/*            groupedReturnsModel={this.getGroupedReturnsModel(this.state.groupProcessReturnWindow.selectedGroupId)}*/}
            {/*            requestGroupLevelData={this.props.requestGroupLevelData}*/}
            {/*            updateControllerInfoData={this.props.updateControllerInfoData}*/}
            {/*            users={this.props.userStore.users}*/}
            {/*            getAllSavedMessages={this.props.requestSavedMessages}*/}
            {/*            savedMessages={this.props.savedMessages.data}*/}
            {/*            companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}*/}
            {/*            taxReturns={this.state.groupProcessReturnWindow.taxReturns}*/}
            {/*            requestManualAddressList={this.props.requestManualAddressList}*/}
            {/*            manualAddressList={this.props.manualAddresses == undefined ? [] : this.props.manualAddresses.data}*/}
            {/*            watermarkList={this.props.watermarkStore.waterMark}*/}
            {/*            company={this.props.company}*/}
            {/*            groupedReturnsApproveForDelivery={this.props.groupedReturnsApproveForDelivery}*/}
            {/*            groupedReturnsDeliverToController={this.props.groupedReturnsDeliverToController}*/}
            {/*            groupedReturnsSendForReview={this.props.groupedReturnsSendForReview}*/}
            {/*            groupedReturnsSendToERO={this.props.groupedReturnsSendToERO}*/}
            {/*            userBasicProfile={this.props.profile}*/}
            {/*            onDocumentSelectedForDelivery={this.onDocumentSelectedForDelivery}*/}
            {/*            selectForDelivery={this.props.selectForDelivery}*/}
            {/*            unselectForDelivery={this.props.unselectForDelivery}*/}
            {/*            markAsReadyForDelivery={this.onMarkReadyForDelivery}*/}
            {/*            onProcessReturnOpen={this.handleProcessReturnOpen}*/}
            {/*            updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}*/}
            {/*            onPrintForPaperDelivery={this.props.PrintForPaperDeliveryAsync}*/}
            {/*            selectAllForDelivery={this.props.selectAllForDelivery}*/}
            {/*            unselectAllForDelivery={this.props.unselectAllForDelivery}*/}
            {/*            previewReadyForDeliverySelection={this.state.previewReadyForDeliverySelection}*/}
            {/*            checkIfReturnIsInUse={this.handleIfReturnIsInUseRequest}*/}
            {/*            requestDocumentSettings={this.getDocumentSignatureType}*/}
            {/*            documentSignatureType={this.state.documentSignatureType}*/}
            {/*            setDocumentSignatureType={this.setDocumentSignatureType}*/}
            {/*            paperFileMessage={this.props.paperFileMessage ? this.props.paperFileMessage.data : []}*/}
            {/*        />*/}
            {/*    }*/}
            {/*</div>*/}
            <ClientInfo
                show={this.state.editClientInfoState.show}
                model={this.state.editClientInfoState.model as ITaxReturn}
                onCancel={this.onEditClientInfoCancel}
                onSave={this.onEditClientInfoSave}
                updateTaxDocument={this.props.updateGroupedTaxDocument}
                isDeliveredReturn={false}
                states={this.props.company.companyProfile.countryStates}
            />
            <GroupedIndividualChangeStatusModal
                model={this.state.changeStatusState.model as ITaxReturn}
                show={this.state.changeStatusState.show}
                users={this.props.usersStore.allUsers}
                selectedUser={this.state.defaultAssignUser}
                onCancel={this.onChangeStatusCancel}
                onChangeStatusSave={this.onChangeStatusSaveCallback}
                showLoader={this.state.showLoader}
            />
            <ControllerInfoComponent
                show={this.state.editControllerInfoState.show}
                model={this.state.editControllerInfoState.model as GroupInfo}
                onCancel={this.onEditControllerInfoCancel}
                onSave={this.onEditControllerInfoSave}
                updateTaxDocument={this.props.updateGroupedTaxDocument}
                isDeliveredReturn={false}
                states={this.props.company.companyProfile.countryStates}
                updateControllerInfoData={this.props.updateControllerInfoData}
            />
            {this.state.recallReturnState.model && <UploadRecalledDocumentModal
                taxSoftwareType={this.state.recallReturnState.model.taxSoftware}
                documentKey={this.state.recallReturnState.model ? this.state.recallReturnState.model.documentGuid : ""}
                onShow={this.state.recallReturnState.show}
                onHide={this.onRecallReturnCancel}
                users={this.props.usersStore.allUsers}
                partners={this.props.usersStore.partners}
                selectedERO={this.props.extensionUserSettings.defaultSettings ? this.props.extensionUserSettings.defaultSettings.eroUser : 0}
                getUploadLink={(url: string) => {
                    this.props.getUploadLink(url, "");
                }}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                    this.props.submitTaxReturn(url, taxData, "", callback);
                }}
                uploadConfirmation={this.onRecallUploadConfirmation}
                isRecalled={true}
                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void ) => {
                    this.props.getTaxDocumentsAccessMaps(ids,true,RBACKeys.extensionInProgress.uploadButton, callback)
                }}
            />}
            <EditGroupInfoModal
                groupInfoList={this.props.groupedReturnStore.groupInfo}
                onCancel={this.onEditGroupInfoCancel}
                onGroupInfoChange={this.onGroupInfoUpdate}
                showEditGroupInfo={this.state.editGroupInfoState.show}
                groupInfo={this.state.editGroupInfoState.model as GroupInfo}
            />
        </div>
    }
}
function isLoaded(taxDocuments: GroupedTaxDocumentDictionary, ids: number[]) {
    for (var i in ids) {
        const groupReturn = taxDocuments[ids[i]].taxReturn;
        if (groupReturn && groupReturn.isfullyLoaded) {
            return false;
        }
    }
    return true;
}
function isControllerLoaded(allControllers: GroupedExtensionsProcessStoreState, id: number) {
    if (allControllers[id] && allControllers[id].isLoading) {
        return false;
    }
    return true;
}

