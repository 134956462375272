import React from  'react';
import { Link } from 'react-router-dom';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { SignatureFormSelectionType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { SignatureFormTypeConstant } from '../../helper/Constants';
import { BooleanLiteral } from 'typescript';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface ISignatureFormSelections {
    companySettings: ExtensionCompanySettings;
    OnChangeSignatureFormSelectionTypeFor1040(signatureFormSelectionType: SignatureFormSelectionType): void;
    handleAllowUserPlaceSignOn1040(checked: boolean): void;
    disableAllowPlaceSignOn1040: boolean;
}

export class SignatureFormSelection extends React.Component<ISignatureFormSelections, {}>{
    constructor(props: any) {
        super(props);
    }

    handleEsignWhenAllAllowed = (e: any) => {
        if (e.currentTarget.checked) {
            this.props.OnChangeSignatureFormSelectionTypeFor1040(SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed);
        }
    }
    handleReviewOnly = (e: any) => {
        if (e.currentTarget.checked) {
            this.props.OnChangeSignatureFormSelectionTypeFor1040(SignatureFormSelectionType.ReviewOnly);
        }
    }
    handleAllowPlaceSignOn1040 = (e: any) => {
        let checked = e.currentTarget.checked;
        this.props.handleAllowUserPlaceSignOn1040(checked);
    }

    public render() {

        const signatureSelection = this.props.companySettings.signatureSettingsModel ?
            this.props.companySettings.signatureSettingsModel.signatureFormSelectionTypeFor1040 :
            "";

        return <div id="divSignatureFormSelection" className="new-main-content">
            <h3>1040 Forms</h3>
            <div className="marL20">
                <div style={{ display: "flex" }} className="div-height">
                    <RadioButtonComponent
                        id={"esignWhenAllReturnsAllowed"}
                        text={"E-Sign when all included extensions are allowed"}
                        onChange={this.handleEsignWhenAllAllowed}
                        checked={SignatureFormTypeConstant.ESignWhenAllIncludedReturnsAllowed === signatureSelection}
                        dataTestAuto="A43895BA-46A2-4587-8BE4-E11CC68491D9"
                        value={SignatureFormTypeConstant.ESignWhenAllIncludedReturnsAllowed}
                        resourceID={RBACKeys.Settings.settings}
                    />
                </div>
                <div className="div-height">
                    <RadioButtonComponent
                        id={"reviewOnly1040"}
                        text={"Review Extension Only"}
                        onChange={this.handleReviewOnly}
                        checked={SignatureFormTypeConstant.ReviewOnly === signatureSelection}
                        dataTestAuto="665E7942-7DC9-4144-BE03-F6831A8CCE06"
                        value={SignatureFormTypeConstant.ReviewOnly}
                        resourceID={RBACKeys.Settings.settings}
                    />
                </div>
                <div className="marL30">
                    <CheckBoxComponent
                        id={"allowPlaceSignOn1040"}
                        text={"Allow user to place signature control when applicable."}
                        onChange={this.handleAllowPlaceSignOn1040}
                        checked={this.props.companySettings.signatureSettingsModel ?
                            this.props.companySettings.signatureSettingsModel.allowUserPlaceSignOn1040 : false}
                        datatestAuto={"7EBE4B14-EE9B-4B39-9DD8-75F66E67EE70"}
                        disabled={this.props.disableAllowPlaceSignOn1040}
                        resourceId={RBACKeys.Settings.settings}
                    />
                </div>
            </div>
        </div>;
    }
}

export default SignatureFormSelection;
