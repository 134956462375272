import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { InUseExtensionsState, InUseExtensionsTableModel } from './InUseExtensionsState';
import { IInUseTaxReturns } from '../../components/common/InUseTaxReturns';
import {
    RequestTaxDocumentAction, ReceiveTaxDocumentAction,
    MakeAvailableTaxDocumentAction, DeleteTaxDocumentAction
} from '../common/TaxDocumentStore';
import { actionTypes } from '../ActionTypes';
import {
    RequestInUseExtensionsAction, ReceiveInUseExtensionsAction,
    ReceiveInUseExtensionsPagesAction, RequestInUseExtensionsPagesAction
} from "./KnownTypes";
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction = RequestInUseExtensionsAction | ReceiveInUseExtensionsAction
    | ReceiveTaxDocumentAction | MakeAvailableTaxDocumentAction | DeleteTaxDocumentAction;

type AllKnownAction =
    RequestInUseExtensionsAction |
    ReceiveInUseExtensionsAction |
    ReceiveTaxDocumentAction |
    RequestTaxDocumentAction |
    ReceiveInUseExtensionsPagesAction |
    RequestInUseExtensionsPagesAction |
    MakeAvailableTaxDocumentAction |
    DeleteTaxDocumentAction;

let inUseReturnsAbortController = new AbortController();

export const actionCreators = {

    requestInUseExtensions: (query: string, reload: boolean = false, callback?: () => void, reloadOnNoDataFound?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.inUseExtensions.query) {

            let page = state.inUseExtensionsPages[query];
            if (!reload && page && page.InUseExtensionTableModel.hasOwnProperty('count')) {
                dispatch({ type: actionTypes.REQUEST_INUSE_EXTENSIONS, query: query });
                dispatch({ type: actionTypes.RECEIVE_INUSE_EXTENSIONS, query: query, table: page.InUseExtensionTableModel });
                return;
            }

            inUseReturnsAbortController.abort();
            inUseReturnsAbortController = new AbortController();

            const fetchTask = fetch(`${API_BASE_URL}api/Reports/InUseExtensions/GetInUseExtensions` + query, {
                method: 'GET',
                credentials: 'include',
                signal: inUseReturnsAbortController.signal
            })
                .then(response => response.json() as Promise<InUseExtensionsTableModel>)
                .then(data => {
                    if (data.count === 0 && reloadOnNoDataFound) {
                        reloadOnNoDataFound();
                    }
                    else {
                        dispatch({ type: actionTypes.RECEIVE_INUSE_EXTENSIONS, query: query, table: data });
                        dispatch({ type: actionTypes.RECEIVE_INUSE_EXTENSIONS_PAGES, query: query, table: data, totalRowCount: data.count });
                        if (callback) {
                            callback();
                        }
                    }
                })
                .catch(function (error) {
                    if(error.name!=="AbortError"){
                        logger.trackError(`requestInUseExtensions failed for query: ${query}, with error ${error.message}`);
                    }
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_INUSE_EXTENSIONS, query: query });
            dispatch({ type: actionTypes.REQUEST_INUSE_EXTENSIONS_PAGES, query: query });
        }
    }

};


const unloadedState: InUseExtensionsState = {
    InUseExtensionTableModel: {
        inUseExtensionModel: [],
        count: 0
    } as InUseExtensionsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as InUseExtensionsState;

const unloadedInUseTaxDocument: IInUseTaxReturns = {} as IInUseTaxReturns;

export const reducer: Reducer<InUseExtensionsState> = (state: InUseExtensionsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_INUSE_EXTENSIONS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as InUseExtensionsState;
        case actionTypes.RECEIVE_INUSE_EXTENSIONS:
            return {
                query: action.query,
                InUseExtensionTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as InUseExtensionsState;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            return updateState(action.type, state, action);
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
        case actionTypes.DELETE_TAX_DOCUMENT:
            return clearTaxReturns(state);
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

function updateState(type: actionTypes, state: InUseExtensionsState, action: KnownAction): InUseExtensionsState {
    let i: number = -1;
    let oldDocument: IInUseTaxReturns = unloadedInUseTaxDocument;
    let document: IInUseTaxReturns = {} as IInUseTaxReturns;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IInUseTaxReturns;
            id = action.id;
    }
    if (state.InUseExtensionTableModel.inUseExtensionModel) {
        state.InUseExtensionTableModel.inUseExtensionModel.forEach((value, index) => {
            if (value.id === id) {
                i = index;
                oldDocument = value;
                return;
            }
        });
    }
    if (i !== -1) {
        let InUseTaxDocument: IInUseTaxReturns = action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument;

        let documents = [
            ...state.InUseExtensionTableModel.inUseExtensionModel.slice(0, i),
            InUseTaxDocument,
            ...state.InUseExtensionTableModel.inUseExtensionModel.slice(i + 1)];
        let InUseExtensionTableModel: InUseExtensionsTableModel = {
            count: state.InUseExtensionTableModel.count,
            inUseExtensionModel: documents
        }

        return {
            query: state.query,
            InUseExtensionTableModel: InUseExtensionTableModel,
            totalRowCount: state.totalRowCount,
            loading: false
        } as InUseExtensionsState;
    }
    return state;
}

function clearTaxReturns(state: InUseExtensionsState): InUseExtensionsState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: true
    } as InUseExtensionsState;
}

