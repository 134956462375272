import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../store/index';
import { actionTypes } from '../ActionTypes';
import { RequestExpireDocumentsCount, ReceiveExpireDocumentsCount, ErrorExpireTaxDocumentCountAction } from "../reports/KnownTypes";
import { NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction = RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction;

type DispatchAction = RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction |
    NotificationAction;

export interface ITaxDocumentAdditionalState {
    count: number,
    error: boolean,
    message: string
}

export const actionCreators = {
    requestDocumentsToexpire: (retentionPeriod: number, attestRetentionPeriod: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/GetDocumentsToExpire/` + retentionPeriod + '/' + attestRetentionPeriod, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<number>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_EXPIRE_DOCUMENT_COUNT, count: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT, message: error });
                logger.trackError(`requestDocumentsToexpire failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_EXPIRE_DOCUMENT_COUNT, count: 0 });
    }
};


const unloadedState: ITaxDocumentAdditionalState = {
    count: 0,
    error: false,
    message: ""
};

export const reducer: Reducer<ITaxDocumentAdditionalState> = (state: ITaxDocumentAdditionalState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_EXPIRE_DOCUMENT_COUNT:
            let expireInitialDocumentState: ITaxDocumentAdditionalState = { ...state };
            expireInitialDocumentState.count = 0;
            expireInitialDocumentState.error = true;
            expireInitialDocumentState.message = "";
            return expireInitialDocumentState;
        case actionTypes.RECEIVE_EXPIRE_DOCUMENT_COUNT:
            let expireDocumentState: ITaxDocumentAdditionalState = { ...state };
            expireDocumentState.count = action.count;
            expireDocumentState.message = "";
            expireDocumentState.error = false;
            return expireDocumentState;

        case actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT:
            let expireErrorState: ITaxDocumentAdditionalState = { ...state };
            expireErrorState.count = 0;
            expireErrorState.error = true;
            expireErrorState.message = action.message;
            return expireErrorState;
    }

    return state || unloadedState;
};