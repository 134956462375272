import React from  'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
//import { Panel, Row, Col } from 'react-bootstrap';
import { SignatureControlTypes, SignatureControlRole, SignatureGroupType } from '../../../common/TaxReturn';
import { IEROSigner } from '../Tabs/TabEFile';
import { EsignHelpTextConstant } from './../../../helper/Constants';

export interface EROSignaturePanelProps {
    title: string;
    selectedEROSigner: IEROSigner;
    eroSigner: IEROSigner[];
    onEROSignerChange(selectedOption: any): void,
    isAssignedToLoggedinUser?: boolean;
    onSignatureControlFocus(focusedGroup: SignatureGroupType): void;
    dragStart(event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole): void;
    dragEnd?: (event: any) => void;
}

export interface EROSignatureSidebarState {
    open: boolean;
}

export class EROSignaturePanel extends React.Component<EROSignaturePanelProps, EROSignatureSidebarState>{
    constructor(props: EROSignaturePanelProps) {
        super(props);
        this.state = {
            open: true,
        };
    }

    private onEROSignerChange = (event: any) => {
        let selectedEROSigner = this.props.eroSigner.find(x => x.value == event.target.value);
        this.props.onEROSignerChange(selectedEROSigner);
    }

    private createEROOptions = () => {
        let controlCollection: any[] = [];

        this.props.eroSigner.map((signer, index) => {
            if (signer.eroImage || signer.eroImage != "") {
                controlCollection.push(<option key={index} value={signer.value}>
                    {signer.label}
                </option>);
            }
            else {
                controlCollection.push(<option className={'text-danger'}
                    key={index} value={signer.value} disabled={true}>
                    {signer.label + " (Signature not found)"} </option>);
            }
        });
        return controlCollection;
    }

    toggleExpand = () => {
        this.setState({ open: !this.state.open })
    }

    public render() {
        let icon = this.state.open ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
        return <div>
            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle
                        style={{ padding: "10px" }}
                        as={Card.Header}
                        variant="link"
                        eventKey="0"
                        onClick={() => this.toggleExpand()}>
                        <Card.Title as="h4">
                            <i className={icon}></i> {this.props.title}
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: "10px" }}>
                            <div>
                                <div className="form-horizontal">
                                    <Row className="marB15">
                                        <Col sm={12}>
                                            <select
                                                id="ero"
                                                name="form-field-name"
                                                className="form-control"
                                                value={this.props.selectedEROSigner.value}
                                                onChange={this.onEROSignerChange}
                                                data-test-auto="32C60802-06ED-42F6-80E2-244747E4F8F9"
                                                onFocus={() => { this.props.onSignatureControlFocus(SignatureGroupType.Stamp) }}>
                                                {this.createEROOptions()}
                                            </select>
                                        </Col>

                                    </Row>
                                </div>
                                <div className="form-horizontal">
                                    <Row className="marB15">
                                        <Col sm={10}>
                                            <div>
                                                {
                                                    <div
                                                        className="icon-div col-xs-12"
                                                        data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                                                        draggable={true}
                                                        onDragStart={(event) => { this.props.dragStart(event, SignatureControlTypes.Signature, SignatureControlRole.ERO) }}
                                                        onDragEnd={(event) => { this.props.dragEnd && this.props.dragEnd(event) }}
                                                    >
                                                        <i className="fas fa-pen"
                                                            style={{ backgroundColor: '#008000', color: '#FFFFFF', width: '28px', height: '100%' }}>
                                                        </i>
                                                    Add Signature Stamp
                                            </div>
                                                }
                                            </div>
                                        </Col>
                                        <i className="fa fa-question-circle" style={{ color: '#DAA520', marginTop: '8px' }} title={EsignHelpTextConstant.EroSignatureStamp}></i>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>

                </Card>
            </Accordion>
            {
                //    <Panel defaultExpanded onToggle={() => this.setState({ open: !this.state.open })}>
                //    <Panel.Heading style={{ padding: "10px" }}>
                //        <Panel.Toggle as="h3" className="panel-title">
                //            <i className={icon}></i> {this.props.title}
                //        </Panel.Toggle>
                //    </Panel.Heading>
                //    <Panel.Collapse>
                //        <Panel.Body>
                //            <div className="form-horizontal">
                //                <Row className="marB15">
                //                    <Col sm={12}>
                //                        <select
                //                            id="ero"
                //                            name="form-field-name"
                //                            className="form-control"
                //                            value={this.props.selectedEROSigner.value}
                //                            onChange={this.onEROSignerChange}
                //                            data-test-auto="32C60802-06ED-42F6-80E2-244747E4F8F9"
                //                            onFocus={() => { this.props.onSignatureControlFocus(SignatureGroupType.Stamp) }}>
                //                            {this.createEROOptions()}
                //                        </select>
                //                    </Col>

                //                </Row>
                //            </div>
                //            <div className="form-horizontal">
                //                <Row className="marB15">
                //                    <Col sm={9}>
                //                        <div>
                //                            {
                //                                <div
                //                                    className="icon-div col-xs-12"
                //                                    data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                //                                    draggable={true}
                //                                    onDragStart={(event) => { this.props.dragStart(event, SignatureControlTypes.Signature, SignatureControlRole.ERO) }}
                //                                    onDragEnd={(event) => { this.props.dragEnd && this.props.dragEnd(event) }}
                //                                >
                //                                    <i className="fas fa-pen"
                //                                        style={{ backgroundColor: '#008000', color: '#FFFFFF', width: '28px', height: '100%' }}>
                //                                    </i>
                //                                    Add Signature Stamp
                //                            </div>
                //                            }
                //                        </div>
                //                    </Col>
                //                    <i className="fa fa-question-circle" style={{ color: '#DAA520' }} title={EsignHelpTextConstant.EroSignatureStamp}></i>
                //                </Row>
                //            </div>
                //        </Panel.Body>
                //    </Panel.Collapse>
                //</Panel>
            }
        </div>
    };
}