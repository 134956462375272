import React from  'react';
import { FormGroup, FormLabel, Col, FormControl } from 'react-bootstrap';
import { ITaxingAuthority, IEFile, IEFileProperty, } from '../../TaxReturn'

export interface FilingEFileProps {
    eFile: IEFile;
    pageNo: number;
    onFilingEFileDataUpdate?: (propertyType: IEFileProperty, value: any, pageNumber: number) => void;
    taxingAuthorities: ITaxingAuthority[];
    enableUpdate: (enable: boolean) => void;
}

export class FilingEFile extends React.Component<FilingEFileProps>
{
    constructor(props: FilingEFileProps) {
        super(props);
    }

    authorityChange = (event: any) => {
        if (this.props.onFilingEFileDataUpdate) {
            this.props.onFilingEFileDataUpdate(IEFileProperty.authorityID, event.target.value, this.props.pageNo);
            this.props.enableUpdate(true);
        }
    }

    formNameChange = (event: any) => {
        if (this.props.onFilingEFileDataUpdate) {
            this.props.onFilingEFileDataUpdate(IEFileProperty.formName, event.target.value, this.props.pageNo);
            this.props.enableUpdate(true);
        }
    }

    render() {
        let authorities: ITaxingAuthority[] = this.props.taxingAuthorities;
        return (
            <div className="panel-body">
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={4} as={FormLabel}>
                        Taxing Authority
                        </Col>
                    <Col sm={8}>
                        <select
                            className="form-control"
                            name="filingFormTaxingAuthority"
                            value={this.props.eFile.authorityID}
                            data-test-auto="60528CE4-87ED-4E8C-9B1D-1F7F4504F965"
                            onChange={(event: any) => { this.authorityChange(event) }}>
                            <option value={0} data-abbreviation=''>Select</option>
                            {authorities.map((authority, index) => {
                                return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                    {authority.AuthorityName}
                                </option>
                            })}
                        </select>
                    </Col>
                </FormGroup >
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={4} as={FormLabel}>
                        Form Name
                        </Col>
                    <Col sm={8}>
                        <FormControl
                            type="text"
                            value={this.props.eFile.formName}
                            placeholder="Form Name"
                            onChange={(event: any) => { this.formNameChange(event) }}
                            data-test-auto="2FB538B3-F17B-4F33-9AF1-B84152632B3F"
                        />
                    </Col>
                </FormGroup>
            </div >
        );

    }
}
