import React, { useEffect, useState } from 'react'
import { IDragAndDropData } from '../DragAndDrop.model';
import DraggableList from '../DraggableList/DraggableList';
import InfoIcon from 'src/components/svg/InfoIcon';


interface DestinationProps{
    destinationData: IDragAndDropData[];
    sourceData: IDragAndDropData[];
    handleDropChange: (
        destinationData: IDragAndDropData[],
        sourceData: IDragAndDropData[]
    ) => void;
    destinationHeading: string;
    destinationHeadingInfoText: string;
    destinationWidth: string;
    destinationHeight: string;
    dndUniqueId: string;
    selectedList: IDragAndDropData[];
    setSelectedList: (selectedList: IDragAndDropData[]) => void;
    disableDragAndDrop: boolean;
    disableDestinationItems?: number[];
}



const Destination =({
    destinationData,
    sourceData,
    handleDropChange,
    destinationHeading,
    destinationHeadingInfoText,
    destinationWidth,
    destinationHeight,
    disableDragAndDrop,
    disableDestinationItems,
    dndUniqueId,
    selectedList,
    setSelectedList    
}: DestinationProps) => {
    
    const [destination, setDestination] = useState<IDragAndDropData[]>([]);

    useEffect(() => {
        setDestination(destinationData);
    }, [destinationData]);

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    }

    const dropHandler = (event: any) => {
        const transferData = JSON.parse(event.dataTransfer.getData("text"));
        const dndUniqueIdTemp = event.dataTransfer.getData("dndUniqueId");
        if (dndUniqueId === dndUniqueIdTemp)
            if (
                destinationData.some((eachDestinationData: IDragAndDropData) => transferData.findIndex((eachTransferData: number) => eachTransferData === eachDestinationData.value) === -1) ||
                !destinationData.length
            ) {
                const transferList = sourceData.filter(
                    (eachSourceData: IDragAndDropData) => transferData.some((eachData: number) => eachData === eachSourceData.value)
                );
                const newDestinationList = [...transferList, ...destinationData];
                const newSourceList = sourceData.filter(
                    (eachSourceData: IDragAndDropData) => !transferData.some((eachData: number) => eachData === eachSourceData.value)
                );
                handleDropChange(newDestinationList, newSourceList);
            }
    }
  return (
    <article className="destination-data-container" style={destinationWidth ? { width: destinationWidth } : null}>
    {destinationHeading && (
        <div className="destination-data-header-div">
            <span className="data-header">{destinationHeading}</span>
            {destinationHeadingInfoText && (
                <span className="destination-data-info" title={destinationHeadingInfoText}>
                    <InfoIcon />
                </span>
            )}
        </div>
    )}
    {disableDragAndDrop ? (
        <ul
            className="list_container"
            style={destinationHeight ? { height: destinationHeight } : null}
            data-dnduniqueid={dndUniqueId}
        >
            <DraggableList
                listData={destination}
                dndUniqueId={dndUniqueId}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
                disableDestinationItems={disableDestinationItems}
            />
        </ul>
    ) : (
        <ul
            className="list_container"
            onDragOver={dragOverHandler}
            onDrop={dropHandler}
            style={destinationHeight ? { height: destinationHeight } : null}
            data-dnduniqueid={dndUniqueId}
        >
            <DraggableList
                listData={destination}
                dndUniqueId={dndUniqueId}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
                disableDestinationItems={disableDestinationItems}
            />
        </ul>
    )}
</article>
  )
}

export default Destination;