import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ILogger } from 'src/Logger/Logger';
import { LoggerFactory } from "src/Logger/LoggerFactory";
import { ApplicationState } from 'src/store';
import { actionCreator } from 'src/store/Logger/LoggerStore';

export let logger : ILogger;

export const initializeLogger = () => {
    logger = new LoggerFactory().getTelemetryLogger();
}

export function logClientEvent(eventName: string, properties: any): void {
    logger.trackEvent(
        logger.buildEvent(
            eventName,
            properties
        )
    );
}

export interface WithLoggerProps{
    logger: ILogger;
}

export const useLogger = (Component) => {
    const logger = useSelector((state: ApplicationState) => state.loggerStore.logger);
    const dispatch = useDispatch();

    if (!logger) {
        dispatch(actionCreator.setLogger);
    }
    return function withLoggerComponent(props) {
        return <Component {...props} logger={logger} />;
      }
}