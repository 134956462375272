import React from  'react';
import { RouteComponentProps } from 'react-router';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as FilterStore from '../../../store/reports/FilterStore';
import * as RecycleGroupedExtensionsStore from '../../../store/groupedExtensions/RecycleGroupedExtensions/RecycleGroupedExtensionsStore';
import {
    RecycleGroupedExtensionsState, RecycleGroupedExtensionsPageState
} from 'src/Core/ViewModels/GroupExtensions/StoreModels';
import { SetAccessType } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { ReportFilterType, SortDirections, IFilters } from '../../reports/Filters';
import RecycleGroupedExtensionsHeader from './RecycleGroupedExtensionsHeader';
import * as bootbox from 'bootbox';
import { ITaxReturn } from '../../common/TaxReturn';
import { HideLoader, ShowLoader } from '../../helper/Loader';
import * as Constants from '../../../components/helper/Constants';
import RecycleGroupedExtensionsTable from './RecycleGroupedExtensionsTable';
import * as Helper from '../../helper/HelperFunctions';
import { RecycleTaxReturns } from '../../common/RecycleTaxReturns';
import moment from 'moment';

type RecycleGroupedExtensionsProps =
    {
        recycleGroupedExtensions: RecycleGroupedExtensionsState;
		reportFilters: FilterStore.IFilterState;
		pageTitle: string;
    }
    & typeof RecycleGroupedExtensionsStore.actionCreators
    & typeof FilterStore.actionCreators
    & RouteComponentProps<{ page: string }>;


const pageSize: number = 20;
const NO_INDEX = -1;

export class RecycleGroupedExtensions extends React.Component<RecycleGroupedExtensionsProps, RecycleGroupedExtensionsPageState> {
	private proxyFilter: any;
	constructor(props: RecycleGroupedExtensionsProps) {
		super(props);
		this.state = {
			selectedRows: [],
			deSelectedRows: [],
			filter: ({
				name: '',
				searchText: '',
				sort: {
					column: "",
					direction: SortDirections.None
				},
				fields: {},
				isDefaultFilter: false,
				filterType: ReportFilterType.SendGroupedExtensions,
				isMasterFilter: false,
			}),

			isBulkSelectionEnabled: false,
			showBulkSelectionMessage: false,
			sortName: "",
			sortOrder: "desc",

			filterCreatedBy: "",
			filterGroupName: "",
			filterSetAccess: SetAccessType.None,
			filterSoftwareType: "",
			page: 1,
			pageSize: pageSize,
			searchString: "",
			saveFilterShow: false,
			selectedDocumentsId: [],
			isSelectAllChecked: false,
			refreshDelay: false,
			selectedGroupIds: []
		}
	}

	componentWillMount() {
		this.props.requestRecycleGroupedExtensions(this.buildQuery(this.state.page, pageSize), true);
	}

	buildQuery = (page: number, pageSize: number) => {
		return '?PageNo=' + page
			+ '&PageSize=' + pageSize
			+ '&SortBy=' + this.state.sortName
			+ '&SortOrder=' + this.state.sortOrder
			+ '&SearchString=' + this.state.searchString
			+ '&filterCreatedBy=' + this.state.filterCreatedBy
			+ '&filterCreatedOn=' + (this.state.filterCreatedOn || "")
			+ '&filterGroupName=' + this.state.filterGroupName
			+ '&filterSetAccess=' + this.state.filterSetAccess
			+ '&filterSoftwareType=' + this.state.filterSoftwareType;
	}
	private onPageReload = () => {
		const query = this.buildQuery(this.state.page, this.state.pageSize);
		this.props.requestRecycleGroupedExtensions(query, true);
		this.clearSelection();
	}
	private clearSelection = () => {
		this.setState({ selectedRows: [], deSelectedRows: [], isBulkSelectionEnabled: false, showBulkSelectionMessage: false });
	}
	private confirmDocumentDelete = (rowIndex: number = NO_INDEX) => {
		const ids = this.onPopupOpen(rowIndex);

		if (ids.length == 0) {
			VenusNotifier.Warning(Constants.RecycleGroupedExtensionConstants.SelectDocumentDeleteWarning, null);
			return;
		}

		bootbox.confirm({
			title: Constants.RecycleGroupedExtensionConstants.Title.DeleteRecyleExtensions,
			message: Constants.RecycleGroupedExtensionConstants.ConfirmMessages.Delete,
			buttons: {
				cancel: {
					label: '<i class="fas fa-times"></i> Cancel',
					className: 'btn-white btn-default'
				},
				confirm: {
					label: '<i class="fas fa-check"></i> Confirm',
					className: 'btn-info'
				}
			},
			callback: (result: boolean) => {
				if (result) {
					this.handleDocumentDelete(ids, result);
				}
			}
		});
	}
	private onPopupOpen(rowIndex: number) {
		if (rowIndex !== NO_INDEX) {
			return [this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions[rowIndex].groupId];
		}
		if (this.state.selectedDocumentsId.length != 0) {
			return this.state.selectedDocumentsId;
		}
		if (this.state.selectedRows.length == 0 && this.state.selectedDocumentsId.length == 0) {
			return [];
		}
		return this.selectedDocumentIds();
	}

	private selectedDocumentIds() {
		let ids: number[] = [];
		for (var i in this.state.selectedRows) {
			const row = this.state.selectedRows[i];

			const taxReturns = this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions[row].taxReturns;
			if (taxReturns.length > 0) {
				taxReturns.map((value, index) => {
					ids.push(value.id);
				});
			}
		}
		return ids;
	}
	private handleDocumentDelete = (selectedDocumentIds: number[], result: boolean) => {
		result && this.props.deleteGroupedExtensions(selectedDocumentIds, this.state.selectedGroupIds, () => {
			this.setState({ selectedRows: [], selectedDocumentsId: [] }, () => this.onPageReload());
		});
	}
	private onRestoreDocument = (rowIndex: number = NO_INDEX) => {
		this.validateExtensionExpiry(rowIndex, this.restoreCallBack);
	}
	private validateExtensionExpiry = (rowIndex: number, callback: (rowIndex: number) => void) => {
		const selectedDocuments = this.selectedDocuments();
		for (let i = 0; i < selectedDocuments.length; i++) {
			if (moment(selectedDocuments[i].deliveredOn).add(selectedDocuments[i].retentionPeriod, 'days') <
				moment(new Date())) {
				VenusNotifier.Warning(Constants.RecycleGroupedExtensionConstants.ExtensionExpired.Restore, null);
				return;
			}
		}
		callback(rowIndex);
	}

	private restoreCallBack = (rowIndex: number) => {
		const ids = this.onPopupOpen(rowIndex);

		if (ids.length == 0) {
			VenusNotifier.Warning(Constants.RecycleGroupedExtensionConstants.SelectDocumentRestoreWarning, null);
			return;
		}

		bootbox.confirm({
			title: Constants.RecycleGroupedExtensionConstants.Title.RestoreRecyleExtensions,
			message: Constants.RecycleGroupedExtensionConstants.ConfirmMessages.Restore,
			buttons: {
				cancel: {
					label: '<i class="fas fa-times"></i> Cancel',
					className: 'btn-white btn-default'
				},
				confirm: {
					label: '<i class="fas fa-check"></i> Confirm',
					className: 'btn-info'
				}
			},
			callback: (result: boolean) => {
				if (result) {
					this.handleDocumentRestore(ids, result);
				}
			}
		});
	}

	private handleDocumentRestore = (selectedDocumentIds: number[], result: boolean) => {
		ShowLoader(Constants.RecycleGroupedExtensionConstants.RestoreProgress);
		if (result) {
			if (this.state.isBulkSelectionEnabled) {
				//not yet implemented
			}
			else {
				this.props.restoreGroupedExtensions(selectedDocumentIds, () => {
					HideLoader();
					this.setState({
						selectedRows: [],
						selectedDocumentsId: [],
						isBulkSelectionEnabled: false,
						showBulkSelectionMessage: false
					}, () => {
						this.onPageReload();
						this.resetRecycleGroupedExtensionsTable();
					});
				});
			}
		}
	}

	private resetRecycleGroupedExtensionsTable = () => {
		let recycleGroupedExtensionsTableRefs: any = this.refs.RecycleGroupedExtensionsTable;
		if (recycleGroupedExtensionsTableRefs && recycleGroupedExtensionsTableRefs.refs.table) {
			recycleGroupedExtensionsTableRefs.refs.table.reset();
			recycleGroupedExtensionsTableRefs.refs.table.setState({ sizePerPage: this.state.pageSize, currPage: 1 });
		}
	}

	private selectedDocuments() {
		let docs: RecycleTaxReturns[] = [];
		if (this.state.isSelectAllChecked && this.state.selectedRows.length > 0) {
			for (var i in this.state.selectedRows) {
				const row = this.state.selectedRows[i];
				const selectedDocuments = this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions[row].taxReturns;
				if (selectedDocuments.length != 0) {
					selectedDocuments.map((value, index) => {
						docs.push(value);
					});
				}
			}
		}
		else {
			const docIds = this.state.selectedDocumentsId;
			const groupIds = this.state.selectedGroupIds;
			groupIds.map((value, index) => {
				this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions.map((groupExt, index) => {
					if (groupExt.groupId == value) {
						docIds.map((docId, index) => {
							groupExt.taxReturns.map((taxReturn, index) => {
								if (taxReturn.id == docId) {
									docs.push(taxReturn);
								}
							});
						});
                    }
				});
			});
        }
		return docs;
	}
	private onFilterChange = (dataField: any, filterType: ReportFilterType) => {

		let newState: RecycleGroupedExtensionsPageState = {
			filterCreatedBy: "",
			filterGroupName: "",
			filterSetAccess: SetAccessType.None,
			filterSoftwareType: "",
			filterCreatedOn: undefined,
		} as RecycleGroupedExtensionsPageState;

		let isClearFilter = true;
		var dict: { [columnName: string]: string; } = {};

		for (let field of Object.keys(dataField)) {
			var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
			if (isClearFilter && data !== "" && data !== "-1") {
				isClearFilter = false;
			}
			dict[field.valueOf().toString()] = data;

			switch (field) {
				case 'createdBy':
					newState.filterCreatedBy = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'groupName':
					newState.filterGroupName = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'setAccess':
					newState.filterSetAccess = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'taxSoftware':
					newState.filterSoftwareType = dataField[field].value ? dataField[field].value : dataField[field];
					break;
				case 'createdOn':
					newState.filterCreatedOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
					break;
			}
		}
		let tempfilter: IFilters = { ...this.state.filter };
		tempfilter.fields = dict;
		tempfilter.filterType = filterType;
		if (isClearFilter) {
			Helper.SessionStore.remove(this.proxyFilter);
		} else {
			Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
		}
		if (this.isFilterChanged(newState)) {
			this.clearSelection();
			this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize },
				() => {
					let recycleGroupedReturnsTable: any = this.refs.RecycleGroupedReturnsTable;
					if (!recycleGroupedReturnsTable.isAppliedFilter)
						this.props.requestRecycleGroupedExtensions(this.buildQuery(this.state.page, this.state.pageSize));
				})
		}
	}

	private isFilterChanged = (newState: RecycleGroupedExtensionsPageState): boolean => {
		return (
			newState.filterCreatedBy !== this.state.filterCreatedBy ||
			newState.filterCreatedOn !== this.state.filterCreatedOn ||
			newState.filterGroupName !== this.state.filterGroupName ||
			newState.filterSetAccess !== this.state.filterSetAccess ||
			newState.filterSoftwareType !== this.state.filterSoftwareType);

	}

	private onPageChange = (page: number, sizePerPage: number) => {
		if (this.state.isBulkSelectionEnabled) {

			this.setState({
				page: page
			}, this.loadGroupedExtensions)
		}
		else {
			this.setState({
				page: page,
				selectedRows: [],
				showBulkSelectionMessage: false,
			}, this.loadGroupedExtensions);
		}
	}
	loadGroupedExtensions = () => {
		const queryString = this.buildQuery(this.state.page, this.state.pageSize);
		this.props.requestRecycleGroupedExtensions(queryString);
	}

	private onRowSelect = (row: any, isSelected: any, e: any) => {
		let newList = [...this.state.selectedRows];
		let deSelectedRows = this.state.deSelectedRows;
		let selectedDocumentsId = [...this.state.selectedDocumentsId];
		let selectedGroupIds = [...this.state.selectedGroupIds];

		if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
			&& e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
			if (isSelected) {

				newList.push(row.rowIndex);
				if (this.state.isBulkSelectionEnabled) {
					index = deSelectedRows.indexOf(row.id);
					deSelectedRows.splice(index, 1);
				}
				const documentIds = this.getTaxDocumentIds(row.groupId as number);
				if (documentIds && documentIds.length > 0) {
					documentIds.map((id, i) => {
						const docIndex = selectedDocumentsId.findIndex(x => x === id);
						if (docIndex === NO_INDEX) {
							selectedDocumentsId.push(id);
						}
					});
				}
				const groupIndex = selectedGroupIds.findIndex(x => x === row.groupId);
				if (groupIndex == NO_INDEX)
					selectedGroupIds.push(row.groupId);
			}
			else {

				var index = newList.indexOf(row.rowIndex);
				if (index > -1) {
					newList.splice(index, 1);
				}
				if (this.state.isBulkSelectionEnabled) {
					deSelectedRows.push(row.id);
				}
				const documentIds = this.getTaxDocumentIds(row.groupId as number);
				if (documentIds && documentIds.length > 0) {
					documentIds.map((id, i) => {
						const docIndex = selectedDocumentsId.findIndex(x => x === id);
						if (docIndex !== NO_INDEX) {
							selectedDocumentsId.splice(docIndex, 1);
						}
					});
				}
				const groupIndex = selectedGroupIds.findIndex(x => x === row.groupId);
				if (groupIndex !== NO_INDEX)
					selectedGroupIds.splice(groupIndex, 1);
			}
			this.state.isBulkSelectionEnabled ?
				this.setState({
					deSelectedRows: deSelectedRows, selectedRows: newList,
					selectedDocumentsId: selectedDocumentsId
				}) :
				this.setState({
					selectedRows: newList,
					selectedDocumentsId: selectedDocumentsId,
					selectedGroupIds: selectedGroupIds
				});
		}
		this.forceUpdate();
	}

	private getTaxDocumentIds = (groupId: number) => {
		let documentIds: number[] = [];
		this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions.map((group, i) => {
			if (group.groupId === groupId) {
				group.taxReturns.map((taxReturn, i) => {
					documentIds.push(taxReturn.id);
				});
			}
		});
		return documentIds;
	}

	private onSearchChange = (searchString: string) => {
		let temp: IFilters = { ...this.state.filter };
		temp.searchText = searchString;
		this.clearSelection();
		Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
		this.setState({
			searchString: searchString,
			filter: temp

		}, () => this.fetchRecycleGroupedExtensions(1, pageSize));
	}

	fetchRecycleGroupedExtensions(page: number, sizePerPage: number) {
		const queryString = this.buildQuery(page, sizePerPage);
		this.setState({ page: page, pageSize: sizePerPage }, () => {
			this.props.requestRecycleGroupedExtensions(queryString);
		});
	}

	private onSelectAll = (isSelected: any, rows: any, e: any) => {
		let selectedRows: number[] = [];
		let showBulkSelectionMessage = false;
		let selectedDocumentsId: number[] = [];

		if (isSelected) {
			let count = rows && rows.length ? rows.length : 0;
			Array.from(Array(count).keys());
			selectedRows = Array.from(Array(count).keys());

			showBulkSelectionMessage = true;
		}

		this.setState({ isSelectAllChecked: isSelected });

		this.setState({
			selectedRows: selectedRows,
			deSelectedRows: [],
			showBulkSelectionMessage: showBulkSelectionMessage,
			isBulkSelectionEnabled: false,
			selectedDocumentsId: selectedDocumentsId
		});
	}

	private onSortChange = (sortName: string, sortOrder: string, column: number) => {
		let temp: IFilters = { ...this.state.filter };
		temp.sort.column = sortName;
		temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
		Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

		this.setState({
			filter: temp,
			sortName: sortName,
			sortOrder: sortOrder == "asc" ? "asc" : "desc"
		}, () => this.fetchRecycleGroupedExtensions(1, pageSize));
	}

	onDocumentSelect = (Ids: number[]) => {
		let newList = [...this.state.selectedDocumentsId];
		let newGroupIdList = [...this.state.selectedGroupIds];
		Ids.map((value, index) => {
			newList.push(value);
			var GroupID = this.getGroupId(value);
			const groupIndex = newGroupIdList.findIndex(x => x === GroupID);
			if (groupIndex == NO_INDEX)
				newGroupIdList.push(GroupID)
		});
		this.setState({
			selectedDocumentsId: newList,
			selectedGroupIds: newGroupIdList
		});
	}

	onDocumentUnSelect = (Ids: number[]) => {
		let newList = [...this.state.selectedDocumentsId];
		Ids.map((value, index) => {
			var docIndex = newList.indexOf(value);
			if (docIndex > -1) {
				newList.splice(docIndex, 1);
			}
		});
		this.setState({
			selectedDocumentsId: newList
		});
	}

	private getGroupId = (documentId: number) => {
		let groupId: number = 0;
		this.props.recycleGroupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions.map((group, i) => {
			group.taxReturns.map((taxReturn, i) => {
				if (taxReturn.id === documentId)
					groupId = group.groupId;
			});
		});
		return groupId;
	}

    public render() {
		return <div className='user-assignment-content devilvered-archived-screen'>
			<RecycleGroupedExtensionsHeader
				pageTitle={this.props.pageTitle}
				selectedDocumentCount={this.state.selectedDocumentsId.length}
				selectedRowCount={this.state.selectedRows.length}
				onPageReload={this.onPageReload}
				onDeleteDocument={this.confirmDocumentDelete}
				refreshDelay={this.state.refreshDelay}
				onRestoreDocument={this.onRestoreDocument}
				isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
			/>
			<br />
			<RecycleGroupedExtensionsTable
				ref='RecycleGroupedReturnsTable'
				clearSelection={this.clearSelection}
				currentFilter={this.state.filter}
				filterType={ReportFilterType.SendGroupedExtensions}
				isLoading={this.props.recycleGroupedExtensions.loading}
				onFilterChange={this.onFilterChange}
				onPageChange={this.onPageChange}
				onPageReload={this.onPageReload}
				onRowSelect={this.onRowSelect}
				onSearchChange={this.onSearchChange}
				onSelectAll={this.onSelectAll}
				onSortChange={this.onSortChange}
				pageNo={this.state.page}
				pageSize={pageSize}
				selectedRows={this.state.selectedRows}
				groupedExtensions={this.props.recycleGroupedExtensions}
				totalRows={this.props.recycleGroupedExtensions.totalRowCount}
				onDocumentSelect={this.onDocumentSelect}
				onDocumentUnSelect={this.onDocumentUnSelect}
				selectedDocumentsId={this.state.selectedDocumentsId}
			/>
		</div>;
    }
}