import moment from 'moment';
import { EngagementType } from '../common/TaxReturn';
import { ReportFilterType, SortDirections } from '../reports/Filters';


export const Common = {
    ButtonLabel: {
        Cancel: "Cancel",
        Ok: "Ok",
        Save: "Save",
        Delete: "Delete"
    },
    OverlayMessages: {
        Downloading: "Downloading...Please wait..",
        Processing: "Processing...Please wait",
        Deleting: "Deleting...Please wait",
        ScreenSharing: "Starting screen share...Please wait"
    },
    Images: {
        ContactIcon: "./dist/images/icon-contact-header.svg",
    },
    Messages: {
        Error: {
            DashboardLinkFetchFailed: "Unable to fetch Dashboard Link"
        }
    }
}

export const clearedFilter={
    name: '',
    searchText: '',
    sort: {
        column: "",
        direction: SortDirections.None
    },
    fields: {},
    isDefaultFilter: false,
    filterType: ReportFilterType.None,
    isMasterFilter: false,
}


export const UserManagementConstants = {
    SelectUserGroupWarning: 'Please select atleast one user role in Memberships.',
    LoadingUserError: 'An Error occured when loading users.Please Try Again.',
    SavingUserError: 'Saving user details failed, please re-login',
    UserUpdationError: 'Updating user details failed, please re-login',
    EditUserLoadingError: 'User Loading failed, please re-login.',
    DeletingUserError: 'Deleting user failed, please re-login',
    SelectUserWarning: 'Please select a user from the list.',
    SelectGroupWarning: 'Please select a group from the list.',
    SaveUserSuccess: 'User added successfully',
    DeleteUserSuccess: 'Selected user has been deleted',
    UserUpdationSuccess: 'User details has been updated.',
    DeleteUserConfirmation: 'Are you sure you want to delete this user?',
    AddUserDetails: 'Add User Details',
    SetPAssword: 'Set Password',
    PasswordChangeOnNextLogin: 'User must change password on next login',
    ManageMembership: 'User Group',
    UserManagement: 'User Management',
    EditUserDetails: 'Edit User Details',
    ChangePassword: 'Change Password',
    UserExists: 'User already exists.',
    UserPasswordSuccess: 'Password changed successfully',
    PasswordMustBeEightCharacters: 'Your password must be at least 8 characters.',
    DeleteLoggedInUser: 'You cannot delete the logged in user',
    DependentUsers: 'Warning:  You are trying to delete a user that has been selected as the Contact Person for extensions that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.',
    SignatureFlowDisabledWarning: 'Signature service  is currently disabled for your company. Please enable from company settings.',
    ExportToExcel: 'Export to Excel',
    MobileNumberEmpty: 'Mobile Number can\'t be empty',
    ResetPasswordHeading: 'Send Reset Password Link',
    ResetPasswordBodyText: `An email with a reset password link will be sent to the user's registered email address. Do you wish to continue?`,
    ResendTemporaryPasswordHeading: 'Send Temporary Password',
    ResendTemporaryPasswordBody: `An email with a temporary password will be sent to the user's registered email address. Do you wish to continue?`,
    TemporaryPasswordMailConfirmation: 'Temporary password mail is successfully sent to %USEREMAIL%',
    ResetPasswordMailConfirmation: 'Reset password mail is successfully sent to %USEREMAIL%'
}

export const ValidationContants = {
    FirstNameWarning: 'Please enter first name.',
    LastNameWarning: 'Please enter last name.',
    EmailAdrressWarning: 'Please enter email address.',
    ValidEmailAddressWarning: 'Please enter valid  email address.',
    EmailNotMatching: 'Email and Confirm Email did not match',
    PhoneNumberWarning: 'Please enter the phone number.',
    PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
    MobileNumberWarning: 'Please enter the mobile number.',
    MobileNumberLengthWarning: 'Please enter a 10 digit mobile number.',
    CountryCodeWarning: 'Please select the country code.',
    PTINWarning: 'PTIN should start with P followed by 8 digit number.',
    EnterPasswordWarning: 'Please enter password and confirm password.',
    PasswordNotMatchedWarning: 'Password and confirmed password did not match.',
    NewPasswordWarning: 'Please enter new password.',
    OldPasswordWarning: 'Please enter old password',
    ConfirmPasswordWarning: 'Please enter confirm password',
    PasswordLengthWarning: 'Password must be 8 characters.',
    PasswordWithoutSpaceWarning: 'Please enter password without space.',
    ZipWarning: 'Please enter zip.',
    ZipLengthWarning: 'Please enter a 5 digit zip.',
    ExtensionWarning: 'Please enter less than 7 digit extension number.',
    FaxWarning: 'Please enter a 10 digit fax number.',
    StateWarning: 'Please select state.',
    CityWarning: 'Please enter city.',
    ValidCityWarning: 'Please enter valid city name.',
    PdfFileValidation: 'Please upload Pdf files only.',
    PaymentURLValidation: 'Please enter a valid payment URL.',
    FirmKeyWarning: 'Please enter valid firm key.',
    AzureGroupIdWarning: 'Please enter valid Azure Group Id.',
    PasswordWarning: 'Please enter password.',
    K1InstructionNotAVailable: 'K1 instruction is not available in the uploaded file',
    NoContentAvailable: 'No content available',
    EINWarning: 'Please enter valid EIN.',
    FileFormatNotSupported: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
    UpperCaseValidationError: 'Password should contain at least one uppercase character.',
    LowerCaseValidationError: 'Password should contain at least one lowercase character.',
    NumbersValidationError: 'Password should contain at least one number.',
    SpecialCharactersValidationError: 'Password should contain at least one special character.',
    CsvFileValidation: 'Please upload Csv files only.',
    SameEmailValidation: 'Email Already selected.'
}


export const CustomColumnConstants = {
    ValidationMessages: {
        CustomColumnValueWarning: "Please enter column value.",
        CustomColumnValueAlreadyExist: "Custom column value already exists.",
        CustomColumnLength: "Please Enter Maximum of 20 characters.",
        CustomColumnAddValidation: "Unable to add values unless a column name is specified first.",
        CustomColumnSaveValidation: "Unable to add values unless a column name is saved/updated."
    },
    StatusMessage: {
        CustomColumnValueAddSuccess: 'Custom column value added successfully.',
        CustomColumnValueAddError: 'Error occured while adding custom column value.',
        CustomColumnValueLength: "Please Enter Maximum of 100 characters.",
        CustomColumnValueUpdateSuccess: 'Custom column value updated successfully.',
        CustomColumnValueUpdateError: 'Error occured while updating custom column value.',
        CustomColumnValueDeleteSuccess: 'Custom column value deleted successfully.',
        CustomColumnValueDeleteError: 'Error occured while deleting custom column value.',
        CustomColumnNameUpdateSuccess: 'Custom column name updated successfully.',
        CustomColumnNameUpdateError: 'Error occured while updating custom column name.',
        CustomColumnHeaderDeleteSuccess: 'Custom column header deleted successfully.',
        CustomColumnHeaderDeleteError: 'Error occured while deleting custom column header.'
    },
    OverlayMessage: {
        UpdatingCustomColumnName: 'Updating custom column name, please wait...',
        AddingCustomColumnValue: 'Adding custom column value, Please wait...',
        UpdatingCustomColumnValue: 'Updating custom column values, Please wait...',
        DeletingCustomColumnValue: 'Deleting custom column values, Please wait...',
    },
    WarningMessage: {
        DeletingCustomColumnHeader: "The Custom Column you are trying to delete will delete all its corresponding values and it cannot be recovered. Are you sure you want to delete it?",
    }
}
export const DocumentTypeConstants = {
    ValidationMessages: {
        DocumentTypeValueWarning: "Please enter column value.",
        DocumentTypeAlreadyExist: "Document type already exists.",
        DocumentTypeValueLength: "Please Enter Maximum of 40 characters.",
    },
    StatusMessage: {
        DocumentTypeAddSuccess: 'Document type value added successfully.',
        DocumentTypeValueAddError: 'Error occured while adding Document type value.',
        DocumentTypeUpdateSuccess: 'Document type value updated successfully.',
        DocumentTypeValueUpdateError: 'Error occured while updating Document type value.',
        DocumentTypeValueDeleteSuccess: 'Document type value deleted successfully.',
        DocumentTypeValueDeleteError: 'Error occured while deleting Document type value.',
    },
    OverlayMessage: {
        AddingDocumentTypeValue: 'Adding document type value, Please wait...',
        UpdatingDocumentTypeValue: 'Updating document type value, Please wait...',
        DeletingDocumentTypeValue: 'Deleting document type value, Please wait...',
    },
}

export const CompanySettingsConstants = {
    StatusMessage: {
        CompanySettingsError: 'Error occured while fetching company settings',
        CompanyProfileError: 'Error while loading company profile',
        CompanyLogoError: 'Error while loading company logo',
        UpdateCompanySettingsSuccess: 'Company settings updated successfully.',
        UpdateCompanySettingsError: 'Error occured while updating company settings',
        UpdateRetentionPeriodError: 'Error occured while applying retention period for all documents',
        UpdateCompanyProfileSuccess: 'Company Information saved',
        UpdateRetentionForAllDocuments: 'Retention Settings applied successfully',
        UpdateCompanyLogoError: 'Unable to update logo, Please try again',
        AdminProfileError: 'Error while loading company profile',
        ADAzureExist: 'Group ID has already been added on a different Account. Please enter a different Group ID'
    },
    OverlayMessage: {
        UpdatingCompanySettings: 'Updating company settings...',
        ApplicationLoading: 'Loading, please wait...'
    },
    Validate: {
        CompanyNameWarning: 'Please enter company name.',
        AddressWarning: 'Please enter street address 1.',
        StateWarning: 'Please select state.',
        WebsiteWarning: 'Please enter valid website.',
        ImageWarning: 'Please upload only image.',
        ImageDimensionWarning: 'Max dimensions are 300x100px.',
        ImageValidation: 'Image validation failed! try again...',
        TrustedDeviceExpiryDays: 'Please enter the days between 1 to 60.',
        ClientIdWarning: "Please enter Client ID.",
    },
    HeaderName: {
        MyCompany: 'My Company',
        CompanyDetails: 'Company Details',
        PrimaryAdmin: 'Primary Admin',
        CompanyLogo: 'Company Logo',
        EditCompany: 'Edit Company Details',
        ChangePrimaryAdmin: 'Change Primary Admin',
        UploadCompanyLogo: 'Upload Company Logo',
        CompanyLogoMessage: 'Your selected logo will be used on all web pages, emails and notifications (Company Logo Dimension should be less than 300x100px)',
        PrimaryAdminMessage: 'Primary Admin is always a system administrator'
    },
    MFASettings: {
        HelpText: "When mobile number is provided in Client info, access code will be sent through text/SMS. If the mobile number is not provided then access code will be sent through email."
    }

}

export const ReturnMailingAddressConstants = {
    ValidationMessages: {
        TitleWarning: 'Please enter title',
        NameWarning: 'Please enter name',
        StreetAddress1Warning: 'Please enter street address1.',
        ManualAddressTitlePresentWarning: 'Title is already present, please enter different title',
    },
    StatusMessage: {
        ManualAddressUpdateSuccess: 'Manual address updated successfully.',
        ManualAddressAddSuccess: 'Manual address added successfully.',
        SavingManualAddress: 'Saving manual address, please wait...',
        Loading: 'Loading Manual Address, please wait...',
        ManualAddressDeleteSuccess: 'Manual Address deleted successfully.',
        ManualAddressDeleteFailed: 'You can\'t delete this manual address as it is being used.',
        FecthingManualAddress: "Fetching manual address, please wait...",
        AddingManualAddress: "Adding manual address, please wait...",
        UpdatingManualAddress: "Updating manual address, please wait...",
        DeletingManualAddress: "Deleting manual address, please wait...",
    }
}

export const ReportFilterConstants = {
    StatusMessage: {
        SavedSuccessMessage: "Filter saved successfully.",
        SavedErrorMessage: "Failed to save the filter!",
        UpdateSuccessMessage: "Filter updated successfully.",
        UpdateErrorMessage: "Failed to update the filter!",
        DeleteSuccessMessage: "Filter deleted successfully.",
        DeleteErrorMessage: "Failed to delete the filter!",
        GetAllErrorMessage: "Failed to fetch the filters!",
    },
    ValidationMessage:
    {
        NoRecentFilters: "There is no recent filter to apply!",
    },
    ControlsLabel:
    {
        ApplyRecentFilterBtn: "Apply recent filter",
        SaveCurrentFilterBtn: "Save current filter",
        SetAsDefaultFilter: "Set as Default Filter",
        DeleteFilter: "Delete Filter",
        ExportToExcel: "Export to Excel",
        ClearFilter: "Clear Filter",
        RemoveDefault: "Remove Default",
        PleaseEnterTheFilterName: "Please enter the filter name",
        Apply: "Apply",
        SetDefault: "Set Default",
        Delete: "Delete",
        AppliedFilter: "Applied Filter"
    },
    OtherMessage: {
        DeleteFilter: "Are you sure you want to delete this filter?",
    },
    ControlsTitle: {
        ApplyMostRecentFilter: "Apply Most Recent Filter",
        ToggleDropdownMenu: "Toggle dropdown menu"
    },
    DefaultFilterBuilder: "_EXT"//This suffix is trimmed to maintain total length to 25,
}

export const DeliveredReturnsTableConstants = {
    Title: {
        FilterSaveUpdate: "Save/Update Filter",
    },
    OtherMessage: {
        DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?",
        NoReturnsFound: "No extensions found"
    },
    ControlLabel: {
        YesCreateNew: "Yes, Create New",
        NoUpdateTheCurrent: "No, Update The Current"
    }
}

export const VoucherReminderManagementConstants = {
    OtherMessage: {
        NoDataFound: "No records found"
    },
    ControlsLabel:
    {
        ExportToExcel: "Export to Excel"
    }
}

export const DeliveredReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!"
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!."
    },
    WarningMessage: {
        SendReminderWarning: "For the selected extensions, Automatic reminders has been enabled, still do you want to send the reminder?"
    },
    BulkOperationMessage: {
        SendReminderWarning: `Signing Reminder can be sent for the extensions which are in 'Awaiting Upload' and 'Awaiting Esign' status. 
                               \nAlso, Automatic reminder has been enabled for the selected extensions. 
                               \nDo you still want to continue ?`,
        SendReminderWaitProcessing: "We are processing the reminders for emailing. This may take several minutes",
        SendReminderError: 'We are unable to send reminder for these documents.',

        Processing: "This may take several minutes. Thank you for your patience.",
        CustomColumnError: 'We are unable to change custom column for these documents.',

        ArchiveError: 'We are unable to archive the selected documents.',
        downloadEFileWarning: `E-File forms can be downloaded for the extensions which are in "Esigned", "Uploaded", "Signed and Esigned" and "Alternate and Uploaded" Status.
                               \nDo you want to continue?`,
        downloadEFileWaitProcessing: 'Download process may take some time , the downloaded Efile forms will be available in "My Downloads"',
        downloadEFileError: 'Download Failed - An error occurred while downloading the selected records. Please try again',
        DeleteReturnsError: 'We are unable to delete the selected documents.',
        ExportToExcelError: 'We are unable to complete the operation.',
        downloadEFileNowWaitProcessing: 'Please wait as we are preparing your files',
    },
    ErrorMessage: {
        UploadedESignedEfileFormsCanOnlyDownloadable: "The E-file forms in the following status - 'Uploaded', 'E-signed', 'Signed & E-signed', can be downloaded."
    }
}

export const InUseExtensionsConstants = {
    ValidationMessage: {
        NoDocumentSelectedWarning: "Please select document(s) to Make available!"
    }
}

export const RecyleReturnsConstants = {
    SelectDocumentWarning: "Please select document(s) to delete!",
    SuccessMessages: {
        Delete: "Deletion in progress, Please refresh after sometime."
    },
    ConfirmMessages: {
        Delete: `Are you sure you want to delete the selected extension(s)? On Delete, System will
                \npermanently delete all the extension(s) from the SafeSend Extensions application and they
                \nwill be no longer available to anyone and the Taxpayer's access link will be
                \ndisabled permanently.`,
    },
    Title: {
        RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete"
    },
    ErrorMessages:{
        Delete: 'We are unable to delete the selected documents.'
    }
}

export const RestoreReturnConstants = {
    SelectDocumentWarning: "Please select document(s) to restore!",
    Progress: "Restore in progress...",
    SuccessMessages: {
        Restore: "Selected extension(s) are restored successfully."
    },
    ConfirmMessages: {
        Restore: `Are you sure you want to restore the selected extension(s)?`,
    },
    Title: {
        Restore: "<i class='fas fa-undo'/> Restore"
    },
    ReturnExpired: {
        Restore: "You have selected one or more expired extensions. You can't restore the expired extension."
    },
    BulkOperationMessage: {
        Processing: "This may take several minutes. Thank you for your patience.",
        Error: 'We are unable to restore the selected documents.'
    }
}

export const DeleteRecycledExtensionConstants = {
    Progress: "Delete in progress...",
    BulkDeleteOperationMessage: {
        Processing: "This may take several minutes. Thank you for your patience.",
        Error: 'We are unable to delete the selected documents.'
    }
}

export const SavedMessageConstants = {
    SaveMessageSuccess: 'Message added successfully',
    SaveMessageNameError: 'Name already exists',
    SaveMessageError: "Failed to save message",
    UpdateMessageError: "Failed to update message",
    UpdateMessageSuccess: 'Message Updated successfully',
    DeleteMessageSuccess: 'Message deleted successfully',
    ValidateName: 'Please enter a message name.',
    ValidateSubject: 'Please enter a message subject.',
    ValidateBody: 'Please enter a message body.',
    DeleteMessageWarning: 'You cannot delete the default message',
    SavedMessageWarning: "Message details",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SelectDefaultMessageWarning: "Please select a default message first",
}

export const ClientInstructionConstants = {
    SaveMessageSuccess: 'Instruction added successfully',
    UpdateMessageSuccess: 'Instruction Updated successfully',
    DeleteMessageSuccess: 'Instruction deleted successfully',
    ValidateName: 'Please enter instruction name.',
    ValidateHeader: 'Please enter instruction header.',
    ValidateText: 'Please enter instruction text.',
    ValidateSubject: 'Please enter instruction subject.',
    DeleteInstructionWarning: 'You cannot delete the default client instruction.',
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SaveMessageError: "Failed to save client instruction",
    UpdateMessageError: "Failed to update client instruction",
    DeleteMessageError: "Failed to delete client instruction",
    FetchMessageError: "Failed to fetch client instruction"
}

export const AttachmentInstructionsConstants = {
    Success: 'Attachment instruction added successfully',
    NameError: 'Attachment instruction name already exists',
    SaveError: 'Failed to save attachment instruction',
    UpdateError: "Failed to update attachment instruction",
    UpdateSuccess: 'Attachment instruction Updated successfully',
    DeleteSuccess: 'Attachment instruction deleted successfully',
    DeleteError: 'Unable to delete attachment instruction',
    ValidateName: 'Please enter attachment instruction name.',
    ValidateText: 'Please enter attachment instruction text.',
    DeleteWarning: 'You cannot delete the default attachment instruction',
    SetDefaultWarning: "This message will be set as default attachment instruction",
    RemoveDefaultWarning: "This message will be removed as default attachment instruction",
    SelectDefaultWarning: "Please select a default attachment instruction first",
}

export const UserSettings = {
    fetchUserSettings: "Failed to fetch User Settings",
    saveUserSettingsSuccess: "User Settings saved successfully",
    saveUserSettingsError: "Failed to save User Settings",
    saveSignatureDelegations: "Failed to save delegates"
}
export const ArchiveReturnsConstants = {
    downloadEformFilesState: "Could not find downloadable Efile document(s).",
    downloadDocument: "Could not find downloadable document(s).",
    PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
    PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
    UploadedESignedEfileFormsCanOnlyDownloadable: "Error: Only Uploaded & E-Signed e-file forms can be downloaded",
    OneOrMoreItemsWereNotRemovedAsThierValuesDidNotMatchTheSelectedValue: "One or more items were not removed as thier values did not match the selected value.",
    documentRestoreConfirmation: "Are you sure you want to restore selected document(s)?"
}

export const ReportProblemConstants = {
    OverlayMessage: {
        SubmittingIssue: "Submitting your issue, Please wait..."
    },
    SuccessMessage: 'Problem reported successfully.Your Tracking ID is ',
    ErrorMessage: 'Failed to report the problem!'
}
export const AttachmentConstants = {
    FilesizeWarning: 'File size limit should not exceed to 1 GB.',
    validDocument: 'Please select a valid document, currently selected file is empty.',
    AddAttachments: 'Add Attachments',
    UploadedFiles: 'Uploaded Files',
    MaxFileSize: '1091900799',
    UploadingIncomplete: 'Please wait for uploading to complete before adding instructions'
}

export const UploadtaxReturnConstants = {
    SetEROSignerWarning: "Please select a ERO / Signer to submit.",
    SubmitTaxReturnSuccess: "The following extension was successfully submitted",
    SubmitTaxReturnFailed: "Failed to submit the following extension, please try again",
    AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
    CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
    UploadingFailedError: "Uploading failed, please try again",
    DeleteFileMessage: "Are you sure you want to delete this file?",
    Recall: {
        FileAddingWarning: "one file is already added.",
        MultipleFileAddingWarning: "only one file can be uploaded."
    },
    ReturnSubmitSuccess: "The following extension(s) were successfully submitted",
    EmptyFileListMessage: "Files list will appear here.",
    OverlayMessage: {
        ProcessTaxReturn: 'Processing tax return extension...',
        ApplicationLoading: 'Loading, please wait...'
    },
    StatusMessage: {
        TaxReturnLinkError: 'Error while fetching tax return extension link.',
        ProcessTaxReturnError: 'Error occured while processing tax return extension',
        UpdateTaxReturnError: 'Unable to update tax return extension, Please try again',
    },
    GroupedReturnSubmitSuccess: "The following return(s) were successfully submitted",
    GroupedReturnNote: "Please note: If you are delivering a 1040 tax extension via Grouped Extensions, you may need to confirm a completed POA is on file or signer delegation is being used to complete the e-signature step.",
    PdfDocumentPasswordProtectedError: " file is encrypted and signature controls can't be placed on it.",
    };

export const AdditionalEsignDocuments = {
    uploadAdditionalSignFileWarning: 'Please upload .doc, .docx or .pdf files only.',
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "File(s) list will appear here.",
    fetchDocumentError: "Error while fetching document link.",
    CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
    uploadInProgress: "File(s) upload in progress",
    abortingUploadWarning: "Aborting upload cannot be processed in between, please wait."
}

export const MyAccountConstants = {
    //API response
    APIResponse: {
        UserUpdateSuccess: "User updated successfully."
    },
    UserUpdateFailedMessage: "Updating user details failed, please re-login",
}

export const ChangePasswordConstants =
{
    PasswordChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
    PasswordUpdateSuccess: "Password updated successfully.",
    PasswordUpdateFail: "Updating password failed, please re-login",
}

export const SaveandCloseProcessReturn = {
    StatusMessage: {
        Success: "Your changes are saved successfully",
        Fail: "Error occured while saving your changes.",
    }
}

export const FinishProcessReturn = {
    MenuItems: {
        SendForReview: 'Send for Review',
        SendToEROSigner: 'Send to ERO / Signer',
        ApproveForDelivery: 'Approve for Delivery',
        DeliverToClient: 'Deliver to Client',
        DeliverToCompany: 'Deliver to Company',
        ReturnToProcessor: 'Return to Processor',
        DeliverToTaxCaddy: 'Deliver to TaxCaddy',
        DeliverWithTaxCaddy: 'Deliver with TaxCaddy',
        RetentionPeriod: 'Modify Retention Period',
        PaperDelivery: 'Download PDF',
        Empty: "",
    },
    StatusMessage: {
        SendToEROSuccess: "Sent to ERO successfully.",
        SendForReviewSuccess: "Sent for review successfully.",
        ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
        DeliverToClientSuccess: "Delivered to client successfully.",
        SendToEROError: "Error occured while sending to ERO.",
        SendForReviewError: "Error occured while sending for review.",
        ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
        DeliverToClientError: "Error occured while delivering to client.",
        GenerateTaxpayerViewSuccess: "Loaded taxpayer view successfully.",
        GenerateTaxpayerViewError: "Error occured while loading the taxpayer view",
        DeliverToClientWithoutInvoiceError: "You are required to include an invoice and amount due with this Tax Return Extension. Please return to the Invoice Step and ensure and invoice is included and the amount is entered in the proper field.",
        SelectApprover: "Please select a user to approve for delivery.",
        SelectDeactivatedDeletedAuthority: "Please Select Alternate Authority for Deleted/DeActivated Authority.",
        TaxCaddyLookupError: "Unable to communicate to TaxCaddy server, please contact support.",
        RetentionValidationMessage: "Please specify a valid retention period.",
        AttachmentValidationMessage: "Attachments status are either uploading or failed to upload!",
    },
    InfoMessage: {
        AttchmentNotDeliverToTaxCaddy: "Attachments will not be delivered to TaxCaddy",
        TaxCaddyApprove: "On Accept, this extension will be delivered to TaxCaddy",
        TaxCaddyReject: "On Reject, this extension will not be delivered to TaxCaddy",
        NotDeliveredToTaxcaddy: ' This extension will not be delivered to TaxCaddy'
    },
    HighlightForm: {
        NoSignPlaced: 'The highlighted forms are marked as Signature Required, but signature controls are not placed. Do you still want to continue?'
    }
}

export const PredefinedFileName = {
    K1InstructionBlob: "6c1525a7-69d4-4ca2-b67b-495049f1d132",
    FileNotFound: "FileNotFound"
}

export const K1InstructionFileName = {
    E1065_2015: "Partner`s Instructions for Schedule K-1 (Form 1065)(2015).pdf",
    E1065_2016: "Partner`s Instructions for Schedule K-1 (Form 1065)(2016).pdf",
    E1065_2017: "Partner`s Instructions for Schedule K-1 (Form 1065)(2017).pdf",
    E1120S_2015: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2015).pdf",
    E1120S_2016: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2016).pdf",
    E1120S_2017: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2017).pdf"
}



export const InvoiceReplaceConstants = {
    //API response
    InvoiceAmountWarning: "Please enter a invoice amount.",
    BookmarkTextWarning: "Please enter bookmark text.",
    NonPdfFileWarning: "Please upload PDF file only.",
    MultipleFileWarning: "Please upload one file."
}

export const Assignment = {
    Description: "DESCRIPTION"
}

export const AssignUserConstants = {
    SelectDocumentWarning: "Please select document(s) to assign to another user!",
    RecalledReturnWarning: "extension(s) cannot be assigned when their status is RECALLED."
}

export const SetAccessConstants = {
    SelectDocumentWarning: "Please select document(s) to set access for other users!",
    RecalledReturnWarning: "Access cannot be set when status is RECALLED.",
    AccessRetricted: "You don't have the permission to assign to another user!"
}

export const ChangeStatusConstants = {
    SelectedStatus: "Please select status",
    Title: "Change Status",
    Error: "Cannot change status of the selected document!",
    changeStatusConfirmation: "You are about to change the status of the document manually. Do you want to proceed?",
    ChangeDeliveredReturnStatus: "Warning: This document has not been E-Signed by all parties. Changing the status to \"Signed & ESigned\" indicates that you have obtained the remaining signatures manually (i.e. fax, email, mail etc.). The audit trail for this document will only include those signers who have E-Signed.",
    EmptyReason: "Please enter a reason for changing status"
}

export const UploadCorrectedReturnWarning = {
    message: "If you upload a different return type from the recalled one, it will count towards your usage."
}

export const DeleteTaxReturnConstants = {
    Title: "Delete Tax Return Extension",
    SelectDocumentWarning: "Please select document(s) to delete!",
    InUseReturnWarning: "Warning: In-use extensions are not allowed to be deleted!",
    ConfirmDeletion: 'You are about to delete the selected document(s). This cannot be undone.\nDo you want to proceed?',
}

export const BusinessReturnConstants = {
    WarningNonPdfFile: "Please upload PDF file",
    DocumentReplacedSuccessMessage: "Document replaced successfully",
    ReplaceDocumentLoaderMessage: "Replacing Document...",
    getEngagementTypeDisplayName: function (engagementType: EngagementType): string {
        let type: string = '';
        switch (engagementType) {
            case EngagementType.E1065: type = "1065";
                break;
            case EngagementType.E1120S: type = "1120S";
                break;
            case EngagementType.E1041: type = "1041";
                break;
        }
        return type;
    },
    FetchInstructionsErrorMessage: "Failed to fetch Business Return Extension Instructions.",
    FetchInstructionErrorMessage: "Failed to fetch Business Return Extension Instruction."
}

export const CustomWindowConstants = {
    closePopoverMessage: "Your changes will be discarded, Are you sure you want to close the extension?"
}

export const CompanySettingConstants = {
    closePopoverMessage: "As a result of changing your retention policy, %COUNT% extensions will be deleted on %DATE%. Would you like to apply this change?"
}

export const ClientInstructionMessageVariables = {
    CompanyName: "The listed Name of your company on your account",
    TaxPayerName: "The listed Taxpayer on the Tax Return Extensions",
    SpouseName: "The listed Spouse on the Tax Return Extensions",
    HelpContact: "The name of the user designated for customer support",
    HelpPhone: "The phone number of the user designated for customer support",
    HelpEmail: "The email address of the designated help contact",
    PrepareName: "The listed Preparer on the Tax Return Extensions",
    NumberOfays: "Number of days set for voucher reminder",
    Recipient: "The person name for which the email is being sent",
    TaxYear: "The Taxyear on the Tax Return Extensions",
    EROName: "The name of the assigned ERO Signer",
    SenderName: "The name of the user selected as the 'Sender' of the Extensions",
    ControllerName: "Controller name of the group"
}

export const Version = {
    VersionNumber: 'V 1.0'
}

export const ReGroupConstants = {
    PageMoveSuccess: "Page moved successfully",
    DeletedPageMoveSuccess: "Page moved to Deleted successfully",
    SameSourceAndDestinationError: "Unable to move selected forms as source and destination groups are same",
    PageDeleteNotAllowed: "Action Not Allowed: There must be at least one page existing in this group."
}

export const PageToolBarConstants = {
    moveToDeleteConfirmation: "Are you sure you want to move this page to deleted?"
}

export const ReturnDownloadOptionsConstants = {
    IncludeInTaxReturnPdf: "Include in the Filing Form PDF",
    CreateSeperatePdf: "Create a Separate PDF",
    SeparateByQuarterlyDueDates: "Separate by Quarterly Due Dates",
    GroupInSinglePDF: "Group in Single PDF"
}

export const ProcessReturnConstants = {
    ReturnAlreadyOpenedMessage: "Extension already opened !!",
    ReturnInUse: "Extension In-Use",
    ReturnNotAssignedToLoggedInUser: "This extension is assigned to another user. Please make sure the extension is assigned to you in order to make any changes.",
    ProcessReturnOpenMessage: "Opening Extension ...",
    DuplicateReturnMessage: '<br/>We have detected that this extension has already been delivered. Please review the Delivered Extensions or Archived Extensions reports to ensure you are not delivering a duplicate tax extension <br/><br/><br/> Please press <b>OK</b> to continue and <b>Cancel</b> to go back',
    ReviewOnlyThisDocumentTitle: "Confirm Review Extension",
    ReviewSignatureRemoveMessage: "All the Signature controls will be removed. Are you sure?",
    NoSignatureMessage: "You must have one signature control present to allow e-signing.",
    ReturnAlreadyProcessed: "Return already processed",
    NoFilingFormsWarning: "You cannot deliver an extension without a filing form uploaded."
}

export const ProcessExtensionConstants = {
    ExtensionInUse: "Extension In-Use"
}

export const ReProcessReturnConstants = {
    ReProcessInitiatedModalLoader: "Reprocessing initiated..."
}

export const EsignHelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    NameAndTitle: "Drag and drop to place Name and Title",
    EroSignatureStamp: "Drag and drop to place ERO signature stamp",
    Initial: "Drag and drop to place Initials control",
    Text: "Drag and drop to place Text control",
    Company: "Drag and drop to place Company control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control"
}

export const PasswordPolicyConstants = {
    NoChanges: 'There are no changes to save.',
    MinimumCharacter: 'Minimum numbers of characters should be greater than 8.',
    MinimumOption: 'Select at least two options in character type.'
}

export const SignatureFlowReportConstants = {
    DocumentNote: {
        SaveSuccess: 'Successfully saved document note.',
        UpdateSuccess: 'Successfully updated document note.',
        DeleteSuccess: 'Successfully deleted document note.',
        Error: 'Error occured while saving document note.'
    },
    CancelDocument: {
        Description: 'By canceling this document, recipients can no longer view it or sign enclosed documents. Recipients will receive an email notification, which includes your reason for canceling the document',
        CanceledTitle: 'Canceled Document',
        CancelTitle: 'Cancel Document',
        DeclinedTitle: 'Declined Document',
        CanceledReasonTilte: 'Reason for canceling this document.',
        DeclinedReasonTitle: 'Reason for declining this document.',
        UpdateSuccess: 'Successfully updated your changes.',
        Error: 'Error occured while saving your changes.',
        EmptyDescriptionWarningMsg: 'Please enter reason for canceling this document'
    },
    SendReminder: {
        Success: 'Successfully updated reminder for tax-return extension',
        Error: 'Failed to send reminder for the selected document(s)!'
    },
    DeleteReturn: {
        Success: 'Successfully deleted the selected extension(s)!',
        Error: 'Failed to delete the selected document(s)!'
    },
    SetAccess: {
        Success: 'Document Access set successfully for all selected extensions',
        Error: 'Setting document access for the users failed!'
    },
    ResendAccessLink: {
        Success: 'Access Link has been sent successfully for the selected extensions',
        Error: 'Failed to resend access link for the selected document(s)!'
    },
    AccessCode: {
        Success: 'Access Code generated successfully',
        Error: 'Failed to generated OTP for the extension'
    },
    CancelDocumentMail: {
        Success: 'Cancel document notification has been sent successfully for the selected document signers',
        Error: 'Failed to send cancel document notification for the selected document(s)!'
    },
    DeleteDocument: {
        Success: 'Successfully deleted the selected signature report',
        Error: 'Failed to deleted the selected signature report'
    },
    DownloadHistory: {
        Error: 'Failed to fetch download history for the selected document.'
    },
    DownloadDocument: {
        Success: 'Document(s) downloaded successfully.',
        Error: 'Failed to download the selected document(s).',
        ModalTitle: 'Download Documents',
        ModalMessage: 'Click the below links to download',
        DocumentNotSignedError: "Document(s) with 'E-SIGNED' status only can be downloaded."
    },
    SignatureSigners: {
        Error: 'Error occured while fetching signature signers'
    }
}

export const SignatureFlowReportCustomColumnConstants = {
    StatusMessage: {
        ErrorFetchingCustomColumns: 'Error occured while fetching signature report custom columns.',
        ErrorUpdatingCustomColumns: 'Error occured while updating signature report custom columns.',
        ErrorSavingCustomColumns: 'Error occured while saving signature report custom columns.',
        UpdatingCustomColumnsSuccess: 'Successfully updated signature report custom columns.',
        SavingCustomColumnsSuccess: 'Successfully saved signature report custom columns.'
    }
}


/////////////////////////////////////////Tab Constants Start/////////////////////////////////////////////////
export const K1TabConstants = {
    K1UploadConfirmation: "Document replaced successfully."
}

export const ClientInfoTab = {
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?"
    },
    AddedRefund: {
        DeleteConfirm: "Are you sure to delete?",
        AuthorityWarning: "Please select a taxing authority to add.",
        AmountEmptyWarning: "Please enter valid amount.",
        OverpaymentLessThanWarning: "Overpayment amount should be greater than overpayment applied.",
        DOBWarning: "Please enter valid date of birth."
    },
    UpdateRefund: {
        RefundUpdateSuccess: "Refunds & payments Due updated successfully",
    }
}

export const TabEFileConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages",
        DoNotInsertDates: "Do not insert dates on the E-File forms",
        DoNotInsertNameAndTitle: "Do not insert Name and Title on the Entity E-file Forms"
    },
    SignatureControlWarning: {
        Taxpayer: "There should be at least one signature control for the taxpayer to sign.",
        Partner: "There should be at least one signature control for the partner to sign.",
        Spouse: "There should be at least one signature control for the spouse to sign.",
        EroStamp: "There should be at least one ERO signature stamp."
    },
    EfileForm: {
        Authority: "Please select a taxing authority "
    }
}

export const TabAdditionalEsignDocumentConstants = {
    Info: {
        EROStampInfoMessage: "The ERO stamp will be applied in all pages"
    },
    SignatureControlWarning: {
        Taxpayer: "There should be at least one signature control for the taxpayer to sign in all the additional document.",
        Partner: "There should be at least one signature control for the partner to sign in all the additional document.",
        Spouse: "There should be at least one signature control for the spouse to sign in all the additional document.",
        EroStamp: "There should be at least one ERO signature stamp in all the additional document."
    }
}

export const VoucherTabConstants = {
    AddedVoucher: {
        DeleteConfirm: "Are you sure you want to delete this Voucher?",
        AmountEmptyWarning: "Please enter valid amount.",
        AuthorityWarning: "Please select a taxing authority.",
        PaymentTypeWarning: "Please select a payment type.",
        DueDateWarning: "Please select the due date.",
        FormNameWarning: "Please enter form name",
        OnlinePaymentUrlWarning: "Please enter payment url.",
        OnlinePaymentValidUrlWarning: "Please enter valid payment url.",
        CustomTypeWarning: "Please select the watermark option.",
        CustomTextWarning: "Please enter a custom watermark.",
        OneVoucherWarning: "You must create at least one Voucher by entering the related Amount ($)."
    },
    AddVoucher: {
        Authority: "Authority",
        PaymentType: "Payment Type",
        DueDate: "Due Date",
        Amount: "Amount",
        FormName: "Form Name",
        AddVoucher: "Add Payment",
        PaymentUrl: "Payment Url",
        VoucherAmount: "Voucher Amount",
        voucherNumbers: [
            { value: '0', label: 'Select' },
            { value: '1', label: 'Payment Voucher' },
            { value: '2', label: 'Q1 Estimated Payment' },
            { value: '3', label: 'Q2 Estimated Payment' },
            { value: '4', label: 'Q3 Estimated Payment' },
            { value: '5', label: 'Q4 Estimated Payment' }
        ],
        paymentMethods: [
            { value: '1', label: 'Select' },
            { value: '2', label: 'DO NOT PAY:  Scheduled for automatic withdrawal' },
            { value: '3', label: 'Online payment required' },
            { value: '4', label: 'Add Custom' },
        ],
        voucherPdfPrepareMessageFailed: "Preparing voucher failed",
        voucherDeleteFailed: "Cannot Delete Vouchers apart from Manually added",
        voucherEditWarning: "No Fields Updated",
        btnAddVoucherText: "Add",
        voucherDueDateUpdate: "Voucher DueDate Update Success",
        voucherAddSuccess: "Data updated successfully for "
    },
    DatabaseValues:
    {
        FederalAuthorityName: "Federal"
    },
    VoucherSortBookmarkWordMatchCount: 1,
    DeleteZeroAmountVoucher_1: "Are you sure you want to delete ",
    DeleteZeroAmountVoucher_2: " '$0' vouchers?",
    VoucherUpdateSuccess: "Voucher details updated successfully",
    VoucherDueDateInvalid: "Please update the due date for voucher ",
    VoucherPaymentType: "Please update the Payment Type for voucher ",
    VoucherAuthorityWarning: "Please Update the Authority for voucher ",
    PaymentMethods: [
        { value: '0', label: 'Payment Voucher' },
        { value: '1', label: 'Q1 Estimated Payment' },
        { value: '2', label: 'Q2 Estimated Payment' },
        { value: '3', label: 'Q3 Estimated Payment' },
        { value: '4', label: 'Q4 Estimated Payment' }
    ],
    voucherDueDateTypes: [
        { value: '1', label: 'SS Ext Default Due Date' },
        { value: '2', label: 'Voucher Form' },
    ],
    voucherDueDateTypesSettings: [
        { value: '1', label: 'Sherlock default due dates' },
        { value: '2', label: 'Printd Due Date on Voucher' },
    ],
    SelectDueDate: "Select Due date from",
    NoPrintedDueDate: "Selected Voucher do not have printed due date."
}

export const EfileTabConstats = {
    UpdatedAuthority: {
        AuthorityUpdateSuccess: "Authority successfully updated",
        FilingFormUpdateSuccess: "Filing form updated successfully"
    }
}

export const GroupTabConstants = {
    singlePageDragGhostText: "1 Page.",
    Page: {
        DeleteConfirm: "Are you sure you want to delete this page?",
        WarningValueNotMatch: " One or more items were not removed as thier values did not match the selected value."
    }
}

export const ClientInfoValidation = {
    inCorrectEmail: "Please enter valid email address.",
    inValidSSN: "Please enter valid SSN.",
    inCorrectTaxpayerEmail: "Please enter valid taxpayer email address.",
    inCorrectSpouseEmail: "Please enter valid spouse email address.",
    inCorrectCompanyEmail: "Please enter valid company email address.",
    inValidTaxpayerSSN: "Please enter valid taxpayer SSN.",
    inValidSpouseSSN: "Please enter valid spouse SSN.",
    inCorrectPartner: "Please select ERO / Signer",
    inCorrectERO: "Please select an ERO / Signer from drop-down",
    inValidTaxPayerName: "Please enter valid taxpayer name.",
    inValidSpouseName: "Please enter valid spouse name.",
    inCorrectControllerEmail: "Please enter valid controller Email address.",
    invalidCountryCodeTaxpayer: "Please select a country code for taxpayer's mobile number",
    invalidCountryCodeSpouse: "Please select a country code for spouse's mobile number",
    invalidCountryCodeCompany: "Please select a country code for company's mobile number",
    invalidMobileNumberTaxpayer: "Please enter mobile number for taxpayer",
    invalidMobileNumberSpouse: "Please enter mobile number for spouse",
    invalidMobileNumberCompany: "Please enter mobile number for company",
    mobileNumberLengthWarningTaxpayer: "Please enter 10 digit mobile number for taxpayer",
    mobileNumberLengthWarningSpouse: "Please enter 10 digit mobile number for spouse",
    mobileNumberLengthWarningCompany: "Please enter 10 digit mobile number for company",
    ssnDuplicateWarningMessage: "SSNs for the taxpayer and spouse are the same. Please update the SSNs to avoid duplicates.",
    inCorrectLocation: "Please select office location."
}

export const DeliveryOptionsValidation = {
    InCorrectContactPerson: "Please select a Contact Person.",
    InValidMessageFromPreparer: "Please select a Preparer Message.",
    InValidSenderName: "Please select a Sender Name.",
    InValidNotifyAboutSigningsEvents: "Please select a contact to Notify About Signing Events.",
    InvalidNumberofDaysVoucherReminder: "Please select a number of days for the Voucher Reminders.",
    InvalidNumberofDaysSigningReminder: "Please select a number of days for the Signing Reminder.",
    InValidMailingAddress: "Please select a Mailing Address."
}


export enum SignatureStatusIds {
    None,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    PartiallySigned
};
export const CustomAuthoritys = {
    DuplicateCustomAuthority: "Dupliate Authority and State",
    AddCustomAuthority: "Authority Saved Successfully.",
    ActivateAuthority: "Status Changed Successfully.",
    DeleteAuthority: "Authority Deleted Successfully.",
    message: "The Authority is in Use So Can't be Deleted",
    deleteConfirmMessage: "Are you sure, you want to delete this Custom Authority?",
    stateWarning: 'Please select state.',
    cityWarning: 'Please enter city.',
    OnlinePaymentValidUrlWarning: "Please enter valid online payment url.",
    InUseWarnig: "This authority you are trying to edit is currently in use. Please remove the Authority from below tax return extensions and try again. Click",

}

export const FileConstants = {
    MaximumFileSize: '536870912',
    FileSizeForEncryptionValidation: "20971520"
}

export const FileSizeExceedsLimit = (fileName: string) => `${fileName} cannot be uploaded since the size is greater than 512 MB.`;

/////////////////////////////////////////Tab Constants End/////////////////////////////////////////////////

export const K1SettingsConstants = {
    K1MailCycleOptions: [
        { value: 1, label: 'Before' },
        { value: 2, label: 'After' },
    ]
}

export const DownloadEfileZip = (date: string) => `efile_Extensions_download_${date}.zip`;

export const DownloadReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns_${taxYear}.zip`;

export const DownloadUnsignedReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns-Copy_${taxYear}.zip`;

export const DownloadAttachmentsZip = () => `Attachments.zip`;

export const DownloadAdditionalDocuments = (clientId: string, taxYear: number, engagementType: EngagementType, taxPayerName: string) =>
    `${clientId}_${taxPayerName}_${taxYear}_${engagementType.toString().substr(1)}_Extensions_Other UnSigned Documents.zip`;

export const DownloadSignedAdditionalDocuments = (clientId: string, taxYear: number, engagementType: EngagementType, taxPayerName: string) =>
    `${clientId}_${taxPayerName}_${taxYear}_${engagementType.toString().substr(1)}_Extensions_Other Signed Documents.zip`;

export const ClientTrackingEvents = {
    InitialEmail: 'Initial Email - ',
    VoucherReminderEmail: 'Voucher Reminder Email - ',
    AccessCodeEmail: 'Access Code Email - ',
    TaxPayerKBASuccess: 'Taxpayer KBA Successful',
    SpouseKBASuccess: 'Spouse KBA Successful ',
    TaxPayerKBAFailed: 'Taxpayer KBA Failed',
    SpouseKBAFailed: 'Spouse KBA Failed',
    SigningReminderEmail: 'Signing Reminder Email - ',
    DelegateeKBASuccess: 'Delegatee KBA Successful',
    DelegateeKBAFailed: 'Delegatee KBA Failed',
    StatusChangeToSignedAndEsigned: 'Status changed to Signed and E-Signed - ',
}

export enum DocumentEventValue {
    "None",
    "Created" = 1,
    "Document Assign To" = 2,
    "Document ERO Stamp Decline" = 3,
    "ERO Sign Decline" = 4,
    "Document Delivery Approved" = 5,
    "Emailed" = 6,
    "Document Change Status Manual" = 7,
    "Tax Return Extension Accessed" = 8,
    "Document Authenticated" = 9,
    "Authentication Failed" = 10,
    "Access Locked" = 11,
    "OTP Authentication Failed" = 12,
    "OTP Access Locked" = 13,
    "Tax Return Extension Reviewed" = 14,
    "Viewed" = 15,
    "Efile Forms Downloaded" = 16,
    "Efile Forms Uploaded" = 17,
    "Taxpayer Esigned - eFile Forms" = 18,
    "Spouse Esigned - eFile Forms" = 19,
    "Vouchers Reviewed" = 20,
    "Voucher Reminder Disabled" = 21,
    "Voucher Reminder Enabled" = 22,
    "Attachment Downloaded" = 23,
    "All Attachment Downloaded" = 24,
    "All K1 Document Downloaded" = 25,
    "Document Attachments Reviewed" = 26,
    "Payment System Accessed" = 27,
    "Invoice Reviewed" = 28,
    "Finished" = 29,
    "Access Link Emailed - " = 30,
    "Transmittal Reviewed" = 31,
    "Document TaxSaved" = 32,
    "K-1 Distributed To" = 33,
    "Document Download All" = 34,
    "Document Filing Instructions Downloaded" = 35,
    "Document Tax Return Extension Downloaded" = 36,
    "Document Vouchers Downloaded" = 37,
    "Document Invoice Downloaded" = 38,
    "Document Signed Efile Downloaded" = 39,
    "KBA Passed" = 40,
    "KBA Failed" = 41,
    "Document KBA Success" = 42,
    "Document KBA Failed" = 43,
    "DOB Changed" = 44,
    "Document Opt Out ESign Option Selected" = 45,
    "Document Signed" = 46,
    "Signed Document Reviewed" = 47,
    "Voucher Reminder Updated" = 48,
    "Deleted" = 49,
    "Efile Forms Deleted" = 50,
    "Shareholder Consent Received" = 51,
    "Shareholder Consent Declined" = 52,
    "K-1 Document Downloaded" = 53,
    "K-1 Instruction Document Downloaded" = 54,
    "Document Mail Status" = 55,
    "Tax Return Extension - Recalled" = 56,
    "Voucher Remainder Mail Status" = 57,
    "Document Request Recall Extension" = 58,
    "Document Request Delete Extension" = 59,
    "Shared" = 60,
    "Cancelled" = 61,
    "Filed" = 62,
    "Document Rejected" = 63,
    "Access Code Email" = 64,
    "Partnership Esigned - eFile Forms" = 65,
    "Document Signed Efile Downloaded By CPA" = 66,
    "Reminder Sent For Signing" = 67,
    "Download Link Sent" = 68,
    "Payment Completed" = 69,
    "Not Defined" = 75,
    "Delivered To TaxCaddy" = 77,
    "Taxcaddy Delivery Failed" = 78,
    "Extension - Archived" = 84,
    "Extension - Restored From Archive" = 85,
    "Extension Status - Changed To Manually Signed" = 86,
    "Access link Sent" = 87,
    "Additional Document Downloaded" = 89,
    "All Additional Document Downloaded" = 90,
    "Taxpayer Esigned - Other Signature Documents " = 91,
    "Spouse Esigned - Other Signature Documents " = 92,
    "Partnership Esigned - Other Signature Documents" = 93,
    "Documents Forwarded To CC Recipient(s)" = 94,
    "Extension Restored" = 95,
    "Tax Return Extension - Uploaded New Extension" = 96,
    "Tax Return Extension - Reprocessed" = 97,
    "Payment Details Added for" = 100,
    "Payment Details Updated for" = 101,
    "Document Signing Delegated" = 103,
    "Delegatee Updated" = 104,
    "Delegation Cancelled" = 105,
    "Delegatee Declined Signing" = 106,
    "Delegatee Esigned - eFile Forms" = 107,
    "Delegatee Esigned - Other Signature Documents " = 109,
}

export const DownloadReturnsConstants = {
    downloadReturn: "Click on the links below to download"
}

export const ScreenShareConstants = {
    screenShareInfo: "Taxpayer should enter the given below code to start screen sharing"
}
// SF Constants

export const SFDocument = {
    sfUploadFileWarning: 'Please upload .doc, .docx or .pdf files only.',
    sfUploadUnsupportedFileWarning: 'Failed to Upload : File format not supported.',
    deleteConfirmMessage: "Are you sure, you want to delete this document?",
    deleteDocument: "Document deleted successfully.",
    documentRequired: "Please upload document(s).",
    documentTypeRequired: "Please select a document(s) type.",
    emptyFileListMessage: "Files list will appear here.",
    documentExpireOnRequired: "Please select expire(s) on.",
    documentExpireOnLesser: "Document expiry date should be on or after ",
    fetchDocumentError: "Error while fetching document link.",
    uploadInProgress: "File(s) upload in progress",
    signatureControlRequired: "Document(s) must have one signature control placement.",
    BackgroundProcessInProgress: "Background process is in progress, Please wait...",
    outForSignature: "Document(s) successfully out for signature.",
    documentCoversionError: "Error while coverting the document.",
    senderSignatureControlRequired: "Document(s) must have one signature control placement for sender.",
    signerControlRequired: "Document(s) must have atleast one control placement for each recipient.",
    clientNameRequired: "Please enter Client Name.",
    taxYearRequired: "Please select Tax Year."
}

export const SFMessageConstants = {
    MessageRequiredWarning: "Message cannot be empty."
}

export const SigFlowCompanySettingsConstants = {
    DocumentType: {
        DocumentTypeAddSuccess: 'Document Type added successfully.',
        DocumentTypeAddError: 'Error occured while adding Document Type.',
        DocumentTypeUpdateSuccess: 'Document Type updated successfully.',
        DocumentTypeUpdateError: 'Error occured while updating Document Type.',
        DocumentTypeFetchError: 'Error occured while fetching Document Types.',
        DocumentTypeEditAlert: 'The change in the setting will not impact already delivered documents.'
    }
}

export const SignatureFlowConstants = {
    ContactsFetchError: 'Error occured while fetching Contacts.',
    SendForSignatureFailed: 'Unable to Send for Signature, Please try again',
    MailMergeFailed: 'Mail Merge failed, Please try again',
    OutForMailMerge: 'Document successfully out for Mail Merge.',
    MailMergeValidateBtnInfo: 'Click validate to verify your data',
    MailMergeInvalidCsvWarning: 'Columns mismatch, please refer template.',
    MailMergeInvalidEmailAddressText: 'Please enter valid Email Address',
    MailMergeRequiredEmailAddressText: 'This is a required field, Please enter valid Email Address.',
    MailMergeCustomFieldText: 'Value upto 80 characters can be entered.',
    SendingForSignatureOverlayMessage: 'Sending for Signature...',
    MailMergeMaxCharsExceededErrorMsg: 'Value of custom fields cannot exceed 80 characters',
    SignatureFieldNotPlacedWarningMsg: "Signature not placed for <Recipient Mail ID> do you want to proceed any way? ",
    SignatureFieldNotPlacedWarningMsgPlaceholder: "<Recipient Mail ID>",
    SignatureFlowSettingsEnableNote: "Note: You will need to re-login to see the changes take effect.",
    SignatureFlowSettingsDownloadNote: "Click to download the SS Mail merge setup file."
}

export const MailMergeDocument = {
    CsvUploadFileWarning: 'Please upload .csv file only.',
    FileInfoMessage: "File info will appear here.",
    MailMergeTemplateFileName: 'MailMergeTemplate.csv',
    DownloadSampleTemplate: 'Download Template',
    HeadingUploadCsv: 'Upload CSV',
    HeadingValidationResult: 'Validation Result',
    SendingForMailMergeOverlayMessage: 'Sending for Mail Merge...',
    DownloadExcelBtnText: 'Download'
}

export enum SignatureReportClientTrackingEvents {
    "None" = 0,
    "Created",
    "Document Mailed",
    "Document Viewed",
    "Document E-Signed",
    "Document Signed and Filed ",
    "Shared ",
    "SendForSignature",
    "Reminder Sent For Signing",
    "Document Downloaded By CPA",
    "All Documents Downloaded By CPA",
    "Cancelled Document",
    "Signed Document Downloaded By CPA",
    "All Signed Documents Downloaded By CPA",
    "Access Code Email",
    "Declined Email",
    "Download Signed Document(s) Mailed",
    "Signed Document Downloaded",
    "All Signed Documents Downloaded",
    "Access Link Emailed",
    "Document Mailed - Delegated To",
    "Partially Signed Document Downloaded ",
    "Delegation Cancelled",
    "Document Signed And Downloaded",
    "Delegatee Updated",
    "Signing Completion Mailed"
}

export const IPAddressConstant = {
    FromIPWarning: 'Please enter from IP address.',
    ToIPWarning: 'Please enter to IP address.',
    ValidFromIPWarning: 'Please enter a valid from IP address.',
    ValidToIPWarning: 'Please enter a valid to IP address.',
    InvalidIPRangeWarning: 'Invalid IP address range!',
    CloseEditingWarning: 'Close current editing.',
    DeleteIpAddress: 'Are you sure, you want to delete?',
    SaveSuccessfully: 'IP Address saved successfully',
    UpdateSuccessfully: 'IP Address updated successfully',
    DeleteSuccessfully: 'IP Address deleted successfully'
}

export const OTPVerify = {
    InvalidOTP: 'Invalid access code',
    validOTP: 'Access code verified successfully',
    NotVerified: "Access code verification failed"
}

export const BrandingConstants = {
    StatusMessage: {
        UpdateCompanyWhiteLogoError: 'Unable to update white logo, Please try again',
        DeleteWhiteLogoConfirmation: 'Are sure you want to delete the Uploaded Company White Logo?',
        CompanyWhiteLogoDeleted: 'Company White Logo has been deleted Successfully',
        DeleteCompanyPrimaryLogoConfirmation: 'Are sure you want to delete the Uploaded Company Primary Logo and Set Safesend Extensions Logo as Company Logo?'
    },
    OverlayMessage: {
        UpdatingCompanySettings: 'Updating company settings...',
        ApplicationLoading: 'Loading, please wait...'
    },
    Validate: {
        ImageWarning: 'Please upload only image.',
        ImageDimensionWarning: 'Max dimensions are 300x100px.',
        ImageValidation: 'Image validation failed! try again...'
    },
    HeaderName: {
        Branding: 'Branding Section',
        CompanyWhiteLogo: 'Company White Logo',
        ColorPalette: 'Color Palette',
        CompanyWhiteLogoMessage: 'Your selected white logo will be used in all areas that contain a colored background (Company White Logo Dimension should be less than 300x100px)',
        UploadCompanyWhiteLogo: 'Upload Company White Logo',
        ColorPaletteMessage: 'Customize Taxpayer Page colors and Email format colors ',
        ChangeColors: 'Change Colors'
    },
    ContentName: {
        HeaderBgColor: "Header Background Color",
        HeaderFontColor: "Header Font Color",
        LandingPageFolderBgColor: "Folder Background Color",
        LandingPageFolderFontColor: "Folder Font Color"
    }
}

export const customAuthority = {
    customAuthorityId: 100001
}

export const watermarkConstants = {
    NameWarning: 'Please enter name',
    TextWarning: 'Please enter text',
    FontSizeWarning: 'Please enter font size',
    FontSizeValueWarning: 'Please enter the font size between 1 to 72',
    FontFamilyWarning: 'Please select font',
    OpacityWarning: 'Please select opacity',
    DeleteWatermark: 'Are you sure, you want to delete?',
    SaveSuccessfully: 'Watermark saved successfully',
    UpdateSuccessfully: 'Watermark updated successfully',
    DeleteSuccessfully: 'Watermark deleted successfully'
}

export const MyDownloadsConstants = {
    StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
    DeleteMyDownload: 'Selected File has been deleted',
    ClearAllMyDownloads: 'All Downloaded files has been deleted',
    DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
    DialogBox: {
        ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
        ConfirmDeletionAll: 'Are you sure you want to clear all files?',
        Confirm: 'Yes',
        Cancel: 'Cancel',
        Title: 'Confirm Deletion'
    }
}

export const PreviewConstants = {
    Scope: {
        ClientPreview: "client_preview",
        ClientView: "client_view"
    }
}

export const GeneralSettingsConstants = {
    NoTaxSoftwareMessage: "Please select atleast one Tax Software."
}

export const StoreConstants = {
    Success: {
        UpdateCompanySettings: "Successfully updated extension company settings.",
    },
    Failure: {
        CompanySettings: "Failed to fetch extension company settings.",
        UpdateCompanySettings: "Failed to update extension company settings.",
    },
};

export enum VoucherPaymentConstants {
    "None" = 0,
    "Payment Voucher" = 1,
    "Q1 Estimated Payment" = 2,
    "Q2 Estimated Payment" = 3,
    "Q3 Estimated Payment" = 4,
    "Q4 Estimated Payment" = 5,
}
export const SignatureFormTypeConstant = {
    ESignWhenAllIncludedReturnsAllowed: "ESignWhenAllIncludedReturnsAllowed",
    ManualSign: "ManualSign",
    ReviewOnly: "ReviewOnly"
};

export const GroupExtensionConstants = {
    Title: "Group Configuration",
    SelectDocumentWarning: "Please select document(s) to group return!",
    ConfirmGroup: 'You are about to move the selected document(s) to group. Do you want to proceed?',
    GroupProcessReturnOpenMessage: "Opening Group Return ...",
    GroupProcessReturnEROSignerText: "Please select ERO/Signer",
    StatusMessage: {
        GroupConfigurationAddSuccess: 'Group added successfully.',
        GroupConfigurationAddError: 'Error occured while adding group.',
        GroupConfigurationUpdateSuccess: 'Group name updated successfully.',
        GroupConfigurationUpdateError: 'Error occured while updating group.',
        GroupConfigurationAndMapReturnToGroupSuccess: 'Group added successfully and return(s) moved to group successfully.',
        SendForReviewSuccess: "Sent for review successfully.",
        ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
        DeliverToClientSuccess: "Delivered to controller successfully.",
        SendForReviewError: "Error occured while sending for review.",
        ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
        DeliverToClientError: "Error occured while delivering to controller.",
        MapExtensionToGroupSuccess: 'Extension(s) moved to group successfully.',
        MapExtensionToGroupError: 'Error occured while moving the extension(s) to group.',
        GroupConfigurationAndMapExtensionToGroupSuccess: 'Group added successfully and extension(s) moved to group successfully.',
        FinishError: "Please select the return(s) to perform the action.",

    },
    ValidationMessage: {
        GroupConfigurationWarning: "Please enter group name.",
        GroupConfigurationAlreadyExist: "Group name already exists.",
        GroupConfigDropDownWarning: "Please select a group.",
    },
};

export const ControllerInfoValidation = {
    inCorrectName: "Please enter valid Controller Name.",
    inValidSSN: "Please enter valid SSN.",
    inCorrectControllerEmail: 'Please enter valid controller Email Id.',
    nameIsMandatory: "Please enter Controller Name.",
    emailIsMandatory: "Please enter Controller Email.",
}

export const SendGroupedReturnsConstants = {
    OtherMessage: {
        SelectCustomColumnValue: "Please select column value",
        FilterNameAlreadyExists: "Filter name already exists.",
        FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
        PleaseEnterFilterName: "Please enter filter name",
        FilterDoesNotExists: "Filter doesn't exists.",
        DeleteFilter: "Are you sure you want to delete this filter?",
        SaveColumnSettingsSuccess: "Column Settings saved successfully!"
    },
    StatusMessage: {
        SetDefaultFilterSuccess: "Default filter has been changed successfully!.",
        RemoveDefaultFilterSuccess: "Default filter has been Removed successfully!.",
        UnlockDocumentSuccess: "Document have been unlocked successfully!."
    },
    HeaderActions: {
        UnGroup: {
            Title: "Ungroup Extension",
            ConfirmationMessage: "You are about to Ungroup the selected document(s). Do you want to proceed?"
        },
        Delete: {
            Title: "Delete Group(s) and/or Extension(s)",
            ConfirmationMessage: "You are about to delete the selected group(s) and/or extension(s).This cannot be undone.\n Do you want to proceed?"
        }
    }
}

export const SendGroupedReturnsHeaderConstants = {
    StatusMessage: {
        UnMapReturnToGroupSuccess: 'Extension(s) removed from group successfully.',
        UnMapReturnToGroupError: 'Error occured while removing the extension(s) from group.',
        GroupAccessRequestError: 'Error occured while fetching group access details.',
        UpdateGroupAccessSuccess: 'Group Access set successfully for all groups',
        UpdateGroupAccessError: 'Setting Group access for the users failed!',
        AssignUserSuccess: "Successfully assigned the document(s) to selected user",
        AssignUserError: "Failed to assign the document(s) to user!",
        DeleteSuccess: "Successfully deleted the selected group(s) and/or extension(s)",
        DeleteError: "Failed to delete the selected group(s) and/or document(s)!",
        UpdateSingleGroupAccessSuccess: 'Group Access set successfully for the group',
    }
}

export const ClientInfoConstants = {
    emailUpdatedConfirmation: "You have updated the email address on file, would you like to resend the Return to the new email address?"
};

export const SendGroupedReturnsGridActionConstants = {
    StatusMessage: {
        DeleteReturnSuccess: "Successfully deleted the selected return(s)",
        DeleteReturnError: "Failed to delete the selected return(s)!",
    },
};

export const GroupedTaxDocumentConstants = {
    StatusMessage: {
        MarkAsReadyForDeliverySuccess: "Return marked as Ready for Delivery successfully.",
        MarkAsReadyForDeliveryError: "Failed to marked as Ready for Delivery!",
    }
}

export const SendControllerInfoMailConstants = {
    StatusMessage: {
        SendControllerMailError: "Error occured while sending mail to Controller."
    }
}

export const RecycleGroupedExtensionConstants = {
    SelectDocumentDeleteWarning: "Please select document(s) to delete!",
    SelectDocumentRestoreWarning: "Please select document(s) to restore!",
    RestoreProgress: "Restore in progress...",
    SuccessMessages: {
        Delete: "Deletion in progress, Please refresh after sometime.",
        Restore: "Selected extension(s) are restored successfully."
    },
    ConfirmMessages: {
        Delete: `Are you sure you want to delete the selected extension(s)? On Delete, System will
                \npermanently delete all the extension(s) from the SafeSend Extensions application and they
                \nwill be no longer available to anyone and the Taxpayer's access link will be
                \ndisabled permanently.`,
        Restore: `Are you sure you want to restore the selected extension(s)?`
    },
    Title: {
        DeleteRecyleExtensions: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete",
        RestoreRecyleExtensions: "<i class='fas fa-undo'/> Restore"
    },
    ExtensionExpired: {
        Restore: "You have selected one or more expired grouped extensions. You can't restore the expired grouped extensions."
    }
}


export const NavigationMenuConstants = {
    MenuToolTip: "SafeSend Suite Menu",
    Title: "SafeSend Suite"
}

export const HeaderConstants = {
    MY_SETTINGS: "My Settings",
    MY_DOWNLOADS: "My Downloads",
    LOGIN_HISTORY: "Login History",
    PROFILE: "Profile",
    SS_SUITE: "SafeSend Suite",
    ACCOUNT_MANAGEMENT: "Account Management",
    HELP: "Help Centre",
    TOOLS: "Tools"
};

export const RouteConstants = {
    HELP: "help",
    BACKSLASH: "/",
    SETTINGSCAPTION: "settings",
    EXTENSIONINPROCESS: "/ExtensionInProcess",
};

export const UserAutoLogoutConstants = {
    UserRoleChanged: 'User Role Changed',
    AccessRemoved: 'Access Removed',
    ProductTierChange: 'Feature Disabled',
    CompanyDisable: 'Company Disabled',
    ExcludedGetBasicDetails: 'GetBasicDetails',
    UserAutoLogoutCookie: 'userAutoLogout',
    PermissionChanged: "Permission Changed"
}

export const UserAutoLogoutMessageConstants = {
    UserRoleChanged: 'Your firm administrator has changed your role. You must login again for changes to take effect.',
    AccessRemoved: 'Your firm administrator has removed your account. You will be logged out and have no further access.',
    ProductTierChange: 'Your firm administrator has disabled this feature and it can no longer be accessed.',
    CompanyDisable: 'Your firm has been disabled.',
    PermissionChanged: "Your SafeSend permissions have been changed. You must login again for the changes to take effect."
}

export const VoucherPaymentNotificationInfo = "SafeSend Extensions is not a financial institution and cannot verify any transfer of funds. When the client adds a payment they are doing so manually.";
export const DefaultVoucherDueDateInfo = "By selecting 'Voucher Form', the system will pull the due date displayed on the voucher. Forms that do not display a due date will use the SS Extension Default Due Date.";
export const SuiteTaxSoftwareSettingsConstants = {
    Failure: {
        TaxSoftwareSettings: "Failed to fetch suite tax software settings."
    },
};

export const SSN = "Social Security Number (SSN)";
export const EIN ="Employer Identification Number (EIN)";


export const SignatureControlConstants = {
    ControlIdPrefix: "control_"
};

export const AwesomePdfViewerText = {
    NoFormPageAvailable: "No forms found !!"
};
export const ChangeSecurityConstants = {
    SecurityChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
    SecurityUpdateSuccess: "Password updated successfully.",
    SecurityUpdateFail: "Updating password failed, please re-login"
};

export const getRetentionDeletionTime = () => moment().set("hour", 12).set("minute", 0).set("second", 0).set("milliseconds", 0).toArray();

export const Code990 = "990/T";