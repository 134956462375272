import { ExtensionCompanySettings, initialExtensionCompanySettings } from "src/Core/ViewModels/Extension/ExtensionComponentModels";
import { ActionCreators as ExtensionCompanySettingsStore } from '../../../store/settings/ExtensionCompanySettingsStore';
import React, { FC, useEffect, useState } from "react";
import { ApplicationState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as CompanyStore } from '../../../store/company/CompanyStore';
import { TaxpayerAuthenticationType } from "src/Core/ViewModels/Company/CompanySettingsViewModel";
import SettingsHeader from "src/components/common/settings/SettingsHeader";
import OverlayLoader from "src/components/helper/OverlayLoader";
import TaxpayerAuthenticationTypes from "../GeneralSettingsComponents/TaxpayerAuthenticationTypes";
import { cloneDeep } from "lodash";
import { VenusNotifier } from "src/components/helper/VenusNotifier";
import RetentionSettings from "./RetentionSettings";
import moment from "moment";
import { getRetentionDeletionTime } from "src/components/helper/Constants";

export const SecuritySettings: FC = () => {

    const dispatch = useDispatch();
    const companyData = useSelector((state: ApplicationState) => state.extensionCompanyData)
    const [extensionCompanySettings, setCompanySettings] = useState<ExtensionCompanySettings>({ ...initialExtensionCompanySettings });
    const [showPopOver, setShowPopOver] = useState<boolean>(false);
    const [expiryDate, setExpiryDate] = useState<Date>();
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);

    useEffect(() => {
        dispatch(ExtensionCompanySettingsStore.requestExtensionCompanySettings());
    }, []);

    useEffect(() => {
        setCompanySettings(cloneDeep(companyData.companySettings));
    }, [companyData.companySettings]);

    const handleApplyButton = () => {
        if (extensionCompanySettings) {
            dispatch(ExtensionCompanySettingsStore.updateExtensionCompanySettings(extensionCompanySettings));
            if (showPopOver) {
                dispatch(CompanyStore.updateRetentionForAllDocuments(extensionCompanySettings.retentionSettingsModel.retentionPeriod,
                    extensionCompanySettings.retentionSettingsModel.attestRetentionPeriod));
                dispatch(CompanyStore.forceRefreshTaxDcoumentStore(true));
            }
        }
    }

    const handleTaxpayerAuthentication = (taxpayerAuthentication: TaxpayerAuthenticationType) => {
        extensionCompanySettings.deliverySettingsModel.taxpayerAuthentication = taxpayerAuthentication;
        setCompanySettings({ ...extensionCompanySettings });
    }

    const handleRetentionDays = (value: number, type: string) => {
        setCompanySettings({ ...extensionCompanySettings, retentionSettingsModel: { ...extensionCompanySettings.retentionSettingsModel, [type]: value } });
        setShowPopOver(true);
        setExpiryDateOnRetentionChange();
    };

    const handleDocumentsToExpire = (e: any, callback: (count: number) => void) => {
        if (extensionCompanySettings) {
            if (!extensionCompanySettings.retentionSettingsModel.retentionPeriod ||
                !extensionCompanySettings.retentionSettingsModel.attestRetentionPeriod) {
                !extensionCompanySettings.retentionSettingsModel.retentionPeriod ?
                    VenusNotifier.Warning("Please select a valid value for default retention period.", "") :
                    VenusNotifier.Warning("Please select a valid value for attest retention period.", "")
            }
            else {
                setButtonLoader(true);
                dispatch(CompanyStore.requestDocumentsToexpire(extensionCompanySettings.retentionSettingsModel.retentionPeriod, extensionCompanySettings.retentionSettingsModel.attestRetentionPeriod, callback));
            }
        }
    }

    const handleOverrideChange = (value: boolean) => {
        setCompanySettings({ ...extensionCompanySettings, retentionSettingsModel: { ...extensionCompanySettings.retentionSettingsModel, allowOverride: value } });
        setShowPopOver(true);
        setExpiryDateOnRetentionChange();
    };

    const setExpiryDateOnRetentionChange = () => {
        const date = new Date();
        const deletionTime = getRetentionDeletionTime();
        const currentUTCTime = moment().toArray();

        if (moment(deletionTime).diff(currentUTCTime) < 0) {
            date.setDate(date.getDate() + 1);
        } else {
            date.setDate(date.getDate());
        }
        setExpiryDate(date);
    }

    return <div id="divSecurity" className='company-settings font14'>
        <SettingsHeader
            handleApplyButton={handleApplyButton}
            header="Security "
            showPopOver={showPopOver}
            expiryDate={expiryDate}
            handleDummyApplyButton={handleDocumentsToExpire}
            buttonLoader={buttonLoader}
            setButtonLoader={setButtonLoader} />
        <OverlayLoader show={companyData.loading} />
        <div>
            <TaxpayerAuthenticationTypes
                taxpayerAuthentication={extensionCompanySettings.deliverySettingsModel.taxpayerAuthentication}
                handleTaxpayerAuthentication={handleTaxpayerAuthentication} />
            <hr />
        </div>
        <RetentionSettings
            companySettings={extensionCompanySettings}
            handleRetentionDays={handleRetentionDays}
            handleOverrideChange={handleOverrideChange}
        />
    </div>;
}
