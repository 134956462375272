import React from  'react';
import { ClientInstructionMessageVariables } from '../../helper/Constants';
import copy from 'copy-to-clipboard';
import { Card, Accordion } from 'react-bootstrap';

export interface IVariableListData {
    variable: string,
    helpText: string
}
export interface VariableListModel {
    variableListModel: IVariableListData[];
    open: boolean;
    isHovering: boolean
}

interface IVariableListModelProps {
    isGroupSetting?: boolean;
}

export class VariableList extends React.Component<IVariableListModelProps, VariableListModel>{
    constructor(props: IVariableListModelProps) {
        super(props);
        this.state = {
            variableListModel: [],
            open: false,
            isHovering: false
        }
        this.getVariableList = this.getVariableList.bind(this);
    }


    public getVariableList(): IVariableListData[] {
        const tempVariableListModel: IVariableListData[] = [];
        tempVariableListModel.push({ variable: "COMPANYNAME", helpText: ClientInstructionMessageVariables.CompanyName },
            { variable: "TAXPAYERNAME", helpText: ClientInstructionMessageVariables.TaxPayerName },
            { variable: "SPOUSENAME", helpText: ClientInstructionMessageVariables.SpouseName },
            { variable: "HELPCONTACT", helpText: ClientInstructionMessageVariables.HelpContact },
            { variable: "HELPPHONE", helpText: ClientInstructionMessageVariables.HelpPhone },
            { variable: "HELPEMAIL", helpText: ClientInstructionMessageVariables.HelpEmail },
            { variable: "PREPARERNAME", helpText: ClientInstructionMessageVariables.PrepareName },
            { variable: "TAXYEAR", helpText: ClientInstructionMessageVariables.TaxYear },
            { variable: "NUMBEROFDAYS", helpText: ClientInstructionMessageVariables.NumberOfays },
            { variable: "RECIPIENT", helpText: ClientInstructionMessageVariables.Recipient },
            { variable: "ERONAME", helpText: ClientInstructionMessageVariables.EROName },
            { variable: "SENDERNAME", helpText: ClientInstructionMessageVariables.SenderName }

        )
        if (this.props.isGroupSetting) {
            tempVariableListModel.push(
                { variable: "CONTROLLERNAME", helpText: ClientInstructionMessageVariables.ControllerName }
            )
        }
        return tempVariableListModel;
    }

    public render() {
        let icon = this.state.open ? "fas fa-minus" : "fas fa-plus";
        let text = this.state.open ? "Variables List" : "Show Variables List";
        const defaultKey = this.state.open ? "0" : "1";
        return (
            <div>
                <Accordion defaultActiveKey={defaultKey}>
                    <Card>
                        <Accordion.Toggle
                            onClick={() => this.setState({ open: !this.state.open })}
                            style={{ padding: "10px" }}
                            as={Card.Header}
                            variant="link"
                            eventKey="0"
                            className="panel-title"
                        >
                            <i className={icon}></i> <a>{text}</a>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="marL05">
                                <ul>{
                                    this.getVariableList().map((item) => {
                                        var value = `<${item.variable}>`;
                                        return <div className="row">
                                            <div className="col-md-4">
                                                <li className="copy marL05">{value}
                                                    <span onClick={() => copy('<' + item.variable + '>', { format: "text/plain" })}
                                                        title="Copy" className="far fa-copy copyIcon">
                                                    </span>
                                                </li>
                                            </div>
                                            <div className="col-md-8">{item.helpText}</div>
                                        </div>
                                    })
                                }
                                </ul>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}