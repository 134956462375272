import React from  'react';
//import { DropTarget, ConnectDropTarget, DropTargetMonitor } from 'react-dnd'

import * as TaxDocument from '../../common/TaxReturn';


export interface IGroupCardProps {
    type: TaxDocument.DocumentGroups;
    label: string | undefined;
    icon: string;
    color: string;
    active: boolean;
    onClick: (type: TaxDocument.DocumentGroups) => void;
};

export interface GroupCardTagetProps extends IGroupCardProps {
    accepts: string[]
    lastDroppedItem?: any
}

export interface GroupCardCollectedProps {
 
}
export class GroupCard extends React.Component<GroupCardTagetProps & GroupCardCollectedProps, {}> {
    constructor(props:any) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }
    public render() {
        let active = this.props.active ? "arrow-right " : " ";
        let type: string = TaxDocument.DocumentGroups[this.props.type].toString(); 
        return (<div
            className={active + "right-pointer " + type + " cursor-pointer"}
            onClick={(e:any) => this.onClick(this.props.type)}>
            <div style={{ width: 32, textAlign: 'center', display: 'inline-block', marginLeft: -32, height: '100%' }}>
                <i className={this.props.icon + " arrow-icon"} />
            </div>
            <span>{this.props.label ? this.props.label : this.props.type}</span>
        </div>);    
    }

    private onClick(e: any) {
        //TODO: change apprearance in needed
        this.props.onClick(this.props.type);
    }
};

