import {
    SILENT_RENEW_ERROR,
    USER_FOUND,
    LOAD_USER_ERROR,
    USER_EXPIRED,
    USER_SIGNED_OUT,
    LOADING_USER,
    USER_PRIVILEGE_CHANGED,
    RESET_CACHE_API_CALLED
} from './action-types'

export interface IAuthState {
    user: any
    isLoadingUser: boolean,
    userPrivilegesChanged: boolean;
    userAutoLogoutCause: LogoutCause;
    isResetCacheApiCalled: boolean;
}
export enum LogoutCause {
    None = 0,
    UserPermissionChange = 1,
    UserDelete = 2,
    ProductTierChange = 3,
    CompanyDisable = 4,
}
export const initialAuthState = {
    user: null,
    isLoadingUser: false,
    userPrivilegesChanged: false,
    userAutoLogoutCause: LogoutCause.None,
    isResetCacheApiCalled : false
}

export const reducer = (state = initialAuthState, action: any) => {
    switch (action.type) {
        case USER_FOUND:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload,
            }
        case LOADING_USER:
            return {
                ...state,
                isLoadingUser: true,
            }
        case USER_EXPIRED:
        case LOAD_USER_ERROR:
        case SILENT_RENEW_ERROR:
        case USER_SIGNED_OUT:
            return {
                ...state,
                user: null,
                isLoadingUser: false,
            }
        case USER_PRIVILEGE_CHANGED:
            return {
                ...state,
                userPrivilegesChanged: true,
                userAutoLogoutCause: action.userAutoLogoutCause
            }
        case RESET_CACHE_API_CALLED:
            return {
                ...state,
                isResetCacheApiCalled: true
            }
        default:
            return state
    }
}
export default reducer
