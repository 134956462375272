import { Container } from "inversify";
import "reflect-metadata";
import { UtilityFactory } from "../Core/Utilities/Factory/UtilityFactory";
import { ServiceFactory } from '../Core/Services/Factory/ServiceFactory';
import { ILocalStore } from "../Core/Utilities/LocalStore";
import { ISessionLocalStore } from "../Core/Utilities/SessionStore";
import { ITextUtilities } from "../Core/Utilities/TextUtilities";
import { IWindowUtilities } from "../Core/Utilities/WindowUtilities";
import { IHtmlUtilities } from "../Core/Utilities/HtmlUtilities";
import { IFileClient } from '../Core/Services/FileClient';
import { IDialogBox } from '../Core/Utilities/UI/DialogBox';
import { ICsvToJsonService } from '../Core/Utilities/CsvToJsonService';
import { TYPES } from "./types";

const container = new Container();

container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container.bind<IWindowUtilities>(TYPES.IWindowUtilities).toConstantValue(new UtilityFactory().createWindowUtilityService());
container.bind<IFileClient>(TYPES.IFileClient).toConstantValue(new ServiceFactory().getFileClient());
container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<ICsvToJsonService>(TYPES.ICsvToJsonService).toConstantValue(new UtilityFactory().createCsvToJsonService())
container.bind<ISessionLocalStore>(TYPES.ISessionStore).toConstantValue(new UtilityFactory().createSessionStore());

export { container };
