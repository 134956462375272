import React from  'react';
import { RouteComponentProps } from 'react-router';
import { ClientInstructionHeader } from '../../settings/ClientInstructions/ClientInstructionHeader';
import { EmailMessage } from '../../settings/ClientInstructions/EmailMessage/EmailMessage';
import * as EmailMessageStore from '../../../store/settings/EmailMessageStore';
import { state as ClientInstructionState } from '../../../components/settings/ClientInstruction';
import { clientInstructions, IClientMessage, IEmailTemplateMessage, initialClientMessage, initialEmailMessage } from '../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import * as Constants from '../../../components/helper/Constants';
import { initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings, initialExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { EmailMessagePopUp } from '../../settings/ClientInstructions/EmailMessage/EmailMessagePopUp';
import * as ExtensionStoreModel from '../../../Core/ViewModels/Extension/ExtensionStoreModels';
import { decodeText } from '../../../components/helper/HelperFunctions';
import * as ExtensionCompanySettingsStore from '../../../store/settings/ExtensionCompanySettingsStore';
import * as bootbox from 'bootbox';
import { ClientMessage } from '../../settings/ClientInstructions/ClientMessage/ClientMessage';
import * as ClientMessageStore from '../../../store/settings/ClientMessageStore';
import { ClientMessagePopUp } from '../../settings/ClientInstructions/ClientMessage/ClientMessagePopUp';

type GroupSettingsProps =
    {
        emailMessageList: EmailMessageStore.IEmailMessageStoreState,
        clientMessageList: ClientMessageStore.IClientMessageStoreState,
        extensionCompanyData: ExtensionStoreModel.ExtensionSettingsStoreState
    }
    & typeof EmailMessageStore.ActionCreators
    & typeof ClientMessageStore.ActionCreators
    & typeof ExtensionCompanySettingsStore.ActionCreators
    & RouteComponentProps<{ page: string }>;

interface GroupSettingsState extends ClientInstructionState {
}

export class GroupSettings extends React.Component<{} & GroupSettingsProps, GroupSettingsState> {
    constructor(props: GroupSettingsProps) {
        super(props);

        this.state = {
            showEmailMessagePopup: false,
            showClientMessagePopup: false,

            defaultMessageId: 0,
            headerTitle: '',
            typeId: 0,
            settings: initialCompanySettings,
            extensionSettings: initialExtensionCompanySettings,
            buttonText: "",
            title: "",
            isUpdateMessage: false,
            selectedInitialWelcomeMessage: 0,
            selectedVoucherReminderMessage: 0,
            selectedDownloadEsignMessage: 0,
            selectedPaymentInstruction: 0,
            selectedSigningReminder: 0,
            defaultInitialWelcomeMessage: 0,
            defaultVoucherReminderMessage: 0,
            defaultDownloadEsignMessage: 0,
            defaultPaymentInstruction: 0,
            defaultSigningReminder: 0,
            selectedMessageId: 0,
            showLoader: false,
            loaderText: "",
            showPopUpLoader: false,
            selectedRecalledReturnWelcomeMessage:0,
            defaultRecalledReturnWelcomeMessage :0
        }
    }

    public UNSAFE_componentWillMount() {
        this.props.requestExtensionCompanySettings();
        this.props.requestEmailMessages(true);
        this.props.requestClientMessages();
        this.setState({ showLoader: true, loaderText: "Loading..." })
    }
    public UNSAFE_componentWillReceiveProps(nextProps: GroupSettingsProps) {
        this.setState({
            selectedInitialWelcomeMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupInitialEmail : 0,
            selectedVoucherReminderMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupVoucherReminders : 0,
            selectedDownloadEsignMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupDownloadSignedForms : 0,
            selectedPaymentInstruction: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupPaymentInstruction : 0,
            selectedSigningReminder: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupSigningReminder : 0,
            defaultInitialWelcomeMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupInitialEmail : 0,
            defaultVoucherReminderMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupVoucherReminders : 0,
            defaultDownloadEsignMessage: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupDownloadSignedForms : 0,
            defaultPaymentInstruction: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupPaymentInstruction : 0,
            defaultSigningReminder: nextProps.extensionCompanyData.companySettings && nextProps.extensionCompanyData.companySettings.defaultGroupSettings ? nextProps.extensionCompanyData.companySettings.defaultGroupSettings.groupSigningReminder : 0,
        })
        this.setState({
            showLoader: !nextProps.emailMessageList.isLoading && !nextProps.extensionCompanyData.loading ? false : true,
            showPopUpLoader: !nextProps.emailMessageList.isLoading && !nextProps.extensionCompanyData.loading ? false : true,
        }, () => {
            if (!this.state.showPopUpLoader) this.setState({
                showEmailMessagePopup: false,
                showClientMessagePopup: false,
            })
        })
    }

    private onInitialMessageListItemClick = (messageId: number) => {
        this.setState({ selectedInitialWelcomeMessage: messageId })
    }
    private onSigningReminderListItemClick = (messageId: number) => {
        this.setState({ selectedSigningReminder: messageId })
    }

    private onVoucherReminderListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherReminderMessage: messageId })
    }

    private onDownloadEsignListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadEsignMessage: messageId })
    }

    private onPaymentInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedPaymentInstruction: messageId })
    }

    private onEmailInstructionAddButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Client Instruction",
            typeId: typeId
        })
    }
    private onEmailInstructionEditButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Client Instruction",
            typeId: typeId
        })
    }
    private deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number) => {
        if (this.props.emailMessageList.data[selectedMessageId].emailMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "")
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure, You want to delete this Instruction ?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessageDetail
                            (_currentObject.props.emailMessageList.data[selectedMessageId].emailMessage);
                        _currentObject.setState({ showLoader: true })
                    }
                }
            });
        }
    }

    private onCancelButtonClick = () => {
        this.setState({
            showClientMessagePopup: false,
            showEmailMessagePopup: false,
        })
    }

    private saveEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        const companyData: ExtensionCompanySettings = this.props.extensionCompanyData.companySettings ? this.props.extensionCompanyData.companySettings : initialExtensionCompanySettings;
        message.type = this.state.typeId;
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.saveGroupEmailMessageDetail(message, companyData, isSetDefault, this.state.typeId);
        this.setState({
            showPopUpLoader: true
        })
    }

    private editEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.updateEmailMessageDetail(message);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id)
        }
        this.setState({
            showPopUpLoader: true
        })
    }

    private saveDefaultMessage = (messageId: number) => {
        let companyData: ExtensionCompanySettings = this.props.extensionCompanyData.companySettings ? this.props.extensionCompanyData.companySettings : initialExtensionCompanySettings;

        if (this.state.typeId == clientInstructions.groupInitialEmail)
            companyData.defaultGroupSettings.groupInitialEmail = messageId
        else if (this.state.typeId == clientInstructions.groupSigningReminder)
            companyData.defaultGroupSettings.groupSigningReminder = messageId
        else if (this.state.typeId == clientInstructions.groupVoucherReminder)
            companyData.defaultGroupSettings.groupVoucherReminders = messageId
        else if (this.state.typeId == clientInstructions.groupDownloadEsignedFroms)
            companyData.defaultGroupSettings.groupDownloadSignedForms = messageId
        else if (this.state.typeId == clientInstructions.groupPaymentInstruction)
            companyData.defaultGroupSettings.groupPaymentInstruction = messageId;
        this.props.updateExtensionCompanySettings(companyData);
    }

    private onClientInstructionAddButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Add",
            title: "Add Client Instruction",
            isUpdateMessage: false,
            showClientMessagePopup: true,
            typeId: typeId
        })
    }

    private onClientInstructionEditButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Save",
            title: "Edit Client Instruction",
            isUpdateMessage: true,
            showClientMessagePopup: true,
            typeId: typeId
        })
    }

    private deleteClientMessage = (selectedMessageId: number, defaultMessageId: number) => {
        if (this.props.clientMessageList.data[selectedMessageId].clientMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "")
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure, You want to delete this Instruction ?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteClientMessageDetail
                            (_currentObject.props.clientMessageList.data[selectedMessageId].clientMessage)
                        _currentObject.setState({ showLoader: true })
                    }
                }
            });
        }
    }

    private saveClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        const companyData: ExtensionCompanySettings = this.props.extensionCompanyData.companySettings ? this.props.extensionCompanyData.companySettings : initialExtensionCompanySettings;
        message.type = this.state.typeId;
        message.name = decodeText(message.name);
        this.props.saveGroupClientMessageDetail(message, companyData, isSetDefault, this.state.typeId);
        this.setState({
            showPopUpLoader: true
        })
    }

    private editClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.name = decodeText(message.name);
        this.props.updateClientMessageDetail(message);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id)
        }
        this.setState({
            showPopUpLoader: true
        })
    }

    public render() {
        return <div className="font14" style={{ marginTop: '20px' }}>
            <ClientInstructionHeader />
            <EmailMessage
                heading={'Initial Email'}
                description={'Initial email sent to clients'}
                emailMessages={Object.keys(this.props.emailMessageList.data).filter((k, i) => (this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.groupInitialEmail))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.emailMessageList.data[key];
                        return obj;
                    }, {})}
                defaultMessage={this.state.defaultInitialWelcomeMessage}
                selectedMessage={this.state.selectedInitialWelcomeMessage}
                onListItemClick={this.onInitialMessageListItemClick}
                typeId={clientInstructions.groupInitialEmail}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessage
                heading={'Review and Sign Reminder Email'}
                description={'Signing reminder sent to client'}
                emailMessages={Object.keys(this.props.emailMessageList.data).filter((k, i) => (this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.groupSigningReminder))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.emailMessageList.data[key];
                        return obj;
                    }, {})}
                defaultMessage={this.state.defaultSigningReminder}
                selectedMessage={this.state.selectedSigningReminder}
                onListItemClick={this.onSigningReminderListItemClick}
                typeId={clientInstructions.groupSigningReminder}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessage
                heading={'Voucher Reminders'}
                description={'Quarterly voucher reminders sent to clients'}
                emailMessages={Object.keys(this.props.emailMessageList.data).filter((k, i) => (this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.groupVoucherReminder))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.emailMessageList.data[key];
                        return obj;
                    }, {})}
                defaultMessage={this.state.defaultVoucherReminderMessage}
                selectedMessage={this.state.selectedVoucherReminderMessage}
                onListItemClick={this.onVoucherReminderListItemClick}
                typeId={clientInstructions.groupVoucherReminder}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>
            <hr></hr>

            <EmailMessage
                heading={'Download E-Signed Forms'}
                description={'Once all parties have e-signed all documents'}
                emailMessages={Object.keys(this.props.emailMessageList.data).filter((k, i) => (this.props.emailMessageList.data[parseInt(k)].emailMessage.type == clientInstructions.groupDownloadEsignedFroms))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.emailMessageList.data[key];
                        return obj;
                    }, {})}
                defaultMessage={this.state.defaultDownloadEsignMessage}
                selectedMessage={this.state.selectedDownloadEsignMessage}
                onListItemClick={this.onDownloadEsignListItemClick}
                typeId={clientInstructions.groupDownloadEsignedFroms}
                onAddButtonClick={this.onEmailInstructionAddButtonClick}
                onEditButtonClick={this.onEmailInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteEmailMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>
            <hr></hr>

            <ClientMessage
                heading={'Payment Instruction'}
                description={'Header in payment step client side'}
                clientMessages={Object.keys(this.props.clientMessageList.data).filter((k, i) => (this.props.clientMessageList.data[parseInt(k)].clientMessage.type == clientInstructions.groupPaymentInstruction))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.clientMessageList.data[key];
                        return obj;
                    }, {})}
                defaultMessage={this.state.defaultPaymentInstruction}
                selectedMessage={this.state.selectedPaymentInstruction}
                onListItemClick={this.onPaymentInstructionListItemClick}
                typeId={clientInstructions.groupPaymentInstruction}
                onAddButtonClick={this.onClientInstructionAddButtonClick}
                onEditButtonClick={this.onClientInstructionEditButtonClick}
                onDeleteButtonClick={this.deleteClientMessage}
                showLoader={this.state.showLoader}
                loaderText={this.state.loaderText}
            />
            <hr></hr>

            <EmailMessagePopUp
                showState={this.state.showEmailMessagePopup}
                onHide={this.onCancelButtonClick}
                submitButtonClick={this.state.isUpdateMessage ? this.editEmailMessage : this.saveEmailMessage}
                buttonText={this.state.buttonText}
                selectedMessageDetail={this.state.isUpdateMessage ? this.props.emailMessageList.data[this.state.selectedMessageId] ? this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage : initialEmailMessage : initialEmailMessage}
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                loaderText={"Saving..."}
                showLoader={this.state.showPopUpLoader}
                emailMessages={Object.keys(this.props.emailMessageList.data).filter((k, i) => (this.props.emailMessageList.data[parseInt(k)].emailMessage.type == this.state.typeId))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.emailMessageList.data[key];
                        return obj;
                    }, {})}
                isGroupSetting={true}
            />
            <ClientMessagePopUp
                onHide={this.onCancelButtonClick}
                showState={this.state.showClientMessagePopup}
                submitButtonClick={this.state.isUpdateMessage ? this.editClientMessage : this.saveClientMessage}
                buttonText={this.state.buttonText}
                selectedMessageDetail={this.state.isUpdateMessage ? this.props.clientMessageList.data[this.state.selectedMessageId] ? this.props.clientMessageList.data[this.state.selectedMessageId].clientMessage : initialClientMessage : initialClientMessage}
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                loaderText={"Saving..."}
                showLoader={this.state.showPopUpLoader}
                clientMessages={Object.keys(this.props.clientMessageList.data).filter((k, i) => (this.props.clientMessageList.data[parseInt(k)].clientMessage.type == this.state.typeId))
                    .reduce((obj: any, key: any) => {
                        obj[key] = this.props.clientMessageList.data[key];
                        return obj;
                    }, {})}
                isGroupSetting={true}
            />
        </div>;

    }
}