import { ApplicationState } from "../../../store";
import * as MailingReturnAddressStore from '../../../store/common/MailingReturnAddressStore';
import { Signatures } from "../Signatures";
import { connect } from 'react-redux';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as ExtensionCompanyStore from '../../../store/settings/ExtensionCompanySettingsStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as AdditionalDocumentTypeStore from '../../../store/common/AdditionalDocumentTypeStore';

export default connect(
    (state: ApplicationState) => ({
        companyData: state.companyData,
        mailingReturnAddresses: state.mailingReturnAddresses,
        settings: state.userSettings,
        additionalDocumentTypeData: state.additionalDocumentType,
        extensionCompanyStore: state.extensionCompanyData,
    }),
    {
        ...CompanyStore.actionCreators,
        ...MailingReturnAddressStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...AdditionalDocumentTypeStore.actionCreators,
        ...ExtensionCompanyStore.ActionCreators,
    },
)(Signatures as any);
