import { injectable } from "inversify";

export interface ILocalStore {
    get(key: string): any;
    set(key: string, value: any): void;
    remove(key: string): void;
    isExists(key: string): boolean;
}

@injectable()
export class LocalStorageStore implements ILocalStore {

    constructor() {

    }

    get(key: string): any {
        return localStorage.getItem(key);
    }

    set(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    isExists(key: string): boolean {
        return localStorage.getItem(key) === null ? false : true;
    }

}