import 'isomorphic-fetch';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { RecycleTaxReturns } from '../../../components/common/RecycleTaxReturns';
import { ReportFilterConstants,DeleteRecycledExtensionConstants, RecyleReturnsConstants, RestoreReturnConstants } from '../../../components/helper/Constants';
import * as RecycleReturnsState from '../../../store/reports/RecycleReturns/RecycleReturnsState';
import * as RecycleReturnsStore from '../../../store/reports/RecycleReturns/RecycleReturnsStore';
import * as Helper from '../../helper/HelperFunctions';
import { RecycleReturnsHeader } from './../RecycleReturns/RecycleReturnsHeader';
import { RecycleReturnsTable } from './../RecycleReturns/RecycleReturnsTable';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as UserStore from '../../../store/userManagement/UserStore';
import { initialCompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { RecycleBulkOperationQuery } from '../../../Core/ViewModels/Common/BulkOperationQuery';
import * as BulkOperationsStore from '../../../store/common/BulkOperationsStore';
import * as bootbox from 'bootbox';
import { ShowLoader, HideLoader } from '../../helper/Loader';
import * as GroupedExtensionsStore from './../../../store/groupedExtensions/GroupedExtensionsStore';
import { GroupedExtensionStoreState } from './../../../Core/ViewModels/GroupExtensions/StoreModels';
import { logClientEvent } from '../../helper/LoggerHelper';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import * as TaxSoftwareStore from '../../../store/SuiteCompany/TaxSoftwareStore';
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import * as ExtensionUserSettingsStore from '../../../store/userManagement/ExtensionUserSettingStore';
import { IFilters, ReportFilterType, SortDirections } from '../Filters';
import { SessionStore } from '../../../components/helper/HelperFunctions';
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
import * as UserOfficeLocationStore from  '../../../store/common/UserOfficeLoacationStore';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import { IDocumentAccess } from 'src/components/common/TaxReturn';


let moment = require('moment');
const pageSize: number = 20;
const NO_INDEX = -1;

type RecycleReturnsProps =
    {
        recycleReturns: RecycleReturnsState.RecycleReturnsState;
        userSettings: UserSettingStore.UserSettings;
        company: CompanyStore.ICompanyData;
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
        usersStore: UserStore.UsersStore;
        groupedExtensionsStore: GroupedExtensionStoreState;
        taxSoftwareData: SuiteTaxSoftwareSettingsStoreState;
        extensionUserSettings: ExtensionUserSettingsStore.IExtensionUserSettings;
        userOfficeLocation:UserOfficeLocationStore.IUserOfficeLocationState;
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    }
    & typeof RecycleReturnsStore.actionCreators
    & typeof UploadTaxReturnStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & typeof GroupedExtensionsStore.actionCreators
    & typeof TaxSoftwareStore.actionCreators
    & typeof ExtensionUserSettingsStore.actionCreators
    & typeof UserOfficeLocationStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & RouteComponentProps<{ page: string }>;

const pageTitle: string = "RecycleBin"
export class RecycleReturns extends React.Component<RecycleReturnsProps, RecycleReturnsState.RecycleReturnsPageState> {
    private proxyFilter: any;
    constructor(props: any) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: ({
                name: '',
                searchText: '', 
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.RecycleReport,
                isMasterFilter: false,
            }),
            selectedRows: [],
            sortColumn: "",
            sortDirection: "",
            taxYear: new Date().getFullYear() - 1,
            clientName: "",
            clientID: "",
            taxReturnType: undefined,
            partner: "",
            sentBy: "",
            deliveredOn: undefined,
            status: "",
            deletedOn: undefined,
            deletedBy: "",
            refreshDelay: false,
            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            isSelectAllChecked: false,
            showBulkSelectionMessage: false,
            defaultGroup: '',
            officeLocation:''
        }

        this.onFilterChange = this.onFilterChange.bind(this);


        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleTaxYearChange = this.handleTaxYearChange.bind(this);


        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchRecycleReturns = this.fetchRecycleReturns.bind(this);
        this.loadRecycleReturns = this.loadRecycleReturns.bind(this);
        this.onPageReload = this.onPageReload.bind(this);
    }

    componentDidMount() {
        this.props.requestUserSettings(true);
        this.props.requestRecycleReturns(this.buildQuery(this.state.page, pageSize), true, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
        this.props.requestCompanySettings(false);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestExtensionUserSettings();
        this.proxyFilter=ReportFilterType[ReportFilterType.RecycleReport].toUpperCase()+ ReportFilterConstants.DefaultFilterBuilder
    }
    //======================Grid Operation Starts here===============================
    
    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        let deSelectedRows = this.state.deSelectedRows;
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    index = deSelectedRows.indexOf(row.id);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.id);
                }
            }
            this.state.isBulkSelectionEnabled ?
                this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList }) :
                this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());

            showBulkSelectionMessage = true;
        }

        this.setState({
            isSelectAllChecked: isSelected,
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false
        });
    }

    private onPageChange(page: number, sizePerPage: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState({
                page: page
            }, this.loadRecycleReturns);
        }
        else {
            this.setState({
                page: page,
                selectedRows: [],
                showBulkSelectionMessage: false,
            }, this.loadRecycleReturns);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: RecycleReturnsProps) {
        this.updateStates(nextProps);
    }

    private updateStates = (props: RecycleReturnsProps) => {
        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            props.recycleReturns.recycleReturnTableModel.documents.forEach((value, index) => {
                this.state.deSelectedRows.find(m => m === value.documentId) === undefined && selectedRows.push(index);
            })
            this.setState({ selectedRows: selectedRows });
        }
    }

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;
        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        let newState: RecycleReturnsState.RecycleReturnsPageState = this.state;
        newState.sortColumn = sortName;
        newState.sortDirection = sortOrder;
        this.setState({ ...newState }, () => { this.onPageReload(); });
    }

    private handleTaxYearChange(eventKey: any) {

        let newState: RecycleReturnsState.RecycleReturnsPageState = this.state;
        newState.taxYear = eventKey.value;
        this.setState({ ...newState }, () => { this.onPageReload(); });
    }

    //======================Grid Operation Ends here=================================


    //======================Filter Operation Starts here=================================

    private onFilterChange(dataField: any, filterType: ReportFilterType) {

        let newState: RecycleReturnsState.RecycleReturnsPageState = {
            page: 1,
            pageSize: pageSize,
            selectedRows: [],
            sortColumn: "",
            sortDirection: "",
            taxYear: this.state.taxYear,
            clientName: "",
            clientID: "",
            taxReturnType: undefined,
            partner: "",
            sentBy: "",
            deliveredOn: undefined,
            status: "",
            deletedOn: undefined,
            deletedBy: "",
            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            isSelectAllChecked: false,
            showBulkSelectionMessage: false,
            defaultGroup: '',
            officeLocation:''
        }as RecycleReturnsState.RecycleReturnsPageState;
        let filterTaxYear: any;
        let filterStatus:any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string; } = {};

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data === "-1" ? "" : data;


            switch (field) {
                case 'clientName':
                    newState.clientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.clientID = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxReturnType':
                    newState.taxReturnType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partner':
                    newState.partner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredByFirstName':
                    newState.sentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredOn':
                    newState.deliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'signatureStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus == "-1") ? "" : filterStatus;
                    newState.status = filterStatus;
                    break;
                case 'deletedOn':
                    newState.deletedOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
                    break;
                case 'deletedByFirstName':
                    newState.deletedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear == "-1") ? "" : filterTaxYear;
                    this.setState({ taxYear: filterTaxYear });
                    newState.taxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'officeLocation':
                    newState.officeLocation = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
            }
        }
        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize },
                () => {
                         const query = this.buildQuery(this.state.page, this.state.pageSize);
                        this.props.requestRecycleReturns(query, true, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
                 })
        }
    }

    private isFilterChanged(newState: RecycleReturnsState.RecycleReturnsPageState, filterStatus: any, filterTaxYear: any): boolean {
        return (
            newState.clientName !== this.state.clientName ||
            newState.clientID !== this.state.clientID ||
            newState.taxReturnType!=this.state.taxReturnType||
            newState.partner !== this.state.partner ||
            newState.sentBy !== this.state.sentBy ||
            filterStatus !== this.state.status ||
            newState.deletedOn!==this.state.deletedOn||
            newState.deliveredOn !== this.state.deliveredOn ||
            newState.deletedBy!==this.state.deletedBy||
            newState.status!== this.state.status ||
            filterTaxYear !== this.state.taxYear ||
            newState.officeLocation!=this.state.officeLocation
        )
    }

    private onPageReload() {
        this.setState(
            { refreshDelay: true },
            () => {
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestRecycleReturns(query, true, () => {
                    this.setState({
                        refreshDelay: false
                    });
                }, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
                this.clearSelection();

                logClientEvent(
                    `${LogEventConstants.Common.RefreshRecycleBin} ${pageTitle}`,
                    { count: 1, page: pageTitle }
                );
            });
    }

    reloadOnNoDataFound = () => {
        this.setState(prevState => ({ page: prevState.page - 1 }), () => {
            this.onPageReload();
        });
    }

    private clearSelection = () => {
        this.setState({ selectedRows: [], deSelectedRows: [], isBulkSelectionEnabled: false, showBulkSelectionMessage: false });
    }


    buildQuery(page: number, pageSize: number) {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortColumn=' + this.state.sortColumn
            + '&sortDirection=' + this.state.sortDirection
            + '&taxYear=' + this.state.taxYear
            + '&clientName=' + this.state.clientName
            + '&clientID=' + this.state.clientID
            + '&partner=' + this.state.partner
            + '&status=' + this.state.status
            + '&engagementType=' + this.state.taxReturnType
            + '&sentBy=' + this.state.sentBy
            + '&deliveredOn=' + this.state.deliveredOn
            + '&deletedOn=' + this.state.deletedOn
            + '&deletedBy=' + this.state.deletedBy
            +'&officeLocation='+this.state.officeLocation;
    }

    loadRecycleReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestRecycleReturns(queryString, true, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
    }

    fetchRecycleReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => { this.props.requestRecycleReturns(queryString, undefined, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined) });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.recycleReturns.recycleReturnTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.documentId);
            }
        }
        return ids;
    }

    private selectedDocuments() {
        let docs: RecycleTaxReturns[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.recycleReturns.recycleReturnTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private confirmDocumentDelete = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(RecyleReturnsConstants.SelectDocumentWarning, null);
            return;
        }

        bootbox.confirm({
            title: RecyleReturnsConstants.Title.RecyleReturns,
            message: RecyleReturnsConstants.ConfirmMessages.Delete,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.handleDocumentDelete(ids, result);
                }
            }
        });
    }

    private handleDocumentDelete = (selectedDocumentIds: number[], result: boolean) => {
        ShowLoader(DeleteRecycledExtensionConstants.Progress);
        if (result) {
            if (this.state.isBulkSelectionEnabled) {
                this.onBulkDeleteExtensions();
            }
            else {
                this.props.deleteDocuments(selectedDocumentIds, () => {
                    HideLoader();
                    this.setState({
                        selectedRows: [],
                        isBulkSelectionEnabled: false,
                        showBulkSelectionMessage: false
                    });
                    this.onPageReload();

                    logClientEvent(
                        `${LogEventConstants.Common.DeleteExtension} ${pageTitle}`,
                        { count: selectedDocumentIds?.length ?? 0, page: pageTitle }
                    );
                });
            }
        }
    }

    private onBulkDeleteExtensions = () => {
        let query = this.getBulkOperationQuery();
        this.props.bulkExtensionDelete(query, () => {
            HideLoader();
            this.setState({
                selectedRows: [],
                isBulkSelectionEnabled: false
            });
            this.onPageReload();
        });
    }

    private onPopupOpen(rowIndex: number) {
        if (rowIndex !== NO_INDEX) {
            return [this.props.recycleReturns.recycleReturnTableModel.documents[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds();
    }

    private onRestoreDocument = (rowIndex: number = NO_INDEX) => {
        this.onRestoreValidate(rowIndex, this.restoreCallBack);
    }

    private onRestoreValidate = (rowIndex: number, callback: (rowIndex: number) => void) => {
        const selectedDocuments = this.selectedDocuments();
        for (let i = 0; i < selectedDocuments.length; i++) {
            if (moment(selectedDocuments[i].deliveredOn).add(selectedDocuments[i].retentionPeriod, 'days') <
                moment(new Date())) {
                VenusNotifier.Warning(RestoreReturnConstants.ReturnExpired.Restore, null);
                return;
            }
        }
        callback(rowIndex);
    }

    private restoreCallBack = (rowIndex: number) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(RestoreReturnConstants.SelectDocumentWarning, null);
            return;
        }

        bootbox.confirm({
            title: RestoreReturnConstants.Title.Restore,
            message: RestoreReturnConstants.ConfirmMessages.Restore,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.handleDocumentRestore(ids, result);
                }
            }
        });
    }

    private handleDocumentRestore = (selectedDocumentIds: number[], result: boolean) => {
        ShowLoader(RestoreReturnConstants.Progress);
        if (result) {
            if (this.state.isBulkSelectionEnabled) {
                this.onBulkRestoreReturns();
            }
            else {
                this.props.restoreDocuments(selectedDocumentIds, () => {
                    HideLoader();
                    this.setState({
                        selectedRows: [],
                        isBulkSelectionEnabled: false,
                        showBulkSelectionMessage: false
                    });
                    this.onPageReload();

                    logClientEvent(
                        `Restore Extension ${pageTitle}`,
                        { count: selectedDocumentIds?.length ?? 0, page: pageTitle }
                    );
                });
            }
        }
    }

    private onBulkRestoreReturns = () => {
        let query = this.getBulkOperationQuery();
        this.props.bulkReturnRestore(query, () => {
            HideLoader();
            this.setState({
                selectedRows: [],
                isBulkSelectionEnabled: false
            });
            this.onPageReload();
        });
    }

    getBulkOperationQuery = (): RecycleBulkOperationQuery => {
        let query: RecycleBulkOperationQuery = {
            clientId: this.state.clientID,
            name: this.state.clientName,
            partner: this.state.partner,
            deliveredOn: this.state.deliveredOn,
            engagementType: this.state.taxReturnType as number,
            sentBy: this.state.sentBy,
            status: this.state.status,
            taxYear: this.state.taxYear.toString(),
            deletedBy: this.state.deletedBy,
            deletionDate: this.state.deletedOn,
            unSelectedIds: this.state.deSelectedRows,
            officeLocation:this.state.officeLocation
        }
        return query;
    }

    public render() {

        return <div className='user-assignment-content'>
            <RecycleReturnsHeader
                pageTitle="Recycle Bin"
                onTaxYearSelect={this.handleTaxYearChange}
                selectedDocumentCount={this.state.selectedRows.length}
                taxYear={this.state.taxYear}
                onPageReload={this.onPageReload}
                onDeleteDocument={this.confirmDocumentDelete}
                company={this.props.company}
                selectedERO={this.props.extensionUserSettings.defaultSettings ? this.props.extensionUserSettings.defaultSettings.eroUser : 0}
                getUploadLink={(url: string, callback: () => void) => {
                    this.props.getUploadLink(url, RBACKeys.RecycleBin.uploadButton, callback);
                }}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                    this.props.submitTaxReturn(url, taxData, RBACKeys.RecycleBin.uploadButton, callback);
                }}
                extensionUsers={this.props.usersStore.extensionUsers}
                partners={this.props.usersStore.partners}
                companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                userDefaultSettings={this.props.userSettings.settings}
                refreshDelay={this.state.refreshDelay}
                onRestoreDocument={this.onRestoreDocument}
                isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                groupInfo={this.props.groupedExtensionsStore.groupInfo}
                updateGroupInfoState={this.props.requestGroupConfiguration}
                defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                groupAccess={this.props.groupedExtensionsStore.groupAccess}
                taxSoftwareData={this.props.taxSoftwareData.taxSoftwareSettings}
                getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void ) => {
                    this.props.getTaxDocumentsAccessMaps(ids, true,RBACKeys.RecycleBin.uploadButton, callback)
                }}
            />
            <br />
           {this.props.userOfficeLocation.userLocation.length>0 && <RecycleReturnsTable
                ref='RecycleReturnsTable'
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                onFilterChange={this.onFilterChange}
                pageNo={this.state.page}
                totalRows={this.props.recycleReturns.totalRowCount}
                loadGrid={this.loadRecycleReturns}
                isLoading={this.props.recycleReturns.loading}
                pageSize={pageSize}
                onRowSelect={this.onRowSelect}
                onSelectAll={this.onSelectAll}
                recycleReturns={this.props.recycleReturns}
                selectedRows={this.state.selectedRows}
                showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                onBulkSelectionChange={this.onBulkSelectionChange}
                userLocation={this.props.userOfficeLocation.userLocation} />
            }

        </div>;
    }
};