import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CloseIcon from "src/images/CloseIcon";
import {
    IClientInfoComparisonResult,
    IClientInfoMismatchType,
    IClientInfoMismatchViewModel,
    clientRows,
    columnNames,
    mismatchModalTitle,
    sortRows
} from "./MismatchModal.model";
import "src/assets/custom/ClientManagement.css";
import { TaxreturnType } from "../ReportProblem/ReportProblemModel";
import { formatEIN, formatSSN, phoneNumberDisplay } from "../../../components/helper/HelperFunctions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store/types";
import { EIN, SSN } from "src/components/helper/Constants";

interface IMismatchModalProps {
    showMismatchModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    clientManagementSave(): void;
    clientManagementCancel(): void;
    closeMismatchModal(): void;
    taxReturnType: TaxreturnType;
    popupInProgress: boolean;
}

interface IClientRow {
    [key: string]: any;
}

const MismatchModal: React.FC<IMismatchModalProps> = ({
    showMismatchModal,
    clientInfoComparisonResult,
    clientManagementSave,
    clientManagementCancel,
    closeMismatchModal,
    taxReturnType,
    popupInProgress}) => {
            
    const [columns, setColumns] = useState<string[]>([]);
    const [rows, setRows] = useState<string[]>([]);

    const modalWidth = clientInfoComparisonResult?.comparisonStatus === IClientInfoMismatchType.NewClient ? "555px" : "700px";
    const { SSNMismatch, ClientIdMismatch, NewClient, DataMismatch } = IClientInfoMismatchType;
    const partnerUsers  = useSelector((state: ApplicationState) => state.usersStore.partners);

    useEffect(() => {
        setRowsAndColumns();
    }, [clientInfoComparisonResult]);

    function getKeyByValue(clientRow: IClientRow, value: any): string {
        const keyValues = Object.entries(clientRow).find(([key, val]) => val === value);
        let key: string = "";

        if (keyValues) {
            key = keyValues[0];
        }
        return key;
    }

    const setRowsAndColumns = () => {
        if (clientInfoComparisonResult?.clientInfoResultData) {
            const rows = sortRows(
                Object.keys(clientInfoComparisonResult.clientInfoResultData),
                clientInfoComparisonResult?.comparisonStatus
            );

            const columns: string[] = [];
            for (let i = 0; i < rows.length; i++) {
                if (clientInfoComparisonResult.clientInfoResultData[rows[i]]?.taxReturnValue) {
                    columns.push(columnNames.taxReturn);
                    break;
                }
            }
            for (let i = 0; i < rows.length; i++) {
                if (clientInfoComparisonResult.clientInfoResultData[rows[i]]?.clientManagementValue) {
                    columns.push(columnNames.clientManagement);
                    break;
                }
            }

            setRows(rows);
            setColumns(columns);
        }
    };

    const checkTaxreturnType = () => {
        return (taxReturnType === TaxreturnType.CorporateTaxReturn) ? EIN : SSN;
    };

    const renderRows = (row: string) => {
        if (row === getKeyByValue(clientRows, clientRows.uid)) {
            return checkTaxreturnType();
        } else {
            return clientRows[row];
        }
    };

    const renderColumnData = (data: string, row: string) => {
        if (data && row === getKeyByValue(clientRows, clientRows.uid)) {
            if (checkTaxreturnType().includes("EIN")) {
                return formatEIN(data);
            } else {
                return formatSSN(data);
            }
        } else if (data && row === getKeyByValue(clientRows, clientRows.spouseSSN)) {
            return formatSSN(data);
        } else if (
            data &&
            [
                getKeyByValue(clientRows, clientRows.dob),
                getKeyByValue(clientRows, clientRows.spouseDOB)
            ].includes(row)
        ) {
            const date = new Date(data);
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        } else if (
            data &&
            [
                getKeyByValue(clientRows, clientRows.isDeceased),
                getKeyByValue(clientRows, clientRows.spouseIsDeceased)
            ].includes(row)
        ) {
            return data?.toLowerCase() === "true" ? "Yes" : "No";
        } else if (
            data &&
            [
                getKeyByValue(clientRows, clientRows.mobileNumber),
                getKeyByValue(clientRows, clientRows.spouseMobileNumber)
            ].includes(row)
        ) {
            return phoneNumberDisplay(data);
        } else if (data && row === getKeyByValue(clientRows, clientRows.ero)) {
            const ero = partnerUsers.find(partnerUser => partnerUser.id === Number(data));
            return `${ero?.firstName} ${ero?.lastName}`;
        } else {
            return data;
        }
    };

    const checkNullValues = (data: IClientInfoMismatchViewModel, row: string) => {
        if (clientInfoComparisonResult?.comparisonStatus === IClientInfoMismatchType.ClientIdMismatch) {
            return (
                (data[row].hadDifference && (data[row].taxReturnValue || data[row].clientManagementValue)) ||
                [getKeyByValue(clientRows, clientRows.clientId), getKeyByValue(clientRows, clientRows.name), getKeyByValue(clientRows, clientRows.uid)].includes(row)
            );
        } else {
            return (
                (data[row].hadDifference && (data[row].taxReturnValue || data[row].clientManagementValue)) ||
                [getKeyByValue(clientRows, clientRows.clientId), getKeyByValue(clientRows, clientRows.name)].includes(row)
            );
        }
    };

    const getMismatchModalHeader = (): any => {
        const taxreturnType = checkTaxreturnType();
        const comparisonStatus = clientInfoComparisonResult.comparisonStatus;

        if (comparisonStatus === SSNMismatch) {
            return taxreturnType.includes("EIN") ? 
            <div>We noticed the <strong>Employer Identification Number (EIN)</strong> from the Tax Extension differs from the Client Management Record. You can update the Client Management record or cancel and adjust the Tax Extension.</div>
            : <div>We noticed the <strong>Social Security Number (SSN)</strong> from the Tax Extension differs from the Client Management Record. You can update the Client Management record or cancel and adjust the Tax Extension.</div>;
        } else if (comparisonStatus === ClientIdMismatch) {
            return taxreturnType.includes("EIN") ? 
            <div>We have detected another Client with a different <strong>Client ID</strong> but the same Employer Identification Number (EIN). You can update the Client Management record or cancel and adjust the Tax Extension.</div> 
            : <div>We have detected another Client with a different <strong>Client ID</strong> but the same Social Security Number (SSN). You can update the Client Management record or cancel and adjust the <br/>Tax Extension.</div>;
        } else if (comparisonStatus === NewClient) {
            return <div>Do you wish to add this data into your Client Management records?</div>;
        } else if (comparisonStatus === DataMismatch) {
            return <div>You have changed some information that differs from Client Management. <br/>Would you like us to update Client Management with this new data?</div>;
        } else {
            return <div>No Data Mismatch</div>;
        }
    };

    const getMismatchModalTitle = (): string => {
        if (clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            return mismatchModalTitle.newClient;
        } else {
            return mismatchModalTitle.reviewAndConfirm;
        }
    };

    const getPrimaryButton = () => {
        if (clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            return 'Yes, Add It';
        } else {
            return 'Yes, Update';
        }
    }

    const getSecondaryButton = () => {
        if (clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            return 'No, Do Not Add It';
        } else {
            return 'No, Do Not Update';
        }
    }

    const getDifferenceClass = (data: IClientInfoMismatchViewModel, row: string) => {
        if (data[row].hadDifference) {
            if (columns.includes(columnNames.clientManagement)) {
                return 'client-data-difference';
            }
        }
        return '';
    }

    return (
        <Modal show={showMismatchModal} className="modalClass mismatch-modal" onHide={closeMismatchModal}>
            <Modal.Dialog style={{ width: modalWidth }}>
                <Modal.Header>
                    <div>
                        <Modal.Title>{getMismatchModalTitle()}</Modal.Title>
                    </div>
                    <div data-test-auto="e2ab6c41-b6f5-4181-b38f-3455585b2141" className="closeIcon" onClick={closeMismatchModal}>
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="mulishFont">{getMismatchModalHeader()}</div>
                    <div>
                        <table className="table table-striped clients-list-table mulishFont">
                            <thead className="table-header">
                                <tr>
                                    <th className="width-auto">Client Information</th>
                                    {columns.length > 0 &&
                                        columns.map((column: string, index: number) => (
                                            <th key={index} className="width-auto">
                                                {column}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.length > 0 &&
                                    rows.map((row: string, index: number) => {
                                        const data = clientInfoComparisonResult.clientInfoResultData;
                                        const taxReturnValue = renderColumnData(data[row].taxReturnValue, row);
                                        const clientManagementValue = renderColumnData(data[row].clientManagementValue, row);

                                        return checkNullValues(data, row) ? (
                                            <tr style={{ fontWeight: `${index === 0 ? '600' : '400'}` }} key={index} className="client-row">
                                                <td title={renderRows(row)}>{renderRows(row)}</td>

                                                {columns.includes(columnNames.taxReturn) ? (
                                                    <td
                                                        title={taxReturnValue}
                                                        className={getDifferenceClass(data, row)}
                                                    >
                                                        {taxReturnValue}
                                                    </td>
                                                ) : (
                                                    <></>
                                                )}

                                                {columns.includes(columnNames.clientManagement) ? (
                                                    <td
                                                        title={clientManagementValue}
                                                        className={getDifferenceClass(data, row)}
                                                    >
                                                        {clientManagementValue}
                                                    </td>
                                                ) : (
                                                    <></>
                                                )}
                                            </tr>
                                        ) : (
                                            <React.Fragment key={index}></React.Fragment>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="white-background">
                    <Button
                        disabled={popupInProgress}
                        className="do-not-update-button"
                        data-test-auto="bf271b75-04e5-4238-bebc-b1f3667be192"
                        variant={
                            [SSNMismatch, ClientIdMismatch].includes(clientInfoComparisonResult.comparisonStatus)
                                ? "primary"
                                : "secondary"
                        }
                        onClick={clientManagementCancel}
                    >
                        {getSecondaryButton()}
                    </Button>
                    <Button
                        disabled={popupInProgress}
                        className="update-button"
                        data-test-auto="6e15750b-3c9d-49a9-b272-49e048689c94"
                        variant={
                            [SSNMismatch, ClientIdMismatch].includes(clientInfoComparisonResult.comparisonStatus)
                                ? "secondary"
                                : "primary"
                        }
                        onClick={clientManagementSave}
                    >
                        {getPrimaryButton()}
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

export default MismatchModal;
