import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { ITaxingAuthority } from '../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { CustomAuthoritys } from '../../../src/components/helper/Constants';
import { RequestTaxAuthoritiesAction, ReceiveTaxAuthoritiesAction } from '../company/CompanyStore';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';


export interface ICustomAuthorityState {
    taxingAuthority: ITaxingAuthority[];
    clientIDs: string[];
    loading: boolean;
}

export interface RequestCustomTaxAuthoritiesAction {
    type: actionTypes.REQUEST_CUSTOM_TAX_AUTHORITIES;
}

export interface ReceiveCustomTaxAuthoritiesAction {
    type: actionTypes.RECEIVE_CUSTOM_TAX_AUTHORITIES;
    taxingAuthority: ITaxingAuthority[];
}

export interface RequestAuthorityRelatedTaxDocuments {
    type: actionTypes.REQUEST_AUTHORITY_RELATED_TAX_DOCUMENT_IDS;
}

export interface ReceiveAuthorityRelatedTaxDocuments {
    type: actionTypes.RECEIVE_AUTHORITY_RELATED_TAX_DOCUMENT_IDS;
    clientIDs: string[];
}

type KnownAction =
    RequestTaxAuthoritiesAction |
    ReceiveTaxAuthoritiesAction |
    RequestCustomTaxAuthoritiesAction |
    ReceiveCustomTaxAuthoritiesAction |
    RequestAuthorityRelatedTaxDocuments |
    ReceiveAuthorityRelatedTaxDocuments |
    NotificationAction
    ;

const unloadedState: ICustomAuthorityState = {
    taxingAuthority: [],
    clientIDs: [],
    loading: false
} as ICustomAuthorityState;

export const actionCreators = {

    requestCustomAuthority: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload) {
            const fetchTask = fetch(`${API_BASE_URL}api/Common/GetCustomTaxingAuthorityAsync`, { credentials: 'include' })
                .then(handleResponse)
                .then(response => response as Promise<ITaxingAuthority[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_CUSTOM_TAX_AUTHORITIES, taxingAuthority: data
                    });
                }).catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    logger.trackError(`requestCustomAuthority failed with error ${error.message}`);
                });
            addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_CUSTOM_TAX_AUTHORITIES });
        }
    },

    SaveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback1: () => void, callback2?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/common/SaveTaxingAuthorityAsync`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(taxingAuthority)
        }).then(handleResponse)
            .then(() => {

                if (callback2) {
                    callback2();
                }
            })
            .then(data => {
                callback1();
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`SaveTaxingAuthority failed for taxingAuthority: ${taxingAuthority}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_CUSTOM_TAX_AUTHORITIES });
    },
};

export const reducer: Reducer<ICustomAuthorityState> = (state: ICustomAuthorityState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.REQUEST_CUSTOM_TAX_AUTHORITIES:
            return {
                taxingAuthority: [...state.taxingAuthority],
                clientIDs: [...state.clientIDs],
                loading: true
            } as ICustomAuthorityState;
        case actionTypes.RECEIVE_CUSTOM_TAX_AUTHORITIES:
            return {
                taxingAuthority: action.taxingAuthority,
                clientIDs: state.clientIDs,
                loading: false
            } as ICustomAuthorityState;
        case actionTypes.REQUEST_AUTHORITY_RELATED_TAX_DOCUMENT_IDS:
            return {
                taxingAuthority: [...state.taxingAuthority],
                clientIDs: [...state.clientIDs],
                loading: true
            } as ICustomAuthorityState;
        case actionTypes.RECEIVE_AUTHORITY_RELATED_TAX_DOCUMENT_IDS:
            return {
                taxingAuthority: [...state.taxingAuthority],
                clientIDs: action.clientIDs,
                loading: false
            } as ICustomAuthorityState;
    }

    return state || unloadedState;
};