import { IDownloadedZipFilesModel } from '../../../components/navigation/profile/MyDownload';
export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ICompanyInfo {
    companyName: string;
    phoneNumber: string;
    fax: string;
    website: string;
    address: string;
    unit: string;
    city: string;
    stateId: number;
    zip: number;
    adminUser: number;
    isDefaultLogo: boolean;
    id: number;
    isCompanyNameAsWhiteLogo: boolean;
};



export interface IPrimaryAdmin {
    userId: number;
    firstName: string;
    phoneNumber: string;
    extension: string;
    email: string;
    title: string;
    mobileNumber: string;
    countryCode: string;
};

export interface ICompany {
    companyInfo: ICompanyInfo;
    countryStates: ICountryState[];
}

export interface IAdmin {
    adminId: number;
    adminUsers: IPrimaryAdmin[];
    currentAdmin: IPrimaryAdmin
}

export interface ICompanyLogoSetting {
    isDefaultLogo: boolean;
    logoPath: string;
    updatedLogo: Blob;
    isLogoUpdated: boolean;
    isLogoChangedToMyLogo: boolean;
    isCompanyNameChanged: boolean
}

export interface ICompanyWhiteLogoSetting {
    isCompanyNameAsWhiteLogo: boolean;
    whiteLogoPath: string;
    updatedWhiteLogo: Blob;
    isWhiteLogoUpdated: boolean;
    isLogoChangedToCompanyName: boolean;
    isWhiteLogoDelted: boolean;
}

export interface IState {
    company: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting; 
    showEditCompanyPopUp: boolean;
    showEditPrimaryAdminPopup: boolean;
    showEditCompanyLogoPopup: boolean;
    alert: string;
    message: string;
    showAlert: boolean;
    companyWhiteLogoSetting: ICompanyWhiteLogoSetting;
};


export const initialCompanyModel: ICompany = {
    companyInfo: {
        companyName: "",
        phoneNumber: "",
        fax: "",
        website: "",
        address: "",
        unit: "",
        city: "",
        stateId: 0,
        zip: 0,
        adminUser: 0,
        isDefaultLogo: false,
        id: 0,
        isCompanyNameAsWhiteLogo: false
    },
    countryStates: [{
        id: 0,
        name: "",
        image: ""
    }]
}

export const initialAdminModel: IAdmin = {
    adminId: 0,
    adminUsers: [{
        userId: 0,
        firstName: "",
        phoneNumber: "",
        extension: "",
        email: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }],
    currentAdmin: {
        userId: 0,
        firstName: "",
        phoneNumber: "",
        extension: "",
        email: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }
}

export const initialCompanyLogoSettings: ICompanyLogoSetting = {
    isDefaultLogo: false,
    logoPath: "",
    updatedLogo: new Blob([]),
    isLogoUpdated: false,
    isLogoChangedToMyLogo: false,
    isCompanyNameChanged: false
}

export const initialCompanyWhiteLogoSettings: ICompanyWhiteLogoSetting = {
    isCompanyNameAsWhiteLogo: false,
    isWhiteLogoDelted: false,
    isLogoChangedToCompanyName: false,
    isWhiteLogoUpdated: false,
    updatedWhiteLogo: new Blob([]),
    whiteLogoPath:""
}

export const initalMyDownloadsList: IDownloadedZipFilesModel[] = [];