import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '..//ActionTypes';
import { IDropdown } from "../../Core/Common/Dropdown";
import { handleResponse } from '../Library';
import { API_BASE_URL } from '../../utils/contants';
import { StatusType, NotificationAction } from '../common/NotificationStore';

export interface IOfficeLocationState {
    locationDropdown: IDropdown[];
    loading: boolean;
}

export interface RequestOfficeLocationDropdownAction {
    type: actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN;
}

export interface ReceiveOfficeLocationDropdownAction {
    type: actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN;
    locationDropdown: IDropdown[];
}

type KnownAction =
    RequestOfficeLocationDropdownAction |
    ReceiveOfficeLocationDropdownAction |
    NotificationAction
    ;

const unloadedState: IOfficeLocationState = {
    locationDropdown: [],
    loading: false
} as IOfficeLocationState;

export const actionCreators = {

    getOfficeLocationDropdown: (reload: boolean = false, callback?: ()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.officeLocation?.locationDropdown.length == 0) {
            const fetchTask = fetch(API_BASE_URL + 'api/office-location/dropdown', { credentials: 'include' })
                .then(handleResponse)
                .then(response => response as Promise<IDropdown[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN, locationDropdown: data
                    });
                    callback && callback();
                }).catch(function (error) {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof (statusMessage) === "string") {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                    }
                });
            addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN });
        }
    }
}

export const reducer: Reducer<IOfficeLocationState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.REQUEST_OFFICE_LOCATION_DROPDOWN:
            return {
                locationDropdown: [...state.locationDropdown == undefined ? [] : state.locationDropdown],
                loading: true
            } as IOfficeLocationState;
        case actionTypes.RECEIVE_OFFICE_LOCATION_DROPDOWN:
            return {
                locationDropdown: action.locationDropdown,
                loading: false
            } as IOfficeLocationState;
    }
    return state;
};