import React from  'react';
//import { Panel } from 'react-bootstrap';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import {
    CustomType, DocumentGroups, getAllStateImages, getVoucherTypes,
    IFormBase, ITaxingAuthority, ITaxReturn, IVoucher,
    StateUSA, VoucherNo, VoucherTypes, VoucherMode, initialVoucher
} from '../../../common/TaxReturn';
import * as Helper from '.././../../helper/HelperFunctions';
import { VoucherPayment, VoucherEstimatedPayment } from '../../../svg/IconCollection';
import { Accordion, Card } from 'react-bootstrap';



export interface IRecognizedVoucherProps {
    taxReturn: ITaxReturn;

    populateVoucherPanel: (voucher: IVoucher) => void;

    getAllTaxingAuthorities: ITaxingAuthority[];

}
export interface IRecognizedVoucherState {
    voucher: IVoucher;
    showAddVoucherView: boolean;
    popoverTarget: any;
    showAlert: boolean;
    message: string;
    expandRecognizedVoucherListPanel: boolean;
    enableLeftPanelScroll: boolean;
}

export class RecognizedVoucherList extends React.Component<IRecognizedVoucherProps, IRecognizedVoucherState>
{
    constructor(props: IRecognizedVoucherProps) {
        super(props);
        this.state = {
            voucher: initialVoucher,
            showAddVoucherView: false,
            popoverTarget: {},
            showAlert: false,
            message: '',
            expandRecognizedVoucherListPanel: true,
            enableLeftPanelScroll: true
        };



        this.enableScroll = this.enableScroll.bind(this);

        this.getTitle = this.getTitle.bind(this);
        this.stopScroll = this.stopScroll.bind(this);

        this.getVoucherClass = this.getVoucherClass.bind(this);
    }




    getTitle(amount: number, formName: string) {

        if (formName) {
            return [
                <div title={formName} className="bookmark-name wordbreak ellipsis">{formName}</div>,
                <div className="bookmark-right client-info-payments" title={'$' + Helper.formatCurency(Number(amount))}
                    style={{
                        fontFamily: "'Lucida Console', 'Monaco', 'monospace'",
                        color: '#ff0000'
                    }}>
                    {'($' + Helper.formatCurency(Number(amount)) + ')'}
                </div>
            ]
        } else {
            return null;
        }
    }

    private getVoucherClass(voucher: IVoucher) {
        let voucherClass: string = "payment-voucher";
        if (getVoucherTypes(voucher.voucherNo) ===
            VoucherTypes.EstimatedVoucher) {
            voucherClass = "estimated-voucher";
        }
        return voucherClass;
    }


    enableScroll = () => {
        if (!this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftPanelScroll: true });
        }

    }

    stopScroll = () => {
        if (this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftPanelScroll: false });
        }

    }

    public render() {
        let authorities = this.props.getAllTaxingAuthorities;
        let recognizedVouchers: IFormBase[] = [];
        let voucherIndex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        if (this.props.taxReturn.formGroups[voucherIndex]) {
            recognizedVouchers = this.props.taxReturn.formGroups[voucherIndex].forms.filter((value: IFormBase, index: number) => {
                return (value as IVoucher).vocherMode === VoucherMode.Recognized
            });
            recognizedVouchers.sort((a, b) => a.pageNo[0] - b.pageNo[0]);
        }


        return <div className="bookmarksPane">

            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Card.Header as="h4">
                        <a aria-expanded="true" role="button" href="#">Recognized Vouchers</a>
                    </Card.Header>
                    <Card.Body style={{ padding: 0 }}>
                        <div className="pageListView">
                            {
                                recognizedVouchers.length == 0 ? <div className="textalign-center">No Content</div> :

                                    (recognizedVouchers.map((value: any, index: number) => {
                                        return (<div
                                            key={index}
                                            className="custom-list-group-item bookmark-item " >
                                            <div className="bookmark-row">
                                                <div style={{ width: "24px" }}>

                                                    {(this.getVoucherClass(value) == "estimated-voucher") ?
                                                        <VoucherEstimatedPayment className={"bookmark-icon-image"} /> :
                                                        <VoucherPayment className={"bookmark-icon-image"} />
                                                    }
                                                </div>
                                                <div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 46px)" }}
                                                    onClick={() => { this.props.populateVoucherPanel(value); }}>
                                                    {
                                                        this.getTitle(value.amount, value.formName)
                                                    }
                                                </div>


                                            </div>
                                        </div>)
                                    }))
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Accordion>
        </div>
    }
};
