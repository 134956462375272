import React from 'react';
import 'isomorphic-fetch';
import * as bootbox from 'bootbox';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
//Internal
import { VenusNotifier } from '../helper/VenusNotifier';
import * as Helper from '../helper/HelperFunctions';

import { ClientInfo } from '../common/ClientInfo';
import { ReportProblemModal } from '../common/ReportProblemModal';
import { ICompanySettings, initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';

import {
    ITaxReturn, TaxCaddyLookupResult, IDocumentAccess, EngagementType,
    DocumentStatus, VoucherNo, ITaxingAuthority, ClientTypes, IVoucher, DocumentGroups, DocumentStatusType, IOfficeLocation, engagementType, IDocumentAccessSaveModel
} from '../common/TaxReturn';
import { getClientName, DaysRangeOptionsList } from '../common/TaxReturn';
import { IUserBaseModel, IUserModel, IUserGroup } from '../../Core/ViewModels/User/UserViewModel';
import { AssignmentChangeStatusModal } from '../common/AssignmentChangeStatusModal';
import { UploadDocumentModal } from '../common/UploadDocumentModal';
import { UploadRecalledDocumentModal } from '../common/UploadDocument/UploadRecalledDocumentModal';
import { TaxReturnUploadData } from '../../Core/ViewModels/Common/UploadDocumentViewModel';

import { ClientTrackingModal } from '../common/ClientTracking';
import { ProcessReturnModal, IProcessReturnProps } from '../common/ProcessReturnModal/ProcessReturnModal';

import { AssignmentsToolbar } from './AssignmentsToolbar';
import { AssignmentTable, ColumnValues } from './AssignmentTable';
import { SetAccessModal } from '../common/SetAccessModal';
import { AssignModal } from '../common/AssignModal';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as UserSignatureStore from '../../store/common/UserSignatureStore';
import * as VoucherStore from '../../store/ProcessReturn/voucher/VoucherStore';
import { IAssignmentsState } from '../../store/assignments/AssignmentsState';
import { IColumnValues, ICustomColumn } from '../settings/GeneralSettings';
import * as PdfStore from '../../store/pdf/PdfStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import { AttachmentsTableModel } from '../../store/ProcessReturn/AttachmentsState';
import * as SavedMessageStore from '../../store/settings/SavedMessageStore';
import { ISubDocument } from '../../Core/Utilities/PdfDocumentFacade';
import { ProcessReturnConstants, AssignUserConstants, SetAccessConstants, ChangeStatusConstants, DeleteTaxReturnConstants, ReProcessReturnConstants, DeliveredReturnsConstants, ReportFilterConstants, ProcessExtensionConstants, GroupExtensionConstants, clearedFilter } from '../helper/Constants';
import { CheckBoxComponent, CheckBoxSize } from '../common/CheckBoxComponent';
import { CustomSelectProps, TextFilter, SelectFilter } from 'react-bootstrap-table';
import { AssignmentAction } from './AssignmentAction';
import { IBusinessReturnsState } from '../../store/settings/BusinessReturnsStore';
import { CustomMultiSelect } from '../common/MultipleSelectComponent';
import { IFilters, SortDirections, ReportFilterType } from '../reports/Filters';
import * as FilterStore from '../../store/reports/FilterStore';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as AdditionalDocumentTypeStore from '../../store/common/AdditionalDocumentTypeStore';
import { IWatermarkModel } from '../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting';
import { EfileInfoView } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import { IReportProblemDetails, ReportedStep, ReportProblemTypes } from '../common/ReportProblem/ReportProblemModel';
import { ExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import { GroupAccess, GroupInfo } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { GroupConfigurationModal } from '../GroupedExtensions/Common/GroupConfiguration';
import { logClientEvent, logger } from '../helper/LoggerHelper';
import { BulkOperationQueryExt } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { RBACKeys } from '../helper/RbacConstants';
import { TaxSoftwareType, initialSuiteTaxSoftwareSettings } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import { IAuthState } from 'src/store/auth/reducer';
import { DocumentEventLog, LogEventConstants } from '../helper/LogEventConstants';
import { IDropdown } from '../../Core/Common/Dropdown';
import { EngagementList } from 'src/Core/Common/Common';
import SetAccessCommonModal from '../common/SetAccess/SetAccessCommonModal';
import { IAccessingUsersAndUserGroups, IUserDataFromDB, IUserGroupDataFromDB } from '../common/SetAccess/SetAccessCommonModal.model';

export interface IAssignmentsProps {
    auth: IAuthState;
    id: string;
    title: string;
    automationId: string;
    currentPage: IAssignmentsState;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    users: IUserModel[];
    partners: IUserModel[];
    defaultERO: number;
    company: CompanyStore.ICompanyData;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    profile: IUserProfile;
    requestAssignments: (query: string, reload: boolean, callback?: () => void, reloadOnNoDataFound?: () => void) => void;
    assignedUser: (selected: ITaxReturn[]) => number;
    requestTaxDocument: (id: number, force: boolean, requestTaxPayerView?: boolean, clientType?: ClientTypes, callback?: (data: any) => void, isEditClientInfoRequest?: boolean) => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    saveTaxDocument: (taxReturn: ITaxReturn, customColumn?: IColumnValues, callback?: (data?: any) => void, emailCallback?: (id: number, isMailSent: boolean) => void, isMailSent?: boolean) => void;
    assignTaxDocument: (taxDocument: ITaxReturn) => void;
    deleteTaxDocument: (ids: number[], callback: () => void, resourceId: string) => void;
    requestUsersList: (reload: boolean) => void;
    getTaxDocumentsAccessMaps: (ids: number[], resourceId:string, callback?: (result: IDocumentAccess) => void ) => void;
    setTaxDocumentsAccessMaps: (accessMaps: IDocumentAccessSaveModel, resourceId: string, callback?: () => void ) => void;
    requestTaxDocumentClientTracking: (id: number) => void;
    reportTaxDocumentProblem: (problemDetails: IReportProblemDetails, callback?: () => void) => void;
    onExportToExcel(onExportToComplete: () => void, bulkQuery: BulkOperationQueryExt): void;
    requestCompanySettings: (force: boolean) => void;
    requestPdfDocument: (taxReturn: ITaxReturn, reload: boolean) => void;
    requestUserProfile: () => void;
    requestTaxingAuthorities: () => void;
    sendToERO: (taxDocument: ITaxReturn) => void;
    sendForReview: (taxDocument: ITaxReturn) => void;
    approveForDelivery: (taxDocument: ITaxReturn) => void;
    deliverToClient: (taxDocument: ITaxReturn) => void;
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    requestAttachments: (taxReturn: ITaxReturn, fileName: string, file: any, processData: (data: AttachmentsTableModel, fileName: string, file: any) => void) => void;
    getK1InstructionFileName: (guid: string, taxYear: number, documentId: number, engagementType: EngagementType) => void;
    deleteK1Instruction: (guid: string, taxYear: number) => void;
    uploadK1Instruction: (engagementType: EngagementType, documentGuid: string, taxYear: number, fileName: string, documentId: number, callback?: () => void) => void;
    userSettings: UserSettingStore.UserSettings;
    requestDelegatedSigners: (userId: number) => void;
    generateTaxPayerView: (taxDocument: ITaxReturn) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    getAllSavedMessages: (reload: boolean) => void;
    saveProcessReturnInfo: (taxReturn: ITaxReturn, subDocuments: ISubDocument[], isK1Replaced: boolean, isK1Restored: boolean, isMFJChanged: boolean, apiEndPoint?: string, callback?: (data?: any) => void,
        taxCaddyLookupResult?: TaxCaddyLookupResult) => void;
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    getMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher) => void) => void;
    downloadUserSignature: (userId: number) => void;
    userSignatures: UserSignatureStore.IUserSignatures;
    getTaxCaddyLookupDetails: (taxpayerName: string, email: string, ssn: string, taxYear: number, taxClientId: string, docId: number) => void;
    voucherStore: VoucherStore.IVoucherState;
    requestVoucherDueDates: (taxReturn: ITaxReturn, authorityId?: number,
        voucherNo?: VoucherNo) => void;
    requestPaymentUrls: (taxReturn: ITaxReturn) => void;
    requestVoucherFormNames: (taxReturn: ITaxReturn) => void;
    isK1Restored: boolean;
    restoreK1Instruction: (fileName: string, documentId: number) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback?: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    getAllTaxingAuthorities: ITaxingAuthority[];
    submitRecallTaxReturn: (docId: number, uploadData: TaxReturnUploadData[], onResponse: (response: any, error: any) => void) => void;
    changeStatusTaxDocument: (taxDocument: ITaxReturn) => void;
    businessInstructionStore: IBusinessReturnsState;
    resetTaxDocument: (id: number) => void;
    updateDocumentInitialStatus: (taxReturn: ITaxReturn, resourceId: string, callback?: () => void, failureCallback?: () => void) => void;
    previewAttachments: (taxReturn: ITaxReturn, fileName: string, documentId: number, attachmentId: number, isArchivedReturn: boolean, callback: (url: string, fileName: string) => void) => void;
    requestDocumentStatus: (id: number, callback: () => void) => void;
    reprocessTaxDocument: (taxDocument: ITaxReturn, callback: (id: number) => void) => void;
    checkDuplicateTaxReturn: (id: number, engagementType: EngagementType, taxyear: number, callback: (result: boolean) => void) => void;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback: () => void, callback2?: () => void) => void;
    requestCustomAuthority: (value: boolean) => void;
    requestTaxingAuthority: (value: boolean) => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;

    reportFilters: FilterStore.IFilterState;
    getAllReportFilter: (reload: boolean, filterType: ReportFilterType) => void;
    //addReportFilter: (name: string, filter: IFilters, callback?: () => void) => void;
    addReportFilter: (name: string, filter: IFilters, callback?: any) => void;
    updateReportFilter: (name: string, filter: IFilters, callback?: any) => void;
    deleteReportFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    addDefaultFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    removeDefaultFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    deleteUploadedAdditionalDocumentBlobFile: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void) => void;
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    requestAdditionalDocumentType: (reload: boolean) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    //watermarkList: IWatermarkModel[],
    customColumnData: ICustomColumn,
    extensionCompanySettings: ExtensionCompanySettings;

    groupInfo: GroupInfo[];
    groupAccess: GroupAccess[];
    defaultGroup: string;
    addGroup: (groupInfo: GroupInfo, callback?: (groupId: number) => void, failurecallback?: () => void, disableSuccessNotification?: boolean) => void;
    mapExtensionstoGroup: (ids: number[], groupId: number, callback?: () => void, failurecallback?: () => void, includeGroupSavedNotificationMessage?: boolean) => void;
    updateGroupInfoState: (reload: boolean) => void;
    loadSpinner?: boolean
    extensionUsers: IUserModel[];
    taxSoftwareData: SuiteTaxSoftwareSettingsStoreState
    requestSuiteTaxSoftwareSettings: () => void;
    userLocation: IOfficeLocation[];
    officeLocation:IDropdown[];
}

export interface IPopupState {
    show: boolean;
}
export interface IPopupStateSingle extends IPopupState {
    model: ITaxReturn | undefined;
    saveCheckId: number | undefined;
}
export interface IPopupStateMultiple extends IPopupState {
    selected: ITaxReturn[] | undefined;
    saveCheckIds: number[] | undefined;
}

export interface IAssignmentsPageState {
    page: number;
    pageSize: number;
    searchString: string;
    sortName: string;
    sortOrder: string;
    filterName: string;
    filterClientID: string;
    filterPartner: string;
    filterLocation: number[];
    filterAssignTo: string;
    filterStatus: number[];
    filterEngagement: number;
    filterUploadedOn?: Date;
    description: string;
    taxPayerName: string;
    taxYear: number;

    //General States
    selectedTaxYear: number;
    defaultAssignUser: number;
    //Grid States
    selectedRows: number[];
    //Popups
    assignReturnsState: IPopupStateMultiple;
    setAccessState: IPopupStateMultiple;
    deleteReturnsState: IPopupStateMultiple;
    changeStatusState: IPopupStateSingle;
    showLoader: boolean;
    editClientInfoState: IPopupStateSingle;
    reportProblemState: IPopupStateSingle;
    recallReturnState: IPopupStateSingle;
    clientTrackingState: IPopupStateSingle;
    //Upload
    showUpload: boolean;
    selectedTaxSoftware: TaxSoftwareType;
    processReturnWindows: IProcessReturnProps[];
    processReturnModalLoader: boolean;
    reProcessReturnModalLoader: boolean;
    groupConfigurationState: IPopupStateMultiple;

    filter: IFilters;
    saveFilterShow: boolean;

    refreshDelay?: boolean;
    isLoading: boolean;

    mergeGroupAddedAndMapExtensionToGroupMessage: boolean;

    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}
export interface INameValue {
    [index: string]: string;
}
export const successMsg: INameValue = {
    editClientInfoState: 'Successfully updated client info for the tax-return extension',
    changeStatusState: 'Status has been successfully changed for the Tax Return Extensions',
    assignReturnsState: 'Successfully assigned the document(s) to selected user',
    setAccessState: 'Document Access set successfully for all extensions',
    deleteReturnsState: 'Successfully deleted the selected extension(s)!',
}

export const errorMsg: INameValue = {
    editClientInfoState: 'ClientInfo Edit failed! Error returned from server while updating the client info!',
    changeStatusState: 'Failed to update the changed status!',
    assignReturnsState: 'Failed to assign the document(s) to user!',
    setAccessState: 'Setting document access for the users failed!',
    deleteReturnsState: 'Failed to delete the selected document(s)!',
}

const pageSize: number = 20;
const NO_INDEX = -1;
let moment = require('moment');
let interval: any;
const EXTENSION_IN_PROCESS_PAGE_NAME = 'Extension In Process Page';
const pageTitle: string = "SendExtension";

export class Assignments extends React.Component<IAssignmentsProps, IAssignmentsPageState> {
    private taxYearList: number[] = [];
    private customMultiSelect: any;
    private proxyFilter: any;
    private reportFilterType: any;
    private customOfficeSelect: any;

    constructor(props: IAssignmentsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            searchString: "",
            sortName: "uploadedOn",
            sortOrder: "desc",
            filterName: '',
            filterClientID: '',
            filterPartner: '',
            filterLocation: [],
            filterAssignTo: '',
            filterStatus: [],
            filterEngagement: 0,
            description: "",
            taxPayerName: "",
            taxYear: 0,

            selectedTaxYear: new Date().getFullYear() - 1,
            defaultAssignUser: 0,
            selectedRows: [],
            filter:clearedFilter,
            saveFilterShow: false,
            //Assign States
            assignReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //SetAccess States
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //Delete Action states
            deleteReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //ChangeStatus states
            changeStatusState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            showLoader: false,
            //EditClientInfo states
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Report a Problem states
            reportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //client Tracking states
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Recall Return
            recallReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Upload
            showUpload: false,
            selectedTaxSoftware: TaxSoftwareType.None,
            processReturnWindows: [],
            processReturnModalLoader: false,
            reProcessReturnModalLoader: false,
            isLoading: false,
            //GroupExtension States
            groupConfigurationState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            mergeGroupAddedAndMapExtensionToGroupMessage: false,
            
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };

        this.onPageReload = this.onPageReload.bind(this);
        this.onAssignOpen = this.onAssignOpen.bind(this);
        this.onAssignSave = this.onAssignSave.bind(this);
        this.onAssignCancel = this.onAssignCancel.bind(this);

        this.checkIfReturnIsNotAssignToLoggedinUser = this.checkIfReturnIsNotAssignToLoggedinUser.bind(this);
        this.checkIfReturnIsInUse = this.checkIfReturnIsInUse.bind(this);
        this.onDeleteTaxReturnOpen = this.onDeleteTaxReturnOpen.bind(this);
        this.onDeleteTaxReturnSave = this.onDeleteTaxReturnSave.bind(this);

        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);

        this.onEditClientInfoOpen = this.onEditClientInfoOpen.bind(this);
        this.onEditClientInfoCancel = this.onEditClientInfoCancel.bind(this);
        this.onEditClientInfoSave = this.onEditClientInfoSave.bind(this);

        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.onUploadReturnsCancel = this.onUploadReturnsCancel.bind(this);

        this.onChangeStatusOpen = this.onChangeStatusOpen.bind(this);
        this.onChangeStatusCancel = this.onChangeStatusCancel.bind(this);
        this.onChangeStatusSave = this.onChangeStatusSave.bind(this);

        this.onRecallReturnOpen = this.onRecallReturnOpen.bind(this);
        this.onRecallReturnCancel = this.onRecallReturnCancel.bind(this);
        this.onRecallUploadConfirmation = this.onRecallUploadConfirmation.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        //Grid Actions
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.clearFiler = this.clearFiler.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        //Support Methods
        this.buildQueryString = this.buildQueryString.bind(this);
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        this.getUserModel = this.getUserModel.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.onDeleteCompletion = this.onDeleteCompletion.bind(this);


        //Filter Methods
        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        //this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);

    }

    componentWillMount() {
        this.initData();
        // This method runs when the component is first added to the page
        this.props.requestUsersList(true);
        this.props.requestUserProfile();
        this.fetchAssignments();
        this.props.requestCompanySettings(true);
        this.props.requestTaxingAuthorities();
        this.props.getAllSavedMessages(true);
        this.taxYearList = Helper.PrepareTaxYear().map(x => x.value);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.requestSuiteTaxSoftwareSettings();
    }

    componentWillReceiveProps(nextProps: IAssignmentsProps) {
        this.initData();
        this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps.taxDocuments);
    }
    initData = () => {
        this.proxyFilter = ReportFilterType[ReportFilterType.SendExtension].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
        this.reportFilterType = ReportFilterType.SendExtension;
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"assignment-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }


    private createActionCell = (cell: any, row: any) => {

        return (<AssignmentAction key={row.rowIndex}
            title={cell}
            taxReturn={this.props.currentPage.assignments[row.rowIndex]}
            rowIndex={row.rowIndex}
            onProcessReturnOpen={this.handleProcessReturnOpen}
            onEditClientInfoOpen={this.onEditClientInfoOpen}
            onSetAccessOpen={this.onSetAccessOpen}
            onChangeStatusOpen={this.onChangeStatusOpen}
            onReportProblemOpen={this.onReportProblemOpen}
            onDeleteTaxReturnOpen={(rowIndex: number) => {
                this.onDeleteTaxReturnOpen(rowIndex, RBACKeys.extensionInProgress.deleteReturnButton)
            }}
            onRecallReturnOpen={this.onRecallReturnOpen}
            onClientTrackingOpen={this.onClientTrackingOpen}
            onReprocessReturnOpen={this.onReprocessReturnOpen} />);
    }
    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options: any = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }


    //Filter Methods
    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter },
            () => this.fetchAssignmentsData(1, pageSize))
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {        
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.reportFilters.filters.find(x => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                    logClientEvent(
                        LogEventConstants.Common.Filter.SaveFilter,
                        { count: 1, page: pageTitle }
                    );
                });

            }
        }
        else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }
    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            logClientEvent(
                LogEventConstants.Common.Filter.UpdateFilter,
                { count: 1, page: pageTitle, filterName: name, filtetData: filter }
            );

            this.onSaveFilterHide();
        }
    }
    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
        filterType && logClientEvent(
            LogEventConstants.Common.Filter.DeleteFilter, { count: 1, page: filterType });
    }
    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => { this.onSetDefaultFilterSuccess(); });
        filterType && logClientEvent(
            LogEventConstants.Common.Filter.SetDefaultFilter, { count: 1, page: filterType });
    }
    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => { this.onRemoveDefaultFilterSuccess(); });
        let AssignmentTable: any = this.refs.AssignmentTable;
        AssignmentTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        filterType && logClientEvent(
            LogEventConstants.Common.Filter.RemoveDefaultFilter, { count: 1, page: filterType });
        this.onPageReload;
    }
    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }
    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }
    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }
    private onFilterNameChange(event: any) {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }

    private getDefaultFilter = (filters: IFilters[]): string | undefined => {

        let existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        }
        else if (existsDefaultFilter) {
            let dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    }
    loadDeliveredReturns = () => {
        let queryString = this.buildQueryString(this.state.page, this.state.pageSize);
        this.resetSelection(() => this.props.requestAssignments(queryString, false));
    }
    onExportToExcel = (callback?: any) => {
        this.props.onExportToExcel(callback, this.buildBulkOperationQuery());

        logClientEvent(
            LogEventConstants.Common.ExportExcle,
            { count: 1, page: pageTitle }

        );
    }

    
    //=======================================================================================

    //Group Configuration
    onGroupConfigurationOpen = (rowIndex: number = NO_INDEX) => {
        logger.trackPageView("Group Configuration Popup");
        const ids = this.onPopupOpen(rowIndex);
        this.handleGroupConfigurationOpenRequest(ids);

    }

    private handleGroupConfigurationOpenRequest = (ids: number[]) => {
        if (!ids || !ids.length) {
            VenusNotifier.Warning(GroupExtensionConstants.SelectDocumentWarning, null);
            return;
        }
        this.lazyLoadSelected(ids);
        const selectedAssignments = ids.map((id, i) => {
            return this.props.taxDocuments[id].taxReturn;
        });
        this.setState({
            groupConfigurationState: {
                show: true,
                selected: selectedAssignments,
                saveCheckIds: undefined
            }
        });
    }

    private onGroupCreateNew = (groupInfo: GroupInfo) => {
        this.setState({ mergeGroupAddedAndMapExtensionToGroupMessage: true });
        this.props.addGroup(groupInfo, this.mapExtensiontoGroup, this.onGroupConfigurationFailure, true);
    }
    private onAddtoExistingGroup = (groupid: number) => {
        this.mapExtensiontoGroup(groupid);
    }
    private mapExtensiontoGroup = (groupId: number) => {
        const ids = this.selectedDocumentIds();
        this.props.mapExtensionstoGroup(ids, groupId, this.onGroupConfigurationSaveCompletion, this.onGroupConfigurationFailure, this.state.mergeGroupAddedAndMapExtensionToGroupMessage);
        this.setState({ mergeGroupAddedAndMapExtensionToGroupMessage: false });
    }
    private onGroupConfigurationSaveCompletion = () => {
        const ids = this.selectedDocumentIds();
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: []
        }, this.onPageReload);
    }
    private onGroupConfigurationFailure = () => {
        const ids = this.selectedDocumentIds();
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: []
        });
    }


    private onGroupConfigurationCancel = () => {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    }
    //End of Group Configuration

    //=====================================================================================
    public render() {
        if (!this.props.currentPage || !this.props.currentPage.assignments) {
            return <div>
                <h3>Loading...</h3>
            </div>
        }

        let selected: number[] = [];
        if (this.state.selectedRows.length > 0) {
            for (var i in this.state.selectedRows) {
                let row = this.state.selectedRows[i];
                if (this.props.currentPage.assignments[row] !== undefined) {
                    selected.push(this.props.currentPage.assignments[row].id);
                }
            }
        }
        let unselectable: number[] = [];
        logger && logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        const data: ColumnValues[] = this.props.currentPage.assignments.map((model, index) => {
            if (Helper.isLocked(model)) {
                unselectable.push(model.id);
            }

            if((model.partnerId ?? 0) == 0)
            {
                model.partner =  {firstName: "", email: "", lastName :"", userId : null}
            }
            let locationName = model.location?.locationName;
            if (model.locationId && !locationName) {
                const index = this.props.userLocation.findIndex(x => x.locationId == model.locationId);
                if (index != NO_INDEX) {
                    locationName = this.props.userLocation[index].locationName;
                }
            }

            return {
                taxDocumentName: getClientName(model),
                clientId: model.clientId,
                partner: model.partner.firstName + ' ' + model.partner.lastName,
                assignToUserName: model.assignedUser.userId == 0 ? '' : model.assignedUser.firstName + ' ' + model.assignedUser.lastName,
                documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
                locationName: locationName ? locationName : "",
                uploadedOn: moment(model.uploadedOn).format('MM/DD/YYYY'),
                engagementType: engagementType(model.engagementType) ,
                taxYear: model.taxYear,
                assignTo: model.assignTo,
                button: 'Actions',
                assignments: this.props.currentPage.assignments,
                index: model.id,
                rowIndex: index
            };
        });

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '130px',
                filter: {
                    type: 'CustomFilter', 
                    getElement: this.getOfficeSelectDropDown, 
                    customFilterParameters: { 
                        options: [...this.props.userLocation.map(location=>{return {"label":location.locationName,"value":location.locationId}}),{"label":"Blanks","value":-100}], 
                        enableAllFilter: false, 
                        placeholder: 'Select Office Location...' 
                    }
                }
            },
            {
                header: 'Assigned To',
                key: 'assignToUserName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: this.props.id == "company" ? false : true,
                filter: { type: 'TextFilter', placeholder: 'Assigned To', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Status',
                key: 'documentStatus',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', 
                    getElement: this.getMultiSelectDropDown, 
                    customFilterParameters: { 
                        options: DocumentStatusType, 
                        enableAllFilter: false, 
                        placeholder: 'Select Status...' 
                    }
                }
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Uploaded Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Type...', options: EngagementList } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                filter:{ type: 'SelectFilter', placeholder: 'Select Tax Year...', options: this.taxYearList }
            },
            {
                header: 'Action',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.createActionCell,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "130px",
                filter: { type: 'TextFilter',placeholder: '', style: { display: 'none' } } as TextFilter
            }
        ];
        const pagination = true;
        const selectRowProp = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            unselectable: unselectable,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
        let selectedDocuments = this.selectedDocuments();

        return (<div data-testid="assignments">
            <AssignmentsToolbar
                key={this.props.id + '-toolbar'}
                pageTitle={this.props.title}
                taxYear={this.state.selectedTaxYear}
                selectedRows={this.state.selectedRows}
                onAssignOpen={this.onAssignOpen}
                onDeleteReturnsOpen={(rowIndex: number) => {
                    this.onDeleteTaxReturnOpen(rowIndex, RBACKeys.extensionInProgress.deleteButton);
                }}
                onSetAccessOpen={this.onSetAccessOpen}
                onUploadReturnsOpen={this.onUploadReturnsOpen}
                selectedDocuments={selectedDocuments}
                companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                userDefaultSettings={this.props.userSettings.settings}
                onGroupConfigurationOpen={this.onGroupConfigurationOpen}
                taxSoftwareData={this.props.taxSoftwareData.taxSoftwareSettings}
            />
            <br />
            <LoadingOverlay style={{ height: '100%' }}>
                <AssignmentTable
                    ref='AssignmentTable'
                    key={this.props.id + '-table'}
                    data={data}
                    column={columns}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    pageNo={this.state.page}
                    totalRows={this.props.currentPage.totalRowCount}
                    onFilterChange={this.onFilterChange}
                    tableAutomationId={this.props.automationId}
                    pageSize={this.state.pageSize}
                    isLoading={this.props.currentPage.isLoading}
                    models={this.props.currentPage.assignments}
                    onProcessReturnOpen={this.handleProcessReturnOpen}
                    onEditClientInfoOpen={this.onEditClientInfoOpen}
                    onSetAccessOpen={this.onSetAccessOpen}
                    onChangeStatusOpen={this.onChangeStatusOpen}
                    onReportProblemOpen={this.onReportProblemOpen}
                    onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                    onRecallReturnOpen={this.onRecallReturnOpen}
                    onClientTrackingOpen={this.onClientTrackingOpen}
                    onRowDoubleClick={this.handleRowDoubleClick}

                    onFilterNameChange={this.onFilterNameChange}
                    onFilterSave={this.onFilterSave}
                    onFilterUpdate={this.onFilterUpdate}
                    onFilterDelete={this.onFilterDelete}
                    filterList={this.props.reportFilters.filters}
                    currentFilter={this.state.filter}
                    onSetDefaultFilter={this.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                    saveFilterShow={this.state.saveFilterShow}
                    onSaveFilterHide={this.onSaveFilterHide}
                    onSaveFilterShow={this.onSaveFilterShow}
                    defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                    proxyFilter={this.proxyFilter}
                    filterType={this.reportFilterType}
                    onExportToExcel={this.onExportToExcel}
                    loadGrid={this.loadDeliveredReturns}
                    customMultiSelect={this.customMultiSelect}
                    id={this.props.id}
                    onPageReload={this.onPageReload}
                    refreshDelay={this.state.refreshDelay}
                    loadSpinner={this.props.loadSpinner}
                    customOfficeSelect={this.customOfficeSelect}
                />
                <Loader loading={this.state.processReturnModalLoader} text={ProcessReturnConstants.ProcessReturnOpenMessage} />
                <Loader loading={this.state.reProcessReturnModalLoader} text={ReProcessReturnConstants.ReProcessInitiatedModalLoader} />
            </LoadingOverlay>
            <ClientInfo
                show={this.state.editClientInfoState.show}
                model={this.state.editClientInfoState.model as ITaxReturn}
                onCancel={this.onEditClientInfoCancel}
                onSave={this.onEditClientInfoSave}
                updateTaxDocument={this.props.updateTaxDocument}
                isDeliveredReturn={false}
                states={this.props.company.companyProfile.countryStates}
            />
            <ReportProblemModal
                show={this.state.reportProblemState.show}
                onCancel={this.onReportProblemCancel}
                onReportProblemSave={this.onReportProblemSave}
                model={this.state.reportProblemState.model as ITaxReturn}
                loggedInCPA={this.props.profile}
                companyName={this.props.company.companyProfile.companyInfo.companyName}
                reportProblemType={ReportProblemTypes.ReturnSpecific}
                problemStep={ReportedStep.Assignments}
            />
            <AssignModal
                show={this.state.assignReturnsState.show}
                count={this.state.selectedRows.length}
                users={this.props.extensionUsers}
                defaultAssignUser={this.state.defaultAssignUser}
                onCancel={this.onAssignCancel}
                onAssign={this.onAssignSave}
            />
            {this.state.setAccessState.show &&
                    (
                        <SetAccessCommonModal
                            onCancel={this.onSetAccessCancel}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                            isDragAndDropDisabled={false}
                            hide={this.onSetAccessCancel}
                        />
                    
                    )}
            <AssignmentChangeStatusModal
                model={this.state.changeStatusState.model as ITaxReturn}
                show={this.state.changeStatusState.show}
                users={this.props.users}
                selectedUser={this.state.defaultAssignUser}
                onCancel={this.onChangeStatusCancel}
                onChangeStatusSave={this.onChangeStatusSave}
                showLoader={this.state.showLoader}
            />
            <UploadDocumentModal
                onShow={this.state.showUpload}
                onHide={this.onUploadReturnsCancel}
                extensionUsers={this.props.extensionUsers}
                partners={this.props.partners}
                taxSoftware={this.state.selectedTaxSoftware}
                selectedERO={this.props.defaultERO}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.onPageReload}
                defaultGroup={this.props.defaultGroup}
                groupInfo={this.props.groupInfo}
                groupAccess={this.props.groupAccess}
                updateGroupInfoState={this.props.updateGroupInfoState}
                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void ) => {
                    this.props.getTaxDocumentsAccessMaps(ids,RBACKeys.extensionInProgress.uploadButton, callback)
                }}
            />
            {this.state.recallReturnState.model && <UploadRecalledDocumentModal
                taxSoftwareType={this.state.recallReturnState.model.taxSoftware}
                documentKey={this.state.recallReturnState.model ? this.state.recallReturnState.model.documentGuid : ""}
                onShow={this.state.recallReturnState.show}
                onHide={this.onRecallReturnCancel}
                users={this.props.users}
                partners={this.props.partners}
                selectedERO={this.props.defaultERO}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                uploadConfirmation={this.onRecallUploadConfirmation}
                isRecalled={true}
                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void ) => {
                    this.props.getTaxDocumentsAccessMaps(ids,RBACKeys.extensionInProgress.uploadButton, callback)
                }}
            />}
            <ClientTrackingModal
                show={this.state.clientTrackingState.show}
                onCancel={this.onClientTrackingCancel}
                taxReturn={this.state.clientTrackingState.model as ITaxReturn}
            />
            <GroupConfigurationModal
                showGroupConfiguration={this.state.groupConfigurationState.show}
                onCancel={this.onGroupConfigurationCancel}
                selectedAssignments={this.state.groupConfigurationState.selected as ITaxReturn[]}
                count={this.state.groupConfigurationState.selected ? this.state.groupConfigurationState.selected.length : 1}
                groupInfo={this.props.groupInfo}
                onCreateNew={this.onGroupCreateNew}
                onAddtoExisting={this.onAddtoExistingGroup}
                hideUnGroupReturn={true}
            />
            <div>
                {this.state.processReturnWindows.map((item: IProcessReturnProps, index: number) => (

                    <ProcessReturnModal {...item} key={"process-return-model" + index}
                        taxDocuments={this.props.taxDocuments}
                        pdfDocuments={this.props.pdfDocuments}
                        users={this.props.users}
                        companySettings={this.props.company.companySettings as ICompanySettings}
                        authorities={this.props.company.taxingAuthorities}
                        authorityLookup={this.props.company.authorityLookup}
                        userBasicProfile={this.props.profile}
                        mailingReturnAddressList={this.props.mailingReturnAddressList}
                        getK1InstructionFileName={this.props.getK1InstructionFileName}
                        deleteK1Instruction={this.props.deleteK1Instruction}
                        uploadK1Instruction={this.props.uploadK1Instruction}
                        userSettings={this.props.userSettings}
                        // requestDelegatedSigners={this.props.requestDelegatedSigners}
                        company={this.props.company}
                        generateTaxPayerView={this.props.generateTaxPayerView}
                        savedMessages={this.props.savedMessages}
                        getAllSavedMessages={this.props.getAllSavedMessages}
                        saveProcessReturnInfo={this.props.saveProcessReturnInfo}
                        userSignatures={this.props.userSignatures}
                        getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                        voucherStore={this.props.voucherStore}
                        isK1Restored={this.props.isK1Restored}
                        requestTaxDocument={this.props.requestTaxDocument}
                        restoreK1Instruction={this.props.restoreK1Instruction}
                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                        businessInstructionStore={this.props.businessInstructionStore}
                        updateDocumentInitialStatus={this.updateDocumentInitialStatus}
                        previewAttachments={this.props.previewAttachments}
                        onReportProblemOpen={this.onReportProblemOpen}
                        saveTaxingAuthority={this.props.saveTaxingAuthority}
                        requestCustomAuthority={this.props.requestCustomAuthority}
                        requestTaxingAuthorities={this.props.requestTaxingAuthority}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        requestEroSignaturePath={this.props.downloadUserSignature}
                        getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                        deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
                        deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                        getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}
                        convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                        requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                        additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                        requestCompanyLogo={this.props.requestCompanyLogo}
                        //watermarkList={this.props.watermarkList}
                        customColumnData={this.props.customColumnData}
                        isLoading={this.state.isLoading}
                        extensionCompanySettings={this.props.extensionCompanySettings}
                        partners={this.props.partners}
                        extensionUsers={this.props.extensionUsers}
                        userRoles={this.props.auth.user.profile.role}
                        auth = {this.props.auth}
                        loggedInUserLocations={this.props.userLocation.map(x=>x.locationId)}
                        officeLocation={this.props.officeLocation}
                        requestAssignments={this.props.requestAssignments}    
                        queryString={this.buildQueryString(this.state.page, this.state.pageSize)}
                        loadGrid={this.onPageReload}
                        defaultEro={this.props.defaultERO}
                        />
                ))}
            </div>
        </div>)
    }
    //=======================================================================================

    private resetSelection = (callback: () => void) => {
        this.setState({
            selectedRows: []
        }, callback)
    }

    private fetchAssignments() {
        let query = this.buildQueryString(this.state.page, pageSize);
        this.resetSelection(() => this.props.requestAssignments(query, false));
    }
    private fetchAssignmentsData(page: number, sizePerPage: number) {
        let query = this.buildQueryString(page, sizePerPage);
        this.resetSelection(() => this.props.requestAssignments(query, false));
    }

    private onPageReload() {
        this.setState(
            {
                refreshDelay: true,
                selectedRows: []
            }, () => {
                const query = this.buildQueryString(this.state.page, pageSize);
                this.props.requestAssignments(query, true, () => {
                    this.setState({ refreshDelay: false });
                }, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
                logClientEvent(
                    LogEventConstants.Common.RefreshExtensions,
                    { count: 1, page: pageTitle }

                );
            }
        );        
    }

    reloadOnNoDataFound = () => {
        this.setState(prevState => ({ page: prevState.page - 1 }), () => {
            this.onPageReload();
        });
    }

    private buildBulkOperationQuery = () => {
        let query: BulkOperationQueryExt = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.searchString,
            clientId: encodeURIComponent(this.state.filterClientID),
            customColumn: "",
            downloadedBy: "",
            name: encodeURIComponent(this.state.filterName),
            partner: encodeURIComponent(this.state.filterPartner),
            engagementType: this.state.filterEngagement,
            sentBy: "",
            status: this.state.filterStatus.length > 0 ? this.state.filterStatus.toString() : "",
            officeLocation: this.state.filterLocation.length > 0 ? this.state.filterLocation.toString() : "",
            isArchived: false,
            taxYear: this.state.taxYear !== 0 ? this.state.taxYear.toString() : "",
            unSelectedIds: [],
            assignedTo: encodeURIComponent(this.state.filterAssignTo),
            reportType: ReportFilterType.SendExtension,
            uploadedOn: (this.state.filterUploadedOn !== undefined && new Date(this.state.filterUploadedOn).toString() !== 'Invalid Date') ? this.state.filterUploadedOn : undefined,
            documentFilingType: 0,
            quickReportStatus: '',
            groupName: '',
            taxSoftware: '',
            createdBy: '',
            globalSearch: '',
            setAccess: "None",
            returnStatus: "",
            isDownloadNow: false
        }
        return query;
    }

    private buildQueryString(page: number, pageSize: number) {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + this.state.sortName
            + '&sortOrder=' + this.state.sortOrder
            + '&taxYear=' + this.state.taxYear
            + '&filterName=' + this.state.filterName
            + '&filterClientID=' + this.state.filterClientID
            + '&filterPartner=' + this.state.filterPartner
            + '&filterLocation=' + this.state.filterLocation
            + '&filterAssignTo=' + this.state.filterAssignTo
            + '&filterStatus=' + this.state.filterStatus
            + '&filterEngagement=' + this.state.filterEngagement
            + '&filterUploadedOn=' + this.state.filterUploadedOn;
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }


    private onPageChange(page: number, sizePerPage: number) {
        this.setState({
            page: page,
            selectedRows: []
        }, this.fetchAssignments);
    }

    private onSortChange(sortName: string, sortOrder: string) {
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchAssignmentsData(1, pageSize));

    }

    private onSearchChange(searchString: string) {
        let temp: IFilters = { ...this.state.filter };
        temp.searchText = searchString;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchAssignmentsData(1, pageSize));
    }

    private fetchFilterValue(data: any) {

    }
    private onFilterChange(dataField: any, filterType: ReportFilterType) {


        let newState: IAssignmentsPageState = {
            filterName: "",
            filterClientID: "",
            filterPartner: "",
            filterLocation: [] as any,
            filterStatus: [] as any,
            filterEngagement: 0,
            filterAssignTo: "",
            taxYear: 0,
            filterUploadedOn: moment(null).format('MM/DD/YYYY')
        } as IAssignmentsPageState;
        let filterStatus: any;
        let filterLocation: any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string; } = {};

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data == "-1" ? "" : data;

            switch (field) {

                case 'taxDocumentName':
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'clientId':
                    newState.filterClientID = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'partner':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'locationName':
                    filterLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocation = (filterLocation == "-1") ? "" : filterLocation;
                    newState.filterLocation = filterLocation;
                    break;

                case 'assignToUserName':
                    newState.filterAssignTo = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'documentStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    newState.filterStatus = dataField[field].value ?
                        dataField[field].value == "-1" ?
                            "" : dataField[field].value
                        : dataField[field];
                    break;

                case 'uploadedOn':
                    let noOfDays = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    newState.filterUploadedOn = moment(noOfDays).format('MM/DD/YYYY');
                    break;

                case 'engagementType':
                    newState.filterEngagement = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'taxYear':
                    newState.taxYear = +this.taxYearList[dataField[field].value ? dataField[field].value : dataField[field]];

                    break;
            }
        }
        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }

        if (this.isFilterChanged(newState, filterStatus)) {
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize, selectedRows: [] },
                () => {
                    let assignmentTable: any = this.refs.AssignmentTable;
                    if (!assignmentTable.isAppliedFilter) {
                        this.props.requestAssignments(this.buildQueryString(this.state.page, this.state.pageSize), false);
                    }
                    logClientEvent(
                        LogEventConstants.Common.Filter.FilterChange,
                        { count: 1, page: pageTitle }

                    );
                })
        }
    }
    private isFilterChanged(newState: IAssignmentsPageState, status: any): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientID !== this.state.filterClientID ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterLocation !== this.state.filterLocation ||
            newState.filterAssignTo !== this.state.filterAssignTo ||
            status !== this.state.filterStatus ||
            newState.filterEngagement !== this.state.filterEngagement ||
            newState.filterUploadedOn != this.state.filterUploadedOn ||
            newState.taxYear !== this.state.taxYear)
    }

    private clearFiler() {
        this.setState({
            filterName: '',
            filterClientID: '',
            filterPartner: '',
            filterLocation: [],
            filterStatus: [],
            filterEngagement: 0,
            filterAssignTo: '',
            taxYear: 0,
            filterUploadedOn: new Date('')
        }, this.fetchAssignments);
        let assignmentTable: any = this.refs.AssignmentTable;
        assignmentTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });

    }

    private onRowSelect(row: ColumnValues, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onRowUnSelect = (index: number) => {
        let newList = [...this.state.selectedRows];
        var index = newList.indexOf(index);
        if (index > -1) {
            newList.splice(index, 1);
        }
        this.setState({ selectedRows: newList });
        this.forceUpdate();
    }

    private onSelectAll(isSelected: boolean, rows: ColumnValues[], e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            rows.forEach((value: ColumnValues, index: number) => {
                selectedRows.push(value.rowIndex);
            });
        }
        this.setState({ selectedRows: selectedRows });
    }
    //=======================================================================================

    onAssignOpen = (rowIndex: number = NO_INDEX) => {
        logger.trackPageView("Assign To Popup");
        let ids = this.onPopupOpen(rowIndex);
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessExtensionConstants.ExtensionInUse, null);
        }
        else {
            this.handleAssignOpenRequest(ids);
        }
    }

    private handleAssignOpenRequest(ids: number[]) {
        if (ids.length == 0) {
            VenusNotifier.Warning(AssignUserConstants.SelectDocumentWarning, null);
            return;
        } else if (this.selectedDocuments().find(x => x.documentStatus === DocumentStatus.RECALLED)
            && this.selectedDocuments().some(x => x.assignTo != this.props.profile.userId)
            && Helper.isAdmin(this.props.auth.user)) {
            VenusNotifier.Warning(AssignUserConstants.RecalledReturnWarning, null);
            return;
        }

        let defaultUser = this.props.assignedUser(this.selectedDocuments());

        this.lazyLoadSelected(ids);
        this.setState({
            assignReturnsState: {
                show: true,
                selected: this.selectedDocuments(),
                saveCheckIds: undefined
            },
            defaultAssignUser: defaultUser
        });
    }

    private onAssignCancel() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            assignReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    }

    private onAssignSave(userId: number) {
        let ids = this.selectedDocumentIds();
        let user = this.getUserModel(userId);
        ids.forEach((id, i) => {
            let taxDocument = this.props.taxDocuments[id].taxReturn;
            taxDocument.assignTo = userId;
            taxDocument.assignedUser = user;
            if (taxDocument.documentStatus !== DocumentStatus.PROCESSING && taxDocument.documentStatus !== DocumentStatus.RECALLED
                && taxDocument.documentStatus !== DocumentStatus.READY) {

                taxDocument.documentStatus = DocumentStatus.PROCESSING;
            }
            taxDocument.isModified = true;
            this.props.updateDocumentInitialStatus(taxDocument, RBACKeys.extensionInProgress.assignButton);
        });

        logClientEvent(
            LogEventConstants.Common.AssignMultiExtensionsToUser,
            { count: ids.length, page: pageTitle, selectedIds: ids }
        );

        this.setState({
            assignReturnsState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids
            }
        });
    }

    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOfficeSelect = ref)} 
            filterHandler={filterHandler} 
            options={options} 
            placeholder={placeholder}
             className={"filter select-filter multi-select-widthauto-office-location"} 
             optionClassName={"office-location-custom-multiselect-status"}/>
        );
    }


    updateDocumentInitialStatus = (taxReturn: ITaxReturn) => {

        this.setState({
            isLoading: true
        });
        this.props.updateDocumentInitialStatus(taxReturn, RBACKeys.extensionInProgress.processReturnButton, () => {
            this.props.requestTaxDocument(taxReturn.id, true, undefined, undefined, () => {
                this.props.updateTaxDocument(taxReturn);
                this.setState({
                    isLoading: false
                });
            });
        });
    }

    //=======================================================================================
    onSetAccessOpen = (rowIndex: number = NO_INDEX) => {
        logger.trackPageView("Set Access Popup");
        let ids = this.onPopupOpen(rowIndex);
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessExtensionConstants.ExtensionInUse, null);
        }
        else if (!Helper.isAdmin(this.props.auth.user) && this.props.company.companySettings.returnAccessSettings.isSetAccessToIndividual &&
            !this.props.company.companySettings.returnAccessSettings.isSetAccessToOtherUsers) {
            VenusNotifier.Warning(SetAccessConstants.AccessRetricted, null);
        }
        else {
            this.handleSetAccessOpenReuqest(ids);
        }
    }

    private handleSetAccessOpenReuqest(ids: number[]) {
        if (ids.length == 0) {
            VenusNotifier.Warning(SetAccessConstants.SelectDocumentWarning, null);
            return;
        }
        else if (this.selectedDocuments().find(x => x.documentStatus === DocumentStatus.RECALLED)
            && this.selectedDocuments().some(x => x.assignTo != this.props.profile.userId)
            && Helper.isAdmin(this.props.auth.user)) {
            VenusNotifier.Warning(AssignUserConstants.RecalledReturnWarning, null);
            return;
        }

        this.props.requestUsersList(false);
        this.lazyLoadSelected(ids);
        this.props.getTaxDocumentsAccessMaps(ids,RBACKeys.extensionInProgress.setAccessButton,(result: IDocumentAccess) => {
            this.setState({
                availableUserGroups: result.availableUserGroups,
                availableUsers: result.availableUsers,
                selectedUserGroups: result.selectedUserGroups,
                selectedUsers: result.selectedUsers
            });
            let selectedAssignments = ids.map((id, i) => {
                return this.props.taxDocuments[id].taxReturn;
            });
            this.setState({
                setAccessState: {
                    show: true,
                    selected: selectedAssignments,
                    saveCheckIds: undefined
                }
            });
        });
    }
    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let ids = this.selectedDocumentIds();
        let accessMaps: IDocumentAccessSaveModel = {
            documents: ids,
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers,
        };

        this.props.setTaxDocumentsAccessMaps(accessMaps,RBACKeys.extensionInProgress.setAccessButton,this.onPageReload);
        logger.trackTrace(`Set access saved successfully for documentIds: ${ids?.toString()} and userIds: ${accessingUsersAndUserGroups.selectedUsers?.toString()}`);
        this.setState({
            setAccessState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: [],
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
        });
    }

    private onSetAccessCancel() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    }
    //=======================================================================================

    onEditClientInfoOpen = (rowIndex: number) => {
        logger.trackPageView("Edit Client Info Popup");
        let item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item.taxReturn;
        let ids = this.onPopupOpen(rowIndex);
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessExtensionConstants.ExtensionInUse, null);
        }
        else {
            this.handleEditClientInfoOpenRequest(taxReturn);
        }
    }

    private handleEditClientInfoOpenRequest(taxReturn: ITaxReturn) {
        if (!Helper.isClientInfoLoaded(taxReturn)) {
            this.props.requestTaxDocument(taxReturn.id, true, false, undefined, undefined, true);
        }
        this.setState({
            editClientInfoState: {
                show: true,
                model: taxReturn ? taxReturn : undefined,
                saveCheckId: undefined
            }
        });
    }

    private onEditClientInfoCancel(id: number) {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        let item = this.state.editClientInfoState.model as ITaxReturn;
        this.props.requestTaxDocument(item.id, true, false, undefined, undefined, true);

        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onEditClientInfoSave(id: number, isMailSend: boolean, clientGUIDs: string[], isEmailUpdated: boolean, resetLoadingMessageState?: any) {

        let taxReturn: ITaxReturn = this.props.taxDocuments[id].taxReturn;
        if (Helper.validateClientBasicInfo(taxReturn,false)) {
            this.props.saveTaxDocument(taxReturn, undefined, () => {
                resetLoadingMessageState();
            });
            logClientEvent(
                LogEventConstants.Common.EditClientInfo,
                { count: 1, page: pageTitle, documentId: id }

            );
            this.setState({
                editClientInfoState: {
                    show: true,
                    model: taxReturn,
                    saveCheckId: id
                }
            });
        }
    }
    //=======================================================================================

    onChangeStatusOpen = (rowIndex: number = NO_INDEX) => {
        logger.trackPageView("Change Status Popup");
        let ids = this.onPopupOpen(rowIndex);
        let item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item.taxReturn;
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        }
        else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessExtensionConstants.ExtensionInUse, null);
        }
        else {
            this.handleChangeStatusOpenRequest(taxReturn);
        }
    }

    private handleChangeStatusOpenRequest(taxDocument: ITaxReturn) {
        if (taxDocument.documentStatus === DocumentStatus.RECALLED ||
            taxDocument.documentStatus == DocumentStatus.PREPARINGFORDELIVERY ||
            taxDocument.documentStatus == DocumentStatus.DELIVERYFAILED) {
            VenusNotifier.Error(ChangeStatusConstants.Error, ChangeStatusConstants.Title);
            return;
        }
        this.props.requestTaxDocument(taxDocument.id, true);
        let defaultUser = this.props.assignedUser([taxDocument]);
        this.setState({
            changeStatusState: {
                show: true,
                model: taxDocument,
                saveCheckId: undefined
            },
            defaultAssignUser: defaultUser,
            showLoader: false
        });
    }

    private onChangeStatusCancel() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            changeStatusState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    onChangeStatusSaveCallback = (docId: number, userId: number, status: DocumentStatus, result: boolean) => {
        if (result) {
            let taxDocument = this.props.taxDocuments[docId].taxReturn;
            taxDocument.documentStatus = status;
            taxDocument.isModified = true;

            taxDocument.assignTo = userId;
            taxDocument.assignedUser = this.getUserModel(userId);
            this.setState({
                changeStatusState: {
                    show: true,
                    model: undefined,
                    saveCheckId: docId
                },
                showLoader: true
            }, () => {
                this.props.changeStatusTaxDocument(taxDocument);
            });
        }
        else {
            this.setState({ showLoader: false })
        }
    }

    private onChangeStatusSave(docId: number, userId: number, status: DocumentStatus) {
        let _self = this;
        bootbox.confirm({
            message: ChangeStatusConstants.changeStatusConfirmation,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-success'
                }
            },
            callback: (result: boolean) => {
                _self.onChangeStatusSaveCallback(docId, userId, status, result);
            }
        });
    }
    //=======================================================================================

    private onUploadReturnsOpen(rowIndex: number, taxSoftware: TaxSoftwareType) {
        logClientEvent(
            `${DocumentEventLog.UploadPopup} ${DocumentEventLog.Open}: ${TaxSoftwareType[taxSoftware]}`, { CustomEventType: DocumentEventLog.Name, Count: 1 });
        this.setState({ showUpload: true, selectedTaxSoftware: taxSoftware });
    }

    private onUploadReturnsCancel() {
        this.setState({ showUpload: false });
    }
    //=======================================================================================
    private handleRowDoubleClick = (rowIndex: number) => {
        let item = this.getStoreItem(rowIndex).taxReturn;

        if (Helper.isLocked(item) || (item.documentStatus === DocumentStatus.RECALLED || item.documentStatus === DocumentStatus.NONSUPPORTED ||
            item.documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION || item.documentStatus === DocumentStatus.REPROCESSING)) {
            return;
        }

        this.handleProcessReturnOpen(rowIndex);
    }

    handleRequest = (taxReturn: ITaxReturn) => {
        if (taxReturn.documentStatus !== DocumentStatus.READY) {
            if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.profile.userId) {
                // locked, assigned to other user 
                this.setState({ processReturnModalLoader: false });
                VenusNotifier.Warning(ProcessExtensionConstants.ExtensionInUse, null);
                return;
            }
            else if (taxReturn.lockedBy <= 0 &&
                taxReturn.assignTo !== this.props.profile.userId) {
                taxReturn.assignTo = this.props.profile.userId != undefined ? this.props.profile.userId : 0;
                taxReturn.assignedUser.email = this.props.profile.emailAddress;
                taxReturn.assignedUser.firstName = this.props.profile.firstName;
                taxReturn.assignedUser.lastName = this.props.profile.lastName;
                taxReturn.assignedUser.userId = this.props.profile.userId || 0;
                this.processReturnModalSetUp(taxReturn);
            }
            else {
                this.processReturnModalSetUp(taxReturn);
            }
        }
        else {
            this.processReturnModalSetUp(taxReturn);
        }
    }

    processReturnModalSetUp = (taxReturn: ITaxReturn) => {
        this.props.requestPdfDocument(taxReturn, false);
        this.props.requestUserProfile();
        this.props.requestTaxingAuthorities();
        this.props.requestUsersList(false);
        this.props.requestCompanySettings(false);

        // download delgated signers
        if (this.props.profile.userId) {
            const userId = this.props.profile.userId as number;
            this.props.requestDelegatedSigners(userId);
        }

        // download the signature default ero signer
        if (taxReturn.partner.userId) {
            this.props.downloadUserSignature(taxReturn.partner.userId);
        }

        let processReturnWindows = this.state.processReturnWindows;
        let count = processReturnWindows.length;
        processReturnWindows.push({
            show: true,
            onCancel: this.handleProcessReturnCancel,
            docId: taxReturn.id,
            updateTaxDocument: this.props.updateTaxDocument,
            saveTaxDocument: this.props.saveTaxDocument,
            requestAttachments: this.props.requestAttachments,
            loadPdf: this.props.requestPdfDocument,
            order: count + 1,
            sendToERO: this.props.sendToERO,
            sendForReview: this.props.sendForReview,
            approveForDelivery: this.props.approveForDelivery,
            deliverToClient: this.props.deliverToClient,
            getVoucherUploadLink: this.props.getVoucherUploadLink,
            getMultiVoucherUploadLink: this.props.getMultiVoucherUploadLink,
            getTaxCaddyLookupDetails: this.props.getTaxCaddyLookupDetails,
            requestVoucherDueDates: this.props.requestVoucherDueDates,
            requestPaymentUrls: this.props.requestPaymentUrls,
            requestVoucherFormNames: this.props.requestVoucherFormNames

        } as IProcessReturnProps);
        this.setState({ processReturnModalLoader: false });
    }

    private handleProcessReturnOpen = (rowIndex: number) => {
        logger.trackPageView("Process Return Popup");
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item.taxReturn;
        this.props.checkDuplicateTaxReturn(taxReturn.id, taxReturn.engagementType, taxReturn.taxYear, (result) => {
            if (result && taxReturn.documentStatus == 'READY') {
                bootbox.confirm({
                    message: ProcessReturnConstants.DuplicateReturnMessage,
                    buttons: {
                        cancel: {
                            label: '<i class="fas fa-times"></i> Cancel',
                            className: 'btn-white btn-default'
                        },
                        confirm: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: 'btn-info'
                        }
                    },
                    callback: (result: boolean) => {
                        if (result == true) {
                            if (this.state.processReturnWindows.find(x => x.docId == taxReturn.id)) {
                                VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                                return;
                            }

                            if (!item.isFullyLoaded) {
                                this.setState({ processReturnModalLoader: true });
                                this.props.requestTaxDocument(taxReturn.id, true, undefined, undefined, this.handleRequest);
                                return;
                            }
                            this.handleRequest(taxReturn);
                        }
                        else {
                            taxReturn.documentStatus = DocumentStatus.DUPLICATE;
                            this.props.updateDocumentInitialStatus(taxReturn, RBACKeys.extensionInProgress.processReturnButton);
                        }
                        logClientEvent(
                            LogEventConstants.Common.ProcessReturn.OpenProcessExtension,
                            { count: 1, CustomEventType: DocumentEventLog.Name, Page: pageTitle, DocumentId: taxReturn.id }
                        );
                    }
                });
            }
            else {
                if (this.state.processReturnWindows.find(x => x.docId == taxReturn.id)) {
                    VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                    return;
                }

                if (!item.isFullyLoaded) {
                    this.setState({ processReturnModalLoader: true });
                    this.props.requestTaxDocument(taxReturn.id, true, undefined, undefined, this.handleRequest);
                    return;
                }
                this.handleRequest(taxReturn);
                logClientEvent(
                    LogEventConstants.Common.ProcessReturn.OpenProcessExtension,
                    { Count: 1, CustomEventType: DocumentEventLog.Name, Page: pageTitle, DocumentId: taxReturn.id }

                );
            }
        });

    }

    private handleProcessReturnCancel = (id: number) => {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        let newState = this.state.processReturnWindows.filter((item) => item.docId !== id);
        this.setState({ processReturnWindows: newState });
        this.props.resetTaxDocument(id);
        this.onPageReload();
        logClientEvent(
            LogEventConstants.Common.ProcessReturn.CloseProcessExtension,
            { Count: 1, CustomEventType: DocumentEventLog.Name, Page: pageTitle, DocumentId: id }

        );
    }

    //=======================================================================================

    private checkIfReturnIsStuckInUploadedOrNonSupported(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (this.props.taxDocuments[id].taxReturn.documentStatus === DocumentStatus.UPLOADED ||
                this.props.taxDocuments[id].taxReturn.documentStatus === DocumentStatus.NONSUPPORTED ||
                this.props.taxDocuments[id].taxReturn.documentStatus === DocumentStatus.ERROR) {
                listItem.push(1);
            }
            else {
                listItem.push(0);
            }
        })
        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }

    private checkIfReturnIsNotAssignToLoggedinUser(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (this.props.taxDocuments[id].taxReturn.documentStatus === DocumentStatus.RECALLED) {
                if (Helper.isAdmin(this.props.auth.user)) {
                    listItem.push(0);
                } else if (this.props.profile.userId == this.props.taxDocuments[id].taxReturn.assignTo) {
                    listItem.push(0);
                }
                else {
                    listItem.push(1);
                }
            }
            else if (handleIfReturnIsNotAssignToLoggedinUserRequest(this.props.taxDocuments[id].taxReturn, this.props.profile, this.props.auth)) {
                listItem.push(1);
            }
            else {
                listItem.push(0);
            }
        })
        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }

    private checkIfReturnIsInUse(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (handleIfReturnIsInUseRequest(this.props.taxDocuments[id].taxReturn, this.props.profile, this.props.auth.user)) {
                listItem.push(1);
            }
            else {
                listItem.push(0);
            }
        })
        if (listItem.filter(u => u === 1).length !== 0) {
            return result = true;
        }
        return result;
    }

    private onDeleteTaxReturnOpen(rowIndex: number = NO_INDEX, resourceId: string) {
        logger.trackPageView("Delete TaxReturn Popup");
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(DeleteTaxReturnConstants.SelectDocumentWarning, null);
            return;
        }

        if (!this.checkIfReturnIsStuckInUploadedOrNonSupported(ids)) {

            if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: ProcessReturnConstants.ReturnNotAssignedToLoggedInUser,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: 'btn-info'
                        }
                    }
                });
                return;
            }
            if (this.checkIfReturnIsInUse(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: DeleteTaxReturnConstants.InUseReturnWarning,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: 'btn-info'
                        }
                    }
                });
                return;
            }
        }
        let obj = this;
        bootbox.confirm({
            title: DeleteTaxReturnConstants.Title,
            message: DeleteTaxReturnConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onDeleteTaxReturnSave(ids, resourceId);
                    logClientEvent(
                        LogEventConstants.Common.Filter.DeleteFilter,
                        { count: ids.length, page: pageTitle }

                    );
                }
            }
        });
    }

    private onDeleteCompletion() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({ selectedRows: [] });
        this.onPageReload();
    }

    private onDeleteTaxReturnSave(ids: number[], resourceId: string) {
        this.props.deleteTaxDocument(ids, this.onDeleteCompletion, resourceId);
        logClientEvent(
            LogEventConstants.Common.DeleteMultiExtensions,
            { count: ids.length, page: pageTitle }

        );
        this.setState({
            deleteReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
        });
    }
    //=======================================================================================

    private onRecallReturnOpen(rowIndex: number) {
        logger.trackPageView("Recall Return Popup");
        this.setState({
            recallReturnState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined
            }
        });
    }


    private onReprocessReturnOpen = (rowIndex: number) => {
        logger.trackPageView("Reprocess Return Popup");
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item.taxReturn;
        this.onRowUnSelect(rowIndex);
        if (!item.isFullyLoaded) {
            this.setState({ reProcessReturnModalLoader: true });
            this.props.requestTaxDocument(taxReturn.id, true, undefined, undefined, (e) => {
                this.handleReprocessOpen(e.id)
            });
            return;
        }

        this.handleReprocessOpen(taxReturn.id);
    }

    private handleReprocessOpen = (id: number) => {
        this.setState({ reProcessReturnModalLoader: true }, () => {
            let taxDocument = this.props.taxDocuments[id].taxReturn;
            taxDocument.documentStatus = DocumentStatus.REPROCESSING;
            this.props.reprocessTaxDocument(taxDocument, () => {
                this.setState({ reProcessReturnModalLoader: false });
                if (this.state.filterStatus) {
                    let query = this.buildQueryString(this.state.page, this.state.pageSize);
                    this.resetSelection(() => this.props.requestAssignments(query, true));
                }
                interval = setTimeout(this.getDocumentStatus(id), 10000);
            });
        });
    }

    private getDocumentStatus(id: number): any {
        let _self = this;
        this.props.requestDocumentStatus(id, () => {
            if (_self.props.taxDocuments[id].taxReturn.documentStatus != DocumentStatus.REPROCESSING) {
                if (this.state.filterStatus) {
                    let query = this.buildQueryString(this.state.page, this.state.pageSize);
                    this.resetSelection(() => this.props.requestAssignments(query, true));
                }
                clearTimeout(interval);
            }
            else {
                interval = setTimeout(this.getDocumentStatus(id), 10000);
            }
        });
    }

    private onRecallReturnCancel() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            recallReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onRecallUploadConfirmation(documentKey: string, clientId: string) {
        const taxDoc = this.state.recallReturnState.model;
        if (taxDoc && taxDoc.documentGuid == documentKey) {
            taxDoc.documentStatus = DocumentStatus.UPLOADED;
            taxDoc.clientId = clientId;
            this.props.updateTaxDocument(taxDoc);
        }
    }

    //=======================================================================================

    private onClientTrackingOpen(rowIndex: number) {
        logger.trackPageView("Client Tracking Popup");
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            return;
        }

        this.props.requestTaxDocumentClientTracking(ids[0]);
        this.setState({
            clientTrackingState: {
                model: this.props.taxDocuments[ids[0]].taxReturn,
                show: true,
                saveCheckId: undefined
            }
        });
    }

    private onClientTrackingCancel(rowIndex: number) {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            }
        });
    }
    //=======================================================================================

    private onReportProblemOpen(id: number) {
        logger.trackPageView("Report Problem Popup");
        if (!Helper.isClientInfoLoaded(this.props.taxDocuments[id].taxReturn)) {
            this.props.requestTaxDocument(id, true, false, undefined, undefined, true);
        }

        this.setState({
            reportProblemState: {
                show: true,
                model: this.props.taxDocuments[id].taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemCancel() {
        logger.trackPageView(EXTENSION_IN_PROCESS_PAGE_NAME);
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    private onReportProblemSave(id: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        this.props.reportTaxDocumentProblem(problemDetails, callback);
        this.setState({
            reportProblemState: {
                show: true,
                model: undefined,
                saveCheckId: id,
            },
        });

    }
    //=======================================================================================

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.currentPage.assignments[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    private selectedDocuments() {
        let docs: ITaxReturn[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            docs.push(this.props.currentPage.assignments[row]);
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    public getUserModel(id: number) {
        for (let i in this.props.users) {
            if (this.props.users[i].id == id) {
                let user: IUserBaseModel = {
                    userId: this.props.users[i].id,
                    firstName: this.props.users[i].firstName,
                    lastName: this.props.users[i].lastName,
                    email: this.props.users[i].emailAddress
                }
                return user;
            }
        }
        this.props.requestUsersList(true);
        throw ("no user found with id:" + id);
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            ids.push(this.props.currentPage.assignments[row].id);
        }
        return ids;
    }

    private getStoreItem(index: number) {
        let id = this.props.currentPage.assignments[index].id;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, stateKey: string, state: IPopupStateSingle) {
        if (state.saveCheckId &&
            isLoaded(taxDocuments, [state.saveCheckId])) {
            if (stateKey != "reportProblemState") {
                if (taxDocuments[state.saveCheckId].error) {
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
                    this.props.requestTaxDocument(state.saveCheckId, true);
                } else {
                    VenusNotifier.Success(successMsg[stateKey], stateKey);

                }
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionMultiple(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, stateKey: string, state: IPopupStateMultiple) {
        if (state.saveCheckIds &&
            isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            let customErrMessage = null; //Handling Custom Error message from server side.
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    if (taxDocuments[id].message) {
                        customErrMessage = taxDocuments[id].message;
                    }
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                if (customErrMessage)
                    VenusNotifier.Error(customErrMessage, stateKey);
                else
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {

        this.onUpdateCompletion(taxDocuments, "editClientInfoState", this.state.editClientInfoState);
        this.onUpdateCompletion(taxDocuments, "changeStatusState", this.state.changeStatusState);
        this.onUpdateCompletion(taxDocuments, "reportProblemState", this.state.reportProblemState);

        this.onUpdateCompletionMultiple(taxDocuments, "assignReturnsState", this.state.assignReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, "deleteReturnsState", this.state.deleteReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, "setAccessState", this.state.setAccessState);
    }

    private updateStates(props: IAssignmentsProps) {
        if (this.state.editClientInfoState.model) {
            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    model: props.taxDocuments[this.state.editClientInfoState.model.id].taxReturn
                }
            });
        }
        if (this.state.changeStatusState.model) {
            this.setState({
                changeStatusState: {
                    ...this.state.changeStatusState,
                    model: props.taxDocuments[this.state.changeStatusState.model.id].taxReturn
                }
            });
        }

        if (this.state.reportProblemState.model) {
            this.setState({
                reportProblemState: {
                    ...this.state.reportProblemState,
                    model: props.taxDocuments[this.state.reportProblemState.model.id].taxReturn
                }
            });
        }
        if (this.state.recallReturnState.model && props.taxDocuments[this.state.recallReturnState.model.id]) {
            this.setState({
                recallReturnState: {
                    ...this.state.recallReturnState,
                    model: props.taxDocuments[this.state.recallReturnState.model.id].taxReturn
                }
            });
        }
    }
}
export default Assignments;

//Generic Methods
function isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
    for (var i in ids) {
        if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
            return false;
        }
    }
    return true;
}
function isReturnsLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, returns: ITaxReturn[]) {
    for (var i in returns) {
        let id = returns[i].id;
        if (allReturns[id].isLoading) {
            return false;
        }
    }
    return true;
}

const handleIfReturnIsNotAssignToLoggedinUserRequest = (taxReturn: ITaxReturn, profile: IUserProfile, user: IAuthState) =>{

    if (taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy <= 0 &&
        taxReturn.assignTo !== profile.userId &&
        !Helper.isAdmin(user)) {
        return true;
    }
    else if (taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo !== profile.userId &&
        !Helper.isAdmin(user)) {
        return true;
    }
    return false;
}

const handleIfReturnIsInUseRequest = (taxReturn: ITaxReturn, profile: IUserProfile, user: IAuthState) => {
    if (taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo !== profile.userId &&
        Helper.isAdmin(user)) {
        return true;
    }
    else if (taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo === profile.userId) {
        return true;
    }
    return false;
}

