import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import * as Constants from '../../../src/components/helper/Constants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IUploadTaxReturnState {
    sas: string;
    guid: string;
}

export interface RequestTaxReturnAction {
    type: actionTypes.REQUEST_TAX_RETURN;
    message: string;
}

export interface ReceiveTaxReturnUploadLinkAction {
    type: actionTypes.RECEIVE_TAX_RETURN_LINK;
    sasUrl: string;
    documentGuid: string;
}

export interface RequestProcessTaxReturnAction {
    type: actionTypes.PROCESS_TAX_RETURN;
}

export interface UpdateTaxReturnAction {
    type: actionTypes.UPDATE_TAX_RETURN;
}

type DispatchAction = RequestTaxReturnAction |
    ReceiveTaxReturnUploadLinkAction |
    RequestProcessTaxReturnAction |
    UpdateTaxReturnAction;


type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {

    submitTaxReturn: (url: string, taxReturnData: string, resourceId: string, callback: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(url, {
                method: 'POST',
                credentials: 'include',
                body: taxReturnData,
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                    'X-Resource-Id': resourceId
                }
            }).then(handleResponse)
                .then(() => {
                    callback();
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.UpdateTaxReturnError,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`submitTaxReturn failed for url: ${url}, with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });;
        },

    getUploadLink: (url: string, resourceId: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(url, { credentials: 'include',
        headers: {
            'X-Resource-Id': resourceId
        } }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_TAX_RETURN_LINK, sasUrl: data.sas, documentGuid: data.guid });                
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.TaxReturnLinkError,
                    statusType: StatusType.Error
                })
                logger.trackError(`getUploadLink failed for url: ${url}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },

    processTaxReturn: (url: string, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
            }
        }).then(handleResponse)
            .then(() => {
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.ProcessTaxReturnError,
                    statusType: StatusType.Error
                })
                logger.trackError(`processTaxReturn failed for url: ${url}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
}


const unloadedState: IUploadTaxReturnState =
    {
        sas: '',
        guid:'',
    } as IUploadTaxReturnState;

export const reducer: Reducer<IUploadTaxReturnState> = (state: IUploadTaxReturnState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_RETURN_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
        case actionTypes.PROCESS_TAX_RETURN:
            var received = { ...state };           
            return received;
        case actionTypes.UPDATE_TAX_RETURN:
            var received = { ...state };
            return received;
    }
    return state || unloadedState;
};