import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import * as Constants from '../../../components/helper/Constants'
import {
    DeleteGroupInfoAction,
    initialSendGroupedExtensionsHeaderStoreState,
    ReceiveGroupedAccessAction, RequestGroupedAccessAction,
    ResetSendGroupedReturnsAction,
    SendGroupedExtensionsHeaderStoreState
} from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import { HeaderGroupAccess } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction = RequestGroupedAccessAction
    | ReceiveGroupedAccessAction
    | ResetSendGroupedReturnsAction
    | NotificationAction
    | DeleteGroupInfoAction;

type DispatchAction = RequestGroupedAccessAction
    | ReceiveGroupedAccessAction;

const NO_INDEX = -1;

export const actionCreators = {
    unmapReturnstoGroup: (ids: number[], callback?: () => void, failurecallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/UnMapExtensionsToGroupAsync/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {

                dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
                if (callback) {
                    callback();
                }
            })
            .catch(function (error) {
                if (failurecallback) {
                    failurecallback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SendGroupedReturnsHeaderConstants.StatusMessage.UnMapReturnToGroupError,
                    statusType: StatusType.Error
                });
                logger.trackError(`unmapReturnstoGroup failed for ids: ${ids}, with error ${error.message}`);
            })
        addTask(fetchTask);
    },
    requestGroupAccessByGroupIds: (ids: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/GetGroupAccessAsync/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(ids)
        })
            .then(response => response.json() as Promise<HeaderGroupAccess[]>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_GROUP_ACCESS_BY_GROUP_ID,
                    data: data,
                    groupIds: ids
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SendGroupedReturnsHeaderConstants.StatusMessage.GroupAccessRequestError,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestGroupAccessByGroupIds failed for ids: ${ids}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_GROUP_ACCESS_BY_GROUP_ID,
            groupIds: ids
        });
    },
    updateGroupAccess: (accessMaps: HeaderGroupAccess[], callback?: () => void, failurecallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        const groupAccesses = JSON.stringify(accessMaps);
        formData.append('groupAccessList', groupAccesses);
        const options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/UpdateGroupAccessAsync`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: accessMaps.length > 1 ? Constants.SendGroupedReturnsHeaderConstants.StatusMessage.UpdateGroupAccessSuccess : Constants.SendGroupedReturnsHeaderConstants.StatusMessage.UpdateSingleGroupAccessSuccess,
                    statusType: StatusType.Success
                })
                if (callback) {
                    callback();
                }
            })
            .catch(function (error) {
                if (failurecallback) {
                    failurecallback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SendGroupedReturnsHeaderConstants.StatusMessage.UpdateGroupAccessError,
                    statusType: StatusType.Error
                });
                logger.trackError(`updateGroupAccess failed for accessMaps: ${accessMaps}, with error ${error.message}`);
            })
        addTask(fetchTask);
    },

    deleteGroupAndReturns: (groupIds: number[], documentIds: number[], successCallback?: () => void, failurecallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        const groupIdsString = JSON.stringify(groupIds);
        const documentIdsString = JSON.stringify(documentIds);
        formData.append('groupList', groupIdsString);
        formData.append('documentIds', documentIdsString);
        const options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/DeleteGroupAndExtensionsAsync`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_GROUP_INFO,
                    groupIds: groupIds
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: groupIds.length == 0 ?
                        Constants.SendGroupedReturnsGridActionConstants.StatusMessage.DeleteReturnSuccess :
                        Constants.SendGroupedReturnsHeaderConstants.StatusMessage.DeleteSuccess,
                    statusType: StatusType.Success
                });
                dispatch({ type: actionTypes.RESET_SEND_GROUPED_RETURNS });
                if (successCallback) {
                    successCallback();
                }
            })
            .catch(function (error) {
                if (failurecallback) {
                    failurecallback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: groupIds.length == 0 ? Constants.SendGroupedReturnsGridActionConstants.StatusMessage.DeleteReturnError : Constants.SendGroupedReturnsHeaderConstants.StatusMessage.DeleteError,
                    statusType: StatusType.Error,
                });
                logger.trackError(`deleteGroupAndReturns failed for groupIds: ${groupIds}, with error ${error.message}`);
            })
        addTask(fetchTask);
    },
}

export const reducer: Reducer<SendGroupedExtensionsHeaderStoreState> =
    (state: SendGroupedExtensionsHeaderStoreState = initialSendGroupedExtensionsHeaderStoreState, incomingAction: Action) => {
        const action = incomingAction as DispatchAction;
        switch (action.type) {

            case actionTypes.REQUEST_GROUP_ACCESS_BY_GROUP_ID:
                const requestGroupAccessData: SendGroupedExtensionsHeaderStoreState = { ...state };
                requestGroupAccessData.setAccessData.isLoading = true;
                requestGroupAccessData.setAccessData.groupAccessList = [];
                return requestGroupAccessData;
            case actionTypes.RECEIVE_GROUP_ACCESS_BY_GROUP_ID:
                return {
                    ...state,
                    setAccessData: {
                        groupAccessList: action.data,
                        isLoading: false,
                    }
                };

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
        return state || initialSendGroupedExtensionsHeaderStoreState;
    }