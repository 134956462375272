import React from  'react';
import { Alert, Col, FormLabel, Modal, Form, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { SignatureStatus } from '../common/TaxReturn';
import { ISignatureStatusList, ISelectedDocument } from '../../store/reports/DeliveredReturnsState';
import { OverlayLoader } from '../helper/OverlayLoader';
import { getStatus } from '../helper/HelperFunctions';
import { ChangeStatusConstants } from '../helper/Constants';
import { DropdownComponent } from '../common/controls/DropdownComponent';
import { VenusNotifier } from '../helper/VenusNotifier';

interface IChangeStatusProps {
    show: boolean;
    selectedReturnStatus: SignatureStatus;
    onChangeStatusSave(status: SignatureStatus, reason?: string): void;
    selectedDocuments: ISelectedDocument[];
    signatureList: ISignatureStatusList[];
    onCancel: () => void;
    showLoader: boolean;
}
interface IChangeStatusState {
    selectedReturnStatus: SignatureStatus;
    saving: boolean;
    alert: string;
    message: string;
    hideAlert: boolean;
    reasonText: string;
}

export class ReportChangeStatusModal extends React.Component<IChangeStatusProps, IChangeStatusState>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedReturnStatus: SignatureStatus.None,
            saving: false,
            alert: "warning",
            message: ChangeStatusConstants.ChangeDeliveredReturnStatus,
            hideAlert: true,
            reasonText: ""
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onCheckStatus = this.onCheckStatus.bind(this);
        this.onValidate = this.onValidate.bind(this);
    }

    componentWillReceiveProps(nextProps: IChangeStatusProps) {
        if (!this.state.saving) {
            this.setState({ selectedReturnStatus: nextProps.selectedReturnStatus });
        }
        if (!nextProps.show) {
            this.setState({ saving: false });
        }
    }

    private onStatusChange(value: any) {
        this.showWarning(value);
        let signatureStatus = Number(parseInt(value ? value : "0")) as SignatureStatus;
        this.setState({ selectedReturnStatus: signatureStatus });
    }

    private showWarning(value: any) {
        if (value == SignatureStatus.SignedAndESigned) {
            this.setState({ alert: "warning", message: ChangeStatusConstants.ChangeDeliveredReturnStatus, hideAlert: false })
        }
        else
            this.setState({ hideAlert: true });
    }

    private clearState() {
        this.setState({ hideAlert: true, reasonText: "" });
        this.props.onCancel();
    }

    private onValidate() {
        let isValid: boolean = true;
        if (this.state.selectedReturnStatus == 0) {
            VenusNotifier.Warning(ChangeStatusConstants.SelectedStatus, null)
            isValid = false;
        }
        else if (this.state.selectedReturnStatus === SignatureStatus.SignedAndESigned && this.state.reasonText.trim() == "") {
            VenusNotifier.Warning(ChangeStatusConstants.EmptyReason, null)
            isValid = false;
        }
        return isValid;
    }

    private onSubmit() {
        let result;
        result = this.onValidate();
        if (result == true) {
            this.props.onChangeStatusSave(this.state.selectedReturnStatus, this.state.reasonText)
            document.body.click();
            this.setState({ hideAlert: true, reasonText: "" });
        }
    }

    private onPopoverCancel() {
        document.body.click();
    }

    private onCheckStatus() {
        let isValid: boolean = true;
        if (this.state.selectedReturnStatus == 0) {
            isValid = false;
        }
        else if (this.state.selectedReturnStatus === SignatureStatus.SignedAndESigned && this.state.reasonText.trim() == "") {
            isValid = false;
        }
        return isValid;
    }

    private PrepareSignatureList = () => {
        var arrayOption: any = []
        this.props.signatureList.map((i: any) => {
            let text: string = getStatus(parseInt(i.value));
            arrayOption.push({
                value: i.value,
                label: text
            })
        })
        return arrayOption;
    }

    private onReasonTextChange = (event: any) => {
        let reason = Object.assign({}, this.state.reasonText);
        reason = event.target.value;
        this.setState({ reasonText: reason })
    }

    public render() {
        const popover = (
            <Popover className="popovereditinfo" id="reports-change-status-modal">
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>
                    Confirmation
                    </Popover.Title>
                <Popover.Content>
                    {ChangeStatusConstants.changeStatusConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onSubmit}
                            title="OK"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={this.onPopoverCancel}
                            title="Cancel"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                     </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        let isLoaded: boolean = this.props.selectedDocuments.every(i => i.isfullyLoaded);
        let modalBody = this.props.showLoader ? (<OverlayLoader
            show={this.props.show}
            text={"Saving, please wait..."} />)
            : (isLoaded ?
                (
                    <div>
                        <Alert variant="info" ><i className='fas fa-info-circle' style={{ marginRight: '5px' }}>
                        </i>{this.props.selectedDocuments.length + " Returns selected."}</Alert>
                        <div className="row marB03">
                            <Col sm={12}>
                                <div>
                                    <FormLabel>Status</FormLabel>
                                    <DropdownComponent
                                        id="ddlReportStatus"
                                        selectedValue={this.state.selectedReturnStatus}
                                        onChange={this.onStatusChange}
                                        options={this.PrepareSignatureList()}
                                    />
                                </div>
                            </Col>
                        </div>
                    </div>
                ) :
                (<OverlayLoader
                    show={this.props.show}
                    text={"Loading, please wait..."} />));

        return <Modal className="report-change-status-modal" show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='text-secondary fas fa-random' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Change Status
					</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Alert variant={this.state.alert} hidden={this.state.hideAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                    </i>{this.state.message}</Alert>
                    {modalBody}
                    {!this.state.hideAlert &&
                        <div>
                            <label>Reason</label>
                            <textarea
                                data-test-auto="E1A5D15F-F20D-40B0-BE5D-F593E6EDB4D2"
                                className="form-control"
                                rows={5}
                                value={this.state.reasonText}
                                onChange={this.onReasonTextChange}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="default"
                        data-test-auto="3CDA1A83-2AEA-4211-9469-1562431A601D"
                        onClick={this.clearState}><i className='fas fa-times'></i>Cancel
                        </Button>

                    {(this.onCheckStatus() === true) ?
                        <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} placement="top" overlay={popover} >
                            <Button variant="info"
                                data-test-auto="524CC1BF-10E5-4940-BBD9-36BD4208AE59"
                                disabled={!isLoaded}>
                                <i className='fas fa-save'></i>Save
                            </Button>
                        </OverlayTrigger> :
                        <Button variant="info"
                            data-test-auto="524CC1BF-10E5-4940-BBD9-36BD4208AE59"
                            onClick={this.onValidate}
                            disabled={!isLoaded}>
                            <i className='fas fa-save'></i>Save
                        </Button>
                    }
                </Modal.Footer>
            </Form >
        </Modal >;
    }
}