import React from  'react';
import { Row, Col } from 'react-bootstrap';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import ToggleSwitch from '../../common/ToggleSwitch';


interface SignerDelegationProps {
    companySettings: ExtensionCompanySettings;
    handleEntityReturnSignerDelegation(event: any): void;
    handleIndividualReturnSignerDelegation(event: any): void;
}

const SignerDelegation = (props: SignerDelegationProps) => {

    return (<div className="new-main-content">
        <h3>Signer Delegation</h3>

        <div className="marL20">
            <Row>
                <Col className="text-left" sm={2} style={{ width: "17.7%", paddingTop: "3px" }}>
                    Individual (1040) Returns
                    </Col>
                <Col sm={4} md={3} lg={2}>
                    <ToggleSwitch switched={props.companySettings.signatureSettingsModel.enableIndividualReturnSignerDelegation}
                        handleChange={props.handleIndividualReturnSignerDelegation} 
                        resourceId={RBACKeys.Settings.settings}/>
                </Col>
                <Col sm={6} md={7} lg={8}>
                </Col>
            </Row>

            <Row>
                <Col className="text-left" sm={2} style={{ width: "17.7%", paddingTop: "3px" }}>
                    Entity (1041, 1065, 1120, 1120S, 990) Returns
                    </Col>
                <Col sm={4} md={3} lg={2}>
                    <ToggleSwitch switched={props.companySettings.signatureSettingsModel.enableEntityReturnSignerDelegation}
                        handleChange={props.handleEntityReturnSignerDelegation} 
                        resourceId={RBACKeys.Settings.settings}/>
                </Col>
                <Col sm={6} md={7} lg={8}>
                </Col>
            </Row>
        </div>
    </div>);
};

export { SignerDelegation };
