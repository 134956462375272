import { VenusNotifier } from './VenusNotifier';
import {
    ValidationContants, TabEFileConstants, FinishProcessReturn, VoucherTabConstants,
    FileConstants, FileSizeExceedsLimit, SFDocument, MailMergeDocument, TabAdditionalEsignDocumentConstants, ProcessReturnConstants
} from './Constants'
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings, IPasswordSettings, PasswordOptions } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import {
    ITaxReturn, isPartnership, isIndividual, isMutual, SignatureType,
    SignatureControlRole, SignatureControlTypes, ISignatureControl, ITaxingAuthority, DocumentGroups, VoucherTypes, VoucherNo, IEFile, IFormBase, IVoucher
} from '../common/TaxReturn';
import { validateClientInfo, filterSignatureByRole, validateDeliveryOption, validateDocumentLocation } from '../helper/HelperFunctions';
import { EfileInfoView, ISignatureControlsDictionary } from '../common/ProcessReturnModal/ProcessReturnModels';
import { IValidationResult } from './ValidationResult';
import { IDocumentData, IAdditionalESignDocumentPage, IAdditionalESignDocumentControl } from '../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { IUserProfile } from '../navigation/profile/ProfileObjects';

export function isValidEmailAddress(emailAddress: any) {
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(emailAddress);
};
export function validateZip(ZipNoId: any) {
    var ZipNo = ZipNoId.trim();
    if (ZipNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.ZipWarning, "");
        return false;
    }
    else if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        VenusNotifier.Warning(ValidationContants.ZipLengthWarning, "");
        return false;
    }
    else
        return true;
}
export function validateExtension(ExtensionId: any) {
    var Extension = ExtensionId.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        VenusNotifier.Warning(ValidationContants.ExtensionWarning, "");
        return false;
    }
    else
        return true;
}
export function validateFax(FaxId: any) {
    var Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        VenusNotifier.Warning(ValidationContants.FaxWarning, "");
        return false;
    }
    else
        return true;
}
export function validateState(stateId: number) {
    if (stateId == 0) {
        VenusNotifier.Warning(ValidationContants.StateWarning, "");
        return false;
    }
}
export function validateCity(CityId: any) {
    var City = CityId.trim();
    if (City == "") {
        VenusNotifier.Warning(ValidationContants.CityWarning, "");
        return false;
    }
    else if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        VenusNotifier.Warning(ValidationContants.ValidCityWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePhone(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
        return false;
    }
    else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePassword(passwordId: any) {
    var password = passwordId.trim();
    if (password.trim().length < 8) {
        VenusNotifier.Warning(ValidationContants.PasswordLengthWarning, "");
        return false;
    }
    else if (password.trim().indexOf(' ') >= 0) {
        VenusNotifier.Warning(ValidationContants.PasswordWithoutSpaceWarning, "");
        return false;
    }
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: IPasswordSettings): IValidationResult {
    var password = password.trim();
    const passwordOptions = passwordSettings.passwordOptions;
    if (password.trim().length < passwordSettings.length) {
        return {
            isError: true,
            errorDescription: 'Password must be minimum ' + passwordSettings.length + ' characters.'
        };
    }
    else if (password.trim().indexOf(' ') >= 0) {
        return {
            isError: true,
            errorDescription: ValidationContants.PasswordWithoutSpaceWarning
        };
    }
    else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.UpperCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.LowerCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.NumbersValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters && !hasSpecialCharacter(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.SpecialCharactersValidationError
        };
    }

    return {
        isError: false,
        errorDescription: "Success"
    };
}

function hasLowerCase(data: string) {
    var pattern = new RegExp('.*[a-z].*');
    return pattern.test(data);
}

function hasUpperCase(data: string) {
    var pattern = new RegExp('.*[A-Z].*');
    return pattern.test(data);
}

function hasNumber(data: string) {
    var pattern = new RegExp(/[\d]/i);
    return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
    var pattern = new RegExp(/[~!@#$%^&*_]/i);
    return pattern.test(data);
}

export function bootboxAlert(message: string, callback: any) {
    bootbox.alert(message, callback);
}

export function validateUrl(url: string) {
    return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i.test(url);
}

export function getFileExtension(fileName: string) {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function getFileNamewithoutExtension(fileName: string) {
    var fileExtension = getFileExtension(fileName);
    return fileName.replace("." + fileExtension, '');
}

export function getContentType(file: File): string {
    let contentType: string = file.type.slice(0, file.type.indexOf("/"));
    if (contentType == "application") {
        return contentType + "/" + getFileExtension(file.name);
    }
    return file.type;
}

export function validateFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf") {
        VenusNotifier.Warning(ValidationContants.PdfFileValidation, "")
        return false;
    }
    return true;
}

export function validateFileSize(file: any): boolean {
    if (file.size > FileConstants.MaximumFileSize) {
        VenusNotifier.Error(FileSizeExceedsLimit(file.name), null);
        return false;
    }
    return true;
}

export function isValidSSN(ssn: string) {
    if (ssn.replace(/-/g, '').length == 9) { return true; }
};

export function isValidEIN(ein: string) {
    if (ein.replace(/-/g, '').length === 9) { return true; }
}

export function isValidDate(date: any) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
}

export function isDigit(zip: any) {
    var date_regex = /^[0-9]{1,10}$/;
    return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function isValidatePTIN(ptin: string) {
    var ptin_regex = /^[Pp][0-9]{8}$/;
    return ptin_regex.test(ptin);
}



export function isValidFirmKey(firmKey: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(firmKey);
}

export function isValidGuid(guid: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(guid);
}

export function validatePhoneLength(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    else
        return true;
}

export function validateFaxLength(FaxId: any) {
    var Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        return false;
    }
    else
        return true;
}

export function validateValidCity(CityId: any) {
    var City = CityId.trim();
    if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        return false;
    }
    else
        return true;
}
export function validateZipLength(ZipNoId: any) {
    var ZipNo = ZipNoId.trim();
    if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        return false;
    }
    else
        return true;
}
export function ValidateWebsite(website: string) {
    if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z A-Z 0-9]+([\-\.]{1}[a-z A-Z 0-9]+)*\.[a-z A-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(website) == false) {
        return false;
    }
    else {
        return true;
    }
}

export function validateExtensionLength(ExtensionId: any) {
    var Extension = ExtensionId.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        return false;
    }
    else
        return true;

}
export function validatePasswordLength(passwordId: any) {
    var password = passwordId.trim();
    if (password.length < 8) {
        return false;
    }
    return true;
}
export function validatePasswordSpace(passwordId: any) {
    var password = passwordId.trim();
    if (password.indexOf(' ') >= 0) {
        return false;
    }
    return true;
}

export function validateCompanySettings(companySetting: ICompanySettings) {
    if (companySetting && companySetting.isDefault) {
        return false;
    }
    return true;
}


export function validateTaxReturn(taxReturn: ITaxReturn, useSignatureStamp: boolean, allTaxingAuthorities: ITaxingAuthority[]): boolean {
    let isValid: boolean = true;
    //validate delivery option
    let isValidDeliveryOption = validateDeliveryOption(taxReturn);
    // Validate client info
    isValid = (validateClientInfo(taxReturn) && isValidDeliveryOption) === true ? true : false;

    // Validate custom authorities in added refund
    //if (isValid) {
    //    isValid = validateRefudInfo(taxReturn, allTaxingAuthorities);
    //}

    // Validate efile tab only if previous validation is successful
    if (isValid) {
        isValid = validateEfileTab(taxReturn, useSignatureStamp)
    }

    // Validate system recognised vouchers with null values value
    if (isValid) {
        isValid = validateRecognisedVoucher(taxReturn);
    }

    // validates Retention Period i.e Value should be >0
    if (isValid) {
        isValid = validateRetentionPeriod(taxReturn);
    }

    //validate office location
    if (isValid) {
        isValid = validateDocumentLocation(taxReturn);
    }

    return isValid;
}
export function validateFormGroup(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType != SignatureType.ManualSign

        //this is an error we need to check this line
        //&& taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType != SignatureType.SignatureNotApplicable
    ) {
        let index = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        if (taxReturn.formGroups[index] && taxReturn.formGroups[index].forms) {
            taxReturn.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                if (!form.signatureControls || form.signatureControls.length == 0) {
                    isValid = false;
                }
            });
        }
        const voucherIndex = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        if (taxReturn.formGroups[voucherIndex] && taxReturn.formGroups[voucherIndex].forms) {
            taxReturn.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                let form: IVoucher = f as IVoucher;
                if (form.signatureEnable &&
                    (!form.signatureControls || form.signatureControls.length == 0)) {
                    isValid = false;
                }
            });
        }
    }
    return isValid;
}

export function validateRefudInfo(taxReturn: ITaxReturn, allTaxingAuthorities: ITaxingAuthority[]) {
    let index: number = -1;
    const numberOfRefunds: number = taxReturn.refundInfo.length;
    if (numberOfRefunds == 0)
        return true;
    for (let i = 0; i < numberOfRefunds; i++) {
        const authorityID: number = taxReturn.refundInfo[i].authorityID;
        index = allTaxingAuthorities.findIndex(authority => authorityID === authority.Id);
        if (index != -1)
            continue;
        else break;
    }
    if (index != -1)
        return true;
    else
        VenusNotifier.Error(FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
    return false;
}

function signableFormsExists(taxReturn: ITaxReturn): boolean {
    let index = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
    let voucherIndex = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
    if (index !== -1 || voucherIndex !== -1)
        return true;
    return false;
}

function validateEfileTab(taxReturn: ITaxReturn, useSignatureStamp: boolean): boolean {

    let isValid: boolean = true;

    if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed) {

        if (!signableFormsExists(taxReturn)) {
            VenusNotifier.Warning(ProcessReturnConstants.NoSignatureMessage, "");
            return false;
        }

        const eFileInfo = new EfileInfoView(taxReturn);
        let signatureControls: ISignatureControlsDictionary = eFileInfo.getSignatureControls();
        let signatureControlstoArray: any = Object.keys(signatureControls).map((key: any) => signatureControls[key]);

        let taxpayerSignatures = filterSignatureByRole(signatureControlstoArray, "signatureControlRole", SignatureControlRole.Taxpayer).
            filter((i: any) => i.type == SignatureControlTypes.Signature);
        let spouseSignatures = filterSignatureByRole(signatureControlstoArray, "signatureControlRole", SignatureControlRole.Spouse).
            filter((i: any) => i.type == SignatureControlTypes.Signature);
        let eroSignatures = filterSignatureByRole(signatureControlstoArray, "signatureControlRole", SignatureControlRole.ERO).
            filter((i: any) => i.type == SignatureControlTypes.Signature);
        let partnerSignatures = filterSignatureByRole(signatureControlstoArray, "signatureControlRole", SignatureControlRole.PartnerShip).
            filter((i: any) => i.type == SignatureControlTypes.Signature);

        if (
            ((isIndividual(taxReturn) || isMutual(taxReturn)) && taxpayerSignatures.length > 0 && !taxReturn.taxpayer.isDeceased)
            ||
            (isMutual(taxReturn) && spouseSignatures.length > 0 && !taxReturn.spouse.isDeceased)
            ||
            (isPartnership(taxReturn) && partnerSignatures.length > 0)
        ) {
            isValid = true;
        } else {
            VenusNotifier.Warning(ProcessReturnConstants.NoSignatureMessage, "");
            isValid = false;
        }
        //else if (useSignatureStamp && eroSignatures.length < 1) {
        //    VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.EroStamp, "");
        //    isValid = false;
        //}
    }

    if (isValid) {
        isValid = ValidateEfileAuthority(taxReturn)
    };

    return isValid;
}

export function filterAdditionalDocumentSignatureByRole(signatureData: IAdditionalESignDocumentPage[], role: number) {
    let filtered: IAdditionalESignDocumentControl[] = [];
    for (var i = 0; i < signatureData.length; i++) {
        let obj: IAdditionalESignDocumentPage = signatureData[i];
        obj.controls.forEach(item => {
            if (item.signatureControlRole == role) {
                filtered.push(item);
            }
        })
    }
    return filtered;
}

function validateAdditionalEsignDocumentTab(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = false;
    let warningMsg: string = '';

    if (taxReturn.additionalEsignDocuments != undefined && taxReturn.additionalEsignDocuments.length > 0) {
        for (var i = 0; i < taxReturn.additionalEsignDocuments.length; i++) {
            var item: IDocumentData = taxReturn.additionalEsignDocuments[i];
            let taxpayerSignatures = filterAdditionalDocumentSignatureByRole(item.documentControls, SignatureControlRole.Taxpayer).
                filter((i: any) => i.type == SignatureControlTypes.Signature);
            let spouseSignatures = filterAdditionalDocumentSignatureByRole(item.documentControls, SignatureControlRole.Spouse).
                filter((i: any) => i.type == SignatureControlTypes.Signature);
            let partnerSignatures = filterAdditionalDocumentSignatureByRole(item.documentControls, SignatureControlRole.PartnerShip).
                filter((i: any) => i.type == SignatureControlTypes.Signature);

            if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign) {
                if (isPartnership(taxReturn) && partnerSignatures.length < 1) {
                    isValid = false;
                    warningMsg = TabAdditionalEsignDocumentConstants.SignatureControlWarning.Partner;
                }
                else if ((isIndividual(taxReturn) || isMutual(taxReturn)) && taxpayerSignatures.length < 1 && !taxReturn.taxpayer.isDeceased) {
                    isValid = false;
                    warningMsg = TabAdditionalEsignDocumentConstants.SignatureControlWarning.Taxpayer;
                }
                else if (isMutual(taxReturn) && spouseSignatures.length < 1 && !taxReturn.spouse.isDeceased) {
                    isValid = false;
                    warningMsg = TabAdditionalEsignDocumentConstants.SignatureControlWarning.Spouse;
                }
                else {
                    isValid = true;
                }
            }
            else {
                isValid = true;
            }
        }
    }
    else {
        isValid = true;
    }
    if (!isValid && warningMsg !== '') {
        VenusNotifier.Warning(warningMsg, null);
    }
    return isValid;
}


export function ValidateEfileAuthority(taxReturn: ITaxReturn) {
    let isValid: boolean = true;

    taxReturn.formGroups.map((formGroups) => {
        if (formGroups.group == DocumentGroups.EFile) {
            formGroups.forms.map((form) => {
                let efileform = form as IEFile;
                if (efileform.authorityID == 0) {
                    VenusNotifier.Warning("Please select taxing authority for " + form.formName + " in filing forms tab ", "");
                    isValid = false;
                }
            });
        }
    });

    return isValid;
}


export function validateFileTypes(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf" && fileExtension.toLowerCase() !== "docx" && fileExtension.toLowerCase() !== "doc" && fileExtension.toLowerCase() !== "png" &&
        fileExtension.toLowerCase() !== "jpeg" && fileExtension.toLowerCase() !== "xlsx"
        && fileExtension.toLowerCase() !== "jpg" && fileExtension.toLowerCase() !== "xls") {
        VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "")
        return false;
    }
    return true
}

function validateRecognisedVoucher(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;

    taxReturn.formGroups.map((formGroups) => {
        if (formGroups.group === DocumentGroups.Vouchers) {
            formGroups.forms.map((form: any) => {
                if (form.dueDate === undefined || new Date(form.dueDate).getFullYear() == 1) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherDueDateInvalid + form.formName, "");
                    isValid = false;
                }
                if (form.paymentType === VoucherTypes.None || form.voucherNo === VoucherNo.None) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherPaymentType + form.formName, "");
                    isValid = false;
                }
                if (form.authorityID === 0) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherAuthorityWarning + form.formName, "");
                    isValid = false;
                }
            });
        }
    });

    return isValid;
}

export function validatePdfFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    return false;
}
export function validatePdfFile(fileExtension: string, signature: any) {
    if (fileExtension.toLowerCase() === "pdf" && validatePdfFileContent(signature)) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.PdfFileValidation, "")
    return false;
}

export function validateSFUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf" && fileExtension.toLowerCase() !== "doc" && fileExtension.toLowerCase() !== "docx") {
        VenusNotifier.Warning(SFDocument.sfUploadFileWarning, "")
        return false;
    }
    return true;
}

export function validateMailMergeTemplateUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "csv") {
        VenusNotifier.Warning(MailMergeDocument.CsvUploadFileWarning, "")
        return false;
    }
    return true;
}

export function validateWordAndPDFFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    else if (signature === 'D0CF11E0') {
        return true;
    }
    else if (signature === '504B34') {
        return true;
    }
    return false;
}

export function isWordFile(signature: any) {
    return signature === 'D0CF11E0' || signature === '504B34';
}

export function ValidateCSVFileContent(signature: any): boolean {
    return signature === 'EFBBBF22' || signature === '5369676E' || signature === '53656E64' ? true : false;
}

export function fileContentValidation(signature: any) {
    if (signature === '25504446' ||             //.pdf
        signature === 'D0CF11E0' ||             //.doc
        signature === '504B34' ||             //.docx
        signature === '89504E47' ||             //.png
        signature === 'FFD8FFE1' ||             //.jpg
        signature === '504B0304' ||             //.xlsx
        signature === 'D0CF11E0' ||             //.xls
        signature === 'FFD8FFE0' ||             //.jpeg
        signature === 'FFD8FFE2'                //.jpeg

    ) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "")
    return false;
}

export function ConvertDateToUtc(date: Date): Date {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
}

export function ValidateTenDigitNumber(event: any) {
    var pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
    var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
}

export function validateRetentionPeriod(taxReturn: ITaxReturn): boolean {
    if (taxReturn.documentSettings.documentRetentionSetting.retentionPeriod > 0) {
        return true;
    }
    else {
        VenusNotifier.Warning(FinishProcessReturn.StatusMessage.RetentionValidationMessage, "");
        return false;
    }
}


export function ValidateTrustedDeviceExpiryDays(value: number) {
    if (value > 0 && value < 61) {
        return true;
    }
}

export function ValidateIPAdress(ipAddress: string): boolean {
    var ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    return ipRegex.test(ipAddress);
}


export function ValidateFontSizeValue(value: number) {
    if (value > 0 && value < 73) {
        return true;
    }
}

export function validateIndividualFileSize(file: any): boolean {
    if (file?.size > FileConstants.FileSizeForEncryptionValidation) {
        return false;
    }
    return true;
}


export const isControlDisabled = (property: string, profile: IUserProfile): boolean => {
    return profile.readonlyFields.some(field => field === property);
};

