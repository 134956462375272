import React from  'react';
import { Card, Accordion, Row, Col, Button, FormLabel } from 'react-bootstrap';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import {
    ITaxReturn, EngagementType,
    DocumentGroups, PaperReturnDeliveryType
} from '../../../common/TaxReturn';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { RadioButtonComponent } from '../../../common/RadioButtonComponent';
import { ReorderList, IOrderList } from '../../../common/Generic/ReorderList';

interface IPaperDelivery {
    taxreturn: ITaxReturn;
    cancelFinish: () => void;
    onPrintForPaperDelivery: () => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
}

interface IPaperDeliveryState {
    multipleFilesChecked: boolean;
    singleFileChecked: boolean;
    listContent: IOrderList[];
}

const DocumentGroupsList: any = {
    "None": "None",
    "EFile": "Filing Forms",
    "Vouchers": "Vouchers",
    "TaxReturns": "Tax Returns",
    "Transmittals": "Transmittals",
};


export class PrintForPaperDelivery extends React.Component<IPaperDelivery, IPaperDeliveryState> {
    constructor(props: IPaperDelivery) {
        super(props);
        this.state = {
            multipleFilesChecked: false,
            singleFileChecked: false,
            listContent: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IPaperDelivery) {
        this.checkAndUpdateState(nextProps);
    }

    UNSAFE_componentWillMount() {
        this.checkAndUpdateState(this.props);
    }

    public render() {

        const {
            documentSettings: {
                deliverySettings: {
                    paperReturnSettings: {
                        deliveryType,
                        isNotifyUserEnabled,
                    },
                },
            },
        } = this.props.taxreturn;

        const { listContent } = this.state;

        const reorderList: IOrderList[] = [];
        if (listContent.length > 0) {
            if (listContent[0].id === DocumentGroups.Transmittals) {
                reorderList.push(listContent[0]);
                reorderList.push({ id: Number.MAX_SAFE_INTEGER, value: "Filing Forms" });
            } else {
                reorderList.push({ id: Number.MAX_SAFE_INTEGER, value: "Filing Forms" });
                if (listContent.some((group) => group.id === DocumentGroups.Transmittals)) {
                    reorderList.push({ id: DocumentGroups.Transmittals, value: "Transmittals" });
                }
            }
        }

        const multipleFilesChecked = deliveryType === PaperReturnDeliveryType.MultipleFiles ||
            deliveryType.toString() === PaperReturnDeliveryType[PaperReturnDeliveryType.MultipleFiles];

        const singleFileChecked = deliveryType === PaperReturnDeliveryType.SingleFile ||
            deliveryType.toString() === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile];

        return <div className="padT04">
            {
                <Accordion defaultActiveKey={"0"}>
                    <Card className="marR20 border-radius-04">
                        <Card.Body>
                            {
                                <div>
                                    <Row className="marB10">
                                        <Col sm={11}>
                                            <RadioButtonComponent text="Download Multiple PDF files (one for each document grouping)"
                                                id="multiple-files"
                                                checked={multipleFilesChecked}
                                                onClick={() => {
                                                    this.onChangePaperDeliveryType(PaperReturnDeliveryType.MultipleFiles);
                                                }}
                                                disabled={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="marB10">
                                        <Col sm={6}>
                                            <RadioButtonComponent text="Download Single PDF file"
                                                id="single-file"
                                                checked={singleFileChecked}
                                                onClick={() => {
                                                    this.onChangePaperDeliveryType(PaperReturnDeliveryType.SingleFile);
                                                }}
                                                disabled={false}
                                            />
                                        </Col>
                                    </Row>
                                    {singleFileChecked ?
                                        <div>

                                            <Row className="marB10"
                                                style={{
                                                    border: "1px solid #ddd",
                                                    marginLeft: "2px",
                                                    marginRight: "2px",
                                                    borderTopLeftRadius: "4px",
                                                    borderTopRightRadius: "4px",
                                                    paddingBottom: "10px",
                                                }}>
                                                <Col sm={12} style={{ borderBottom: "1px solid #ddd" }}>
                                                    <FormLabel style={{ padding: "10px 0px" }} >
                                                        Select Document Order in the Single PDF file
                                                </FormLabel>

                                                </Col>
                                                {
                                                    <div style={{ width: "100%" }}>
                                                        <OverlayLoader
                                                            show={this.state.listContent.length > 0 ? false : true}
                                                            text={"Loading..."} />
                                                        <Col sm={12} className="padT10">
                                                            <ReorderList
                                                                listContent={reorderList}
                                                                reorderList={this.reorderList}
                                                            />
                                                        </Col>

                                                        <div>
                                                            <Col sm={6} style={{
                                                                marginLeft: "129px",
                                                                marginTop: "15px"
                                                            }}>
                                                                <CheckBoxComponent
                                                                    id="saveAsDefaultorder"
                                                                    checked={this.props.isDefaultOrder}
                                                                    onChange={this.props.handleSaveAsDefaultOrderChange}
                                                                    text="Save as my default order" />
                                                            </Col>
                                                        </div>
                                                    </div>
                                                }
                                            </Row>

                                        </div>
                                        : ""

                                    }
                                    <Row className="marB10">
                                        <Col sm={12} style={{ fontSize: "11px" }} >
                                            <i className="fas fa-info-circle"></i>
                                            <span style={{ marginLeft: 5 }}>
                                                It may take some time to prepare your files for download.
                                                Please download your files from the Delivered Extensions Report.
                                </span>
                                        </Col>
                                    </Row>
                                    <Row className="marB10">
                                        <Col sm={11} >
                                            <CheckBoxComponent
                                                id="notify"
                                                checked={isNotifyUserEnabled}
                                                onChange={this.handleNotifyUserChange} text="Notify me by mail when the file(s) are available for Download" />
                                        </Col>
                                    </Row>
                                    <div>
                                        <Col sm={12}>
                                            <Button
                                                type="button"
                                                variant="info"
                                                className="pull-right"
                                                data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                                                onClick={this.onPrintForPaperDelivery}
                                            >
                                                <i className="fa fa-paper-plane text-secondary"
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}></i>Prepare PDF File(s) For Printing
                                                </Button>
                                            <Button
                                                type="button"
                                                variant="light"
                                                className="pull-right marR05"
                                                data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                                                onClick={this.props.cancelFinish}
                                            >
                                                <i className="fas fa-times"
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}></i>
                                                Cancel
                                                </Button>
                                        </Col>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Accordion>
            }
        </div>;
    }

    private checkAndUpdateState = (nextProps: IPaperDelivery) => {
        const {
            documentSettings: {
                deliverySettings: {
                    paperReturnSettings: {
                        documentOrder,
                        deliveryType,
                    },
                },
            },
            formGroups,
        } = nextProps.taxreturn;

        let listContents: IOrderList[] = [];
        const newList: IOrderList[] = [];

        if (documentOrder !== undefined) {
            listContents = this.getGroupOrder(documentOrder);
        } else {
            listContents = this.getDefaultGroupOrder();
        }

        listContents.forEach((value) => {
            if (formGroups.find((m) => m.group === value.id) !== undefined &&
                formGroups.filter((m) => m.group === value.id)[0] &&
                formGroups.filter((m) => m.group === value.id)[0].forms.length !== 0) {
                newList.push(value);
            }
        });

        const documentGroups = formGroups.filter((m) => m.group);

        documentGroups.forEach((value) => {
            if (value.group !== DocumentGroups.Deleted) {

                const groupName = DocumentGroups[value.group];

                if (formGroups.find((m) => m.group === value.group) !== undefined &&
                    formGroups.filter((m) => m.group === value.group)[0] &&
                    formGroups.filter((m) => m.group === value.group)[0].forms.length !== 0 &&
                    newList.find((m) => m.id == value.group) === undefined) {
                    const group: IOrderList = { id: value.group, value: DocumentGroupsList[groupName] };
                    newList.push(group);
                }
            }

        });

        this.setState({
            listContent: newList,
            multipleFilesChecked: deliveryType === PaperReturnDeliveryType.MultipleFiles ||
                deliveryType.toString() === PaperReturnDeliveryType[PaperReturnDeliveryType.MultipleFiles],

            singleFileChecked: deliveryType === PaperReturnDeliveryType.SingleFile ||
                deliveryType.toString() === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile],
        });
    }

    private onChangePaperDeliveryType(paperDeliveryType: PaperReturnDeliveryType) {

        this.props.taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType = paperDeliveryType;
        this.props.updateTaxDocument(this.props.taxreturn);

        this.setState({
            multipleFilesChecked: paperDeliveryType === PaperReturnDeliveryType.MultipleFiles,
            singleFileChecked: paperDeliveryType === PaperReturnDeliveryType.SingleFile,
        });
    }

    private reorderList = (updatedList: IOrderList[]) => {

        let orderedList = this.getDefaultGroupOrder();

        if (updatedList[0].id === DocumentGroups.Transmittals) {
            orderedList = orderedList.filter((item) => item.id !== DocumentGroups.Transmittals);
            orderedList.unshift({ id: DocumentGroups.Transmittals, value: "Transmittals" });
        }

        const taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder =
            orderedList.map((value) => {
                return value.id;
            });
        this.props.updateTaxDocument(taxReturn);

        this.setState({ listContent: orderedList });
    }

    private handleNotifyUserChange = (event: any) => {
        const taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.isNotifyUserEnabled = event.target.checked;
        this.props.updateTaxDocument(taxReturn);
    }

    private getDefaultGroupOrder = (): IOrderList[] => {
        return [

            { id: DocumentGroups.EFile, value: "EFiles" },
            { id: DocumentGroups.Vouchers, value: "Vouchers" },
            { id: DocumentGroups.TaxReturns, value: "Tax Returns" },
            { id: DocumentGroups.Transmittals, value: "Transmittals" },

        ];
    }

    private getGroupOrder = (order: DocumentGroups[]): IOrderList[] => {
        const listContents: IOrderList[] = [];

        order.map((value) => {
            const groupName = DocumentGroups[value];
            const group: IOrderList = { id: value, value: DocumentGroupsList[groupName] };
            listContents.push(group);
        });

        return listContents;
    }

    private onPrintForPaperDelivery = () => {
        const taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder =
            this.state.listContent.map((value) => {
                return value.id;
            });

        this.props.updateTaxDocument(taxReturn);
        this.props.onPrintForPaperDelivery();
    }

}

export default PrintForPaperDelivery;
