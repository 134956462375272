import 'isomorphic-fetch';
import React, { FC, useEffect, useState } from 'react';
import 'src/Core/Services/FetchInterceptorService';
import Notification from './helper/NotificationContainer';
import { IEExplorerBlocker } from '../components/IEExplorerBlocker';
import { handleResponse } from '../store/Library';
import SignalRWebSocket from '../components/SignalRwebSocketContainer';
import LastLoginModal from './LastLoginModal';
import SecurityModal from './SecurityModal';
import { TYPES } from './../Startup/types';
import { container } from './../Startup/inversify.config';
import { ISessionLocalStore } from 'src/Core/Utilities/SessionStore';
import { API_BASE_URL } from 'src/utils/contants';
import { getAccessToken, signoutRedirect } from 'src/oidcClient/userService';
import { initializeLogger } from './helper/LoggerHelper';
import Layout from '@sssuite-component-ui/ss-suite-layout';
import { headerConfig, getSideMenu, initialHeaderConfig } from './LayoutConfig';
import { IHeaderProps } from '@sssuite-component-ui/ss-suite-layout/dist/Header/Header';
import { actionCreators as UserActions } from 'src/store/userManagement/UserStore';
import { actionCreators as UserProfileActions } from 'src/store/userManagement/UserProfileStore';
import { actionCreators as CompanyActions } from 'src/store/company/CompanyStore';
import { actionCreators as UserSettingsActions } from 'src/store/userManagement/UserSettingStore';
import { actionCreators as ExtensionUserSettingActions } from 'src/store/userManagement/ExtensionUserSettingStore';
import { actionCreators as UserLoginHistoryActions } from 'src/store/userManagement/UserLoginHistoryStore';
import { actionCreators as CustomColumnActions } from 'src/store/common/CustomColumnStore';
import { actionCreators as TaxSoftwareActions } from 'src/store/SuiteCompany/TaxSoftwareStore';
import { actionCreators as OneTimePassword } from 'src/store/OTP/OneTimePassword';
import { actionCreators as PostAuthStore } from 'src/store/PostAuth/PostAuthStore';
import { actionCreators as UserMFASettingsActions } from 'src/store/company/SuiteMFASettingStore';
import { MySettings } from './navigation/profile/MySettings';
import { ApplicationState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import MyDownload from './navigation/profile/MyDownload';
import { actionCreators as companyActionCreator } from 'src/store/company/CompanyStore';
import { useHistory } from 'react-router'
import { ReportProblemIcon } from './svg/IconCollection';
import Logout from './navigation/profile/Logout';
import { UserAutoLogoutModal } from './UserAutoLogoutModal';
import { IAuthState, initialAuthState } from '../store/auth/reducer';
import { ActionCreators as ExtensionCompanyActions } from 'src/store/settings/ExtensionCompanySettingsStore';
import { MyLoginHistory } from './navigation/profile/MyLoginHistory';
import MyAccount from './navigation/profile/MyAccount';
import { actionCreators as PasswordPolicySettingActions } from 'src/store/PasswordPolicy/PasswordPolicyStore'
import {actionCreators as OfficeLocationAction} from 'src/store/common/OfficeLocationStore'
import {actionCreators as UserOfficeLocation} from 'src/store/common/UserOfficeLoacationStore'
import { SessionTimeout } from '@sssuite-component-ui/session-timeout';
import { useForethought } from './helper/useForethought';
import { createForethoughtCookie, injectPendoScript } from './helper/HelperFunctions';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

declare global {
    interface Window {
        Variables: any;
        Forethought: any;
        pendo: any;
    }
}

const WarningVisibleMinutes = 1;

export interface LayoutProps {
    children?: React.ReactNode;
}

interface MenuToggler {
    toggleMenu(): void;
}

export interface IHeaderModal {
    mySettings: boolean
    myDownloads: boolean
    myLoginHistory: boolean
    profile: boolean
    logOut: boolean
}
const LayoutWrapper: FC<LayoutProps> = (props) => {
    let sessionRenew: any;
    let sessionExpired: any;

    initializeLogger();
    const [sessionExpiredModal, setSessionExpiredModal] = useState<boolean>(false);
    const [sessionIdleMinutes, setSessionIdleMinutes] = useState<number>(0);
    const [localAuthState, setlocalAuthState] = useState<IAuthState>(initialAuthState);
    const [isPendoInjected, setIsPendoInjected] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<IHeaderModal>({
        mySettings: false,
        myDownloads: false,
        myLoginHistory: false,
        logOut: false,
        profile: false
    });
    const [header, setHeader] = useState<IHeaderProps>(initialHeaderConfig);
    const rbacStore = useSelector((state: ApplicationState) => state.rbacStore)
    const history = useHistory();
    const [forethoughtClick] = useForethought();

    const userProfile = useSelector((state: ApplicationState) => state.userProfile);
    const downloadsList = useSelector((state: ApplicationState) => state.companyData.myDownloads);
    const companyData = useSelector((state: ApplicationState) => state.companyData);
    const { company_name, device_id } = useSelector((state: ApplicationState) => state.auth.user.profile);
    const userPrivilege = useSelector((state: ApplicationState) => state.auth);
    let isCompanyExtensionProductEnabled = useSelector((state: ApplicationState) => state.postAuthDataStore.extensionEnabled);
    let loggedOut = useSelector((state: ApplicationState) => state.postAuthDataStore.loggedOut);
    let officeLocation = useSelector((state: ApplicationState) => state.officeLocation.locationDropdown);

    const dispatch = useDispatch();


    const setIntervalSessionExpiredCheck = () => {

        sessionExpired = setInterval(() => {
            if (!getAccessToken()) {
                SessionStore.remove("isVisited");
                setSessionExpiredModal(true);
            }
        }, 1000);

    }

    const renewSession = () => {
        fetch(`${API_BASE_URL}api/MyAccount/StayAliveAsync`, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse);
    }

    const setSessionIdleTime = () => {
        fetch(`${API_BASE_URL}api/KeyVault/SessionTimeOutMinutes`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                setSessionIdleMinutes(data);
            })
            .catch(error => {
                setSessionIdleMinutes(20);
            });
    }   

    useEffect(() => {
        sessionRenew = setInterval(() => {
            renewSession();
        }, 1000 * 60 * (20));

        setSessionIdleTime();
        setIntervalSessionExpiredCheck();

        SessionStore.remove("isCompanyDisabled");
        if (userPrivilege.isResetCacheApiCalled && !userPrivilege.userPrivilegesChanged) {
            dispatch(UserActions.requestAllPartners());
            dispatch(UserActions.requestExtensionsUsers(false));
            dispatch(UserProfileActions.requestUserProfile());
            dispatch(CompanyActions.requestCompanyLogo(true));
            dispatch(CompanyActions.getMyDownloadsListAsync());
            dispatch(UserSettingsActions.requestUserSettings(true));
            dispatch(ExtensionUserSettingActions.requestExtensionUserSettings(true));
            dispatch(CompanyActions.requestCompanyProfile());
            dispatch(UserLoginHistoryActions.requestUserLoginHistory());
            dispatch(CompanyActions.requestCompanySettings());
            dispatch(CustomColumnActions.requestCustomColumn(true));
            dispatch(TaxSoftwareActions.requestSuiteTaxSoftwareSettings());
            dispatch(ExtensionCompanyActions.requestExtensionCompanySettings());
            dispatch(OneTimePassword.requestMFAOTPLength());
            dispatch(PasswordPolicySettingActions.requestPasswordPolicySetting());
            dispatch(OfficeLocationAction.getOfficeLocationDropdown(false));
            dispatch(UserOfficeLocation.fetchUserOfficeLocation());
            dispatch(UserMFASettingsActions.requestMFASetting());
        }

        if (!(window as any)._walkmeConfig?.smartLoad) {
            dispatch(CompanyActions.getWalkMeScript((script: string) => {
                if (script && script.length > 0) {
                    let scriptElement = document.createElement("script");
                    scriptElement.type = "text/javascript";
                    scriptElement.async = true;
                    scriptElement.text = script;
                    document.head.appendChild(scriptElement);
                    (window as any).loadWalkMe && (window as any).loadWalkMe();
                }
            }));
        }
    }, [userPrivilege.isResetCacheApiCalled]);

    const headerChildren = () => {
        return (
            <div
            onClick={forethoughtClick}
                title={"Support"}
                className={`mr-4 mt-2 cursor-pointer support-icon`}
            >
                <ReportProblemIcon />
            </div>
        );
    };

    useEffect(() => {
        () => {
            clearInterval(sessionRenew);
            clearInterval(sessionExpired);
        }
    }, []);

    useEffect(() => {
        const { isDefaultLogo, logoPath } = companyData.companyLogoSetting;

        setHeader(headerConfig(
            showModal,
            setShowModal,
            headerChildren,
            isDefaultLogo ? company_name : null,
            userProfile.firstName,
            rbacStore.navigationMenus,
            rbacStore.suiteUrl,
            history.push,
            !isDefaultLogo ? logoPath : null,
        ));
    }, [userProfile, company_name, rbacStore, companyData,showModal,history.push]);

    useEffect(() => {
        const { userId, firstName, lastName, emailAddress } = userProfile;
		const { companyId, companyName } = companyData.companyProfile?.companyInfo;
		injectPendoScript(
			companyId,
			companyName,
			userId,
			firstName,
			lastName,
			emailAddress,
			isPendoInjected,
			setIsPendoInjected,
		);
    }, [userProfile, companyData]);

    useEffect(() => {
        //Incase of multiple userPrivilage update displaying only first one
        if (userPrivilege.userPrivilegesChanged &&
            localAuthState?.userPrivilegesChanged !== userPrivilege.userPrivilegesChanged) {
            setlocalAuthState(userPrivilege);
        }
    });

    if (navigator.userAgent.indexOf("Trident") > -1) {
        return <IEExplorerBlocker />
    }
    else {
        return (
            <div>
                <Layout
                    headerProps={header}
                    sideMenuProps={
                        getSideMenu(
                            rbacStore.leftMenus,
                            history.push,
                            !rbacStore.isExtensionEnabled || !isCompanyExtensionProductEnabled)
                    }>

                    <div id="wrapper">

                        <div id="content-wrapper">
                            <div className="container-fluid">
                                <Notification />
                                {props.children}
                            </div>
                        </div>
                    </div>
                </Layout>
                {userPrivilege.isResetCacheApiCalled &&
                    <>
                        <SecurityModal
                            openModal={sessionExpiredModal}
                        />
                        <SessionTimeout
                            deviceId={device_id}
                            onExternalLogout= {()=>createForethoughtCookie("isForethoughtWidgetVisible", "false")}
                            logout={signoutRedirect}
                            sessionIdleMinutes={sessionIdleMinutes}
                            warningVisibleMinutes={WarningVisibleMinutes}
                            currentTabLoggedOutShow={loggedOut}
                            setCurrentTabLoggedOutShow={() => {
                                dispatch(PostAuthStore.loggedOut());
                            }}
                        />

                        <SignalRWebSocket />
                        <LastLoginModal />
                        <MyAccount showState={showModal.profile} onHide={() => setShowModal({ ...showModal, profile: false })} />
                        <MySettings
                            showState={showModal.mySettings}
                            onHide={() => setShowModal({ ...showModal, mySettings: false })}
                        />
                        <MyDownload
                            showState={showModal.myDownloads}
                            onHide={() => setShowModal({ ...showModal, myDownloads: false })}
                            downloadsList={downloadsList}
                            downloadReturnFromPopup={(jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void) =>
                                dispatch(companyActionCreator.downloadReturnFromPopup(jobId, fileName, isDownloadNow, callback))}
                            clearAllMyDownloads={() => dispatch(companyActionCreator.clearAllMyDownloads())}
                            deleteMyDownloads={(jobId: string, isDownloadNow?: boolean) => dispatch(companyActionCreator.deleteMyDownloads(jobId, isDownloadNow))}
                            officeLocation = {officeLocation} />
                        <MyLoginHistory showState={showModal.myLoginHistory} onHide={() => setShowModal({ ...showModal, myLoginHistory: false })} />
                    </>
                }

                {localAuthState.userPrivilegesChanged && <UserAutoLogoutModal openModal={localAuthState.userPrivilegesChanged} logoutCause={localAuthState.userAutoLogoutCause} />}
                <Logout
                    showState={showModal.logOut}
                    onHide={() => setShowModal({ ...showModal, logOut: false })}
                />
            </div>)
    };
};

export default LayoutWrapper;
