import React from  'react';
import { GroupInfo } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';

interface IButtonFormatterProps {
    deleteReturn: any;
    disabled: boolean;
}

interface IDropdownItem {
    disableDropdown: boolean;
    defaultERO: number;
    EROs: IUserModel[];
    onEROChange: any;
    id: string;
    className?: any;
}

interface IDownloadDocument {
    downloadAttachment(event: any): void;
    disabled: boolean;
}

const ButtonFormatter: React.FC<IButtonFormatterProps> = ({ deleteReturn, disabled }) => {
    return (
        <button type="button" className="btn-white btn-xs"
            disabled={disabled} onClick={deleteReturn}>
            <i className="text-danger fas fa-times"
                data-test-auto="C166BDDC-ADE5-46F2-8B94-7ABAFFC4BED7">
            </i>
        </button>
    );
};

const DropDownFormatter: React.FC<IDropdownItem> =
    ({ id, EROs, onEROChange, defaultERO, disableDropdown, className }) => {
        return (
            <DropdownComponent
                id={id}
                options={
                    EROs.map((value, index) => {
                        return {
                            value: value.id,
                            label: value.firstName + ' ' + value.lastName
                        };
                    })}
                onChange={onEROChange}
                selectedValue={defaultERO === 0 ? null : defaultERO}
                disabled={disableDropdown}
                className={className}
            />
        );
    };

const DownloadButtonFormatter: React.FC<IDownloadDocument> = ({ disabled, downloadAttachment }) => {
    return (
        <button type="button" className="btn-white btn-lg attachmentDownload"
            disabled={disabled}
            onClick={downloadAttachment}>
            <i className="fa fa-download" style={{ color: "#333", backgroundColor: "white" }}></i>
        </button>);
};

export { DownloadButtonFormatter, ButtonFormatter, DropDownFormatter };
