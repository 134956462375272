import React, { FC } from "react";
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { TaxpayerAuthenticationType } from "src/Core/ViewModels/Company/CompanySettingsViewModel";

interface ITaxPayerAuthenticationProps {
    taxpayerAuthentication: TaxpayerAuthenticationType;
    handleTaxpayerAuthentication(event: any): void;
}

export const TaxpayerAuthenticationTypes :FC<ITaxPayerAuthenticationProps> =({taxpayerAuthentication , handleTaxpayerAuthentication }) => {

    return <div className="new-main-content">
        <h3 >Taxpayer Authentication</h3>
        <div className="marL20">
            <div className="div-height">
                <RadioButtonComponent
                    id={"LastSSN"}
                    text={"Last 4 of SSN"}
                    checked={taxpayerAuthentication == TaxpayerAuthenticationType.Last4ofSSN}
                    onChange={() => handleTaxpayerAuthentication(TaxpayerAuthenticationType.Last4ofSSN)}
                    value={TaxpayerAuthenticationType.Last4ofSSN}
                    dataTestAuto={"5FD24C1F-0BBF-4ABF-BDA4-BFD171EE10BE"}
                />

            </div>
            <div>
                <RadioButtonComponent
                    id={"FirstSSN"}
                    text={"First 4 of SSN"}
                    checked={taxpayerAuthentication == TaxpayerAuthenticationType.First4ofSSN}
                    onChange={() => handleTaxpayerAuthentication(TaxpayerAuthenticationType.First4ofSSN)}
                    value={TaxpayerAuthenticationType.First4ofSSN}
                    dataTestAuto={"84447BD9-88F8-4118-A9C4-4B738E3C0922"}
                    />

            </div>
        </div>
    </div>
 }

export default TaxpayerAuthenticationTypes;