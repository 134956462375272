import React from  'react';
import { ClientInfoProperty } from './ClientInfoEnum';
import { ITaxpayer } from './TaxClient';
import { ITaxReturn, SignatureStatus, ClientTypesNumber, DocumentStatus } from './TaxReturn';
import Select from 'react-select';
import { prepareStateDropdown, FilterSSN, GetCountryCode } from '../helper/HelperFunctions';
import { CustomDatePicker } from './controls/CustomDatePicker';
import { ICountryState } from '../../Core/ViewModels/Company/CompanyViewModel';
import { TaxIdentificationNumber } from '../common/controls/TaxIdentificationNumber';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ClientInfoTab } from '../helper/Constants';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { Card } from 'react-bootstrap';
import { SignerStatus } from './DeliveredTaxReturns';
import { IDropdown } from 'src/Core/Common/Dropdown';
import DropdownComponent from './controls/DropdownComponent';
let dateMoment = require('moment');

export interface ITaxpayerProp {
    clientId: string;
    model: ITaxpayer;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    taxReturn: ITaxReturn;
    states: ICountryState[];
    locationDropdown: IDropdown[];
    isDeliveredReturn: boolean;
}

export interface ITaxPayerState {
    isDisabled: boolean;
    isDeliveredToTaxCaddy: boolean;
}

export class Taxpayer extends React.Component<ITaxpayerProp, ITaxPayerState> {
    constructor(props: ITaxpayerProp) {
        super(props);
        this.state = {
            isDisabled: false,
            isDeliveredToTaxCaddy: false,
            // locationId: 0
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: ITaxpayerProp) {
        if (nextprops.taxReturn.signedDetails != undefined) {

            const taxpayer = this.props.taxReturn.signedDetails
                .find((x) => x.signerType === ClientTypesNumber.Taxpayer);


            const isDisabled: boolean = (this.props.taxReturn.signatureStatus === SignatureStatus.PartiallySigned &&
                this.props.taxReturn.signedDetails != undefined &&
                taxpayer &&
                (taxpayer.signerStatus == SignerStatus.Reviewed ||
                taxpayer.signerStatus == SignerStatus.Signed) ?
                true :
                false)
                || (
                this.props.taxReturn.signatureStatus === SignatureStatus.ESigned ||
                this.props.taxReturn.signatureStatus === SignatureStatus.AwaitingReview ||
                this.props.taxReturn.signatureStatus === SignatureStatus.PartiallyReviewed ||
                this.props.taxReturn.signatureStatus === SignatureStatus.Reviewed
                );

            const isDeliveredToTaxCaddy: boolean = (nextprops.taxReturn.documentStatus ===
                DocumentStatus.DELIVEREDTOTAXCADDY);

            this.setState({ isDisabled, isDeliveredToTaxCaddy });

        }
    }

    onOfficeLocationChange = (event: any) => {
            this.props.handleValueChange(ClientInfoProperty.officeLocationId, event);
        };

    prepareLocation = () => {
        return this.props.locationDropdown?.map(item => {
            return {
                value: item.value.toString(),
                label: item.name
            }
        });
    }


    public render() {
        return <div className='col-lg-12' >

            <Card className="margin-bottom-20">
                <Card.Header>
                    <Card.Title as="h4"><strong>Taxpayer</strong></Card.Title>
                </Card.Header>
            </Card>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Client ID <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.clientId}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.clientId, event.target.value);
                                }}
                                placeholder="Client ID"
                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                disabled={this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Name <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.name}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerName, event.target.value);
                                }}
                                placeholder="First Name"
                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Email <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.email || ''}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerEmail, event.target.value);
                                }}
                                placeholder="Email"
                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                disabled={this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            {this.props.isDeliveredReturn && <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Office <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <DropdownComponent
                                id="form-field-edit-info-state-taxpayer"
                                className='font12'
                                selectedValue={this.props.taxReturn?.location.locationId}
                                onChange={(event: any) => {
                                    this.onOfficeLocationChange(event)
                                    }}
                                options={this.prepareLocation()}
                                data-test-auto="894C3CEB-4671-4529-A835-ECA0CCC9DD91"
                                clearable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>}

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Address
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.address}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerAddress, event.target.value);
                                }}
                                placeholder="Address"
                                data-test-auto="FC1A0182-46DA-40B2-8439-ACED6B19D759"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            City
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.city}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerCity, event.target.value);
                                }}
                                placeholder="City"
                                data-test-auto="74469676-1B78-4E82-B9C2-6C0A43D0A98D"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            State
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            <Select
                                name="form-field-edit-info-state-taxpayer"
                                value={this.props.model.state}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerState, event.value);
                                }}
                                options={prepareStateDropdown(this.props.states)}
                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                clearable={false}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Zip
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.zip}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerZip, event.target.value);
                                }}
                                maxLength={5}
                                placeholder="Zip"
                                data-test-auto="F8EC44D1-A576-4B71-BE4B-C1A844D44E62"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            DOB
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            {
                                <CustomDatePicker
                                    value={this.props.model.dob}
                                    onChange={this.handleChangeDoB}
                                    disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                    maxDate={new Date()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Country Code
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <Select
                                name="form-field-edit-info-state-taxpayer"
                                value={this.props.model.countryCode}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerCountryCode, event ? event.value : "");
                                }}
                                options={GetCountryCode()}
                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                clearable={true}
                                searchable={true}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Mobile
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            <PhoneNumberComponent
                                phoneNumber={this.props.model.mobileNumber ? this.props.model.mobileNumber : ""}
                                handleChangePhoneNumber={(value: string) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerMobileNumber, value);
                                }}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </PhoneNumberComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0 justify-flex-end">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row '>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            SSN
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <TaxIdentificationNumber
                                value={this.props.model ? FilterSSN(this.props.model.ssn) : ""}
                                onChange={(value: string) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.taxpayerSSN, value);
                                }}
                                placeholder="SSN"
                                mask='000-00-0000'
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    private handleChangeDoB = (event: any) => {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        } else {
            this.props.handleValueChange(
                ClientInfoProperty.taxpayerDateOfBirth, event);
        }
    }

}
export default Taxpayer;
