import { connect } from 'react-redux';
import { ApplicationState } from "../../../store";
import { GroupSettings } from './GroupSettings';
import * as  EmailMessageStore from '../../../store/settings/EmailMessageStore';
import * as ExtensionCompanySettingsStore from '../../../store/settings/ExtensionCompanySettingsStore';
import * as  ClientMessageStore from '../../../store/settings/ClientMessageStore';

export default connect(
    (state: ApplicationState) => ({
        emailMessageList: state.emailMessage,
        clientMessageList: state.clientMessage,
        extensionCompanyData: state.extensionCompanyData
    }),
    {
        ...EmailMessageStore.ActionCreators,
        ...ClientMessageStore.ActionCreators,
        ...ExtensionCompanySettingsStore.ActionCreators
    },
)(GroupSettings as any);