import React from  'react';
import { Col, Row } from 'react-bootstrap';
import { SignatureControlTypes } from '../../../common/TaxReturn';

interface IEfileSignControlProps {
	caption: string,
	icon: string,
    controlType: SignatureControlTypes,
    helpText: string,
    dragStart(event: any, controlType: SignatureControlTypes): void;
    dragEnd?: (event: any) => void;
}

export interface IEfileSignControlBoxItem {
	caption: string,
	icon: string,
    controlType: SignatureControlTypes,
    helpText: string
}

export class EfileSignControl extends React.Component<IEfileSignControlProps, {}>{
    constructor(props: IEfileSignControlProps) {
		super(props);
    }

	public render() {

        return <Row className="marB15">
            <Col sm={10}>
                {
                    <div 
                        className="icon-div col-xs-12"
                        data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                        draggable={true}
                        onDragStart={(event) => { this.props.dragStart(event, this.props.controlType) }}
                        onDragEnd={(event) => this.props.dragEnd && this.props.dragEnd(event) }
                    >
                        <i className={this.props.icon} style={{ backgroundColor: '#008000', color: '#FFFFFF', width: '28px', height: '100%' }}>
                        </i>
                        {this.props.caption}
                    </div>
                }
            </Col>
            <i className="fa fa-question-circle" style={{ color: '#DAA520', marginTop:'8px' }} title={this.props.helpText}></i>
        </Row>
	}
}