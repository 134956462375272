import React from  'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ITaxReturn } from '../common/TaxReturn';
import { OverlayLoader } from '../helper/OverlayLoader';

let moment = require('moment');

interface IDownloadHistory {
	show: boolean;
	onCancel: () => void;
	taxReturn: ITaxReturn;
}

interface IColumnValues {
	downloadedBy: string,
	downloadedDate: Date
}
export class DownloadHistory extends React.Component<IDownloadHistory, {}>{
	constructor(props:any) {
        super(props);
		this.defaultType = this.defaultType.bind(this);
	}
	defaultType(cell: any, row: any) {
		return cell;
	}

	public render() {

		const downloadHistoryColumns = [
			{
				header: 'Downloaded By',
				key: 'downloadedBy',
				isKey: false,
				dataFormat: this.defaultType,
				toolTip: true,
				width: 'auto'
			},
			{
				header: 'Downloaded Date',
				key: 'downloadedDate',
				isKey: true,
				dataFormat: this.defaultType,
				toolTip: true,
				width: 'auto'
			},
		];

		let downloadHistoryData: IColumnValues[] = [];

		if (this.props.taxReturn && this.props.taxReturn.downloadHistory) {
			this.props.taxReturn.downloadHistory.map((value, index) => {
				downloadHistoryData.push({
					downloadedBy: value.actedBy.firstName + " " + value.actedBy.lastName,
					downloadedDate: moment(value.actedOn).format('MM/DD/YYYY hh:mm:ss A')
				});
			});
		}

		let modalBody = this.props.taxReturn && this.props.taxReturn.downloadHistory ?
			(<BootstrapTable data={downloadHistoryData}>
				{downloadHistoryColumns.map((value, index) => {
					return <TableHeaderColumn key={index} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat} width={value.width} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
				})}
			</BootstrapTable>) : (<OverlayLoader
				show={this.props.show}
				text={"Loading, please wait..."} />);

		return <Bs.Modal className="download-history-modal" show={this.props.show} onHide={this.props.onCancel} >
			<Bs.Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="134ABB8D-9B61-4B6F-A47C-DCD94D6BAF9D">
				<Bs.Modal.Title>Download History</Bs.Modal.Title>
			</Bs.Modal.Header>
			<Bs.Form>
				<Bs.Modal.Body>
					{modalBody}
				</Bs.Modal.Body>
			</Bs.Form>
		</Bs.Modal >
	}
}