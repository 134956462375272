import React from  'react';
import * as Bootstrap from 'react-bootstrap';
import { Button } from 'react-bootstrap';

interface RecycleGroupedExtensionsHeaderProps {
    pageTitle: string,
    selectedDocumentCount: number,
    onPageReload: () => void;
    onDeleteDocument: (rowIndex: number) => void;
    refreshDelay?: boolean;
    onRestoreDocument: (rowIndex: number) => void;
    isBulkSelectionEnabled: boolean;
    selectedRowCount: number;
}

interface RecycleGroupedExtensionsHeaderState {
}
const NO_INDEX = -1;
export class RecycleGroupedExtensionsHeader extends React.Component<RecycleGroupedExtensionsHeaderProps, RecycleGroupedExtensionsHeaderState> {
    constructor(props: RecycleGroupedExtensionsHeaderProps) {
        super(props);
        this.state = {
        };
    }

    public render() {

        return <div>
            <div className="row" style={{ marginBottom: 5 }}>
                <div className="col-sm-8">
                    <h3 className="page-title">{this.props.pageTitle}</h3>
                </div>

            </div>
            <div className="row">
                <div className="col-sm-10">
                    <Button
                        variant='default'
                        className='btn btn-white'
                        disabled={this.props.selectedDocumentCount === 0 && this.props.selectedRowCount === 0}
                        data-test-auto="B7924657-96E3-4965-8469-BBE05578F208"
                        onClick={(e:any) => this.props.onDeleteDocument(NO_INDEX)}>
                        <i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        variant='default'
                        className='btn btn-white'
                        style={{ marginLeft: '8px' }}
                        disabled={this.props.selectedDocumentCount === 0 && this.props.selectedRowCount === 0}
                        data-test-auto="CF7B62E7-CD65-4081-8E2B-64A0C011F6DD"
                        onClick={(e:any) => this.props.onRestoreDocument(NO_INDEX)}>
                        <i className='fas fa-undo'></i> Restore
                    </Button>
                    <Bootstrap.Button
                        variant="default"
                        onClick={this.props.onPageReload}
                        title="Refresh"
                        disabled={this.props.refreshDelay}
                        className="btn-white btn-only marR10 btn btn-default"
                        style={{ paddingLeft: '12px', marginLeft: '8px' }}
                        data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
                        <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                            style={{ padding: '4px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                        </span>
                    </Bootstrap.Button>
                </div>

            </div>

        </div >
    }
}
export default RecycleGroupedExtensionsHeader;