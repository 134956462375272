import React from  'react';
import { Button } from 'react-bootstrap';
import * as bootbox from 'bootbox';
import 'isomorphic-fetch';

import { RouteComponentProps } from 'react-router';
import { MessageDetails, initialMessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { MessagePopUp } from './SavedMessage/MessagePopUp';
import { MessageList } from './SavedMessage/MessageList';
import { MessageView } from '../settings/SavedMessage/MessageView'
import { OverlayLoader } from '../helper/OverlayLoader';
import { VenusNotifier } from '../helper/VenusNotifier';
import * as SavedMessageStore from '../../store/settings/SavedMessageStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as Constants from '../helper/Constants';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import * as ExtensionStoreModel from '../../Core/ViewModels/Extension/ExtensionStoreModels';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import { logClientEvent } from '../helper/LoggerHelper';
import { RBACKeys } from '../helper/RbacConstants';
import { LogEventConstants } from '../helper/LogEventConstants';

var htmlencode = require('htmlencode');
const isEqual = require("react-fast-compare");
const pageTitle = "SavedMessages";

export interface SavedMessageState {
    showAddMessagePopUp: boolean;
    selectedMessageId: number;
    defaultMessageId: number;
    showLoader: boolean;
    loaderText: string;
    buttonText: string;
    isUpdateMessage: boolean;
    title: string;
    showLoaderPopUp: boolean;
}

type SavedMessageProps = {
    savedMessageList: SavedMessageStore.SavedMessageStoreState,
    extensionCompanyData: ExtensionStoreModel.ExtensionSettingsStoreState,
}
    & typeof SavedMessageStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof ExtensionCompanySettingsStore.ActionCreators
    & RouteComponentProps<{ page: string }>;

export class SavedMessages extends React.Component<{} & SavedMessageProps, SavedMessageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showAddMessagePopUp: false,
            selectedMessageId: 0,
            defaultMessageId: 0,
            showLoader: false,
            loaderText: "",
            buttonText: "",
            isUpdateMessage: false,
            title: "",
            showLoaderPopUp: false
        }
        this.closeAddPopUp = this.closeAddPopUp.bind(this);

        this.onListItemClick = this.onListItemClick.bind(this);

        this.addButtonClick = this.addButtonClick.bind(this);
        this.updateButtonClick = this.updateButtonClick.bind(this);
        this.deleteButtonClick = this.deleteButtonClick.bind(this);

        this.openAddMessagePopUp = this.openAddMessagePopUp.bind(this);
        this.openEditMessagePopUp = this.openEditMessagePopUp.bind(this);
    }

    componentDidMount() {
        this.props.requestSavedMessages(false);
        this.props.requestExtensionCompanySettings();
        this.props.requestUserSettings(true);
        this.setState({ showLoader: true, loaderText: "Loading...." })

        //If Redux already has these then componentWillReceiveProps won't get called on load
        if (this.props.extensionCompanyData.companySettings) {
            this.setState({
                selectedMessageId: this.props.extensionCompanyData.companySettings.defaultSettings.savedMessage,
                defaultMessageId: this.props.extensionCompanyData.companySettings.defaultSettings.savedMessage
            });
        }

        //Loader Hide if no backend call needed, componentWillReceiveProps won't get called first time
        if (this.props.extensionCompanyData.companySettings && this.props.savedMessageList) {
            this.setState({ showLoader: false });
        }

        logClientEvent(
                `${LogEventConstants.Common.Settings.Open} ${pageTitle}`,
                { count: 1, page: pageTitle}
            );
    }

    componentWillReceiveProps(nextProps: SavedMessageProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                selectedMessageId: nextProps.extensionCompanyData.companySettings ?
                    nextProps.extensionCompanyData.companySettings.defaultSettings.savedMessage : 0,
                defaultMessageId: nextProps.extensionCompanyData.companySettings ?
                    nextProps.extensionCompanyData.companySettings.defaultSettings.savedMessage : 0
            });
        }
        //If No change in Data, but clicked save/update. No change in props
        this.setState({
            showLoader: !nextProps.savedMessageList.isLoading && !nextProps.extensionCompanyData.loading ? false : true,
            showLoaderPopUp: !nextProps.savedMessageList.isLoading && !nextProps.extensionCompanyData.loading ? false : true,

        }, () => { if (!this.state.showLoaderPopUp) this.setState({ showAddMessagePopUp: false }) });
    }

    //===============List Action Button Start===========

    private addButtonClick(messageDetail: MessageDetails, isSetDefault: boolean, isToggled: boolean) {
        let companySettings = this.props.extensionCompanyData.companySettings;
        if (companySettings) {
            messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
            this.props.saveMessageDetail(messageDetail, companySettings, isSetDefault);
            this.setState({ selectedMessageId: this.state.defaultMessageId, showLoaderPopUp: true });

            logClientEvent(
                    `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
                    { count: 1, page: pageTitle }
                );
        } else {
            VenusNotifier.Error(Constants.SavedMessageConstants.SaveMessageError, null);
        }
    }

    private updateButtonClick(messageDetail: MessageDetails, isSetDefault: boolean, isToggled: boolean) {
        let companySettings = this.props.extensionCompanyData.companySettings;
        if (companySettings) {
            if (isToggled) {
                if (isSetDefault) {
                    companySettings.defaultSettings.savedMessage = messageDetail.id;
                    this.props.updateExtensionCompanySettings(companySettings);
                } else {
                    //Default message shouldnot be turned off
                    VenusNotifier.Warning(Constants.SavedMessageConstants.SelectDefaultMessageWarning, null);
                    return false;
                }
            }
            messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
            this.props.updateMessageDetail(messageDetail);
            this.setState({
                isUpdateMessage: false,
                showLoaderPopUp: true
            });

            logClientEvent(
                    `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
                    { count: 1, page: pageTitle }
                );

        } else {
            VenusNotifier.Error(Constants.SavedMessageConstants.UpdateMessageError, null);
        }
    }

    private deleteButtonClick() {
        if (this.state.selectedMessageId == this.state.defaultMessageId) {
            bootbox.alert({
                message: Constants.SavedMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Message',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteMessageDetail(_currentObject.props.savedMessageList.data[_currentObject.state.selectedMessageId].messageDetails);
                        _currentObject.setState({ selectedMessageId: _currentObject.state.defaultMessageId, showLoader: true, loaderText: "Deleting message" })
                    }
                }
            });
        }
    }

    private openAddMessagePopUp() {
        this.setState({
            showAddMessagePopUp: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Saved Message",
            loaderText: "Saving message"
        });
    }

    private closeAddPopUp() {
        this.setState({ showAddMessagePopUp: false, isUpdateMessage: false });
    }

    private openEditMessagePopUp() {
        this.setState({
            showAddMessagePopUp: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Saved Message",
            loaderText: "Saving message"
        });
    }

    private onListItemClick(messageId: number) {
        this.setState({
            selectedMessageId: messageId
        });
    }

    public render() {
        const savedMessageDetail = this.props.savedMessageList.data == undefined || this.props.savedMessageList.data[this.state.selectedMessageId == 0 ?
            parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId] == undefined ?
            initialMessageDetails : this.props.savedMessageList.data[this.state.selectedMessageId == 0 ?
                parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId].messageDetails;

        return <div style={{ position: 'initial' }} >
            <OverlayLoader show={this.state.showLoader} text={this.state.loaderText} />
            <h3 className="page-title">Saved Messages</h3>
            <hr />

            <div className="row">
                <div className="col-lg-4 col-sm-6">
                    <div className="row justify-content-end">
                        <div className="mr-4">
                            <Button
                                data-resource-id={RBACKeys.Settings.settings}
                                variant='info'
                                size='lg'
                                style={{ padding: 12, minWidth: 40 }}
                                title="Add Message"
                                onClick={this.openAddMessagePopUp}>
                                <span className="fas fa-plus" />
                            </Button>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="col">
                            <MessageList
                                messageDetails={this.props.savedMessageList.data ? this.props.savedMessageList.data : []}
                                onListItemClick={this.onListItemClick}
                                selectedMessageId={this.props.savedMessageList.data && this.props.savedMessageList.data[this.state.selectedMessageId] ?
                                    this.props.savedMessageList.data[this.state.selectedMessageId].messageDetails.id : 0}
                                defaultMessageId={this.state.defaultMessageId}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 col-sm-6" style={{ marginLeft: 32 }}>
                    <div className="row justify-content-end">
                        <div className="mr-4">
                            <Button
                                data-resource-id={RBACKeys.Settings.settings}
                                variant="btn btn-default btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={this.openEditMessagePopUp}>
                            </Button>
                            <Button
                                data-resource-id={RBACKeys.Settings.settings}
                                variant="btn btn-default btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={this.deleteButtonClick}>
                            </Button>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="col">
                            <MessageView
                                selectedMessageDetails={savedMessageDetail}
                                defaultMessageId={this.state.defaultMessageId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <MessagePopUp
                onShow={this.state.showAddMessagePopUp}
                onHide={this.closeAddPopUp}
                SubmitButtonClick={this.state.isUpdateMessage ? this.updateButtonClick : this.addButtonClick}
                buttonText={this.state.buttonText}
                selectedMessageDetail={this.state.isUpdateMessage ? savedMessageDetail : initialMessageDetails}
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                messageList={this.props.savedMessageList}
                loadertext={"Saving message"}
                showLoader={this.state.showLoaderPopUp}
            />
        </div >
    }
}

