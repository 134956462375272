import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../ActionTypes';
import * as Helper from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export enum DefaultDownloadInfoType {
    DownloadNow = "DownloadNow",
    DownloadToMyDownloads = "DownloadToMyDownloads",
    PromptMeEveryTime = "PromptMeEveryTime"

}

export interface DefaultDownloadInfo {
    downloadType: DefaultDownloadInfoType;
    rememberDefaultDownload: boolean;
}

export interface IExtensionDefaultSettings {
    manualAddressId: number;
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyUser: number;
    customColumnValue: number;
    download: DefaultDownloadInfo;
    userId: number;
}

export interface DefaultSenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export interface ExtensionUserNotificationSettings{
    paymentNotification: boolean;
}

export interface IExtensionUserSettings {
    defaultSettings: IExtensionDefaultSettings
    notificationSettings: ExtensionUserNotificationSettings;
    loading: boolean;
}

interface RequestExtensionUserSettingAction {
    type: actionTypes.REQUEST_EXTENSION_USER_SETTINGS;
}

interface ReceiveExtensionUserSettingAction {
    type: actionTypes.RECEIVE_EXTENSION_USER_SETTINGS;
    settings: IExtensionUserSettings;
}

interface ErrorExtensionUserSettingAction {
    type: actionTypes.ERROR_EXTENSION_USER_SETTINGS;
    message: string;
}

type KnownAction =
    RequestExtensionUserSettingAction |
    ReceiveExtensionUserSettingAction |
    ErrorExtensionUserSettingAction |
    NotificationAction;

export const actionCreators = {
    requestExtensionUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> =>  (dispatch, getState) =>  {

        if (reload) {
            const fetchTask = fetch(`${API_BASE_URL}api/ExtensionUserSettings/UserSetting`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IExtensionUserSettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_EXTENSION_USER_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_EXTENSION_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                    logger.trackError(`requestUserSettings failed with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },

    saveExtensionUserSettings: (settings: IExtensionUserSettings, callback?: () => void): AppThunkAction<KnownAction> =>  (dispatch, getState) =>  {
        const fetchTask = fetch(`${API_BASE_URL}api/ExtensionUserSettings`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(settings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IExtensionUserSettings>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_EXTENSION_USER_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }

                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_EXTENSION_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
                logger.trackError(`saveUserSettings failed for settings: ${settings}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateExtensionUserSettings: (settings: IExtensionUserSettings): AppThunkAction<KnownAction> =>  (dispatch) =>  {
        dispatch({ type: actionTypes.RECEIVE_EXTENSION_USER_SETTINGS, settings: settings });
    },
}

export const initialExtensionUserSettings: IExtensionUserSettings = {
    defaultSettings: {
        manualAddressId: 0,
        eroUser: 0,
        contactPerson: 0,
        sender: {
            senderType: DefaultSenderInfoType.Company,
            senderId: 0 
        },
        notifyUser: 0,
        customColumnValue: 0,
        download: {
            downloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            rememberDefaultDownload :false
        },
        userId :0
    },
    notificationSettings: {
        paymentNotification: false
    },
    loading: false
};

export const reducer: Reducer<IExtensionUserSettings> = (state: IExtensionUserSettings = initialExtensionUserSettings, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_EXTENSION_USER_SETTINGS:
            let cleanSettings: IExtensionUserSettings = { ...state, loading: true };
            return cleanSettings;
        case actionTypes.RECEIVE_EXTENSION_USER_SETTINGS:
            let updated: IExtensionUserSettings = { ...action.settings, loading: false };
            return updated;
    }
    return state || initialExtensionUserSettings;
}
