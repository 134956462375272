import React, { FC, useState, useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import DropdownComponent from "../controls/DropdownComponent";
import CustomToggleSwitch from "../controls/CustomToggleSwitch";
import { useSelector } from "react-redux";
import { ApplicationState } from "src/store";
import { IDocumentNotificationSetting } from "../TaxReturn";

interface IReminderOptionState {
    voucher: number,
    signing: number
}

interface IReminderToggleState {
    voucher: boolean,
    signing: boolean
}

interface IScheduledReminderProps {
    selectedDocumentIds: number[];
    setSchedulePayload: React.Dispatch<any>;
    documentSettings: IDocumentNotificationSetting;
    disableSigningReminder: boolean;
    disableVoucherReminder: boolean;
    totalCount: number;
}

const ScheduledReminder: FC<IScheduledReminderProps> = ({
    selectedDocumentIds,
    setSchedulePayload,
    documentSettings,
    disableSigningReminder,
    disableVoucherReminder,
    totalCount }) => {

    const [selectedReminderOption, setSelectedReminderOption] = useState<IReminderOptionState>({ voucher: 1, signing: 1 }),
        [checkedReminderToggle, setCheckedReminderToggle] = useState<IReminderToggleState>({ voucher: true, signing: true }),
        [isMultiple, setIsMultiple] = useState<boolean>(selectedDocumentIds.length > 1),
        { signingReminderNotificationSettings } = useSelector((state: ApplicationState) => state.extensionCompanyData.companySettings.notificationSettingsModel);

    useEffect(() => {
        const payload = {
            signingReminder: {
                enabled: checkedReminderToggle.signing,
                reminderDays: selectedReminderOption.signing
            },
            voucherReminder: {
                enabled: checkedReminderToggle.voucher,
                reminderDays: selectedReminderOption.voucher
            }
        };
        setSchedulePayload(payload);
    }, [selectedReminderOption, checkedReminderToggle]);

    useEffect(() => {
        if (documentSettings) {
            const voucherReminder = documentSettings.paymentVoucherNotificationSettingsModel;
            const signingReminder = documentSettings.signingReminderNotificationSettingsModel;

            setSelectedReminderOption({ voucher: voucherReminder.noOfDaysForVoucherReminder, signing: signingReminder.noOfDaysForSigningReminder });
            setCheckedReminderToggle({ voucher: voucherReminder.enablePaymentVoucherReminder, signing: signingReminder.enableSigningReminder });
        }
    }, [documentSettings]);

    useEffect(() => {
        if (!signingReminderNotificationSettings.enableSigningReminder) {
            setCheckedReminderToggle((prev) => { return { ...prev, signing: false } });
        }
    }, [signingReminderNotificationSettings]);

    useEffect(() => {
        if (disableVoucherReminder) {
            setCheckedReminderToggle((prevState) => { return { ...prevState, voucher: false } });
        } else if (disableSigningReminder) {
            setCheckedReminderToggle((prevState) => { return { ...prevState, signing: false } });
        }
    }, [disableSigningReminder, disableVoucherReminder]);

    useEffect(() => {
        setIsMultiple(selectedDocumentIds.length > 1);
    }, [selectedDocumentIds]);

    const handleVoucherReminderChange = (value) => {
        setSelectedReminderOption({ ...selectedReminderOption, voucher: value });
    };

    const handleVoucherToggleChange = (e) => {
        setCheckedReminderToggle({ ...checkedReminderToggle, voucher: e.target.checked });
    };

    const handleSigningReminderChange = (value) => {
        setSelectedReminderOption({ ...selectedReminderOption, signing: value });
    };

    const handleSigningToggleChange = (e) => {
        setCheckedReminderToggle({ ...checkedReminderToggle, signing: e.target.checked });
    };

    const getReminderOptions = () => {
        let reminderOptions = [];
        for (let i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + ' day' : i + ' days' });
        }
        return reminderOptions;
    };

    const signingReminderDescription = () => (
        <div className="mt-5">
            <div className="text-danger mb-3">Please Note!</div>
            <div>
                Signing Reminder will be sent for statuses: “Awaiting E-Sign”, “Awaiting Upload”, “Partially Signed”.
            </div>
        </div>
    );

    const voucherReminderDescription = () => (
        <div className="mt-5">
            <div className="text-danger mb-3">Please Note!</div>
            <div className="mb-2">Voucher reminders cannot be delivered for the extensions due to one of the following reasons:</div>
            <ul className="ml-4">
                <Row>
                    <Col>
                        <li>Tax Payer has disabled the settings</li>
                        <li>All voucher payments flagged as paid</li>
                        <li>Expired Vouchers</li>
                    </Col>
                    <Col>
                        <li>No voucher found for the return</li>
                        <li>Disabled on return delivery</li>
                    </Col>
                </Row>
            </ul>
        </div>
    );

    return <div className="py-4">
        {isMultiple && <Alert variant='primary'>
            <i className="fas fa-info-circle mr-2"></i>
            {totalCount} Extensions selected.
        </Alert>}
        <div className="px-4">
            <div className="mb-5">
                <div><b>Voucher</b></div>
                <Row className="align-items-center" noGutters>
                    <Col xs={4}>Send a reminder</Col>
                    <Col xs={3}>
                        <DropdownComponent
                            id="ddlVoucherReminder"
                            options={getReminderOptions()}
                            onChange={handleVoucherReminderChange}
                            selectedValue={selectedReminderOption.voucher}
                            disabled={!checkedReminderToggle.voucher}
                            clearable={true} />
                    </Col>
                    <Col className="ml-3" xs={3}>
                        before due date
                    </Col>
                    <Col>
                        <CustomToggleSwitch
                            switched={checkedReminderToggle.voucher}
                            handleChange={handleVoucherToggleChange}
                            disabled={disableVoucherReminder} />
                    </Col>
                </Row>
                {isMultiple ? voucherReminderDescription() : disableVoucherReminder && voucherReminderDescription()}
            </div>

            <div>
                <div><b>Signing</b></div>
                <Row className="align-items-center" noGutters>
                    <Col xs={4}>Send a reminder every</Col>
                    <Col xs={3}>
                        <DropdownComponent
                            id="ddlVoucherReminder"
                            options={getReminderOptions()}
                            onChange={handleSigningReminderChange}
                            selectedValue={selectedReminderOption.signing}
                            disabled={!checkedReminderToggle.signing}
                            clearable={true} />
                    </Col>
                    <Col className="ml-3" xs={3}>
                        since last reminder / initial mail
                    </Col>
                    <Col>
                        <CustomToggleSwitch
                            switched={checkedReminderToggle.signing}
                            handleChange={handleSigningToggleChange}
                            disabled={!signingReminderNotificationSettings.enableSigningReminder || disableSigningReminder} />
                    </Col>
                </Row>
                {isMultiple ? signingReminderDescription() : disableSigningReminder && <div className="mt-5">
                    <div className="text-danger mb-3">Please Note!</div>
                    <div>
                        User cannot update reminders for the signature statues “E-Signed”, “Uploaded”, “Manually Signed”, "Signed & E-Signed", "Awaiting Review", "Partially Reviewed" ,"Reviewed" 
                    </div>
                </div>}
            </div>
        </div>
    </div>
};

export default ScheduledReminder;