import moment from "moment";
import React from  'react';
import { Alert, Button, Col, FormLabel, FormControl, Overlay, OverlayTrigger, /*Panel,*/ Popover, Row, Table, Accordion, Card } from 'react-bootstrap';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { CustomType, DocumentGroups, getAllStateImages, getVoucherTypes, IFormBase, ITaxingAuthority, ITaxReturn, IVoucher, StateUSA, VoucherNo, VoucherMode, VoucherTypes, TaxFormType } from '../../../common/TaxReturn';
import { VoucherTabConstants } from '../../../helper/Constants';
import { formatCurency } from '.././../../helper/HelperFunctions';
import { AddMultiplePayment } from '../../../common/ProcessReturnModal/LeftSidebar/AddMultiplePayment';
import { VoucherPayment, VoucherEstimatedPayment } from '../../../svg/IconCollection';
import { DueDateType } from "src/Core/ViewModels/Extension/ExtensionComponentModels";


export interface IAddedVoucherProps {
    taxReturn: ITaxReturn;
    onAddVoucher: (voucher: IVoucher) => void;
    populateVoucherPanel: (voucher: IVoucher) => void;
    voucherStore: IVoucherState;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onVoucherDelete: (voucher: IVoucher) => void;
    addedVoucherList: IVoucher[];
    onAddMultipeVouchers?: (vouchers: IVoucher[]) => void;
}
export interface IAddedVoucherState {
    voucher: IVoucher;
    showAddVoucherView: boolean;
    popoverTarget: any;
    showAlert: boolean;
    message: string;
    expandAddedVoucherListPanel: boolean;
    enableLeftPanelScroll: boolean;
}

const initialVoucher: Readonly<IVoucher> = {
    authorityID: 0,
    dueDate: undefined,
    amount: 0,
    voucherNo: VoucherNo.None,
    paymentType: VoucherTypes.None,
    customType: CustomType.None,
    onlinePaymentUri: "",
    customText: "",
    authorityImage: "",
    authorityName: "",
    groupId: undefined,
    formName: "",
    bookmark: "",
    pageNo: [],
    isMasterPaymentUrl: false,
    vocherMode: VoucherMode.None,
    isFormNameUpdated: false,
    formType: TaxFormType.Voucher,
    signatureControls: [],
    printedDueDate: new Date(),
    signatureEnable: false,
    voucherDueDateType: DueDateType.SSExtDefault,
    voucherEnable: false,
    id: 0
}

export class AddedVoucherList extends React.Component<IAddedVoucherProps, IAddedVoucherState>
{
    constructor(props: IAddedVoucherProps) {
        super(props);
        this.state = {
            voucher: initialVoucher,
            showAddVoucherView: false,
            popoverTarget: {},
            showAlert: false,
            message: '',
            expandAddedVoucherListPanel: true,
            enableLeftPanelScroll: true
        };

    }

    public closeDeleteConfirmPopHover = () => {
        document.body.click();
    }

    getTitle(authorities: ITaxingAuthority[], authorityId: number, amount: number, formName: IFormBase[]) {
        let authority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == authorityId)];
        if (authority) {
            return [
                <div title={authority.AuthorityName + " " + formName} className="bookmark-name wordbreak ellipsis">{authority.AuthorityName} {formName}</div>,
                <div className="bookmark-right client-info-payments" title={'$' + formatCurency(Number(amount))}
                    style={{
                        fontFamily: "'Lucida Console', 'Monaco', 'monospace'",
                        color: '#ff0000'
                    }}>
                    {'($' + formatCurency(Number(amount)) + ')'}
                </div>
            ]
        } else {
            return null;
        }
    }

    private getVoucherClass = (voucher: IVoucher) => {
        let voucherClass: string = "payment-voucher";
        if (getVoucherTypes(voucher.voucherNo) ===
            VoucherTypes.EstimatedVoucher) {
            voucherClass = "estimated-voucher";
        }
        return voucherClass;
    }

    onDeleteVoucher = () => {
        this.props.onVoucherDelete(this.state.voucher);
        document.body.click();
    }


    enableScroll = () => {
        if (!this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftPanelScroll: true });
        }

    }

    stopScroll = () => {
        if (this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftPanelScroll: false });
        }

    }

    public render() {
        let authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        let addedVouchers: IFormBase[] = [];
        let voucherIndex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        if (this.props.taxReturn.formGroups[voucherIndex]) {
            addedVouchers = this.props.taxReturn.formGroups[voucherIndex].forms.filter((value: IFormBase, index: number) => {
                return (value as IVoucher).vocherMode !== VoucherMode.Recognized
            });
        }

        const deletePopover = (<Popover
            id="popover-voucher-delete-confirm"
            style={{ fontSize: '14px' }}>
            <Popover.Title as="h3" style={{ fontSize: '14px' }}>{`Confirm Delete`}</Popover.Title>
            <Popover.Content>
                {VoucherTabConstants.AddedVoucher.DeleteConfirm}
                <button
                    className="btn btn-only btn-success"
                    onClick={this.onDeleteVoucher}
                    title="Yes"
                    style={{ marginLeft: "5px" }}
                    data-test-auto="8198C695-5D0C-47CD-9DC3-5A44235743EF">
                    Yes
                    </button>
                <button
                    onClick={this.closeDeleteConfirmPopHover}
                    title="No"
                    className="btn btn-only btn-danger"
                    style={{ marginLeft: "5px" }}
                    data-test-auto="6A358AC4-7DDB-4DA2-819B-182E41B7F7CF">
                    No
                    </button>
            </Popover.Content>
        </Popover>
        )

        return <div className="bookmarksPane">
            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Card.Header as="h4">
                        <a aria-expanded="true" role="button" href="#">  Manually Added Vouchers</a>
                        <span className="pull-right">
                            <div>
                                <AddMultiplePayment
                                    onAddVoucher={this.props.onAddVoucher}
                                    taxReturn={this.props.taxReturn}
                                    voucherStore={this.props.voucherStore}
                                    populateVoucherPanel={this.props.populateVoucherPanel}
                                    getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                    onAddMultipeVouchers={this.props.onAddMultipeVouchers}
                                />
                            </div>
                        </span>
                    </Card.Header>
                    <Card.Body style={{ padding: 0 }}>
                        <div id="manual-vouchers" className="pageListView">
                            {
                                addedVouchers.length == 0 ? <div className="textalign-center">No Content</div> :

                                    (addedVouchers.map((value: any, index: number) => {
                                        return (<div
                                            key={index}
                                            className="custom-list-group-item bookmark-item " >
                                            <div id={"m-voucher" + index} className="bookmark-row">
                                                <div style={{ width: "24px" }}>

                                                    {(this.getVoucherClass(value) == "estimated-voucher") ?
                                                        <VoucherEstimatedPayment className={"bookmark-icon-image"} /> :
                                                        <VoucherPayment className={"bookmark-icon-image"} />}
                                                </div>
                                                <div className="cursor-pointer" style={{ display: "inline-flex", width: "calc(100% - 46px)" }}
                                                    onClick={() => { this.props.populateVoucherPanel(value); }}>
                                                    {
                                                        this.getTitle(authorities, value.authorityID, value.amount, value.formName)
                                                    }
                                                </div>

                                                <OverlayTrigger
                                                    rootClose trigger="click"
                                                    onEnter={() => document.body.click()}
                                                    onEntered={() => { this.stopScroll() }}
                                                    onExit={() => { this.enableScroll() }}
                                                    placement="right"
                                                    overlay={deletePopover}>
                                                    <div style={{ width: "24px" }}>
                                                        <i className="padL8 glyphicon glyphicon-remove cursor-pointer text-danger"
                                                            onClick={() => { this.setState({ voucher: value }) }}
                                                            title={"Delete Voucher"}>
                                                        </i>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>)
                                    }))
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Accordion>
        </div>
    }
};
