import React from  'react';
import { Modal, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { ClientTypes, ITaxReturn, DocumentEvent, EngagementType, SignatureStatus, ClientTypesNumber, isMutual } from '../common/TaxReturn';

let moment = require('moment');
import { OverlayLoader } from '../helper/OverlayLoader';

const NO_INDEX = -1;

interface IViewAccessCodeProps {
    taxReturn: ITaxReturn;
    onCancel(): void;
    show: boolean;
    onGenerateOTP(documentId: number, clientType: ClientTypes, clientGUID: string, callback: () => void): void;
}

interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    width: string,
    toolTip: boolean,
}
interface IColumnValues {
    signersName: string,
    createdOn: Date,
    OTP: string,
    status: string,
    documentId: number,
    clientType: ClientTypes,
    clientGUID: string,
    date: Date,
}

const msg = {
    saving: 'Generating OTP...',
    loading: 'Loading, please wait...'
}

interface IViewAccessCodeState {
    message: string;
    isOtpGenerated: boolean;
    alert: string;
    hideAlert: boolean;
    alertMessage: string;
}

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
}

export class ViewAccessCode extends React.Component<IViewAccessCodeProps, IViewAccessCodeState>{
    constructor(props: any) {
        super(props);
        this.state = {
            message: msg.loading,
            isOtpGenerated: false,
            alert: "success",
            hideAlert: true,
            alertMessage: "",
        };
        this.defaultType = this.defaultType.bind(this);
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(props: IViewAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }

    public UNSAFE_componentWillUnmount() {
        this.setState({ isOtpGenerated: false, hideAlert: true })
    }

    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        return (!row.createdOn || this.getDifferenceInDate(row.createdOn) > 20)
            ? "NA" : moment.utc(row.createdOn).local().format('MM/DD/YYYY hh:mm:ss A')
    }
    private getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "NA" : row.OTP;
        }
        else {
            return "NA";
        }
    }
    private statusDataFormatType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "Expired" : "Active";
        }
        else if (row.OTP == "NA" && this.props.taxReturn.accessCode.clientEvents != undefined &&
            this.props.taxReturn.accessCode.clientEvents.find(x => x.eventId == DocumentEvent.TaxReturnAccessed) != undefined &&
            this.props.taxReturn.accessCode.clientEvents.some(x => x.eventId == DocumentEvent.TaxReturnAccessed &&
                x.actedBy.firstName == row.signersName)) {
            return "Authenticated"
        }
        else if (row.OTP == "NA" && this.props.taxReturn.accessCode.clientEvents != undefined && ((this.props.taxReturn.accessCode.clientEvents.length == 0 ||
            this.props.taxReturn.accessCode.clientEvents.find(x => x.eventId == DocumentEvent.AccessCodeEmail) == undefined)
            || this.props.taxReturn.accessCode.clientEvents.find(x => x.eventId == DocumentEvent.AccessCodeEmail &&
                x.actedBy.firstName == row.signersName) == undefined)) {
            return "Not Requested"
        }
    }
    private generateAccessCodeToolTip = () => {
        return "Generate Access Code";
    }

    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Signer's Name",
                key: 'signersName',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                columnClassName: 'word-Visible',

            },
            {
                header: "Recipient Details",
                key: 'recipientEmail',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                columnClassName: 'word-Visible',

            },
            {
                header: "Client ID",
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                columnClassName: 'word-Visible',

            },
            {
                header: 'Date',
                key: 'createdOn',
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Access Code',
                key: 'OTP',
                isKey: false,
                dataFormat: this.getAccessCodeDataType,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusDataFormatType,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Action',
                key: 'action',
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this.generateAccessCodeToolTip,
                columnClassName: '',
            }
        ];

        let minDate = new Date();
        minDate.setFullYear(1, 0, 1);
        minDate.setHours(0, 0, 0, 0);

        if (this.props.taxReturn && this.props.taxReturn.accessCode && this.props.taxReturn.accessCode.accessCodeDetails.length > 0) {
            viewAccessCodeData = this.getAccessCodeInfo(this.props.taxReturn).accessCode.accessCodeDetails.map((value, index) => {
                return {
                    signersName: value.name,
                    clientId: this.props.taxReturn.clientId,
                    recipientEmail: value.recipientEmail,
                    createdOn: Date.parse(new Date(value.createdOn).toString()) == Date.parse(minDate.toString()) ? null : value.createdOn,
                    OTP: !value.otp ? "NA" : value.otp,
                    documentId: value.documentId,
                    clientType: value.clientType,
                    clientGUID: value.clientGuid,
                    date: value.createdOn,
                }
            })
        }
        let modalBody = (this.props.taxReturn && this.props.taxReturn.accessCode) ?
            (<BootstrapTable headerStyle={{ background: 'lightgray' }} data={viewAccessCodeData}>
                {viewAccessCodeColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        columnClassName={value.columnClassName}
                        columnTitle={value.toolTip}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable>) : (<OverlayLoader
                show={this.props.show}
                text={this.state.message}
                width='94%' />);
        return <div>
            <Modal className="view-access-code-modal" show={this.state.isOtpGenerated ? true : this.props.show} onHide={this.onCancel}>
                <Modal.Header closeButton onClick={this.onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
                    <Modal.Title><span className='text-secondary fas fa-clock' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Access Code</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Alert variant={this.state.alert} hidden={this.state.hideAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.alertMessage}</Alert>
                        {modalBody}
                    </Modal.Body>
                </Form>
                <Modal.Footer>
                    <Row style={{ width: "100%" }}>
                        <Col className="access-code-note" sm={8}>
                            Note: Generating a new code will not send a new email
                        </Col>
                        <Col sm={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                variant="default"
                                data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                                onClick={this.onCancel}>
                                <i className='fas fa-times'></i>Cancel
                             </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>;
    }
    private getAccessCodeInfo = (taxReturn: ITaxReturn): ITaxReturn => {
        if (taxReturn.engagementType.toString() == EngagementType[EngagementType.E1040]) {
            let index = NO_INDEX;
            if (taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ESigned] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.Uploaded] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.SignedAndESigned] &&
                taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ManuallySigned]) {

                if (taxReturn.documentSettings.deliverySettings && taxReturn.accessCode.clientEvents && taxReturn.signedDetails
                    && taxReturn.signedDetails.filter(x => x.signerType == ClientTypesNumber.Taxpayer)[0]?.signerStatus == 0
                    && taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientType == ClientTypesNumber.Spouse);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                    }
                }
                else if (taxReturn.documentSettings.deliverySettings && taxReturn.accessCode.clientEvents && taxReturn.signedDetails
                    && taxReturn.signedDetails.filter(x => x.signerType == ClientTypesNumber.Spouse)[0]?.signerStatus == 0
                    && taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientType == ClientTypesNumber.Taxpayer);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                    }
                }
            }
            else {
                if (taxReturn.documentSettings.deliverySettings &&
                    taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                    if (isMutual(taxReturn)) {
                        if (taxReturn.spouse.isDeceased) {
                            index = taxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientType == ClientTypesNumber.Spouse);
                            if (index > NO_INDEX) {
                                taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                            }
                        }
                    }
                    return taxReturn;
                }
                else {
                    index = taxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientType == ClientTypesNumber.Spouse);
                    if (index > NO_INDEX) {
                        taxReturn.accessCode.accessCodeDetails.splice(0, 0, taxReturn.accessCode.accessCodeDetails.splice(index, 1)[0])
                    }
                    if (isMutual(taxReturn)) {
                        if (taxReturn.taxpayer.isDeceased) {
                            index = taxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientType == ClientTypesNumber.Taxpayer);
                            if (index > NO_INDEX) {
                                taxReturn.accessCode.accessCodeDetails.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }
        return taxReturn;
    }

    private defaultType(cell: any, row: IColumnValues) {
        return cell;
    }


    private generateOTPButton(cell: any, row: IColumnValues) {

        return <Link to={"#"} onClick={(event) => { this.onGenerateOTP(row) }}> {"Generate Access Code"}</Link >;
    }

    private onGenerateOTP(row: IColumnValues) {
        this.setState({
            message: msg.saving,
            isOtpGenerated: true,
            hideAlert: true,
        },
            () => {
                this.props.onGenerateOTP(
                    row.documentId,
                    row.clientType,
                    row.clientGUID,
                    this.triggerAlert);
            });
    }

    private getDifferenceInDate(createdDate: Date) {
        const date1 = new Date();
        const date2 = moment.utc(createdDate).toDate();
        const minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute;
    }
    private onCancel = () => {
        this.setState({
            isOtpGenerated: false,
            hideAlert: true,
        });
        this.props.onCancel();
    }
    private triggerAlert = () => {
        this.setState({
            hideAlert: false,
            alertMessage: successMsg.generatedAccessCode,
            alert: "success",
        });
    }
}

export default ViewAccessCode;
