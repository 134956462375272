import React from  'react';
import { /*Panel,*/ Row, Col, Button, Card, Accordion } from 'react-bootstrap';
import * as TaxDocument from '../../../common/TaxReturn';
import { RadioButtonComponent } from '../../../common/RadioButtonComponent';
import { ITaxpayer } from '../../../common/TaxClient';
import { validateSpouseEmail, validateTaxPayerEmail } from '../../../helper/HelperFunctions';
import { DocumentGroups, IEFile, IVoucher, SignatureControlRole, SignatureType } from '../../../common/TaxReturn';
import { IAdditionalESignDocumentControl, IDocumentData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { ProcessReturnConstants } from '../../../helper/Constants';
import { removeAllUserSignature } from '../SignatureControls/SignatureHelper';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';

interface IDeliverToClient {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    deliverToClient: () => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    extensionCompanySettings: ExtensionCompanySettings;
}

interface IDeliverToClientState {
    taxpayerChecked: boolean;
    spouseChecked: boolean;
}

export class DeliverToClient extends React.Component<IDeliverToClient, IDeliverToClientState> {
    constructor(props: IDeliverToClient) {
        super(props);
        this.state = {
            taxpayerChecked: true,
            spouseChecked: false,
        }
        this.onChangeTaxpayer = this.onChangeTaxpayer.bind(this);
        this.onChangeSpouse = this.onChangeSpouse.bind(this);
    }
    public onChangeTaxpayerType(event: any, taxpayerType: TaxDocument.ClientTypes) {

        this.props.taxreturn.documentSettings.deliverySettings.deliverTo = taxpayerType;
        this.props.updateTaxDocument(this.props.taxreturn);

        this.setState({
            taxpayerChecked: taxpayerType == TaxDocument.ClientTypes.Taxpayer ? true : false,
            spouseChecked: taxpayerType == TaxDocument.ClientTypes.Spouse ? true : false,
        })
    }
    public onChangeTaxpayer() {
        this.setState({
            taxpayerChecked: true,
            spouseChecked: false,
        })
    }
    public onChangeSpouse() {
        this.setState({
            taxpayerChecked: false,
            spouseChecked: true,
        })
    }
    validateSignature = () => {
        let tempFormsGroup = { ...this.props.taxreturn };
        let formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        let voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        let hasEsign: boolean = false;

        if (formIndex > -1) {
            let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
            efileForms.forEach(efileForm => {
                let controls: TaxDocument.ISignatureControl[] = efileForm.signatureControls;
                controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                    x.signatureControlRole === SignatureControlRole.Spouse ||
                    x.signatureControlRole === SignatureControlRole.PartnerShip);

                if (controls?.length > 0) { hasEsign = true; }
            });
        }
        if (voucherIndex > -1) {
            let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
            voucherForms.forEach(voucherForm => {
                let controls: TaxDocument.ISignatureControl[] = (voucherForm.signatureControls);
                controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                    x.signatureControlRole === SignatureControlRole.Spouse ||
                    x.signatureControlRole === SignatureControlRole.PartnerShip);
                if (controls?.length > 0) { hasEsign = true; }
            });
        }

        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
        if (additionalForms && additionalForms.length) {
            additionalForms.forEach(additionalForm => {
                additionalForm.documentControls.forEach(y => {
                    let controls: IAdditionalESignDocumentControl[] = y.controls;
                    controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                        x.signatureControlRole === SignatureControlRole.Spouse ||
                        x.signatureControlRole === SignatureControlRole.PartnerShip);
                    if (controls?.length > 0) { hasEsign = true; }
                });
            });
        }
        if (!hasEsign) {
            VenusNotifier.Warning(ProcessReturnConstants.NoSignatureMessage, "");
            return false;
        }
        return true;
    }

    removeAllSignature = () => {
        this.props.taxreturn.formGroups = removeAllUserSignature(this.props.taxreturn);
        this.forceUpdate();
    }
    public onDeliverToClient = () => {

        if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            if (!this.validateSignature()) {
                return false;
            }
        } else if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ReviewOnly) {
            this.removeAllSignature();
        }

        if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ManualSign) {

            let tempTaxReturn: any = { ...this.props.taxreturn };

            if (TaxDocument.isIndividual(tempTaxReturn)) {

                let tempFormsGroup: TaxDocument.IIndividualTaxReturn = { ...this.props.taxreturn as TaxDocument.IIndividualTaxReturn };
                let formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
                let voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
                let additionalIndex = tempFormsGroup.additionalEsignDocuments.length;

                if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                        efileForms.forEach(efileForm => {
                            if (!tempTaxReturn.taxpayer.isDeceased) { efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer); }
                        })
                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    if (voucherIndex > -1) {
                        let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                        voucherForms.forEach(voucherForm => {
                            if (!tempTaxReturn.taxpayer.isDeceased) { voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer); }
                        })
                        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                    }
                    if (additionalIndex > 0) {
                        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                        additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                            if (!tempTaxReturn.taxpayer.isDeceased) { documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Taxpayer); }
                        }))
                        tempFormsGroup.additionalEsignDocuments = additionalForms;
                    }
                }
            }
            else if (TaxDocument.isMutual(tempTaxReturn)) {

                let tempFormsGroup: TaxDocument.IMarriedJointTaxReturn = { ...this.props.taxreturn as TaxDocument.IMarriedJointTaxReturn };
                let formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
                let voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
                let additionalIndex = tempFormsGroup.additionalEsignDocuments.length;

                if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                        efileForms.forEach(efileForm => {
                            if (!tempTaxReturn.spouse.isDeceased) { efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse); }
                            if (!tempTaxReturn.taxpayer.isDeceased) { efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer); }
                        })
                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    if (voucherIndex > -1) {
                        let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                        voucherForms.forEach(voucherForm => {
                            if (!tempTaxReturn.spouse.isDeceased) { voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse); }
                            if (!tempTaxReturn.taxpayer.isDeceased) { voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer); }
                        })
                        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                    }
                    if (additionalIndex > 0) {
                        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                        additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                            if (!tempTaxReturn.spouse.isDeceased) { documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Spouse); }
                            if (!tempTaxReturn.taxpayer.isDeceased) { documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Taxpayer); }
                        }))
                        tempFormsGroup.additionalEsignDocuments = additionalForms;
                    }
                }
            }
        }

        if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse) {
            if (validateSpouseEmail(this.props.taxreturn)) {
                this.props.deliverToClient();
            }
        } else if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Taxpayer) {
            if (validateTaxPayerEmail(this.props.taxreturn)) {
                this.props.deliverToClient();
            }
        } else {
            this.props.deliverToClient();
        }
    }

    public render() {
        let deceasedCount = 0;

        let taxpayer: ITaxpayer = {} as ITaxpayer;
        if (TaxDocument.isMutual(this.props.taxreturn)) {
            if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse) {
                taxpayer = this.props.taxreturn.spouse;
            }
            else {
                taxpayer = this.props.taxreturn.taxpayer;
            }
            if (this.props.taxreturn.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (this.props.taxreturn.spouse && this.props.taxreturn.spouse.isDeceased) {
                deceasedCount += 1;
            }
        }
        else if (TaxDocument.isIndividual(this.props.taxreturn)) {
            taxpayer = this.props.taxreturn.taxpayer;
        }
        return <div className="padT04">
            {
                <Accordion defaultActiveKey={"0"}>
                    <Card className="marR20 border-radius-04">
                        <Card.Body>
                            {

                                TaxDocument.isMutual(this.props.taxreturn) ?
                                    <div>
                                        <Row className="marB15">
                                            <Col sm={6}>
                                                <RadioButtonComponent text="Taxpayer"
                                                    id="taxpayer"
                                                    checked={this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Taxpayer ? true : false}
                                                    onClick={(event) => { this.onChangeTaxpayerType(event, TaxDocument.ClientTypes.Taxpayer) }}
                                                    disabled={(deceasedCount != 2 && this.props.taxreturn.taxpayer.isDeceased)}
                                                    dataTestAuto={"2C32A8F2-43EB-4D6A-AEC5-65D819597059"}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <RadioButtonComponent text="Spouse"
                                                    id="spouse"
                                                    checked={this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse ? true : false}
                                                    onClick={(event) => { this.onChangeTaxpayerType(event, TaxDocument.ClientTypes.Spouse) }}
                                                    disabled={(deceasedCount != 2 && this.props.taxreturn.spouse.isDeceased)}
                                                    dataTestAuto={"85C0D749-1CF3-4AC6-AD31-E2C31297AEB5"}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    : TaxDocument.isIndividual(this.props.taxreturn) ?
                                        <div>
                                            <Row className="marB15">
                                                <Col sm={12}>
                                                    <RadioButtonComponent text="Taxpayer" id="taxpayer" checked={true} />
                                                </Col>
                                            </Row>
                                        </div>
                                        : ""
                            }
                            <div>
                                <Row className="marB15">
                                    <Col className="pull-right text-right" sm={4} as={Col}>
                                        Name
                                    </Col>
                                    <Col className="pull-right" sm={8} as={Col}>
                                        {taxpayer.name}
                                    </Col>


                                </Row>
                                <Row className="marB15">
                                    <Col className="pull-right text-right" sm={4} as={Col}>
                                        SSN
                                    </Col>
                                    <Col className="pull-right" sm={8} as={Col}>
                                        {taxpayer.ssn}
                                    </Col>

                                </Row>
                                <Row className="marB15">
                                    <Col className="pull-right text-right" sm={4} as={Col}>
                                        Address
                                    </Col>
                                    <Col className="pull-right" sm={8} as={Col}>
                                        {taxpayer.address}
                                    </Col>

                                </Row>
                                <Row className="marB15">
                                    <Col className="pull-right text-right" sm={4} as={Col}>
                                        Email
                                    </Col>
                                    <Col className="pull-right" sm={8} as={Col}>
                                        {taxpayer.email}
                                    </Col>


                                </Row>
                            </div>

                            <div>
                                <Col sm={12}>
                                    <Button type="button" variant="info" className="pull-right" data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                                        onClick={this.onDeliverToClient}
                                    ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                                    <Button type="button" variant="light" className="pull-right marR05" data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                                        onClick={this.props.cancelFinish}
                                    ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                                </Col>
                            </div>
                        </Card.Body>
                    </Card>
                </Accordion>
            }
        </div>
    }
}

export default DeliverToClient;