import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from "../ActionTypes";
import {
    ITaxReturn, initialTaxReturnState, updateTaxFormType,
    ClientTypes, DocumentStatus, PaperReturnDeliveryType,
    DocumentGroups, IAccessCodeViewModel, DeliveryMode
} from "../../components/common/TaxReturn";
import {
    RequestSignedDetailsAction, ReceiveDocumentStatusAction,
    ReceiveSignedDetailsAction, MakeAvailableTaxDocumentAction,
    GetTaxCaddyLookupDetails, K1Instruction, ClearTaxCaddyLookupDetails,
    ResetTaxDocument,
    getProcessReturnSucessStatusMessage, getProcessReturnFailureStatusMessage,
    ReceiveDownloadableDocumentsAction, ReceiveDownloadableEfileFormsAction,
    ReceiveDownloadHistoryAction
} from '../common/TaxDocumentStore';
import { handleResponse } from '../Library';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { GroupedTaxDocumentConstants } from '../../components/helper/Constants';
import { ISubDocument, SubDocType } from '../../Core/Utilities/PdfDocumentFacade';
import { processReturnActionEndPoints } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import * as Constants from '../../components/helper/Constants'
import * as Notification from '../common/NotificationStore';
//import { ReceiveDeliveredGroupedReturnsAction, ResetDeliveredGroupedReturnsAction } from '../reports/GroupedReturns/DeliveredGroupedReturns/DeliveredGroupedReturnsStore';

import {
    ErrorGroupedTaxDocumentAction,
    GroupedTaxDocumentStoreState, MarkAsReadyForDeliveryAction, ReceiveGroupedTaxDocumentAction, ReceiveSendGroupedReturnsAction,
    RequestGroupedTaxDocumentAction,
    ResetSendGroupedReturnsAction, unloadedGroupedTaxDocumentStoreState, UpdateGroupedTaxDocumentAssignAction, UpdateGroupedTaxDocumentStatusAction, UpdateSendGroupedReturnsDocumentAssignUpdate, UpdateSendGroupedReturnsDocumentStatusUpdate
} from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { GroupInfo } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction =
    RequestGroupedTaxDocumentAction |
    ReceiveGroupedTaxDocumentAction |
    ErrorGroupedTaxDocumentAction |
    MarkAsReadyForDeliveryAction |
    UpdateGroupedTaxDocumentStatusAction |
    UpdateSendGroupedReturnsDocumentStatusUpdate |
    //ResetDeliveredGroupedReturnsAction |
    ResetSendGroupedReturnsAction |
    UpdateGroupedTaxDocumentAssignAction |
    UpdateSendGroupedReturnsDocumentAssignUpdate |
    NotificationAction;

type DispatchActions =
    RequestGroupedTaxDocumentAction |
    ReceiveGroupedTaxDocumentAction |
    ReceiveSendGroupedReturnsAction |
    //ReceiveDeliveredGroupedReturnsAction |
    ErrorGroupedTaxDocumentAction |
    RequestSignedDetailsAction |
    ReceiveDocumentStatusAction |
    ReceiveSignedDetailsAction |
    MakeAvailableTaxDocumentAction |
    K1Instruction |
    GetTaxCaddyLookupDetails |
    ClearTaxCaddyLookupDetails |
    ResetTaxDocument |
    MarkAsReadyForDeliveryAction |
    ReceiveDownloadableDocumentsAction |
    ReceiveDownloadableEfileFormsAction |
    UpdateGroupedTaxDocumentStatusAction |
    UpdateGroupedTaxDocumentAssignAction |
    ReceiveDownloadHistoryAction;

export const actionCreators = {
    requestGroupedTaxDocument: (id: number, force: boolean = false, requestTaxPayerView?: boolean, clientType?: ClientTypes, callback?: (data: any) => void, isEditClientInfoRequest?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        if (isEditClientInfoRequest === undefined) {
            isEditClientInfoRequest = false;
        }
        if (force ||
            !state.taxDocuments[id] ||
            !state.taxDocuments[id].taxReturn ||
            state.taxDocuments[id].taxReturn.id === 0) {
            const fetchTask = fetch(`${API_BASE_URL}api/GroupedTaxDocument/` + id + '/' + isEditClientInfoRequest, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ITaxReturn>)
                .then(data => {
                    //when ungrouped tax document is requested, data is empty object.
                    if (data.id) {
                        updateTaxFormType(data);
                        dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: id, taxDocument: data, isEditClientInfoRequest: isEditClientInfoRequest });
                        if (requestTaxPayerView) {
                            const action: any = DeliveredReturnsStore.actionCreators.generateTaxpayerView(data, clientType);
                            dispatch(action);
                        }
                        if (callback) {
                            callback(data);
                        }
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_GROUPED_TAX_DOCUMENT, ids: [id], message: error });
                    logger.trackError(`requestGroupedTaxDocument failed for id: ${id}, with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },

    markAsReadyForDelivery: (id: number, successCallback?: (id: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedTaxDocument/MarkReturnAsReadyForDeliveryAsync/` + id, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse)
            .then(() => {

                dispatch({ type: actionTypes.MARK_AS_READY_FOR_DELIVERY, id: id });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliverySuccess, statusType: StatusType.Success });
                if (successCallback) {
                    successCallback(id);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliveryError, statusType: StatusType.Error });
                logger.trackError(`markAsReadyForDelivery failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    updateGroupedTaxDocument: (taxReturn: ITaxReturn, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn });
        if (callback) {
            callback();
        }
    },

    sendControllertInfoMail: (groupInfo: GroupInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/SendControllerInfoMail/`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(groupInfo),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            }
        })
            .then(handleResponse)
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.SendControllerInfoMailConstants.StatusMessage.SendControllerMailError, statusType: Notification.StatusType.Error });
                logger.trackError(`sendControllertInfoMail failed for groupInfo: ${groupInfo}, with error ${error.message}`);
            });

    },
    //saveProcessGroupedTaxDocumentInfo: (taxReturn: ITaxReturn, subDocuments: ISubDocument[], taxDocumentsForDelivery: ISelectedDocumentForDeliveryViewModel[], isK1Replaced: boolean,
    //    isK1Restored: boolean, isMFJChanged: boolean, apiEndPoint?: string, callback?: (data?: any) => void,
    //    clientType?: ClientTypes, groupInfo?: IGroupInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //        let actualPath: string = subDocuments.filter(doc => { return doc.subDocType === SubDocType.Original })[0].path;
    //        subDocuments.map((doc, index) => {
    //            subDocuments[index].pageMap = doc.pageMap.filter(map => { return map.facadePageNum != -1 });
    //            subDocuments[index].path = subDocuments[index].path ? subDocuments[index].path :
    //                actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
    //            (subDocuments[index].document as any) = undefined; //Circular dependency error by stringify if not done.
    //        })
    //        let modelData: IProcessGroupedTaxDocumentViewModel = {
    //            taxDocument: taxReturn,
    //            parts: subDocuments,
    //            isK1Replaced: isK1Replaced,
    //            isK1Restored: isK1Restored,
    //            isMFJChanged: isMFJChanged,
    //            clientType: clientType,
    //            groupInfo: groupInfo,
    //            taxDocumentsForDelivery: taxDocumentsForDelivery
    //        }

    //        let endPoint: string = 'api/ProcessGroupedTaxDocument';
    //        if (apiEndPoint) {
    //            endPoint = endPoint + '/' + apiEndPoint;
    //        }

    //        let options: any = {
    //            method: 'PUT',
    //            credentials: 'include',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(modelData)
    //        };

    //        const fetchTask = fetch(endPoint, options)
    //            .then(handleResponse)
    //            .then((data) => {
    //                let action: any = actionCreators.updateGroupedTaxDocument(taxReturn);
    //                dispatch(action);
    //                if (apiEndPoint !== processReturnActionEndPoints.generateTaxpayerViewAsync) {
    //                    dispatch({
    //                        type: actionTypes.NOTIFICATION,
    //                        statusMessage: getProcessReturnSucessStatusMessage(apiEndPoint ? apiEndPoint : ""),
    //                        statusType: StatusType.Success
    //                    });
    //                }
    //                if (apiEndPoint == processReturnActionEndPoints.generateTaxpayerViewAsync) {
    //                    let action: any = actionCreators.requestGroupedTaxDocument(taxReturn.id, false);
    //                    dispatch(action);
    //                }

    //                if (apiEndPoint == processReturnActionEndPoints.deliverAsync && taxDocumentsForDelivery && taxDocumentsForDelivery.length > 0) {
    //                    let Ids = taxDocumentsForDelivery.map(x => x.taxDocumentId);
    //                    dispatch({
    //                        type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
    //                        ids: Ids,
    //                        status: DocumentStatus.PREPARINGFORDELIVERY
    //                    });
    //                    dispatch({
    //                        type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
    //                        ids: Ids,
    //                        status: DocumentStatus.PREPARINGFORDELIVERY
    //                    });
    //                    dispatch({
    //                        type: actionTypes.RESET_DELIVERED_GROUPED_RETURNS
    //                    });
    //                }
    //                if ((apiEndPoint == processReturnActionEndPoints.approveForDeliveryAsync
    //                    || apiEndPoint == processReturnActionEndPoints.sendForReviewAsync
    //                    || apiEndPoint == processReturnActionEndPoints.sendToEROAsync)
    //                    && taxDocumentsForDelivery && taxDocumentsForDelivery.length > 0) {
    //                    let Ids = taxDocumentsForDelivery.map(x => x.taxDocumentId);
    //                    dispatch({
    //                        type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
    //                        ids: Ids,
    //                        status: taxReturn.documentStatus,
    //                        assignedUser: taxReturn.assignedUser,
    //                        assignTo: taxReturn.assignTo
    //                    });
    //                    dispatch({
    //                        type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
    //                        ids: Ids,
    //                        status: taxReturn.documentStatus,
    //                        assignedUser: taxReturn.assignedUser,
    //                        assignTo: taxReturn.assignTo
    //                    });
    //                }

    //                if (callback) {
    //                    data ? callback(data) : callback();
    //                }
    //            })
    //            .catch(error => {
    //                dispatch({
    //                    type: actionTypes.NOTIFICATION,
    //                    statusMessage: getProcessReturnFailureStatusMessage(apiEndPoint ? apiEndPoint : ""),
    //                    statusType: StatusType.Error
    //                });
    //                if (callback) {
    //                    callback();
    //                }
    //            });
    //        addTask(fetchTask);
    //    },

    //groupedReturnsSendForReview: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    let endPoint: string = 'api/GroupLevelProcessing/SendForReviewAsync';
    //    let options: any = {
    //        method: 'PUT',
    //        credentials: 'include',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(groupedReturnsModel)
    //    };

    //    const fetchTask = fetch(endPoint, options)
    //        .then(handleResponse)
    //        .then((data) => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewSuccess,
    //                statusType: Notification.StatusType.Success
    //            })
    //            if (callback) {
    //                data ? callback(data) : callback();
    //            }
    //            let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
    //            let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
    //            dispatch({
    //                type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.REVIEW,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //            dispatch({
    //                type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.REVIEW,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //        })
    //        .catch(error => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewError,
    //                statusType: Notification.StatusType.Error
    //            });
    //            if (callback) {
    //                callback();
    //            }

    //        });
    //    addTask(fetchTask);
    //},

    //groupedReturnsSendToERO: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    let endPoint: string = 'api/GroupLevelProcessing/SendToEROAsync';
    //    let options: any = {
    //        method: 'PUT',
    //        credentials: 'include',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(groupedReturnsModel)
    //    };

    //    const fetchTask = fetch(endPoint, options)
    //        .then(handleResponse)
    //        .then((data) => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewSuccess,
    //                statusType: Notification.StatusType.Success
    //            })
    //            if (callback) {
    //                data ? callback(data) : callback();
    //            }
    //            let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
    //            let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
    //            dispatch({
    //                type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.REVIEW,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //            dispatch({
    //                type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.REVIEW,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //        })
    //        .catch(error => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.SendForReviewError,
    //                statusType: Notification.StatusType.Error
    //            });
    //            if (callback) {
    //                callback();
    //            }

    //        });
    //    addTask(fetchTask);
    //},

    //groupedReturnsApproveForDelivery: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    let endPoint: string = 'api/GroupLevelProcessing/ApproveForDeliveryAsync';
    //    let options: any = {
    //        method: 'PUT',
    //        credentials: 'include',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(groupedReturnsModel)
    //    };

    //    const fetchTask = fetch(endPoint, options)
    //        .then(handleResponse)
    //        .then((data) => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.ApproveForDeliverySuccess,
    //                statusType: Notification.StatusType.Success
    //            })
    //            if (callback) {
    //                data ? callback(data) : callback();
    //            }
    //            let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
    //            let document: ITaxReturn[] = groupedReturnsModel.taxReturns.map(item => item as ITaxReturn);
    //            dispatch({
    //                type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.APPROVEDFORDELIVERY,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //            dispatch({
    //                type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN,
    //                ids: Ids,
    //                status: DocumentStatus.APPROVEDFORDELIVERY,
    //                assignedUser: document[0].assignedUser,
    //                assignTo: document[0].assignTo
    //            });
    //        })
    //        .catch(error => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.ApproveForDeliveryError,
    //                statusType: Notification.StatusType.Error
    //            });
    //            if (callback) {
    //                callback();
    //            }

    //        });
    //    addTask(fetchTask);
    //},

    //groupedReturnsDeliverToController: (groupedReturnsModel: IGroupedReturnsModel, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    let endPoint: string = 'api/GroupLevelProcessing/DeliverAsync';
    //    let options: any = {
    //        method: 'PUT',
    //        credentials: 'include',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json',
    //        },
    //        body: JSON.stringify(groupedReturnsModel)
    //    };

    //    const fetchTask = fetch(endPoint, options)
    //        .then(handleResponse)
    //        .then((data) => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientSuccess,
    //                statusType: Notification.StatusType.Success
    //            })
    //            if (callback) {
    //                data ? callback(data) : callback();
    //            }
    //            let Ids = groupedReturnsModel.taxReturns.map((x: any) => x.id);
    //            dispatch({
    //                type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS,
    //                ids: Ids,
    //                status: DocumentStatus.PREPARINGFORDELIVERY
    //            });
    //            dispatch({
    //                type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS,
    //                ids: Ids,
    //                status: DocumentStatus.PREPARINGFORDELIVERY
    //            });
    //        })
    //        .catch(error => {
    //            dispatch({
    //                type: actionTypes.NOTIFICATION,
    //                statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
    //                statusType: Notification.StatusType.Error
    //            });
    //            if (callback) {
    //                callback();
    //            }

    //        });
    //    addTask(fetchTask);
    //},

    //PrintForPaperDeliveryAsync: (groupedReturnsModel: IGroupedReturnsModel, paperReturnDeliveryType: PaperReturnDeliveryType,
    //    orderList: DocumentGroups[], isNotifyUserEnabled: boolean, isSaveAsDefaultOrder: boolean, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //        let modelData: IPrintForPaperDeliveryViewModel = {
    //            groupedReturnsModel: groupedReturnsModel,
    //            paperReturnDeliveryType: paperReturnDeliveryType,
    //            orderList: orderList,
    //            isNotifyUserEnabled: isNotifyUserEnabled,
    //            isSaveAsDefaultOrder: isSaveAsDefaultOrder
    //        };
    //        let endPoint: string = 'api/GroupLevelProcessing/PrintForPaperDeliveryAsync';
    //        let options: any = {
    //            method: 'PUT',
    //            credentials: 'include',
    //            headers: {
    //                'Accept': 'application/json',
    //                'Content-Type': 'application/json',
    //            },
    //            body: JSON.stringify(modelData)
    //        };

    //        const fetchTask = fetch(endPoint, options)
    //            .then(handleResponse)
    //            .then((data) => {
    //                if (Object.keys(data).length > 0) {
    //                    dispatch({
    //                        type: actionTypes.NOTIFICATION,
    //                        statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientSuccess,
    //                        statusType: Notification.StatusType.Success
    //                    })

    //                    if (callback) {
    //                        data ? callback(data) : callback();
    //                    }
    //                }
    //                else {
    //                    dispatch({
    //                        type: actionTypes.NOTIFICATION,
    //                        statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
    //                        statusType: Notification.StatusType.Error
    //                    });
    //                    if (callback) {
    //                        callback();
    //                    }
    //                }
    //            })
    //            .catch(error => {
    //                dispatch({
    //                    type: actionTypes.NOTIFICATION,
    //                    statusMessage: Constants.GroupReturnConstants.StatusMessage.DeliverToClientError,
    //                    statusType: Notification.StatusType.Error
    //                });
    //                if (callback) {
    //                    callback();
    //                }

    //            });
    //        addTask(fetchTask);
    //    },

    //notifyGroupedTaxDocument: (id: number, status: DocumentStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const state = getState();
    //    if (!state.taxDocuments[id] || status === DocumentStatus.READY) {
    //        dispatch(actionCreators.requestGroupedTaxDocument(id, true));
    //        console.log('id not in state.taxDocuments in GR');
    //    } else {
    //        console.log('id exist in state.taxDocuments in GR');
    //        const taxReturn = state.taxDocuments[id].taxReturn;
    //        if (taxReturn) {
    //            if (taxReturn.documentSettings.deliverySettings.deliveryMode == DeliveryMode.PaperFiled &&
    //                status == DocumentStatus.DELIVERED) {
    //                taxReturn.documentStatus = DocumentStatus.DOWNLOADPDFDESCRIPTION;
    //            }
    //            else {
    //                taxReturn.documentStatus = status;
    //            }
    //            dispatch({ type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn });
    //        }
    //    }
    //}
}


export const reducer: Reducer<GroupedTaxDocumentStoreState> = (state: GroupedTaxDocumentStoreState = unloadedGroupedTaxDocumentStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_GROUPED_TAX_DOCUMENT:
            const loading: GroupedTaxDocumentStoreState = { ...state };
            loading[action.id] = {
                taxReturn: state[action.id] ? state[action.id].taxReturn : initialTaxReturnState,
                isLoading: true,
                error: false,
                message: '',
                isFullyLoaded: false
            };

            return loading;


        case actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT:
            const received: GroupedTaxDocumentStoreState = { ...state };
            const message = Date();
            action.taxDocument.accessCode = {} as IAccessCodeViewModel;
            received[action.id] = {
                taxReturn: action.taxDocument,
                isLoading: false,
                error: false,
                message: message,
                isFullyLoaded: (action.isEditClientInfoRequest != undefined && action.isEditClientInfoRequest == true) ? false
                    : action.taxDocument.formGroups.length != 0 && action.taxDocument.documentSettings != undefined ? true : false,
                fileName: received[action.id] == undefined ? "" : received[action.id].fileName
            };

            return received;

        case actionTypes.ERROR_GROUPED_TAX_DOCUMENT:
            const errorState: GroupedTaxDocumentStoreState = { ...state };
            action.ids.map((id, i) => {
                if (state[id]) {
                    const model = state[id].taxReturn;
                    errorState[id].isLoading = false;
                    errorState[id].error = true;
                    errorState[id].message = action.message;
                }
            });

            return errorState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS:
            const signedDetailsState: GroupedTaxDocumentStoreState = { ...state };
            const signedDetailsTaxReturn = state[action.id];
            if (signedDetailsTaxReturn) {
                signedDetailsTaxReturn.taxReturn.signedDetails = action.signedDetails;
                signedDetailsState[action.id] = {
                    taxReturn: signedDetailsTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: '',
                    isFullyLoaded: signedDetailsState[action.id].isFullyLoaded
                };
            }

            return signedDetailsState;

        //case actionTypes.RECEIVE_TAX_DOCUMENT_STATUS:
        //    const documentStatusState: GroupedTaxDocumentStoreState = { ...state };
        //    const documentStatusOfTaxReturn = state[action.id].taxReturn;
        //    documentStatusOfTaxReturn.documentStatus = action.documentStatus;
        //    documentStatusState[action.id] = {
        //        taxReturn: documentStatusOfTaxReturn,
        //        isLoading: false,
        //        error: false,
        //        message: '',
        //        isFullyLoaded: documentStatusState[action.id].isFullyLoaded
        //    };

        //    return documentStatusState;

        //case actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS:
        //    const signedDetailsRequestState: GroupedTaxDocumentStoreState = { ...state };
        //    const signedDetailsRequestTaxReturn = state[action.id].taxReturn;
        //    signedDetailsRequestTaxReturn.signedDetails = [];
        //    signedDetailsRequestState[action.id] = {
        //        taxReturn: signedDetailsRequestTaxReturn,
        //        isLoading: false,
        //        error: false,
        //        message: '',
        //        isFullyLoaded: signedDetailsRequestState[action.id].isFullyLoaded
        //    };

        //    return signedDetailsRequestState;

        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
            const makeavailableState = { ...state };
            action.ids.map((id: any) => {
                delete makeavailableState[id];
            });

            return makeavailableState;
        case actionTypes.K1_INSTRUCTION_DETAIL:
            const taxDocument: GroupedTaxDocumentStoreState = { ...state };
            const taxReturn = state[action.id].taxReturn;
            taxDocument[action.id] = {
                taxReturn: taxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: taxDocument[action.id].isFullyLoaded,
                fileName: action.fileName
            };
            return taxDocument;
        case actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS:
            const taxcaddyDetails: GroupedTaxDocumentStoreState = { ...state };
            const taxreturn = state[action.id].taxReturn;
            taxreturn.taxCaddyLookupResultModel = action.taxcaddyLookupDetails;
            taxcaddyDetails[action.id] = {
                taxReturn: taxreturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: taxcaddyDetails[action.id].isFullyLoaded,
            };
            return taxcaddyDetails;
        case actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS:
            const clearTaxcaddyDetails: GroupedTaxDocumentStoreState = { ...state };
            const taxcaddyTaxreturn = state[action.id].taxReturn;
            //delete taxcaddyTaxreturn.taxCaddyLookupResultModel;
            clearTaxcaddyDetails[action.id] = {
                taxReturn: taxcaddyTaxreturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: clearTaxcaddyDetails[action.id].isFullyLoaded,
            };
            return clearTaxcaddyDetails;

        case actionTypes.RESET_TAX_DOCUMENT:
            const resetTaxDocumentState = { ...state };
            if (resetTaxDocumentState[action.id]) {
                resetTaxDocumentState[action.id].isFullyLoaded = false;
                resetTaxDocumentState[action.id].taxReturn.formGroups = [];
            }
            return resetTaxDocumentState;
        case actionTypes.RECEIVE_SEND_GROUPED_RETURNS:

            const receivedDocs: GroupedTaxDocumentStoreState = { ...state };
            const messageStr = Date();

            action.table.sendGroupedExtensions.forEach((group, i) => {
                group.taxReturns.forEach((model, i) => {

                    const document = state[model.id];

                    receivedDocs[model.id] = {
                        taxReturn: document && document.isFullyLoaded ? document.taxReturn : model,
                        isLoading: false,
                        error: false,
                        message: messageStr,
                        isFullyLoaded: document && document.isFullyLoaded ? true : false,
                        fileName: receivedDocs[model.id] == undefined ? "" : receivedDocs[model.id].fileName
                    };
                });
            });

            return receivedDocs;

        //case actionTypes.RECEIVE_DELIVERED_GROUPED_RETURNS:

        //    let receivedDeliveredDocs: GroupedTaxDocumentStoreState = { ...state };

        //    action.table.deliveredGroupedReturns.forEach((group, i) => {
        //        group.taxReturns.forEach((model, i) => {

        //            const document = state[model.document.id];

        //            receivedDeliveredDocs[model.document.id] = {
        //                taxReturn: document && document.isFullyLoaded ? document.taxReturn : model.document,
        //                isLoading: false,
        //                error: false,
        //                message: "",
        //                isFullyLoaded: document && document.isFullyLoaded ? true : false,
        //                fileName: receivedDeliveredDocs[model.document.id] == undefined ? "" : receivedDeliveredDocs[model.document.id].fileName
        //            };
        //        });
        //    });

        //    return receivedDeliveredDocs;


        case actionTypes.MARK_AS_READY_FOR_DELIVERY:
            const markAsReadyForDeliveryState: GroupedTaxDocumentStoreState = { ...state };
            const taxreturnMark = state[action.id].taxReturn;
            taxreturnMark.documentStatus = DocumentStatus.READYFORDELIVERY;
            markAsReadyForDeliveryState[action.id] = {
                taxReturn: taxreturnMark,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: state[action.id].isFullyLoaded,
            };
            return markAsReadyForDeliveryState;

        case actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS:
            const updateGroupedTaxDocumentStatusState: GroupedTaxDocumentStoreState = { ...state };
            action.ids.map((id, index) => {
                const taxDocument = updateGroupedTaxDocumentStatusState[id];
                if (taxDocument) {
                    taxDocument.taxReturn.documentStatus = action.status;
                }
            });
            return updateGroupedTaxDocumentStatusState;
        case actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN:
            const updateGroupedTaxDocumentAssignState: GroupedTaxDocumentStoreState = { ...state };
            action.ids.map((id, index) => {
                const taxDocument = updateGroupedTaxDocumentAssignState[id];
                if (taxDocument) {
                    taxDocument.taxReturn.assignedUser = action.assignedUser;
                    taxDocument.taxReturn.assignTo = action.assignTo;
                    taxDocument.taxReturn.documentStatus = action.status;
                }
            });
            return updateGroupedTaxDocumentAssignState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS:
            const downloadableDocumentState: GroupedTaxDocumentStoreState = { ...state };
            const downloadableDocumentTaxReturn = state[action.id];
            if (downloadableDocumentTaxReturn) {
                downloadableDocumentTaxReturn.taxReturn.downloadableDocuments = action.downloadableDocuments;
                downloadableDocumentState[action.id] = {
                    taxReturn: downloadableDocumentTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadableDocumentState[action.id].isFullyLoaded
                };
            }
            return downloadableDocumentState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS:
            const downloadableEfileFormState: GroupedTaxDocumentStoreState = { ...state };
            const downloadableEfileFormTaxReturn = state[action.id];
            if (downloadableEfileFormTaxReturn) {
                downloadableEfileFormTaxReturn.taxReturn.downloadableEfileForms = action.downloadableEfileForms;
                downloadableEfileFormState[action.id] = {
                    taxReturn: downloadableEfileFormTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadableEfileFormState[action.id].isFullyLoaded
                };
            }
            return downloadableEfileFormState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY:
            const downloadHistoryState: GroupedTaxDocumentStoreState = { ...state };
            const downloadHistoryTaxReturn = state[action.id];
            if (downloadHistoryTaxReturn) {
                downloadHistoryTaxReturn.taxReturn.downloadHistory = action.downloadHistory;
                downloadHistoryState[action.id] = {
                    taxReturn: downloadHistoryTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: Date(),
                    isFullyLoaded: downloadHistoryState[action.id].isFullyLoaded
                };
            }
            return downloadHistoryState;

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
    }
    return state || unloadedGroupedTaxDocumentStoreState;
};