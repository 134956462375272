import React, { useEffect, useState } from 'react';
import PaymentVoucherNotification from './VoucherAndInvoiceComponent/PaymentVoucherNotification';
import { SettingsHeader } from '../common/settings/SettingsHeader';
import { OverlayLoader } from '../helper/OverlayLoader';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import {
    ExtensionCompanySettings,
    initialExtensionCompanySettings
} from 'src/Core/ViewModels/Extension/ExtensionComponentModels';
import { ActionCreators as ExtensionCompanySettingsStore } from './../../store/settings/ExtensionCompanySettingsStore';
import { cloneDeep } from 'lodash'
import VoucherDueDateSetting from './VoucherAndInvoiceComponent/VoucherDueDateSetting';

export const Vouchers: React.FC = () => {

    const dispatch = useDispatch();
    const companyData = useSelector((state: ApplicationState) => state.extensionCompanyData)
    const [extensionCompanySettings, setCompanySettings] = useState<ExtensionCompanySettings>({ ...initialExtensionCompanySettings });

    useEffect(() => {
        dispatch(ExtensionCompanySettingsStore.requestExtensionCompanySettings());
    }, []);

    useEffect(() => {
        setCompanySettings(cloneDeep(companyData.companySettings));
    }, [companyData]);

    const handleApplyButton = () => {
        dispatch(ExtensionCompanySettingsStore.updateExtensionCompanySettings(extensionCompanySettings));
    }

    const onPaymentVoucherReminderChange = (enablePaymentVoucherReminder: boolean) => {
        extensionCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings.enablePaymentVoucherReminder = enablePaymentVoucherReminder;
        setCompanySettings({ ...extensionCompanySettings });
    }

    const onVoucherReminderDaysChange = (days: number) => {
        if (days > 0) {
            extensionCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder = days;
            setCompanySettings({ ...extensionCompanySettings });
        }
    }

    const onPaymentNotificationChange = (notification: boolean) => {
        extensionCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification = notification;
        setCompanySettings({ ...extensionCompanySettings });
    }

    const handleVoucherDueDateChange = (event: any) => {
        let temp = companyData.companySettings as ExtensionCompanySettings;
        temp.displaySettingsModel.voucherDueDateType = parseInt(event.value);
        setCompanySettings({ ...temp });
    }

    const {
        enablePaymentVoucherReminder,
        noOfDaysForVoucherReminder,
        paymentNotification
    } = extensionCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings;

    return <div id="divPaymentAndVoucher" className='company-settings font14'>
        <SettingsHeader
            handleApplyButton={handleApplyButton}
            header="Vouchers"
            showPopOver={false} />
        <OverlayLoader show={companyData.loading} text={"Loading..."} />
        <div>
            <PaymentVoucherNotification
                enablePaymentVoucherReminder={enablePaymentVoucherReminder}
                noOfDaysForVoucherReminder={noOfDaysForVoucherReminder}
                onPaymentNotificationChange={onPaymentNotificationChange}
                onPaymentVoucherReminderChange={onPaymentVoucherReminderChange}
                onVoucherReminderDaysChange={onVoucherReminderDaysChange}
                paymentNotification={paymentNotification}
            />
            <hr />

            <VoucherDueDateSetting
                extCompanySettings={companyData.companySettings}
                handleVoucherDueDateChange={handleVoucherDueDateChange}
             />
             <hr />
        </div>
    </div>;
}