import { IMailingReturnAddress } from "src/Core/ViewModels/CompanySettings/SignaturesViewModel";
import { actionTypes } from "..//ActionTypes";
import { AppThunkAction } from "../";
import { API_BASE_URL } from "../../utils/contants";
import { Reducer } from "redux";
import { addTask } from "domain-task";
import { StatusType, NotificationAction } from '../common/NotificationStore';

export interface IMailingReturnAddressStoreState {
    data: IMailingReturnAddressDictionary;
    isLoading: boolean;
    message: string;
}

export interface IMailingReturnAddressDictionary {
    [id: number]: IMailingReturnAddress
}

export interface requestMailingReturnAddressList {
    type: actionTypes.REQUEST_MANUAL_ADDRESS_LIST,
    message: string,
}

export interface ReceivemailingReturnAddress {
    type: actionTypes.RECEIVE_MANUAL_ADDRESS_LIST,
    mailingReturnAddress: IMailingReturnAddress,
    id: number,
}

export interface ErrormailingReturnAddress {
    type: actionTypes.ERROR_MANUAL_ADDRESS_LIST,
}

type KnownAction =
    requestMailingReturnAddressList |
    ReceivemailingReturnAddress |
    NotificationAction |
    ErrormailingReturnAddress;

export const actionCreators = {
    requestMailingReturnAddressList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/office-location/office-location-list', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<Array<IMailingReturnAddress>>)
            .then(data => {
                if (data) {
                    data.map((mailingReturnAddress, index) => {
                        dispatch({
                            type: actionTypes.RECEIVE_MANUAL_ADDRESS_LIST,
                            mailingReturnAddress: mailingReturnAddress,
                            id: mailingReturnAddress.id,
                        })
                    })
                }
            })
            .catch(function (error) {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
                dispatch({ type: actionTypes.ERROR_MANUAL_ADDRESS_LIST })
            })
        addTask(fetchTask);
    },
}

const mailingReturnAddressDict: IMailingReturnAddressDictionary = [];
const unloadedState: IMailingReturnAddressStoreState = { data: mailingReturnAddressDict, isLoading: false, message: "" } as IMailingReturnAddressStoreState;

export const reducer: Reducer<IMailingReturnAddressStoreState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_MANUAL_ADDRESS_LIST:
            if (state) {
                let requestState: IMailingReturnAddressStoreState = { ...state };
                requestState.isLoading = true;
                requestState.message = action.message;
                return requestState;
            }
            return {
                ...unloadedState
            } as IMailingReturnAddressStoreState;

        case actionTypes.RECEIVE_MANUAL_ADDRESS_LIST:
            let received: IMailingReturnAddressStoreState = { ...state };
            let message = Date();
            if (received.data[-1])
                delete received.data[-1];
            received.data[action.id] = action.mailingReturnAddress;
            received.isLoading = false;
            return received;
        case actionTypes.ERROR_MANUAL_ADDRESS_LIST:
            let errorReceived: IMailingReturnAddressStoreState = { ...state };
            errorReceived.isLoading = false;
            errorReceived.message = "";
            return errorReceived;
    }
    return state;
};