import { IPasswordPolicySettings } from "src/Core/ViewModels/Company/CompanySettingsViewModel"
import { actionTypes } from "../ActionTypes"
import { PasswordOptions, PasswordPolicyType } from "src/components/navigation/profile/ProfileObjects"
import { AppThunkAction } from '../'
import { SUITE_API_BASE_URL } from '../../utils/contants'
import { handleResponse } from "../Library"
import { addTask } from 'domain-task'
import { Reducer } from "redux"
import { StatusType, NotificationAction } from '../common/NotificationStore'

export interface IPasswordPolicySettingsState {
    passwordPolicySetting: IPasswordPolicySettings
    isVerifyLoading: boolean
}

interface RequestPasswordPolicyAction {
    type: actionTypes.REQUEST_PASSWORDPOLICYSETTINGS
    isVerifyLoading: boolean
}

interface ReceivePasswordPolicyAction {
    type: actionTypes.RECEIVE_PASSWORDPOLICYSETTINGS
    passwordPolicySetting:IPasswordPolicySettings
    isVerifyLoading: boolean
}

const unloadedState:IPasswordPolicySettingsState={
    passwordPolicySetting: {
        passwordPolicy: PasswordPolicyType.NoPolicy,
        modifiedDate: new Date,
        passwordSettings: {
            passwordOptions: PasswordOptions.None,
            length: 8,
            passwordAge: 0
        }
    },
    isVerifyLoading:false
}

type KnownAction =
    | RequestPasswordPolicyAction
    | ReceivePasswordPolicyAction
    | NotificationAction

export const actionCreators={
    requestPasswordPolicySetting:():AppThunkAction<KnownAction>=>(dispatch,getState)=>
    {        
        dispatch({type:actionTypes.REQUEST_PASSWORDPOLICYSETTINGS,isVerifyLoading:true})
        let fetchTask=fetch(SUITE_API_BASE_URL+'api/settings/password-policy',{
            method:'GET',
            credentials:'include'
        })
        .then(handleResponse)
        .then(data=>{            
            dispatch({
                type:actionTypes.RECEIVE_PASSWORDPOLICYSETTINGS,
                passwordPolicySetting:data,
                isVerifyLoading:false
            })
        })
        .catch(function (error) {
            const statusMessage: any = error.statusText
            if (typeof statusMessage === 'string') {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: statusMessage,
                    statusType: StatusType.Error,
                })
            }
        })
        addTask(fetchTask)
    }
}

export const reducer:Reducer<IPasswordPolicySettingsState>=(state=unloadedState,incomingAction)=>{
    const action=incomingAction as KnownAction
    switch(action.type){
        case actionTypes.REQUEST_PASSWORDPOLICYSETTINGS:
            return{
                ...state,
                isVerifyLoading:action.isVerifyLoading
            }
        case actionTypes.RECEIVE_PASSWORDPOLICYSETTINGS:
            return{
                ...state,
                passwordPolicySetting:action.passwordPolicySetting,
                isVerifyLoading:false
            }
    }
    return state
}