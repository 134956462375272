import {
    IFormBase, ITaxReturn, DocumentGroups,
    IVoucher, IEFile,
    IGroup, ISignatureControl, SignatureControlRole, ITaxingAuthority,
    SignatureGroupType, SignatureType, VoucherNo, EntityType, ITaxReturnGroup, ITransmittal, SignatureControlTypes
} from '../../common/TaxReturn';
import { IAuthorityDictionary, ICompanyData } from '../../../store/company/CompanyStore';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as Helper from '../../helper/HelperFunctions';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { UserSettings } from '../../../store/userManagement/UserSettingStore';
import * as TabEFile from './Tabs/TabEFile';
import { IUserSignatures } from '../../../store/common/UserSignatureStore';
import { VoucherSort } from '../ProcessReturnModal/Voucher/VoucherSort';
import { IAuthState } from 'src/store/auth/reducer';
export const NO_INDEX = -1;

export interface IFormGroupViewProps {
    bookmarks: IBookmarkSection[];
    pages: number[];
    taxReturn: ITaxReturn;
    updateTaxDocument: (taxDocument: ITaxReturn, callBack?: () => void) => void;
    isAssignedToLoggedinUser: boolean;
    reGroupPreview: (pages: number[], destinationGroup: DocumentGroups) => void;
    docId: number;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    deletePages: (pages: number[]) => void;
    renderTrigger: string;
    tabType?: number;
    getAllTaxingAuthorities: ITaxingAuthority[];
}

export interface IFormGroupViewState {
    currentPage: number;
    actionPanelShow: boolean;
    zoomEnabled: boolean;
}

export function toPageItem(this: IFormBase) {
    return {
        pageNo: this.pageNo[0],
        pageTitle: this.formName,
        icon: ''
    } as IPageItem;
}

//IFormBase.prototype.toPageItem = toPageItem;

export interface IPageItem {
    pageNo: number;
    pageTitle: string;
    icon: string;
    color?: string;
    className?: string;
    id: number;
    isDeleteEnabled: boolean;
    displayIconAsImage: boolean;
    isClientInfo?: boolean;
    form: IFormBase;
}

export interface IVoucherPageItem extends IPageItem {
    voucherNo: VoucherNo;
    isSignatureRequired: boolean;
}

export interface IEFilePageItem extends IPageItem {
    isSignatureRequired: boolean;
}

export interface IBookmarkSection {
    heading: string;
    pages: IPageItem[];
}

export enum ProcessReturnTabs {
    ClientInfo = 1,
    Grouping,
    Transmittals,
    TaxReturns,
    EFile,
    AdditionalEsignDocuments,
    Vouchers,
    K1,
    InVoice,
    Attachments,
    DeliveryOptions
}
export enum ExtensionProcessReturnTabs {
    ClientInfo = 1,
    Grouping,
    Transmittals,
    FilingForm,
    AdditionalEsignDocuments,
    DeliveryOptions
}

export interface IShareHolder {
    k1StatusId: number,
    name: string;
    ssn: string;
    id: number;
    emailAddress: string;
    k1SendDate: string;
    isK1Decline: boolean;
    k1DownloadDate: string;
    partnerAddress: string;
    entityType: EntityType;
    financialYearEnd: Date | undefined;
    countryCode: string;
    mobileNumber: string;
}


export const ICONS = {
    clientInfo: 'fa fa-id-card-alt',
    refund: 'fas fa-hand-holding-usd',
    efile: 'fas fa-pen-nib',
    transmittal: 'fas fa-list-alt',
    taxreturn: 'fas fa-copy',
    k1: 'fas fa-id-card',
    invoice: 'file-invoice-dollar'
}

export const ClientInfoIcons = {
    clientInfoPayments: 'client-info-payments',
    clientInfoRefunds: 'client-info-refunds'
}

export interface ISigner {
    role: SignatureControlRole,
    id: number,
}

export interface IEROSigner {
    id: number,
    signPath: string,
}

export interface PdfPageSignatureControls {
    signatureControls: ISignatureControl[],
    signer: ISigner,
    eroSigner: IEROSigner,
    focusedGroup: SignatureGroupType
}

export const processReturnActionEndPoints = {
    sendForReviewAsync: 'SendForReviewAsync',
    approveForDeliveryAsync: 'ApproveForDeliveryAsync',
    sendToEROAsync: 'SendToEROAsync',
    returnToProcessorAsync: 'ReturnToProcessorAsync',
    deliverAsync: 'DeliverAsync',
    generateTaxpayerViewAsync: 'GenerateTaxpayerViewAsync'
}

export const SEPARATOR = "###";
export const INVALID_PAGE = -1;

class TaxReturnView<T> {
    constructor(taxReturn: ITaxReturn) {
        this.model = taxReturn;
    }
    protected model: ITaxReturn;

    public getFormIndex = (group: number) => {
        return this.model.formGroups.findIndex(x => x.group === group);
    }
    public getAuthorityName = (authorities: IAuthorityDictionary, authorityID: number, formName: string) => {
        for (let i in authorities) {
            if (authorities[i] && authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName;
            }
        }
        return formName;
    }

    public getPages(bookmarks: IBookmarkSection[]): number[] {
        let ret: number[] = [];
        bookmarks.map((f, i) => {
            f.pages.map((p, j) => {
                if (p.pageNo !== INVALID_PAGE) {
                    ret.push(p.pageNo);
                }
            });
        });
        return ret;
    }

    public getVoucherAuthorities(authorities: IVoucher[]): number[] {
        let ret: number[] = [];

        authorities.map((f, i) => {
            ret.push(f.authorityID);
        });

        return ret;
    }

    public getAllAuthorities(authorities: ITaxingAuthority[]): number[] {
        let ret: number[] = [];

        authorities.map((f, i) => {
            ret.push(f.Id);
        });

        return ret;
    }

    public sortGroup = (pages: number[], group: DocumentGroups): ITaxReturn => {

        let taxDocument = this.model;
        let forms = taxDocument.formGroups.find(m => m.group === group) as IGroup;

        if (forms) {

            let groupIndex = taxDocument.formGroups.indexOf(forms);

            let sortedForms: IGroup = {
                forms: [],
                group: forms.group
            };

            pages.forEach(function (pageNumber) {
                let form = forms.forms.find(m => m.pageNo[0] === pageNumber);
                if (form) {
                    sortedForms.forms.push(form);
                }
            })

            taxDocument.formGroups.splice(groupIndex, 1, sortedForms);
        }

        return taxDocument;
    }
};

export class ClientInfoView extends TaxReturnView<ITaxReturn> {
    constructor(taxReturn: ITaxReturn) {
        super(taxReturn);
    }

    public getBookmarks(): IBookmarkSection[] {
        let ret: IBookmarkSection[] = [];

        //Get Client Pages
        let clientInfo: IBookmarkSection = {
            heading: 'Client Details',
            pages: [],
        };

        let taxReturnIndex = this.getFormIndex(DocumentGroups.TaxReturns);
        if (this.model.formGroups[taxReturnIndex] && this.model.formGroups[taxReturnIndex].forms) {
            this.model.formGroups[taxReturnIndex].forms.map((f, i) => {
                if (f.formName === 'ClientInfo') {
                    f.pageNo.map((p, j) => {
                        clientInfo.pages.push({
                            pageNo: p,
                            pageTitle: f.bookmark,
                            icon: ICONS.clientInfo,
                            isDeleteEnabled: false,
                            isClientInfo: true
                        } as IPageItem);
                    });
                }
            });
        }

        if (!clientInfo.pages.length) {
            const clientInfoIndex = this.getFormIndex(DocumentGroups.ClientInfo);
            if (this.model.formGroups[clientInfoIndex] && this.model.formGroups[clientInfoIndex].forms) {

                const clientInfoForm = this.model.formGroups[clientInfoIndex].forms[0];

                clientInfoForm.pageNo.map((p, j) => {
                    clientInfo.pages.push({
                        pageNo: p,
                        pageTitle: clientInfoForm.bookmark,
                        icon: ICONS.clientInfo,
                        isDeleteEnabled: false,
                        isClientInfo: true
                    } as IPageItem);
                });
            }
        }
        
        clientInfo.pages.sort((a, b) => a.pageNo-b.pageNo);
        ret.push(clientInfo);
        
        return ret;
    }
}

export class Transmittalsview extends TaxReturnView<ITaxReturn> {
    constructor(taxReturn: ITaxReturn) {
        super(taxReturn);
    }

    public getBookmarks(): IBookmarkSection[] {
        let ret: IBookmarkSection[] = [];
        let bookmarks: IBookmarkSection = {
            heading: 'Transmittals',
            pages: [],
        };
        let transmittalIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);

        if (this.model.formGroups[transmittalIndex] && this.model.formGroups[transmittalIndex].forms) {
            this.model.formGroups[transmittalIndex].forms.sort((a: IFormBase, b: IFormBase) => a.pageNo[0] - b.pageNo[0])
            this.model.formGroups[transmittalIndex].forms.map((f, i) => {
                f.pageNo.map((p, j) => {
                    bookmarks.pages.push({
                        pageNo: p,
                        pageTitle: 'Transmittal [ ' + f.pageNo + ' ]',
                        icon: ICONS.transmittal,
                        isDeleteEnabled: false
                    } as IPageItem);
                });
            });
        }

        bookmarks.pages.sort((a, b) => a.pageNo-b.pageNo);
        ret.push(bookmarks);

        return ret;
    }

    public getSignatureControls(): ISignatureControlsDictionary {

        let controls: ISignatureControlsDictionary = {};

        let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
        if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
            this.model.formGroups[index].forms.map((f: IFormBase) => {
                let form: ITransmittal = f as ITransmittal;
                controls[form.pageNo[0]] = form.signatureControls;
            });
        }
        return controls;
    }

    public addSignatureControl(control: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
            if (this.model.formGroups[index]) {

                this.model.formGroups[index].forms.map((f: IFormBase) => {

                    let form: ITransmittal = f as ITransmittal;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        if (!form.signatureControls) {
                            form.signatureControls = [];
                        }
                        form.signatureControls.push(control);
                    }
                });
            }
        }
    }

    public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
            if (this.model.formGroups[index]) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {

                    let form: ITransmittal = f as ITransmittal;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);

                    if (found != NO_INDEX) {
                        form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
                    }
                });
            }
        }
    }

    public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
            if (this.model.formGroups[index]) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {

                    let form: ITransmittal = f as ITransmittal;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);

                    if (found != NO_INDEX) {

                        for (let i = 0; i < form.signatureControls.length; i++) {

                            let signControl = form.signatureControls[i];

                            if (signControl.controlGuid === oldControl.controlGuid) {

                                signControl.left = newControl.left;
                                signControl.top = newControl.top;
                                signControl.signatureControlRole = newControl.signatureControlRole;
                                signControl.type = newControl.type;

                                break;
                            }
                        }
                    }
                });
            }
        }
    }
}

export interface IReGroupTaxReturn {
    sourceGroup: DocumentGroups;
    destinationGroup: DocumentGroups;
    form: IFormBase;
}

export interface IReGroupPreviewTaxReturn {
    sourceGroup: DocumentGroups;
    destinationGroup: DocumentGroups;
    pageNo: number[];
}

export interface ITaxReturnBookMarksView {
    bookmarks: string[];
    pageNo: number[];
}

export interface ISignatureControlsDictionary {
    [pageNo: number]: ISignatureControl[]
}

export class TaxReturnInfoView extends TaxReturnView<ITaxReturn> {
    constructor(taxReturn: ITaxReturn) {
        super(taxReturn);
    }

    public addSignatureControl(control: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
            if (this.model.formGroups[index]) {

                this.model.formGroups[index].forms.map((f: IFormBase) => {

                    let form: ITaxReturnGroup = f as ITaxReturnGroup;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        if (!form.signatureControls) {
                            form.signatureControls = [];
                        }
                        form.signatureControls.push(control);
                    }
                });
            }
        }
    }

    public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
            if (this.model.formGroups[index]) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {

                    let form: ITaxReturnGroup = f as ITaxReturnGroup;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);

                    if (found != NO_INDEX) {

                        for (let i = 0; i < form.signatureControls.length; i++) {

                            let signControl = form.signatureControls[i];

                            if (signControl.controlGuid === oldControl.controlGuid) {

                                signControl.left = newControl.left;
                                signControl.top = newControl.top;
                                signControl.signatureControlRole = newControl.signatureControlRole;
                                signControl.type = newControl.type;

                                break;
                            }
                        }
                    }
                });
            }
        }
    }
}

export class EfileInfoView extends TaxReturnView<ITaxReturn> {
    constructor(taxReturn: ITaxReturn) {
        super(taxReturn);
    }

    public getBookmarks(authorities: ITaxingAuthority[], signatureType: any, enableESignature: boolean): IBookmarkSection[] {
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;
        let section: IBookmarkSection[] = [];
        let bookmarks: IBookmarkSection = {
            heading: 'Extensions',
            pages: [],
        };
        let taxReturnBookmark: IBookmarkSection = {
            heading: 'Tax Returns',
            pages: []
        };
        let iconColor: string;
        if (signatureType === SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] || enableESignature)
            iconColor = "text-green";
        else if (signatureType === SignatureType[SignatureType.ManualSign])
            iconColor = "text-yellow";
        let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        
        if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
            this.model.formGroups[index].forms.sort((a: IFormBase, b: IFormBase) => a.pageNo[0] - b.pageNo[0])
            this.model.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                const authorityName = this.getAuthorityName(authorities, form.authorityID, form.formName);
                
                form.pageNo.map((p, j) => {
                    bookmarks.pages.push({
                        pageNo: p,
                        color: iconColor,
                        pageTitle: form.formName.startsWith(authorityName) ? form.formName : authorityName + " " + form.formName,
                        icon: ICONS.efile,
                        isDeleteEnabled: false,
                        isSignatureRequired: !form.signatureControls || form.signatureControls.length == 0,
                        form: f
                    } as IEFilePageItem);
                });
            });
        }

        const voucherSort = new VoucherSort(this.model, authorities);

        const voucherBookmarks = voucherSort.getVoucherBookmark();

        const taxReturnIndex = this.getFormIndex(DocumentGroups.TaxReturns);

        const taxReturns = this.model.formGroups[taxReturnIndex] &&
            this.model.formGroups[taxReturnIndex].forms;

        taxReturns && taxReturns.map((form, index) => {

            form.pageNo.map((page, pageIndex) => {
                taxReturnBookmark.pages.push({
                    pageNo: page,
                    pageTitle: form.bookmark,
                    icon: ICONS.taxreturn,
                    isDeleteEnabled: false,
                    form
                } as IPageItem);
            });
        });

        section.push(bookmarks);

        if (section && voucherBookmarks) {
            section[0].pages.push(...voucherBookmarks.pages)
        }
        if (section && taxReturnBookmark) {
            section[0].pages.push(...taxReturnBookmark.pages);
        }

        section[0].pages.sort((a, b) => a.pageNo-b.pageNo);
        return section;
    }

    public getSignatureControls(): ISignatureControlsDictionary {

        let controls: ISignatureControlsDictionary = {};

        let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
            this.model.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                controls[form.pageNo[0]] = form.signatureControls;
            });
        }

        let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        if (this.model.formGroups[voucherIndex] && this.model.formGroups[voucherIndex].forms) {
            this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                let form: IVoucher = f as IVoucher;
                controls[form.pageNo[0]] = form.signatureControls;
            });
        }
        return controls;
    }

    public addSignatureControl(control: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {

            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (this.model.formGroups[index] && this.model.formGroups[index].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        if (!form.signatureControls) {
                            form.signatureControls = [];
                        }
                        form.signatureControls.push(control);
                    }
                });
            }
            if (this.model.formGroups[voucherIndex] && this.model.formGroups[voucherIndex].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                    let form: IVoucher = f as IVoucher;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        if (!form.signatureControls) {
                            form.signatureControls = [];
                        }
                        form.signatureControls.push(control);
                    }
                });
            }
        }
    }

    public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {
            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (this.model.formGroups[index] && this.model.formGroups[index].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
                    }
                });
            }
            if (this.model.formGroups[voucherIndex] && this.model.formGroups[voucherIndex].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                    let form: IVoucher = f as IVoucher;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
                    }
                });
            }
        }
    }

    public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
        if (this.model && this.model.formGroups) {
            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (this.model.formGroups[index] && this.model.formGroups[index].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        for (let i = 0; i < form.signatureControls.length; i++) {
                            let signControl = form.signatureControls[i];
                            if (signControl.controlGuid === oldControl.controlGuid) {
                                signControl.left = newControl.left;
                                signControl.top = newControl.top;
                                signControl.signatureControlRole = newControl.signatureControlRole;
                                signControl.type = newControl.type;
                                break;
                            }
                        }
                    }
                });
            }

            if (this.model.formGroups[voucherIndex] && this.model.formGroups[voucherIndex].forms.find(x => x.pageNo[0] === pageNo)) {
                this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                    let form: IVoucher = f as IVoucher;
                    let found = NO_INDEX;
                    found = form.pageNo.findIndex((n) => n === pageNo);
                    if (found != NO_INDEX) {
                        for (let i = 0; i < form.signatureControls.length; i++) {
                            let signControl = form.signatureControls[i];
                            if (signControl.controlGuid === oldControl.controlGuid) {
                                signControl.left = newControl.left;
                                signControl.top = newControl.top;
                                signControl.signatureControlRole = newControl.signatureControlRole;
                                signControl.type = newControl.type;
                                break;
                            }
                        }
                    }
                });
            }
        }
    }

    public removeDateControls(): void {
        if (this.model && this.model.formGroups) {
            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (this.model.formGroups[index]) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    form.signatureControls = form.signatureControls.filter(x => x.type !== SignatureControlTypes.Date);
                });
            }

            if (this.model.formGroups[voucherIndex]) {
                this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                    let form: IVoucher = f as IVoucher;
                    form.signatureControls = form.signatureControls.filter(x => x.type !== SignatureControlTypes.Date);
                });
            }
        }
    }

    public removeEROSignatureControls(): void {
        if (this.model && this.model.formGroups) {
            let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            let voucherIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (this.model.formGroups[index]) {
                this.model.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    form.signatureControls = form.signatureControls.filter(x => x.signatureControlRole !== SignatureControlRole.ERO);
                });
            }
            if (this.model.formGroups[voucherIndex]) {
                this.model.formGroups[voucherIndex].forms.map((f: IFormBase) => {
                    let form: IVoucher = f as IVoucher;
                    form.signatureControls = form.signatureControls.filter(x => x.signatureControlRole !== SignatureControlRole.ERO);
                });
            }
        }
    }
}


export interface IDelegatedEROSigner {
    userId: number,
    firstName: string,
    lastName: string,
    isEnableSignatureDelegation: boolean,
    signaturePath: string,
}

export function getAllEroSigners(company: ICompanyData, userProfile: IUserProfile,
    taxReturn: ITaxReturn, userSettings: UserSettings,
    userSignatures: IUserSignatures,auth: IAuthState): TabEFile.IEROSigner[] {
    let eroSigners: TabEFile.IEROSigner[] = [];
    if (company != undefined) {
        let companyAsSigner = {
            label: company.companyProfile.companyInfo.companyName,
            value: 0,
            isEnableSignatureDelegation: true,
            eroImage: company.signatureUploadLink,
        }
        addEroSigner(eroSigners, companyAsSigner);
    }



    if (userProfile != undefined
        && Helper.isPartner(auth.user)){
        const currentUser = {
            label: userProfile.firstName + " " + userProfile.lastName,
            value: userProfile.userId as number,
            isEnableSignatureDelegation: true,
            eroImage: userSettings.settings.signatureSettings.signaturePath ? userSettings.settings.signatureSettings.signaturePath || "" : "",
        }
        addEroSigner(eroSigners, currentUser);
    }

    if (taxReturn.partner.userId && taxReturn.partner != undefined) {
        const partner = taxReturn.partner;
        const partnerEro = {
            label: partner.firstName + " " + partner.lastName,
            value: partner.userId,
            isEnableSignatureDelegation: true,
            eroImage: getEroSignerPath(partner.userId, userSignatures)
        }
        addEroSigner(eroSigners, partnerEro);
    }

    if (userSettings.delegatedSigners.length > 0) {
        userSettings.delegatedSigners.map(eroSigner => {
            let signer = {
                label: eroSigner.firstName + " " + eroSigner.lastName,
                value: eroSigner.userId,
                isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
                eroImage: eroSigner.signaturePath,
            }
            addEroSigner(eroSigners, signer);
        });
    }

    return eroSigners;
}

export function addEroSigner(eroSignersArray: TabEFile.IEROSigner[], eroSigner: TabEFile.IEROSigner) {
    if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
        eroSignersArray.push(eroSigner);
    }
}

export function getEroSignerPath(userId: number, userSignatures: IUserSignatures): string {
    let eroImagePath = "";
    if (userSignatures[userId] && userSignatures[userId].signatureDownloadPath) {
        eroImagePath = userSignatures[userId].signatureDownloadPath;
    }
    return eroImagePath;
}