import React from  'react';
import { IStates, IMailingReturnAddress, initialMailingReturnAddressList } from '../../../Core/ViewModels/CompanySettings/SignaturesViewModel';
import * as MailingReturnAddressStore from '../../../store/common/MailingReturnAddressStore';
import { ICountryState } from '../../../Core/ViewModels/Company/CompanyViewModel';


interface ISelectedMailingAddressView {
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    states: ICountryState[];
    mailingReturnAddress: IMailingReturnAddress
    selectedAddressId: number;
}

export class SelectedMailingAddressView extends React.Component<ISelectedMailingAddressView, {}>{
    constructor(props: ISelectedMailingAddressView) {
        super(props);
    }

    public render() {
        const list = this.props.mailingReturnAddressList ? this.props.mailingReturnAddressList : [];
        let authorityName: string = "";
        let addressId: number = this.props.selectedAddressId == 0 ? parseInt(Object.keys(list)[0]) : this.props.selectedAddressId
        let manualAddress: IMailingReturnAddress = list[addressId];
        if (manualAddress) {
            let state = this.props.states.filter(x => x.id == manualAddress.stateId);
            authorityName = state && state[0] ? state[0].name : "";
        }
        else {
            manualAddress = initialMailingReturnAddressList;
        }

        return (
            <div className="card selected-message-view">{manualAddress != undefined ?
                <div className="card-body"> <address>
                    {manualAddress.streetAddress2} <br />
                    {(manualAddress.streetAddress3 != null && manualAddress.streetAddress3 != "")
                        ? manualAddress.streetAddress3 : ""}
                    {(manualAddress.streetAddress3 != null && manualAddress.streetAddress3 != "")
                        ? <br /> : ""}
                    {manualAddress.city}<br/>
                    {authorityName}<br/>
                    {manualAddress.zip}<br />
                    {(manualAddress.fax != null && manualAddress.fax != "") ? manualAddress.fax : ""}
                </address>
                </div>

                : ""
            }
            </div>
        )
    }

}