import CSV from 'csvtojson';

export interface ICsvToJsonService {
    convertFromString: (csvString: string, onParseSuccess: (value: any[]) => void, onParseError: (reason: any) => void) => void;

    convertFromCsvFile: (file: any, onParseSuccess: (value: any[]) => void, onParseError: (reason: any) => void) => void;
}

export class CsvToJsonService implements ICsvToJsonService {

    convertFromString(csvString: string, onParseSuccess: (value: any[]) => void, onParseError: (reason: any) => void): void {
        CSV().fromString(csvString)
            .then(onParseSuccess, onParseError);
    }

    convertFromCsvFile(file: any, onParseSuccess: (value: any[]) => void, onParseError: (reason: any) => void) {
        const reader = new FileReader()
        reader.readAsText(file);
        return new Promise((resolve) => {
            reader.onload = function () {
                if (this.readyState == reader.DONE) {
                    return resolve({ data: this.result });
                }
            }
        }).then((result: any) => {
            CSV().fromString(result.data)
                .then(onParseSuccess, onParseError);
        });
    }

}