import { actionTypes } from "../../../../store/ActionTypes.js";
import { ITaxSoftwareSettings, initialSuiteTaxSoftwareSettings } from "./SuiteTaxSoftwareComponentModel";

export interface SuiteTaxSoftwareSettingsStoreState {
    taxSoftwareSettings: ITaxSoftwareSettings;
    loading: boolean;
}

export const unloadedSuiteTaxSoftwareSettingsStoreState: SuiteTaxSoftwareSettingsStoreState = {
    taxSoftwareSettings: initialSuiteTaxSoftwareSettings,
    loading: false,
};

export interface SuiteTaxSoftwareSettingsLoaderAction {
    type: actionTypes.SUITE_TAX_SOFTWARE_SETTINGS_LOADER;
    loading: boolean;
}

export interface ReceiveSuiteTaxSoftwareSettings {
    type: actionTypes.RECEIVE_SUITE_TAX_SOFTWARE_SETTINGS;
    data: ITaxSoftwareSettings;
}