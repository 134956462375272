import React from  'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaLink } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ITaxReturn, ISignerModel, SignatureStatus, DocumentEvent } from './TaxReturn';
import { VenusNotifier } from '../helper/VenusNotifier';
import { Col, Row } from 'react-bootstrap';
import { SignerStatus } from './DeliveredTaxReturns';

interface IResendAccessLinkProps {
    show: boolean;
    onCancel: () => void;
    model: ITaxReturn;
    onResendAccessLink: (documentId: number, model: ISignerModel) => void;
}
interface IResendAccessLinkState {
    message: string;
}

const msg = {
    loading: 'Loading, please wait...',
    saving: 'Sending Access Link...',
};

export class ResendAccessLinkModal extends React.Component<IResendAccessLinkProps, IResendAccessLinkState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            message: msg.loading,
        };
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IResendAccessLinkProps) {
        if (!nextProps.show) {
            this.setState({ message: msg.loading });
        }
    }

    public render() {
        const { model } = this.props
        const signedDetails = this.props.model !== undefined &&
            this.props.model.signedDetails &&
            this.props.model.signedDetails.length > 0;

        return <Modal show={this.props.show} onHide={this.props.onCancel} className="resendaccess-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaLink className='modalIcon' />
                    Resend Access Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ minHeight: 100 }}>
                    {signedDetails ?
                        <div>
                            {this.props.model.isSSRExtensionDocument &&
                                <Row>
                                    <Col sm={1} className="reminder-info">
                                        <i className='fas fa-info-circle'> </i>
                                    </Col>
                                    <Col sm={11} className="padL00 margin-left-15">
                                        <p className="reminder-info" > Payment and estimated voucher reminders have been disabled due to the delivered return. Taxpayers will not have access to the payment section.</p>
                                    </Col>
                                </Row>
                            }
                            <ul style={{ padding: 10, listStyleType: 'none' }}>
                                {this.props.model.signedDetails.map((i, index) =>
                                    <Row key={index}>
                                        <Col sm={10} className={this.isVisited(i) ?
                                            "" : "list-disabled"}>
                                            {i.name}  ({i.signer})
                                        </Col>
                                        <Col sm={1}>
                                            <a onClick={() => this.onResendAccessLink(i)} title="Resend"
                                                style={{ marginLeft: '7px' }} className='fas fa-share-square' href="javascript:void(0)" data-testid="resend-access-button" />
                                        </Col>
                                        <Col sm={1}>
                                            <CopyToClipboard text={this.isVisited(i) ?
                                                i.clientAccessLink : undefined} >
                                                <a title="Copy Link Address" onClick={() => this.onCopy(i)}
                                                    style={{ marginLeft: '7px' }} className='far fa-copy' href="javascript:void(0)" />
                                            </CopyToClipboard>
                                        </Col>
                                    </Row>
                                )}
                            </ul> </div> 
                        : ''
                    }
                    <Loader loading={(model === undefined)||
                        model.signedDetails === undefined ||
                         model.clientTracking === undefined} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onCancel}
                    variant="default"
                    className="btn btn-white"
                    data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                    <i className='fas fa-times'></i>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    private isVisited = (signerModel: ISignerModel | undefined): boolean => {

        var deliveredSigner=this.props.model.signedDetails.find(x => x.order===1)
        if(deliveredSigner.signerStatus != SignerStatus.NotSigned )
        {
            return true;
        }
        else{
            return (signerModel.signerStatus != SignerStatus.NotSigned || signerModel.order === 1);
        }
    }

    private isIntialEmailSent(signerModel: ISignerModel) {
        if (this.props.model.clientTracking !== undefined) {
            const DocumentMailstaus = this.props.model.clientTracking.filter(
                x => x.eventId === DocumentEvent.DocumentMailStatus &&
                    x.eventData.recipientInfo !== undefined &&
                    x.eventData.recipientInfo !== null &&
                    x.eventData.recipientInfo.emailAddress !== undefined &&
                    x.eventData.recipientInfo.emailAddress !== null &&
                    x.eventData.recipientInfo.emailAddress == signerModel.signer);

            return DocumentMailstaus.length > 0;
        }
        return false;
    }
    private onCopy(signerObj: any) {

        const model = this.props.model.signedDetails.find(x => x.signer == signerObj.signer &&
            x.signerType == signerObj.signerType);

        if (this.isVisited(model)) {
            VenusNotifier.Success("Access Link has been copied", null);
        } else {
            VenusNotifier.Warning("Copy access links have been disabled until all steps are completed by the first signer.", null);
        }
    }
    private onResendAccessLink(signerObj: any) {

        const model = this.props.model.signedDetails.find(x => x.signer == signerObj.signer &&
            x.signerType == signerObj.signerType);

        if (this.isVisited(model)) {
            this.setState({ message: msg.saving },
                () => {
                    if (model) { this.props.onResendAccessLink(model.documentId, model); }
                },
            );
        } else {
            VenusNotifier.Warning("Resend access links have been disabled until all steps are completed by the first signer.", null);
        }

    }

}
