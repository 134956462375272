import React from  'react';
import { IEmailTemplateMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';

interface IEmailMessageViewProps {
    selectedMessage: IEmailTemplateMessage;
}

export class EmailMessageView extends React.Component<IEmailMessageViewProps, {}>{
    constructor(props: IEmailMessageViewProps) {
        super(props);
    }

    public render() {
        return (
            <div className="col-lg-12 col-sm-12" style={{ width: '-webkit-fill-available', lineHeight: '3em', padding: '0px' }}>
                <div ><h4>{this.props.selectedMessage.name}</h4></div>
                <div >
                    <label>Header:</label>
                    <label style={{ fontWeight: 200, marginLeft: 10 }}>{this.props.selectedMessage.subject}</label>
                </div>
            <textarea rows={7} value={this.props.selectedMessage.text} className="form-control" disabled={true} />
            </div>
        )
    }
}