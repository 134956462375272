import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as RecycleReturnsStore from '../../../store/reports/RecycleReturns/RecycleReturnsStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as Component from './RecycleReturns';
import * as BulkOperationsStore from '../../../store/common/BulkOperationsStore';
import * as GroupedExtensionsStore from './../../../store/groupedExtensions/GroupedExtensionsStore';
import * as TaxSoftwareStore from '../../../store/SuiteCompany/TaxSoftwareStore';
import * as ExtensionUserSettingsStore from '../../../store/userManagement/ExtensionUserSettingStore';
import * as UserOfficeLocstionStore from '../../../store/common/UserOfficeLoacationStore';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';

export default connect(
    (state: ApplicationState) => ({
        recycleReturns: state.recycleReturns, // Selects which state properties are merged into the component's props
        usersStore: state.usersStore,
        userSettings: state.userSettings,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        company: state.companyData,
        groupedExtensionsStore: state.groupedExtensionsStore,
        taxSoftwareData: state.taxSoftwareData,
        extensionUserSettings: state.extensionUserSettings,
        userOfficeLocation:state.userOfficeLocation,
        taxDocuments: state.taxDocuments
    }),
    {
        ...RecycleReturnsStore.actionCreators,
        ...UserStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
        ...GroupedExtensionsStore.actionCreators,
        ...TaxSoftwareStore.actionCreators,
        ...ExtensionUserSettingsStore.actionCreators,
        ...UserOfficeLocstionStore.actionCreators,
        ...TaxDocumentStore.actionCreators
    }
)(Component.RecycleReturns) as any;

