import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { GetCountryCode, countryCodeDisplay, phoneNumberDisplay } from '../../helper/HelperFunctions';
import { PhoneNumberComponent } from '../../common/PhoneNumberComponent';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { IModalProps, IUserProfile, initialUserProfile } from './ProfileObjects';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Validation from '../../helper/Validations';
import { ValidationContants, OTPVerify, MyAccountConstants } from '../../helper/Constants';
import { Modal, Button } from 'react-bootstrap';
import ChangePassword from './ChangePassword';
import { isControlDisabled } from '../../helper/Validations';
import { actionCreators } from '../../../store/OTP/OneTimePassword';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { actionCreators as profileActionCreators } from '../../../store/userManagement/UserProfileStore';


const MyAccountLayout: React.FC<IModalProps> = ({ showState, onHide }) => {

    const userProfile = useSelector((state: ApplicationState) => state.userProfile)

    const [formDetail, setFormDetail] = useState<IUserProfile>(initialUserProfile)

    const [disableVerifyLink, setDisableVerifyLink] = useState(false)

    const [showOtpVerification, setShowOtpVerification] = useState(false)

    const [otpValue, setOtpValue] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const profile = useSelector((state: ApplicationState) => state.userProfile)

    const otpData = useSelector((state: ApplicationState) => state.otpData)

    const mfaSettings = useSelector((state: ApplicationState) => state.mfaSettingsStore);

    const dispatch = useDispatch();

    let accessCodeTimer: any;

    const closeModal = () => {
        setFormDetail({
            ...formDetail,
            emailAddress: userProfile.emailAddress,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            countryCode: userProfile.countryCode,
            fax: userProfile.fax,
            extension: userProfile.extension,
            phone: userProfile.phone,
            mobileNumber: userProfile.mobileNumber,
            readonlyFields: userProfile.readonlyFields,
            userId: userProfile.userId,
            isMobileVerify: userProfile.isMobileVerify
        })
        clearTimeout(accessCodeTimer);
        setShowOtpVerification(false);
        onHide();
    }

    const validateMobileNumber = () => {
        if (!Validation.NullandEmptyCheck(formDetail.mobileNumber)) {
            VenusNotifier.Warning(ValidationContants.MobileNumberWarning, "");
            return false;
        }
        else if (Validation.NullandEmptyCheck(formDetail.mobileNumber) && !Validation.validatePhoneLength(formDetail.mobileNumber)) {
            VenusNotifier.Warning(ValidationContants.MobileNumberLengthWarning, "");
            return false;
        }
        else if (formDetail.countryCode === "" || formDetail.countryCode === undefined) {
            VenusNotifier.Warning(ValidationContants.CountryCodeWarning, "");
            return false;
        }
        else {
            return true;
        }
    }

    const onFormChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        const data = { ...formDetail, [element.name]: element.value };
        setFormDetail(data);
    };

    const onFaxChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, fax: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onExtChange: React.ChangeEventHandler = e => {
        const element = e.target as HTMLInputElement;
        if (element.value.match(/^\d+$/) || element.value === "") {
            setFormDetail({ ...formDetail, extension: element.value });
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onChangeCountryCode: React.ChangeEventHandler<HTMLSelectElement> = (event: any = {}) => {
        const value = event ? event.value : "";
        setFormDetail({
            ...formDetail,
            countryCode: value,
            isMobileVerify: false,
        });
        setDisableVerifyLink(false)
    };

    const onChangeMobileNumber = (value: string) => {

        setFormDetail({
            ...formDetail,
            mobileNumber: value,
            isMobileVerify: false,
        });
        setDisableVerifyLink(false)
    }
    const onClickVerifyOTP = (event: any) => {

        if (validateMobileNumber()) {
            setDisableVerifyLink(true);
            accessCodeTimer = setTimeout(() => setDisableVerifyLink(false), 108000);
            let mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
            let countryCode = formDetail.countryCode;
            setShowOtpVerification(true)
            setOtpValue('')
            setIsLoading(true)
            dispatch(actionCreators.saveOneTimePassword(mobileNumber, countryCode, () => {
                setIsLoading(false);
            }))

        }
    }

    const onChangeOtpValue = (event: any) => {

        if (Validation.ValidateTenDigitNumber(event)) {
            let value = event.target.value;
            setOtpValue(value);
            if ((otpData.otpState.mfaOTPLength - 1) === otpValue.length) {
                let mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
                setIsLoading(true)
                dispatch(actionCreators.validateOTP(value, mobileNumber, hideVerifyLink, () => { setIsLoading(false) }))
            }
        }
    }

    const hideVerifyLink = () => {
        if (otpData.otpState.validOTP) {
            setFormDetail({
                ...formDetail,
                isMobileVerify: true
            })
            setShowOtpVerification(false)
        }
    }

    const submitMyAccount = () => {
        var retSaveMyAccount = true;
        if (!formDetail.firstName.trim()) {
            VenusNotifier.Warning(ValidationContants.FirstNameWarning, "");
            return retSaveMyAccount = false;
        }
        else if (!formDetail.lastName.trim()) {
            VenusNotifier.Warning(ValidationContants.LastNameWarning, "");
            return retSaveMyAccount = false;
        }
        else if (!formDetail.emailAddress.trim()) {
            VenusNotifier.Warning(ValidationContants.EmailAdrressWarning, "");
            return retSaveMyAccount = false;
        }
        else if (!Validation.isValidEmailAddress(formDetail.emailAddress.trim()) || !formDetail.emailAddress.trim()) {
            VenusNotifier.Warning(ValidationContants.ValidEmailAddressWarning, "");
            return retSaveMyAccount = false;
        }
        else if (profile?.isMFAEnabled && !Validation.NullandEmptyCheck(formDetail.mobileNumber)) {
            VenusNotifier.Warning(ValidationContants.MobileNumberWarning, "");
            return false;
        }
        else if (profile?.isMFAEnabled &&
            (Validation.NullandEmptyCheck(formDetail.mobileNumber) && !Validation.validatePhoneLength(formDetail.mobileNumber))) {
            VenusNotifier.Warning(ValidationContants.MobileNumberLengthWarning, "");
            return false;
        }
        else if (profile?.isMFAEnabled &&
            (formDetail.countryCode === "" || !formDetail.countryCode)) {
            VenusNotifier.Warning(ValidationContants.CountryCodeWarning, "");
            return false;
        }
        else {
            var phoneNumber = !formDetail.phone ? '' : formDetail.phone;
            if (phoneNumber.trim() != "") {
                if (Validation.validatePhone(formDetail.phone) == false) {
                    return retSaveMyAccount = false;
                }
            }
            if (showOtpVerification) {
                if (otpValue.trim().length == 0) {
                    VenusNotifier.Warning(OTPVerify.NotVerified, "");
                }
                else if (!otpData.otpState.validOTP) {
                    VenusNotifier.Warning(OTPVerify.InvalidOTP, "");
                    return retSaveMyAccount = false;
                }
            }

            var faxNumber = !formDetail.fax ? "" : formDetail.fax;
            if (faxNumber.trim() != "") {
                if (Validation.validateFax(formDetail.fax) == false) {
                    return retSaveMyAccount = false;
                }
            }
            var extension = !formDetail.extension ? "" : formDetail.extension;
            if (extension.trim() != "") {
                if (Validation.validateExtension(formDetail.extension) == false) {
                    return retSaveMyAccount = false;
                }
                else {
                    if (!formDetail.phone.trim()) {
                        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
                        return retSaveMyAccount = false;
                    }
                }
            }
        }
        if (retSaveMyAccount) {
            saveMyAccount();
        }
    }

    const saveMyAccount = () => {
        setIsLoading(true)

        dispatch(profileActionCreators.saveMyAccount(formDetail, (response: any, error: any) => {
            setFormDetail({ ...formDetail });
            setIsLoading(false);
            dispatch(profileActionCreators.requestUserProfile(true));
            if (error) {
                VenusNotifier.Error(error, "");
            } else {
                setFormDetail({
                    ...formDetail,
                });
                if (response) {
                    VenusNotifier.Success(MyAccountConstants.APIResponse.UserUpdateSuccess, "");
                    onHide();
                } else {
                    VenusNotifier.Error(MyAccountConstants.UserUpdateFailedMessage, "");
                }
            }
        }))
    }

    const [changePassword, setChangePassword] = useState(false)

    const onClickChangePassword = (event: any) => {
        setChangePassword(true)
    }

    useEffect(() => {
        setFormDetail({
            ...formDetail,
            emailAddress: userProfile.emailAddress,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            countryCode: userProfile.countryCode,
            fax: userProfile.fax,
            extension: userProfile.extension,
            phone: userProfile.phone,
            mobileNumber: userProfile.mobileNumber,
            readonlyFields: userProfile.readonlyFields,
            userId: userProfile.userId,
            isMobileVerify: userProfile.isMobileVerify,
        })
    }, [userProfile])

    return (
        <Modal className="header-my-account-modal" show={showState} onHide={closeModal}>
            <Modal.Header closeButton data-test-auto="7A232988-989E-4DEB-9B10-6EF9BDFBAA37">
                <Modal.Title className='custom-modal-header'>
                    <span className='fas fa-user ddl-icon my-account-icon' style={{ marginLeft: 0, padding: 0 }}></span>
                    Profile
                </Modal.Title>
            </Modal.Header>
            {
                <ChangePassword
                    showState={changePassword}
                    onHide={() => { setChangePassword(false) }}
                    hideProfileModal={onHide}
                />
            }
            <Modal.Body className="padding-0-px">
                <LoadingOverlay style={{ height: '100%' }}>

                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xl-6">
                            <div className="marB10 pos-relative">
                                <input type="text" className="form-control"
                                    placeholder="First Name"
                                    value={formDetail.firstName}
                                    name="firstName"
                                    onChange={onFormChange}
                                    data-test-auto="55A2B5B9-54F1-4E19-ABE7-A8C992027401"
                                    disabled={isControlDisabled("FirstName", profile)}
                                    data-lpignore="true"
                                ></input>
                                <span className="fas fa-asterisk my-profile-compulsory-star"></span>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-6">
                            <div className="marB10 pos-relative">
                                <input type="text" className="form-control"
                                    placeholder="Last Name"
                                    value={formDetail.lastName}
                                    name="lastName"
                                    onChange={onFormChange}
                                    data-test-auto="55A2B5B9-54F1-4E19-ABE7-A8C992027401"
                                    disabled={isControlDisabled("LastName", profile)}
                                    data-lpignore="true"
                                ></input>
                                <span className="fas fa-asterisk my-profile-compulsory-star"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <div className="marB10 pos-relative">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    value={formDetail.emailAddress}
                                    name="emailAddress"
                                    onChange={onFormChange}
                                    data-test-auto="3F21CD09-C6AF-46F0-81A4-F42934F36903"
                                    disabled={isControlDisabled("EmailAddress", profile)}
                                    data-lpignore="true"
                                />
                                <span className="fas fa-asterisk my-profile-compulsory-star" />
                            </div>
                            <div className="marB10">
                                <input type="phone"
                                    maxLength={10}
                                    className="form-control"
                                    placeholder="Fax Number"
                                    value={formDetail?.fax}
                                    name="faxNumber"
                                    onChange={onFaxChange}
                                    data-test-auto="C0402DCD-B191-47C1-BACD-28AE23AF3AE9"
                                    disabled={isControlDisabled("Fax", profile)}
                                    data-lpignore="true" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-md-3 col-xl-3">
                            <div className="marB10 pos-relative">
                                <Select
                                    name="form-field-country-code"
                                    value={formDetail.countryCode}
                                    onChange={onChangeCountryCode}
                                    options={GetCountryCode()}
                                    data-test-auto="36B0F044-D3EA-4EB1-AC67-BCC81343A99C"
                                    clearable={false}
                                />
                                {
                                    mfaSettings?.isMfaEnabled ?
                                        <span className="fas fa-asterisk my-profile-compulsory-star"></span> : null
                                }
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-5 col-xl-5">
                            <div className="marB10 pos-relative">
                                <PhoneNumberComponent
                                    phoneNumber={formDetail.mobileNumber}
                                    handleChangePhoneNumber={onChangeMobileNumber}
                                    disabled={false}>
                                </PhoneNumberComponent>
                                {
                                    mfaSettings?.isMfaEnabled ?
                                        <span className="fas fa-asterisk my-profile-compulsory-star"></span> : null
                                }
                            </div>
                        </div>
                        {
                            !formDetail.isMobileVerify &&
                            <div className="col-sm-4 col-md-4 col-xl-4">
                                <a className={disableVerifyLink ? 'disableAnchor' : 'enableAnchor'} href="javascript:void(0)" onClick={onClickVerifyOTP} ><u>
                                    Verify
                                </u> </a>
                                <Link to={'#'}
                                    className="help-icon marL05"
                                    data-placement="right"
                                    data-toggle="tooltip"
                                    title="You can skip the verification step. On next login, you will be prompted to verify the mobile number">
                                    <i className="fas fa-question-circle"></i>
                                </Link>
                            </div>
                        }
                    </div>
                    {showOtpVerification ?
                        <div className="row padB10">
                            <div className="col-sm-8 col-md-8 col-xl-8">
                                Enter the access code sent to {countryCodeDisplay(formDetail.countryCode)}
                                {phoneNumberDisplay(formDetail.mobileNumber)}
                            </div>
                            <div className="col-sm-3 col-md-3 col-xl-3">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Access code"
                                    value={otpValue}
                                    onChange={onChangeOtpValue}
                                    data-test-auto="5E794F51-B0E5-4780-80C5-817296F78AB0"
                                    maxLength={otpData.otpState.mfaOTPLength}
                                />
                            </div>
                        </div> : null
                    }
                    <div className="row">
                        <div className="col-sm-9 col-md-9 col-xl-9">
                            <div className="marB10">
                                <PhoneNumberComponent
                                    phoneNumber={formDetail.phone}
                                    handleChangePhoneNumber={(value) => setFormDetail({ ...formDetail, phone: value })}
                                    disabled={false}>
                                </PhoneNumberComponent>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xl-3">
                            <div className="marB10">
                                <input type="text"
                                    className="form-control"
                                    name="extension"
                                    placeholder="Ext"
                                    value={formDetail.extension}
                                    onChange={onExtChange}
                                    data-test-auto="FD934570-D098-4381-9D25-C98C0F4D7556"
                                    disabled={isControlDisabled("Extension", profile)}
                                    data-lpignore={"true"} />
                            </div>
                        </div>
                    </div>
                    {
                        formDetail && formDetail.readonlyFields
                            && formDetail.readonlyFields.length > 0 ? null : (
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <i className='fas fa-user-shield padR05'></i>
                                    <a href="javascript:void(0)" onClick={onClickChangePassword} ><u>Change Password</u></a>
                                </div>
                            </div>
                        )}
                    <Loader
                        classNamePrefix="sm"
                        text=""
                        loading={isLoading} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-between" }}>
                <div>
                    <span className="modal-footer-left float-left padT07">
                        <span className="fas fa-asterisk compulsory-star-only padR05"></span>
                        <span>Required</span>
                    </span>
                </div>
                <div>
                    <Button
                        variant='default'
                        onClick={closeModal}
                        className="btn-white"
                        data-test-auto="4A0C3490-369E-49E9-A36A-3139973D5A81">
                        <i className='fas fa-times'></i>Cancel
                    </Button>

                    <Button
                        variant='info'
                        disabled={!profile.isMobileVerify && showOtpVerification}
                        onClick={submitMyAccount}
                        data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B">
                        <i className='fas fa-save'></i>  Save
                    </Button>

                </div>
            </Modal.Footer>

        </Modal>
    );
}

export default MyAccountLayout;
