import React, { FC, useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import SendReminderNow from "./SendReminderNow";
import ScheduledReminder from "./ScheduledReminder";
import { IDeliveredTaxDocument, IScheduleReminderPayload, IVoucherStatus } from "../DeliveredTaxReturns";
import { IDocumentNotificationSetting } from "../TaxReturn";
import { isInValidSignatureStatus } from "src/components/helper/HelperFunctions";

interface IReminderModalProps {
    show: boolean;
    onHide: () => void;
    onSendReminderNow: (ids: number[]) => void;
    onSendScheduledReminder: (ids: number[], IScheduleReminderPayload: IScheduleReminderPayload) => void;
    selectedDocuments: IDeliveredTaxDocument[];
    selectedVoucherStatus?: IVoucherStatus;
    totalCount: number;
}

const ReminderModal: FC<IReminderModalProps> = ({
    show,
    onHide,
    onSendReminderNow,
    selectedDocuments,
    onSendScheduledReminder,
    selectedVoucherStatus,
    totalCount }) => {

    const [key, setKey] = useState<string>('send_reminder_now');
    const [schedulePayload, setSchedulePayload] = useState<IScheduleReminderPayload>();
    const [disableSigningReminder, setDisableSigningReminder] = useState<boolean>(false);
    const [disableVoucherReminder, setDisableVoucherReminder] = useState<boolean>(false);
    const selectedDocumentIds = selectedDocuments.map(document => document.document.id);
    const [documentSettings, setDocumentSettings] = useState<IDocumentNotificationSetting>();
    const [signingReminder, setSigningReminder] = useState<boolean>(false);

    useEffect(() => {
        if (selectedDocuments.length === 1) {
            const { signatureStatus } = selectedDocuments[0].document;
            setDisableVoucherReminder(selectedVoucherStatus !== IVoucherStatus.Valid);
            if (selectedDocuments[0].document.documentSettings) {
                setDocumentSettings(selectedDocuments[0].document.documentSettings.documentNotificationSetting);
            }
            setDisableSigningReminder(isInValidSignatureStatus(signatureStatus));
        } else {
            setDisableVoucherReminder(false);
            setDisableSigningReminder(false);
            setDocumentSettings(undefined);
        }
    }, [selectedDocuments, selectedVoucherStatus]);

    useEffect(() => {
        if (!show) { 
            setKey("send_reminder_now"); 
            setSigningReminder(false);
        }
    }, [show]);

    const handleSend = () => {
        if (key === 'send_reminder_now') {
            onSendReminderNow(selectedDocumentIds);
        } else {
            schedulePayload && onSendScheduledReminder(selectedDocumentIds, schedulePayload);
        }
    };

    const isSendReminderNow = (key: string) => key === 'send_reminder_now';

    return <Modal show={show} className="reminder-modal" onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Send Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tabs className="custom-tab" fill id="send_reminder" activeKey={key} onSelect={(key) => setKey(key)}>
                <Tab eventKey="send_reminder_now" title={
                    <div>
                        <i className="fas fa-paper-plane"></i>
                        <span className="ml-2">Send Reminder Now</span>
                    </div>
                }>
                    <SendReminderNow
                        signingReminder={signingReminder}
                        setSigningReminder={setSigningReminder}
                        selectedDocumentIds={selectedDocumentIds}
                        disabledSigningReminder={disableSigningReminder}
                        showReminderText={schedulePayload?.signingReminder?.enabled}
                        totalCount={totalCount} />
                </Tab>
                <Tab eventKey="scheduled_automated_reminder" title={
                    <div>
                        <i className="fas fa-calendar-check"></i>
                        <span className="ml-2">Scheduled Automated Reminder</span>
                    </div>
                }>
                    <ScheduledReminder
                        documentSettings={documentSettings}
                        selectedDocumentIds={selectedDocumentIds}
                        setSchedulePayload={setSchedulePayload}
                        disableSigningReminder={disableSigningReminder}
                        disableVoucherReminder={disableVoucherReminder}
                        totalCount={totalCount} />
                </Tab>
            </Tabs>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="default" onClick={onHide}>
                <i className="fas fa-times"></i> Cancel
            </Button>
            <Button variant="info" onClick={handleSend} disabled={isSendReminderNow(key) && (disableSigningReminder || !signingReminder)}>
                {isSendReminderNow(key) ? <><i className="fas fa-paper-plane"></i>Send</> : <><i className='fas fa-save'></i>Save</>}
            </Button>
        </Modal.Footer>
    </Modal>
};

export default ReminderModal;