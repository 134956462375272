import React from  'react';
import { ProgressBar, Modal, Button } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from './UploadDocument/DocumentUploadDropzoneComponent';
import { UploadedDocumentsTable } from './UploadDocument/UploadedDocumentsTable';
import * as Request from 'superagent';
import { getFileExtension, bootboxAlert } from '../helper/Validations';
import * as Enumerable from 'linq';
import * as Validation from '../helper/Validations';
import * as bootbox from 'bootbox';
import { ITaxReturn } from '../common/TaxReturn';
import { getFileSize, GetFileMagicNumber } from '../helper/HelperFunctions';
import { TaxReturnUploadData, UploadStatus } from '../../Core/ViewModels/Common/UploadDocumentViewModel';
import { OverlayLoader } from '../helper/OverlayLoader';
import { validateFileSize } from '../helper/Validations';
import { initialGroupInfo } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { API_BASE_URL } from 'src/utils/contants';
import { RBACKeys } from '../helper/RbacConstants';

interface IUploadFormsProps {
    show: boolean;
    onCancel: () => void;
    taxReturn: ITaxReturn,
    onUploadFormsSuccess: (documentId: number, files: string[]) => void;
    isArchived:boolean
}

interface IUploadFormsState {
    componentConfig: any;
    djsConfig: any;
    TaxReturnUploadData: TaxReturnUploadData[],
    gridColumnDisable: boolean,
    process: number,
    fileName: string,
    fileSize: number,
    clientId: string,
    sasContainer: SasContainer[]
}

interface SasContainer {
    sasGuid: string
}

export class UploadForms extends React.Component<IUploadFormsProps, IUploadFormsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            componentConfig: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            djsConfig: {
                uploadMultiple: true,
                addRemoveLinks: true,
                acceptedFiles: "application/pdf",
                headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
                method: 'PUT',
                previewsContainer: false,
                autoProcessQueue: false,
                autoDiscover: false
            },
            TaxReturnUploadData: [],
            clientId: "",
            fileName: "",
            fileSize: 0,
            gridColumnDisable: true,
            process: 0,
            sasContainer: []
        }
        this.IsFileExist = this.IsFileExist.bind(this);
    }

    defaultType(cell: any, row: any) {
        return cell;
    }

    statusFormatter(cell: any, row: any) {
        return (<span>{UploadStatus[cell].toString()}</span>);
    }


    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={(cell != undefined) && (cell != 100) ? "warning" : "success"} now={cell} />
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter deleteReturn={() => this.deleteReturn(row)} disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
        );
    }

    deleteReturn(row: any) {
        let _self = this;
        bootbox.confirm("Are you sure you want to delete this file ?", function (result) {
            if (result) {
                var _gridData = [..._self.state.TaxReturnUploadData];
                _gridData = _gridData.filter(i => i.number != row.number);
                for (let i = row.number - 1; i < _gridData.length; i++) {
                    _gridData[i].number = i + 1;
                }
                let _tempgridData = _self.state.TaxReturnUploadData;
                _tempgridData = _gridData;


                let _uploadData = [..._self.state.sasContainer];
                _uploadData = _uploadData.filter(i => i.sasGuid != row.sasGuid);

                let _tempUploadData = _self.state.sasContainer;
                _tempUploadData = _uploadData;

                _self.setState({ TaxReturnUploadData: _tempgridData, sasContainer: _tempUploadData });
            }
        });
    }

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            let _self = this;
            let tempGridData = _self.state.TaxReturnUploadData;
            Enumerable.from(files).toArray().map((file: any, i: number) => {
                if (validateFileSize(file)) {
                    let fileName = file.name;
                    let fileExtension = getFileExtension(fileName);
                    GetFileMagicNumber(file).then((result) => {
                        if (Validation.validatePdfFile(fileExtension, result)) {
                            let _fetchThis = this;
                            let filecount = 1;

                            while (_self.IsFileExist(fileName, tempGridData) > 0) {
                                fileName = file.name;
                                fileName = fileName.replace("." + fileExtension, '');
                                fileName = fileName + " - Copy (" + filecount + ")." + fileExtension;
                                filecount++;
                            }

                            tempGridData.push({
                                name: fileName, clientName: "clname", clientId: fileName.replace("." + fileExtension, ''),
                                number: tempGridData.length + 1,
                                progressBar: _self.state.process, setAccess: "Everyone", size: getFileSize(file.size),
                                status: UploadStatus.Uploading, sasGuid: '', gridRowDisable: true,
                                documentAccess: { documentId: 0, userId: [] }, groupInfo: initialGroupInfo,
                            });

                            if (tempGridData.length > 1) {
                                tempGridData.sort((a: any, b: any) => {
                                    if (a.name < b.name && a.number < b.number) return -1;
                                    if (a.name > b.name && a.number > b.number) return 1;
                                    return 0;
                                });
                            }

                            for (let i = 0; i < tempGridData.length; i++) {
                                let index = tempGridData.findIndex(x => x.name == tempGridData[i].name);
                                tempGridData[index].number = index + 1;
                            }

                            _self.setState({ TaxReturnUploadData: tempGridData, gridColumnDisable: true });
                            const uploadManualFormExtensionResourceId = this.props.isArchived ? 
                            RBACKeys.ArchivedExtension.uploadForms :
                            RBACKeys.ExtensionReport.uploadForms

                            fetch(
                                `${API_BASE_URL}/api/Upload/GetUploadFormsLinkAsync?documentKey=` + this.props.taxReturn.documentGuid + "&fileName=" + fileName + "&taxYear=" + this.props.taxReturn.taxYear, 
                                { 
                                    credentials: 'include',
                                    headers : {
                                        'X-Resource-Id': uploadManualFormExtensionResourceId
                                    }
                                }
                                
                                )
                                .then((resp) => resp.json())
                                .then(function (data) {
                                    let index = tempGridData.findIndex(x => x.name === fileName);
                                    try {
                                        if (data) {
                                            tempGridData[index].sasGuid = data.guid;

                                            _self.setState({ TaxReturnUploadData: tempGridData }, () => _fetchThis.uploadedFile(file, data, fileName, files.length));
                                        } else {
                                            tempGridData.splice(index, 1);
                                            _self.setState({ TaxReturnUploadData: tempGridData, gridColumnDisable: false });
                                        }
                                    } catch (err) {
                                        tempGridData.splice(index, 1);
                                        _self.setState({ TaxReturnUploadData: tempGridData, gridColumnDisable: false });
                                    }
                                });
                        }
                    });
                }
            });
        }
    }

    public IsFileExist(fileName: string, tempGridData: TaxReturnUploadData[]) {
        return Enumerable.from(tempGridData).where(j => j.name == fileName).count();
    }

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGridData: TaxReturnUploadData[] = [];
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({ sasContainer: tempUploadData, TaxReturnUploadData: tempGridData, gridColumnDisable: true });
    }
    public uploadedFile(fileObject: any, sas: any, fileName: string, filesLength: number) {
        let fileToUpload = {
            maxBlockSize: 0,
            file: 0,
            size: 0,
            uploadFailed: false,
            bytesUploaded: 0,
            sasUrl: 0,
            completeEvent: 0,
            blockIds: new Array(),
            uploadedIds: new Array(),
            filecontent: new Array(),
            blockIdPrefix: "",
            numberOfBlocks: 0,
            fileName: "",
            fileGUID: ""
        };
        fileToUpload.maxBlockSize = 4096 * 1024;
        fileToUpload.file = fileObject;
        fileToUpload.size = fileObject.size;
        fileToUpload.uploadFailed = false;
        fileToUpload.bytesUploaded = 0;
        fileToUpload.fileGUID = sas.guid;
        if (fileToUpload.size < fileToUpload.maxBlockSize) {
            fileToUpload.maxBlockSize = fileToUpload.size;
        }
        if (fileToUpload.size % fileToUpload.maxBlockSize == 0) {
            fileToUpload.numberOfBlocks = fileToUpload.size / fileToUpload.maxBlockSize;
        } else {
            fileToUpload.numberOfBlocks = parseInt((fileToUpload.size / fileToUpload.maxBlockSize).toString(), 10) + 1;
        }
        fileToUpload.sasUrl = sas.sas
        //fileToUpload.completeEvent = fileObject.ProgressUpdater; //ToDo
        fileToUpload.blockIdPrefix = "block-";
        fileToUpload.fileName = fileName;
        this.uploadFileInBlocks(fileToUpload, filesLength)
    }


    public uploadFileInBlocks(fileToUpload: any, filesLength: number) {
        let _uploadFileThis = this;
        for (let i = 0; i < fileToUpload.numberOfBlocks; i++) {

            let fileContent;
            if ((i + 1) * fileToUpload.maxBlockSize < fileToUpload.file.size)
                fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize, (i + 1) * fileToUpload.maxBlockSize);
            else
                fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize, fileToUpload.file.size);

            var blockId = fileToUpload.blockIdPrefix + this.pad(fileToUpload.blockIds.length, 6);
            fileToUpload.blockIds.push(btoa(blockId));
            fileToUpload.filecontent.push(fileContent);
        }
        var ajaxcaller = 10;
        if (fileToUpload.numberOfBlocks < 10)
            ajaxcaller = fileToUpload.numberOfBlocks;
        for (let i = 0; i < ajaxcaller; i++) {
            fileToUpload.nextItemtoUpload = ajaxcaller;
            _uploadFileThis.initReaderObject(fileToUpload, fileToUpload.blockIds[i], fileToUpload.filecontent[i], 0, filesLength);
        }
    }

    public pad(number: number, length: number) {
        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }


    public initReaderObject(fileToUpload: any, blockId: any, fileContent: any, retry: any, filesLength: number) {
        let readerObject: any = new FileReader();
        let _initThis = this;
        readerObject.onloadend = function (evt: any) {
            if (evt.target.readyState == 2) { // DONE == 2
                var uri = fileToUpload.sasUrl + '&comp=block&blockid=' + blockId;
                var requestData = new Uint8Array(evt.target.result);

                Request.put(uri)
                    .set('Access-Control-Allow-Origin', '*')
                    .set("x-ms-blob-type", "BlockBlob")
                    .send(requestData)
                    .on('progress', function (e: any) {
                        _initThis.setState({
                            process: e.percent
                        });

                        let tempGridData = _initThis.state.TaxReturnUploadData;

                        tempGridData.map((tempGridDataValue, index) => {
                            if (tempGridDataValue.name == fileToUpload.fileName) {
                                tempGridDataValue.progressBar = e.percent;
                            }
                            if (tempGridDataValue.progressBar == 100) {
                                tempGridDataValue.status = UploadStatus.Uploaded;
                                tempGridDataValue.gridRowDisable = false;
                                //Call to update status
                                _initThis.setState({
                                    gridColumnDisable: false
                                });
                            }
                        });

                    }.bind(_initThis))
                    .end((err, res) => {
                        fileToUpload.bytesUploaded += requestData.length;
                        fileToUpload.uploadedIds.push(blockId);
                        if (fileToUpload.blockIds[fileToUpload.nextItemtoUpload]) {
                            _initThis.initReaderObject(fileToUpload, fileToUpload.blockIds[fileToUpload.nextItemtoUpload], fileToUpload.filecontent[fileToUpload.nextItemtoUpload], 0, filesLength);
                            fileToUpload.nextItemtoUpload = fileToUpload.nextItemtoUpload + 1;
                        } else {
                            if (fileToUpload.uploadedIds.length === fileToUpload.blockIds.length)
                                _initThis.commitBlockList(fileToUpload, filesLength);
                        }
                    })
            }
        };
        readerObject.readAsArrayBuffer(fileContent);
    }


    public commitBlockList(fileToUpload: any, filesLength: number) {
        let _commitBlockListThis = this;
        var uri = fileToUpload.sasUrl + '&comp=blocklist';
        var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (var i = 0; i < fileToUpload.blockIds.length; i++) {
            requestBody += '<Latest>' + fileToUpload.blockIds[i] + '</Latest>';
        }
        requestBody += '</BlockList>';
        var encryptedFileName = '';
        try {
            encryptedFileName = btoa(fileToUpload.fileName);
        } catch (exp) {
            try {
                encryptedFileName = btoa(encodeURIComponent(fileToUpload.fileName));
            } catch (exp) {
                encryptedFileName = fileToUpload.fileName.replace(/\W/g, '');
                encryptedFileName = btoa(encryptedFileName);
            }
        }

        Request.put(uri)
            .set('Access-Control-Allow-Origin', '*')
            .set('x-ms-blob-content-disposition', 'signedFormUpload;filename=' + '"' + encryptedFileName + '"')
            .withCredentials()
            .send(requestBody)
            .on('progress', function (e: any) {

            }.bind(_commitBlockListThis))
            .end((err, res) => {
            })
    }

    onSubmitUploadForms = () => {
        let files: any = this.state.TaxReturnUploadData.map(data => data.name);
        this.props.onUploadFormsSuccess(this.props.taxReturn.id, files);
    }

    handleClose = () => {
        this.reset();
        this.props.onCancel();
    }

    public render() {
        const data = this.state.TaxReturnUploadData;

        const columns = [
            {
                header: '#',
                key: 'number',
                isKey: true,
                dataFormat: this.defaultType,
                width: '30px',
                columnClassName: ''
            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: 'word-Visible'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbar,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusFormatter,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: '',
                key: 'button',
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: '90px',
                columnClassName: ''
            }
        ];

        const pageSize = 10;

        return <div>
            <Modal className="upload-forms-modal" show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton data-test-auto="81B7EA09-8E1C-4B16-BEDE-9937F197882F">
                    <Modal.Title>Upload Forms</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.componentConfig}
                                djsConfig={this.state.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"D505C40A-D324-4E02-A323-E9947496D852"}
                            />
                        </div>
                        <div className="col-sm-10 uploaded-documents-table-container" id="uploadDocument">
                            <UploadedDocumentsTable
                                column={columns}
                                data={this.state.TaxReturnUploadData} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="default"
                        className="btn-white"
                        onClick={this.handleClose}
                        data-test-auto="CA121902-21AE-4F15-BEE3-E6FE525647F2">
                        <i className='fas fa-times'></i>Close</Button>

                    <Button variant="info" onClick={this.onSubmitUploadForms}
                        disabled={this.state.gridColumnDisable}
                        data-test-auto="333FBE3A-980D-403B-9DF0-60AFD48DB862">
                        <i className='fas fa-save'></i>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}
export default UploadForms;

interface IButtonFormatterProps {
    deleteReturn: any,
    disabled: boolean
}

class ButtonFormatter extends React.Component<IButtonFormatterProps, {}> {
    render() {
        return (
            <button type="button" className="btn btn-danger padTB15" disabled={this.props.disabled} onClick={this.props.deleteReturn}><i className="fas fa-times" data-test-auto="FC022896-A0F0-4170-AEB5-28F4BABF0649"></i></button>
        );
    }
}
