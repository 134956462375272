import { IUserGroups } from '../../../Core/ViewModels/User/UserViewModel';
import { AuthenticationProvider } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';

export interface IModalProps {
	caption?: string;
	route?: string;
	showState: boolean;
	onHide(): void;
};

export interface IUserProfile {
	userId: number,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phone: string,
    extension: string,
    fax: string,
    title: string,
    countryCode: string,
    mobileNumber: string,
    isMobileVerify: boolean,
    ptin: string,
    createdOn: string,
    isDeleted: number,
    metadata: string,
    authenticationProvider: AuthenticationProvider[],
    deviceId: string,
	isMFAEnabled: boolean,
    modifiedBy: number,
    readonlyFields: string[],
    revokeStatus: number
}

export interface ILoginHistoryResponse {
	deviceId: string;
	loginHistories: { 
		count: number; 
		items: IUserLoginHistoryWithId[] 
	}
}

export interface IUserLoginHistory {
	userEmail: string;
	loggedInOn: string;
	loggedOutOn: string;
	ipAddress: string;
	clientApp: string;
	deviceId: string;
	customData: string;
}
export interface IUserLoginHistoryWithId extends IUserLoginHistory {
	id: number
}

export const initialUserProfile: IUserProfile = {
	firstName: '',
	lastName: '',
	phone: '',
	extension: '',
	fax: '',
	emailAddress: '',
	userId: 0,	
	title: "",
    ptin: "",
	metadata: "",
	countryCode: "",
	mobileNumber: "",
	isMobileVerify: false,
	isDeleted: 0,
	createdOn: "",
    authenticationProvider: [AuthenticationProvider.AzureAD],
    deviceId: "",
    isMFAEnabled: false,
    modifiedBy: 0,
    readonlyFields: ["0"],
    revokeStatus: 0,
}

export interface IOneTimePassword {
	id: number;
	otp: string;
	retryCount: number;
	otpType: OTPType;
	address: string;
	createdOn: Date;
	otpData: IOTPData;
}

export enum OTPType {
	MFA = 1,
	MobileVerification = 2
}

export interface IOTPData {
	userId: number;
}

export const initialOneTimePassword: IOneTimePassword = {
	id: 0,
	otp: '',
	retryCount: 0,
	otpType: OTPType.MobileVerification,
	address: '',
	createdOn: new Date(),
	otpData: {
		userId: 0
	}
}

export interface IOTPState {
	mfaOTPLength: number;
	validOTP: boolean;
}

export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface IPasswordSettings {
    passwordOptions: PasswordOptions
    length: number;
    passwordAge: number;
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8
}


export enum PasswordPolicyType {
    NoPolicy = "NoPolicy",
    SSRDefault = "SSRDefault",
    FirmDefinedPolicy = "FirmDefinedPolicy"
}

export interface IPasswordState {
    password: string;
    newPassword: string;
}