import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { API_BASE_URL } from "src/utils/contants";
import { AppThunkAction } from "..";

export interface PostAuthStoreState {
    extensionEnabled: boolean;
    loading: boolean;
    loggedOut: boolean;
}

const initalPostAuthStoreState: PostAuthStoreState = {
    extensionEnabled: false,
    loading: false,
    loggedOut: false
}

type KnownAction = LoaderAction | RecieveDataAction | LoggedOutAction;

export interface LoaderAction {
    type: "PostAuthStoreLoader";
    loading: boolean;
}

export interface RecieveDataAction {
    type: "PostAuthStoreReceiveData";
    extensionEnabled: boolean;
}

export interface LoggedOutAction {
    type: "LoggedOut";
    loggedOut: boolean;
}

let authDataExists = false;

export const actionCreators = {

    requestPostAuthData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (authDataExists) {
            return;
        }

        dispatch({ type: "PostAuthStoreLoader", loading: true });

        const fetchTask = fetch(`${API_BASE_URL}Home/GetPostAuthData`, { credentials: 'include' })
            .then((resp) => resp.json())
            .then(data => {
                authDataExists = true;
                dispatch({ type: "PostAuthStoreReceiveData", extensionEnabled: data.extensionEnabled })
                dispatch({ type: "PostAuthStoreLoader", loading: false });
            }).catch(() => {
                dispatch({ type: "PostAuthStoreLoader", loading: false });
            });

        addTask(fetchTask);
    },
    loggedOut: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "LoggedOut", loggedOut: true });
    }
}

export const reducer: Reducer<PostAuthStoreState> = (
    state: PostAuthStoreState = initalPostAuthStoreState,
    incomingAction: Action) => {

    const action = incomingAction as KnownAction;

    switch (action.type) {

        case "PostAuthStoreLoader":
            return { ...state, loading: action.loading }

        case "PostAuthStoreReceiveData":
            return { ...state, extensionEnabled: action.extensionEnabled }

        case "LoggedOut":
            return { ...state, loggedOut: action.loggedOut }

        default: return state || initalPostAuthStoreState;
    }
}