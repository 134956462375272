import React from  'react';
import { RouteComponentProps } from 'react-router';
import * as Bootstrap from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import * as  IClientMessageStoreState from '../../../../store/settings/ClientMessageStore';
import { ClientMessageList } from './ClientMessageList';
import { ClientMessageView } from './ClientMessageView';
import { initialClientMessage, IClientMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface IClientMessageProps {
    heading: string;
    description: string;
    clientMessages: IClientMessageStoreState.IClientMessageDictionary;
    defaultMessage: number;
    selectedMessage: number;
    onListItemClick: (messageId: number) => void;
    typeId: number;
    onAddButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onEditButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onDeleteButtonClick: (selectedMessageId: number, defaultMessage: number) => void;
    showLoader: boolean;
    loaderText: string;
}


export class ClientMessage extends React.Component<IClientMessageProps, {}> {
    constructor(props: IClientMessageProps) {
        super(props);
    }

    public render() {
        return (<LoadingOverlay style={{ height: '100%' }}>
            <div className="row">
                <div style={{ marginLeft: 16, marginBottom: 10, fontSize: '14px' }}>
                    <h3 className="text-primary">{this.props.heading}</h3>
                    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{this.props.description}</span>
                </div>
            </div>
            <div className="row" style={{ position: "relative" }}>
                <div className="col-lg-4 col-sm-6" style={{ marginLeft: 17 }}>
                    <div className="row" style={{ display: 'flow-root' }}>
                        <div className="pull-right">
                            <Bootstrap.Button
                                data-test-auto="4A8F0C0A-F71D-4423-BC7C-BAACBCF206BC"
                                variant="info"
                                className="btn btn-lg"
                                style={{ padding: 12, minWidth: 40 }}
                                onClick={() => this.props.onAddButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId)}
                                title="Add Message"
                                data-resource-id={RBACKeys.Settings.settings}>
                                <span className="fas fa-plus" 
                                />
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 207, border: 'rgb(221, 221, 221) 1px solid', fontSize: '14px' }}>
                        <div style={{ width: '100%' }}>
                            <ClientMessageList
                                clientMessages={this.props.clientMessages}
                                defaultMessage={this.props.defaultMessage}
                                selectedMessage={this.props.selectedMessage}
                                onListItemClick={this.props.onListItemClick}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-6" style={{ marginLeft: 32 }}>
                    <div className="row" style={{ display: 'flow-root' }}>
                        <div className="pull-right">
                            <Bootstrap.Button
                                data-test-auto="00A11CE7-0970-4A9A-B21D-BFC0AC56732B"
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={() => this.props.onEditButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId)}
                                data-resource-id={RBACKeys.Settings.settings}
                            >
                            </Bootstrap.Button>
                            <Bootstrap.Button
                                data-test-auto="9A0E3F03-0157-49D1-BAD6-57EF2EC44C71"
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={() => this.props.onDeleteButtonClick(this.props.selectedMessage, this.props.defaultMessage)}
                                data-resource-id={RBACKeys.Settings.settings}
                            >
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row text-message">
                        <ClientMessageView
                            selectedMessage={this.props.clientMessages[this.props.selectedMessage] ? this.props.clientMessages[this.props.selectedMessage].clientMessage : initialClientMessage}
                        />
                    </div>
                </div>
            </div>
            <Loader loading={this.props.showLoader} />
        </LoadingOverlay>)
    }
}