import React from  'react';
import * as Bootstrap from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { UploadDocumentModal } from '../../common/UploadDocumentModal';
import { IDropzoneConfigState } from '../../common/UploadDocument/DropzoneConfigModel';
import { Link } from 'react-router-dom';
import { IDocumentAccess, SignatureStatus } from '../../common/TaxReturn';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import Select from 'react-select';
import { PrepareTaxYear } from '../../helper/HelperFunctions';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import { UploadFabButton } from '../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from '../../navigation/profile/MySettings';
import { GroupAccess, GroupInfo } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { ITaxSoftwareSettings, TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';

interface InUseExtensionsHeaders {
    pageTitle: string,
    extensionUsers: IUserModel[],
    partners: IUserModel[];
    selectedERO: number,
    selectedDocumentCount: number,
    onMakeAvailable: (rowIndex: number) => void;
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    uploadTaxReturn: UploadTaxReturnStore.IUploadTaxReturnState;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    updateGroupInfoState: (reload: boolean) => void;
    groupInfo: GroupInfo[];
    groupAccess: GroupAccess[];
    defaultGroup: string;
    taxSoftwareData: ITaxSoftwareSettings;
    getTaxDocumentsAccessMaps: (ids: number[], callback?: (result: IDocumentAccess) => void ) => void;
}
interface InUseExtensionsHeadersState {
    show: boolean,
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class InUseExtensionsHeader extends React.Component<InUseExtensionsHeaders, InUseExtensionsHeadersState> {
    constructor(props: InUseExtensionsHeaders) {
        super(props);
        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None
        };
    }

    private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    handleClose() {
        this.setState({ show: false });
    }

    public render() {

        return <div>
            <div className="row marB10">
                <div className="col-md-8">
                    <h3 className="page-title">{this.props.pageTitle}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <Button
                        variant="default"
                        className="btn btn-white"
                        disabled={this.props.selectedDocumentCount === 0}
                        onClick={(e: any) => this.props.onMakeAvailable(NO_INDEX)}
                        data-resource-id={RBACKeys.lockedExtension.unlockButton}
                        data-test-auto="32211A05-F0BF-418B-8123-3943D06DDFE2">
                        <i className="fas fa-lock-open"></i>Unlock Extension
					</Button>

                </div>
                <div className="col-md-4 txt-ar">
                    <UploadFabButton
                        companySettings={this.props.companySettings}
                        userDefaultSettings={this.props.userDefaultSettings}
                        onUploadReturnsClick={this.onUploadReturnsOpen}
                        pageId="lockedExtension"
                        taxSoftwareData={this.props.taxSoftwareData}
                    />
                </div>
            </div>
            <UploadDocumentModal
                onShow={this.state.show}
                onHide={this.handleClose}
                extensionUsers={this.props.extensionUsers}
                partners={this.props.partners}
                taxSoftware={this.state.selectedTaxSoftware}
                selectedERO={this.props.selectedERO}
                isRedirect={true}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.props.onPageReload}
                defaultGroup={this.props.defaultGroup}
                groupInfo={this.props.groupInfo}
                groupAccess={this.props.groupAccess}
                updateGroupInfoState={this.props.updateGroupInfoState}
                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                getTaxDocumentsAccessMaps={this.props.getTaxDocumentsAccessMaps}
            />
        </div >
    }
}
export default InUseExtensionsHeader;