import React from  'react';
import { Alert, Modal, Col, FormGroup, FormLabel, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { FaRandom } from 'react-icons/fa';
import { DocumentStatus } from './../../../common/TaxReturn';
import { PrepareUserList } from './../../../helper/UserHelperFunctions';
import { DropdownComponent } from './../../../common/controls/DropdownComponent';
import { ChangeStatusConstants } from './../../../helper/Constants';

import { IChangeStatusProps, ChangeState, msg, getStatus } from './../../../common/AssignmentChangeStatusModal';

export class GroupedIndividualChangeStatusModal extends React.Component<IChangeStatusProps, ChangeState>{
    constructor(props: IChangeStatusProps) {
        super(props);
        this.state = {
            userId: 0,
            status: DocumentStatus.None,
            saving: false,
            message: msg.warning,
            alert: 'warning',
        };
    }

    componentWillReceiveProps(nextProps: IChangeStatusProps) {
        const state: ChangeState = {
            saving: true,
            message: msg.loading,
            userId: 0,
            status: DocumentStatus.None,
            alert: 'warning'
        };
        if (nextProps.show &&
            nextProps.model) {
            state.status = this.state.status === DocumentStatus.None ? getStatus(nextProps.model) : this.state.status;
            state.userId = nextProps.model.assignTo ? nextProps.model.assignTo : this.state.userId;
            state.saving = false;
            state.message = msg.warning;
        }
        this.setState(state);
    }
    public render() {

        const popover = (
            <Popover id="grouped-individual-status-change-modal-popover">
                <Popover.Title as="h3">Confirmation</Popover.Title>
                <Popover.Content>
                    {ChangeStatusConstants.changeStatusConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onSubmit}
                            title="OK"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={this.onPopoverCancel}
                            title="Cancel"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                     </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        return (<Modal
            className="change-status-modal"
            show={this.props.show}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaRandom className='modalIcon' />
                    Change Status
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant={this.state.alert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                </i>{this.state.message}</Alert>
                <LoadingOverlay style={{ height: '100%' }}>
                    {this.props.model &&
                        <div className="row marB03">
                            <Col sm={12}>
                                <FormGroup>
                                    <FormLabel>Status</FormLabel>
                                    <DropdownComponent
                                        id="ddlStatusChange"
                                        options={this.PrepareSignatureList()}
                                        onChange={this.onStatusChange}
                                        selectedValue={this.state.status}
                                        data-test-auto="54F2CEFD-C83E-4AF4-A064-759764DC59S8"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Assigned To</FormLabel>
                                    <DropdownComponent
                                        id="ddlUserChange"
                                        options={PrepareUserList(this.props.users)}
                                        onChange={this.onUserChange}
                                        selectedValue={this.state.userId}
                                        data-test-auto="54F2CEFD-C83E-4AF4-A064-759764DC59T8"
                                    />
                                </FormGroup>
                            </Col>
                        </div>}
                    <Loader loading={this.props.showLoader} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => { this.props.onCancel(this.props.model.id) }}
                    variant="default"
                    className="btn-white">
                    <i className='fas fa-times'></i>Cancel
                    </Button>
                {(this.props.model && this.onCheckStatus() === true) ?
                    <OverlayTrigger rootClose trigger="click"
                        onEnter={() => document.body.click()} placement="top" overlay={popover} >
                        <Button
                            variant='info' >
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </OverlayTrigger> :
                    <Button
                        variant='info'
                        onClick={this.onValidate}>
                        <i className='fas fa-save'></i>Save
                        </Button>
                }
            </Modal.Footer>
        </Modal>)
    }


    private PrepareSignatureList = () => {
        const optionArray = [];
        optionArray.push({
            value: DocumentStatus.None,
            label: "Select",
        });
        optionArray.push({
            value: DocumentStatus.PROCESSING,
            label: DocumentStatus[DocumentStatus.PROCESSING],
        });
        optionArray.push({
            value: DocumentStatus.REVIEW,
            label: DocumentStatus[DocumentStatus.REVIEW],
        });
        optionArray.push({
            value: DocumentStatus.READYFORDELIVERY,
            label: "READY FOR DELIVERY",
        });
        return optionArray;
    }

    private onStatusChange = (value: any) => {
        const documentStatus = value as DocumentStatus;
        if (!documentStatus || documentStatus === DocumentStatus.None) {
            this.setState({ message: msg.errorStatus, alert: 'danger', status: documentStatus });
        } else {
            this.setState({ message: msg.warning, alert: 'warning', status: documentStatus });
        }
    }

    private onUserChange = (value: any) => {
        if (value) {
            this.setState({ message: msg.warning, alert: 'warning', userId: value });
        } else {
            this.setState({ message: msg.errorUser, alert: 'danger', userId: value });
        }
    }

    private onValidate = () => {
        let isValid: boolean = true;
        if (!this.state.status || this.state.status === DocumentStatus.None) {
            this.setState({ message: msg.errorStatus, alert: 'danger' });
            isValid = false;
        }
        else if (this.state.userId === 0 || this.state.userId === null) {
            this.setState({ message: msg.errorUser, alert: 'danger' });
            isValid = false;
        }
        return isValid;
    }

    private onSubmit = () => {
        const result = this.onValidate();
        if (result) {
            this.props.onChangeStatusSave(this.props.model.id, this.state.userId, this.state.status);
            document.body.click();
        }
    }

    private onPopoverCancel = () => {
        document.body.click();
    }

    private onCheckStatus = () => {
        let result: boolean = true;
        if (!this.state.status || this.state.status === DocumentStatus.None) {
            result = false;
        }
        else if (this.state.userId === 0 || this.state.userId === null) {
            result = false;
        }
        return result;
    }
}