import React from  'react';
import { Modal, Alert, ListGroup, Form, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileDownload } from 'react-icons/fa';
import * as DeliveredReturnsState from '../../store/reports/DeliveredReturnsState';
import {
    IDownloadableDocuments, IAttachment, ITaxReturn,
    getUnformattedClientName, FormType, SignatureStatus,
    PaperReturnDeliveryType, DocumentStatus, EngagementType
} from '../common/TaxReturn';
import { DownloadReturnsConstants } from '../helper/Constants';
import { getDocumentEntityorIndividualName, displayFileName, formatFileName } from '../../components/helper/HelperFunctions';
import { IDocumentData } from '../common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import * as HelperFunction from '../helper/HelperFunctions'

interface IDownloadReturnsProps {
    show: boolean;

    onCancel(): void;

    taxReturn: ITaxReturn;

    downloadIndividual(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        isDownloadReturn: boolean,
        clientId: string,
        isEfileForm: boolean,
        isIndividualFileDownload: boolean): void;

    downloadAll(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        isEfileForm: boolean): void;

    downloadAllAttachments: (
        taxReturn: ITaxReturn,
        isArchivedReturn: boolean,
        clientName: string) => void;

    isArchivedReturn: boolean;

    downloadAdditionalEsignDocuments: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        clientId: string,
        additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean) => void;
}

export interface IPopupState {
    show: boolean;

}
export interface IPopupStateSingle extends IPopupState {
    model: IAttachment[] | undefined;
    saveCheckId: number | undefined;
}
export interface DownloadReturnsPageState {
    downloadAttachmentsState: IPopupStateSingle;
}

export class DownloadReturns extends React.Component<IDownloadReturnsProps, DownloadReturnsPageState> {
    constructor(props: any) {
        super(props);
        this.onDownloadAttachmentsOpen = this.onDownloadAttachmentsOpen.bind(this);
        this.onDownloadAttachmentCancel = this.onDownloadAttachmentCancel.bind(this);
        this.onDownloadAllAttachments = this.onDownloadAllAttachments.bind(this);
        this.state = {
            downloadAttachmentsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
        };
    }


    public render() {
        const { taxReturn } = this.props;
        const downloadable = taxReturn !== undefined && taxReturn.downloadableDocuments !== undefined && Object.keys(taxReturn.downloadableDocuments).length != 0;

        return <div><Modal show={this.props.show} onHide={this.props.onCancel} className="downloadreturn-modal">
            <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="8A258655-8C8D-43FC-8685-0E4793776C2D">
                <Modal.Title>
                    <FaFileDownload className='modalIcon' />
                    Download Returns
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        {downloadable ?
                            <div><Alert variant="info" ><i className='fa fa-info-circle' style={{ marginRight: '5px' }}>
                            </i>{DownloadReturnsConstants.downloadReturn}</Alert>
                                <ListGroup data-test-auto="EDE084EA-60DA-4F72-BF01-17A8F47F744B">
                                    {
                                        this.props.taxReturn.downloadableDocuments.map((value, i) => {
                                            let formattedName = this.getFormattedFileName(value);
                                            return <li className="delivered-return-download-link download-return-pop-up"
                                                title={formattedName}
                                                onClick={() => {
                                                    this.props.downloadIndividual(
                                                        value.documentGuid,
                                                        value.documentId,
                                                        value.taxYear,
                                                        value.fileName,
                                                        value.groupId,
                                                        value.signedDocumentId,
                                                        true,
                                                        taxReturn.clientId,
                                                        false,
                                                        true);
                                                }} >
                                                {formattedName}
                                            </li>;
                                        })
                                    }
                                    {
                                        this.props.taxReturn && this.props.taxReturn.attachments &&
                                        <li className="delivered-return-download-link" onClick={() => {
                                            this.onDownloadAttachmentsOpen();
                                        }}
                                            hidden={this.props.taxReturn.attachments.length <= 0}> Attachments</li>
                                    }
                                    {
                                        this.props.taxReturn && this.props.taxReturn.additionalEsignDocuments &&
                                        this.props.taxReturn.additionalEsignDocuments.length > 0 &&
                                        <li className="delivered-return-download-link"
                                            onClick={() => {
                                                this.onDownloadAdditionalEsignDocuments(this.props.taxReturn,
                                                    this.props.taxReturn.documentStatus == DocumentStatus.USERSIGNED)
                                            }}
                                            hidden={this.props.taxReturn.additionalEsignDocuments.length <= 0}>
                                            {
                                                this.props.taxReturn.documentStatus == DocumentStatus.USERSIGNED ?
                                                    "Other Signed Documents" : "Other Documents"
                                            }
                                        </li>
                                    }
                                </ListGroup></div>
                            :
                            (taxReturn !== undefined) &&
                            (taxReturn.downloadableDocuments !== undefined) &&
                            <Alert variant='warning' >
                                <i className='fas fa-exclamation-triangle'
                                    style={{ marginRight: '5px', color: '#666' }}>
                                </i>No returns found!
                                </Alert>}
                        <Loader
                            loading={(taxReturn === undefined) || (taxReturn.downloadableDocuments === undefined)} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        variant='default'
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF8">
                        <i className='fas fa-times'></i>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        this.props.downloadAll(
                            this.props.taxReturn.documentGuid,
                            this.props.taxReturn.id,
                            this.props.taxReturn.taxYear,
                            false);
                    }}
                        variant="info"
                        disabled={!downloadable}
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                        <i className='fas fa-download'></i>
                        Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

        </div>;
    }

    private onDownloadAttachmentsOpen() {
        let downloadableAttachments = this.props.taxReturn.attachments;
        this.setState({
            downloadAttachmentsState: {
                show: true,
                model: downloadableAttachments,
                saveCheckId: undefined,
            },
        });
    }

    private onDownloadAttachmentCancel() {
        this.setState({
            downloadAttachmentsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    private onDownloadAllAttachments() {
        this.props.downloadAllAttachments(
            this.props.taxReturn,
            this.props.isArchivedReturn,
            getUnformattedClientName(this.props.taxReturn));
    }

    private getFormattedFileName(value: any) {
        const { taxReturn } = this.props;
        let fileName: any;
        const name = getDocumentEntityorIndividualName(taxReturn);
        switch (value.groupId) {
            case FormType.Transmittals:
                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + "Extensions_Filing Instructions.pdf";
                break;
            case FormType.EFile: {

                const displayNameFormat = this.props.taxReturn.documentStatus === DocumentStatus.USERSIGNED ?
                    "Extensions_Signed Filing Forms.pdf" : "Extensions_Filing Forms.pdf";

                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + displayNameFormat;
                break;
            }
            case FormType.Vouchers:
                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_Extensions_" + value.fileName.replace(' On ', '_');
                break;
            case FormType.TaxReturns:
                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + "Extensions.pdf";
                break;
            case FormType.Invoice:
                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + "Tax Preparation Fee.pdf";
                break;
            case FormType.K1:
                fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
                HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + "K1.pdf";
                break;
        }
        return formatFileName(fileName);
    }

    private onDownloadAdditionalEsignDocuments(taxReturn: ITaxReturn, isSigned: boolean) {
        this.props.downloadAdditionalEsignDocuments(taxReturn.documentGuid,
            taxReturn.id, taxReturn.taxYear, taxReturn.clientId,
            taxReturn.additionalEsignDocuments, isSigned ? true : false);
    }
}
