import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IUserModel, Product } from '../../Core/ViewModels/User/UserViewModel';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface UsersStore {
    allUsers: IUserModel[];
    partners: IUserModel[];
    extensionUsers: IUserModel[];
    usersLoading: boolean;
    partnersLoading: boolean;
    extensionUsersLoading: boolean;
}

interface ReceivePartnerListAction {
    type: actionTypes.RECEIVE_PARTNER_LIST;
    users: IUserModel[];
}

interface ReceiveUserListAction {
    type: actionTypes.RECEIVE_USER_LIST;
    users: IUserModel[];
}

interface ReceiveExtensionUsersAction {
    type: actionTypes.RECEIVE_EXTENSION_USERS;
    users: IUserModel[];
}

interface AllUsersLoaderAction {
    type: actionTypes.ALL_USERS_LOADER;
    loader: boolean;
}

interface PartnersLoaderAction {
    type: actionTypes.PARTNERS_LOADER;
    loader: boolean;
}

interface ExtensionUsersLoaderAction {
    type: actionTypes.EXTENSION_USERS_LOADER;
    loader: boolean;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ReceiveUserListAction |
    ReceiveExtensionUsersAction |
    ReceivePartnerListAction |
    AllUsersLoaderAction |
    PartnersLoaderAction |
    ExtensionUsersLoaderAction;

export const actionCreators = {
    requestAllPartners: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState();

        if (reload || !state.usersStore.partners.length) {

            dispatch({
                type: actionTypes.PARTNERS_LOADER,
                loader: true
            });

            const fetchTask = fetch(`${SUITE_API_BASE_URL}api/users/partners`, { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {

                    dispatch({
                        type: actionTypes.RECEIVE_PARTNER_LIST,
                        users: data
                    });

                    dispatch({
                        type: actionTypes.PARTNERS_LOADER,
                        loader: false
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestAllPartners failed with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },

    requestAllUsers: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState();

        if (reload || !state.usersStore.allUsers.length) {
            dispatch({
                type: actionTypes.ALL_USERS_LOADER,
                loader: true
            });
            const fetchTask = fetch(`${SUITE_API_BASE_URL}api/users`, { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {

                    dispatch({
                        type: actionTypes.RECEIVE_USER_LIST,
                        users: data
                    });

                    dispatch({
                        type: actionTypes.ALL_USERS_LOADER,
                        loader: false
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestAllUsers failed with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },

    requestExtensionsUsers: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState();

        if (reload || !state.usersStore.extensionUsers.length) {
            dispatch({
                type: actionTypes.EXTENSION_USERS_LOADER,
                loader: true
            });
            const fetchTask = fetch(`${SUITE_API_BASE_URL}api/users/${Product.Extensions}`, { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {

                    dispatch({
                        type: actionTypes.RECEIVE_EXTENSION_USERS,
                        users: data
                    });

                    dispatch({
                        type: actionTypes.EXTENSION_USERS_LOADER,
                        loader: false
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestExtensionsUsers failed with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },



};

const unloadedUsersStore: UsersStore = {
    allUsers: [],
    extensionUsers: [],
    extensionUsersLoading: false,
    partners: [],
    partnersLoading: false,
    usersLoading: false
};

export const reducer: Reducer<UsersStore> = (state: UsersStore = unloadedUsersStore, incomingAction: Action): UsersStore => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_PARTNER_LIST:
            return {
                ...state,
                partners: action.users,
            };
        case actionTypes.RECEIVE_USER_LIST:
            return {
                ...state,
                allUsers: action.users
            };
        case actionTypes.RECEIVE_EXTENSION_USERS:
            return {
                ...state,
                extensionUsers: action.users
            };
        case actionTypes.ALL_USERS_LOADER:
            return{
                ...state,
                usersLoading: action.loader
            };
        case actionTypes.PARTNERS_LOADER:
            return{
                ...state,
                partnersLoading: action.loader
            };
        case actionTypes.EXTENSION_USERS_LOADER:
            return{
                ...state,
                extensionUsersLoading: action.loader
            };
        default:
            const exhaustiveCheck: never = action;
    }

    return state || unloadedUsersStore;
};