import React from  'react';
import Lightbox, { ModalGateway, Modal } from 'react-images';

export interface CarouselImage {
    source: string;
    caption: string;
    pageNo: number;
}
interface PageCarouselProps {
    open: boolean;
    images: CarouselImage[],
    currentImage: number,
    onClose: () => void;
}
interface PageCarouselState {
    images: any[];
    open: boolean;
    currentImage: number;
    title: string;
}
const theme = {
    thumbnail__active: {
        boxShadow: '0 0 0 2px #00D8FF',
    },
};

export class PageCarousel extends React.Component<PageCarouselProps, PageCarouselState> {
    constructor(props: PageCarouselProps) {
        super(props);
        this.state = {
            images: [],
            open: false,
            currentImage: 0,
            title: ""
        };
    }
    componentDidMount() {
        this.createImages(this.props);
    }
    componentWillReceiveProps(props: PageCarouselProps) {
        this.createImages(props);
    }
    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
            title: this.props.images[this.state.currentImage - 1].caption
        });
    }
    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
            title: this.props.images[this.state.currentImage + 1].caption
        });
    }
    gotoImage = (index: number) => {
        this.setState({
            currentImage: index,
            title: this.props.images[index] && this.props.images[index].caption
        });
    }
    createImages = (props: PageCarouselProps) => {
        this.setState({
            images: props.images,
            open: props.open,
            title: props.images[props.currentImage] && props.images[props.currentImage].caption
        }, () => {
            props.images.sort((a: CarouselImage, b: CarouselImage) => a.pageNo - b.pageNo);
            this.gotoImage(props.currentImage)
        }
        );
    }
    render() {
        return ((this.state.images && this.state.images.length > 0) ?
            <ModalGateway>
                <Modal onClose={this.props.onClose}>
                    <Lightbox
                        currentIndex={this.state.currentImage}
                        onClickNext={this.gotoNext}
                        onClickPrev={this.gotoPrevious}
                        onClickThumbnail={this.gotoImage}
                        views={this.state.images} />
                </Modal>
            </ModalGateway> : <div></div>)
    }
}
