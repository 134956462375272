import { SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { UserSettings } from '../../../../store/userManagement/UserSettingStore';
import { DocumentGroups, IEFile, IGroup, ISignatureControl, ITaxReturn, IVoucher, SignatureControlRole, SignatureControlTypes, SignatureType } from '../../TaxReturn';
import { IDocumentData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import ControlBase, { PDF_VIEWER_BASE_HEIGHT } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PdfProperties } from '../PdfViewer/PdfHelper';
import { VenusNotifier } from 'src/components/helper/VenusNotifier';

export const getControlText = (controlType: SignatureControlTypes): string => {
    switch (controlType) {
        case SignatureControlTypes.Date:
            return "Date";
        case SignatureControlTypes.Signature:
            return "Signature";
        case SignatureControlTypes.NameAndTitle:
            return "Name and Title";
        case SignatureControlTypes.Company:
            return "Company";
        case SignatureControlTypes.Initial:
            return "Initials";
        case SignatureControlTypes.Text:
            return "Text";
        default:
            return "";
    }
}

export const getControlIcon = (controlType: SignatureControlTypes): string => {
    switch (controlType) {
        case SignatureControlTypes.Date:
            return "fas fa-calendar-alt";
        case SignatureControlTypes.Signature:
            return "fas fa-signature";
        case SignatureControlTypes.NameAndTitle:
            return "fas fa-address-card";
        case SignatureControlTypes.Company:
            return "fas fa-building";
        case SignatureControlTypes.Initial:
            return "fas fa-font";
        case SignatureControlTypes.Text:
            return "fas fa-text-width";
        default:
            return "";
    }
}

export const GetEnableEsignatureForBusinessReturns = (extensionCompanySettings: ExtensionCompanySettings, isBusinessReturn: boolean): boolean => {
    return !isStrictReviewExtesnion(extensionCompanySettings, isBusinessReturn);
}

export const isReviewExtesnion = (extensionCompanySettings: ExtensionCompanySettings, isbusinessReturn: boolean) => {

    return isbusinessReturn ?
        extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeForBusiness == SignatureFormSelectionType.ReviewOnly :
        extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeFor1040 == SignatureFormSelectionType.ReviewOnly;
}

export const isEsignExtension = (extensionCompanySettings: ExtensionCompanySettings, isBusinessReturn: boolean) => {

    return isBusinessReturn ?
        extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeForBusiness == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed :
        extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeFor1040 == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed;
}
export const isStrictReviewExtesnion = (extensionCompanySettings: ExtensionCompanySettings, isbusinessReturn: boolean) => {
    return isbusinessReturn ?
        (extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeForBusiness == SignatureFormSelectionType.ReviewOnly &&
            !extensionCompanySettings.signatureSettingsModel.allowUserPlaceSignOnBusiness)
        :
        (extensionCompanySettings.signatureSettingsModel.signatureFormSelectionTypeFor1040 == SignatureFormSelectionType.ReviewOnly &&
            !extensionCompanySettings.signatureSettingsModel.allowUserPlaceSignOn1040);
}
export const removeAllUserSignature = (taxreturn: ITaxReturn): IGroup[] => {
    const tempFormsGroup = { ...taxreturn };
    const formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
    const voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);

    if (formIndex !== -1) {
        const efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];

        efileForms.forEach(efileForm => {
            efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole !== SignatureControlRole.Taxpayer).
                filter(x => x.signatureControlRole !== SignatureControlRole.Spouse).
                filter(x => x.signatureControlRole !== SignatureControlRole.PartnerShip);;
        });

        tempFormsGroup.formGroups[formIndex].forms = efileForms;
    }
    if (voucherIndex !== -1) {
        const voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];

        voucherForms.forEach(voucherForm => {
            voucherForm.signatureControls =
                voucherForm.signatureControls.filter(x => x.signatureControlRole !== SignatureControlRole.Taxpayer).
                    filter(x => x.signatureControlRole !== SignatureControlRole.Spouse).
                    filter(x => x.signatureControlRole != SignatureControlRole.PartnerShip);
        });

        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
    }

    const additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];

    additionalForms.forEach(additionalForm => {
        additionalForm.documentControls.forEach(
            x => x.controls = x.controls.filter(y => y.signatureControlRole !== SignatureControlRole.Taxpayer).
                filter(y => y.signatureControlRole !== SignatureControlRole.Spouse).
                filter(y => y.signatureControlRole != SignatureControlRole.PartnerShip))
    });

    tempFormsGroup.additionalEsignDocuments = additionalForms;

    return tempFormsGroup.formGroups;
}

export const GetSignatureControl = (
    signatureControls: ISignatureControl[],
    signatureType: any,
    userSettings: UserSettings): ISignatureControl[] => {

    let signControls: ISignatureControl[] = signatureControls;

    if (signControls) {

        if (signatureType === SignatureType[SignatureType.ManualSign] || signatureType === SignatureType[SignatureType.ReviewOnly]) {
            if (userSettings.settings.useSignatureStamp) {
                signControls = signControls.filter(control => control.signatureControlRole === SignatureControlRole.ERO);
            }
            else if (!userSettings.settings.useSignatureStamp) {
                signControls = [];
            }
        } else if (!userSettings.settings.useSignatureStamp) {
            signControls = signControls.filter(control => control.signatureControlRole !== SignatureControlRole.ERO);
        }
    }
    return signControls;
}


export const getControlTopPositionPDFJS = (page: number, top: number, scale: number): number => {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;

    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;
    top = pageHeight - top;
    top = top / topRatio;
    return top;
}

export const getSignatureControlStyle = (control: ISignatureControl, scale: number): React.CSSProperties => {
    let fontSize = 16;
    let style: React.CSSProperties = {};

    style.zIndex = 5;
    style.display = "block";
    style.fontSize = (fontSize * scale).toString() + "px";
    style.height = (30 * scale).toString() + "px";
    if (control.signatureControlRole !== SignatureControlRole.ERO) {
        style.paddingRight = "10px";
    }
    return style;
}

const TopAdjustmentForSignatureControl = 12;

export const getTopAdjustmentSignatureControlValue = (controlType: SignatureControlTypes): number => {
    // May be implemented later
    // switch(controlType){
    //     case SignatureControlTypes.CheckBox:
    //     case SignatureControlTypes.RadioButton:
    //         return 0;
    //     default:
    //         return TopAdjustmentForSignatureControl;
    // }
    return TopAdjustmentForSignatureControl;
}


export function dragStartSignatureControl(
    event: any,
    controlType: SignatureControlTypes,
    signatureRole: SignatureControlRole,
    eroSignerLabel: string,
    currentPage: number,
    scale: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void,
    pageHeight?: number) {

    registerDropEvents(currentPage, scale, pageHeight, addSignatureControl);
    event.dataTransfer.setData('controlType', controlType.toString());
    event.dataTransfer.setData('signatureRole', signatureRole.toString());

    var element = document.createElement("div");
    element.id = "dragging-element";
    element.style.position = "absolute";
    element.style.top = "-1000px";

    if (signatureRole == SignatureControlRole.ERO) {
        element.innerHTML = '<p class="esignEROStamp" style="border-style: solid; border-width: 1px; max-height: '
            + (22 * scale).toString() + 'px; font-size: '
            + (12 * scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px; overflow: hidden;">'
            + eroSignerLabel + '</p>';
    }
    else {
        let controlText = getControlText(controlType);
        let icon = getControlIcon(controlType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';
    }
    document.body.appendChild(element);
    event.dataTransfer.setDragImage(element, 0, 0);
}

function registerDropEvents(
    currentPage: number,
    scale: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    const elements = document.getElementsByClassName("page");
    for (var i = 0; i <= elements.length - 1; i++) {
        const tmpElement: any = elements[i];
        tmpElement.ondrop = function (ev: any) {
            ev?.preventDefault();
            const tmpPageElement = ev.target.offsetParent;
            if (tmpPageElement?.attributes["data-page-number"]) {
                dropSignatureControl(ev, currentPage, scale, pageHeight, addSignatureControl);
            }
        };
        tmpElement.ondragover = function (ev: any) {
            ev?.preventDefault();
        };
    }
}

function dropSignatureControl(
    event: any, currentPage: number,
    scale: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    event?.preventDefault();
    if (scale !== PdfProperties.DefaultScale) {
        VenusNotifier.Warning("The controls can be placed only on 100% Zoom in ratio.", "");
        return;
    }
    let controlType = event.dataTransfer.getData('controlType');
    let signatureRole = event.dataTransfer.getData('signatureRole');
    if (controlType && signatureRole) {
        controlType = parseInt(controlType);
        signatureRole = parseInt(signatureRole);
        if (!Number.isNaN(controlType) && !Number.isNaN(signatureRole)) {
            calculatePositionAndAddSignatureControl(
                controlType, signatureRole, event.offsetX, event.offsetY, currentPage, pageHeight, addSignatureControl);
        }
    }
}

function calculatePositionAndAddSignatureControl(
    type: SignatureControlTypes,
    role: SignatureControlRole,
    clientX: number,
    clientY: number,
    currentPage: number,
    pageHeight: number,
    addSignatureControl: (type: SignatureControlTypes, role: SignatureControlRole, clientX: number, clientY: number) => void) {
    const left = ControlBase.getBackendControlLeftPosition(clientX);
    const top = ControlBase.getBackendControlTopPosition(pageHeight ? pageHeight : PDF_VIEWER_BASE_HEIGHT,
        clientY + getTopAdjustmentSignatureControlValue(type));
    if (addSignatureControl) {
        addSignatureControl(type, role, left, top);
    }
}