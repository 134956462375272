import { Action, Reducer } from 'redux';
import {
    ReceiveSendGroupedReturnsPagesAction, RequestSendGroupedReturnsPagesAction,
    ResetSendGroupedReturnsAction, SendGroupedExtensionsPagesStoreState,
    SendGroupedExtensionsStoreState,
    unloadedSendGroupedExtensionsStoreState,
} from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import { actionTypes } from '../../ActionTypes';

type DispatchAction = RequestSendGroupedReturnsPagesAction
    | ReceiveSendGroupedReturnsPagesAction
    | ResetSendGroupedReturnsAction;

export const actionCreators = {};

export const reducer: Reducer<SendGroupedExtensionsPagesStoreState> =
    (state: SendGroupedExtensionsPagesStoreState = { "?": unloadedSendGroupedExtensionsStoreState }, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES:
            if (!state[action.query]) {
                const added = { ...unloadedSendGroupedExtensionsStoreState };
                added.loading = true;
                added.query = action.query;
                const item = {} as SendGroupedExtensionsPagesStoreState;
                item[action.query] = added;

                return {
                    ...state,
                    ...item,
                };
            }
            break;
        case actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES:
            if (state[action.query]) {
                const changed = state[action.query] as SendGroupedExtensionsStoreState;
                changed.loading = false;
                changed.sendGroupedExtensionsTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                const item = {} as SendGroupedExtensionsPagesStoreState;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.RESET_SEND_GROUPED_RETURNS:
            return clearGroupedReturnsPages();
        default:
            const exhaustiveCheck: never = action;
        }
        return state || { "?": unloadedSendGroupedExtensionsStoreState };
};

function clearGroupedReturnsPages(): SendGroupedExtensionsPagesStoreState {
    return {} as SendGroupedExtensionsPagesStoreState;
}