import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';

import { API_BASE_URL } from '../../../src/utils/contants';

import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { logger } from '../../../src/components/helper/LoggerHelper';
import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { ReceiveSuiteTaxSoftwareSettings, SuiteTaxSoftwareSettingsLoaderAction, SuiteTaxSoftwareSettingsStoreState, unloadedSuiteTaxSoftwareSettingsStoreState} from "../../../src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels"
import { ITaxSoftwareSettings } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { SuiteTaxSoftwareSettingsConstants } from 'src/components/helper/Constants';

type KnownActions =
ReceiveSuiteTaxSoftwareSettings |
SuiteTaxSoftwareSettingsLoaderAction |
NotificationAction

export const actionCreators = {
    requestSuiteTaxSoftwareSettings: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.SUITE_TAX_SOFTWARE_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/GetSuiteTaxSoftwareSettingsAsync`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ITaxSoftwareSettings>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_SUITE_TAX_SOFTWARE_SETTINGS,
                    data: data
                });
                dispatch({
                    type: actionTypes.SUITE_TAX_SOFTWARE_SETTINGS_LOADER,
                    loading: false
                })
            }).catch((error) => { 
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SuiteTaxSoftwareSettingsConstants.Failure.TaxSoftwareSettings,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestSuiteTaxSoftwareSettings failed with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<SuiteTaxSoftwareSettingsStoreState> = (state: SuiteTaxSoftwareSettingsStoreState = unloadedSuiteTaxSoftwareSettingsStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_SUITE_TAX_SOFTWARE_SETTINGS:
            const suiteTaxSoftwareSettings = { ...state };
            suiteTaxSoftwareSettings.taxSoftwareSettings = action.data;
            return suiteTaxSoftwareSettings;

        case actionTypes.SUITE_TAX_SOFTWARE_SETTINGS_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        default: return state || unloadedSuiteTaxSoftwareSettingsStoreState;
    }
};