import React, { useEffect, useState } from 'react'
import { ClientReturn, EngagementType, IPrefilledClientInfo, ITaxReturn, isIndividual, isMutual } from '../../TaxReturn'
import { ClientInfoRightSidePanelType } from '../Tabs/TabClientInfo'
import { Accordion, Card, Col, FormControl, FormGroup } from 'react-bootstrap'
import { CheckBoxComponent } from '../../CheckBoxComponent'
import DropdownComponent from '../../controls/DropdownComponent'
import { PrepareTaxYear } from 'src/components/helper/HelperFunctions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'src/store'
import Select from 'react-select';
import ClientInfoProperty from '../../ClientInfoEnum'

export interface IReturnInformationProps {
    clientId: string,
    taxReturn: ITaxReturn,
    selectedTaxYear: number,
    selectedEngagement: EngagementType,
    isAssignedToLoggedinUser?: boolean,
    handleChange: (item: ClientReturn, value: string) => void
    isMarriedJointly: boolean,
    onChangeMJFCheckbox: (e: any) => void,
    partnerId: string,
    expandReturnInformation: boolean,
    onPanelToggle: (panelType: ClientInfoRightSidePanelType) => void,
    prefilledClientInfo?: IPrefilledClientInfo;
    getPrefilledClassName: (clientInfoProperty: ClientInfoProperty) => string;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty) => void;
}

export const ClientReturnInfo:React.FC<IReturnInformationProps> = (props) => {

    let icon = props.expandReturnInformation ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";

    const partners=useSelector((state:ApplicationState)=>state.usersStore.partners)

    const locations=useSelector((state:ApplicationState)=>state.officeLocation.locationDropdown)

    const [clientId,setClientId]=useState(props.clientId)
    const [partnerID,setPartnerId]=useState(props.partnerId)
    const [taxReturnYear,setTaxReturnYear]=useState(props.taxReturn.taxYear)
    const [officeLocation,setOfficeLocation]=useState(props.taxReturn.location.locationId??"Select...")

    useEffect(
        () => { 
            setPartnerId(props.partnerId)
            setOfficeLocation(props.taxReturn.location.locationId)
        }
    )

    const onClientIdChange = (event: any) => {
        setClientId(event.target.value)
    }
    const onClientIdBlur = (event: any) => {
          props.handleChange(ClientReturn.ClientId, event.target.value);
    }
    const signerChange = (value: any) => {
        setPartnerId(value?value.toString():"0")
        props.handleChange(ClientReturn.Signer, value ? value : "0");
    }

    const officeLocationChange=(value:any)=>{
        setOfficeLocation(value)
        props.handleChange(ClientReturn.OfficeLocation,value)
        props.resetPrefillInfo(ClientInfoProperty.officeLocationId)
    }

    const taxYearChange = (event: any) => {
        setTaxReturnYear(event.value)
        props.handleChange(ClientReturn.TaxYear, event.value);
    }
    let engType = [];
        for (let type in EngagementType) {
            if (isNaN(Number(type))) {
                switch (EngagementType[type].toString()) {
                    case EngagementType.None.toString():
                        engType.push({ value: type.toString(), label: type });
                        break;
                    case EngagementType.E990.toString():
                        engType.push({ value: type.toString(), label: "990/T" });
                        break;
                    default:
                        engType.push({ value: type.toString(), label: type.slice(1) });
                }
                
            }
            
        }
  return (
    <div>
         <Accordion defaultActiveKey="0" style={{ marginRight: "1px" }}>
                <Card>
                    <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                        <Card.Title as="h4"
                            onClick={() => { props.onPanelToggle(ClientInfoRightSidePanelType.ReturnInformationPanel) }} >
                            <i className={icon}></i> Return Information
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: "10px" }}>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-4" >
                                    Client ID
                        </label>
                                <Col sm={8}>
                                    <FormControl
                                        placeholder="Client ID"
                                        value={clientId}
                                        onChange={onClientIdChange}
                                        data-test-auto="C65CB625-33B0-445E-9BB8-9B7B763D5301"
                                        onBlur={onClientIdBlur}
                                        disabled={!props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-4">ERO / Signer</label>
                                <Col sm={8} className="zeroborderRadius">
                                    <DropdownComponent
                                        id="ddlSigner"
                                        className = {props.getPrefilledClassName(ClientInfoProperty.eroPartnerId)}
                                        options={partners.map((usr) => { return { value: usr.id.toString(), label: usr.firstName + " " + usr.lastName } })}
                                        onChange={signerChange}
                                        selectedValue={partnerID}
                                        disabled={!props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-4" >Engagement Type</label>
                                <Col sm={8}>
                                    <Select disabled={true}
                                        name="form-field-engagement"
                                        value={props.selectedEngagement}
                                        options={engType}
                                        data-test-auto="1B5E2CCC-F4D5-4198-A82E-DD0397E4DAC1"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-4">Tax Year</label>
                                <Col sm={8}>
                                    <Select
                                        name="form-field-taxYear"
                                        value={taxReturnYear}
                                        onChange={taxYearChange}
                                        options={PrepareTaxYear()}
                                        data-test-auto="F6251C24-A0A0-4013-A962-B38A5EB40C2D"
                                        clearable={false}
                                        disabled={true}/>

                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-4">Office Location</label>
                                <Col sm={8} className="zeroborderRadius">
                                    <DropdownComponent
                                        id="ddlOffice"
                                        className = {props.getPrefilledClassName(ClientInfoProperty.officeLocationId)}
                                        options={locations.map((location) => { return { value: location.value, label: location.name} })}
                                        onChange={officeLocationChange}
                                        selectedValue={officeLocation}
                                        disabled={!props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            {(isIndividual(props.taxReturn) || isMutual(props.taxReturn)) ?
                                <FormGroup className="Row">
                                    <Col sm={12} className="nopadding-left">
                                        <CheckBoxComponent
                                            id={"isMarriedJointly"}
                                            text={"Married Filing Joint"}
                                            checked={props.isMarriedJointly}
                                            onChange={props.onChangeMJFCheckbox}
                                            datatestAuto={"E2DF135F-F2B4-4E7E-9F34-691CAF70E24D"}
                                        />
                                    </Col>
                                </FormGroup> : <FormGroup className="row"></FormGroup>
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
    </div>
  )
}

