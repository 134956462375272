import React from 'react';
import { Tabs, Button, Popover, Overlay } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as TaxDocument from '../../common/TaxReturn';
import * as User from '../../../Core/ViewModels/User/UserViewModel';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import {
    ICompanySettings, ReportingEngineType,
    SignatureFormSelectionType
} from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as Company from '../../../store/company/CompanyStore';
import * as VoucherStore from '../../../store/ProcessReturn/voucher/VoucherStore';
import * as Helper from '../../helper/HelperFunctions';
import { CustomWindow, CustomWindowFooter, CustomWindowBody } from './CustomWindow/CustomWindow';
import { IPdfDocumentFacade } from '../../../Core/Utilities/PdfDocumentFacade';
import { TabDeliveryOptions } from '../../common/ProcessReturnModal/Tabs/TabDeliveryOptions';
import { GroupsView } from './GroupsView';
import {
    ClientInfoView, IBookmarkSection, Transmittalsview,
    TaxReturnInfoView, EfileInfoView, IDelegatedEROSigner,
    IShareHolder, ExtensionProcessReturnTabs, processReturnActionEndPoints
} from './ProcessReturnModels';
import { TabClientInfo } from './Tabs/TabClientInfo';
import { TabTransmittals } from './Tabs/TabTransmittals';
import { TabTaxReturn } from './Tabs/TabTaxReturn';
import { TabEFile } from './Tabs/TabEFile';
//import { TabK1s } from './Tabs/TabK1s';
import { container } from '../../../Startup/inversify.config';
import { ILocalStore } from '../../../Core/Utilities/LocalStore';
import { TYPES } from '../../../Startup/types';
import { CarouselImage, PageCarousel } from './PageCarousel/PageCarousel';
import { PDFPageProxy } from 'pdfjs-dist';
import {
    IFormBase, DocumentGroups, IVoucher,
    ITaxingAuthority, ClientTypes, ITaxReturn,
    SignatureControlRole, IEFile, EntityType, DeliveryMode, SignatureType
} from '../../common/TaxReturn';
import { FinishPopover } from './FinishProcessReturn/FinishPopover';
import { Guid } from 'guid-typescript';
import { AttachmentsState, AttachmentsTableModel, IAttachmentsDictionary } from '../../../store/ProcessReturn/AttachmentsState';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';
import * as UserSignatureStore from '../../../store/common/UserSignatureStore';
import * as UserHelper from '../../helper/UserHelperFunctions';
import { ISubDocument } from '../../../Core/Utilities/PdfDocumentFacade';
import { GroupFilesPreview } from './GroupFilesPreview';
import { IUserModel, IUserBaseModel } from '../../../Core/ViewModels/User/UserViewModel';
import * as Constants from '../../helper/Constants';
import {
    validateTaxReturn, validateRefudInfo, ValidateWebsite,
    validateRetentionPeriod, ValidateEfileAuthority, validateFormGroup
} from '../../helper/Validations';
import { IBusinessReturnsState } from '../../../store/settings/BusinessReturnsStore';
import { TabAdditionalEsignDocuments } from './Tabs/TabAdditionalEsignDocuments';
import * as AdditionalEsignDocumentStore from '../../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import { DocumentUploadData, IDocumentData } from './AdditionalEsignDocuments/AdditionalEsignDocument';
import * as AdditionalDocumentTypeStore from '../../../store/common/AdditionalDocumentTypeStore';
import { IWatermarkModel } from '../../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting';
import { ICustomColumn, IColumnValues } from 'src/components/settings/GeneralSettings';
import { TabEFileConstants } from '../../helper/Constants';
import { cloneDeep, countBy } from 'lodash';
import * as bootbox from 'bootbox';
import { DueDateType, ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { GetEnableEsignatureForBusinessReturns, isReviewExtesnion, isStrictReviewExtesnion } from './SignatureControls/SignatureHelper';
import { logClientEvent } from '../../helper/LoggerHelper';
import { isBusinessReturn } from '../../helper/HelperFunctions';
import { VenusNotifier } from 'src/components/helper/VenusNotifier';
import { getRequestVerificationToken } from 'src/oidcClient/helpers';
import MismatchModal from '../MisMatchModal/MismatchModal';
import { IClientInfoComparisonResult, IClientInfoMismatchType, getNewClientInfo, initialClientInfoComparisonResult, setNewClientInfoComparisonResult } from '../MisMatchModal/MismatchModal.model';
import { addNewClientInfo, compareClientInfo, editClientInfo, getClientInfoByClientId } from '../../../store/MisMatchModel/MismatchModal.Api';
import ClientInfoProperty from '../ClientInfoEnum';
import { ITaxClient, ITaxpayer } from '../TaxClient';
import { IAuthState } from 'src/store/auth/reducer';
import { DocumentEventLog } from 'src/components/helper/LogEventConstants';
import LocationMismatchActionModal from '../LocationMismatchModal/LocationMimatchActionModal';
import { IDropdown } from 'src/Core/Common/Dropdown';
import { RBACKeys, getProcessResourceId } from 'src/components/helper/RbacConstants';

let moment = require('moment');
const isEqual = require("react-fast-compare");

export interface IModalState {
    isBusy: boolean;
    message: string;
}

interface IProcessReturnstate extends IModalState {
    key: any;
    signers: User.IUserModel[];
    viewIndex: number | undefined;
    initialMFJValue: boolean;
    k1Pages: number[];
    showCarousel: boolean;
    pdfPages: CarouselImage[];
    pdfCarouselActivePage: number;
    renderTrigger: string;
    shareHolderInfo: IShareHolder;
    delegatedEROSigners: IDelegatedEROSigner[];
    isEsign: boolean;
    showGroupFilesPreview: boolean;
    pages: number[];
    matchedForms: TaxDocument.IGroupFormBase[];
    destinationGroup: TaxDocument.DocumentGroups;
    attachments: TaxDocument.IAttachment[];
    verifyK1Replaced: boolean;
    isK1Restored: boolean;
    isK1Replaced: boolean;
    selectedK1Bookmark: number;
    authoritiesOptions: TaxDocument.SelectOptions[];
    shareHolderOptions: TaxDocument.SelectOptions[];
    generateTaxPayer: boolean;
    //renderCache: {
    //    voucher: {
    //        bookmarks: IBookmarkSection[],
    //        vouchers: IVoucher[]
    //    }
    //},
    showClosePopover: boolean;
    disabledTaxpayerPreview: boolean;
    processingTaxReturn: TaxDocument.ITaxReturn | undefined;
    refreshTaxReturn: boolean;
    disableSaveClose: boolean;
    signatureTypeInDocSettings: string;
    isSignatureoptionsToggledInDeliveryOption: boolean;
    signatureFormSelectionType: TaxDocument.SignatureType;
    isSaveAsDefaultOrder: boolean;
    uploadedAdditionalEsignDocumentData: DocumentUploadData[];
    selectedCustomColumn: IColumnValues;
    isFileEdited: boolean;
    duplicatePages: number[];
    popUpShown: boolean;
    duplicateFormPopup: boolean;
    showMismatchModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    selectedOption: {
        save: boolean;
        finish: boolean;
    };
    popupInProgress: boolean;
    clientInfo: TaxDocument.IClientInfoViewModel;
    prefilledClientInfo: TaxDocument.IPrefilledClientInfo;
    clientBasicInfoId: number;
    showLocationMismatchActionModel:boolean;
    isEroFromExtensionDb : boolean;
}

interface IModalProps {
    show: boolean;
    onCancel: (id: number) => void;
}

const viewMax = ExtensionProcessReturnTabs.DeliveryOptions;
const viewMin = ExtensionProcessReturnTabs.ClientInfo;

export interface IProcessReturnProps extends IModalProps {
    order: number;
    docId: number;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    requestTaxDocument: (id: number, force: boolean) => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    saveTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    sendToERO: (taxDocument: TaxDocument.ITaxReturn) => void;
    sendForReview: (taxDocument: TaxDocument.ITaxReturn) => void;
    approveForDelivery: (taxDocument: TaxDocument.ITaxReturn) => void;
    deliverToClient: (taxDocument: TaxDocument.ITaxReturn) => void;
    getK1InstructionFileName: (
        guid: string,
        taxYear: number,
        documentId: number,
        engagementType: TaxDocument.EngagementType) => void;
    deleteK1Instruction: (guid: string, taxYear: number) => void;
    uploadK1Instruction: (
        engagementType: TaxDocument.EngagementType,
        documentGuid: string,
        taxYear: number,
        fileName: string,
        documentId: number,
        callback?: () => void) => void;
    //attachments
    attachments: IAttachmentsDictionary;
    requestAttachments: (
        taxReturn: TaxDocument.ITaxReturn,
        fileName: string,
        file: any,
        processData: (data: AttachmentsTableModel, fileName: string, file: any) => void) => void;
    downloadAttachments: (
        taxReturn: TaxDocument.ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean) => void;
    //Company
    companySettings: ICompanySettings;
    company: CompanyStore.ICompanyData;
    authorities: TaxDocument.ITaxingAuthority[];
    authorityLookup: Company.IAuthorityDictionary;
    userBasicProfile: IUserProfile;
    users: User.IUserModel[];
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    userSettings: UserSettingStore.UserSettings;
    // requestDelegatedSigners: (userId: number) => void;
    userSignatures: UserSignatureStore.IUserSignatures;
    //PdfRelated
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    loadPdf: (taxReturn: TaxDocument.ITaxReturn, reload: boolean) => void;
    returnType: TaxDocument.EngagementType;
    isAssignedToLoggedinUser?: boolean;
    generateTaxPayerView: (taxDocument: TaxDocument.ITaxReturn, clientType?: ClientTypes) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    getAllSavedMessages: (reload: boolean) => void;
    saveProcessReturnInfo: (
        taxReturn: TaxDocument.ITaxReturn,
        subDocuments: ISubDocument[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        taxCaddyLookupResult?: TaxDocument.TaxCaddyLookupResult,
        clientType?: ClientTypes) => void;
    getVoucherUploadLink: (
        taxReturn: TaxDocument.ITaxReturn,
        callback: (data: any) => void) => void;
    getMultiVoucherUploadLink: (
        taxReturn: ITaxReturn,
        cbVoucher: IVoucher,
        callback: (data: any, voucher: IVoucher) => void) => void;

    getTaxCaddyLookupDetails: (
        taxpayerName: string,
        email: string,
        ssn: string,
        taxYear: number,
        taxClientId: string,
        docId: number) => void;
    voucherStore: VoucherStore.IVoucherState;
    requestVoucherDueDates: (taxReturn: TaxDocument.ITaxReturn, authorityId?: number,
        voucherNo?: TaxDocument.VoucherNo) => void;
    requestPaymentUrls: (taxReturn: TaxDocument.ITaxReturn) => void;
    requestVoucherFormNames: (taxReturn: TaxDocument.ITaxReturn) => void;
    isK1Restored: boolean;
    closePopoverRef: any;
    restoreK1Instruction: (fileName: string, documentId: number) => void; getAllTaxingAuthorities: ITaxingAuthority[];
    businessInstructionStore: IBusinessReturnsState;
    updateDocumentInitialStatus: (updateDocumentInitialStatus: TaxDocument.ITaxReturn) => void;
    previewAttachments: (
        taxReturn: TaxDocument.ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void) => void;

    onReportProblemOpen: (id: number) => void;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback: () => void, callback2?: () => void) => void;
    requestCustomAuthority: (value: boolean) => void;
    requestTaxingAuthorities: (value: boolean) => void;

    updateDocumentGroupOrder: (engagementType: TaxDocument.EngagementType, order: DocumentGroups[]) => void;
    requestEroSignaturePath: (userId: number) => void;
    getAddtionalEsignDocumentUploadLink: (
        url: string,
        callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    deleteUploadedAdditionalDocumentBlobFile: (
        documentGuid: string,
        fileName: string,
        taxYear: number,
        callback?: () => void) => void;
    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void) => void;
    getAddtionalEsignDocumentDownLoadLink: (
        url: string,
        callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    convertDocToPdfArtifactAsync: (
        url: string,
        callback?: (data?: AdditionalEsignDocumentStore.IBlobFile) => void) => void;
    requestAdditionalDocumentType: (reload: boolean) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    requestAssignments: (query: string, reload: boolean, callback?: () => void, reloadOnNoDataFound?: () => void) => void;
    //watermarkList: IWatermarkModel[];
    customColumnData: ICustomColumn;
    selectedCustomColumn: IColumnValues;
    isLoading: boolean;
    extensionCompanySettings: ExtensionCompanySettings;
    partners: IUserModel[];
    extensionUsers: IUserModel[];
    userRoles: string;
    auth: IAuthState;
    loggedInUserLocations:number[];
    officeLocation:IDropdown[];
    queryString:string;
    loadGrid(): void;
    defaultEro: number;
}

const enum LoadPropsStage {
    Ready,
    Document,
    Forms,
    Settings,
    Authority,
    Pdf,
    Users,
}

const msg = {
    loading: 'The Tax-Return details are loading...',
    saving: 'Updating the Tax-Return changes...',
};

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);
const pageTitle: string = "SendExtension";

export class ProcessReturnModal extends React.Component<IProcessReturnProps, IProcessReturnstate> {
    private closePopoverRef: any;
    private deactivatedAuthority: number[] = [];
    private editClientInfoResourceId: string = getProcessResourceId();
    constructor(props: IProcessReturnProps) {
        super(props);
        this.state = {
            key: undefined,
            signers: [],
            //IModalState Properties
            isBusy: false,
            message: "",
            viewIndex: undefined,
            initialMFJValue: false,
            //pdf: {} as IPdfDocumentFacade,
            k1Pages: [],
            selectedK1Bookmark: 0,
            delegatedEROSigners: [],
            showCarousel: false,
            pdfPages: [],
            pdfCarouselActivePage: 0,
            renderTrigger: Guid.create().toString(),
            isEsign: false,
            showGroupFilesPreview: false,
            pages: [],
            destinationGroup: DocumentGroups.None,
            matchedForms: [],
            shareHolderInfo: {
                k1StatusId: 0,
                name: "",
                ssn: "",
                id: 0,
                emailAddress: "",
                k1DownloadDate: "",
                isK1Decline: false,
                k1SendDate: "",
                partnerAddress: "",
                entityType: EntityType.Individual,
                financialYearEnd: undefined,
                countryCode: "+1",
                mobileNumber: ""
            },
            verifyK1Replaced: false,
            isK1Restored: false,
            isK1Replaced: false,
            authoritiesOptions: [],
            shareHolderOptions: [],
            generateTaxPayer: false,
            attachments: [],
            //renderCache: {
            //    voucher: {
            //        bookmarks: [VoucherInfoView.getInitialVoucherBookmark()],
            //        vouchers: []
            //    }
            //},
            showClosePopover: false,
            disabledTaxpayerPreview: false,
            processingTaxReturn: undefined,
            refreshTaxReturn: false,
            disableSaveClose: false,
            signatureTypeInDocSettings: "",
            isSignatureoptionsToggledInDeliveryOption: false,
            signatureFormSelectionType: TaxDocument.SignatureType.None,
            isSaveAsDefaultOrder: true,
            uploadedAdditionalEsignDocumentData: [],
            selectedCustomColumn: { id: 0, value: "", customColumn: 0, inUseCount: 0 },
            isFileEdited: false,
            duplicatePages: [],
            popUpShown: false,
            duplicateFormPopup: false,
            showMismatchModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            selectedOption: {
                save: false,
                finish: false
            },
            popupInProgress: false,
            clientInfo: TaxDocument.initialClientInfoViewModel,
            prefilledClientInfo: {},
            clientBasicInfoId: 0,
            showLocationMismatchActionModel:false,
            isEroFromExtensionDb : false
        };
        this.undoState = [];
        this.undoTop = Helper.NO_INDEX;
    }


    onPrevious = (index: number | undefined) => {
        let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;

        activeIndex = currentViewIndex - 1;

        this.handleTabSelect(activeIndex);
    }

    onNext = (index: number | undefined) => {
        let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;

        activeIndex = currentViewIndex + 1;

        this.handleTabSelect(activeIndex);
    }

    componentWillReceiveProps(nextProps: IProcessReturnProps) {
        if (nextProps.show) {
            let saving = true;
            if (nextProps.taxDocuments) {
                if (nextProps.taxDocuments[nextProps.docId].taxReturn) {
                    saving = false;
                }
                if (nextProps.taxDocuments[this.props.docId].isFullyLoaded) {
                    if (nextProps.taxDocuments[this.props.docId].taxReturn.documentStatus === TaxDocument.DocumentStatus.READY && this.isUserHasAccessToDocumentLocation()) {  
                        this.updateDocumentStatus(nextProps.taxDocuments[this.props.docId].taxReturn);
                    }
                    this.setDuplicatePages(nextProps.taxDocuments[this.props.docId].taxReturn.formGroups);
                }
                this.setState({ isBusy: saving, isK1Restored: nextProps.isK1Restored });
                this.setTempProcessingDocument(nextProps.taxDocuments[this.props.docId].taxReturn);

            }
            this.excerptRetentionPeriod(nextProps.taxDocuments[nextProps.docId].taxReturn);
        }
    }

    shouldComponentUpdate(nextProps: IProcessReturnProps, nextState: IProcessReturnstate) {
        return ((!isEqual(this.state, nextState)) || (!isEqual(this.props, nextProps)));
    }

    private setTempProcessingDocument = (taxReturn: TaxDocument.ITaxReturn) => {
        if (!this.state.processingTaxReturn || this.state.processingTaxReturn.id !== this.props.docId) {
            this.setState({ processingTaxReturn: JSON.parse(JSON.stringify(taxReturn)) });
        }
    }

    checkIfEsignEnabled = (efileBookmarks: IBookmarkSection[]): boolean => {
        let e1040: any = TaxDocument.EngagementType[TaxDocument.EngagementType.E1040];
        let taxReturn = this.props.taxDocuments[this.props.docId].taxReturn;
        if (taxReturn.engagementType === e1040 &&
            GetEnableEsignatureForBusinessReturns(this.props.extensionCompanySettings, Helper.isBusinessReturn(taxReturn))) {
            return efileBookmarks[0].pages.some(i => i.pageTitle == 'New York');
        }
        else {
            return false;
        }

    }

    updateTaxReturnLockStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.lockedBy = this.props.userBasicProfile.userId == undefined ? 0 : this.props.userBasicProfile.userId;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
    }
    resetTaxReturnLockStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.lockedBy = 0;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
    }

    updateDocumentStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
        taxDocument.assignTo = !this.props.userBasicProfile.userId ? 0 : this.props.userBasicProfile.userId;
        taxDocument.lockedBy = !this.props.userBasicProfile.userId ? 0 : this.props.userBasicProfile.userId;
        taxDocument.assignedUser.userId = !this.props.userBasicProfile.userId ? 0 : this.props.userBasicProfile.userId;
        taxDocument.assignedUser.firstName = !this.props.userBasicProfile.userId ? '' : this.props.userBasicProfile.firstName;
        taxDocument.assignedUser.lastName = !this.props.userBasicProfile.userId ? '' : this.props.userBasicProfile.lastName;
        taxDocument.assignedUser.email = !this.props.userBasicProfile.userId ? '' : this.props.userBasicProfile.emailAddress;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
        logClientEvent(
            `${DocumentEventLog.DocumentInitialStatusUpdateSuccess}`, 
            { 
                Count: 1, 
                CustomEventType: 
                DocumentEventLog.Name, 
                Page: pageTitle, DocumentGuid: taxDocument.documentGuid, 
                DocumentStatus: taxDocument.documentStatus
            });
    }

    componentDidMount() {
        let taxReturn: TaxDocument.ITaxReturn = this.props.taxDocuments[this.props.docId].taxReturn;
        if (taxReturn.documentStatus != TaxDocument.DocumentStatus.READY) {
            this.updateTaxReturnLockStatus(taxReturn);
        }
        if (taxReturn.documentStatus === TaxDocument.DocumentStatus.REVIEW || taxReturn.documentStatus === TaxDocument.DocumentStatus.APPROVEDFORDELIVERY) {
            this.setState({ isSaveAsDefaultOrder: false });
        }
        this.props.requestVoucherDueDates(taxReturn);
        this.props.requestPaymentUrls(taxReturn);
        this.props.requestVoucherFormNames(taxReturn);

        let defaultActiveTab: number = parseInt(localStorage.get(this.props.docId.toString()) == null ? 1
            : localStorage.get(this.props.docId.toString()));

        this.excerptRetentionPeriod(taxReturn);

        // Need to remove Additional ESign Tab from below after releae
        //if (defaultActiveTab == ExtensionProcessReturnTabs.InVoice && !this.props.companySettings.displaySettingsModel.isEnableInvoice) {
        //	defaultActiveTab = ExtensionProcessReturnTabs.ClientInfo;
        //}
        if (defaultActiveTab == ExtensionProcessReturnTabs.AdditionalEsignDocuments &&
            this.props.taxDocuments[this.props.docId].taxReturn &&
            this.props.taxDocuments[this.props.docId].taxReturn.additionalEsignDocuments.length < 1) {
            defaultActiveTab = ExtensionProcessReturnTabs.ClientInfo;
        }
        let signatureType: any = taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
        this.setState({
            viewIndex: defaultActiveTab,
            initialMFJValue: TaxDocument.isMutual(taxReturn),
            signatureTypeInDocSettings: signatureType,
            signatureFormSelectionType: taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType
        });
        getClientInfoByClientId(taxReturn.clientId, "", this.editClientInfoResourceId, this.setclientInfo);
        this.updateMailingAddress();
    }

    public updateMailingAddress = () => {
        const manualAddressId =
            this.props.taxDocuments[this.props.docId]?.taxReturn.documentSettings.deliverySettings.manualAddressId;
        const selectedMailingAddress =
            this.props.mailingReturnAddressList[manualAddressId] === undefined
                ? 0
                : this.props.mailingReturnAddressList[manualAddressId].id;

        this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.manualAddressId =
            selectedMailingAddress;
    };

    handleSignatureOptionToggle = (event: any) => {
        this.setState({ isSignatureoptionsToggledInDeliveryOption: event.target.checked });
    }

    handleCustomColumnChange = (customColumnVal: IColumnValues) => {
        let _taxReturn = this.props.taxDocuments[this.props.docId].taxReturn
        _taxReturn.documentSettings.documentDisplaySetting.customColumn = customColumnVal;
        this.props.updateTaxDocument(_taxReturn);
    }

    handleEfileAddedToggle = (
        isFileEdited: boolean
    ): void => {
        if (isFileEdited) {
            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ESignWhenAllIncludedReturnsAllowed;
        }
        this.setState({
            isFileEdited: isFileEdited
        });
    }

    private handleSaveAsDefaultOrderChange = (event: any) => {
        this.setState({ isSaveAsDefaultOrder: event.target.checked });

    }

    private checkDeceased = (isMutual: boolean, taxpayerDeceased: boolean, spouseDeceased: boolean) => {

        let tempTaxReturn: any = { ...this.props.taxDocuments[this.props.docId].taxReturn };
        let tempFormsGroup: TaxDocument.IMarriedJointTaxReturn = { ...this.props.taxDocuments[this.props.docId].taxReturn as TaxDocument.IMarriedJointTaxReturn };
        let formIndex = -1;
        let voucherIndex = -1;
        formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        let additionalIndex = tempFormsGroup.additionalEsignDocuments.length;

        if (isMutual && taxpayerDeceased && spouseDeceased) {
            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = TaxDocument.SignatureType.ManualSign;

            if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                if (formIndex > -1) {
                    let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                    efileForms.map(efileForm => {
                        efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                        efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                    })
                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                }
                if (voucherIndex > -1) {
                    let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                    voucherForms.map(voucherForm => {
                        voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                        voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                    })
                    tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                }
                if (additionalIndex > 0) {
                    let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                    additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                        documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Taxpayer);
                        documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Spouse);
                    }))
                    tempFormsGroup.additionalEsignDocuments = additionalForms;
                }
                tempFormsGroup.spouse = tempTaxReturn.spouse
                tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
            }
        }
        else if (isMutual && (!taxpayerDeceased || !spouseDeceased)) {
            if (taxpayerDeceased) {
                this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = this.state.signatureFormSelectionType;
                if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                        efileForms.map(efileForm => {
                            efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                        })
                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    if (voucherIndex > -1) {
                        let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                        voucherForms.map(voucherForm => {
                            voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                        })
                        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                    }
                    if (additionalIndex > 0) {
                        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                        additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                            documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Taxpayer);
                        }))
                        tempFormsGroup.additionalEsignDocuments = additionalForms;
                    }
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Spouse;
                }
            }
            else if (spouseDeceased) {
                this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = this.state.signatureFormSelectionType;
                if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                        efileForms.map(efileForm => {
                            efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                        })
                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    if (voucherIndex > -1) {
                        let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                        voucherForms.map(voucherForm => {
                            voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                        })
                        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                    }
                    if (additionalIndex > 0) {
                        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                        additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                            documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Spouse);
                        }))
                        tempFormsGroup.additionalEsignDocuments = additionalForms;
                    }
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }
        }
        else if (!isMutual && taxpayerDeceased) {
            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = TaxDocument.SignatureType.ManualSign;
            if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                if (formIndex > -1) {
                    let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                    efileForms.map(efileForm => {
                        efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                    })
                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                }
                if (voucherIndex > -1) {
                    let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                    voucherForms.map(voucherForm => {
                        voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Taxpayer);
                    })
                    tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                }
                if (additionalIndex > 0) {
                    let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                    additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                        documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.Taxpayer);
                    }))
                    tempFormsGroup.additionalEsignDocuments = additionalForms;
                }
                tempFormsGroup.spouse = tempTaxReturn.spouse
            }
        }
        else if (!isMutual && !taxpayerDeceased) {
            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = this.state.signatureFormSelectionType;
        }
    }

    private excerptRetentionPeriod = (taxReturn: TaxDocument.ITaxReturn) => {
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            this.setLocalState(taxReturn, this.props.extensionCompanySettings, taxReturn.taxpayer.additionalInfo.isAttested);
        }

        else if (TaxDocument.isPartnership(taxReturn)) {
            this.setLocalState(taxReturn, this.props.extensionCompanySettings, taxReturn.partnership.additionalInfo.isAttested);
        }
    }

    private setLocalState(taxReturn: TaxDocument.ITaxReturn, compSettings: ExtensionCompanySettings, isAttested: boolean) {        
        if (taxReturn.documentSettings.documentRetentionSetting.retentionPeriod <= 0) {
            let retentionPeriod: number = 0;
            retentionPeriod = isAttested ? compSettings.retentionSettingsModel.attestRetentionPeriod : compSettings.retentionSettingsModel.retentionPeriod;
            taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        }
    }

    setDuplicatePages = (formGroups: TaxDocument.IGroup[]) => {

        if (formGroups && formGroups.length) {

            const allForms: IFormBase[] = [];

            formGroups
                .filter(x => x.group !== DocumentGroups.ClientInfo)
                .forEach(x => allForms.push(...x.forms));

            const pageNoOccurence = countBy(allForms, "pageNo[0]");

            const duplicatePages: number[] = [];

            for (let key in pageNoOccurence) {
                if (pageNoOccurence[key] > 1) {
                    duplicatePages.push(Number(key));
                }
            }

            this.setState({
                duplicatePages
            }, () => {

                if (duplicatePages && duplicatePages.length && !this.state.popUpShown) {
                    this.setState({ popUpShown: true });
                    this.showDuplicateFormsErrorPopup(duplicatePages, formGroups);
                }

            });

        }
    }

    removeDuplicateForm = (duplicateForm: IFormBase) => {
        const taxDocument = this.props.taxDocuments[this.props.docId].taxReturn;
        taxDocument.formGroups = taxDocument.formGroups.map((group: TaxDocument.IGroup) => {
            if (group.group === DocumentGroups.ClientInfo) {
                return group;
            }
            group.forms = group.forms.filter(form => {
                if (form.pageNo[0] === duplicateForm.pageNo[0] &&
                    form.formType === duplicateForm.formType &&
                    form.bookmark === duplicateForm.bookmark &&
                    form.formName === duplicateForm.formName &&
                    form.id === duplicateForm.id
                ) {
                    return false;
                }
                return true;
            });
            return group;
        });
        this.props.updateTaxDocument(taxDocument);
    }

    showDuplicateFormsErrorPopup = (duplicatePages: number[], formGroups: TaxDocument.IGroup[]) => {

        if (!duplicatePages || !duplicatePages.length || !formGroups || !formGroups.length) {
            return;
        }

        let message = "<h4>A form has been identified as a payment voucher and an estimated voucher, which is not allowed. Please go to Filing Forms and remove one to enable delivery.<h4></br>";

        message = message + `<h5>Form Names</h5>`;

        message = message + "<div>";

        duplicatePages.forEach(page => {
            message = message + "<div style='padding: 10px'>";

            formGroups.forEach(group => {

                if (group.forms.some(x => x.pageNo[0] === page)) {


                    message = message + "<ul>";

                    group.forms.forEach(form => {
                        if (form.pageNo[0] == page) {
                            message = message + `<li>${form.formName}</li>`;
                        }

                    });

                    message = message + "</ul>";
                }

            });

            message = message + "</div>";
        });

        message = message + "</div>";


        bootbox.dialog({
            message: message,
            buttons: {
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: string) => {

            }
        });

    }

    private setclientInfo = (clientInfo: TaxDocument.ClientInfoWithEroCheck) => {
        this.setState({ clientInfo: clientInfo.clientInfo });
        this.setState({ isEroFromExtensionDb : clientInfo.isEroFromExtensionDb})
        this.preFillClientInfo();
    }

    prefillEROInfo = (taxreturn: ITaxReturn, clientInfo: TaxDocument.IClientInfoViewModel) => {
           if(Number(clientInfo.ero) > 0)
           {
                if(!taxreturn.partnerId && clientInfo.ero){
                    taxreturn.partnerId = Number(clientInfo.ero)
                    taxreturn.partner.userId = Number(clientInfo.ero)
                    const partner = this.props.partners.find(e=>e.id == taxreturn.partnerId);
                    taxreturn.partner.firstName = partner?.firstName;
                    taxreturn.partner.lastName = partner?.lastName;
                    taxreturn.partner.email = partner?.emailAddress;
                    if(!this.state.isEroFromExtensionDb){
                        this.setPrefillInfo(ClientInfoProperty.eroPartnerId);
                    }else{
                        this.resetPrefillInfo(ClientInfoProperty.eroPartnerId);
                    }
                    this.props.updateTaxDocument(taxreturn);
                }
           } 
           if(!taxreturn.partnerId && this.props.defaultEro > 0)
           {
                taxreturn.partnerId = Number(this.props.defaultEro)
                taxreturn.partner.userId = Number(this.props.defaultEro)
                var partner = this.props.partners.find(e=>e.id == taxreturn.partnerId);
                taxreturn.partner.firstName = partner.firstName;
                taxreturn.partner.lastName = partner.lastName;
                this.resetPrefillInfo(ClientInfoProperty.eroPartnerId);
                this.props.updateTaxDocument(taxreturn);
            }
    }

    prefillOficeLocationInfo = (taxreturn: ITaxReturn, clientInfo: TaxDocument.IClientInfoViewModel) => {
        if(Number(clientInfo.locationId) > 0)
        {
             if(!taxreturn.locationId && clientInfo.locationId){
                 taxreturn.locationId = Number(clientInfo.locationId)
                 taxreturn.location.locationId = Number(clientInfo.locationId)
                 taxreturn.location.locationName = clientInfo.locationName
                 this.setPrefillInfo(ClientInfoProperty.officeLocationId);
                 if (taxreturn.documentSettings.deliverySettings.manualAddressId == 0) {
                     taxreturn.documentSettings.deliverySettings.manualAddressId = Number(clientInfo.locationId)
                 }
                 this.props.updateTaxDocument(taxreturn);
             }
        }               
    }

    preFillClientInfo = () => {
        let taxreturn: ITaxReturn = this.props.taxDocuments[this.props.docId].taxReturn;
        if(taxreturn.partnerId){
            taxreturn.partner.userId = taxreturn.partnerId
            this.props.updateTaxDocument(taxreturn);
        }
        if (this.state.clientInfo) {
            let clientInfo: TaxDocument.IClientInfoViewModel = this.state.clientInfo;
            if (TaxDocument.isMutual(taxreturn) || TaxDocument.isIndividual(taxreturn)) {
                this.prefillTaxpayerInfo(taxreturn.taxpayer, clientInfo);
            }
            else {
                if (TaxDocument.isPartnership(taxreturn)) {
                    this.prefillPartnerInfo(taxreturn.partnership, clientInfo);
                }
            }

            if (TaxDocument.isMutual(taxreturn)) {
                this.prefillSpouseInfo(taxreturn.spouse, clientInfo, taxreturn);
            }
            this.prefillEROInfo(taxreturn, clientInfo)
            this.prefillOficeLocationInfo(taxreturn,clientInfo) 
            this.props.updateTaxDocument(taxreturn);
        }
    }

    setPrefillInfo = (item: ClientInfoProperty) => {
        this.setState(state => ({ prefilledClientInfo: { ...state.prefilledClientInfo, [item]: true } }));
    }

    resetPrefillInfo = (item: ClientInfoProperty) => {
        this.setState(state => ({ prefilledClientInfo: { ...state.prefilledClientInfo, [item]: false } }));
    }

    prefillTaxpayerInfo = (taxpayer: ITaxpayer, clientInfo: TaxDocument.IClientInfoViewModel) => {
        if (!taxpayer.name && clientInfo.name) {
            taxpayer.name = clientInfo.name;
            this.setPrefillInfo(ClientInfoProperty.taxpayerName);
        }
        if (!taxpayer.email && clientInfo.emailAddress) {
            taxpayer.email = clientInfo.emailAddress;
            this.setPrefillInfo(ClientInfoProperty.taxpayerEmail);
        }
        if (!Helper.FilterSSN(taxpayer.ssn) && clientInfo.uid) {
            taxpayer.ssn = Helper.getUnFormatedUID(clientInfo.uid);
            this.setPrefillInfo(ClientInfoProperty.taxpayerSSN);
        }
        if (!taxpayer.dob && clientInfo.dob) {
            taxpayer.dob = clientInfo.dob;
            this.setPrefillInfo(ClientInfoProperty.taxpayerDateOfBirth);
        }
        if (!taxpayer.mobileNumber && clientInfo.mobileNumber) {
            taxpayer.mobileNumber = clientInfo.mobileNumber;
            this.setPrefillInfo(ClientInfoProperty.taxpayerMobileNumber);
        }
        if (!taxpayer.countryCode && clientInfo.countryCode) {
            taxpayer.countryCode = clientInfo.countryCode;
            this.setPrefillInfo(ClientInfoProperty.taxpayerCountryCode);
        }

        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            taxpayer.isModified = true;
        }
    }

    prefillSpouseInfo = (spouse: ITaxpayer, clientInfo: TaxDocument.IClientInfoViewModel, taxreturn: TaxDocument.IMarriedJointTaxReturn) => {
        if (!spouse.name && clientInfo.spouseName) {
            spouse.name = clientInfo.spouseName;
            this.setPrefillInfo(ClientInfoProperty.spouseName);
        }
        if (!spouse.email && clientInfo.spouseEmailAddress) {
            taxreturn.spouse.email = clientInfo.spouseEmailAddress;
            this.setPrefillInfo(ClientInfoProperty.spouseEmail);
        }
        if (!Helper.FilterSSN(spouse.ssn) && clientInfo.spouseSsn) {
            spouse.ssn = clientInfo.spouseSsn;
            this.setPrefillInfo(ClientInfoProperty.spouseSSN);
        }
        if (!spouse.dob && clientInfo.spouseDob) {
            spouse.dob = clientInfo.spouseDob;
            this.setPrefillInfo(ClientInfoProperty.spouseDateOfBirth);
        }
        if (!spouse.mobileNumber && clientInfo.spouseMobileNumber) {
            spouse.mobileNumber = clientInfo.spouseMobileNumber;
            this.setPrefillInfo(ClientInfoProperty.spouseMobileNumber);
        }
        if (!spouse.countryCode && clientInfo.spouseCountryCode) {
            spouse.countryCode = clientInfo.spouseCountryCode;
            this.setPrefillInfo(ClientInfoProperty.spouseCountryCode);
        }

        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            spouse.isModified = true;
        }

        if (clientInfo.defaultFirstSigner) {
            taxreturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Spouse;
            taxreturn.documentSettings.isModified = true;
        }
        else {
            taxreturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer;
            taxreturn.documentSettings.isModified = true;
        }
    }

    prefillPartnerInfo = (partnership: ITaxClient, clientInfo: TaxDocument.IClientInfoViewModel) => {
        if (!partnership.name && clientInfo.name) {
            partnership.name = clientInfo.name;
            this.setPrefillInfo(ClientInfoProperty.partnerName);
        }
        if (!partnership.email && clientInfo.emailAddress) {
            partnership.email = clientInfo.emailAddress;
            this.setPrefillInfo(ClientInfoProperty.partnerEmail);
        }
        if (!Helper.FilterSSN(partnership.ssn) && clientInfo.uid) {
            partnership.ssn = clientInfo.uid;
            this.setPrefillInfo(ClientInfoProperty.partnerEin);
        }
        if (!partnership.mobileNumber && clientInfo.mobileNumber) {
            partnership.mobileNumber = clientInfo.mobileNumber;
            this.setPrefillInfo(ClientInfoProperty.partnerMobileNumber);
        }
        if (!partnership.countryCode && clientInfo.countryCode) {
            partnership.countryCode = clientInfo.countryCode;
            this.setPrefillInfo(ClientInfoProperty.partnerCountryCode);
        }
        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            partnership.isModified = true;
        }
    }



    public render() {

        let defaultActiveTab = this.state.viewIndex || 1;

        const { duplicatePages } = this.state;
        const { authorities, pdfDocuments, order, docId, taxDocuments } = this.props;
        const taxDocument = taxDocuments[docId].taxReturn;
        const isBusy = this.state.isBusy || !this.props.taxDocuments[docId].isFullyLoaded;

        const clientInfo = new ClientInfoView(taxDocument);
        const clientInfoBookmarks = clientInfo.getBookmarks();
        const clientInfoPages = clientInfo.getPages(clientInfoBookmarks);

        const transmittals = new Transmittalsview(taxDocument);
        const transmittalsbookmarks = transmittals.getBookmarks();
        const transmittalspages = transmittals.getPages(transmittalsbookmarks);
        const transmittalsSignatureControls = transmittals.getSignatureControls();

        const pdfLoading = pdfDocuments ?
            pdfDocuments[docId] ?
                pdfDocuments[docId].isLoading :
                false :
            false;
        let isBusinessExtension = Helper.isBusinessReturn(taxDocuments[docId].taxReturn);

        let enableESignature = GetEnableEsignatureForBusinessReturns(this.props.extensionCompanySettings, isBusinessExtension);

        const eFileInfo = new EfileInfoView(taxDocument);

        let efileBookmarks = eFileInfo.getBookmarks(authorities, taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType, enableESignature);

        const efilePages = eFileInfo.getPages(efileBookmarks);

        let isEsign = this.checkIfEsignEnabled(efileBookmarks);

        let signatureControls = eFileInfo.getSignatureControls(); 

        const pop = { 'max-width': "276px !important" }
        const finishPopover = (
            <Popover id="finish-popover" className="finishPopover"
                style={{ width: "750px", height: "530px", top: "219px" }}
            >
                <Popover.Title as="h3" style={{ fontSize: '14px', paddingLeft: '14px', paddingTop: '8px', paddingBottom: '10px' }}>Finish Extension Processing</Popover.Title>
                <Popover.Content>
                    <FinishPopover
                        taxreturn={this.props.taxDocuments[docId].taxReturn}
                        extensionUsers={this.props.extensionUsers}
                        userProfile={this.props.userBasicProfile}
                        companySettings={this.props.companySettings}
                        sendToERO={this.sendToERO}
                        sendForReview={this.sendForReview}
                        approveForDelivery={this.approveForDelivery}
                        deliverToClient={this.deliverToClient}
                        getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                        deliverWithTaxcaddy={this.deliverWithTaxcaddy}
                        updateTaxDocument={this.updateTaxDocument}
                        onPrintForPaperDelivery={this.onPrintForPaperDelivery}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        handleSaveAsDefaultOrderChange={this.handleSaveAsDefaultOrderChange}
                        isDefaultOrder={this.state.isSaveAsDefaultOrder}
                        companyData={this.props.company}
                        requestCompanyLogo={this.props.requestCompanyLogo}
                        extensionCompanySettings={this.props.extensionCompanySettings}
                    />
                </Popover.Content>


            </Popover>);

        return (<CustomWindow key={"custome-window" + taxDocument.id}
            onClose={() => { this.onModalClose(taxDocument.id) }}
            title={Helper.taxReturnTitle(taxDocument)}
            order={order}
            disableMaximize={true}
            disableMinimize={true}
            disableFullscreen={false}
            alwaysFitToParent={true}
            taxReturn={taxDocument}
            onReportProblemOpen={this.props.onReportProblemOpen}
            onResize={() => { this.setState({ renderTrigger: Guid.create().toString() }) }}>
            {
                (<CustomWindowBody isBusy={isBusy}>
                    {this.createPageCarousel()}
                    <Tabs
                        className="custom-tab"
                        activeKey={this.state.viewIndex ? this.state.viewIndex : defaultActiveTab}
                        onSelect={this.handleTabSelect}
                        id="process-return-groups"
                        justified={true}
                        fill>
                        <Tab eventKey={ExtensionProcessReturnTabs.ClientInfo.toString()}
                            title={<div className="overflowText">Client Info</div>}
                            className="zIndex10">
                            <React.Fragment>
                                {(this.state.viewIndex == ExtensionProcessReturnTabs.ClientInfo) && <TabClientInfo
                                    isLoading={this.props.isLoading}
                                    bookmarks={clientInfoBookmarks}
                                    pages={clientInfoPages}
                                    docId={docId}
                                    pdfDocuments={this.props.pdfDocuments}
                                    taxReturn={taxDocuments[docId].taxReturn}
                                    updateTaxDocument={this.props.updateTaxDocument}
                                    isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                    reGroupPreview={this.reGroupPreview}
                                    deletePages={this.deleteRefundOrPayment}
                                    renderTrigger={this.state.renderTrigger}
                                    tabType={ExtensionProcessReturnTabs.ClientInfo}
                                    allSignerList={this.props.partners}
                                    userList={this.props.users}
                                    getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                    authorities={this.props.authorities}
                                    states={this.props.company.companyProfile.countryStates}
                                    checkDeceased={this.checkDeceased}
                                    getVoucherUploadLink={this.props.getVoucherUploadLink}
                                    requestEroSignaturePath={this.props.requestEroSignaturePath}
                                    prefilledClientInfo={this.state.prefilledClientInfo}
                                    resetPrefillInfo={this.resetPrefillInfo}
                                    officeLocation={this.props.officeLocation}
                                />}
                            </React.Fragment>
                        </Tab>

                        <Tab eventKey={ExtensionProcessReturnTabs.Grouping.toString()}
                            title={<div className="overflowText">Group</div>}
                            className="zIndex10">
                            {
                                (this.state.viewIndex == ExtensionProcessReturnTabs.Grouping) &&
                                <GroupsView
                                    engagementType={taxDocuments[docId].taxReturn.engagementType}
                                    pdfDocuments={this.props.pdfDocuments}
                                    docId={docId}
                                    formGroups={taxDocument.formGroups.filter(x => x.group != TaxDocument.DocumentGroups.ClientInfo)}
                                    deletePages={this.deletePages}
                                    onPreviewPage={this.openCarouselView}
                                    renderTrigger={this.state.renderTrigger}
                                    reGroupPreview={this.reGroupPreview}
                                    efileBookmarks={efileBookmarks}
                                    duplicatePages={duplicatePages}
                                />
                            }

                        </Tab>

                        <Tab eventKey={ExtensionProcessReturnTabs.Transmittals.toString()}
                            title={<div className="overflowText">Transmittals</div>}
                            className="zIndex10">
                            {(this.state.viewIndex == ExtensionProcessReturnTabs.Transmittals) && <TabTransmittals
                                bookmarks={transmittalsbookmarks}
                                pages={transmittalspages}
                                docId={docId}
                                pdfDocuments={pdfDocuments}
                                taxReturn={taxDocuments[docId].taxReturn}
                                reGroupPreview={this.reGroupPreview}
                                deletePages={this.deletePages}
                                renderTrigger={this.state.renderTrigger}
                                getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                getVoucherUploadLink={this.props.getVoucherUploadLink}
                                voucherStore={this.props.voucherStore}
                                updateTaxDocument={this.props.updateTaxDocument}
                                getMultiVoucherUploadLink={this.props.getMultiVoucherUploadLink}
                                saveTaxingAuthority={this.props.saveTaxingAuthority}
                                requestCustomAuthority={this.props.requestCustomAuthority}
                                userSettings={this.props.userSettings}
                                signatureControls={transmittalsSignatureControls}
                                removeSignatureControl={this.removeSignatureControlTransmittalForm}
                                addSignatureControl={this.addSignatureControlTransmittalForm}
                                updateEROSignatureStamp={this.updateEROSignatureStamp}
                                replaceSignatureControl={this.replaceSignatureControlTransmittalForm}
                                company={this.props.company}
                                userProfile={this.props.userBasicProfile}
                                userSignatures={this.props.userSignatures}
                                isLoading={this.props.isLoading}
                                extensionCompanySettings={this.props.extensionCompanySettings}
                                duplicatePages={duplicatePages}
                                auth={this.props.auth}
                            />}
                        </Tab>

                        <Tab eventKey={ExtensionProcessReturnTabs.FilingForm.toString()}
                            title={<div className="overflowText">Filing Form</div>}
                            className="zIndex10">
                            {
                                (this.state.viewIndex == ExtensionProcessReturnTabs.FilingForm) &&
                                <TabEFile
                                    isLoading={this.props.isLoading}
                                    bookmarks={efileBookmarks}
                                    pages={efilePages}
                                    docId={docId}
                                    pdfDocuments={this.props.pdfDocuments}
                                    taxReturn={taxDocuments[docId].taxReturn}
                                    updateTaxDocument={this.props.updateTaxDocument}
                                    isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                    reGroupPreview={this.reGroupPreview}
                                    deletePages={this.deletePages}
                                    renderTrigger={this.state.renderTrigger}
                                    userSettings={this.props.userSettings}
                                    isEsign={isEsign}
                                    enableEsignatureForBusinessReturns={enableESignature}
                                    company={this.props.company}
                                    userProfile={this.props.userBasicProfile}
                                    signatureControls={signatureControls}
                                    userSignatures={this.props.userSignatures}
                                    removeSignatureControl={this.removeSignatureControlEFieForm}
                                    addSignatureControl={this.addSignatureControlEFieForm}
                                    updateEROSignatureStamp={this.updateEROSignatureStamp}
                                    replaceSignatureControl={this.replaceSignatureControlEFieForm}
                                    signatureFormSelectionType={(
                                        isBusinessExtension ?
                                            this.props.
                                                extensionCompanySettings.
                                                signatureSettingsModel.
                                                signatureFormSelectionTypeForBusiness
                                            :
                                            this.props.
                                                extensionCompanySettings.
                                                signatureSettingsModel.
                                                signatureFormSelectionTypeFor1040
                                    )}
                                    getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                    voucherStore={this.props.voucherStore}
                                    getVoucherUploadLink={this.props.getVoucherUploadLink}
                                    saveTaxingAuthority={this.props.saveTaxingAuthority}
                                    requestCustomAuthority={this.props.requestCustomAuthority}
                                    reGroupForms={this.reGroupForms}
                                    extensionCompanySettings={this.props.extensionCompanySettings}
                                    handleEfileAddedToggle={this.handleEfileAddedToggle}
                                    isFileEdited={this.state.isFileEdited}
                                    removeDuplicateForm={this.removeDuplicateForm}
                                    duplicatePages={duplicatePages}
                                    auth={this.props.auth}
                                />
                            }
                        </Tab>

                        <Tab eventKey={ExtensionProcessReturnTabs.AdditionalEsignDocuments.toString()}
                            title={<div className="overflowText">Additional E-Sign Documents</div>}
                            className="zIndex10">
                            {
                                (this.state.viewIndex == ExtensionProcessReturnTabs.AdditionalEsignDocuments) && <TabAdditionalEsignDocuments
                                    taxReturn={taxDocuments[docId].taxReturn}
                                    getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                                    getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}
                                    convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                                    requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                                    additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                                    deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
                                    deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                                    updateTaxDocument={this.props.updateTaxDocument}
                                    isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                    reGroupPreview={this.reGroupPreview}
                                    renderTrigger={this.state.renderTrigger}
                                    userSettings={this.props.userSettings}
                                    isEsign={isEsign}
                                    enableEsignatureForBusinessReturns={enableESignature}
                                    company={this.props.company}
                                    userProfile={this.props.userBasicProfile}
                                    userSignatures={this.props.userSignatures}
                                    updateEROSignatureStamp={this.updateEROSignatureStamp}
                                    signatureFormSelectionType={(
                                        isBusinessExtension ?
                                            this.props.
                                                extensionCompanySettings.
                                                signatureSettingsModel.
                                                signatureFormSelectionTypeForBusiness
                                            :
                                            this.props.
                                                extensionCompanySettings.
                                                signatureSettingsModel.
                                                signatureFormSelectionTypeFor1040
                                    )}
                                    isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                    addAdditionalEsignDocumentData={this.addAdditionalEsignDocumentData}
                                    uploadedAdditionalEsignDocumentData={this.state.uploadedAdditionalEsignDocumentData}
                                    updateAdditionalEsignDocumentData={this.updateAdditionalEsignDocumentData}
                                    isLoading={this.props.isLoading}
                                    extensionCompanySettings={this.props.extensionCompanySettings}
                                    auth={this.props.auth} />
                            }
                        </Tab>

                        <Tab
                            eventKey={ExtensionProcessReturnTabs.DeliveryOptions.toString()}
                            title={<div className="overflowText">Delivery Options</div>}>
                            {(this.state.viewIndex == ExtensionProcessReturnTabs.DeliveryOptions) &&
                                <TabDeliveryOptions
                                    taxReturn={taxDocuments[docId].taxReturn}
                                    company={this.props.company}
                                    extensionCompanySettings={this.props.extensionCompanySettings}
                                    isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                    isEsign={isEsign}
                                    generateTaxPayerView={this.generateTaxPayerView}
                                    extensionUsers={this.props.extensionUsers}
                                    savedMessages={this.props.savedMessages}
                                    getAllSavedMessages={this.props.getAllSavedMessages}
                                    mailingReturnAddressDictionary={this.props.mailingReturnAddressList!}
                                    disabledTaxpayerPreview={this.state.disabledTaxpayerPreview}
                                    signatureTypeInDocSettings={this.state.signatureTypeInDocSettings}
                                    isSignatureoptionsToggledInDeliveryOption={this.state.isSignatureoptionsToggledInDeliveryOption}
                                    handleSignatureOptionToggle={this.handleSignatureOptionToggle}
                                    //watermarkList={this.props.watermarkList}
                                    customColumnData={this.props.customColumnData}
                                    userSettings={this.props.userSettings}
                                    handleCustomColumnChange={this.handleCustomColumnChange}
                                    isLoading={this.props.isLoading}
                                    pdfLoading={pdfLoading}
                                    handleEfileAddedToggle={this.handleEfileAddedToggle}
                                    updateTaxDocument={this.props.updateTaxDocument}
                                />}
                        </Tab>
                    </Tabs>
                </CustomWindowBody>)
            }

            <CustomWindowFooter>
                <Button
                    data-test-auto="27A54F41-0CFF-4F81-B684-2052B7424A9B"
                    variant="light"
                    disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMin || isBusy}
                    onClick={(e: any) => { this.onPrevious(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) }}>
                    <i className='fa fa-chevron-left'></i>Previous
                </Button>
                <Button
                    data-test-auto="B1C60A60-C306-4A4D-B914-AC034DCB71E6"
                    variant="light"
                    disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMax || isBusy}
                    onClick={(e: any) => { this.onNext(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) }}>
                    <i className='fa fa-chevron-right'></i>Next
                </Button>
                <Button
                    disabled={isBusy || this.props.isLoading || pdfLoading}
                    data-test-auto="BC827AB3-8EBD-4A3B-A7F9-72D632DCFB0D"
                    variant="info" onClick={this.onSaveTaxReturn} >
                    <i className='fas fa-save'></i>Save & Close
                </Button>
                <Button
                    disabled={isBusy || this.props.isLoading || pdfLoading}
                    data-test-auto="B88BCB03-B5BB-4EA6-AE43-D80336A1A927"
                    variant="success" onClick={this.onFinishProcessing}
                    ref={(reference: any) => { this.closePopoverRef = reference }}
                >
                    <i className='fa fa-check'></i>Finish
                </Button>

                <Overlay rootClose={true} placement="top"
                    onHide={() => { this.setState({ showClosePopover: false }) }}
                    target={this.closePopoverRef} show={this.state.showClosePopover}>
                    {finishPopover}
                </Overlay>
                {
                    this.state.showMismatchModal &&
                    <MismatchModal
                        showMismatchModal={this.state.showMismatchModal}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        clientManagementSave={this.clientManagementSave}
                        clientManagementCancel={this.clientManagementCancel}
                        closeMismatchModal={this.closeMismatchModal}
                        taxReturnType={Helper.GetTaxReturnType(this.props.taxDocuments[this.props.docId]?.taxReturn)}
                        popupInProgress={this.state.popupInProgress}
                    />
                }

                {
                    this.state.showLocationMismatchActionModel &&
                    <LocationMismatchActionModal
                        onCancel={this.onCloseLocationMismatchActionModal}
                        onContinue={this.onContinueLocationMismatchActionModal}
                        show={this.state.showLocationMismatchActionModel}
                        location={this.props.taxDocuments[docId].taxReturn.location.locationName}
                    />
                }

            </CustomWindowFooter>
            <GroupFilesPreview
                showGroupFilesPreview={this.state.showGroupFilesPreview}
                onHideGroupFilesPreview={this.onHideGroupFilesPreview}
                pages={this.state.pages}
                destinationGroup={this.state.destinationGroup}
                matchedForms={this.state.matchedForms}
                pdfDocuments={this.props.pdfDocuments}
                documentId={docId}
                renderTrigger={this.state.renderTrigger}
                removePage={this.removePage}
                onMove={this.reGroupForms}
                authoritiesOptions={this.state.authoritiesOptions}
                shareHolderOptions={this.state.shareHolderOptions}
                onVoucherDataUpdate={this.onVoucherDataUpdate}
                onEFileDataUpdate={this.onEFileDataUpdate}
                taxReturn={taxDocuments[docId].taxReturn}
                voucherStore={this.props.voucherStore}
                allTaxingAuthorities={this.props.getAllTaxingAuthorities}
                handleSignatureChange={this.handleSignatureChange}
                handleVoucherChange={this.handleVoucherChange}
                extensionCompanySettings={this.props.extensionCompanySettings}
            />
        </CustomWindow >)
    }

    private taxReturnHeading = () => {
        {
            const { docId, taxDocuments } = this.props;
            const taxDocument = taxDocuments[docId].taxReturn;
            return TaxDocument.isPartnership(taxDocument) ? (taxDocument.partnership.name + " - " + taxDocument.clientId) :
                TaxDocument.isMutual(taxDocument) ? (taxDocument.taxpayer.name + " - " + taxDocument.clientId) :
                    TaxDocument.isIndividual(taxDocument) ? (taxDocument.taxpayer.name + " - " + taxDocument.clientId) : ""
        }
    }

    private handleTabSelect = (index: any) => {
        this.setState({ viewIndex: parseInt(index.toString()) }, () => {
            localStorage.set(this.props.docId.toString(), index);
        });
    }

    private isAssignedToLoggedinUser = (): boolean => {
        return true;//TODO: implement the logic
    }

    ///////////////////////////////////////Grouping Start//////////////////////////////////////////////////////////
    private reGroupPreview = (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => {

        const taxDocument = this.props.taxDocuments[this.props.docId].taxReturn;
        const matchedForms: TaxDocument.IFormBase[] = [];
        let isValid: boolean = true;
        let pagesWithSourceGroup: object[] =[];

        taxDocument.formGroups.map((form) => {
            if (form.group == DocumentGroups.EFile) {
                const efileAuthrity: IEFile = form.forms.find(x => x.pageNo[0] === pages[0]) as IEFile;
                if (efileAuthrity != undefined && efileAuthrity.authorityID == 0) {
                    VenusNotifier.Warning(TabEFileConstants.EfileForm.Authority, "");
                    isValid = false;
                }
            }
        });

        if (isValid) {
            taxDocument.formGroups.map((formGroups) => {

                if (formGroups.group !== DocumentGroups.ClientInfo) {

                    if (formGroups.group != destinationGroup ||
                        destinationGroup === DocumentGroups.EFile) {
                        formGroups.forms.map((forms) => {
                            if (forms.pageNo.some(x => pages.some(y => y == x))) {
                                matchedForms.push(forms);
                                pagesWithSourceGroup.push(
                                    {"PageNo":forms.pageNo[0],"SourceGroup": formGroups.group}
                                    );
                            }
                        });
                    } else {
                        formGroups.forms.map((forms) => {
                            pages = pages.filter(function (page) {
                                return forms.pageNo.indexOf(page) < 0;
                            });
                        });
                    }
                }
            });

            if (pages.length > 0) {
                switch (destinationGroup) {
                    case TaxDocument.DocumentGroups.Transmittals:
                        this.reGroup(pages, matchedForms, destinationGroup, pagesWithSourceGroup);
                        VenusNotifier.Success(Constants.ReGroupConstants.PageMoveSuccess, null);
                        break;
                    case TaxDocument.DocumentGroups.TaxReturns:
                        this.reGroup(pages, matchedForms, destinationGroup, pagesWithSourceGroup);
                        VenusNotifier.Success(Constants.ReGroupConstants.PageMoveSuccess, null);
                        break;
                    case TaxDocument.DocumentGroups.Deleted:
                        this.reGroup(pages, matchedForms, destinationGroup, pagesWithSourceGroup);
                        VenusNotifier.Success(Constants.ReGroupConstants.DeletedPageMoveSuccess, null);
                        break;
                    case TaxDocument.DocumentGroups.Vouchers:
                        const tmpMatchedVoucherForm: TaxDocument.IVoucher[] = [];
                        matchedForms.map(i => {
                            tmpMatchedVoucherForm.push({
                                bookmark: i.bookmark,
                                formName: i.formName,
                                pageNo: i.pageNo,
                                authorityID: 0,
                                dueDate: undefined,
                                amount: 0,
                                voucherNo: TaxDocument.VoucherNo.None,
                                paymentType: TaxDocument.VoucherTypes.None,
                                customType: TaxDocument.CustomType.None,
                                onlinePaymentUri: "",
                                customText: "",
                                authorityImage: "",
                                authorityName: "",
                                groupId: undefined,
                                isMasterPaymentUrl: false,
                                vocherMode: TaxDocument.VoucherMode.Recognized,
                                isFormNameUpdated: false,
                                formType: TaxDocument.TaxFormType.Voucher,
                                signatureControls: [],
                                signatureEnable: false,
                                voucherEnable: true,
                                printedDueDate: undefined,
                                voucherDueDateType: DueDateType.SSExtDefault,
                                id: 0
                            });
                        })
                        this.setAuthorityOptions();
                        this.setState({
                            showGroupFilesPreview: true,
                            pages: pages,
                            destinationGroup: destinationGroup,
                            matchedForms: tmpMatchedVoucherForm as TaxDocument.IVoucher[],
                        });
                        break;
                    case TaxDocument.DocumentGroups.EFile:
                        const tmpMatchedForm: TaxDocument.IEFile[] = [];
                        matchedForms.map(i => {
                            tmpMatchedForm.push({
                                bookmark: i.bookmark,
                                formName: i.formName,
                                pageNo: i.pageNo,
                                signatureControls: [],
                                authorityID: 0,
                                formType: TaxDocument.TaxFormType.EFile,
                                signatureEnable: true,
                                voucherEnable: false,
                                id: 0
                            });
                        })
                        this.setAuthorityOptions();
                        this.setState({
                            showGroupFilesPreview: true,
                            pages: pages,
                            destinationGroup: destinationGroup,
                            matchedForms: tmpMatchedForm as TaxDocument.IEFile[],
                        });
                        break;
                }
            } else {
                VenusNotifier.Warning(Constants.ReGroupConstants.SameSourceAndDestinationError, null);
            }
        }
    }

    updateTaxFormType = (forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
        forms.map((form, index) => {
            switch (destinationGroup) {
                case TaxDocument.DocumentGroups.Transmittals:
                    form.formType = TaxDocument.TaxFormType.Transmittal;
                    break;
                case TaxDocument.DocumentGroups.TaxReturns:
                    form.formType = TaxDocument.TaxFormType.TaxReturn;
                    break;
                case TaxDocument.DocumentGroups.EFile:
                    form.formType = TaxDocument.TaxFormType.EFile;
                    break;
                case TaxDocument.DocumentGroups.Vouchers:
                    form.formType = TaxDocument.TaxFormType.Voucher;
                    break;
                default:
                    form.formType = TaxDocument.TaxFormType.FormBase;
                    break;
            }
        });
    }

    private CheckSameGroupDestination(forms: TaxDocument.IGroupFormBase[]) {
        let moveToSameGroup = false;
        const taxReturn = this.props.taxDocuments[this.props.docId].taxReturn;
        forms.forEach(form => {
            const destinationGroup = form.voucherEnable ?
                DocumentGroups.Vouchers :
                form.signatureEnable ?
                    DocumentGroups.EFile :
                    DocumentGroups.TaxReturns;

            if (taxReturn.formGroups.find((x) => x.group === destinationGroup)?.forms
                .find((y) => y.pageNo === form.pageNo)) {
                moveToSameGroup = true;
            }
        });
        return moveToSameGroup;
    }

    private reGroupForms = (
        pages: number[],
        forms: TaxDocument.IGroupFormBase[],
        destinationGroup: TaxDocument.DocumentGroups) => {

        const moveToSameGroup = this.CheckSameGroupDestination(forms);

        let isFileEdited: boolean = this.state.isFileEdited;
        const isBusinessRet = isBusinessReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const isStrictReview = isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet);

        if (moveToSameGroup) {
            VenusNotifier.Warning(Constants.ReGroupConstants.SameSourceAndDestinationError, null);
            return;
        }

        if (this.validateGroupData(forms)) {
            forms.forEach((form) => {
                const currentDestinationGroup = form.voucherEnable ?
                    DocumentGroups.Vouchers :
                    form.signatureEnable ?
                        DocumentGroups.EFile :
                        DocumentGroups.TaxReturns;

                this.updateTaxFormType([form], currentDestinationGroup);

                const clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);

                Helper.removeForms(clone, form.pageNo);

                const formGroup = clone.formGroups.find((x: any) => x.group == currentDestinationGroup);

                if (formGroup) {
                    if (formGroup.forms) {

                        const nextId = Math.max(...formGroup.forms.map(x => x.id));

                        formGroup.forms = formGroup
                            .forms
                            .concat(forms.map((x, index) => { x.id = nextId + index + 1; return x; }));
                    } else {
                        formGroup.forms = [{ ...form, id: 1 }];
                    }
                } else {
                    clone.formGroups.push({ group: currentDestinationGroup, forms: [{ ...form, id: 1 }] });
                }

                this.sortFormGroup(clone, currentDestinationGroup);
                //this.cleanUpFormGroup(clone, TaxDocument.DocumentGroups.K1);
                this.updateTaxDocument(clone);

                if (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                    !isStrictReview && form.signatureEnable) {
                    isFileEdited = true;
                }
            });

            if (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)
                && !isStrictReview) {
                this.handleEfileAddedToggle(isFileEdited);
            }

            this.onHideGroupFilesPreview();
            if (destinationGroup == DocumentGroups.EFile) {
                VenusNotifier.Success(Constants.ReGroupConstants.PageMoveSuccess, null);
            }
        }
    }

    private reGroup = (
        pages: number[],
        forms: TaxDocument.IFormBase[],
        destinationGroup: TaxDocument.DocumentGroups,
        pagesWithSourceGroup: object[]) => {

        if (this.validateReGroupData(destinationGroup, forms)) {
            this.updateTaxFormType(forms, destinationGroup);

            const clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
            Helper.removeForms(clone, pages);
            const formGroup = clone.formGroups.find(x => x.group == destinationGroup);
            if (formGroup) {
                if (formGroup.forms) {

                    const nextId = Math.max(...formGroup.forms.map(x => x.id));

                    formGroup.forms = formGroup
                        .forms
                        .concat(forms.map((x, index) => { x.id = nextId + index + 1; return x; }));
                } else {
                    formGroup.forms = forms.map((x, index) => { x.id = index + 1; return x; });
                }
            } else {
                clone.formGroups.push({
                    group: destinationGroup,
                    forms: forms.map((x, index) => { x.id = index + 1; return x; })
                });
            }

            this.sortFormGroup(clone, destinationGroup);
            //this.cleanUpFormGroup(clone, TaxDocument.DocumentGroups.K1);

            this.updateTaxDocument(clone);

            this.onHideGroupFilesPreview();

            if (destinationGroup == DocumentGroups.EFile || destinationGroup == DocumentGroups.Vouchers) {
                VenusNotifier.Success(Constants.ReGroupConstants.PageMoveSuccess, null);
            }

            logClientEvent(
                `${DocumentEventLog.PageAddedToGroup}: ${destinationGroup}`,
                {
                    Count: 1,
                    CustomEventType: DocumentEventLog.Name,
                    DocumentId: this.props.docId,
                    Pages: pages,
                    PagesWithSourceGroup: pagesWithSourceGroup,
                    DestinationGroup: destinationGroup
                });

        }
    }

    getNextFormId = (group: DocumentGroups) => {

    }

    onHideGroupFilesPreview = () => {
        this.setState({ showGroupFilesPreview: false })
    }

    removePage = (event: any, pageNo: number) => {
        let pages: number[] = [...this.state.pages];
        pages.splice(pages.indexOf(pageNo), 1)
        let forms = [...this.state.matchedForms];
        forms.splice(forms.findIndex(x => x.pageNo[0] == pageNo), 1);
        this.setState({
            showGroupFilesPreview: pages.length == 0 ? false : true,
            matchedForms: forms,
            pages: pages
        })
    }


    sortFormGroup = (taxReturn: ITaxReturn, group: TaxDocument.DocumentGroups) => {
        switch (group) {
            case DocumentGroups.EFile:
                const eFileInfo = new EfileInfoView(taxReturn);

                let isBusinessExtension = Helper.isBusinessReturn(taxReturn);

                let enableESignature = GetEnableEsignatureForBusinessReturns(this.props.extensionCompanySettings, isBusinessExtension);

                const efileBookmarks = eFileInfo.getBookmarks(this.props.authorities,
                    taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType, enableESignature);

                const efilePages = eFileInfo.getPages(efileBookmarks);

                //return eFileInfo.sortGroup(efilePages, DocumentGroups.EFile);
                return eFileInfo

        }
    }
    ///////////////////////////////////////Grouping End//////////////////////////////////////////////////////////




    private getK1Pages = (bookmarkId: number, clientInfo: any) => {
        this.setState({ k1Pages: clientInfo.getK1Pages(bookmarkId) })
    }

    //getShareHolderInfo(id: number) {
    //    return this.props.taxDocuments[this.props.docId].taxReturn.formGroups.filter(x => x.group == DocumentGroups.K1)
    //        .map((i: any) => { return i.shareHolder }).filter(y => y.id == id)[0];
    //}

    private K1InstructionModified = (verifyK1Replaced: boolean, isK1Restored: boolean, isK1Replaced?: boolean) => {
        isK1Replaced != undefined ?
            this.setState({ verifyK1Replaced: verifyK1Replaced, isK1Restored: isK1Restored, isK1Replaced: isK1Replaced }) :
            this.setState({ verifyK1Replaced: verifyK1Replaced, isK1Restored: isK1Restored })
            ;
    }

    //private onK1BookmarkClick = (pageTitle: string, id: number) => {
    //    this.setState({ selectedK1Bookmark: id });
    //    this.getShareHolderInfo(id);

    //}

    private disableSaveClose = (option: boolean): void => {
        this.setState({
            disableSaveClose: option
        })
    }

    private openCarouselView = (group: TaxDocument.DocumentGroups, activePage: number) => {
        this.setCarouselImages(group, activePage);
    }

    private createPageCarousel = () => {
        return <PageCarousel currentImage={this.state.pdfCarouselActivePage} onClose={() => { this.setState({ showCarousel: false, pdfPages: [] }) }}
            images={this.state.pdfPages} open={this.state.showCarousel} />;
    }

    private setCarouselImages = (group: TaxDocument.DocumentGroups, activePage: number) => {

        if (this.props.taxDocuments[this.props.docId]) {
            const { formGroups } = this.props.taxDocuments[this.props.docId].taxReturn;

            let forms: IFormBase[] = [];

            if (group === TaxDocument.DocumentGroups.Deleted) {
                if (formGroups.filter((m) => m.group === group).length > 0) {
                    forms = forms.concat(formGroups.find((x) => x.group == group)!.forms.sort());
                }
            }
            if (group === TaxDocument.DocumentGroups.EFile) {
                const eFileForms = formGroups.find(x => x.group == TaxDocument.DocumentGroups.EFile)?.forms.sort() ?? [];
                const voucherForms = formGroups.find(x => x.group == TaxDocument.DocumentGroups.Vouchers)?.forms.sort() ?? [];
                const extensionForms = formGroups.find(x => x.group == TaxDocument.DocumentGroups.TaxReturns)?.forms.sort() ?? [];
                if (eFileForms && eFileForms.length) {
                    forms = forms.concat(eFileForms);
                }
                if (voucherForms && voucherForms.length) {
                    forms = forms.concat(voucherForms);
                }
                if (extensionForms && extensionForms.length) {
                    forms = forms.concat(extensionForms);
                }
            } else {
                forms = formGroups.find(x => x.group == group)!.forms.sort();
            }

            const pages: number[] = forms.map(x => x.pageNo[0]);
            let documentFacade: IPdfDocumentFacade = (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            var activeIndex = pages.indexOf(activePage[0]);

            pages.map((value: number, index: number) => {

                let tmpPdfPage: Promise<PDFPageProxy> = documentFacade.getPage(value);

                tmpPdfPage.then((page: PDFPageProxy) => {

                    let tmpPdfPageSource: Promise<string> = documentFacade.getPageSourceBase64(page);

                    tmpPdfPageSource.then((pageURL: string) => {

                        let title: string = forms.find(x => x.pageNo[0] == value)!.formName == undefined ?
                            " [No Name] ( " + TaxDocument.DocumentGroups[group] + " ) " :
                            forms.find(x => x.pageNo[0] == value)!.formName +
                            " ( " + TaxDocument.DocumentGroups[group] + " ) ";

                        this.state.pdfPages.push({
                            source: pageURL,
                            pageNo: index,
                            caption: title
                        } as CarouselImage);

                        if (this.state.pdfPages.length == pages.length) {
                            this.state.pdfPages.sort((a: CarouselImage, b: CarouselImage) => a.pageNo - b.pageNo);
                            this.setState({
                                pdfPages: this.state.pdfPages, showCarousel: true,
                                pdfCarouselActivePage: this.state.pdfPages.findIndex(x => x.pageNo == activeIndex)
                            });
                        }
                    });
                });

            });
        }
    }
    /////////////////////////////////////////Finish Modal Popup Start/////////////////////////////////////////////////
    sendToERO = () => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);
        let user: User.IUserModel = this.props.partners.find(user => user.id === taxReturn.partnerId);
        taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
        taxReturn.assignTo = taxReturn.partnerId;
        taxReturn.assignedUser = {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.emailAddress
        } as IUserBaseModel;
        taxReturn.isModified = true;
        taxReturn.lockedBy = 0;
        if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
            this.setState({ isBusy: true, generateTaxPayer: false },
                () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder) {
                        this.props.updateDocumentGroupOrder(taxReturn.engagementType, taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder);
                    }
                    this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(taxReturn), this.getSubDocuments(), this.state.verifyK1Replaced, this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                        processReturnActionEndPoints.sendToEROAsync, this.callback);

                    logClientEvent(
                        `${DocumentEventLog.SendExtensionToERO}`,
                        { Count: 1, Page: pageTitle, DocumentId: taxReturn.id,
                            CustomEventType: DocumentEventLog.Name,
                            DocumentGuid: taxReturn.documentGuid 
                        }
                    );
                })
        }
    }

    sendForReview = (selectedUser: number) => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);
        let userIndex: number = this.props.users.findIndex(user => user.id === selectedUser);
        taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
        taxReturn.assignTo = selectedUser;
        taxReturn.assignedUser = {
            userId: this.props.users[userIndex].id,
            firstName: this.props.users[userIndex].firstName,
            lastName: this.props.users[userIndex].lastName,
            email: this.props.users[userIndex].emailAddress
        } as IUserBaseModel;
        taxReturn.isModified = true;
        taxReturn.lockedBy = 0;

        if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
            this.setState({ isBusy: true, generateTaxPayer: false },
                () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder) {
                        this.props.updateDocumentGroupOrder(taxReturn.engagementType, taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder);
                    }
                    this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(taxReturn), this.getSubDocuments(), this.state.isK1Replaced, this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn), processReturnActionEndPoints.sendForReviewAsync, this.callback);

                    logClientEvent(
                        `${DocumentEventLog.SendExtensionForReview}`,
                        { Count: 1, Page: pageTitle, DocumentId: taxReturn.id,
                            CustomEventType: DocumentEventLog.Name,
                            DocumentGuid: taxReturn.documentGuid 
                        }
                    );
                })
        }
    }

    approveForDelivery = (selectedUser: number) => {
        if (selectedUser === 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.SelectApprover, "Error");
        }
        else {
            let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);
            let userIndex: number = this.props.users.findIndex(user => user.id === selectedUser);
            taxReturn.documentStatus = TaxDocument.DocumentStatus.APPROVEDFORDELIVERY;
            taxReturn.assignTo = selectedUser;
            taxReturn.assignedUser = {
                userId: this.props.users[userIndex].id,
                firstName: this.props.users[userIndex].firstName,
                lastName: this.props.users[userIndex].lastName,
                email: this.props.users[userIndex].emailAddress
            } as IUserBaseModel;
            taxReturn.isModified = true;
            taxReturn.lockedBy = 0;

            if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false },
                    () => {
                        document.body.click();
                        if (this.state.isSaveAsDefaultOrder) {
                            this.props.updateDocumentGroupOrder(taxReturn.engagementType, taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder);
                        }
                        this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(taxReturn), this.getSubDocuments(), this.state.isK1Replaced, this.state.isK1Restored,
                            this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn), processReturnActionEndPoints.approveForDeliveryAsync, this.callback);

                        logClientEvent(
                            `${DocumentEventLog.SendExtensionApproveForDelivery}`,
                            {
                                Count: 1, Page: pageTitle, DocumentId: taxReturn.id,
                                CustomEventType: DocumentEventLog.Name,
                                DocumentGuid: taxReturn.documentGuid
                            }
                        );
                    })
            }
        }
    }

    deliverToClient = () => {
        let model = this.props.taxDocuments[this.props.docId].taxReturn;

        let userRolesArray = this.props.userRoles.split(',')

        const permission = userRolesArray.find(u => u == 'Admin' || u == 'Partner') ?? '';

        if (!this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary) {
            //Only Partner and Staff can deliver if allowAnyUsertoSendDelivary is not set
            if (permission.length === 0) {
                VenusNotifier.Warning("You don't have enough permission to send return for delivery.", null);
                return;
            }
        }

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            model.lockedBy = 0;
            this.setState({ isBusy: true, generateTaxPayer: false },
                () => {
                    document.body.click();
                    model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                    model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.ElectronicFiled;
                    this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(model), this.getSubDocuments(),
                        this.state.isK1Replaced, this.state.isK1Restored, this.state.initialMFJValue !== TaxDocument.isMutual(model),
                        processReturnActionEndPoints.deliverAsync, this.callback);

                    logClientEvent(
                        `${DocumentEventLog.DeliverProcessExtensionToClient}`,
                        {
                            Count: 1, Page: pageTitle, DocumentId: model.id,
                            CustomEventType: DocumentEventLog.Name,
                            DocumentGuid: model.documentGuid
                        }
                    );
                })
        }
    }

    deliverWithTaxcaddy = (taxCaddyLookupResult: TaxDocument.TaxCaddyLookupResult) => {
        let model = this.props.taxDocuments[this.props.docId].taxReturn;
        let userRolesArray = this.props.userRoles.split(',')

        const permission = userRolesArray.find(u => u == 'Admin' || u == 'Partner') ?? '';

        if (!this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary) {
            //Only Partner and Staff can deliver if allowAnyUsertoSendDelivary is not set
            if (permission.length === 0) {
                VenusNotifier.Warning("You don't have enough permission to send return for delivery.", null);
                return;
            }
        }

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (taxCaddyLookupResult.isTaxCaddyLookupAccepted) {
            taxCaddyLookupResult.isTaxCaddyDelivery = true;
            taxCaddyLookupResult.isTaxCaddyLookupAccepted = true;
            taxCaddyLookupResult.taxpayerUniqueId = this.props.taxDocuments[this.props.docId].taxReturn.taxCaddyLookupResultModel.resultObjectModel.taxPayerUniqueId;
            taxCaddyLookupResult.taxCaddyTransactionEventdata = this.props.taxDocuments[this.props.docId].taxReturn.taxCaddyLookupResultModel.resultObjectModel;
            if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
                model.lockedBy = 0;
                this.setState({ isBusy: true, generateTaxPayer: false },
                    () => {
                        document.body.click();
                        if (this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy) {
                            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = true;
                        }
                        else {
                            this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                        }
                        this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.deliveryMode = DeliveryMode.ElectronicFiled;

                        this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(model), this.getSubDocuments(), this.state.isK1Replaced,
                            this.state.isK1Restored, this.state.initialMFJValue !== TaxDocument.isMutual(model),
                            processReturnActionEndPoints.deliverAsync, this.callback, taxCaddyLookupResult);

                        logClientEvent(
                            `${DocumentEventLog.DeliverExtesionWithTaxCaddy}`,
                            {
                                Count: 1, Page: pageTitle, DocumentId: this.props.docId,
                                CustomEventType: DocumentEventLog.Name,
                                DocumentGuid: model.documentGuid
                            }
                        );
                    })
            }
        }
        else {
            if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false },
                    () => {
                        document.body.click();
                        this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                        this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(this.props.taxDocuments[this.props.docId].taxReturn), this.getSubDocuments(), this.state.isK1Replaced, this.state.isK1Restored,
                            this.state.initialMFJValue !== TaxDocument.isMutual(model), processReturnActionEndPoints.deliverAsync, this.callback);
                    })
            }
        }
    }

    generateTaxPayerView = (clientType: ClientTypes) => {
        this.setState({ disabledTaxpayerPreview: true });
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);

        if(!Helper.ValidateTaxPayerPreviewOption(taxReturn))
        {
            this.setState({ disabledTaxpayerPreview: false });
            return;
        }
       
        taxReturn.isModified = true;
        this.setState({ generateTaxPayer: true }, () => {
            this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(taxReturn), this.getSubDocuments(), this.state.isK1Replaced, this.state.isK1Restored,
                this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn), processReturnActionEndPoints.generateTaxpayerViewAsync, this.callback, undefined, clientType);

            logClientEvent(
                `${DocumentEventLog.SaveProcessReturnInfo} To ${DocumentEventLog.GenerateTaxpayerView}`,
                {
                    Count: 1, Page: pageTitle, DocumentId: taxReturn.id,
                    CustomEventType: DocumentEventLog.Name,
                    DocumentGuid: taxReturn.documentGuid
                }
            );
        })
    }

    //setPageBusy = (isBusy: boolean, ExtensionProcessReturnTabs: ExtensionProcessReturnTabs) => {
    //    switch (ExtensionProcessReturnTabs) {
    //        case ExtensionProcessReturnTabs.Attachments:
    //            {
    //                if (isBusy) { this.setState({ disabledTaxpayerPreview: true }); }
    //                else { this.setState({ disabledTaxpayerPreview: false }); }
    //            }
    //    }
    //}

    onPrintForPaperDelivery = () => {
        let model = this.props.taxDocuments[this.props.docId].taxReturn;

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }


        //if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
        //    VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
        //    return;
        //}

        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            model.lockedBy = 0;
            this.setState({ isBusy: true, generateTaxPayer: false },
                () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder) {
                        this.props.updateDocumentGroupOrder(model.engagementType, model.documentSettings.deliverySettings.paperReturnSettings.documentOrder);
                    }
                    model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                    model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                    this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(model), this.getSubDocuments(),
                        this.state.isK1Replaced, this.state.isK1Restored, this.state.initialMFJValue !== TaxDocument.isMutual(model),
                        processReturnActionEndPoints.deliverAsync, this.callback);

                    logClientEvent(
                        `${DocumentEventLog.SendExtensionForPaperDelivery}`,
                        {
                            Count: 1, Page: pageTitle, DocumentId: model.id,
                            CustomEventType: DocumentEventLog.Name,
                            DocumentGuid: model.documentGuid
                        }
                    );
                })
        }

    }
    /////////////////////////////////////////Finish Modal Popup End/////////////////////////////////////////////////

    private getSubDocuments() {
        let documentFacade: IPdfDocumentFacade = (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
        let subDocuments: ISubDocument[] = [];
        if (typeof (documentFacade) !== 'undefined') {
            subDocuments = documentFacade.getSubDocuments();
        }
        return subDocuments;
    }

    private callback = (data?: any) => {
        if (this.state.generateTaxPayer) {
            Helper.openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', Constants.PreviewConstants.Scope.ClientPreview, true);
            //Set generateTaxPayer false, otherwise it will open empty tab in other scenario's callback
            this.setState({ generateTaxPayer: false, disabledTaxpayerPreview: false, isK1Replaced: false, verifyK1Replaced: false });
        }
        else {
            this.onClose(this.props.taxDocuments[this.props.docId].taxReturn.id, true);
        }

        //Update store on any save operation in process return window 
        this.props.requestTaxDocument(this.props.taxDocuments[this.props.docId].taxReturn.id, true);
        this.props.loadPdf(this.props.taxDocuments[this.props.docId].taxReturn, true);
    }

    private onClose = (taxReturnID: number, fromCallback: boolean = false) => {
        if (!fromCallback) {
            this.resetTaxReturnLockStatus(this.props.taxDocuments[taxReturnID].taxReturn);
        }
        this.props.onCancel(taxReturnID);
    }

    private onModalClose = (taxReturnID: number) => {
        this.resetTaxReturnLockStatus(this.state.processingTaxReturn || this.props.taxDocuments[taxReturnID].taxReturn);
        this.props.onCancel(taxReturnID);
    }

  
    private onSaveTaxReturn = () => {
  
        this.setState({
            refreshTaxReturn: true,
            selectedOption: { save: true, finish: false }
        });
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);
        if (Helper.validateClientInfo(taxReturn) && validateRefudInfo(taxReturn, this.props.authorities) && validateRetentionPeriod(taxReturn) && Helper.validateDeliveryOption(taxReturn) && Helper.validateDocumentLocation(taxReturn)) {
            this.assignReturnOnOpen(taxReturn);
            if (taxReturn.documentStatus !== TaxDocument.DocumentStatus.REVIEW) {
                taxReturn.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
            }
            taxReturn.lockedBy = 0;
            taxReturn.isModified = true;
            if (!this.state.isBusy) {
                if(this.isUserHasAccessToDocumentLocation())
                {
                    this.setState({ isBusy: true, generateTaxPayer: false },
                        () => {
                            compareClientInfo(
                                getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo),
                                this.editClientInfoResourceId,
                                this.setClientInfoComparisonResult,
                                this.clientManagementCancel,
                                this.newClientCallback,
                                this.setClientBasicInfoId
                            );
                        })
                }
                else
                {
                    this.setState({ showLocationMismatchActionModel: true});
                }
            }

        }
    }

    private removeEmptyFormGroups = (taxReturn: TaxDocument.ITaxReturn): TaxDocument.ITaxReturn => {
        taxReturn.formGroups = taxReturn.formGroups.filter(group => group.forms && group.forms.length > 0);
        return taxReturn;
    }


    private checkFilingFormsExists = (formGroups: TaxDocument.IGroup[]): boolean => {
        return formGroups.some(formGroup => (
            formGroup.group == DocumentGroups.EFile ||
            formGroup.group == DocumentGroups.Vouchers ||
            formGroup.group == DocumentGroups.TaxReturns
        ) && (formGroup.forms && formGroup.forms.length > 0));
    }

    private onFinishProcessing = () => {
        this.setState({
            refreshTaxReturn: true,
            selectedOption: { save: false, finish: true }
        });


        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId].taxReturn);

        if (this.state.duplicatePages && this.state.duplicatePages.length) {
            this.showDuplicateFormsErrorPopup(this.state.duplicatePages, taxReturn.formGroups);
            return;
        }

        if (!this.checkFilingFormsExists(taxReturn.formGroups)) {
            VenusNotifier.Warning(Constants.ProcessReturnConstants.NoFilingFormsWarning, "");
            return;
        }

        let isValid = validateTaxReturn(taxReturn, this.props.userSettings.settings.useSignatureStamp, this.props.company.taxingAuthorities);
        let e1040: any = TaxDocument.EngagementType[TaxDocument.EngagementType.E1040]
        let isValidFormGroup = validateFormGroup(taxReturn);
        if (isValid && !isValidFormGroup) {
            bootbox.confirm({
                title: '',
                message: Constants.FinishProcessReturn.HighlightForm.NoSignPlaced,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> No',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Yes',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        compareClientInfo(
                            getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo),
                            this.editClientInfoResourceId,
                            this.setClientInfoComparisonResult,
                            this.clientManagementCancel,
                            this.newClientCallback,
                            this.setClientBasicInfoId);
                    }
                }
            });
        }
        else {
            if(isValid) {
                if(this.isUserHasAccessToDocumentLocation())
                {        
                    compareClientInfo(
                            getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo),
                        this.editClientInfoResourceId,
                            this.setClientInfoComparisonResult,
                            this.clientManagementCancel,
                            this.newClientCallback,
                            this.setClientBasicInfoId);
                }
                else
                {
                    this.setState({showLocationMismatchActionModel:true});
                }
            }

            //if (taxReturn.engagementType === e1040 && this.props.companySettings.reportingChannelSettings.filter(r => r.reportingEngine === ReportingEngineType.TaxCaddy && r.enabled == true).length > 0) {
            //    if (TaxDocument.isIndividual(this.props.taxDocuments[this.props.docId].taxReturn) || TaxDocument.isMutual(this.props.taxDocuments[this.props.docId].taxReturn)) {
            //        let taxReturn = this.props.taxDocuments[this.props.docId].taxReturn as TaxDocument.IIndividualTaxReturn;
            //        this.props.getTaxCaddyLookupDetails(taxReturn.taxpayer.name, taxReturn.taxpayer.email,
            //            taxReturn.taxpayer.ssn ? taxReturn.taxpayer.ssn.replace(/[^0-9]/g, "").trim() : "",
            //            taxReturn.taxYear, taxReturn.clientId, taxReturn.id);
            //    }
            //}
        }
    }

    private assignReturnOnOpen = (taxReturn: TaxDocument.ITaxReturn) => {
        if (taxReturn.assignTo == 0 || taxReturn.assignTo == undefined) {
            let userIndex: number = this.props.users.findIndex(user => user.id === this.props.userBasicProfile.userId!);
            taxReturn.assignTo = this.props.userBasicProfile.userId!
            taxReturn.assignedUser = {
                userId: this.props.users[userIndex].id,
                firstName: this.props.users[userIndex].firstName,
                lastName: this.props.users[userIndex].lastName,
                email: this.props.users[userIndex].emailAddress
            } as IUserBaseModel;
        }
    }

    private deletePages = (pages: number[]) => {
        let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        let deleted = Helper.removeForms(clone, pages);

        let deletedFormGroupIndex = new ClientInfoView(clone).getFormIndex(TaxDocument.DocumentGroups.Deleted);


        if (clone.formGroups[deletedFormGroupIndex]) {
            clone.formGroups[deletedFormGroupIndex].forms = clone.formGroups[deletedFormGroupIndex].forms.concat(deleted);
        } else {
            clone.formGroups.push({ group: TaxDocument.DocumentGroups.Deleted, forms: deleted });
        }

        this.updateTaxDocument(clone);
    }

    private deleteRefundOrPayment = (pages: number[]) => {
        let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        let deletedForms = Helper.removeForms(clone, pages);

        let tmpForms: TaxDocument.IFormBase[] = [];
        deletedForms.map(i => {
            tmpForms.push({
                bookmark: i.bookmark,
                formName: i.formName,
                pageNo: i.pageNo,
                formType: TaxDocument.TaxFormType.TaxReturn,
                id: 0
            });
        });

        let formGroupIndex = new ClientInfoView(clone).getFormIndex(TaxDocument.DocumentGroups.TaxReturns);
        if (clone.formGroups[formGroupIndex]) {
            clone.formGroups[formGroupIndex].forms = clone.formGroups[formGroupIndex].forms.concat(tmpForms);
        } else {
            clone.formGroups.push({ group: TaxDocument.DocumentGroups.TaxReturns, forms: tmpForms });
        }

        this.updateTaxDocument(clone);
    }

    //Undo/Redo section
    //-------------------------------
    private undoState: TaxDocument.ITaxReturn[];
    private undoTop: number;

    private updateTaxDocument = (model: TaxDocument.ITaxReturn) => {
        this.undoTop++;
        this.undoState[this.undoTop] = model;
        //Trim off the tail if any
        this.undoState = this.undoState.splice(0, this.undoTop + 1);
        this.props.updateTaxDocument(this.undoState[this.undoTop]);
    }

    private removeSignatureControlEFieForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    //private removeSignatureControlTaxReturnForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
    //    let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
    //    const taxReturnInfo = new TaxReturnInfoView(taxReturn);
    //    taxReturnInfo.removeSignatureControl(control, pageNo);
    //    this.updateTaxDocument(taxReturn);
    //}

    //private removeSignatureControlInvoiceForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
    //    let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
    //    const invoiceInfo = new InvoiceInfoView(taxReturn);
    //    invoiceInfo.removeSignatureControl(control, pageNo);
    //    this.updateTaxDocument(taxReturn);
    //}

    private removeSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    private addSignatureControlEFieForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    private addSignatureControlTaxReturnForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const taxReturnInfo = new TaxReturnInfoView(taxReturn);
        taxReturnInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    //private addSignatureControlInvoiceForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
    //    let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
    //    const invoiceInfo = new InvoiceInfoView(taxReturn);
    //    invoiceInfo.addSignatureControl(control, pageNo);
    //    this.updateTaxDocument(taxReturn);
    //}

    private addSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    private updateEROSignatureStamp = (userId: number): void => {
        const taxDocument = this.props.taxDocuments[this.props.docId].taxReturn;
        if (taxDocument && taxDocument.documentSettings) {

            let taxReturn = Helper.cloneTaxReturn(taxDocument);

            if (taxReturn.documentSettings.documentSignatureSetting.signatureStampUser) {
                taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = userId;
            }
            else {
                taxReturn.documentSettings.documentSignatureSetting.signatureStampUser = {
                    userId: userId
                } as IUserBaseModel;
            }
            taxReturn.documentSettings.isModified = true;

            this.updateTaxDocument(taxReturn);
            logClientEvent(
                `${DocumentEventLog.ChangedEROSignature}`,
                {
                    Count: 1,
                    CustomEventType: DocumentEventLog.Name,
                    DocumentGuid: taxReturn.documentGuid,
                    EroSignerId: userId
                });
        }
    }

    private replaceSignatureControlEFieForm = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    private replaceSignatureControlTaxReturnForm = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const taxReturnInfo = new TaxReturnInfoView(taxReturn);
        taxReturnInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    //private replaceSignatureControlInvoiceForm = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number) => {
    //    let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
    //    const invoiceInfo = new InvoiceInfoView(taxReturn);
    //    invoiceInfo.replaceSignatureControl(oldControl, newControl, pageNo);
    //    this.updateTaxDocument(taxReturn);
    //}

    private replaceSignatureControlTransmittalForm = (oldControl: TaxDocument.ISignatureControl, newControl: TaxDocument.ISignatureControl, pageNo: number) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    }

    private setAuthorityOptions() {
        let authoritiesOptions: TaxDocument.SelectOptions[] = [];
        this.props.authorities.map((x, i) => {
            authoritiesOptions.push({ value: x.Id.toString(), label: x.AuthorityName.toString() });
        });
        this.setState({ authoritiesOptions: authoritiesOptions });
    }

    //private shareHolderOption() {
    //    let shareHolderOptions: TaxDocument.SelectOptions[] = [];
    //    let taxDocument = this.props.taxDocuments[this.props.docId].taxReturn;
    //    taxDocument.formGroups.filter(x => x.group === Number(TaxDocument.DocumentGroups.K1)).map((x: any) => {
    //        shareHolderOptions.push({ value: x.shareHolder.id, label: x.shareHolder.name });
    //    });
    //    this.setState({ shareHolderOptions: shareHolderOptions });
    //}


    //private validateInvoice = (model: TaxDocument.ITaxReturn): boolean => {
    //    if (this.props.companySettings.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice)
    //        return true;
    //    else {
    //        let invoice = model.formGroups.find(x => x.group == DocumentGroups.Invoice);
    //        return (invoice != undefined && invoice != null && invoice.forms != null && invoice.forms.length > 0);
    //    }
    //}

    onVoucherDataUpdate = (propertyType: TaxDocument.VoucherProperty, value: any, pageNumber: number) => {

        let vouchers = this.state.matchedForms.slice() as TaxDocument.IVoucher[];
        vouchers.forEach((tempVoucher) => {
            if (tempVoucher.pageNo[0] == pageNumber) {
                let voucher = tempVoucher;
                switch (propertyType) {
                    case TaxDocument.VoucherProperty.authorityID:
                        {
                            voucher.authorityID = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.voucherTypes:
                        {
                            voucher.voucherNo = Number(value);
                            voucher.paymentType = TaxDocument.getVoucherTypes(value);
                        }
                        break;
                    case TaxDocument.VoucherProperty.dueDate:
                        {
                            if (value === "") {
                                voucher.dueDate = undefined;
                            } else {
                                voucher.dueDate = value;
                            }
                        }
                        break;
                    case TaxDocument.VoucherProperty.amount:
                        {
                            voucher.amount = isNaN(value) ? 0 : value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.formName:
                        {
                            voucher.formName = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.customType:
                        {
                            voucher.customType = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.customText:
                        {
                            voucher.customText = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.onlinePaymentUri:
                        {
                            voucher.onlinePaymentUri = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.isMasterPaymentUrl:
                        {
                            voucher.isMasterPaymentUrl = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.isFormNameUpdated:
                        {
                            voucher.isFormNameUpdated = value;
                        }
                        break;
                }

            }
        });
        this.setState({ matchedForms: vouchers }, () => { this.forceUpdate() });
    }

    private handleSignatureChange = (status: boolean, pageNumber: number) => {

        const updatedForms: TaxDocument.IGroupFormBase[] = [];
        for (const form of this.state.matchedForms) {
            if (form.pageNo[0] == pageNumber) {
                if (form.voucherEnable) {
                    const voucher = cloneDeep(form) as IVoucher;
                    voucher.voucherEnable = true;
                    voucher.vocherMode = TaxDocument.VoucherMode.Recognized;
                    voucher.formType = TaxDocument.TaxFormType.Voucher;
                    voucher.authorityID = voucher.authorityID;
                    voucher.dueDate = undefined;
                    voucher.amount = voucher.amount;
                    voucher.voucherNo = TaxDocument.VoucherNo.None;
                    voucher.paymentType = TaxDocument.VoucherTypes.None;
                    voucher.customType = TaxDocument.CustomType.None;
                    voucher.onlinePaymentUri = "";
                    voucher.customText = "";
                    voucher.authorityImage = "";
                    voucher.authorityName = "";
                    voucher.groupId = undefined;
                    voucher.isMasterPaymentUrl = false;
                    voucher.isFormNameUpdated = false;
                    voucher.signatureControls = [];
                    voucher.signatureEnable = Boolean(Number(status));
                    updatedForms.push(voucher);
                } else if (Boolean(Number(status))) {
                    const forms = cloneDeep(form) as IEFile;
                    const efile: IEFile = {
                        signatureEnable: Boolean(Number(status)),
                        voucherEnable: false,
                        authorityID: forms.authorityID,
                        bookmark: forms.bookmark,
                        formName: forms.formName,
                        formType: TaxDocument.TaxFormType.EFile,
                        signatureControls: [],
                        id: forms.id,
                        pageNo: forms.pageNo,
                    };
                    updatedForms.push(efile);
                } else {
                    const forms = cloneDeep(form) as TaxDocument.ITaxReturnGroup;
                    const taxreturn: TaxDocument.ITaxReturnGroup = {
                        signatureEnable: false,
                        voucherEnable: false,
                        bookmark: forms.bookmark,
                        formName: forms.formName,
                        formType: TaxDocument.TaxFormType.TaxReturn,
                        signatureControls: [],
                        id: forms.id,
                        pageNo: forms.pageNo,
                    };
                    updatedForms.push(taxreturn);
                }
            } else {
                updatedForms.push(form);
            }
        }

        this.setState({
            matchedForms: updatedForms,
        }, () => { this.forceUpdate(); });
    }

    private handleVoucherChange = (status: boolean, pageNumber: number) => {
        const updatedForms: TaxDocument.IGroupFormBase[] = [];
        for (const form of this.state.matchedForms) {
            if (form.pageNo[0] == pageNumber) {
                if (Boolean(Number(status))) {
                    const forms = cloneDeep(form) as IVoucher;

                    if (forms.pageNo[0] == pageNumber) {
                        forms.voucherEnable = Boolean(Number(status));
                        forms.vocherMode = TaxDocument.VoucherMode.Recognized;
                        forms.formType = TaxDocument.TaxFormType.Voucher;
                        forms.authorityID = forms.authorityID;
                        forms.dueDate = undefined;
                        forms.amount = forms.amount;
                        forms.voucherNo = TaxDocument.VoucherNo.None;
                        forms.paymentType = TaxDocument.VoucherTypes.None;
                        forms.customType = TaxDocument.CustomType.None;
                        forms.onlinePaymentUri = "";
                        forms.customText = "";
                        forms.authorityImage = "";
                        forms.authorityName = "";
                        forms.groupId = undefined;
                        forms.isMasterPaymentUrl = false;
                        forms.isFormNameUpdated = false;
                        forms.signatureControls = [];
                    }
                    updatedForms.push(forms);
                } else if (form.signatureEnable) {
                    const forms = cloneDeep(form) as IEFile;

                    const efile: IEFile = {
                        signatureEnable: true,
                        voucherEnable: false,
                        authorityID: forms.authorityID,
                        bookmark: forms.bookmark,
                        formName: forms.formName,
                        formType: TaxDocument.TaxFormType.EFile,
                        signatureControls: [],
                        id: forms.id,
                        pageNo: forms.pageNo,
                    };
                    updatedForms.push(efile);
                } else {
                    const forms = cloneDeep(form) as TaxDocument.ITaxReturnGroup;
                    const taxreturn: TaxDocument.ITaxReturnGroup = {
                        signatureEnable: false,
                        voucherEnable: false,
                        bookmark: forms.bookmark,
                        formName: forms.formName,
                        formType: TaxDocument.TaxFormType.TaxReturn,
                        signatureControls: [],
                        id: forms.id,
                        pageNo: forms.pageNo,
                    };
                    updatedForms.push(taxreturn);
                }
            } else {
                updatedForms.push(form);
            }
        }

        this.setState({
            matchedForms: updatedForms,
        }, () => { this.forceUpdate(); });
    }

    onEFileDataUpdate = (propertyType: TaxDocument.IEFileProperty, value: any, pageNumber: number) => {

        let eFiles = this.state.matchedForms.slice() as TaxDocument.IEFile[];
        eFiles.forEach(function (tempEFile) {
            if (tempEFile.pageNo[0] == pageNumber) {
                let eFile = tempEFile;
                switch (propertyType) {
                    case TaxDocument.IEFileProperty.authorityID:
                        {
                            eFile.authorityID = +value;
                        }
                        break;
                    case TaxDocument.IEFileProperty.formName:
                        {
                            eFile.formName = value;
                        }
                        break;
                }
            }
        });
        this.setState({ matchedForms: eFiles }, () => { this.forceUpdate() });
    }

    validateGroupData = (forms: TaxDocument.IGroupFormBase[]): boolean => {

        return forms.every(form => {
            const destinationGroup = form.voucherEnable ?
                DocumentGroups.Vouchers :
                form.signatureEnable ?
                    DocumentGroups.EFile :
                    DocumentGroups.TaxReturns;

            switch (destinationGroup) {
                case TaxDocument.DocumentGroups.Vouchers:
                    return this.validateVoucherData([form] as TaxDocument.IVoucher[]);
                case TaxDocument.DocumentGroups.EFile:
                    return this.validateEFileData([form] as TaxDocument.IEFile[]);
                default:
                    return true;
            }

        });
    }

    validateReGroupData = (destinationGroup: TaxDocument.DocumentGroups, forms: TaxDocument.IFormBase[]) => {
        switch (destinationGroup) {
            case TaxDocument.DocumentGroups.Vouchers:
                return this.validateVoucherData(forms as TaxDocument.IVoucher[]);
            case TaxDocument.DocumentGroups.EFile:
                return this.validateEFileData(forms as TaxDocument.IEFile[]);
            default:
                return true;
        }
    }

    //private reGroupTok1 = (pages: number[], forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {

    //    let k1formsData: TaxDocument.IK1ShareHolder[] = forms as TaxDocument.IK1ShareHolder[];
    //    let clone: TaxDocument.ITaxReturn = { ...this.props.taxDocuments[this.props.docId].taxReturn };
    //    Helper.removeForms(clone, pages);

    //    k1formsData.forEach(function (form) {
    //        let formsData = clone.formGroups.filter(x => x.group == TaxDocument.DocumentGroups.K1);
    //        formsData.map(function (formGroup: any) {
    //            if (formGroup.shareHolder.id == form.shareHolderId) {
    //                formGroup.forms = formGroup.forms.concat(form as TaxDocument.IFormBase);
    //            }
    //        });
    //    });
    //    this.updateTaxDocument(clone);
    //    this.onHideGroupFilesPreview();get
    //}

    private validateVoucherData(forms: TaxDocument.IVoucher[]) {
        let isValid = true;
        let voucherForms = forms as TaxDocument.IVoucher[];
        for (let i = 0; i < voucherForms.length; i++) {
            let vouchr = voucherForms[i];
            if (vouchr !== undefined) {
                const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
                let taxingAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == vouchr.authorityID)]
                if (!vouchr.authorityID || vouchr.authorityID === 0) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
                    isValid = false;
                    break;
                }
                if (!vouchr.voucherNo) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
                    isValid = false;
                    break;
                } else {
                    switch (Number(vouchr.voucherNo)) {
                        case TaxDocument.VoucherNo.PaymentVoucher:
                        case TaxDocument.VoucherNo.Q1:
                        case TaxDocument.VoucherNo.Q2:
                        case TaxDocument.VoucherNo.Q3:
                        case TaxDocument.VoucherNo.Q4:
                            {
                                break;
                            }
                        default:
                            {
                                VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
                                isValid = false;
                                break;
                            }
                    }

                }

                if (!vouchr.dueDate || !moment(vouchr.dueDate).isValid()) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.DueDateWarning, "");
                    isValid = false;
                    break;
                }
                if (Number.isNaN(vouchr.amount) || vouchr.amount === null) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.AmountEmptyWarning, "");
                    isValid = false;
                    break;
                }
                if (!vouchr.formName) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.FormNameWarning, "");
                    isValid = false;
                    break;
                }

                if ((vouchr.customType === undefined || vouchr.customType === TaxDocument.CustomType.None || vouchr.customType === TaxDocument.CustomType.Select) && vouchr.vocherMode !== TaxDocument.VoucherMode.Recognized) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.CustomTypeWarning, "");
                    isValid = false;
                    break;
                }
                if (vouchr.customType && vouchr.customType == TaxDocument.CustomType.OnlinePaymentRequired) {
                    if (!vouchr.onlinePaymentUri || vouchr.onlinePaymentUri === "") {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning, "");
                        isValid = false;
                        break;
                    }
                    else if (!ValidateWebsite(vouchr.onlinePaymentUri.trim())) {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning, "");
                        isValid = false;
                        break;
                    }
                }
                if (vouchr.customType && vouchr.customType === TaxDocument.CustomType.AddCustom) {
                    if (!vouchr.customText || vouchr.customText.trim() === "") {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.CustomTextWarning, "");
                        isValid = false;
                        break;
                    }
                }
                if (taxingAuthority && taxingAuthority.CustomAuthority && vouchr.customType === TaxDocument.CustomType.OnlinePaymentRequired) {
                    taxingAuthority.PrefixStateCode = false;
                    taxingAuthority.PaymentURL = vouchr.onlinePaymentUri;
                    this.props.saveTaxingAuthority(taxingAuthority, this.fetchCustomAuthority);
                }
            }
            if (isValid) {
                vouchr.vocherMode = TaxDocument.VoucherMode.Recognized;

            }
        }

        return isValid;

    }

    fetchCustomAuthority = () => {
        this.props.requestCustomAuthority(true);
    }
    private validateEFileData(eFileForms: TaxDocument.IEFile[]) {
        let isValid = true;
        for (let i = 0; i < eFileForms.length; i++) {
            var eFile = eFileForms[i];
            if (eFile !== undefined) {
                if (!eFile.authorityID || eFile.authorityID === 0) {
                    VenusNotifier.Warning("Please select Taxing Authority.", "");
                    isValid = false;
                    break;
                }
                if (eFile.formName == undefined || !eFile.formName.trim()) {
                    VenusNotifier.Warning("Please enter Formname.", "");
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }
    private validateKOneForms(k1Forms: TaxDocument.IK1ShareHolder[]) {
        let isValid = true;
        for (let i = 0; i < k1Forms.length; i++) {
            var k1 = k1Forms[i];
            if (k1 !== undefined) {
                if (!k1.shareHolderId || k1.shareHolderId === 0) {
                    VenusNotifier.Warning("Please select Shareholder.", "");
                    isValid = false;
                    break;
                }
                if (!k1.formName) {
                    VenusNotifier.Warning("Please enter Formname.", "");
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    private cleanUpFormGroup = (taxReturn: ITaxReturn, ...formGroup: TaxDocument.DocumentGroups[]) => {
        const formGroups = taxReturn.formGroups.filter(x => formGroup.some(g => g == x.group) && (!x.forms || x.forms.length == 0));
        taxReturn.formGroups = taxReturn.formGroups.filter(x => formGroups.indexOf(x) < 0);
    }

    private addAdditionalEsignDocumentData = (uploadedAdditionalEsignDocumentData: DocumentUploadData[]) => {
        this.setState({ uploadedAdditionalEsignDocumentData: uploadedAdditionalEsignDocumentData });
    }

    private updateAdditionalEsignDocumentData = (updatedAdditionalEsignDocumentData: DocumentUploadData) => {
        let tempUploadedData: DocumentUploadData[] = cloneDeep(this.state.uploadedAdditionalEsignDocumentData);

        tempUploadedData.forEach((item, index) => {
            if (item.name == updatedAdditionalEsignDocumentData.name) {
                item.documentType = updatedAdditionalEsignDocumentData.documentType;
                item.pdf = updatedAdditionalEsignDocumentData.pdf;
                item.isPDFloaded = updatedAdditionalEsignDocumentData.isPDFloaded;
            }
        });

        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId].taxReturn);
        taxReturn.additionalEsignDocuments.forEach((item, index) => {
            if (item.fileName == updatedAdditionalEsignDocumentData.name) {
                item.documentType = updatedAdditionalEsignDocumentData.documentType;
                item.isModified = true;
            }
        });

        this.setState({ uploadedAdditionalEsignDocumentData: tempUploadedData });
        this.props.updateTaxDocument(taxReturn);
    }
    private validateAttachmentStatus(attachments: TaxDocument.IAttachment[]) {

        if (attachments !== undefined && attachments.filter(x => x.progressBar && x.progressBar < 100).length > 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.AttachmentValidationMessage, "Error");
            return false;
        }
        return true;
    }

    private isUserHasAccessToDocumentLocation=()=>{
        const taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        if(taxReturn.location.locationId !== null)
        {
            const index=this.props.loggedInUserLocations.findIndex(x=>x===taxReturn.location.locationId);
            return index!=Helper.NO_INDEX;
        }
        return true;
    }

    private clientManagementSave = () => {
        if (this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            this.addNewClientInCM();
        } else {
            this.editClientInfoInCM();
        }
        this.setState({ showMismatchModal: false, popupInProgress: true, isBusy: true });
    }

    private clientManagementCancel = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false,
            isBusy: true
        });
        if (this.state.selectedOption.save) {
            this.saveProcessReturnInfo();
        } else if (this.state.selectedOption.finish) {
            this.finishProcessReturn();
        }
    }

    private editClientInfoInCM = () => {
        const clientInfo = getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo);
        
        logClientEvent(
            `${DocumentEventLog.UpdateClientInCM}`,
            {
                Count: 1,
                CustomEventType: DocumentEventLog.Name,
                DocumentId: this.props.docId
            });
        if (this.state.selectedOption.save) {
            editClientInfo(clientInfo, this.editClientInfoResourceId, this.saveProcessReturnInfo);
        } else if (this.state.selectedOption.finish) {
            editClientInfo(clientInfo, this.editClientInfoResourceId, this.finishProcessReturn);
        }
    }

    private addNewClientInCM = () => {
        const clientInfo = getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo);

        logClientEvent(
            `${DocumentEventLog.AddNewClientInCM}`,
            {
                Count: 1,
                CustomEventType: DocumentEventLog.Name,
                DocumentId: this.props.docId
            });
        if (this.state.selectedOption.save) {
            addNewClientInfo(clientInfo, this.editClientInfoResourceId, this.saveProcessReturnInfo);
        } else if (this.state.selectedOption.finish) {
            addNewClientInfo(clientInfo, this.editClientInfoResourceId, this.finishProcessReturn);
        }
    }

    private closeMismatchModal = () => {
        this.setState({ isBusy: false, showMismatchModal: false, popupInProgress: false });
    }
    private onCloseLocationMismatchActionModal = () => {
        this.setState({ isBusy: false, showLocationMismatchActionModel: false, popupInProgress: false });
    }

    private onContinueLocationMismatchActionModal=()=>{
        
        this.setState({showLocationMismatchActionModel: false},()=>{

            compareClientInfo(
                getNewClientInfo(this.props.taxDocuments[this.props.docId]?.taxReturn, this.state.clientBasicInfoId, this.state.clientInfo),
                this.editClientInfoResourceId,
                this.setClientInfoComparisonResult,
                this.clientManagementCancel,
                this.newClientCallback,
                this.setClientBasicInfoId
            );
        });
    }

    private saveProcessReturnInfo = () => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);

        if(!this.isUserHasAccessToDocumentLocation())
        {
            taxReturn.assignTo=undefined;
            taxReturn.documentStatus=TaxDocument.DocumentStatus.READY
        }
        taxReturn.locationId=taxReturn.location.locationId;
        this.props.saveProcessReturnInfo(this.removeEmptyFormGroups(taxReturn), this.getSubDocuments(), this.state.isK1Replaced, this.state.isK1Restored,
            this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn), undefined, this.callback);
        logClientEvent(
            `${DocumentEventLog.SaveProcessReturnInfo}`,
            {
                Count: 1,
                CustomEventType: DocumentEventLog.Name,
                DocumentGuid: taxReturn.documentGuid
            });
            this.setState({ isBusy: false });            
}

    private finishProcessReturn = () => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);

        let isValid = validateTaxReturn(taxReturn, this.props.userSettings.settings.useSignatureStamp, this.props.company.taxingAuthorities);
        isValid && this.setState((prevState) => { return { showClosePopover: !prevState.showClosePopover }; });
        this.setState({ isBusy: false });
    }

    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ showMismatchModal: true, popupInProgress: false });
        });
    }

    private newClientCallback = (clientInfo: TaxDocument.IClientInfoViewModel) => {
        this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
    }

    private setClientBasicInfoId = (id: number) => {
        this.setState({ clientBasicInfoId: id });
    }

};
//=============================================================================


