import React from  'react'
import { Modal, Button } from 'react-bootstrap';
import { IFormBase, IGroupFormBase, DocumentGroups, SelectOptions, VoucherProperty, IEFileProperty, K1Property, IEFile, IK1ShareHolder, IVoucher, ITaxReturn, ITaxingAuthority } from '../../common/TaxReturn';
import { PDFRegroupPageViewer } from './../ProcessReturnModal/PdfViewer/PDFRegroupPageViewer';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { Link } from 'react-router-dom';
import { RegroupToVoucher } from '../ProcessReturnModal/RightSidebar/RegroupToVoucher';
import { RegroupToEFile } from '../ProcessReturnModal/RightSidebar/RegroupToEFile';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { isReviewExtesnion, isStrictReviewExtesnion } from './SignatureControls/SignatureHelper';
import { isBusinessReturn } from '../../helper/HelperFunctions';

export enum RadioStatus {
    No,
    Yes
}

interface GroupFilesPreviewProps {
    voucherStore: IVoucherState;
    allTaxingAuthorities: ITaxingAuthority[];
    taxReturn: ITaxReturn;
    showGroupFilesPreview: boolean;
    onHideGroupFilesPreview: (event: any) => void;
    documentId: number;
    pages: number[];
    matchedForms: IGroupFormBase[];
    destinationGroup: DocumentGroups;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    renderTrigger: string;
    removePage: (event: any, pageNo: number) => void;
    onMove: (pages: number[], matchedForms: IGroupFormBase[], destinationGroup: DocumentGroups) => void;
    authoritiesOptions: SelectOptions[],
    shareHolderOptions: SelectOptions[],
    onVoucherDataUpdate: (propertyType: VoucherProperty, value: any, pageNumber: number) => void,
    onEFileDataUpdate: (propertyType: IEFileProperty, value: any, pageNumber: number) => void,
    handleSignatureChange: (status: boolean, pageNumber: number) => void;
    handleVoucherChange: (status: boolean, pageNumber: number) => void;
    extensionCompanySettings?: ExtensionCompanySettings;
}

export class GroupFilesPreview extends React.Component<GroupFilesPreviewProps, {}>{
    constructor(props: GroupFilesPreviewProps) {
        super(props);

    }

    renderEfilePage = (form: IFormBase) => {
        let eFile: IEFile = form as IEFile;
        return <div style={{ border: "1px solid black" }}>
            <RegroupToEFile
                eFile={eFile}
                authorities={this.props.authoritiesOptions}
                pageNo={form.pageNo[0]}
                onEFileDataUpdate={this.props.onEFileDataUpdate}
            />
        </div>
    }

    renderVoucherPage = (form: IFormBase) => {
        let voucher: IVoucher = form as IVoucher;
        return <div style={{ border: "1px solid black" }}>
            <RegroupToVoucher
                voucher={voucher}
                authorities={this.props.authoritiesOptions}
                pageNo={form.pageNo[0]}
                onVoucherDataUpdate={this.props.onVoucherDataUpdate}
                taxReturn={this.props.taxReturn}
                allTaxingAuthorities={this.props.allTaxingAuthorities}
                voucherStore={this.props.voucherStore}
            />
        </div>
    }

    render() {
        const groupName = this.props.destinationGroup === DocumentGroups.EFile ? "Filing Forms" : DocumentGroups[this.props.destinationGroup];
        let isBusinessRet = this.props.taxReturn ? isBusinessReturn(this.props.taxReturn) : false;
        return <div>
            <Modal dialogClassName="modal-GroupFilePreview" show={this.props.showGroupFilesPreview} onHide={(event: any) => { this.props.onHideGroupFilesPreview(event) }}>
                <Modal.Header closeButton data-test-auto="">
                    <Modal.Title>
                        <i className='fas fa-arrows-alt'></i>
                        <span style={{ paddingLeft: 6, paddingRight: 6 }}>Move to {groupName}</span>
                        <span className="badge">{this.props.pages.length}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.matchedForms.map((form, index) => {
                            return <div className="row">
                                <div className="col-sm-12 col-lg-12" style={{ borderBottom: "1px" }}>
                                    <div className="row font-size-14">
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-sm-10 col-lg-10">
                                                    <span>{form.formName}</span>
                                                </div>
                                                <div className="col-sm-2 col-lg-2 pull-right">
                                                    {
                                                        <Link to={'#'} style={{ color: "red" }} onClick={(event) => { this.props.removePage(event, form.pageNo[0]) }}>
                                                            <span><i className='fas fa-times'></i> De-Select</span>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal-GroupFilePreviewBody">
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-sm-6 col-lg-6">
                                                    <div style={{ width: "99%", border: "1px solid black" }}>
                                                        <PDFRegroupPageViewer
                                                            documentId={this.props.documentId}
                                                            pageNo={form.pageNo[0]}
                                                            pdfDocuments={this.props.pdfDocuments}
                                                            showText={false} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-6">
                                                    <div className="row padT15">
                                                        <div className="col-sm-7 col-lg-7">
                                                            <span style={{ fontSize: '12px' }} >  Does this form require taxpayer and/or spouse signatures?</span>
                                                        </div>
                                                        <div className="col-sm-5 col-lg-5">
                                                            <RadioButtonComponent
                                                                text="Yes"
                                                                id={`efile-sign-yes-${form.pageNo[0]}`}
                                                                checked={form.signatureEnable}
                                                                onChange={(event: any) => { this.props.handleSignatureChange(event.target.value, form.pageNo[0]) }}
                                                                disabled={this.props.extensionCompanySettings ? (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                                                                    isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)) : false}
                                                                value={RadioStatus.Yes}
                                                            />
                                                            <RadioButtonComponent
                                                                text="No"
                                                                id={`efile-sign-no-${form.pageNo[0]}`}
                                                                checked={!form.signatureEnable}
                                                                onChange={(event: any) => { this.props.handleSignatureChange(event.target.value, form.pageNo[0]) }}
                                                                disabled={this.props.extensionCompanySettings ? (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                                                                    isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)) : false}
                                                                value={RadioStatus.No}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row padT15">
                                                        <div className="col-sm-7 col-lg-7">
                                                            <span style={{ fontSize: '12px' }} >  Is this form a payment voucher?</span>
                                                        </div>
                                                        <div className="col-sm-5 col-lg-5">
                                                            <RadioButtonComponent
                                                                text="Yes"
                                                                id={`voucher-yes-${form.pageNo[0]}`}
                                                                checked={form.voucherEnable}
                                                                onChange={(event: any) => { this.props.handleVoucherChange(event.target.value, form.pageNo[0]) }}
                                                                disabled={false}
                                                                value={RadioStatus.Yes}
                                                            />
                                                            <RadioButtonComponent
                                                                text="No"
                                                                id={`voucher-no-${form.pageNo[0]}`}
                                                                checked={!form.voucherEnable}
                                                                onChange={(event: any) => { this.props.handleVoucherChange(event.target.value, form.pageNo[0]) }}
                                                                disabled={false}
                                                                value={RadioStatus.No}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        form.voucherEnable ?
                                                            <div className="row padT15">
                                                                <div className="col-sm-12 col-lg-12">
                                                                    {this.renderVoucherPage(form)}
                                                                </div>
                                                            </div>
                                                            :
                                                            form.signatureEnable ?
                                                                <div className="row padT15">
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        {this.renderEfilePage(form)}
                                                                    </div>
                                                                </div>
                                                                : null

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index != this.props.matchedForms.length - 1 &&
                                        <hr />
                                    }
                                </div>
                            </div>
                        })
                    }

                </Modal.Body>
                <Modal.Footer style={{ overflow: "hidden" }}>
                    <Button
                        variant="light"
                        onClick={(event: any) => { this.props.onHideGroupFilesPreview(event) }}
                    ><i className='fas fa-times'></i>Cancel</Button>
                    <Button
                        variant="info"
                        onClick={() => {
                            this.props.onMove(
                                this.props.pages,
                                this.props.matchedForms,
                                this.props.destinationGroup);
                        }}
                    ><i className='fas fa-check'></i>Move</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}