import React from  'react';

type GroupDeliveredReportsProps =
    {};
interface GroupDeliveredReportsState {
}

export class GroupDeliveredReports extends React.Component<{} & GroupDeliveredReportsProps, GroupDeliveredReportsState> {
    public render() {
        return <div>Group Deivery Report</div>
    }
}