import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from './../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { ControllerInfo, GroupedReturnsModel, initialGroupedReturnsModel, } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { GroupedExtensionsProcessStoreState, RecieveGroupControllerInfoAction, RecieveGroupControllerSaveAction, RequestGroupControllerInfoAction, RequestGroupControllerSaveAction, unloadedGroupedExtensionsProcessStoreState } from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { ITaxReturn } from '../../components/common/TaxReturn';
import * as Constants from '../../components/helper/Constants';
import { API_BASE_URL } from 'src/utils/contants';


type KnownAction = RequestGroupControllerInfoAction
    | RequestGroupControllerSaveAction
    | RecieveGroupControllerSaveAction 
    | RecieveGroupControllerInfoAction
    | NotificationAction;

export const actionCreators = {
    requestGroupLevelData: (groupId: number, force: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({
            type: actionTypes.REQUEST_GROUP_LEVEL_DATA,
            loading: true,
            groupId: groupId
        })

        if (force
            || !state.groupedExtensionsProcessStore[groupId]
            || !state.groupedExtensionsProcessStore[groupId].groupLevelReturnsData
            || !(state.groupedExtensionsProcessStore[groupId].groupLevelReturnsData.groupInfo.id === 0)) {
            const fetchTask = fetch(`${API_BASE_URL}api/GroupLevelProcessing/GetGroupLevelDataAsync/?groupId=` + groupId, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as GroupedReturnsModel)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUP_LEVEL_DATA,
                        groupId: groupId,
                        loading: false,
                        isModified: false,
                        groupControllerInfoModel: data
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        }
    },

    updateControllerInfoData: (data: ControllerInfo, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        dispatch({
            type: actionTypes.RECEIVE_GROUP_LEVEL_DATA,
            groupId: data.groupId,
            loading: false,
            isModified: true,
            groupControllerInfoModel: {
                ...state.groupedExtensionsProcessStore[data.groupId].groupLevelReturnsData,
            },
        });
        if (callback) {
            callback();
        }
    },
    saveGroupLevelReturnData: (groupReturnsModel: GroupedReturnsModel, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQUEST_GROUPLEVEL_SAVE,
            updating: true,
        });

        const state = getState();
        const fetchTask = fetch(`${API_BASE_URL}api/GroupLevelProcessing`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(groupReturnsModel)
        })
            .then(handleResponse)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT,
                    success: true,
                    updating: false,
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SaveandCloseProcessReturn.StatusMessage.Success,
                    statusType: StatusType.Success
                });
                callback && callback();
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT,
                    success: false,
                    updating: false,
                });
                callback && callback();
            })
        addTask(fetchTask);
    },

    requestGroupLevelTaxDocument: (id: number, callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/` + id + '/' + false, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<ITaxReturn>)
            .then(data => {
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {

            });
        addTask(fetchTask);
    },
}

export const reducer: Reducer<GroupedExtensionsProcessStoreState> = (state: GroupedExtensionsProcessStoreState = unloadedGroupedExtensionsProcessStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_GROUP_LEVEL_DATA:
            const groupLevelData: GroupedExtensionsProcessStoreState = { ...state };
            groupLevelData[action.groupId] = {
                error: false,
                isLoading: true,
                message: '',
                groupLevelReturnsData: initialGroupedReturnsModel,
            }
            return groupLevelData as GroupedExtensionsProcessStoreState
        case actionTypes.RECEIVE_GROUP_LEVEL_DATA:
            const newState: GroupedExtensionsProcessStoreState = { ...state }
            newState[action.groupId] = {
                error: false,
                isLoading: action.loading,
                message: '',
                groupLevelReturnsData: action.groupControllerInfoModel,
            };
            return newState;
        case actionTypes.REQUEST_GROUPLEVEL_SAVE:
            return {
                groupLevelReturnSaving: true
            }
        case actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT:
            return {
                ...unloadedGroupedExtensionsProcessStoreState,
                groupLevelReturnSaving: false
            }
    }
    return state || unloadedGroupedExtensionsProcessStoreState;
}