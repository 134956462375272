import React from  'react';
import { Button } from 'react-bootstrap';
import { UploadDocumentModal } from '../../common/UploadDocumentModal';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { UploadFabButton } from '../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from '../../navigation/profile/MySettings';
import { GroupAccess, GroupInfo } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { ITaxSoftwareSettings, TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { IDocumentAccess } from 'src/components/common/TaxReturn';

interface RecycleReturnsHeaders {
    pageTitle: string;
    onTaxYearSelect: (event: any) => void;
    selectedDocumentCount: number;
    taxYear: number;
    onPageReload: () => void;
    onDeleteDocument: (rowIndex: number) => void;
    extensionUsers: IUserModel[];
    partners: IUserModel[];
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    refreshDelay?: boolean;
    onRestoreDocument: (rowIndex: number) => void;
    isBulkSelectionEnabled: boolean;
    updateGroupInfoState: (reload: boolean) => void;
    groupInfo: GroupInfo[];
    groupAccess: GroupAccess[];
    defaultGroup: string;
    taxSoftwareData: ITaxSoftwareSettings;
    getTaxDocumentsAccessMaps: (ids: number[], callback?: (result: IDocumentAccess) => void ) => void;
}

interface RecycleReturnsHeadersState {
    show: boolean;
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class RecycleReturnsHeader extends React.Component<RecycleReturnsHeaders, RecycleReturnsHeadersState> {
    constructor(props: RecycleReturnsHeaders) {
        super(props);
        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.HandleClose = this.HandleClose.bind(this);
        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None
        };
    }

    private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    HandleClose() {
        this.setState({ show: false });
    }

    public render() {
        return <div>
            <div className="row marB10">
                <div className="col-md-8">
                    <h3 className="page-title">{this.props.pageTitle}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <Button
                        variant="default"
                        className='btn btn-white btnStyle'
                        disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                        onClick={(e: any) => this.props.onDeleteDocument(NO_INDEX)}
                        data-resource-id={RBACKeys.RecycleBin.deleteButton}
                        data-test-auto="B7924657-96E3-4965-8469-BBE05578F208">
                        <i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        variant="default"
                        className='btn btn-white btnStyle'
                        disabled={this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0}
                        data-test-auto="CF7B62E7-CD65-4081-8E2B-64A0C011F6DD"
                        data-resource-id={RBACKeys.RecycleBin.restoreButton}
                        onClick={(e: any) => this.props.onRestoreDocument(NO_INDEX)}>
                        <i className='fas fa-undo'></i> Restore
                    </Button>
                    <Button
                        onClick={this.props.onPageReload}
                        title="Refresh"
                        disabled={this.props.refreshDelay}
                        className="btn-white btn-only marR10 btn btn-default"
                        style={{ paddingLeft: '12px', marginLeft: '8px' }}
                        data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
                        <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                            style={{ padding: '4px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                        </span>
                    </Button>
                </div>
                <div className="col-md-4 txt-ar">
                    <UploadFabButton
                        companySettings={this.props.companySettings}
                        userDefaultSettings={this.props.userDefaultSettings}
                        onUploadReturnsClick={this.onUploadReturnsOpen}
                        pageId="RecycleBin"
                        taxSoftwareData={this.props.taxSoftwareData}
                    />
                </div>
            </div>
            <UploadDocumentModal
                onShow={this.state.show}
                onHide={this.HandleClose}
                extensionUsers={this.props.extensionUsers}
                partners={this.props.partners}
                taxSoftware={this.state.selectedTaxSoftware}
                selectedERO={this.props.selectedERO}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.props.onPageReload}
                defaultGroup={this.props.defaultGroup}
                groupInfo={this.props.groupInfo}
                groupAccess={this.props.groupAccess}
                updateGroupInfoState={this.props.updateGroupInfoState}
                returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                getTaxDocumentsAccessMaps={this.props.getTaxDocumentsAccessMaps}
            />
        </div >
    }
}
export default RecycleReturnsHeader;