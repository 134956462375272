import { ToastContainer, toast } from 'react-toastify';
import React from  'react';
import { NotificationState, StatusType } from "../../store/common/NotificationStore";
import { VenusNotifier } from './VenusNotifier';
import { getAutoLogout } from '../../oidcClient/helpers';

type NotificationProps =
{
    notification: NotificationState
}

export class Notification extends React.Component<NotificationProps, any> {

    componentWillReceiveProps(nextProps: NotificationProps) {
        if (nextProps.notification.type == StatusType.Success) {
            VenusNotifier.Success(nextProps.notification.message, null);
        }
        else if (nextProps.notification.type == StatusType.Error) {
            //supress all error when user permission updated and autologout enabled
            if (getAutoLogout() !== "1") {
                VenusNotifier.Error(nextProps.notification.message, null);
            }
        }
        else if (nextProps.notification.type == StatusType.Warning) {
            VenusNotifier.Warning(nextProps.notification.message, null);
        }
        else {
            VenusNotifier.Info(nextProps.notification.message, null);
        }
    }
    render() {
        return (<div />);
    }
}