import React from  'react';
import {
    Col, FormLabel, FormControl, FormGroup,/*, Panel*/
    Accordion,
    Card
} from 'react-bootstrap';
import Select from 'react-select';
import { ClientInfoTab, CompanySettingsConstants } from '../../../helper/Constants';
import { CheckIfStringHasValue, FilterSSN, GetCountryCode } from '../../../helper/HelperFunctions';
import { PhoneNumberComponent } from '../../../common/PhoneNumberComponent';
import { isDigit } from '../../../helper/Validations';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { ClientInfoProperty } from '../../ClientInfoEnum';
import { ITaxpayer } from '../../TaxClient';
import { ClientTypes, IPrefilledClientInfo } from '../../TaxReturn';
import { CustomDatePicker } from '../../controls/CustomDatePicker';
import { ClientInfoRightSidePanelType } from '../Tabs/TabClientInfo';
import { ICountryState } from '../../../../Core/ViewModels/Company/CompanyViewModel';
import { TaxIdentificationNumber } from '../../../common/controls/TaxIdentificationNumber';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import { isEqual } from 'lodash';

let dateMoment = require('moment');

export interface ITaxpayerProp {
    title: string,
    model: ITaxpayer,
    handleValueChange: (item: ClientInfoProperty, value: string) => void,
    states: ICountryState[];
    isAssignedToLoggedinUser?: boolean,
    expandTaxpayerPanel: boolean,
    expandSpousePanel: boolean,
    onPanelToggle: (panelType: ClientInfoRightSidePanelType) => void
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty) => void;
    getPrefilledClassName: (clientInfoProperty: ClientInfoProperty) => string;
}

export interface ITaxpayerState {
    name: string;
    email: string;
    dob: Date;
    address: string;
    city: string;
    state: string;
    zip: string;
    ssn: string;
    isDeceased: boolean;
    mobileNumber: string;
    countryCode: string;
}
export function prepareCountryStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: any) => {
        columnValues.push({
            value: state.id,
            label: state.name
        })
    });
    return columnValues;
}

export class TaxpayerSidebar extends React.Component<ITaxpayerProp, ITaxpayerState> {
    constructor(props: ITaxpayerProp) {
        super(props);
        this.state = {
            name: "",
            dob: new Date(),
            email: "",
            ssn: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            isDeceased: false,
            mobileNumber: "",
            countryCode: "+1"
        };
        if (props.model) {
            this.state = {
                name: props.model.name || "",
                dob: props.model.dob || new Date(),
                email: props.model.email || "",
                ssn: props.model.ssn || "",
                address: props.model.address || "",
                city: props.model.city || "",
                state: props.model.state || "",
                zip: props.model.zip || "",
                isDeceased: props.model.isDeceased || false,
                mobileNumber: props.model.mobileNumber || "",
                countryCode: props.model.countryCode || "+1"
            };
        }
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleChangeDoB = this.handleChangeDoB.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleBlurAddress = this.handleBlurAddress.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleBlurCity = this.handleBlurCity.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChangeZip = this.handleChangeZip.bind(this);
        this.handleBlurZip = this.handleBlurZip.bind(this);
        this.handleChangeSSN = this.handleChangeSSN.bind(this);
        this.onDeceasedChange = this.onDeceasedChange.bind(this);
    }

    componentWillReceiveProps(nextProps: ITaxpayerProp) {
        if (nextProps.model  && isEqual(this.props.prefilledClientInfo, nextProps.prefilledClientInfo)) {
            this.state = {
                name: nextProps.model.name,
                dob: nextProps.model.dob,
                email: nextProps.model.email,
                ssn: nextProps.model.ssn,
                address: nextProps.model.address,
                city: nextProps.model.city,
                state: nextProps.model.state,
                zip: nextProps.model.zip,
                isDeceased: nextProps.model.isDeceased,
                mobileNumber: nextProps.model.mobileNumber,
                countryCode: nextProps.model.countryCode,
            };
        }
    }

    handleResetPrefillInfo = (item: ClientInfoProperty) => {
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(item);
    }

    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
        this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseName : ClientInfoProperty.taxpayerName);
    }

    handleBlurName(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseName : ClientInfoProperty.taxpayerName, this.state.name);
    }

    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
        this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseEmail : ClientInfoProperty.taxpayerEmail);
    }

    handleBlurEmail(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseEmail : ClientInfoProperty.taxpayerEmail, this.state.email);
    }

    handleChangeDoB(event: any) {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        }
        else
        {
            this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseDateOfBirth : ClientInfoProperty.taxpayerDateOfBirth);
            this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseDateOfBirth : ClientInfoProperty.taxpayerDateOfBirth, event);
        }
    }

    handleChangeAddress(event: any) {
        this.setState({ address: event.target.value });
    }

    handleBlurAddress(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseAddress : ClientInfoProperty.taxpayerAddress, event.target.value);
    }

    handleChangeCity(event: any) {
        this.setState({ city: event.target.value });
    }

    handleBlurCity(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCity : ClientInfoProperty.taxpayerCity, event.target.value);
    }

    handleChangeState(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseState : ClientInfoProperty.taxpayerState, event.label);
    }

    handleChangeZip(event: any) {
        if (isDigit(event.target.value) == true || event.target.value.trim() == '')
            this.setState({ zip: event.target.value });
    }

    handleBlurZip(event: any) {
        if (isDigit(event.target.value) == true || event.target.value.trim() == '')
            this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseZip : ClientInfoProperty.taxpayerZip, event.target.value);
    }
    handleBlurMobileNumber = (event: any) => {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseMobileNumber : ClientInfoProperty.taxpayerMobileNumber, this.state.mobileNumber);
    }
    handleBlurCountryCode = (event: any) => {

        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCountryCode : ClientInfoProperty.taxpayerCountryCode, this.state.countryCode);
    }

    handleChangeSSN(value: string) {
        this.setState({ ssn: value });
        this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseSSN : ClientInfoProperty.taxpayerSSN);
    }

    handleBlurSSN = (value: string) => this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseSSN : ClientInfoProperty.taxpayerSSN, value);

    onDeceasedChange(event: any) {
        this.props.handleValueChange(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseDeceased : ClientInfoProperty.taxpayerDeceased, event.target.checked);
    }

    onChangeCountryCode = (event: any) => {
        let value = event ? event.value : "";
        this.setState({
            countryCode: value
        });
        this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCountryCode : ClientInfoProperty.taxpayerCountryCode);
    }

    onChangeMobileNumber = (value: any) => {
        this.setState({
            mobileNumber: value
        });
        this.handleResetPrefillInfo(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseMobileNumber : ClientInfoProperty.taxpayerMobileNumber);
    }

    

    public render() {
        let panelType = this.props.title == ClientTypes.Taxpayer ? this.props.expandTaxpayerPanel : this.props.expandSpousePanel
        let icon = panelType ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
        return <div>
            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                        <Card.Title as="h4"
                            onClick={() => {
                                this.props.onPanelToggle(this.props.title == ClientTypes.Taxpayer ? ClientInfoRightSidePanelType.TaxperInformationPanel :
                                    ClientInfoRightSidePanelType.SpouseInformationPanel)
                            }}>
                            <i className={icon}></i> {this.props.title}
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: "10px" }}>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-3" >Name</label>
                                <Col sm={9}>
                                    <FormControl
                                        className = {this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseName : ClientInfoProperty.taxpayerName)}
                                        type="text"
                                        value={this.state.name}
                                        placeholder="Enter Name"
                                        onChange={this.handleChangeName}
                                        onBlur={this.handleBlurName}
                                        data-test-auto="A8FE0A0F-49BC-4D41-AA55-D6E9DC47EBE1"
                                        disabled={!this.props.isAssignedToLoggedinUser} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 control-label col-sm-3">SSN</label>
                                <Col sm={9} className={"zeroborderRadius"}>
                                    <TaxIdentificationNumber
                                        className={this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseSSN : ClientInfoProperty.taxpayerSSN)}
                                        value={this.state.ssn ? FilterSSN(this.state.ssn) : ""}
                                        onChange={this.handleChangeSSN}
                                        onBlur={this.handleBlurSSN}
                                        placeholder={"Enter SSN"}
                                        mask='000-00-0000'
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3"> Address</label>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.address}
                                        placeholder="Enter Address"
                                        onChange={this.handleChangeAddress}
                                        onBlur={this.handleBlurAddress}
                                        data-test-auto="617DCDA2-B8C3-45D1-BFDE-7106C7911C38"
                                        disabled={!this.props.isAssignedToLoggedinUser} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3" >City</label>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.city}
                                        placeholder="Enter City"
                                        onChange={this.handleChangeCity}
                                        onBlur={this.handleBlurCity}
                                        data-test-auto="9E82F9BD-C071-4DB7-97C0-193D3010B07F"
                                        disabled={!this.props.isAssignedToLoggedinUser} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3">State</label>
                                <Col sm={9}>
                                    <Select
                                        name="form-field-state"
                                        value={this.props.model && CheckIfStringHasValue(this.props.model.state) ?
                                            this.props.states[this.props.states.findIndex(x => x.name.toLowerCase() == this.props.model.state.toLowerCase())].id : ""}
                                        onChange={this.handleChangeState}
                                        options={prepareCountryStateDropdown(this.props.states)}
                                        data-test-auto="EB1E7C0C-1427-444A-A3B6-4815820DA04C"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                        clearable={false}
                                    >
                                    </Select>

                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3">Zip</label>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.zip}
                                        placeholder="Enter Zip"
                                        onChange={this.handleChangeZip}
                                        onBlur={this.handleBlurZip}
                                        maxLength={5}
                                        data-test-auto="65A21CBF-51A4-4015-A106-F720025EC1B3"
                                        disabled={!this.props.isAssignedToLoggedinUser} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3">DOB</label>
                                <Col sm={9} className={'zindexzero'}>
                                    {
                                        <CustomDatePicker value={this.props.model.dob}
                                            customClassName={this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseDateOfBirth : ClientInfoProperty.taxpayerDateOfBirth)}
                                            onChange={this.handleChangeDoB}
                                            maxDate={new Date()}
                                        />
                                    }
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left col-sm-3" style={{ paddingTop: "8px", paddingLeft: "15px", paddingBottom: "0px", paddingRight: "0px" }}>
                                    <Col sm={12} className="padL00 padR00">
                                        Mobile <i
                                            className="help-icon fas fa-question-circle padR00 fontS15"
                                            data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}></i>
                                    </Col>
                                </label>
                                <Col sm={9}>
                                    <Col sm={6} className="padL00 padR00" style={{ float: "left" }}>
                                        <Select
                                            name="form-field-country-code"
                                            className={this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCountryCode : ClientInfoProperty.taxpayerCountryCode)}
                                            value={this.state.countryCode}
                                            onChange={this.onChangeCountryCode}
                                            options={GetCountryCode()}
                                            onBlur={this.handleBlurCountryCode}
                                            data-test-auto="EB1E7C0C-1427-444A-A3B6-4815820DA04C"
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                            clearable={true}
                                            searchable={true}

                                        >
                                        </Select>
                                    </Col>
                                    <Col sm={6} className="pad00 padL00 padR00" style={{ float: "right" }}>
                                        <PhoneNumberComponent
                                            phoneNumber={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                            className={this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseMobileNumber : ClientInfoProperty.taxpayerMobileNumber)}
                                            handleChangePhoneNumber={this.onChangeMobileNumber}
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                            onBlur={this.handleBlurMobileNumber}
                                        >
                                        </PhoneNumberComponent>
                                    </Col>


                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-3" >Email</label>
                                <Col sm={9}>
                                    <FormControl
                                        type="email"
                                        className={this.props.getPrefilledClassName(this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseEmail : ClientInfoProperty.taxpayerEmail)}
                                        placeholder="Enter Email"
                                        value={this.state.email}
                                        onChange={this.handleChangeEmail}
                                        onBlur={this.handleBlurEmail}
                                        data-test-auto="B23A8320-C234-4F91-8F37-8C431E2FB41A"
                                        disabled={!this.props.isAssignedToLoggedinUser} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 div-flex col-sm-3">Deceased</label>
                                <Col sm={9} className={"marTNeg03"}>
                                    <CheckBoxComponent
                                        id={this.props.title == ClientTypes.Spouse ? "FDE1D4B7-FO08-45E9-9A3D-E62A99D2493D" : "FDE1D4B7-FO08-45E9-9A3D-E62A99D249YT"}
                                        text={""}
                                        checked={this.state.isDeceased}
                                        datatestAuto={"1189D6C6-93FB-4846-8D37-859AC9D833E5"}
                                        onChange={this.onDeceasedChange}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    }
}
export default TaxpayerSidebar;