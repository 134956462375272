import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { RequestInUseExtensionsPagesAction, ReceiveInUseExtensionsPagesAction } from './KnownTypes'
import { IInUseTaxReturns } from '../../components/common/InUseTaxReturns';
import { InUseExtensionsState, InUseExtensionsTableModel, IInUseExtensionsDictionary } from './InUseExtensionsState';
import { ReceiveTaxDocumentAction, MakeAvailableTaxDocumentAction } from '../common/TaxDocumentStore';
import { API_BASE_URL } from 'src/utils/contants';

type KnownAction = RequestInUseExtensionsPagesAction
    | ReceiveInUseExtensionsPagesAction | MakeAvailableTaxDocumentAction | ReceiveTaxDocumentAction ;

type DispatchAction = KnownAction;

export const actionCreators = {
    requestInUseExtensions: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().inUseExtensionsPages[query]) {
            const fetchTask = fetch(`${API_BASE_URL}api/Reports/InUseExtensions/GetInUseExtensions` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<InUseExtensionsTableModel>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_INUSE_EXTENSIONS_PAGES, query: query, table: data, totalRowCount: data.count });
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_INUSE_EXTENSIONS_PAGES, query: query });
        }
    }
};


const unloadedState: InUseExtensionsState = {
    InUseExtensionTableModel: {},
    query: '?',
    loading: false,
    totalRowCount: 0
} as InUseExtensionsState;

const unloadedInUseTaxDocument: IInUseTaxReturns = {
    
} as IInUseTaxReturns;

export const reducer: Reducer<IInUseExtensionsDictionary> = (state: IInUseExtensionsDictionary = {}, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_INUSE_EXTENSIONS_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as InUseExtensionsState;
                added.loading = true;
                added.query = action.query;
                let item = {} as IInUseExtensionsDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            } 
            break;
        case actionTypes.RECEIVE_INUSE_EXTENSIONS_PAGES:  
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as InUseExtensionsState;
                changed.loading = false;
                changed.InUseExtensionTableModel = action.table;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as IInUseExtensionsDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
            return clearTaxReturns();
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            return updateState(action.type, state, action);
        default:
    }
    return state || { "?": unloadedState };
};

function updateState(type: actionTypes, state: IInUseExtensionsDictionary, action: DispatchAction): IInUseExtensionsDictionary {
    let i: number = -1;
    let newState = {} as IInUseExtensionsDictionary;
    let oldDocument: IInUseTaxReturns = unloadedInUseTaxDocument;
    let document: IInUseTaxReturns = {} as IInUseTaxReturns;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IInUseTaxReturns;
            id = action.id;
            break;
    }

    for (var query in state) {
        i = -1;
        if (state[query].InUseExtensionTableModel.inUseExtensionModel) {
            state[query].InUseExtensionTableModel.inUseExtensionModel.forEach((value, index) => {
                if (value.id === id) {
                    i = index;
                    oldDocument = value;
                    return;
                }
            });
        }
        if (i !== -1) {
            let InUseTaxDocument: IInUseTaxReturns = action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument;

            let documents = [
                ...state[query].InUseExtensionTableModel.inUseExtensionModel.slice(0, i),
                InUseTaxDocument,
                ...state[query].InUseExtensionTableModel.inUseExtensionModel.slice(i + 1)];
            let InUseExtensionTableModel: InUseExtensionsTableModel = {
                count: state[query].InUseExtensionTableModel.count,
                inUseExtensionModel: documents
            }
            newState[query] = {
                query: query,
                InUseExtensionTableModel: InUseExtensionTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
        else {
            newState[query] = {
                query: query,
                InUseExtensionTableModel: state[query].InUseExtensionTableModel,
                totalRowCount: state[query].totalRowCount,
                loading: false
            };
        }
    }
    return newState;
}

function clearTaxReturns(): IInUseExtensionsDictionary {
    return {} as IInUseExtensionsDictionary;   //clearing the dictionary on archive/recall
}
