import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { FeatureDisabled } from "src/components/FeatureDisabled";
import Login from "src/components/Login";
import PreLogin from "src/components/PreLogin";
import { Toaster } from '../components/helper/VenusNotifier';
import SigninOidc from "src/oidcClient/SigninOidc";
import SignoutOidc from "src/oidcClient/SignoutOidc";
import SilentSigninOidc from "src/oidcClient/silentSigninOidc";
import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";
import UserBlockedRoute from "./UserBlockedRoute";
import ExtensionInProcess from 'src/components/assignments/CompanyAssignmentsContainer';
import ClientInstructionContainer from 'src/components/settings/ClientInstructions/ClientInstructionContainer';
import InUseExtensionsContainer from 'src/components/reports/InUseExtensionsContainer';
import SavedMessageContainer from 'src/components/settings/SavedMessage/SavedMessageContainer';
import Signatures from 'src/components/settings/SignaturteComponents/SignaturesContainer';
import RecycleReturns from 'src/components/reports/RecycleReturns/RecycleReturnsContainer';
import ArchivedReports from "src/components/reports/ArchiveReturnsContainer";
import DeliveredReports from "src/components/reports/DeliveredReturnsContainer";
import GeneralSettingsContainer from 'src/components/settings/GeneralSettingsComponents/GeneralSettingsContainer';
import SendGroupedExtensionsContainer from 'src/components/GroupedExtensions/SendGroupedExtensions/SendGroupedExtensionsContainer';
import GroupDeliveredReportsContainer from 'src/components/GroupedExtensions/DeliveredGroupedExtensions/GroupDeliveredReportsContainer';
import GroupSettingsContainer from 'src/components/GroupedExtensions/GroupedExtensionsSettings/GroupSettingsContainer';
import RecycleGroupedExtensionsContainer from 'src/components/GroupedExtensions/RecycleGroupedExtensions/RecycleGroupedExtensionsContainer';
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from "src/store";
import { actionCreators } from './../store/PostAuth/PostAuthStore'
import { actionCreators as rbacActionCreator } from "./../store/common/RBACStore";
import LayoutWrapper from "src/components/LayoutWrapper"
import { getAutoLogout } from "../oidcClient/helpers";
import { resetUserCache } from "../store/auth/auth.apis";
import { Vouchers } from "src/components/settings/Vouchers";
import { signoutRedirect } from "src/oidcClient/userService";
import { SecuritySettings }from "src/components/settings/SecurityComponents/SecuritySettings";


export const Routes = () => {

    const user = useSelector((state: ApplicationState) => state.auth.user)
    const dispatch = useDispatch();
    const history = useHistory();
    const userAutoLogout = getAutoLogout();

    const getData = () => {
        dispatch(actionCreators.requestPostAuthData());
        dispatch(rbacActionCreator.requestResourceIdList());
        dispatch(rbacActionCreator.getLeftMenus(history.push));
        dispatch(rbacActionCreator.getNavigationMenu());
        dispatch(rbacActionCreator.getSuiteUrl());
    }

    const resetCache = async () => {
        await resetUserCache();
        getData();
    };

    useEffect(() => {
        if (user) {
            if (userAutoLogout !== "1") {
                resetCache();
            } else {
                signoutRedirect();
            }
        }
    }, [user, userAutoLogout, dispatch])
    return <>
            <LoggedIn>
                <LayoutWrapper>
                    <Switch>                        
                        <Route exact path="/FeatureDisabled" component={FeatureDisabled} />
                        <UserBlockedRoute path='/' exact component={ExtensionInProcess} />
                        <UserBlockedRoute path='/ExtensionInProcess' component={ExtensionInProcess} />
                        <UserBlockedRoute path='/DeliveredReport' component={DeliveredReports} />
                        <UserBlockedRoute path='/ArchivedReport' component={ArchivedReports} />
                        <UserBlockedRoute path='/InUseExtensions' component={InUseExtensionsContainer} />
                        <UserBlockedRoute path='/RecycleReturns' component={RecycleReturns} />
                        <UserBlockedRoute path='/GeneralSettings' component={GeneralSettingsContainer} />
                        <UserBlockedRoute path='/ClientInstruction' exact component={ClientInstructionContainer} />
                        <UserBlockedRoute path='/SavedMessages' exact component={SavedMessageContainer} />
                        <UserBlockedRoute path='/Signatures' exact component={Signatures} />
                        <UserBlockedRoute path='/RecycleGroupedExtensions' component={RecycleGroupedExtensionsContainer} />
                        <UserBlockedRoute path='/GroupExtensionInProcess' component={SendGroupedExtensionsContainer} />
                        <UserBlockedRoute path='/GroupDeliveredReports' component={GroupDeliveredReportsContainer} />
                        <UserBlockedRoute path='/GroupSettings' component={GroupSettingsContainer} />
                        <UserBlockedRoute path='/Vouchers' component={Vouchers} />
                        <UserBlockedRoute path='/Security' component={SecuritySettings} />
                    </Switch>
                    <Toaster />
                </LayoutWrapper>
            </LoggedIn>

            <LoggedOut>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/signin-oidc" component={SigninOidc} />
                    <Route path="/silent-signin-oidc" component={SilentSigninOidc} />
                    <Route path="/signout-callback-oidc" component={SignoutOidc} />
                    <Route path="*" component={PreLogin} />
                </Switch>
            </LoggedOut>          
    </>
};