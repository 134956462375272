import React from  'react';
import * as Bs from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ITaxReturn, SignatureStatus, DocumentEvent } from '../common/TaxReturn';
import { OverlayLoader } from '../helper/OverlayLoader';
import { GetFormatedDateTime } from '../helper/HelperFunctions';
import { SignerStatus } from './DeliveredTaxReturns';

let moment = require('moment');

interface ISignedDetails {
    show: boolean;
    onCancel: () => void;
    taxReturn: ITaxReturn;
}
interface IColumnValues {
    signerName: string,
    signerEmail: string,
    signedStatus: string,
    signedDate: Date
}



export class SignedDetails extends React.Component<ISignedDetails, {}>{
    constructor(props: any) {
        super(props);
    }

    signerDateType(cell: any, row: any) {
        if (cell === "N/A")
            return "N/A";
        else
            return cell;
    }

    ellipsisFormatter = (cell) => {
        return <span title={cell}  className="ellipsis">{cell}</span>
      };

    public render() {

        const signedDetailsColumn = [
            {
                header: 'Signer Name',
                key: 'signerName',
                isKey: false,
                dataFormat: (cell: string) =>this.ellipsisFormatter(cell),
                width: 'auto',
            },
            {
                header: 'Signer Email',
                key: 'signerEmail',
                isKey: false,
                dataFormat: (cell: string) =>this.ellipsisFormatter(cell),
                width: 'auto',
            },
            {
                header: 'Signed Status',
                key: 'signedStatus',
                isKey: true,
                dataFormat: (cell: string) =>this.ellipsisFormatter(cell),
                width: 'auto',
            },
            {
                header: 'Signed Date',
                key: 'signedDate',
                isKey: false,
                dataFormat: (cell: string) =>this.ellipsisFormatter(cell),
                width: 'auto',
            }
        ];

        let signedDetailsData: IColumnValues[] = [];

        if (this.props.taxReturn && this.props.taxReturn.signedDetails) {
            let signStatus: any = this.props.taxReturn.signatureStatus;
            this.props.taxReturn.signedDetails.map((value, index) => {
                signedDetailsData.push({
                    signerName: value.name,
                    signerEmail: value.signer,
                    signedStatus: value.signerStatus === SignerStatus.Signed ?
                        "Signed" :
                        value.signerStatus === SignerStatus.Reviewed ?
                            "Reviewed" :
                            (signStatus === SignatureStatus[SignatureStatus.AwaitingUpload] &&
                                value.isKBAVerified == null &&
                                value.kbaFailedCount > 0) ?
                                "Failed KBA - Awaiting document upload" :
                                (signStatus === SignatureStatus[SignatureStatus.AwaitingUpload] &&
                                    value.isKBAVerified == null &&
                                    value.kbaFailedCount === 0) ?
                                    "opted for manual sign" :
                                    (signStatus === SignatureStatus[SignatureStatus.AwaitingReview] ||
                                        signStatus === SignatureStatus[SignatureStatus.PartiallyReviewed] ||
                                        signStatus === SignatureStatus[SignatureStatus.ESigned]) ?
                                        "Not Reviewed" :
                                        "Not Signed",
                    signedDate: value.signerStatus === SignerStatus.Signed ||
                        value.signerStatus === SignerStatus.Reviewed
                        ?
                        GetFormatedDateTime(value.signedOn) :
                        "N/A"  //for 24 hours format use HH:mm:ss, for 12 hours format use hh:mm:ss
                });
            });
        }

        let modalBody = this.props.taxReturn && this.props.taxReturn.signedDetails ?
            (
                <BootstrapTable data={signedDetailsData}>
                    {signedDetailsColumn.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            width={value.width}
                            >
                            {value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>) :
            (<OverlayLoader
                show={this.props.show}
                text={"Loading, please wait..."} />);

        return <Bs.Modal className="signed-Details-modal" show={this.props.show} onHide={this.props.onCancel}>
            <Bs.Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="3E48309B-CE0F-40FC-9AAD-A3C673A7A5A0">
                <Bs.Modal.Title>
                    <span
                        className='fas fa-user'
                        style={{ color: 'grey', marginRight: '5px' }}>
                    </span>E-Sign Details
                   </Bs.Modal.Title>
            </Bs.Modal.Header>
            <Bs.Form>
                <Bs.Modal.Body>
                    {modalBody}
                </Bs.Modal.Body>
            </Bs.Form>
            <Bs.Modal.Footer>
                <Bs.Button
                    variant="default"
                    data-test-auto="2791EE4F-F1F6-424A-9471-222568102B29"
                    onClick={this.props.onCancel}>
                    <i className='fas fa-times'></i>Cancel
                </Bs.Button>
            </Bs.Modal.Footer>
        </Bs.Modal>;
    }
}

export default SignedDetails;
