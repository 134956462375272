import React, { useEffect, useState } from 'react'
import { IDragAndDropData } from '../DragAndDrop.model';
import InfoIcon from 'src/components/svg/InfoIcon';
import DraggableList from '../DraggableList/DraggableList';
import "./source.css"


interface SourceProps {
    destinationData: IDragAndDropData[];
    sourceData: IDragAndDropData[];
    handleDropChange: (
        destination: IDragAndDropData[],
        source: IDragAndDropData[],
    ) => void;
    sourceHeading: string;
    sourceHeadingInfoText: string;
    sourceWidth: string;
    sourceHeight: string;
    dndUniqueId: string;
    selectedList: IDragAndDropData[];
    setSelectedList: (selectedList: IDragAndDropData[]) => void;
    disableDragAndDrop: boolean;
}


const Source = ({
    destinationData = [],
    sourceData = [],
    handleDropChange ,
    sourceHeading,
    sourceHeadingInfoText,
    sourceWidth,
    sourceHeight,
    disableDragAndDrop,
    dndUniqueId,
    selectedList,
    setSelectedList
}: SourceProps) => {

    const [source, setSource] = useState<IDragAndDropData[]>([])

    useEffect(() => {
        setSource(sourceData)
    }, [sourceData])

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    }

    const dropHandler = (event: any) => {
        const transferData = JSON.parse(event.dataTransfer.getData("text"))
        const dndUniqueIdTemp = event.dataTransfer.getData("dndUniqueId")

        if (dndUniqueIdTemp === dndUniqueId) {
            if (sourceData.some((eachSourceData: IDragAndDropData) => transferData.findIndex((eachTransferData: number) => eachTransferData === eachSourceData.value) === -1) 
            || !sourceData.length) {
                const transferList = destinationData.filter((eachDestinationData: IDragAndDropData) => transferData.some((eachTransferData:number) => eachTransferData=== eachDestinationData.value))
                const newSourceList = [...transferList, ...sourceData]
                const newDestinationList = destinationData.filter((eachDestinationData: any) => !transferData.some((eachTransferData: any) => eachTransferData === eachDestinationData.value))
                handleDropChange(newDestinationList, newSourceList)
            }
        }
    }

    return (
        <article className='sourceDataContainer' style={sourceWidth ? { width: sourceWidth } : null}>
            {
                sourceHeading && (
                    <div className="source-data-header-div">
                        <span className="data-header">{sourceHeading}</span>
                        {sourceHeadingInfoText && (
                            <span className="source-data-info" title={sourceHeadingInfoText}>
                                <InfoIcon />
                            </span>
                        )}
                    </div>
                )
            }
            {
                disableDragAndDrop ?
                    (
                        <ul className='list_container'
                            style={sourceHeight ? { height: sourceHeight } : null}
                            data-dnduniqueid={dndUniqueId}
                        >
                          <DraggableList
                                listData={source}
                                dndUniqueId={dndUniqueId}
                                selectedList={selectedList}
                                setSelectedList={setSelectedList}
                                disableDragAndDrop={disableDragAndDrop}
                            />
                        </ul>
                    ) :
                    <ul className='list_container'
                        onDragOver={dragOverHandler}
                        onDrop={dropHandler}
                        style={sourceHeight ? { height: sourceHeight } : null}
                        data-dnduniqueid={dndUniqueId}
                    >
                        
                        <DraggableList
                            listData={source}
                            dndUniqueId={dndUniqueId}
                            selectedList={selectedList}
                            setSelectedList={setSelectedList}
                            disableDragAndDrop={disableDragAndDrop}
                            />

                    </ul>
            }


        </article>
    )
}

export default Source;