import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../ActionTypes';
import { IDelegatedEROSigner } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import { IUserDefaultSettingsStates, DefaultMailingAddress } from '../../components/navigation/profile/MySettings';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as Helper from '../../components/helper/Constants';
import { EngagementType, DocumentGroups } from '../../components/common/TaxReturn';
import { IUserNotificationSettings } from '../../Core/ViewModels/User/NotificationSettingModel';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';


export interface UserSettings {
    settings: IUserDefaultSettingsStates;
    addresses: DefaultMailingAddress[];
    delegatedSigners: IDelegatedEROSigner[];
    error: boolean;
    isLoading: boolean;
    message: string;
    notificationSettings: IUserNotificationSettings;
}

interface RequestUserSettingAction {
    type: actionTypes.REQUEST_USER_SETTINGS;
}

interface ReceiveUserSettingAction {
    type: actionTypes.RECEIVE_USER_SETTINGS;
    settings: IUserDefaultSettingsStates;
}

interface ErrorUserSettingAction {
    type: actionTypes.ERROR_USER_SETTINGS;
    message: string;
}


interface ReceiveDelegatedSignersAction {
    type: actionTypes.RECEIVE_DELEGATED_SIGNERS;
    delegatedSigners: IDelegatedEROSigner[];
}

interface UpdateDocumentOrderAction {
    type: actionTypes.UPDATE_DOCUMENTORDER;
}

type KnownAction =
    RequestUserSettingAction |
    ReceiveUserSettingAction |
    ReceiveDelegatedSignersAction |
    ErrorUserSettingAction |
    NotificationAction |
    UpdateDocumentOrderAction ;

export const actionCreators = {
    requestUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.settings) {
            const fetchTask = fetch(`${API_BASE_URL}api/UserSettings/UserSetting`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IUserDefaultSettingsStates>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                    logger.trackError(`requestUserSettings failed with error ${error.message}`);
                });
            addTask(fetchTask);
            //dispatch({ type: actionTypes.REQUEST_USER_SETTINGS });
        }
    },
   
    saveUserSettings: (settings: IUserDefaultSettingsStates, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/UserSettings`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(settings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }

                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
                logger.trackError(`saveUserSettings failed for settings: ${settings}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateUserSettings: (settings: IUserDefaultSettingsStates): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
    },

    requestDelegatedSigners: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().userSettings;
        if (state.delegatedSigners && userId != 0) {
            const fetchTask = fetch(`${API_BASE_URL}api/UserManagement/GetAllDelegatedEroSigners/?id=` + userId, { 
                credentials: 'include',
                headers:{
                    'X-Resource-Id': getProcessResourceId()
                }
             })
                .then(
                    response => response.json() as Promise<Array<IDelegatedEROSigner>>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_DELEGATED_SIGNERS, delegatedSigners:data });
                })
                .catch(error => {
                    logger.trackError(`requestDelegatedSigners failed for userId: ${userId}, with error ${error.message}`);

                });
        
            addTask(fetchTask);
        }
    },

      updateDocumentGroupOrder: (engagementType: EngagementType, documentGroupOrder: DocumentGroups[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const resourceId = getProcessResourceId();
          const fetchTask = fetch(`${API_BASE_URL}api/UserSettings/UpdateDocumentGroupOrder/?engagementType=${engagementType}&documentGroupOrder=${JSON.stringify(documentGroupOrder)}`,
              {
                  method: 'PUT',
                  credentials: 'include',
                  headers: { 'X-Resource-Id': resourceId }
              })
                .then(handleResponse)
                .then(data => {
                })
              .catch(error => {
                  logger.trackError(`updateDocumentGroupOrder failed for engagementType: ${engagementType}, with error ${error.message}`);

                });

            addTask(fetchTask);
        
    }
};

const unloadedState: UserSettings = {
    settings: {},
    addresses: {},
    delegatedSigners: {},
    notificationSettings: {}
} as UserSettings;

export const reducer: Reducer<UserSettings> = (state: UserSettings = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_SETTINGS:
            let cleanSettings: UserSettings = { ...state };
            delete cleanSettings.settings;
            cleanSettings.error = false;
            cleanSettings.isLoading = true;
            cleanSettings.message = '';
            return cleanSettings;
        case actionTypes.RECEIVE_USER_SETTINGS:
            let updated: UserSettings = { ...state };
            updated.error = false;
            updated.settings = action.settings;
            updated.isLoading = false;
            updated.message = Date();
            return updated;
        case actionTypes.RECEIVE_DELEGATED_SIGNERS:
            var received: UserSettings = { ...state };
            received.delegatedSigners = action.delegatedSigners;
            received.error = false;
            received.isLoading = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_USER_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            } as UserSettings;
    }

    return state || unloadedState;
};
