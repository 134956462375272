import { IMenuSection } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { RouteConstants } from 'src/components/helper/Constants';
import { API_BASE_URL } from 'src/utils/contants'
import { handleResponse } from '../store/Library'

export function IsAdminUser(user: any) {
    return user?.profile?.role?.split(',')?.includes('Admin') || false
}

export function isOnlySignatureUser(user: any) {
    return user?.profile?.role === 'Signatures'
}

export function isScreenPermisionDisabled(leftMenus: IMenuSection[], navigate: (route : string) => void): boolean {
    //check if screen is disable then show access denied page
    let isScreenAccessDenied = false;
    if (leftMenus && leftMenus.length > 0) {
        let location = window.location.pathname;
        if (location == RouteConstants.EXTENSIONINPROCESS) {

                let menuSection = leftMenus.find(sections => sections.items.find(menu => !menu.disabled
                    && menu.caption.toLowerCase() != RouteConstants.HELP))
                let menu = menuSection.items.find(m => !m.disabled);
                //parent menu routing
                if (menu.route && menu.route != "") {
                    if(menu.route != RouteConstants.EXTENSIONINPROCESS)
                    {
                        navigate(menu.route);
                    }
                } else {
                    let subMenu = menu.items.find(x => !x.disabled);
                    //child sub menu routing
                    if (subMenu && subMenu.route) {
                        navigate(subMenu.route);
                    }
                }
                isScreenAccessDenied = false;
        } else {

            let sectionList = leftMenus.some(sections => sections.items.some(menu => menu.route == location && !menu.disabled)
                || sections.items.some(menu => menu.items.some(subMenu => subMenu.route == location && !subMenu.disabled)));
            //for unaccess manual URL 
            if (!sectionList) {
                isScreenAccessDenied = true;
            }
        }
    }
    return isScreenAccessDenied;
}

export const getIsSFProductEnabled = async () => {
    const response = await fetch(API_BASE_URL + 'api/Company/GetSFProductStatusAsync', { credentials: 'include' })
    return handleResponse(response)
}
