export enum TaxreturnType {
    None = 0,
    IndividualTaxReturn = 1,
    MarriedJointTaxReturn = 2,
    CorporateTaxReturn = 3,
}

export interface IReportProblemDetails {
    productType: string;
    taxSoftware: string;
    cpaPhoneNumber: string;
    cpaEmail: string;
    documentId: number;
    taxreturnType: TaxreturnType;
    returnType: string;
    taxYear: number;
    clientId: string;
    status: string;
    taxpayerName: string;
    taxpayerEmail: string;
    spouseName: string;
    spouseEmail: string;
    partnershipName: string;
    partnershipEmail: string;
    description: string;
}

export enum ProductTypes {
    None = "None",
    General = "General",
    SafeSendReturns = "SafeSend Returns",
    SafeSendSignatures = "SafeSend Signatures",
    SafeSendOrganizers = "SafeSend Organizers",
    SafeSendExtensions = "SafeSend Extensions",
}

export const initialState: IReportProblemDetails = {
    clientId: "",
    cpaEmail: "",
    cpaPhoneNumber: "",
    description: "",
    documentId: 0,
    partnershipEmail: "",
    partnershipName: "",
    productType: ProductTypes.None,
    returnType: "",
    spouseEmail: "",
    spouseName: "",
    status: "",
    taxpayerEmail: "",
    taxpayerName: "",
    taxreturnType: TaxreturnType.None,
    taxSoftware: "",
    taxYear: 0,
};

export enum ReportProblemProperty {
    productType,
    clientId,
    returnType,
    taxYear,
    cpaEmail,
    cpaPhoneNumber,
    taxSoftware,
    status,
    // Taxpayer
    taxpayerName,
    taxpayerEmail,
    // Spouse
    spouseName,
    spouseEmail,
    // Partner
    partnerName,
    partnerEmail,
    description,
}

export enum ReportProblemTypes {
    Generic = 1,
    ReturnSpecific = 2,
}

export enum ReportedStep {
    None = 0,
    Assignments = 1,
    Report = 2,
}

export const SignatureStatusOptionsList: SelectOptions[] = [
    { label: "E-SIGNED", value: "ESigned" },
    { label: "MANUALLY SIGNED", value: "ManuallySigned" },
    { label: "UPLOADED", value: "Uploaded" },
    { label: "AWAITING E-SIGN", value: "AwaitingESign" },
    { label: "AWAITING UPLOAD", value: "AwaitingUpload" },
    { label: "SIGNED AND E-SIGNED", value: "SignedAndESigned" },
    { label: "DELIVERED TO TAXCADDY", value: "DeliveredToTaxCaddy" },
    { label: "TAXCADDY DELIVERY FAILED", value: "TaxCaddyDeliveryFailed" },
    { label: "ALTERNATE DELIVERY", value: "AlternateDelivery" },
    //{ label: "ALTERNATE AND UPLOADED", value: "AlternateAndUploaded" },
    { label: "REVIEWED", value: "Reviewed" },
    { label: "AWAITING REVIEW", value: "AwaitingReview" },
    { label: 'PARTIALLY SIGNED', value: "PartiallySigned" },
    { label: "PARTIALLY REVIEWED", value: "PartiallyReviewed" }
];

export interface SelectOptions {
    value: string;
    label: string;
}

export const DocumentStatusType: SelectOptions[] =
    [
        { label: "UPLOADED", value: "UPLOADED" },
        { label: "ERROR", value: "ERROR" },
        { label: "READY", value: "READY" },
        { label: "PROCESSING", value: "PROCESSING" },
        { label: "REVIEW", value: "REVIEW" },
        { label: "APPROVED FOR DELIVERY", value: "APPROVEDFORDELIVERY" },
        { label: "PREPARING FOR DELIVERY", value: "PREPARINGFORDELIVERY" },
        { label: "DELIVERY FAILED", value: "DELIVERYFAILED" },
        { label: "NON SUPPORTED", value: "NONSUPPORTED" },
        { label: "RECALLED", value: "RECALLED" },
        { label: "REPROCESSING", value: "REPROCESSING" },
        { label: "TAXCADDY DELIVERY FAILED", value: "TAXCADDYDELIVERYFAILED" },
        { label: "DUPLICATE", value: "DUPLICATE" },
        { label: "DOWNLOADING FAILED", value: "DOWNLOADINGFAILED" },
        { label: "PREPARING FOR PDF DOWNLOAD", value: "PREPARINGFORPDFDOWNLOAD" },
        { label: "TO BE REPROCESSED", value: "TOBEREPROCESSED" }
    ];
