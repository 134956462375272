import React from 'react';
export interface LogoImageProps {
    height?: number;
    width?: number;
    className?: string;
    color?: string;
};

export const DeleteDollarZero: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 100} height={props.height || 100} className={props.className} viewBox="0 0 100 100">
        <path fill="#333333" d="M17.669,27.607v-7.113c3.92,0.646,6.777,2.297,6.817,2.321l4.725-5.337 c-0.229-0.136-4.729-3.189-11.542-4.035V6.988h-4.094v6.264c-5.37,0.296-12.466,3.424-12.468,10.984 c-0.004,7.254,7.017,8.645,12.468,9.529v9.321c-4.966-0.743-9.762-3.744-9.812-3.775L0,45.22c0.293,0.188,6.652,4.19,13.575,4.922 v6.449h4.094v-6.427c6.699-0.618,12.798-4.367,12.966-10.987C30.856,30.472,23.784,28.707,17.669,27.607z M13.576,26.846 c-2.644-0.581-4.534-1.375-4.562-3.216c-0.044-2.829,2.916-3.338,4.562-3.405V26.846z M17.669,43.1v-8.611 c4.463,0.935,5.959,2.016,5.959,4.69C23.629,41.333,20.593,42.67,17.669,43.1z" />
        <path fill="#0055A3" d="M54.999,80c0-4.081,0.976-8.011,2.81-11.529c-3.152-4.455-4.081-12.816-4.081-22.406 c0-14.263,2.631-25.825,10.427-25.825c7.699,0,10.429,11.563,10.429,25.825c0,3.4-0.112,6.643-0.396,9.617 C76.069,55.234,78.017,55,80,55c3.834,0,7.534,0.861,10.886,2.485c0.374-3.318,0.557-7.027,0.557-11.226 c0-9.082-1.061-16.342-3.243-22.194c-4.146-11.121-13.133-17.76-24.045-17.76c-10.964,0-19.97,6.653-24.093,17.795 c-2.149,5.808-3.193,13.056-3.193,22.159c0,9.372,0.922,16.346,2.901,21.946c2.896,8.191,8.505,13.968,15.678,16.525 C55.153,83.188,54.999,81.605,54.999,80z" />
        <circle fill="#E80505" cx="80" cy="80" r="20.001" />
        <circle fill="#E80505" cx="80" cy="80" r="20.001" />
        <path fill="#FFFFFF" d="M93,81.333C92.999,82.807,92.17,84,91.142,84H68.857C67.829,83.999,67,82.806,67,81.333v-2.666 C67,77.193,67.83,76,68.857,76h22.284C92.17,76,93,77.194,93,78.667V81.333z" />
        <ellipse fill="#0055A3" cx="64" cy="47" rx="6.5" ry="8.5" />
    </svg>;
}


export const VoucherEstimatedPayment: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 525.029} height={props.height || 525} className={props.className} viewBox="0 0 525.029 525">
        <path fill="#39B54A" d="M467.779,0h-412.5C24.224,0-0.971,25.195,0.029,56.25v412.5c-1,31.055,24.195,56.25,55.25,56.25h412.5 c31.055,0,56.25-25.195,57.25-56.25V56.25C524.029,25.195,498.833,0,467.779,0z M221.923,168.754c0,10.313-8.438,18.75-18.75,18.75 l-66.888-0.004V225h38.067c10.312,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75h-38.067v37.5l66.888-0.004 c10.312,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75L80.036,412.5c-10.313,0-18.75-8.438-18.75-18.75v-262.5 c0-10.313,8.438-18.75,18.75-18.75l123.138,0.004c10.312,0,18.75,8.438,18.75,18.75V168.754z M362.272,413.667H273.72h-0.07 c-10.312,0-18.75-8.438-18.75-18.75v-37.5c0-10.313,8.438-18.75,18.75-18.75h0.07h88.552c20.625,0,37.5,1.125,37.5-19.5 c0-13.719-10.47-19.926-21.538-20.539l-46.796-2.293c-53.007-5.705-89.538-34.969-89.538-90.5c0-61.992,43.508-94.5,105.5-94.5 h88.552h0.07c10.313,0,18.75,8.438,18.75,18.75v37.5c0,10.313-8.438,18.75-18.75,18.75h-0.07H347.4c-20.625,0-37.5-1.125-37.5,19.5 c0,13.718,10.47,19.926,21.538,20.539l46.796,2.292c53.008,5.706,89.538,34.97,89.538,90.501 C467.772,381.159,424.264,413.667,362.272,413.667z" />
    </svg>;
}

export const VoucherPayment: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 525.003} height={props.height || 525} className={props.className} viewBox="0 0 525.003 525">
        <path fill="#0071BC" d="M-1027.039,853.093v-68.971h-75h-11.943c-8.131-0.695-16.596-1-25.286-1h-42.398h-13.262 c-0.558-1.654-0.86-3.776-0.86-6.5c0-20.625,16.875-31.5,37.5-31.5h28.393h27.857h75v-56.25c0-10.313-8.438-18.75-18.75-18.75 h-112.5c-61.992,0-112.5,44.508-112.5,106.5c0,2.596,0.096,5.111,0.271,7.563v73.938h75h11.812h2.812c7.306,0.68,14.867,1,22.606,1 h15.128h41.122h0.23c0.022,0.48,0.041,0.97,0.041,1.5c0,20.625-16.875,34.5-37.5,34.5h-44.438h-11.812h-75v56.25 c0,10.313,8.438,18.75,18.75,18.75h112.5c61.992,0,112.5-47.508,112.5-109.5C-1026.769,858.029-1026.865,855.525-1027.039,853.093z" />
        <path fill="#0071BC" d="M277.878,187.5h-56.25v75h56.25c20.625,0,37.5-16.875,37.5-37.5S298.503,187.5,277.878,187.5z" />
        <path fill="#0071BC" d="M468.753,0H56.25C25.195,0,0,25.195,0,56.25v412.5C0,499.805,25.195,525,56.25,525h412.503 c31.055,0,56.25-25.195,56.25-56.25V56.25C525.003,25.195,499.808,0,468.753,0z M277.878,337.5h-56.25v56.25 c0,10.311-8.439,18.75-18.752,18.75h-37.501c-10.313,0-18.75-8.439-18.75-18.75v-262.5c0-10.313,8.438-18.75,18.75-18.75h112.503 c61.992,0,112.5,50.508,112.5,112.5S339.87,337.5,277.878,337.5z" />
    </svg>;
}

export const SvgIconContactHeader: React.FC<LogoImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} viewBox="0 0 24 24">
        <path fill="#FFFFFF" d="M12.053,1.448c0,0,4.402,0.202,4.402,4.552S10.643,10.35,12,10.35c1.357,0-4.35,0-4.35-4.35 S12.053,1.448,12.053,1.448z M12.15,15.15c4.966,0,10.2,3.285,10.2,4.725v2.475H1.95v-2.475C1.95,18.436,7.184,15.15,12.15,15.15z M6,6c0,3.315,2.685,6,6,6c3.314,0,6-2.685,6-6s-2.686-6-6-6C8.685,0,6,2.685,6,6z M12,13.5c-4.005,0-12,2.01-12,6V24h24v-4.5 C24,15.51,16.005,13.5,12,13.5z" />
    </svg>;
}

export const HamburgerIcon: React.FC<LogoImageProps> = ({ height, width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={height || 20}
            height={width || 20}
            version="1.1"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={height || 20}
                height={width || 20}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={height || 20}
                    height={width || 20}
                    viewBox="0 0 276.167 276.167"
                >
                    <path
                        fill="#0c7cc0"
                        d="M33.144 2.471C15.336 2.471.85 16.958.85 34.765s14.48 32.293 32.294 32.293 32.294-14.486 32.294-32.293S50.951 2.471 33.144 2.471zm104.519 0c-17.807 0-32.294 14.487-32.294 32.294s14.487 32.293 32.294 32.293c17.808 0 32.297-14.486 32.297-32.293S155.477 2.471 137.663 2.471zm106.21 64.588c17.804 0 32.294-14.486 32.294-32.293S261.689 2.471 243.873 2.471s-32.294 14.487-32.294 32.294 14.489 32.294 32.294 32.294zM32.3 170.539c17.807 0 32.297-14.483 32.297-32.293 0-17.811-14.49-32.297-32.297-32.297S0 120.436 0 138.246c0 17.81 14.493 32.293 32.3 32.293zm104.519 0c17.804 0 32.294-14.483 32.294-32.293 0-17.811-14.478-32.297-32.294-32.297-17.813 0-32.294 14.486-32.294 32.297 0 17.81 14.487 32.293 32.294 32.293zm106.219 0c17.811 0 32.294-14.483 32.294-32.293 0-17.811-14.483-32.297-32.294-32.297s-32.306 14.486-32.306 32.297c0 17.81 14.49 32.293 32.306 32.293zM33.039 209.108c-17.807 0-32.3 14.483-32.3 32.294 0 17.804 14.493 32.293 32.3 32.293s32.293-14.482 32.293-32.293-14.486-32.294-32.293-32.294zm104.525 0c-17.808 0-32.3 14.483-32.3 32.294 0 17.804 14.487 32.293 32.3 32.293 17.804 0 32.293-14.482 32.293-32.293s-14.489-32.294-32.293-32.294zm106.207 0c-17.804 0-32.294 14.483-32.294 32.294 0 17.804 14.49 32.293 32.294 32.293 17.811 0 32.294-14.482 32.294-32.293s-14.49-32.294-32.294-32.294z"
                        className="color000 svgShape color21a9e1"
                    ></path>
                </svg>
            </svg>
        </svg>
    )
}

export const ReportProblemIcon: React.FC<LogoImageProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "20"}
        height={props.height || "20"}
        fill={"none"}
        viewBox="0 0 12 15"
    >
        <path
            fill={props.color || "#565A5E"}
            d="M6 1a5 5 0 00-5 5v1h1a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V6a6 6 0 1112 0v6a2.5 2.5 0 01-2.5 2.5H7.366a1 1 0 01-.866.5h-1a1 1 0 010-2h1a1 1 0 01.866.5H9.5A1.5 1.5 0 0011 12h-1a1 1 0 01-1-1V8a1 1 0 011-1h1V6a5 5 0 00-5-5z"
        ></path>
    </svg>
);