import React from  'react';
import { Button} from 'react-bootstrap';
import { DocumentGroups, EngagementType } from '../../TaxReturn';
import { ISelectedPagesDictionary } from '../GroupsView';
import { ReGroupPage } from '../ReGroupPage';

interface GroupToolBarProps {
    onClearSelection: () => void;
    onDelete: () => void;
    onMove: (destinationGroup: DocumentGroups) => void;
    selectedPages: ISelectedPagesDictionary
    engagementType: EngagementType;
}
const NO_INDEX = -1;
export class GroupToolBar extends React.Component<GroupToolBarProps, {}> {

    constructor(props: GroupToolBarProps) {
        super(props);
    }

    public render() {
        let selectedGroup = Object.keys(this.props.selectedPages).map((key: any) => this.props.selectedPages[key].source);

        return (<div className="group-toolbar" style={{ display: 'flex' }}>

            <div style={{ flex:'1' }}>
                <Button title="Clear Selection"
                    className="btn-white btn-only" onClick={this.props.onClearSelection} >
                    <span className='fas fa-times'></span>
                    <span className="pages-selected">{Object.keys(this.props.selectedPages).length} Selected</span>
                </Button>
            </div>

            <div style={{ flex: '3', display: "flex", flexDirection: 'row-reverse' }}>                
                <ReGroupPage selectedGroup={selectedGroup}
                    engagementType={this.props.engagementType}
                    onRegroup={this.props.onMove}
                /> 
                {selectedGroup.findIndex(x => x === DocumentGroups.Deleted) == NO_INDEX &&

                    <Button
                        className="btn-white btn-only" title="Delete selected pages." onClick={this.props.onDelete}>
                        <span className='fas fa-trash-alt'></span> Delete
                 </Button>
                }
            </div>
        </div>);
    }
}