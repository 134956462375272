const moment = require('moment');
import * as bootbox from 'bootbox';
import React from  'react';
import * as ReactDom from 'react-dom';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    DateFilter, FilterComparator, SelectFilterOptionsType, CustomSelectProps,
    ToolBarProps, CustomFilterParameters
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ExtensionsActions } from './ExtensionsActions';
import { ITaxReturn } from './../../../../components/common/TaxReturn';
import { CheckBoxComponent, CheckBoxSize } from './../../../common/CheckBoxComponent';
import * as Helper from './../../../helper/HelperFunctions';
import { getClientName, DaysRangeOptionsList, DocumentStatus, EngagementType } from './../../../common/TaxReturn';

export interface ExtensionsTableProps {
    isLoading: boolean;
    onProcessReturnOpen: (taxDocumentId: number, groupId: number) => void;
    taxReturns: ITaxReturn[];
    onDocumentSelect: (Ids: number[]) => void;
    onDocumentUnSelect: (Ids: number[]) => void;
    selectedDocuments: number[];
    groupId: number;
    onEditClientInfoOpen: (taxDocumentId: number) => void;
    onDeleteTaxReturnOpen: (taxDocumentId: number) => void;
    onChangeStatusOpen: (taxDocumentId: number) => void;
    onReportProblemOpen: (taxDocumentId: number) => void;
    onReprocessReturnOpen: (taxDocumentId: number) => void;
    onRecallReturnOpen: (taxDocumentId: number) => void;
    onReturnLevelRowDoubleClick: (rowIndex: number, groupId: number) => void;
    pageSize: number;
}

export interface ExtensionsTableState {

}

export class ExtensionsTable extends React.Component<ExtensionsTableProps, ExtensionsTableState> {
    renderShowsTotal = (start: number, to: number, total: number) => {
        return <p>
            Showing <b>{start}</b> to <b>{to}</b> of <b>{total}</b> entries
            </p>
    }

    private setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No assignments found"
        }
    }

    private defaultType = (cell: any, row: any) => {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    private getTaxReturnAction = (cell: any, row: any) => {
        return <ExtensionsActions
            rowIndex={row.rowIndex}
            taxDocumentId={row.index as number}
            groupId={this.props.groupId}
            onProcessReturnOpen={this.props.onProcessReturnOpen}
            onEditClientInfoOpen={this.props.onEditClientInfoOpen}
            onDeleteTaxReturnOpen={this.props.onDeleteTaxReturnOpen}
            onChangeStatusOpen={this.props.onChangeStatusOpen}
            onReportProblemOpen={this.props.onReportProblemOpen}
            documentStatus={row.documentStatusEnum as DocumentStatus}
            uploadedOn={row.uploadedOnDate as Date}
            onReprocessReturnOpen={this.props.onReprocessReturnOpen}
            onRecallReturnOpen={this.props.onRecallReturnOpen}
        />;
    }

    private onRowSelect = (row: any, isSelected: any, e: any) => {

        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                this.props.onDocumentSelect([row.index]);
            } else {
                this.props.onDocumentUnSelect([row.index]);
            }
        }
        this.forceUpdate();
    }

    private onSelectAll = (isSelected: boolean, rows: any, e: any) => {
        const selectedDocuments: number[] = [];

        rows.forEach((value: any, index: number) => {
            selectedDocuments.push(value.index);
        });

        if (isSelected) {
            this.props.onDocumentSelect(selectedDocuments);
        } else {
            this.props.onDocumentUnSelect(selectedDocuments);
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {

        const taxDocument = this.props.taxReturns[props.rowIndex as number];

        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"assignment-checkbox-" + (taxDocument ? taxDocument.id : (props.rowIndex + "_" + this.props.groupId))}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""}
            className="" />);
    }

    private EngagementType = (engagementType: EngagementType) => {
        return (engagementType.toString() == EngagementType[EngagementType.None] ? engagementType.toString() : engagementType.toString().slice(1, 10));
    }

    public render() {
        const pagingationPos: "both" | "top" | "bottom" | undefined = "bottom";
        const options = {
            sizePerPage: this.props.pageSize,
            paginationShowsTotal: this.renderShowsTotal,
            noDataText: this.setNoContent(),
            paginationPosition: pagingationPos, // default is bottom, top and both is all available
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />,
            clearSearch: true,
            onRowDoubleClick:
                (row: any) => {
                    this.props.onReturnLevelRowDoubleClick(row.index, this.props.groupId);
                },
        };

        const unselectable: number[] = [];
        const data = this.props.taxReturns.map((model, index) => {
            if (Helper.isLocked(model)) {
                unselectable.push(model.id);
            }
            return {
                taxDocumentName: getClientName(model),
                clientId: model.clientId,
                partner: model.partner.firstName + ' ' + model.partner.lastName,
                assignToUserName: model.assignedUser.userId == 0 ? '' : model.assignedUser.firstName + ' ' + model.assignedUser.lastName,
                documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
                documentStatusEnum: model.documentStatus,
                uploadedOn: moment(model.uploadedOn).format('MM/DD/YYYY'),
                uploadedOnDate: model.uploadedOn,
                engagementType: this.EngagementType(model.engagementType),
                taxYear: model.taxYear,
                assignTo: model.assignTo,
                button: 'Actions',
                taxReturns: this.props.taxReturns,
                index: model.id,
                rowIndex: index
            };
        });

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',

            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'Assigned To',
                key: 'assignToUserName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'Status',
                key: 'documentStatus',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',

            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
            },

            {
                header: 'Action',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.getTaxReturnAction,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "130px",
            }
        ];

        const selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            unselectable: unselectable,
            selected: this.props.selectedDocuments,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };


        return <div className="pos-relative">
            <div data-test-auto="490D0687-0267-4CC1-97BB-58D9BE65B18F">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={false}
                    fetchInfo={{ dataTotalSize: this.props.taxReturns.length }}
                    options={options}
                    striped
                    hover={true}
                    pagination={this.props.pageSize < this.props.taxReturns.length}
                    selectRow={selectRowProp}
                    search={false}
                >
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div>
    }
}
