import React from 'react';
import { NavLink } from 'react-bootstrap';
import { Modal, Button, FormControl, Table, ProgressBar } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from '../../DocumentUploadDropzoneComponent';
import { VenusNotifier } from '../../../../helper/VenusNotifier';
import { getFileExtension, NullandEmptyCheck, validateFileType } from '../../../../helper/Validations';
import * as bootbox from 'bootbox';
import { getFileSize } from '../../../../helper/HelperFunctions';
import { ShowLoader, HideLoader } from '../../../../helper/Loader';
import { IUserModel } from '../../../../../Core/ViewModels/User/UserViewModel';
import { ButtonFormatter } from '.././UploadCommonFormatters';
import { SasContainer, MultiFileTaxReturnUploadData, subDocumentUploadData, UploadStatus } from '../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import * as UploadTaxReturnStore from '../../../../../store/common/uploadTaxReturn';
import { ITaxReturn } from '../../../TaxReturn';
import { Guid } from '../../../../../Core/Utilities/Guid';
import { GroupExtensionsUploadProps, initialGroupInfo } from '../../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { logClientEvent } from '../../../../helper/LoggerHelper';
import { API_BASE_URL } from 'src/utils/contants';
import { TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { UploadFunctions, isFileExist } from "@sssuite-js-packages/file-utility";
import { UploadtaxReturnConstants } from 'src/components/helper/Constants';
import { DocumentEventLog, LogEventConstants } from 'src/components/helper/LogEventConstants';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { IAccessingUsersAndUserGroups, IUserDataFromDB, IUserGroupDataFromDB } from 'src/components/common/SetAccess/SetAccessCommonModal.model';
import SetAccessCommonModal from 'src/components/common/SetAccess/SetAccessCommonModal';

interface MultiFileReturnUploadModalProps extends GroupExtensionsUploadProps {
    onShow: boolean;
    onHide(): void;
    defaultERO: number;
    EROs: IUserModel[];
    getUploadLink: (url: string, callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    onPageReload: () => void
    taxSoftware: TaxSoftwareType;
    returnAccessSettings: IReturnAccessSettingsModel;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

interface MultiFileReturnUploadModalState {
    multiFileReturnUploadData: MultiFileTaxReturnUploadData;
    submitDisable: boolean;
    showUpload: boolean;
    showSetAccess: boolean;
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: ['.pdf'];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: IUserModel[];
    setAccessCurrentUsers: IUserDataFromDB[];
    taxReturns: ITaxReturn;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

const MaxFileLimitPerUpload: number = 30;

export class MultiFileUploadDocumentModal extends
    React.Component<MultiFileReturnUploadModalProps, MultiFileReturnUploadModalState> {

    constructor(props: MultiFileReturnUploadModalProps) {
        super(props);
        this.state = {
            multiFileReturnUploadData: {
                clientId: "",
                sasGuid: "",
                setAccess: "Everyone",
                clientName: "",
                partnerId: 0,
                documentAccess: {
                    documents: [],
                    users: [],
                    userGroups: []
                },
                subDocumentsUploadData: [],
                groupInfo: initialGroupInfo,
                taxSoftware: TaxSoftwareType.None,
            },
            submitDisable: true,
            showUpload: false,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/",
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturns: {} as ITaxReturn,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };

    }

    componentWillReceiveProps(nextProps: MultiFileReturnUploadModalProps) {

        if (nextProps.defaultERO && this.state.multiFileReturnUploadData.partnerId === 0) {
            const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
            tempMultiFileReturnUploadData.partnerId = nextProps.defaultERO;
            tempMultiFileReturnUploadData.groupInfo.name = nextProps?.defaultGroup ?? '';
            tempMultiFileReturnUploadData.taxSoftware = nextProps.taxSoftware;
            this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
        }
        if (nextProps.availableUsers.length > 0) {
            const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = nextProps;
            this.setState({
                availableUsers,
                selectedUsers,
                availableUserGroups,
                selectedUserGroups
            });
            const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
            tempMultiFileReturnUploadData.documentAccess = {
                documents: [],
                userGroups: nextProps.selectedUserGroups.map((group) => {
                    return group.id;
                }),
                users: nextProps.selectedUsers.map((user) => {
                    return user.id;
                })
            }
            tempMultiFileReturnUploadData.setAccess = !(
                nextProps.returnAccessSettings != undefined &&
                nextProps.returnAccessSettings!.isSetAccessToEveryone
            )
                ? "Restricted"
                : "Everyone"
            this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
        }
    }

    private getMultiFileDocumentGuid = () => {
        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.sasGuid = Guid.newGuid().toString();
        this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
    }

    private onClientIdChange = (event: any) => {
        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.clientId = event.target.value;
        this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
    }


    private reset = () => {
        const { defaultGroup } = this.props;

        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.clientId = "";
        tempMultiFileReturnUploadData.clientName = "";
        tempMultiFileReturnUploadData.sasGuid = "";
        tempMultiFileReturnUploadData.partnerId = this.props.defaultERO;
        tempMultiFileReturnUploadData.setAccess = !(
            this.props.returnAccessSettings != undefined &&
            this.props.returnAccessSettings!.isSetAccessToEveryone
        )
            ? "Restricted"
            : "Everyone",
            tempMultiFileReturnUploadData.documentAccess.users = [];
        tempMultiFileReturnUploadData.documentAccess.userGroups = [];
        tempMultiFileReturnUploadData.documentAccess.documents = [];
        tempMultiFileReturnUploadData.subDocumentsUploadData = [];
        tempMultiFileReturnUploadData.groupInfo.name = defaultGroup ?? "";
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({
            sasContainer: [],
            multiFileReturnUploadData: tempMultiFileReturnUploadData,
            submitDisable: true,
        });
    }

    private handleClose = () => {
        this.reset();
        this.props.onHide();
    }

    private handleUploaderClose = () => {
        let _self = this;
        if (this.state.multiFileReturnUploadData.subDocumentsUploadData.length > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                className: 'in',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                    }
                }
            });
        }
        else {
            _self.handleClose();
        }
    }
    //===========================================================================
    // Set Access Start 
    //===========================================================================

    setAcessShow = () => {
        this.setState({ showSetAccess: true })
    }

    onSetAccessHide = () => {
        this.setState({
            showSetAccess: false
        })
    }

    onSetAccess = (event: any) => {
        this.setState({
            showSetAccess: true
        });
    }
    getSetAccessLable = () => {
        const { selectedUsers, availableUsers, selectedUserGroups, availableUserGroups } = this.state;
        let isAllUsers = false;
        let isAllUserGroups = false;
        if ((selectedUsers.length > 0 && selectedUsers.some((x) => x.id == 0)) ||
        availableUsers.length === 0 ||
        (availableUsers.length === 1 && availableUsers.some((x) => x.id == 0))) {
            isAllUsers = true;
        } 
        if((selectedUserGroups.length > 0 && selectedUserGroups.some((x) => x.id == 0)) ||
        availableUserGroups.length === 0 ||
        (availableUserGroups.length === 1 &&  availableUserGroups.some((x) => x.id == 0))) {
            isAllUserGroups = true;
        }
        if (isAllUsers && isAllUserGroups) {
            return "Everyone";
        } else {
            return "Restricted";
        }
    };

    private onSetAccessSave = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const uploadingDocument: MultiFileTaxReturnUploadData = Object.assign({}, this.state.multiFileReturnUploadData);
        
        uploadingDocument.documentAccess = {
            documents: [],
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers
        };
        uploadingDocument.setAccess = this.getSetAccessLable();

        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups),
            multiFileReturnUploadData: uploadingDocument
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                availableUser.push(user);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        return availableUserGroups;
    };
    //===========================================================================
    // Set Access End 
    //===========================================================================

    onSubmitTaxreturns = () => {
        if (this.validateTaxReturnUploadData()) {
            ShowLoader();
            this.props.submitTaxReturn(`${API_BASE_URL}/api/MultiFileReturnUpload/SubmitMultiFileReturnsAync`,
                JSON.stringify({ ...this.state.multiFileReturnUploadData, taxSoftware: this.props.taxSoftware }), () => {
                    this.UploadConfirmation();
                    this.props.onPageReload();
                    this.handleClose();
                    HideLoader();
                    if (this.state.multiFileReturnUploadData.groupInfo.id == 0)
                        this.props.updateGroupInfoState(true);
                });
            logClientEvent(
                `${LogEventConstants.Common.RecallUpload} ${TaxSoftwareType[this.props.taxSoftware]}`,
                { count: 1 }
            );
        }
        logClientEvent(
            `${DocumentEventLog.DocumentUploadSuccess}: ${TaxSoftwareType[this.props.taxSoftware]}`, { Count: 1, CustomEventType: DocumentEventLog.Name });
    }

    deleteReturn = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            className: 'in',
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _subDocumentData = [..._self.state.multiFileReturnUploadData.subDocumentsUploadData];
                    _subDocumentData = _subDocumentData.filter(i => i.number != row.number);
                    //correct index
                    for (let i = row.number - 1; i < _subDocumentData.length; i++) {
                        _subDocumentData[i].number = i + 1;
                    }
                    let _tempgridData = _subDocumentData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter(i => i.sasGuid != row.subDocumentGuid);

                    let _tempUploadData = _uploadData;

                    const tempMultiFileReturnUploadData = _self.state.multiFileReturnUploadData;
                    tempMultiFileReturnUploadData.subDocumentsUploadData = _tempgridData;
                    if (_subDocumentData.length == 0) {
                        _self.setState({ submitDisable: true })
                    }
                    _self.setState({
                        multiFileReturnUploadData: tempMultiFileReturnUploadData,
                        sasContainer: _tempUploadData
                    });
                }
            }
        });
    }

    private validateTaxReturnUploadData = (): boolean => {
        let uploaddata = this.state.multiFileReturnUploadData;
        if (uploaddata.subDocumentsUploadData.length < 1) {
            VenusNotifier.Warning("No files Uploaded", "");
            return false;
        }

        if (uploaddata.clientId == undefined || uploaddata.clientId == "") {
            VenusNotifier.Warning("Please enter client Id to submit", "");
            return false;
        }
        return true;
    }

    private UploadConfirmation = () => {
        let message: string = "<h5 class='marT0'> " + UploadtaxReturnConstants.ReturnSubmitSuccess + ":<h5> <br/><ul class='Modal-UploadUl'>";
        message = message + "<li>" + this.state.multiFileReturnUploadData.clientId + "</li>";
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            className: 'in',
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> Ok',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    //===========================================================================
    // File Upload Start 
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    }

    eventHandlers = {

        init: () => {
            this.reset();
            this.getMultiFileDocumentGuid();
        },

        addedfiles: (files: any) => {

            try {

                let _self = this;

                // Commented this because a Tax Return can have more than 30 K1-shareholders files, uncomment to add file count limit
                //this.validateFileCount(files);

                let tmpTaxReturnUploadData: subDocumentUploadData[] = this.convertToModel(files);

                tmpTaxReturnUploadData = this.handleDuplicates(tmpTaxReturnUploadData);

                tmpTaxReturnUploadData = _self.state.multiFileReturnUploadData.subDocumentsUploadData.concat(tmpTaxReturnUploadData)

                _self.state.multiFileReturnUploadData.subDocumentsUploadData = tmpTaxReturnUploadData;

                _self.setState({
                    multiFileReturnUploadData: _self.state.multiFileReturnUploadData,
                    submitDisable: true
                }, () => {
                    _self.getUploadLink();
                });

            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }


    private validateFileCount = (uploadedFiles: any) => {

        if (uploadedFiles.length + this.state.multiFileReturnUploadData.subDocumentsUploadData.length > MaxFileLimitPerUpload) {

            throw new Error("You cannot upload more than " + MaxFileLimitPerUpload + " files at a time. Please choose less.");
        }
    }


    private convertToModel = (uploadedFiles: any): subDocumentUploadData[] => {

        let tmpTaxReturnUploadData: subDocumentUploadData[] = [];

        for (let i = 0; i < uploadedFiles.length; i++) {

            let uploadedFile = uploadedFiles[i];
            let tmpFileName = uploadedFiles[i].name;
            let fileExtension = getFileExtension(tmpFileName);

            // Validate file extension

            if (validateFileType(fileExtension)) {

                tmpTaxReturnUploadData.push({
                    name: tmpFileName, number: (tmpTaxReturnUploadData.length +
                        this.state.multiFileReturnUploadData.subDocumentsUploadData.length) + 1,
                    progressBar: 0, size: getFileSize(uploadedFile.size),
                    status: UploadStatus.Wait, file: uploadedFile, subDocumentGuid: ""
                });
            }
            else {
                VenusNotifier.Info("Some file(s) found with invalid extesion, those will skip during upload !!", null);
            }
        }

        return tmpTaxReturnUploadData;
    }

    private handleDuplicates = (taxReturnUploadData: subDocumentUploadData[]): subDocumentUploadData[] => {

        for (let i = 0; i < taxReturnUploadData.length; i++) {

            let file = taxReturnUploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);

            let filecount = 1;
            while (isFileExist(fileName, this.state.multiFileReturnUploadData.subDocumentsUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, '');
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            taxReturnUploadData[i].name = fileName;
        }

        return taxReturnUploadData;
    }


    private getUploadLink = () => {

        let _self = this;

        let uploadHelperFunctions = new UploadFunctions();

        this.state.multiFileReturnUploadData.subDocumentsUploadData.filter(x => x.status == UploadStatus.Wait).forEach((file: subDocumentUploadData, index: number) => {

            file.status = UploadStatus.Initiating;

            this.props.getUploadLink(`${API_BASE_URL}/api/MultiFileReturnUpload/GetMultiFileReturnUploadLinkAsync?documentGuid=`
                + this.state.multiFileReturnUploadData.sasGuid, (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {
                    try {

                        if (data) {

                            file.subDocumentGuid = data ? data.guid : "";
                            file.status = UploadStatus.Uploading;
                            _self.setState({ multiFileReturnUploadData: _self.state.multiFileReturnUploadData },
                                () => uploadHelperFunctions.uploadFile(file.file, data, file.name ? file.name : "",
                                    _self.uploadProgressCallback, _self.uploadCommittCallBack, _self.uploadFailureCallback));
                        } else {
                            throw new Error("Upload link not found !!");
                        }

                    } catch (error) {
                        _self.state.multiFileReturnUploadData.subDocumentsUploadData.splice(index, 1);
                        _self.setState({ multiFileReturnUploadData: _self.state.multiFileReturnUploadData });
                    }

                });

        });

    }



    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempUploadData = this.state.multiFileReturnUploadData;
        let tempGridData = tempUploadData.subDocumentsUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.subDocumentGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        tempUploadData.subDocumentsUploadData = tempGridData;
        this.setState({
            multiFileReturnUploadData: tempUploadData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        let tempUploadData = this.state.multiFileReturnUploadData;
        if (tempUploadData.subDocumentsUploadData.length > 0) {
            let tempGridData = tempUploadData.subDocumentsUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                }
            });
            this.setState({
                multiFileReturnUploadData: tempUploadData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ submitDisable: false });
                }
            });
        }
    }

    uploadFailureCallback = (fileToUpload: any) => {
        // On failure of retry , Error view and pop up notification need to be discussed with team, and will be implemented in next sprit.
    }

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.multiFileReturnUploadData.subDocumentsUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    //===========================================================================
    // File Upload End 
    //===========================================================================

    render() {
        const uploadDataLength = this.state.multiFileReturnUploadData.subDocumentsUploadData.length;
        const disableLink = NullandEmptyCheck(this.state.multiFileReturnUploadData.groupInfo.name) ? "disable-link" : "";
        return <div>
            < Modal className="upload-doc-modal in" show={this.props.onShow} onHide={this.handleUploaderClose} enforceFocus={false} >
                <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                    <Modal.Title> Upload Documents
                        {/*<br />*/}
                        {/*<div className="uploadpopupinfo">*/}
                        {/*    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{UploadtaxReturnConstants.GroupedReturnNote}</span>*/}
                        {/*</div>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={this.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"FC4527E4-D3B2-47EF-B529-3C56467E4405"}
                            />
                        </div>
                        <div className="col-sm-10 uploaded-documents-table-container">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-xl-4 disp-flex">
                                    <div className="padT07 txt-ar text-bold span-left-float">Client ID <span className='asterisk-error'>*</span></div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 input-left-padding pos_rel">
                                        <FormControl type="text"
                                            className="font-size-14"
                                            disabled={uploadDataLength < 1}
                                            value={this.state.multiFileReturnUploadData.clientId}
                                            onChange={this.onClientIdChange.bind(this)}
                                            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
                                    </div>
                                </div>


                                {/*<div className="col-sm-3 col-md-3 col-xl-3 disp-flex">*/}
                                {/*    <div className="padT07 txt-ar text-bold span-left-float">Group Name</div>*/}

                                {/*    <div className="col-sm-8 col-md-8 col-xl-8 input-left-padding pos_rel">*/}

                                {/*        <DatalistComponent*/}
                                {/*            id='ddlUTUploadGroup'*/}
                                {/*            options={*/}
                                {/*                this.props.groups?.map((option) => {*/}
                                {/*                    return {*/}
                                {/*                        value: option.id,*/}
                                {/*                        label: option.name,*/}
                                {/*                    };*/}
                                {/*                }) ?? []*/}
                                {/*            }*/}
                                {/*            onChange={this.onGroupChange}*/}
                                {/*            selectedValue={this.state.multiFileReturnUploadData.groupInfo.name}*/}
                                {/*            disabled={uploadDataLength < 1}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="col-sm-4 col-md-4 col-xl-4 disp-flex">
                                    <div className="padT07 txt-ar text-bold span-left-float">Set Access</div>

                                    <div className="col-sm-6 col-md-6 col-xl-6 pos_rel">
                                        <NavLink href="javascript:" onClick={this.onSetAccess.bind(this)}
                                            className={disableLink}
                                            style={{ textDecoration: "underline" }}
                                            data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0">
                                            {this.getSetAccessLable()}
                                        </NavLink>
                                    </div>
                                </div>

                            </div>

                            <div className="padT07">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="w40">#</th>
                                            <th>Included Files</th>
                                            <th>Upload Progress</th>
                                            <th>Size</th>
                                            <th>Status</th>
                                            <th className="w40"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadDataLength > 0 ?
                                            this.state.multiFileReturnUploadData.subDocumentsUploadData.map((value) => {
                                                return <tr key={value.number}>
                                                    <th className="txt-ar">{value.number}</th>
                                                    <th>{value.name}</th>
                                                    <th>
                                                        <ProgressBar striped
                                                            variant={(value.progressBar != undefined) && (value.progressBar != 100) ? "warning" : "success"}
                                                            now={value.progressBar} />
                                                    </th>
                                                    <th>{value.size}</th>
                                                    <th>{UploadStatus[value.status].toString()}</th>
                                                    <th>
                                                        <ButtonFormatter deleteReturn={() => this.deleteReturn(value)}
                                                            disabled={(value.progressBar != undefined) && (value.progressBar != 100) ? true : false}
                                                            data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
                                                    </th>
                                                </tr>
                                            }) :
                                            <tr>
                                                <th colSpan={8}>There is no data to display</th>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.handleUploaderClose}
                        data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED">
                        <i className='fas fa-times'></i>
                        Cancel
                    </Button>
                    <Button variant="info" onClick={this.onSubmitTaxreturns}
                        disabled={this.state.submitDisable || uploadDataLength < 1}
                        data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B">
                        <i className='fa fa-check'></i>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal >
            {this.state.showSetAccess &&
                (
                    <SetAccessCommonModal
                        onCancel={this.onSetAccessHide}
                        onApplyAccess={this.onSetAccessSave}
                        availableUsers={this.state.availableUsers}
                        selectedUsers={this.state.selectedUsers}
                        availableUserGroups={this.state.availableUserGroups}
                        selectedUserGroups={this.state.selectedUserGroups}
                        returnAccessSettings={this.props.returnAccessSettings}
                        isDragAndDropDisabled={true}
                        hide={this.onSetAccessHide}
                    />
                )}
        </div >
    }

}