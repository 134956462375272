import { IBookmarkSection, IVoucherPageItem, SEPARATOR } from "../ProcessReturnModels"
import {
    ITaxReturn, DocumentGroups,
    IVoucher, VoucherNo, VoucherTypes, ITaxingAuthority, CustomType, VoucherMode, IFormBase
} from '../../../common/TaxReturn'
import { VoucherTabConstants } from '../../../helper/Constants';
import * as Helper from '../../../helper/HelperFunctions';

export interface IVoucherSort {
    isVouchersEqual(preVouchers: IVoucher[]): boolean;
    getVouchers(): IVoucher[];
    getVoucherBookmark(): IBookmarkSection;
}


export class VoucherSort implements IVoucherSort {
    private voucherBookmark: IBookmarkSection;
    private taxReturn: ITaxReturn;
    private authorities: ITaxingAuthority[];
    private voucherNumberSortOrder: VoucherNo[] = [];
    private vouchers: IVoucher[] = [];

    constructor(taxReturn: ITaxReturn, authorities: ITaxingAuthority[]) {
        this.taxReturn = taxReturn;
        this.authorities = authorities;
        this.voucherBookmark = {
            heading: 'Vouchers',
            pages: [],
        }
        this.voucherNumberSortOrder = [VoucherNo.PaymentVoucher, VoucherNo.Q1, VoucherNo.Q2, VoucherNo.Q3, VoucherNo.Q4, VoucherNo.None];
        this.init();
    }

    private init() {

        const voucherIndex = this.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        this.vouchers = this.taxReturn.formGroups[voucherIndex] ?
            [...this.taxReturn.formGroups[voucherIndex].forms as IVoucher[]] : [];
    }

    public getVouchers(): IVoucher[] {
        return this.vouchers;
    }

    public getVoucherBookmark(): IBookmarkSection {
        this.createVoucherBookmarks();
        return this.voucherBookmark;
    }

    private createVoucherBookmarks() {
        const allAuthorities = this.authorities;

        allAuthorities && allAuthorities.forEach((authority, i) => {
            let authorityVouchers = this.vouchers.filter(x => x.authorityID == authority.Id);
            if (authorityVouchers && authorityVouchers.length > 0) {
                authorityVouchers = this.getVoucherList(authorityVouchers);
                this.addToBookmarks(authorityVouchers);
            }
        });

        const authorities = this.authorities ? this.authorities.map(x => x.Id) : [];

        const unknownVouchers = this.vouchers.filter(x => authorities.indexOf(x.authorityID) == -1);

        if (unknownVouchers && unknownVouchers.length) {
            const unknownaAuthorities = Array.from(new Set(unknownVouchers.map((v) => v.authorityID)));
            unknownaAuthorities.map((authority, i) => {
                let authorityVouchers = unknownVouchers.filter(x => x.authorityID == authority);
                if (authorityVouchers && authorityVouchers.length > 0) {
                    authorityVouchers = this.getVoucherList(authorityVouchers);
                    this.addToBookmarks(authorityVouchers);
                }
            });
        }
    }

    private addToBookmarks(vouchers: IVoucher[]) {
        vouchers.map((v, i) => {
            v.pageNo.map((page, pageIndex) => {
                let formName;
                if (this.voucherBookmark) {
                    if (v.vocherMode === VoucherMode.CustomVoucher) {
                        formName = v.customType == CustomType.None ? v.formName : this.getAddedVoucherFormName(v.authorityID, v.formName);
                    }
                    else
                        formName = v.formName;
                    let pageTitle = v.amount === 0 ? formName + SEPARATOR + '$' + Helper.formatCurency(v.amount) : formName;

                    this.voucherBookmark.pages.push({
                        pageNo: page,
                        pageTitle: pageTitle,
                        icon: VoucherTypes[v.paymentType],
                        displayIconAsImage: true,
                        voucherNo: v.voucherNo,
                        isSignatureRequired: v.signatureEnable &&
                            (!v.signatureControls || v.signatureControls.length == 0),
                        form: v as IFormBase
                    } as IVoucherPageItem);
                }
            });
        });
    }

    public getAddedVoucherFormName = (authorityID: number, formName: string) => {
        for (let i in this.authorities) {
            if (this.authorities[i].Id === authorityID) {
                return this.authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }

    private getVoucherList(vouchers: IVoucher[]): IVoucher[] {
        let voucherList: IVoucher[] = [];

        let paymentVouchers = vouchers.filter(x => x.paymentType == VoucherTypes.PaymentVoucher);
        let otherVouchers = vouchers.filter(x => x.paymentType != VoucherTypes.PaymentVoucher);
        
        //create a multidimention array of other vouchers and percentage of match on bookmark name for each payment voucher
        let otherVouchersBookmarkMatchData = this.CreateBookmarkMatchData(paymentVouchers, otherVouchers);
        if (paymentVouchers) {
            voucherList = paymentVouchers;
            paymentVouchers.map((voucher, pIndex) => {
                const estimatedPaymentVouchers = this.GetEstimatedPaymentVouchers(otherVouchers, pIndex, otherVouchersBookmarkMatchData);
                if (estimatedPaymentVouchers) {
                    voucherList = voucherList.concat(estimatedPaymentVouchers);
                }
            });
        }
        voucherList.map((voucher, index) => {
            const vIndex = otherVouchers.indexOf(voucher);
            if (vIndex != -1) {
                otherVouchers.splice(vIndex, 1);
            }
        });

        this.voucherNumberSortOrder.map((vnum) => {
            let voucherGroup = otherVouchers.filter(x => x.voucherNo == vnum);
            if (voucherGroup) {
                voucherList = voucherList.concat(voucherGroup);
            }
        });

        return voucherList;
    }

    private CreateBookmarkMatchData(paymentVouchers: IVoucher[], otherVouchers: IVoucher[]): any {

        let matchData: number[][] = [];
        otherVouchers.map((eVoucher, eIndex) => {
            matchData[eIndex] = [];
            paymentVouchers.map((pVoucher, pIndex) => {
                matchData[eIndex][pIndex] = this.matchBookmark(pVoucher.formName, eVoucher.formName);
            });

        });

        return matchData;
    }


    private GetEstimatedPaymentVouchers(otherVouchers: IVoucher[], pIndex: number, otherVouchersBookmarkMatchData: number[][]): IVoucher[] {

        let estimatedPaymentVouchers: IVoucher[] = [];
        otherVouchers.map((voucher, eIndex) => {
            const maxMatchValue = Math.max(...otherVouchersBookmarkMatchData[eIndex]);
            if (maxMatchValue > -1 && otherVouchersBookmarkMatchData[eIndex].findIndex(x => x == maxMatchValue) == pIndex) {
                estimatedPaymentVouchers.push(voucher);
            }
        });

        //sort the identified estimated payment voucher based on voucher num
        let sortedVouchers: IVoucher[] = [];
        this.voucherNumberSortOrder.map((vnum) => {
            let voucherGroup = estimatedPaymentVouchers.filter(x => x.voucherNo == vnum);
            if (voucherGroup) {
                sortedVouchers = sortedVouchers.concat(voucherGroup);
            }
        });
        return sortedVouchers;
    }

    private matchBookmark(bookmark1: string, bookmark2: string) {

        bookmark1 = bookmark1.trim();
        bookmark2 = bookmark2.trim();

        const bookmark1Words = bookmark1.split(/[ ]|-/).filter(x => x != "");
        const bookmark2Words = bookmark2.split(/[ ]|-/).filter(x => x != "");
        const minLength = Math.min(bookmark1Words.length, bookmark2Words.length);
        let wordMatch = 0;
        for (let i = 0; i < minLength; i++) {
            if (bookmark1Words[i].toLowerCase() != bookmark2Words[i].toLowerCase()) {
                break;
            }
            wordMatch++;
        }

        if (wordMatch < VoucherTabConstants.VoucherSortBookmarkWordMatchCount) {
            return -1;
        }
        const startCharMatchWordIndex = VoucherTabConstants.VoucherSortBookmarkWordMatchCount;
        let points = 0;
        for (let i = startCharMatchWordIndex; i < minLength; i++) {
            if (bookmark1Words[i].toLowerCase() == bookmark2Words[i].toLowerCase()) {
                points += bookmark1Words[i].length;
            } else {
                for (let j = 0; j < Math.min(bookmark1Words[i].length, bookmark2Words[i].length); j++) {
                    if (bookmark1Words[i][j].toLowerCase() != bookmark2Words[i][j]) {
                        break;
                    }
                    points++;
                }
            }
        }

        return points;
    }

    public isVouchersEqual(preVouchers: IVoucher[]): boolean {
        if (preVouchers) {
            const voucherIndex = this.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (!this.taxReturn.formGroups[voucherIndex]) {
                return true;
            }
            const currentVouchers = this.taxReturn.formGroups[voucherIndex].forms as IVoucher[];

            if (currentVouchers.length != preVouchers.length) {
                return false;
            }

            return preVouchers.every((v) => {
                return currentVouchers
                    .findIndex(x => x.bookmark === v.bookmark &&
                        x.authorityID === v.authorityID &&
                        x.pageNo === v.pageNo &&
                        x.paymentType === v.paymentType &&
                        x.voucherNo === v.voucherNo) !== -1;
            });
        } else {
            return false;
        }
    }
}