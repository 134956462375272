import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { ITaxReturn, EngagementType, IVoucher } from '../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { Guid } from '../../Core/Utilities/Guid';
import * as Constants from '../../../src/components/helper/Constants';
import { K1Instruction } from '../common/TaxDocumentStore';
import { TaxReturnUploadData } from '../../Core/ViewModels/Common/UploadDocumentViewModel';
import { RequestTaxDocumentAction } from './TaxDocumentStore';
import { API_BASE_URL } from 'src/utils/contants';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';

export interface IUploadState { }

type KnownAction = NotificationAction | K1Instruction | RequestTaxDocumentAction;

export const actionCreators = {
    uploadK1Instruction: (engagementType: EngagementType, documentGuid: string, taxYear: number, file: any, documentId: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Upload/GetK1UploadLinkAsync?engagementType=` + engagementType.toString() + "&documentKey=" +
            documentGuid + '&taxYear=' + taxYear, { credentials: 'include' })
            .then((resp) => resp.json())
            .then(function (data) { return data; })
            .then(url => {
                const formData = new FormData()
                formData.append('myFile', file)
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        "x-ms-blob-type": "BlockBlob",
                        'x-ms-meta-filename': file.name,
                    },
                    body: formData
                }).then(response => {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.K1TabConstants.K1UploadConfirmation, statusType: StatusType.Success
                    });
                    dispatch({ type: actionTypes.K1_INSTRUCTION_DETAIL, fileName: file.name, id: documentId });
                    if (callback)
                        callback();
                });
            });
    },

    requestVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const resourceId = getProcessResourceId();
            let fileGuid = Guid.newGuid().toString();
            const fetchTask = fetch(`${API_BASE_URL}api/ProcessReturn/GetUploadSASUrlAsync/?documentGuid=` +
				taxReturn.documentGuid + '&fileName=' + escape(fileGuid) + '&taxYear=' +
                taxReturn.taxYear + '&uploadType=' + "voucher", 
                { 
                    credentials: 'include',
                    headers : { 
                        'X-Resource-Id': resourceId
                     }
                })
                .then(handleResponse)
                .then(data => {
                    data.fileGuid = fileGuid;
                    callback(data);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        },

    requestMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const resourceId = getProcessResourceId();
            let fileGuid = Guid.newGuid().toString();
            const fetchTask = fetch(`${API_BASE_URL}api/ProcessReturn/GetUploadSASUrlAsync/?documentGuid=` +
                taxReturn.documentGuid + '&fileName=' + escape(fileGuid) + '&taxYear=' +
                taxReturn.taxYear + '&uploadType=' + "voucher", 
                { 
                    credentials: 'include',
                    headers : { 
                        'X-Resource-Id': resourceId
                     }
                })
                .then(handleResponse)
                .then(data => {
                    data.fileGuid = fileGuid;
                    callback(data, cbVoucher);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        },

    submitRecallTaxReturn: (docId: number, uploadData: TaxReturnUploadData[], onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(uploadData),
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
            }
        };

        const fetchTask = fetch(`${API_BASE_URL}/api/Upload/SubmitRecallTaxReturnsAync`, options)
            .then(response => {
                dispatch({ type: actionTypes.REQUEST_TAX_DOCUMENT, id: docId });
                if (onResponse) {
                    onResponse(response, null);
                }
            }).catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
            });
    }
}

const unloadedState: IUploadState = {} as IUploadState;

export const reducer: Reducer<IUploadState> = (state: IUploadState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    return state || unloadedState;
};