let moment = require('moment');
import React from  'react';
import {
	BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
	DateFilter, FilterComparator, SelectFilterOptionsType, CustomSelectProps,
	ToolBarProps, CustomFilterParameters, Options
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ITaxReturn, SignatureStatus } from '../../../components/common/TaxReturn';
import { getClientName, DocumentStatus, EngagementType } from '../../common/TaxReturn';
import * as Helper from '../../helper/HelperFunctions';
import * as Constants from '../../../components/helper/Constants';
import { CheckBoxComponent, CheckBoxSize } from '../../common/CheckBoxComponent';
import { SignatureStatusList } from '../../reports/DeliveredReturns/DeliveredReturnsTable';

export interface RecycleTaxReturnExtensionsTableProps {
	isLoading: boolean;
	taxReturns: ITaxReturn[];
	onDocumentSelect: (Ids: number[]) => void;
	onDocumentUnSelect: (Ids: number[]) => void;
	selectedDocumentsId: number[];
	groupId: number;
	pageSize: number;
}

export class RecycleTaxReturnExtensionsTable extends React.Component<RecycleTaxReturnExtensionsTableProps, {}> {

	renderShowsTotal = (start: number, to: number, total: number) => {
		return <p>
			Showing <b>{start}</b> to <b>{to}</b> of <b>{total}</b> entries
		</p>
	}

	private defaultType = (cell: any, row: any) => {
		return <div title={cell} className="ellipsis">{cell}</div>;
	}

	private setNoContent = () => {
		if (this.props.isLoading) {
			return (<LoadingOverlay style={{ height: '400px' }}>
				<Loader loading={this.props.isLoading} />
			</LoadingOverlay>)
		} else {
			return "No assignments found"
		}
	}

	private getEngagementTypeName = (engagementType: EngagementType) => {
		return (engagementType.toString() == EngagementType[EngagementType.None] ? engagementType.toString() : engagementType.toString().slice(1, 10));
	}

	private signatureStatusIdType = (cell: any, row: any) => {


		let status: any = row.signatureStatus;
		let signatureType: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload" | "Locked" |
			"MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering" | "Delivered" |
			"DeliveryFailed" = (typeof status == "number") ? SignatureStatus[status] : status;
		return <span>{SignatureStatusList[signatureType].toUpperCase()}</span>;
	}

	private customStatusTooltip = (cell: any, row: any) => {
		const status: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload" | "Locked" | "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering" | "Delivered" | "DeliveryFailed" = (typeof row.signatureStatus === "number") ? SignatureStatus[row.signatureStatus] : row.signatureStatus;
		return `${SignatureStatusList[status]}`;
	}

	private onRowSelect = (row: any, isSelected: any, e: any) => {

		if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
			&& e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
			if (isSelected) {
				this.props.onDocumentSelect([row.index]);
			} else {
				this.props.onDocumentUnSelect([row.index]);
			}
		}
		this.forceUpdate();
	}

	private onSelectAll = (isSelected: boolean, rows: any, e: any) => {
		let selectedDocuments: number[] = [];

		rows.forEach((value: any, index: number) => {
			selectedDocuments.push(value.index);
		});

		if (isSelected) {
			this.props.onDocumentSelect(selectedDocuments);
		} else {
			this.props.onDocumentUnSelect(selectedDocuments);
		}
	}

	createCustomCheckbox = (props: CustomSelectProps): any => {

		const taxDocument = this.props.taxReturns[props.rowIndex as number];

		return (<CheckBoxComponent size={CheckBoxSize.sm}
			id={"assignment-checkbox-" + (taxDocument ? taxDocument.id : (props.rowIndex + "_" + this.props.groupId))}
			indeterminate={props.indeterminate}
			checked={props.checked}
			disabled={props.disabled}
			onChange={(e: any) => props.onChange(e, props.rowIndex)}
			ref={(input: any) => {
				if (input) {
					input.indeterminate = props.indeterminate;
				}
			}}
			text={""}
			className="" />);
	}

	public render() {
		let pagingationPos: "both" | "top" | "bottom" | undefined = "bottom";
		const options = {
			sizePerPage: this.props.pageSize,
			paginationShowsTotal: this.renderShowsTotal,
			noDataText: this.setNoContent(),
			paginationPosition: pagingationPos, // default is bottom, top and both is all available
			nextPage: <span className="fa fa-angle-right" />,
			prePage: <span className="fa fa-angle-left" />,
			firstPage: <span className="fa fa-angle-double-left" />,
			lastPage: <span className="fa fa-angle-double-right" />,
			clearSearch: true
		};

		let unselectable: number[] = [];
		const data = this.props.taxReturns.map((model, index) => {
			return {
				taxDocumentName: getClientName(model),
				clientId: model.clientId,
				partner: model.partner.firstName + ' ' + model.partner.lastName,
				assignToUserName: model.assignedUser.userId == 0 ? '' : model.assignedUser.firstName + ' ' + model.assignedUser.lastName,
				documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
				signatureStatus: model.signatureStatus,
				documentStatusEnum: model.documentStatus,
				uploadedOn: moment(model.uploadedOn).format('MM/DD/YYYY'),
				uploadedOnDate: model.uploadedOn,
				engagementType: this.getEngagementTypeName(model.engagementType),
				taxYear: model.taxYear,
				assignTo: model.assignTo,
				button: 'Actions',
				taxReturns: this.props.taxReturns,
				index: model.id,
				rowIndex: index
			};
		});

		const columns = [
			{
				header: '',
				key: 'index',
				isKey: true,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: true,
				width: 'auto',

			},
			{
				header: 'Name',
				key: 'taxDocumentName',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'Client ID',
				key: 'clientId',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'ERO / Signer',
				key: 'partner',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'Assigned To',
				key: 'assignToUserName',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'Status',
				key: 'documentStatus',
				isKey: false,
				dataFormat: this.signatureStatusIdType,
				columnClassName: '',
				dataSort: true,
				toolTip: this.customStatusTooltip,
				isHidden: false,
				width: 'auto',

			},
			{
				header: 'Date',
				key: 'uploadedOn',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'Type',
				key: 'engagementType',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
			},
			{
				header: 'Tax Year',
				key: 'taxYear',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: true,
				isHidden: false,
				width: 'auto',
			}
		];

		const selectRowProp: any = {
			mode: 'checkbox',
			clickToSelect: true,
			onSelect: this.onRowSelect,
			onSelectAll: this.onSelectAll,
			unselectable: unselectable,
			selected: this.props.selectedDocumentsId,
			customComponent: this.createCustomCheckbox,
			className: 'row-selected'
		};


		return <div className="pos-relative">
			<div data-test-auto="490D0687-0267-4CC1-97BB-58D9BE65B18F">
				<BootstrapTable
					ref='table'
					data={data}
					remote={false}
					fetchInfo={{ dataTotalSize: this.props.taxReturns.length }}
					options={options}
					striped
					hover={true}
					pagination={this.props.pageSize < this.props.taxReturns.length}
					selectRow={selectRowProp}
					search={false}
				>
					{columns.map((value, index) => {
						return <TableHeaderColumn
							key={index}
							ref={value.key}
							isKey={value.isKey}
							dataField={value.key}
							hidden={value.isHidden}
							width={value.width}
							dataFormat={value.dataFormat}
							columnClassName={value.columnClassName}
							columnTitle={value.toolTip}
							dataSort={value.dataSort}
						>{value.header}
						</TableHeaderColumn>;
					})}
				</BootstrapTable>
			</div>
		</div>
	}
}
