import { IClientInfoComparisonResult } from 'src/components/common/MisMatchModal/MismatchModal.model';
import { IDeliveredTaxDocument, IVoucherStatus } from '../../components/common/DeliveredTaxReturns';
import { IClientInfoViewModel, ISignerModel, ITaxReturn, initialTaxReturnState } from '../../components/common/TaxReturn';
import { IFilters } from '../../components/reports/Filters';
import { BulkOperationQueryExt } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { DefaultDownloadInfoType } from '../userManagement/ExtensionUserSettingStore';
import { IColumnSettings } from './ColumnSettings/ColumnSettingsState';
import { IUserDataFromDB, IUserGroupDataFromDB } from 'src/components/common/SetAccess/SetAccessCommonModal.model';

export interface ISignatureStatusList {
    value: number;
    name: string;
}

export interface DeliveredReturnsTableModel {
    count: number,
    documents: IDeliveredTaxDocument[]
}

export enum ReturnStatusList {
    None = 0,
    Esigned,
    ManuallySigned,
    Uploaded,
    AwaitingEsign,
    AwaitingUpload,
    Locked,
    MailFax,
    SignedESigned,

    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed
}

export interface DeliveredReturnsState {
    deliveredReturnTableModel: DeliveredReturnsTableModel;
    query: string;
    totalRowCount: number;

    loading: boolean;
}
export interface RowState {
    showClientInfo: boolean;
    docId: number;
}
export interface DeliveredReturnsPageState {

    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: IFilters;
    searchString: string;
    sortName: string;
    sortOrder: string;
    filterTaxYear: any;
    saveFilterShow: boolean,
    filterName: string;
    filterClientId: string;
    filterPartner: string;
    filterStatus: any;
    filterLocation: any;
    filterDeliveredOn?: Date;
    filterEngagementType: number;
    filterCustomColumn: string;
    filterDownloadedBy: string;
    filterLastReminderOn?: Date;
    filterRetentionPeriod?: Date;
    filterSentBy: string;
    hideCustomCulumnLoader: boolean;
    selectedStatus: number;
    signatureStatusList: ISignatureStatusList[],
    selectedColumnValue: number;
    isBulkSelectionEnabled: boolean;
    showBulkSelectionMessage: boolean;
    deSelectedRows: number[];
    columnSettings: IColumnSettings[];
    isSelectAllChecked: boolean;
    screenShareOtp: string;
    filterReturnStatus: string;

    //Popups
    editClientInfoState: IPopupStateSingle;
    setAccessState: IPopupStateMultiple;
    reportProblemState: IPopupStateSingle;
    deleteReturnsState: IPopupStateSingle;
    archiveReturnsState: IPopupStateMultiple;
    changeStatusState: IPopupStateMultiple;
    customColumnState: IPopupStateMultiple;
    sendReminderState: IPopupStateMultiple;
    resendAccessLinkState: IPopupStateSingle;
    clientTrackingState: IPopupStateSingle;
    recallReturnState: IPopupStateSingle;
    reprocessReturnState: IPopupStateSingle;
    voucherReminderState: IPopupStateSingle;
    accessCodeState: IPopupStateSingle;
    retentionPolicyState: IPopupStateSingle;
    downloadReturnState: IPopupStateSingle;
    downloadEformFilesState: IPopupStateSingle;
    uploadFormsState: IPopupStateSingle;
    signedDetailsState: IPopupStateSingle;
    downloadHistoryState: IPopupStateSingle;
    clientViewState: IPopupStateSingle;
    showLoader: boolean;
    restoreReturnsState: IPopupStateMultiple;
    screenShareState: IPopupStateSingle;
    refreshDelay?: boolean;
    defaultGroup: string;

    downloadTypeOptionState: IPopupStateMultiple;

    selectedDownloadType: DefaultDownloadInfoType;
    /*showDownloadTypeOption: boolean;*/
    bulkOpnQuery: BulkOperationQueryExt;
    loadSpinner: boolean,
    selectedDefaultDownloadType: boolean
    selectedDocuments: IDeliveredTaxDocument[],
    isFromGrid?: boolean;
    selectedVoucherStatus?: IVoucherStatus;

    showMismatchModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    popupInProgress: boolean;
    clientInfo: IClientInfoViewModel;
    clientBasicInfoId: number;
    editClientSaveState: IEditClientSaveState;

    showLocationMismatchActionModel: boolean;
    loadingMessage: boolean;

    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export interface IEditClientSaveState {
    id: number;
    taxReturn: ITaxReturn;
    isMailSend: boolean;
    clientGUIDs: string[];
    isEmailUpdated: boolean;
    callback?:()=> void;
}

export const intialEditClientSaveState: IEditClientSaveState = {
    id: 0,
    taxReturn: initialTaxReturnState,
    isEmailUpdated: false,
    isMailSend: false,
    clientGUIDs: []
}

export interface IDeliveredReturnsDictionary {
    [index: string]: DeliveredReturnsState;
}

export interface IPopupState {
    show: boolean

}
export interface IPopupStateSingle extends IPopupState {
    model: ITaxReturn | undefined,
    saveCheckId: number | undefined
}
export interface IPopupStateMultiple extends IPopupState {
    selected: ITaxReturn[] | undefined,
    saveCheckIds: number[] | undefined
    remove: boolean
}

export interface ISelectedDocument {
    id: number;
    isfullyLoaded: boolean
}

