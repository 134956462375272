import { handleResponse } from "../../store/Library";
import { API_BASE_URL } from "../../utils/contants";
import { ClientInfoWithEroCheck, IClientInfoViewModel } from "../../components/common/TaxReturn";
import { IClientInfoComparisonResult, IClientInfoMismatchType } from "../../components/common/MisMatchModal/MismatchModal.model";
import { logger } from "src/components/helper/LoggerHelper";
import { addTask } from "domain-task";

export const compareClientInfo = (
    clientInfo: IClientInfoViewModel,
    resourceId: string,
    setResult: (result: IClientInfoComparisonResult) => void,
    noDataMismatchCallback: () => void,
    newClientCallback: (clientInfo: IClientInfoViewModel) => void,
    setClientBasicInfoId: (id: number) => void
) => {
    fetch(API_BASE_URL + "/api/ClientManagement/CompareAsync", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            'Accept': "application/json, */*",
            'Content-Type': "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: IClientInfoComparisonResult) => {
            if (response?.comparisonStatus === IClientInfoMismatchType.NoDataMismatch) {
                noDataMismatchCallback();
            } else if (response?.comparisonStatus === IClientInfoMismatchType.NewClient) {
                newClientCallback(clientInfo);
            } else {
                setResult(response);
                getClientInfoByClientId(clientInfo.clientId, clientInfo.uid, resourceId, (resp: ClientInfoWithEroCheck) => {
                    setClientBasicInfoId(resp.clientInfo.clientBasicInfoId);
                });
            }
        })
        .catch((error: any) => {
            logger.trackError(`compareClientInfo failed with error ${error.message}`)
        });
};

export const editClientInfo = (clientInfo: IClientInfoViewModel, resourceId: string, callback: () => void) => {
    fetch(API_BASE_URL + "/api/ClientManagement", {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            'Accept': "application/json, */*",
            'Content-Type': "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback();
        })
        .catch((error: any) => {
            logger.trackError(`editClientInfo failed with error ${error.message}`)
        });
};

export const addNewClientInfo = (clientInfo: IClientInfoViewModel, resourceId: string, callback: () => void) => {
    fetch(API_BASE_URL + "/api/ClientManagement", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            'Accept': "application/json, */*",
            'Content-Type': "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback();
        })
        .catch((error: any) => {
            logger.trackError(`addNewClientInfo failed with error ${error.message}`)
        });
};

export function getClientInfoByClientId(clientId: string, uid: string = "", resourceId: string, callback: (clientInfoViewModel: IClientInfoViewModelExtended) => void) {
    const fetchTask = fetch(API_BASE_URL + `/api/ClientManagement/GetClientInfoByClientIdAsync?clientId=${clientId}&ssn=${uid}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json, */*',
            'Content-Type': 'application/json',
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback(response);
        })
        .catch((error: any) => {
            logger.trackError(`getClientInfoByClientId failed with error ${error.message}`)
        });
    addTask(fetchTask);
}
