import React from 'react';
import * as  ReactDOM from 'react-dom';
import "react-bootstrap-table/css/react-bootstrap-table.css";

interface GlobalSearchFieldState {
    value: string;
}

export class GlobalSearchField extends React.Component<any, GlobalSearchFieldState> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: props.defaultValue
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            this.setState({ value: nextProps.defaultValue });
            this.setValue(nextProps.defaultValue);
        }
    }

    getValue() {
        return this.state.value;
    }

    setValue(value: any) {
        (ReactDOM.findDOMNode(this) as HTMLInputElement).value = value;
    }

    handleKeyUp = (event: any) => {
        this.setState({ value: event.target.value });
        this.props.search(event);
    }

    render() {
        return (
            <input
                data-testid='global-search-input'
                className='form-control'
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onKeyUp={this.handleKeyUp} />
        );
    }
}