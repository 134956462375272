import { log } from 'console';
import * as React from 'react';
import Select from 'react-select';

interface DropdownData {
    value: number;
    label: string;
}

export class CustomMultiSelect extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedOptions: (this.props.selectedOptions != undefined ? this.props.selectedOptions:(new Date().getFullYear() - 1).toString()),
            options: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.setOptions(this.props.options);
    }
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.options != this.props.options) {

            this.setOptions(nextProps.options);
        }
    }
    public setOptions = (options: any[]) => {
        var dropDownData: any = [];
        var data = { value: 0, label: "All" }
        if (this.props.enableAllFilter) {
            dropDownData.push(data);
        }
        options?.map((data: any) => {
            dropDownData.push(data);
        });
        this.setState({ options: dropDownData });
    }

    public cleanFiltered = () => {
        this.props.filterHandler("-1"); 
        this.setState({
            selectedOptions: []
        });
    }

    public applyFilter = (value: any) => {
        this.props.filterHandler(this.state.selectedOptions)
        this.setState({
            selectedOptions: value
            })
        }

    private handleAllSelected = (value: any): string => {
        var checkedValues = value.split(',');

        if (this.props.enableAllFilter) {
            if (checkedValues.includes('0')) {
                checkedValues = this.selectAll();
            }
            else if (this.state.selectedOptions.length > 0) {
                if (this.state.selectedOptions.split(',').includes('0'))
                    checkedValues = "";
                else if (checkedValues.length == this.props.options.length) {
                    checkedValues = this.selectAll();
                }
            }

        }
        return checkedValues.toString();
    }

    private selectAll = (): string => {
        var all = "0";
        var output: string = this.props.options.reduce((output: string, option: any) => {
            output = output + "," + option.value;
            return output
        }, "")
        output = all + output;
        return output;
    }

    private handleSelectChange(value: any) {
        var selectedValue = this.handleAllSelected(value);
        this.setState({ selectedOptions: selectedValue }, () => {
            if (!this.state.selectedOptions)
                this.props.filterHandler("-1");//if the dropdrop is cleared let the table know something has triggered,table filter will not trigger if the value is empty
            else
                this.props.filterHandler(this.state.selectedOptions);
        });

    }



    public disableOptions(option: any): boolean {
        var isDisabled: boolean = false;
        if (this.state.selectedOptions.length > 0) {
            if (this.state.selectedOptions.split(',').includes('0') && option != "All") {
                isDisabled = true;
            }

        }
        return isDisabled;
    }

    private createCustomOption = (props: any) => {
        return (
            <div style={{ paddingLeft: '5px', verticalAlign: 'middle' }} onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onSelect(props.option, e);
            }}
                onMouseEnter={(e) => { props.onFocus(props.option, e); }}
                onMouseMove={(e) => {
                    if (props.isFocused) return;
                    props.onFocus(props.option, e);
                }}>
                <input type="checkbox" checked={(props.isSelected) ? true : false} disabled={this.disableOptions(props.children)} />
                <span title={props.children} className={this.props.optionClassName?this.props.optionClassName:"custom-multiselect-status"}>{props.children}</span>
            </div>
        )
    }
    private createCustomTag = (props: any) => {
        const { selectedOptions } = this.state;
        let nbValues = (selectedOptions).split(',').includes('0') ? 'All' : (selectedOptions).split(',').length;
        return (
            <span>
                {`[${nbValues}]`}
            </span>
        );
    }
    getClassName=()=>{
        if(this.props.className)
        {            
            return this.props.className;
        }
        else if(this.props.enableAllFilter){
            return "filter select-filter multi-select-width100"
        }
        else{
            return "filter select-filter multi-select-widthauto"
        }
    }

    render() {
        return (
            <Select ref='OptionsSelect'
                className={this.getClassName()}
                options={this.state.options}
                onChange={(e: any) => { this.handleSelectChange(e); }}
                value={this.state.selectedOptions}
                optionComponent={this.createCustomOption}
                valueComponent={this.createCustomTag}
                removeSelected={false}
                multi
                simpleValue
                searchable={false}           
                backspaceRemoves={false}
                placeholder={<span className="filter select-filter placeholder-selected">{this.props.placeholder}</span>}
            />
        )
    }
}
