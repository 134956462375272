import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { fullName } from '../helper/HelperFunctions';
var moment = require('moment-timezone');

export function PrepareUserList(userList: IUserModel[]) {
    var userOptionArray: any = [];
    userOptionArray.push({
        value: 0,
        label: "Select"
    })
    userList.map((user: any) =>
        userOptionArray.push({
            value: user.id,
            label: fullName(user)
        }));

    return userOptionArray;
}


export function DocumentTypeExpirationList() {
    var documentTypeExpirationArray: any = [];
    documentTypeExpirationArray.push({
        value: 0,
        label: "Select"
    })
    documentTypeExpirationArray.push({
        value: "6",
        label: "6 Months"
    });
    documentTypeExpirationArray.push({
        value: "12",
        label: "1 Year"
    });
    documentTypeExpirationArray.push({
        value: "24",
        label: "2 Years"
    });
    documentTypeExpirationArray.push({
        value: "36",
        label: "3 Years"
    });
    documentTypeExpirationArray.push({
        value: "84",
        label: "7 Years"
    });

    return documentTypeExpirationArray;
}

export function getUserTimeZone() {
    return encodeURIComponent(moment.tz.guess(true));
}