import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import {
    ITaxReturn, TaxCaddyLookupResultModel, initialTaxCaddyDeliveryViewModel, TaxCaddyLookupResult, EngagementType,
    IDocumentAccess, IDocumentTransaction, ISignerModel, IAccessCode, ClientTypes, IDownloadableDocuments, DocumentEvent,
    DocumentGroups, DocumentStatus, DeliveryMode, initialTaxCaddyLookupResultModel, updateTaxFormType, IAccessCodeViewModel, IDocumentAccessSaveModel
} from '../../components/common/TaxReturn';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { actionTypes } from '../ActionTypes';
import { handleResponse, extractDocumentIds, handleBlob, handleBlobwithFileName } from '../Library';
import { IColumnValues } from '../../components/settings/GeneralSettings';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import * as DeliveredReturnsStore from 'src/store/reports/DeliveredReturnsStore';
import * as Notification from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
import { getCurrentDate } from '../../components/helper/HelperFunctions';
import { ISubDocument, SubDocType } from '../../Core/Utilities/PdfDocumentFacade';
import { processReturnActionEndPoints } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import { ResetPdfAction } from '../pdf/PdfStore';
import { HideLoader } from '../../components/helper/Loader';
import { IDocumentData } from '../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { IReportProblemDetails } from '../../components/common/ReportProblem/ReportProblemModel';
import { getUserTimeZone } from '../../components/helper/UserHelperFunctions';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { validator } from './RBACStore';
import { getProcessResourceId, RBACKeys } from 'src/components/helper/RbacConstants';
import { IScheduleReminderPayload } from 'src/components/common/DeliveredTaxReturns';
import { VenusNotifier } from 'src/components/helper/VenusNotifier';


export enum TaxReturnSource {
    TaxDocument,
    UserAssignments,
    CompanyAssignments,
    DeliveredReturns,
    InUseExtensions
}

export interface ResetTaxReturnsAction {
    type: actionTypes.RESET_TAX_RETURNS;
}

export interface ResetDeliveredReturnsAction {
    type: actionTypes.RESET_DELIVERED_RETURNS;
}

export interface RequestTaxDocumentAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT;
    id: number;
}

export interface ReceiveTaxDocumentAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
    source: TaxReturnSource;
    isEditClientInfoRequest?: boolean;
}

export interface ReceiveTaxDocumentsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENTS;
    taxDocuments: ITaxReturn[];
    source: TaxReturnSource;
}

export interface UpdateTaxDocumentAction {
    type: actionTypes.UPDATE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
}

export interface SaveTaxDocumentAction {
    type: actionTypes.UPDATE_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
}

export interface DeleteTaxDocumentAction {
    type: actionTypes.DELETE_TAX_DOCUMENT;
    ids: number[];
}

export interface DeleteDeliveredTaxDocumentAction {
    type: actionTypes.DELETE_DELIVERED_TAX_DOCUMENT;
    ids: number[];
}

export interface ArchiveTaxDocumentAction {
    type: actionTypes.ARCHIVE_TAX_DOCUMENT;
    ids: number[];
}

export interface RecallTaxDocumentAction {
    type: actionTypes.RECALL_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface AssignTaxDocumentAction {
    type: actionTypes.ASSIGN_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface ChangeStatusTaxDocumentAction {
    type: actionTypes.ASSIGN_TAX_DOCUMENT;
    taxDocument: ITaxReturn;
}

export interface RequestAssignUserAction {
    type: actionTypes.REQUEST_ASSIGN_USER;
    id: number;
}

export interface ReceiveAssignUserAction {
    type: actionTypes.RECEIVE_ASSIGN_USER;
    id: number;
    taxDocument: ITaxReturn;
}

export interface RequestDocumentAccessAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_ACCESS;
    ids: number[];
}



export interface ErrorTaxDocumentAction {
    type: actionTypes.ERROR_TAX_DOCUMENT;
    ids: number[],
    message: string
}

export interface RequestClientTrackingAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number;
}

export interface ReceiveClientTrackingAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING;
    id: number,
    clientTracking: IDocumentTransaction[];
}

export interface UpdateTaxDocumentCustomColumnValueAction {
    type: actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE;
    id: number,
    customColumn: string;
}

export interface SendReminderTaxDocumentAction {
    type: actionTypes.SEND_REMINDER_TAX_DOCUMENT;
    id: number;
    isScheduled?: boolean;
}

export interface RequestSignedDetailsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number;
}

export interface ReceiveSignedDetailsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS;
    id: number,
    signedDetails: ISignerModel[];
}

export interface CheckSSRExtensionDocumentExistAction {
    type: actionTypes.CHECK_SSR_EXTENSION_DOCUMENT_EXIST;
    id: number,
    isSSRExtensionDocument: boolean;
}

export interface ReceiveDocumentStatusAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_STATUS,
    id: number;
    documentStatus: DocumentStatus;
}

export interface CheckTaxDocumentExistAction {
    type: actionTypes.CHECK_TAXDOCUMENT_EXIST,
    id: number;
    engatementType: EngagementType;
    taxyear: number;
    result: boolean;
}

export interface RequestAccessCodeAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_ACCESS_CODE;
    id: number;
}

export interface ReceiveAccessCodeAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE;
    id: number,
    accessCode: IAccessCodeViewModel;
}

export interface RequestDownloadableDocumentsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number;
}

export interface ReceiveDownloadableDocumentsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS;
    id: number,
    downloadableDocuments: IDownloadableDocuments[];
}

export interface RequestDownloadableEfileFormsAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number;
}

export interface ReceiveDownloadableEfileFormsAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS;
    id: number,
    downloadableEfileForms: IDownloadableDocuments[];
}

export interface GenerateOTPAction {
    type: actionTypes.GENERATE_OTP_TAX_DOCUMENT;
    id: number,
    clientType: ClientTypes,
    clientGUID: string,
    otp: string,
    createdOn: Date
}

export interface ClearAccessCodeAction {
    type: actionTypes.CLEAR_OTP_TAX_DOCUMENT;
    id: number,
}

export interface RequestDownloadHistoryAction {
    type: actionTypes.REQUEST_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number;
}

export interface ReceiveDownloadHistoryAction {
    type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY;
    id: number,
    downloadHistory: IDocumentTransaction[];
}

export interface MakeAvailableTaxDocumentAction {
    type: actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT;
    ids: number[];
}

export interface SendToEROAction {
    type: actionTypes.SEND_TO_ERO_TAX_DOCUMENT
}

export interface SendForReviewAction {
    type: actionTypes.SEND_FOR_REVIEW_TAX_DOCUMENT
}

export interface DeliverToClientAction {
    type: actionTypes.DELIVER_TAX_DOCUMENT
}

export interface ApproveForDeliveryAction {
    type: actionTypes.APPROVE_FOR_DELIVERY_TAX_DOCUMENT
}

export interface K1Instruction {
    type: actionTypes.K1_INSTRUCTION_DETAIL;
    id: number;
    fileName: string;
}

export interface IProcessReturnViewModel {
    taxDocument: ITaxReturn;
    parts: ISubDocument[];
    taxCaddyDeliveryViewModel?: TaxCaddyLookupResult;
    isK1Replaced: boolean;
    isK1Restored: boolean;
    isMFJChanged: boolean;
    clientType?: ClientTypes
}

export interface IProcessReturnSubDocument {
    path: string;
    pageMap: number[];
}

export interface GetTaxCaddyLookupDetails {
    type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS;
    taxcaddyLookupDetails: TaxCaddyLookupResultModel;
    id: number;
}

export interface ClearTaxCaddyLookupDetails {
    type: actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS;
    id: number;
}

export interface ResetTaxDocument {
    type: actionTypes.RESET_TAX_DOCUMENT;
    id: number
}

export interface UpdateDocumentInitialStatus {
    type: actionTypes.UPDATE_DOCUMENT_INITIAL_STATUS
}

export interface NotifyTaxDocument {
    type: actionTypes.NOTIFY_TAX_DOCUMENT
}

export interface RestoreArchivedTaxDocumentAction {
    type: actionTypes.RESTORE_ARCHIVE_TAX_DOCUMENT;
    ids: number[];
}

type KnownAction =
    RequestTaxDocumentAction |
    ReceiveTaxDocumentAction |
    UpdateTaxDocumentAction |
    SaveTaxDocumentAction |
    DeleteTaxDocumentAction |
    DeleteDeliveredTaxDocumentAction |
    ArchiveTaxDocumentAction |
    RestoreArchivedTaxDocumentAction |
    RecallTaxDocumentAction |
    AssignTaxDocumentAction |
    ChangeStatusTaxDocumentAction |
    RequestAssignUserAction |
    ReceiveAssignUserAction |
    RequestDocumentAccessAction |
    ErrorTaxDocumentAction |
    RequestClientTrackingAction |
    ReceiveClientTrackingAction |
    RequestDownloadHistoryAction |
    ReceiveDownloadHistoryAction |
    RequestAccessCodeAction |
    ReceiveAccessCodeAction |
    GenerateOTPAction |
    ClearAccessCodeAction |
    SendReminderTaxDocumentAction |
    RequestSignedDetailsAction |
    ReceiveSignedDetailsAction |
    ReceiveDocumentStatusAction |
    CheckTaxDocumentExistAction |
    RequestDownloadableDocumentsAction |
    ReceiveDownloadableDocumentsAction |
    RequestDownloadableEfileFormsAction |
    ReceiveDownloadableEfileFormsAction |
    ResetTaxReturnsAction |
    ResetDeliveredReturnsAction |
    MakeAvailableTaxDocumentAction |
    SendToEROAction |
    SendForReviewAction |
    DeliverToClientAction |
    ApproveForDeliveryAction |
    Notification.NotificationAction |
    K1Instruction |
    GetTaxCaddyLookupDetails |
    ClearTaxCaddyLookupDetails |
    ResetTaxDocument |
    UpdateDocumentInitialStatus |
    NotifyTaxDocument |
    ResetPdfAction |
    CheckSSRExtensionDocumentExistAction;

type DispatchActions =
    RequestTaxDocumentAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentsAction |
    DeleteTaxDocumentAction |
    DeleteDeliveredTaxDocumentAction |
    ArchiveTaxDocumentAction |
    RecallTaxDocumentAction |
    ErrorTaxDocumentAction |
    ReceiveClientTrackingAction |
    ReceiveDownloadHistoryAction |
    ReceiveAccessCodeAction |
    RequestSignedDetailsAction |
    ReceiveDocumentStatusAction |
    GenerateOTPAction |
    ClearAccessCodeAction |
    ReceiveSignedDetailsAction |
    ReceiveDownloadableDocumentsAction |
    ReceiveDownloadableEfileFormsAction |
    MakeAvailableTaxDocumentAction |
    K1Instruction |
    GetTaxCaddyLookupDetails |
    ClearTaxCaddyLookupDetails |
    RequestAssignUserAction |
    ReceiveAssignUserAction |
    ResetTaxDocument |
    CheckSSRExtensionDocumentExistAction;

export interface ITaxDocumentDictionary {
    [index: number]: {
        taxReturn: ITaxReturn,
        isLoading: boolean,
        error: boolean,
        message: string,
        isFullyLoaded: boolean,
        fileName?: string
    };
}

export function documentsToUpdate(store: ITaxDocumentDictionary, ids: number[]) {
    let newList: number[] = [];
    ids.map((id, i) => {
        if (!store[id].taxReturn.documentAccess) {
            newList.push(id);
        }
    });
    return newList;
}

export const actionCreators = {
    requestTaxDocument: (id: number, force: boolean = false, requestTaxPayerView?: boolean, clientType?: ClientTypes, callback?: (data: any) => void, isEditClientInfoRequest?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (isEditClientInfoRequest === undefined) {
            isEditClientInfoRequest = false;
        }
        if (force ||
            !state.taxDocuments[id] ||
            !state.taxDocuments[id].taxReturn ||
            state.taxDocuments[id].taxReturn.id === 0) {
            const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/` + id + '/' + isEditClientInfoRequest, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ITaxReturn>)
                .then(data => {
                    updateTaxFormType(data);
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: id, taxDocument: data, source: TaxReturnSource.TaxDocument, isEditClientInfoRequest: isEditClientInfoRequest });
                    if (requestTaxPayerView) {
                        let action: any = DeliveredReturnsStore.actionCreators.generateTaxpayerView(data, RBACKeys.ExtensionReport.clientView, clientType);
                        dispatch(action);
                    }
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                    logger.trackError(`requestTaxDocument failed for id: ${id}, with error ${error.message}`);
                });
            addTask(fetchTask);
        }
    },

    updateTaxDocument: (taxReturn: ITaxReturn, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument });
        if (callback) {
            callback();
        }
    },

    saveTaxDocument: (taxReturn: ITaxReturn, resourceId: string, customColumn?: IColumnValues, callback?: (data?: any) => void,
        emailCallback?: (id: number, isMailSent: boolean, clientGUIDs: string[]) => void, isMailSent?: boolean,
        clientGUIDs?: string[], isEditDeliveredClientInfoRequest?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const formData = new FormData();
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append('taxDocument', taxReturnData);
            formData.append('isEditDeliveredClientInfoRequest', isEditDeliveredClientInfoRequest ? JSON.stringify(isEditDeliveredClientInfoRequest) : 'false');
            let options: any = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'X-Resource-Id': resourceId
                },
                body: formData
            };
            const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument`, options)
                .then(handleResponse)
                .then(() => {
                    if (customColumn) {
                        let action: any = CustomColumnStore.actionCreators.updateCustomColumnValue(customColumn, false, resourceId);
                        dispatch(action);
                    }
                    dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument });
                    callback && callback(taxReturn);
                    emailCallback && isMailSent && clientGUIDs && emailCallback(taxReturn.id, isMailSent, clientGUIDs);
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                    logger.trackError(`saveTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                    callback && callback(taxReturn);
                });
            addTask(fetchTask);
        },

    deleteTaxDocument: (ids: number[], onSuccess: () => void, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_TAX_DOCUMENT, ids: ids });
                onSuccess();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error.statusText });
                logger.trackError(`deleteTaxDocument failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteDeliveredTaxDocument: (ids: number[], onSuccess: () => void, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/DeleteDeliveredReturn`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_DELIVERED_TAX_DOCUMENT, ids: ids });
                onSuccess();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error.statusText });
                logger.trackError(`deleteDeliveredTaxDocument failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    archiveTaxDocument: (ids: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/DeliveredReturns/`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.ARCHIVE_TAX_DOCUMENT, ids: ids });
                //dispatch({ type: actionTypes.RESET_ARCHIVE_RETURNS });
                dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                logger.trackError(`archiveTaxDocument failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    restoreArchivedTaxDocument: (ids: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/DeliveredReturns/RestoreTaxReturns/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                logger.trackError(`restoreArchivedTaxDocument failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    recallTaxDocument: (taxReturn: ITaxReturn, callback: () => void, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/RecallReturn/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            },
            body: formData
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECALL_TAX_DOCUMENT, taxDocument: taxReturn });
                dispatch({ type: actionTypes.RESET_TAX_RETURNS });
                dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
                dispatch({ type: actionTypes.RESET_PDF_OBJECTS, id: taxReturn.id });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`recallTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    reprocessTaxDocument: (taxReturn: ITaxReturn, callback: (id: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/ReprocessReturn/`, {
            method: 'PUT',
            credentials: 'include',
            body: formData
        })
            .then(handleResponse)
            .then(() => {
                callback(taxReturn.id);
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument })
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`reprocessTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    AssignTaxDocument: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/AssignUser`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_ASSIGN_USER, id: taxReturn.id, taxDocument: taxReturn });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`AssignTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ASSIGN_USER, id: taxReturn.id });
    },

    ChangeStatusTaxDocument: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/UpdateChangeStatus`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_ASSIGN_USER, id: taxReturn.id, taxDocument: taxReturn });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`ChangeStatusTaxDocument failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_ASSIGN_USER, id: taxReturn.id });
    },

    getTaxDocumentsAccessMaps: (ids: number[], force: boolean = false,resourceId:string, callback?: (result: IDocumentAccess) => void ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let newIds = force ? ids : documentsToUpdate(state.taxDocuments, ids);
        
        let options:any={
            method:'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
            body:JSON.stringify(newIds)
        }
            const fetchTask = fetch(`${API_BASE_URL}api/document-access/GetDocumentAccessAsync`,options)
                .then(handleResponse)
                .then(json => json as Promise<IDocumentAccess>)
                .then(data => {
                    callback && callback(data);
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: newIds, message: error });
                    logger.trackError(`getTaxDocumentsAccessMaps failed for ids: ${ids}, with error ${error.message}`);
                });
            addTask(fetchTask);
    },

    setTaxDocumentsAccessMaps: (accessMaps: IDocumentAccessSaveModel, resourceId: string, callback?: () => void ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(accessMaps)
        };
        const fetchTask = fetch(API_BASE_URL + 'api/document-access', options)
            .then(handleResponse)
            .then(() => {
                callback && callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: extractDocumentIds(accessMaps), message: error });
                logger.trackError(`setTaxDocumentsAccessMaps failed for accessMaps: ${accessMaps}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestTaxDocumentClientTracking: (id: number, resourceId: string, callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientTracking/` + id, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING, id: id, clientTracking: data });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestTaxDocumentClientTracking failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestTaxDocumentAccessCode: (id: number, resourceId: string, requestClientEvents?: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/OTP/` + id + '/' + requestClientEvents, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<IAccessCodeViewModel>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE, id: id, accessCode: data });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestTaxDocumentAccessCode failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    reportTaxDocumentProblem: (
        problemDetails: IReportProblemDetails, resourceId: string,
        callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            const fetchTask = fetch(`${API_BASE_URL}api/SupportIssue/SaveReportedProblem/`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(problemDetails),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId
                },
            })
                .then(handleResponse)
                .then((response) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.ReportProblemConstants.SuccessMessage + response,
                        statusType: Notification.StatusType.Success,
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [problemDetails.documentId], message: error });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.ReportProblemConstants.ErrorMessage,
                        statusType: Notification.StatusType.Error,
                    });
                    logger.trackError(`reportTaxDocumentProblem failed for problemDetails: ${problemDetails}, with error ${error.message}`);
                });
            addTask(fetchTask);
        },

    sendReminder: (ids: number[], resourceId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/SendReminder`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(ids),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((response) => {
                ids.map((id, index) => {
                    dispatch({ type: actionTypes.SEND_REMINDER_TAX_DOCUMENT, id: id });
                });
                callback && callback();
                VenusNotifier.Success("Reminder has been sent successfully for the selected Tax Return Extension", "");
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                logger.trackError(`sendReminder failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    sendScheduledReminder: (ids: number[], resourceId: string, autoReminderSettings: IScheduleReminderPayload, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reminder/SetAutoReminderAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                documentIdList: ids,
                autoReminderSettings
            }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((response) => {
                ids.map((id, index) => {
                    dispatch({ type: actionTypes.SEND_REMINDER_TAX_DOCUMENT, id: id, isScheduled: true });
                });
                callback && callback();
                VenusNotifier.Success("Successfully updated reminder for tax-return extension", "");
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                logger.trackError(`sendReminder failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestDocumentVoucherStatus: (id: number, resourceId : string, callback?: (status) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reminder/GetVoucherStatusToSetReminder/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((response) => {
                callback(response);
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`Getting voucher status failed for ids: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestSignedDetails: (id: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/GetSignedDetailsAsync/` + id, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<ISignerModel[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS, id: id, signedDetails: data });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestSignedDetails failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestDocumentStatus: (id: number, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/GetDocumentStatus/` + id, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<DocumentStatus>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_STATUS, id: id, documentStatus: data });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestDocumentStatus failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    checkDuplicateTaxReturn: (id: number, engagementType: EngagementType, taxyear: number, callback: (result: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/CheckDuplicateTaxReturn/?id=` + id + '&engagementType=' + engagementType.toString() + '&taxYear=' + taxyear, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Resource-Id': getProcessResourceId()
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<boolean>)
            .then(data => {
                callback(data);
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [engagementType], message: error });
                logger.trackError(`checkDuplicateTaxReturn failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },



    resendAccessLink: (id: number, model: ISignerModel, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/ResendAccessLink/`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS, id: id });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`resendAccessLink failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    sendClientInfoMail: (documentId: number, clientGUIDs: string[], resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/SendClientInfoMail/`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ documentId: documentId, clientGUIDs: clientGUIDs }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                logger.trackError(`sendClientInfoMail failed for documentId: ${documentId}, with error ${error.message}`);
            });

    },

    SendDownloadTaxReturnMailOnDeliveredEmailAddressChange: (taxDocumentId: number, clientGUIDs: string[], resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/SendDownloadTaxReturnMailOnDeliveredEmailAddressChange/` + taxDocumentId + '/' + clientGUIDs, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocumentId], message: error });
                logger.trackError(`SendDownloadTaxReturnMailOnDeliveredEmailAddressChange failed for taxDocumentId: ${taxDocumentId}, with error ${error.message}`);
            });
    },

    generateOTP: (id: number, clientType: ClientTypes, clientGUID: string, resourceId: string, callBack?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/OTP/` + id + '/' + clientType + '/' + clientGUID, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({
                    type: actionTypes.GENERATE_OTP_TAX_DOCUMENT, id: id, clientGUID: clientGUID, clientType: clientType,
                    otp: response.item1,
                    createdOn: response.item2
                });
                if (callBack) {
                    callBack();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`generateOTP failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestDownloadableReturns: (id: number, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Download/GetDownloadableReturnsAsync/?documentId=` + id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(id)
        })
            .then(handleResponse)
            .then(response => response as Promise<IDownloadableDocuments[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS, id: id, downloadableDocuments: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestDownloadableReturns failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestDownloadableEfileForms: (id: number, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Download/GetDownlaodableEfileFormAsync`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(id)
        })
            .then(handleResponse)
            .then(response => response as Promise<IDownloadableDocuments[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS, id: id, downloadableEfileForms: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestDownloadableEfileForms failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    downloadAllReturn: (documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean, clientId: string, returnType: any, clientName: string, resourceId: string, isEsignedOrManuallySigned?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${API_BASE_URL}api/Download/GetDownloadAllReturnAsync`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify({
                documentKey: documentGuid,
                documentId: documentId,
                taxYear: taxYear,
                IsEfileForm: isEfileForm,
                clientId: clientId,
                returnType: returnType,
                clientName: clientName,
                isSigned: isEsignedOrManuallySigned
            })
        }).then(handleBlobwithFileName).then((data) => {
            let displayDownloadFile = new DisplayDownloadFile();

            displayDownloadFile.showFile(data.blob, data.filename);
            HideLoader();

        }).catch(error => {
            dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
            logger.trackError(`downloadAllReturn failed for documentId: ${documentId}, with error ${error.message}`);
            HideLoader();
        });
    },


    downloadReturn: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        clientId: string,
        clientName: string,
        returnType: any,
        isSinglePrinted: boolean,
        resourceId: string,
        onPageReload?: () => void,
        isUpdateDocTransaction?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(`${API_BASE_URL}api/Download/GetDownloadReturnLinkAsync`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Resource-Id': resourceId
                },
                body: JSON.stringify({
                    documentKey: documentGuid,
                    fileName: fileName,
                    group: groupId,
                    taxYear: taxYear,
                    id: documentId,
                    signedDocumentId: signedDocumentId,
                    clientId: clientId,
                    clientName: clientName,
                    returnType: returnType,
                    isSinglePrinted: isSinglePrinted,
                    isUpdateDocTransaction: isUpdateDocTransaction
                }),
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    const displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data.blob, data.filename);
                    if (groupId == DocumentGroups.EFile && onPageReload) {
                        onPageReload();
                    }
                }).catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                    logger.trackError(`downloadReturn failed for documentId: ${documentId}, with error ${error.message}`);
                });
        },

    downloadEfileForm: (
        downloadableDocumentIds: number[],
        resourceId: string,
        callBack?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(`${API_BASE_URL}/api/Download/GetDownloadEfileFormAsync`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(downloadableDocumentIds),
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                    'X-Resource-Id': resourceId
                }
            }).then(handleBlob)
                .then((data) => {
                    let displayDownloadFile = new DisplayDownloadFile();
                    let todayDate = getCurrentDate().replace(/\//g, '_');
                    displayDownloadFile.showFile(data, Constants.DownloadEfileZip(todayDate));
                    if (callBack) {
                        callBack();
                    }
                    HideLoader();

                }).catch(error => {
                    dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: downloadableDocumentIds, message: error });
                    logger.trackError(`downloadEfileForm failed for downloadableDocumentIds: ${downloadableDocumentIds}, with error ${error.message}`);
                    if (callBack) {
                        callBack();
                    }
                    HideLoader();
                });
        },

    requestTaxDocumentDownloadHistory: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/DownloadHistory/` + id + '/' + DocumentEvent.DocumentSignedEfileDownloadedByCPA, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IDocumentTransaction[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY, id: id, downloadHistory: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`requestTaxDocumentDownloadHistory failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    makeAvailableTaxDocument: (ids: number[], callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/InUseExtensions/`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT, ids: ids });
                dispatch({ type: actionTypes.RESET_TAX_RETURNS })
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: ids, message: error });
                logger.trackError(`makeAvailableTaxDocument failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    sendToERO: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/SendToERO/`, options)
            .then(handleResponse)
            .then(() => {
                let action: any = actionCreators.updateTaxDocument(taxReturn);
                dispatch(action);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.FinishProcessReturn.StatusMessage.SendToEROSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.FinishProcessReturn.StatusMessage.SendToEROError, statusType: Notification.StatusType.Error })
                logger.trackError(`sendToERO failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    sendForReview: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/SendForReview/`, options)
            .then(handleResponse)
            .then(() => {
                let action: any = actionCreators.updateTaxDocument(taxReturn);
                dispatch(action);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.FinishProcessReturn.StatusMessage.SendForReviewSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.FinishProcessReturn.StatusMessage.SendForReviewError, statusType: Notification.StatusType.Error })
                logger.trackError(`sendForReview failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    approveForDelivery: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/ApproveForDelivery/`, options)
            .then(handleResponse)
            .then(() => {
                let action: any = actionCreators.updateTaxDocument(taxReturn);
                dispatch(action);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.FinishProcessReturn.StatusMessage.ApproveForDeliverySuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.FinishProcessReturn.StatusMessage.ApproveForDeliveryError, statusType: Notification.StatusType.Error });
                logger.trackError(`approveForDelivery failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deliverToClient: (taxReturn: ITaxReturn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let resourceId = RBACKeys.extensionInProgress.deliveryButton
        if (validator) {
            if (!validator.someResourcesExists([RBACKeys.extensionInProgress.deliveryButton])) {
                resourceId = RBACKeys.extensionInProgress.internalRouting;
            }
        }
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/DeliverAsync/`, options)
            .then(handleResponse)
            .then(() => {
                let action: any = actionCreators.updateTaxDocument(taxReturn);
                dispatch(action);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Constants.FinishProcessReturn.StatusMessage.DeliverToClientError, statusType: Notification.StatusType.Error })
                logger.trackError(`deliverToClient failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    getK1InstructionFileName: (guid: string, taxYear: number, documentId: number, engagementType: EngagementType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/download/GetK1InstructionMetaDataAsync?documentKey=` + guid + '&taxYear=' + taxYear + '&engagementType=' + engagementType.toString()
            , { credentials: 'include' }).then(handleResponse)
            .then((data) => {
                dispatch({ type: actionTypes.K1_INSTRUCTION_DETAIL, fileName: data, id: documentId });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [documentId], message: error });
                logger.trackError(`getK1InstructionFileName failed for documentId: ${documentId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteK1Instruction: (guid: string, taxYear: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/ProcessReturn/DeleteK1Instruction/` + guid + '/' + taxYear, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => { })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [], message: error });
                logger.trackError(`deleteK1Instruction failed for guid: ${guid}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    saveProcessReturnInfo: (
        taxReturn: ITaxReturn,
        subDocuments: ISubDocument[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        taxCaddyLookupResult?: TaxCaddyLookupResult,
        clientType?: ClientTypes): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let actualPath: string;
            if (subDocuments.filter(doc => { return doc.subDocType === SubDocType.Original })[0] != undefined) {
                actualPath = subDocuments.filter(doc => { return doc.subDocType === SubDocType.Original })[0].path;
            }

            subDocuments.map((doc, index) => {
                subDocuments[index].pageMap = doc.pageMap.filter(map => { return map.facadePageNum != -1 });
                subDocuments[index].path = subDocuments[index].path ? subDocuments[index].path :
                    actualPath.split("/")[0] + "/" + "processReturn/" + taxReturn.documentGuid;
                (subDocuments[index].document as any) = undefined; //Circular dependency error by stringify if not done.
            })
            let modelData: IProcessReturnViewModel = {
                taxDocument: taxReturn, parts: subDocuments,
                taxCaddyDeliveryViewModel: taxCaddyLookupResult ? taxCaddyLookupResult : initialTaxCaddyDeliveryViewModel,
                isK1Replaced: isK1Replaced, isK1Restored: isK1Restored, isMFJChanged: isMFJChanged, clientType: clientType
            }

            let endPoint: string = 'api/ProcessReturn';
            if (apiEndPoint) {
                endPoint = endPoint + '/' + apiEndPoint;
            }

            let headers = {};
            let resourceId ;
                        
            if(apiEndPoint === undefined || apiEndPoint === '' || apiEndPoint == processReturnActionEndPoints.generateTaxpayerViewAsync )
            {
                resourceId = getProcessResourceId(); 
            }
            else if (apiEndPoint == processReturnActionEndPoints.deliverAsync) 
            {
                resourceId = RBACKeys.extensionInProgress.deliveryButton;
            }
            else {
                    resourceId = RBACKeys.extensionInProgress.internalRouting;
                }
            headers["X-Resource-Id"] = resourceId;
            

            let options: any = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(modelData)
            };

            const fetchTask = fetch(`${API_BASE_URL}${endPoint}`, options)
                .then(handleResponse)
                .then((data) => {
                    let action: any = actionCreators.updateTaxDocument(taxReturn);
                    dispatch(action);
                    if (apiEndPoint !== processReturnActionEndPoints.generateTaxpayerViewAsync) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: getProcessReturnSucessStatusMessage(apiEndPoint ? apiEndPoint : ""),
                            statusType: Notification.StatusType.Success
                        });
                    }
                    if (apiEndPoint == processReturnActionEndPoints.generateTaxpayerViewAsync) {
                        let action: any = actionCreators.requestTaxDocument(taxReturn.id, false);
                        dispatch(action);
                    }
                    if (callback) {
                        data ? callback(data) : callback();
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: getProcessReturnFailureStatusMessage(apiEndPoint ? apiEndPoint : ""),
                        statusType: Notification.StatusType.Error
                    });
                    logger.trackError(`saveProcessReturnInfo failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },

    getTaxCaddyLookupDetails:
        (taxpayerName: string, email: string, ssn: string, taxYear: number, taxClientId: string, docId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}/api/ProcessReturn/GetTaxCaddyLookupAsync/` + taxpayerName + '/' + email + '/' + ssn + '/' + taxYear + '/' + taxClientId, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then((data) => {
                    dispatch({ type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS, taxcaddyLookupDetails: data, id: docId })
                })
                .catch(error => {
                    let taxcaddyLookupDetails = initialTaxCaddyLookupResultModel;
                    taxcaddyLookupDetails.isSuccess = false;
                    taxcaddyLookupDetails.result = Constants.FinishProcessReturn.StatusMessage.TaxCaddyLookupError;
                    dispatch({ type: actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS, taxcaddyLookupDetails: taxcaddyLookupDetails, id: docId });
                    logger.trackError(`getTaxCaddyLookupDetails failed for taxpayerName: ${taxpayerName}, with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS, id: docId });

        },

    updateDocumentInitialStatus: (taxReturn: ITaxReturn, resourceId: string, callback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (resourceId == RBACKeys.extensionInProgress.processReturnButton) {
            resourceId = getProcessResourceId();
        }

        const fetchTask = fetch(`${API_BASE_URL}/api/TaxDocument/UpdateDocumentInitialStatus/` + taxReturn.documentStatus + '/' + taxReturn.assignTo + '/'
            + taxReturn.lockedBy + '/' + taxReturn.id, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument })
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`updateDocumentInitialStatus failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                if (failureCallback) {
                    failureCallback();
                }
            });
        addTask(fetchTask);
    },

    notifyTaxDocument: (id: number, status: DocumentStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        if (!state.taxDocuments[id] || status === DocumentStatus.READY) {
            dispatch(actionCreators.requestTaxDocument(id, true));
            console.log('id not in state.taxDocuments');
        } else {
            console.log('id exist in state.taxDocuments');
            const taxReturn = state.taxDocuments[id].taxReturn;
            if (taxReturn) {
                if (taxReturn.documentSettings?.deliverySettings?.deliveryMode == DeliveryMode.PaperFiled && status == DocumentStatus.DELIVERED) {
                    taxReturn.documentStatus = DocumentStatus.DOWNLOADPDFDESCRIPTION;
                }
                else {
                    taxReturn.documentStatus = status;
                }
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument });
            }
        }

    },

    restoreK1Instruction: (fileName: string, documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.K1_INSTRUCTION_DETAIL, fileName: fileName, id: documentId });
    },

    resetTaxDocument: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_TAX_DOCUMENT, id: id })
    },

    submitUploadForms: (taxReturn: ITaxReturn, files: string[], resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let modelData: any = {
            taxDocument: taxReturn, files: files
        }

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(modelData)
        };
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/SubmitUploadForms?userTimeZone=` + getUserTimeZone(), options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.RECEIVE_TAX_DOCUMENT, id: taxReturn.id, taxDocument: taxReturn, source: TaxReturnSource.TaxDocument });
            }).then(()=>{
                let action: any = actionCreators.PrepareZipFile(taxReturn, resourceId);
                dispatch(action);
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxReturn.id], message: error });
                logger.trackError(`submitUploadForms failed for taxReturn: ${taxReturn}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    UpdateStatusToSignedAndESigned: (taxDocument: ITaxReturn, reason: string, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.append('taxDocument', JSON.stringify(taxDocument));
        formData.append('reason', reason);
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/UpdateStatusToSignedAndESigned?userTimeZone=` + getUserTimeZone(), {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then((response) => {
                let action: any = actionCreators.PrepareZipFile(taxDocument,resourceId);
                dispatch(action);
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                logger.trackError(`UpdateStatusToSignedAndESigned failed for taxDocument: ${taxDocument}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    PrepareZipFile: (taxDocument: ITaxReturn, resourceId : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.append('taxDocument', JSON.stringify(taxDocument));
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/PrepareZipFile`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(handleResponse)
            .then((response) => {

            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                logger.trackError(`PrepareZipFile failed for taxDocument: ${taxDocument}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    UpdateSigningOrder: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/UpdateSigningOrder/` + id, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'

            },
        })
            .then(handleResponse)
            .then((response) => {

            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`UpdateSigningOrder failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    CleanUpSigningOrder: (taxDocument: ITaxReturn, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.append('taxDocument', JSON.stringify(taxDocument));
        const fetchTask = fetch(`${API_BASE_URL}api/Upload/CleanUpSigningOrder/`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then((response) => {

            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [taxDocument.id], message: error });
                logger.trackError(`CleanUpSigningOrder failed for taxDocument: ${taxDocument}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    reloadDeliveredReturns: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
    },

    LogChangeStatusToManaullySignedTransaction: (taxDocument: ITaxReturn, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.append('taxDocument', JSON.stringify(taxDocument));
        let fetchTask = fetch(`${API_BASE_URL}api/ClientTracking/ChangeStatusToManaullySignedTransaction/`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId
            },
        }).then(handleResponse)
            .then((response) => {

            });
    },

    deleteProcessInfo: (documentId: number, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/TaxDocument/DeleteProcessInfoAsync/` + documentId, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then(() => { })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [], message: error });
                logger.trackError(`deleteProcessInfo failed for documentId: ${documentId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    isSSRExtensionDocumentExist: (id: number, resourceId : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/IsSSRExtensionDocumentExist/?id=` + id, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<boolean>)
            .then(data => {
                dispatch({ type: actionTypes.CHECK_SSR_EXTENSION_DOCUMENT_EXIST, id: id, isSSRExtensionDocument: data });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_TAX_DOCUMENT, ids: [id], message: error });
                logger.trackError(`isSSRExtensionDocumentExist failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
};

export function getProcessReturnSucessStatusMessage(apiEndPoint: string) {
    let message: string = "";
    switch (apiEndPoint) {
        case processReturnActionEndPoints.approveForDeliveryAsync:
            message = Constants.FinishProcessReturn.StatusMessage.ApproveForDeliverySuccess;
        case processReturnActionEndPoints.sendToEROAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendToEROSuccess;
        case processReturnActionEndPoints.sendForReviewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendForReviewSuccess;
        case processReturnActionEndPoints.deliverAsync:
            message = Constants.FinishProcessReturn.StatusMessage.DeliverToClientSuccess;
        case processReturnActionEndPoints.generateTaxpayerViewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.GenerateTaxpayerViewSuccess;
        default: message = Constants.SaveandCloseProcessReturn.StatusMessage.Success;
    }
    return message;
}

export function getProcessReturnFailureStatusMessage(apiEndPoint: string) {
    let message: string = "";
    switch (apiEndPoint) {
        case processReturnActionEndPoints.approveForDeliveryAsync:
            message = Constants.FinishProcessReturn.StatusMessage.ApproveForDeliveryError;
        case processReturnActionEndPoints.sendToEROAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendToEROError;
        case processReturnActionEndPoints.sendForReviewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.SendForReviewError;
        case processReturnActionEndPoints.deliverAsync:
            message = Constants.FinishProcessReturn.StatusMessage.DeliverToClientError;
        case processReturnActionEndPoints.generateTaxpayerViewAsync:
            message = Constants.FinishProcessReturn.StatusMessage.GenerateTaxpayerViewError;
        default: message = Constants.SaveandCloseProcessReturn.StatusMessage.Fail;
    }
    return message;
}

const unloadedState: ITaxReturn = {
    id: 0
} as ITaxReturn;

export const reducer: Reducer<ITaxDocumentDictionary> = (state: ITaxDocumentDictionary = {}, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_TAX_DOCUMENT:
            let loading: ITaxDocumentDictionary = { ...state };
            loading[action.id] = {
                taxReturn: state[action.id] ? state[action.id].taxReturn : unloadedState,
                isLoading: true,
                error: false,
                message: '',
                isFullyLoaded: false
            };

            return loading;

        case actionTypes.DELETE_TAX_DOCUMENT:
            let deleteState = { ...state };
            action.ids.map((id, i) => {
                delete deleteState[id];
            });

            return deleteState;

        case actionTypes.DELETE_DELIVERED_TAX_DOCUMENT:
            let deleteDeliveredState = { ...state };
            action.ids.map((id, i) => {
                delete deleteDeliveredState[id];
            });

            return deleteDeliveredState;

        case actionTypes.ARCHIVE_TAX_DOCUMENT:
            let archiveState = { ...state };
            action.ids.map((id, i) => {
                delete archiveState[id];
            });

            return archiveState;

        case actionTypes.RECALL_TAX_DOCUMENT:
            let recallState = { ...state };
            delete recallState[action.taxDocument.id];
            return recallState;

        case actionTypes.RECEIVE_TAX_DOCUMENT:
            if (action.source === TaxReturnSource.TaxDocument || !state[action.id]) {
                let received: ITaxDocumentDictionary = { ...state };
                let message = Date();
                action.taxDocument.accessCode = {
                    accessCodeDetails: [], clientEvents: []
                };
                received[action.id] = {
                    taxReturn: action.taxDocument,
                    isLoading: false,
                    error: false,
                    message: message,
                    isFullyLoaded: (action.isEditClientInfoRequest != undefined && action.isEditClientInfoRequest == true) ? false
                        : action.source === TaxReturnSource.TaxDocument && action.taxDocument.formGroups.length != 0 && action.taxDocument.documentSettings != undefined ? true : false,
                    fileName: received[action.id] == undefined ? "" : received[action.id].fileName
                };

                return received;
            }
            break;

        case actionTypes.RECEIVE_TAX_DOCUMENTS:
            if (action.source === TaxReturnSource.CompanyAssignments ||
                TaxReturnSource.DeliveredReturns) {
                let received: ITaxDocumentDictionary = { ...state };
                let message = Date();

                action.taxDocuments.forEach((model, i) => {
                    received[model.id] = {
                        taxReturn: model,
                        isLoading: false,
                        error: false,
                        message: message,
                        isFullyLoaded: action.source === TaxReturnSource.TaxDocument ? true : false,
                        fileName: received[model.id] == undefined ? "" : received[model.id].fileName
                    };
                });

                return received;
            }
            break;

        case actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS:
            let accessState: ITaxDocumentDictionary = { ...state };
            action.accessMaps.map((item, i) => {
                let model = state[item.documentId].taxReturn;
                model.documentAccess = item;
                accessState[item.documentId] = {
                    taxReturn: model,
                    isLoading: false,
                    error: false,
                    message: '',
                    isFullyLoaded: accessState[item.documentId].isFullyLoaded
                };
            });

            return accessState;

        case actionTypes.ERROR_TAX_DOCUMENT:
            let errorState: ITaxDocumentDictionary = { ...state };
            action.ids.map((id, i) => {
                if (state[id]) {
                    let model = state[id].taxReturn;
                    errorState[id].isLoading = false;
                    errorState[id].error = true;
                    errorState[id].message = action.message;
                }
            });

            return errorState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING:
            let clientStatusState: ITaxDocumentDictionary = { ...state };
            let model = state[action.id].taxReturn;
            model.clientTracking = action.clientTracking;
            clientStatusState[action.id] = {
                taxReturn: model,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: clientStatusState[action.id].isFullyLoaded
            };
            return clientStatusState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsState: ITaxDocumentDictionary = { ...state };
            let signedDetailsTaxReturn = state[action.id];
            if (signedDetailsTaxReturn) {
                signedDetailsTaxReturn.taxReturn.signedDetails = action.signedDetails;
                signedDetailsState[action.id] = {
                    taxReturn: signedDetailsTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: '',
                    isFullyLoaded: signedDetailsState[action.id].isFullyLoaded
                };
            }
            return signedDetailsState;

        case actionTypes.CHECK_SSR_EXTENSION_DOCUMENT_EXIST:
            let ssrExtensionDocumentState: ITaxDocumentDictionary = { ...state };
            let ssrExtensionDocument = state[action.id];
            if (ssrExtensionDocument) {
                ssrExtensionDocument.taxReturn.isSSRExtensionDocument = action.isSSRExtensionDocument;
                ssrExtensionDocument.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder = !action.isSSRExtensionDocument;
                ssrExtensionDocumentState[action.id] = {
                    taxReturn: ssrExtensionDocument.taxReturn,
                    isLoading: false,
                    error: false,
                    message: '',
                    isFullyLoaded: ssrExtensionDocumentState[action.id].isFullyLoaded
                };
            }
            return ssrExtensionDocumentState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_STATUS:
            let documentStatusState: ITaxDocumentDictionary = { ...state };
            let documentStatusOfTaxReturn = state[action.id].taxReturn;
            documentStatusOfTaxReturn.documentStatus = action.documentStatus;
            documentStatusState[action.id] = {
                taxReturn: documentStatusOfTaxReturn,
                isLoading: false,
                error: false,
                message: '',
                isFullyLoaded: documentStatusState[action.id].isFullyLoaded
            };

            return documentStatusState;

        case actionTypes.REQUEST_TAX_DOCUMENT_SIGNED_DETAILS:
            let signedDetailsRequestState: ITaxDocumentDictionary = { ...state };
            let signedDetailsRequestTaxReturn = state[action.id];
            if (signedDetailsRequestTaxReturn) {
                signedDetailsRequestTaxReturn.taxReturn.signedDetails = [];
                signedDetailsRequestState[action.id] = {
                    taxReturn: signedDetailsRequestTaxReturn.taxReturn,
                    isLoading: false,
                    error: false,
                    message: '',
                    isFullyLoaded: signedDetailsRequestState[action.id].isFullyLoaded
                };
            }
            return signedDetailsRequestState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_ACCESS_CODE:
            let accessCodeState: ITaxDocumentDictionary = { ...state };
            let accessCodeRequestTaxReturn = state[action.id].taxReturn;
            accessCodeRequestTaxReturn.accessCode = action.accessCode;
            accessCodeState[action.id] = {
                taxReturn: accessCodeRequestTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: accessCodeState[action.id].isFullyLoaded
            };
            return accessCodeState;
        case actionTypes.GENERATE_OTP_TAX_DOCUMENT:
            let generateOTPState: ITaxDocumentDictionary = { ...state };
            let generateOTPTaxReturn = state[action.id].taxReturn;
            const index = generateOTPTaxReturn.accessCode.accessCodeDetails.findIndex(x => x.clientGuid == action.clientGUID);
            if (index > -1) {
                generateOTPTaxReturn.accessCode.accessCodeDetails[index].otp = action.otp;
                generateOTPTaxReturn.accessCode.accessCodeDetails[index].createdOn = action.createdOn;
            }
            generateOTPState[action.id] = {
                taxReturn: generateOTPTaxReturn,
                isLoading: false,
                error: false,
                message: '',
                isFullyLoaded: generateOTPState[action.id].isFullyLoaded
            };

            return generateOTPState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS:
            let downloadableDocumentState: ITaxDocumentDictionary = { ...state };
            let downloadableDocumentTaxReturn = state[action.id].taxReturn;
            downloadableDocumentTaxReturn.downloadableDocuments = action.downloadableDocuments;
            downloadableDocumentState[action.id] = {
                taxReturn: downloadableDocumentTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: downloadableDocumentState[action.id].isFullyLoaded
            };
            return downloadableDocumentState;

        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS:
            let downloadableEfileFormState: ITaxDocumentDictionary = { ...state };
            let downloadableEfileFormTaxReturn = state[action.id].taxReturn;
            downloadableEfileFormTaxReturn.downloadableEfileForms = action.downloadableEfileForms;
            downloadableEfileFormState[action.id] = {
                taxReturn: downloadableEfileFormTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: downloadableEfileFormState[action.id].isFullyLoaded
            };
            return downloadableEfileFormState;
        case actionTypes.RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY:
            let downloadHistoryState: ITaxDocumentDictionary = { ...state };
            let downloadHistoryTaxReturn = state[action.id].taxReturn;
            downloadHistoryTaxReturn.downloadHistory = action.downloadHistory;
            downloadHistoryState[action.id] = {
                taxReturn: downloadHistoryTaxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: downloadHistoryState[action.id].isFullyLoaded
            };
            return downloadHistoryState;
        case actionTypes.MAKE_AVAILABLE_INUSE_TAX_DOCUMENT:
            let makeavailableState = { ...state };
            action.ids.map((id, i) => {
                delete makeavailableState[id];
            });

            return makeavailableState;
        case actionTypes.K1_INSTRUCTION_DETAIL:
            let taxDocument: ITaxDocumentDictionary = { ...state };
            let taxReturn = state[action.id].taxReturn;
            taxDocument[action.id] = {
                taxReturn: taxReturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: taxDocument[action.id].isFullyLoaded,
                fileName: action.fileName
            };
            return taxDocument;
        case actionTypes.REQUEST_TAXCADDY_LOOKUP_DETAILS:
            let taxcaddyDetails: ITaxDocumentDictionary = { ...state };
            let taxreturn = state[action.id].taxReturn;
            taxreturn.taxCaddyLookupResultModel = action.taxcaddyLookupDetails;
            taxcaddyDetails[action.id] = {
                taxReturn: taxreturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: taxcaddyDetails[action.id].isFullyLoaded,
            };
            return taxcaddyDetails;
        case actionTypes.CLEAR_TAXCADDY_LOOKUP_DETAILS:
            let clearTaxcaddyDetails: ITaxDocumentDictionary = { ...state };
            let taxcaddyTaxreturn = state[action.id].taxReturn;
            delete taxcaddyTaxreturn.taxCaddyLookupResultModel;
            clearTaxcaddyDetails[action.id] = {
                taxReturn: taxcaddyTaxreturn,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: clearTaxcaddyDetails[action.id].isFullyLoaded,
            };
            return clearTaxcaddyDetails;
        case actionTypes.REQUEST_ASSIGN_USER:
            let assignUser: ITaxDocumentDictionary = { ...state };
            assignUser[action.id] = {
                taxReturn: unloadedState,
                isLoading: true,
                error: false,
                message: '',
                isFullyLoaded: assignUser[action.id].isFullyLoaded
            };

            return assignUser;
        case actionTypes.RECEIVE_ASSIGN_USER:
            let receiveUser: ITaxDocumentDictionary = { ...state };
            receiveUser[action.id] = {
                taxReturn: action.taxDocument,
                isLoading: false,
                error: false,
                message: Date(),
                isFullyLoaded: receiveUser[action.id].isFullyLoaded
            };

            return receiveUser;
        case actionTypes.RESET_TAX_DOCUMENT:
            let resetTaxDocumentState = { ...state };
            resetTaxDocumentState[action.id].isFullyLoaded = false;
            resetTaxDocumentState[action.id].taxReturn.formGroups = [];
            return resetTaxDocumentState;
        case actionTypes.CLEAR_OTP_TAX_DOCUMENT:
            let clearOTPState: ITaxDocumentDictionary = { ...state };
            let clearOTPTaxReturn = state[action.id].taxReturn;
            clearOTPState[action.id] = {
                taxReturn: clearOTPTaxReturn,
                isLoading: false,
                error: false,
                message: '',
                isFullyLoaded: clearOTPState[action.id].isFullyLoaded
            };

            return clearOTPState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || { "?": unloadedState };
};