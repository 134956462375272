import React, { FC } from 'react';

const ResetIcon: FC = () => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.99907 2.99911C9.07972 2.99953 10.1311 3.35004 10.9959 3.99815C11.8606 4.64626 12.4921 5.55708 12.7957 6.59419C13.0994 7.6313 13.0588 8.73887 12.6802 9.75101C12.3015 10.7631 11.6052 11.6254 10.6954 12.2085C9.78563 12.7917 8.71139 13.0644 7.63361 12.9859C6.55583 12.9073 5.5325 12.4817 4.71692 11.7727C3.90133 11.0638 3.33738 10.1097 3.10954 9.05331C2.8817 7.99695 3.00223 6.89522 3.45307 5.91311C3.50214 5.7937 3.50313 5.65995 3.45582 5.53983C3.40852 5.41972 3.31659 5.32255 3.19927 5.26868C3.08196 5.2148 2.94835 5.20838 2.82642 5.25078C2.70448 5.29317 2.60367 5.38108 2.54507 5.49611C2.00408 6.6747 1.85951 7.99684 2.133 9.26449C2.4065 10.5321 3.08334 11.6771 4.06215 12.5278C5.04095 13.3785 6.26903 13.8891 7.56243 13.9833C8.85583 14.0774 10.1449 13.75 11.2366 13.05C12.3283 12.3501 13.1638 11.3152 13.618 10.1006C14.0722 8.88587 14.1206 7.55673 13.756 6.31222C13.3914 5.06771 12.6334 3.97482 11.5956 3.19726C10.5577 2.4197 9.29589 1.99933 7.99907 1.99911V2.99911Z'
				fill='#0973BA'
			/>
			<path
				d='M7.99907 4.46511V0.53311C7.99905 0.485606 7.9855 0.439091 7.96 0.399011C7.93451 0.358931 7.89812 0.326945 7.8551 0.306799C7.81208 0.286653 7.76421 0.279181 7.71709 0.285257C7.66998 0.291334 7.62557 0.310707 7.58907 0.34111L5.22907 2.30711C5.20094 2.33057 5.1783 2.35993 5.16277 2.39311C5.14724 2.42629 5.13918 2.46248 5.13918 2.49911C5.13918 2.53574 5.14724 2.57193 5.16277 2.60511C5.1783 2.63829 5.20094 2.66765 5.22907 2.69111L7.58907 4.65711C7.62557 4.68751 7.66998 4.70689 7.71709 4.71296C7.76421 4.71904 7.81208 4.71157 7.8551 4.69142C7.89812 4.67127 7.93451 4.63929 7.96 4.59921C7.9855 4.55913 7.99905 4.51261 7.99907 4.46511Z'
				fill='#0973BA'
			/>
		</svg>
	);
};

export default ResetIcon;
