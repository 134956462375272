import React from  'react';
import { Row, Popover, Button, Overlay } from 'react-bootstrap';
import {
    ITaxingAuthority, IEFile, IVoucher, ITaxReturn,
    VoucherProperty, IEFileProperty, IGroupFormBase,
} from '../../common/TaxReturn';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { RadioStatus } from './GroupFilesPreview';
import { FilingVoucher } from '../ProcessReturnModal/LeftSidebar/FilingVoucher';
import { FilingEFile } from '../ProcessReturnModal/LeftSidebar/FilingEFile';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';
import { isReviewExtesnion, isStrictReviewExtesnion } from './SignatureControls/SignatureHelper';
import { isBusinessReturn } from './../../helper/HelperFunctions';
import { ExtensionCompanySettings } from './../../../Core/ViewModels/Extension/ExtensionComponentModels';


export interface IFilingFormsProps {
    taxingAuthorities: ITaxingAuthority[];
    taxReturn?: ITaxReturn;
    voucherStore?: IVoucherState;
    pageNo: number;
    matchedForm?: IGroupFormBase;
    handleSignatureChange?: (status: boolean, pageNumber: number) => void;
    handleVoucherChange?: (status: boolean, pageNumber: number) => void;
    onChangeClick: (click: boolean, disable: boolean) => void;
    onFilingEFileDataUpdate?: (propertyType: IEFileProperty, value: any, pageNumber: number) => void;
    onFilingVoucherDataUpdate?: (propertyType: VoucherProperty, value: any, pageNumber: number) => void;
    onUpdateFilingForm?: (callback?: () => void) => void;
    onResetFilingEFileData?: () => void;
    extensionCompanySettings?: ExtensionCompanySettings;
}

export interface IFilingFormsState {
    showFilingFormView: boolean;
    popoverTarget: any;
    enableLeftPanelScroll: boolean;
    enableUpdate: boolean;
}

export class FilingForms extends React.Component<IFilingFormsProps, IFilingFormsState> {

    constructor(props: IFilingFormsProps) {
        super(props);
        this.state = {
            showFilingFormView: false,
            popoverTarget: {},
            enableLeftPanelScroll: true,
            enableUpdate: false,
        }
    }

    private handleFilingFormBtnClick = (event: any) => {
        this.setState(s => ({
            showFilingFormView: !s.showFilingFormView,
            enableUpdate: false
        }), () => { this.props.onChangeClick(true, true) });
    }

    private handleUpdate = () => {
        if (this.props.onUpdateFilingForm) {
            this.props.onUpdateFilingForm(this.onCancel);
        }
    }

    private attachAddPopoverRef = (target: any) => {
        this.setState({ popoverTarget: target })
    };

    private onCancel = () => {
        this.setState({
            showFilingFormView: false,
        }, () => {
            this.props.onChangeClick(false, false);
            this.props.onResetFilingEFileData && this.props.onResetFilingEFileData();
        });
    }


    renderForm = (form: IGroupFormBase) => {
        if (form.voucherEnable) {
            let voucher: IVoucher = form as IVoucher;
            return <div style={{ border: "1px solid black" }}>
                <FilingVoucher
                    voucher={voucher}
                    pageNo={this.props.pageNo}
                    taxReturn={this.props.taxReturn}
                    taxingAuthorities={this.props.taxingAuthorities}
                    voucherStore={this.props.voucherStore}
                    onFilingVoucherDataUpdate={this.props.onFilingVoucherDataUpdate}
                    enableUpdate={this.enableUpdate}

                />
            </div>
        }
        else if (form.signatureEnable) {
            let eFile: IEFile = form as IEFile;
            return <div style={{ border: "1px solid black" }}>
                <FilingEFile
                    eFile={eFile}
                    pageNo={this.props.pageNo}
                    onFilingEFileDataUpdate={this.props.onFilingEFileDataUpdate}
                    taxingAuthorities={this.props.taxingAuthorities}
                    enableUpdate={this.enableUpdate}
                />
            </div>
        }
        else {
            return null;
        }
    }

    private handleSignatureChange = (event: any) => {
        this.enableUpdate(true);
        this.props.handleSignatureChange && this.props.handleSignatureChange(event.target.value, this.props.pageNo);
    }

    private enableUpdate = (enable: boolean) => {
        this.setState({
            enableUpdate: enable
        })
    }

    private handleVoucherChange = (event: any, voucher: IVoucher) => {
        this.enableUpdate(true);
        this.props.handleVoucherChange && this.props.handleVoucherChange(event.target.value, this.props.pageNo);
    }

    enableScroll = () => {
        if (!this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftPanelScroll: true });
        }

    }

    stopScroll = () => {
        if (this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftPanelScroll: false });
        }

    }

    public render() {
        let isBusinessRet = this.props.taxReturn?isBusinessReturn(this.props.taxReturn):false;
        const filingformPopover = (<Popover id="popover-filing-form" style={{ maxWidth: 1000 }}>
            <Popover.Title
                as="h3"
                style={{ fontSize: '14px', paddingLeft: '14px', paddingTop: '8px', paddingBottom: '10px' }}
            >
                Edit Filing Forms
            </Popover.Title>
            <Popover.Content>
                <div className="new-main-content" style={{ height: '500px' }}>
                    <Row style={{ marginBottom: "10px", paddingLeft: '20px' }}>
                        <div className="col-sm-12 col-lg-12">
                            <div className="row padT15">
                                <div className="col-sm-7 col-lg-7">
                                    <span style={{ fontSize: '12px', float: 'left', textAlign: 'left' }}>  Does this form require taxpayer and/or spouse signatures?</span>
                                </div>
                                <div className="col-sm-5 col-lg-5">
                                    <RadioButtonComponent
                                        text="Yes"
                                        id="efile-sign-yes"
                                        checked={this.props.matchedForm && this.props.matchedForm.signatureEnable}
                                        onChange={(event: any) => { this.handleSignatureChange(event) }}
                                        disabled={this.props.extensionCompanySettings ? (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                                            isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)) : false}
                                        value={RadioStatus.Yes}
                                        dataTestAuto="8DADE4CD-E792-40AA-9FAA-EB4B835D72B1"
                                    />
                                    <RadioButtonComponent
                                        text="No"
                                        id="efile-sign-no"
                                        checked={this.props.matchedForm && !this.props.matchedForm.signatureEnable}
                                        onChange={(event: any) => { this.handleSignatureChange(event) }}
                                        disabled={this.props.extensionCompanySettings ? (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                                            isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)) : false}
                                        value={RadioStatus.No}
                                        dataTestAuto="BA855F92-BBA2-4B29-8F3C-F4E87529EF8A"
                                    />
                                </div>
                            </div>
                            <div className="row padT15">
                                <div className="col-sm-7 col-lg-7">
                                    <span style={{ fontSize: '12px', float: 'left', textAlign: 'left' }} >  Is this form a payment voucher?</span>
                                </div>
                                <div className="col-sm-5 col-lg-5">
                                    <RadioButtonComponent
                                        text="Yes"
                                        id="voucher-yes"
                                        checked={this.props.matchedForm && this.props.matchedForm.voucherEnable}
                                        onChange={(event: any) => { this.handleVoucherChange(event, this.props.matchedForm as IVoucher) }}
                                        disabled={false}
                                        value={RadioStatus.Yes}
                                        dataTestAuto="8DC99F16-E8B4-442C-9B93-32822A6005BE"
                                    />
                                    <RadioButtonComponent
                                        text="No"
                                        id="voucher-no"
                                        checked={this.props.matchedForm && !this.props.matchedForm.voucherEnable}
                                        onChange={(event: any) => { this.handleVoucherChange(event, this.props.matchedForm as IVoucher) }}
                                        disabled={false}
                                        value={RadioStatus.No}
                                        dataTestAuto="2ED540E9-1B29-4B01-8A89-58DBF15E4B2A"
                                    />
                                </div>
                            </div>
                            {
                                this.props.matchedForm &&
                                <div className="row padT15">
                                    <div className="col-sm-11 col-lg-11">
                                        {this.renderForm(this.props.matchedForm)}
                                    </div>
                                </div>
                            }
                        </div>
                    </Row>
                </div>
            </Popover.Content>
            <div className="modal-footer">
                <Button
                    className="btn-white"
                    onClick={this.onCancel}
                    data-test-auto="7F824BA9-2E36-4B44-84DB-E15D023CD07E">
                    <i className="fas fa-times"></i>
                    Cancel
                </Button>
                <Button
                    variant='info'
                    onClick={this.handleUpdate}
                    data-test-auto="9448077C-6B6A-4752-AEFD-3D82CB505113"
                    disabled={!this.state.enableUpdate}>
                    <i className='fas fa-plus'></i>
                    Update
                </Button>
            </div>
        </Popover>)

        return <div>
            <span className="pull-right">
                <a
                    title="Edit Filing Forms"
                    onClick={this.handleFilingFormBtnClick}
                    ref={this.attachAddPopoverRef}
                    data-test-auto="C1626864-6FCE-4545-AB3E-EF19CF5F3545">
                    <i className="fas fa-edit"></i>
                </a>
            </span>
            <Overlay
                rootClose={true}
                onHide={this.onCancel}
                placement="right"
                target={this.state.popoverTarget}
                show={this.state.showFilingFormView}
                onEntered={() => this.stopScroll()}
                onExited={() => this.enableScroll()}
                data-test-auto="2857492B-006D-40E3-9D5A-ADE9F0A0FD7D">
                {filingformPopover}
            </Overlay>
        </div>
    }
}