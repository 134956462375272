import * as React from "react";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Card, Accordion, Button } from 'react-bootstrap'

export interface IAccordianProps {
    uniqueId: any;
    header: any;
    onHeaderClick?: () => void;
    onContentClick?: (pageNo: number) => void;
    content: any;
    loading?: boolean;
}

interface AccordianState {
    toggle: boolean;
}

export class Accordian extends React.PureComponent<IAccordianProps, AccordianState> {
    constructor(props: any) {
        super(props);
        this.state = {
            toggle: false
        }
    }

    onToggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    private renderHeading = (key: any) => {
        return <div className="card-header panel-heading bookmark-row bookmark-item" onClick={() => { this.props.onHeaderClick && this.props.onHeaderClick() }}
            style={{ background: "transparent", border: 0 }}>
            <h4 className="card-title panel-title col-md-12" style={{ display: "block", width: "100%", padding: "0px" }}>
                <div className="col-md-10" style={{ padding: "0px", lineHeight: 'initial', float: 'left' }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey={`${key}-ad`} className="acordian-toggle-anchor acordian-toggle-anchor-edit" onClick={this.onToggle}>
                        <a className="bookmark-name wordbreak ellipsis collapsed" title={this.props.header} style={{ display: "block", cursor: "pointer" }}
                            data-toggle="collapse" data-parent="#accordion" href={`#${key}`}>
                            {this.props.header}
                        </a>
                    </Accordion.Toggle>
                </div>
                <div className="col-md-2" style={{ padding: "0px", lineHeight: 'initial', float: 'left' }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey={`${key}-ad`} className="acordian-toggle-anchor acordian-toggle-anchor-edit" onClick={this.onToggle}>
                        <a className={`accordion-toggle bookmark-right ${this.state.toggle ? 'open' : 'closed'}`}
                            style={{ cursor: "pointer" }} data-toggle="collapse"
                            data-parent="#accordion" href={"#" + key + ""}></a>
                    </Accordion.Toggle>
                </div>
            </h4>
        </div>;
    }

    private renderContent = (key: any) => {
        return <Accordion.Collapse eventKey={`${key}-ad`}>
            <div id={key}>
                <div className="card-body" style={{ padding: 0 }}>
                    <LoadingOverlay style={{ height: '100%' }}>
                        <ul key={key}>
                            {
                                this.props.content
                            }
                        </ul>
                        <Loader loading={this.props.loading} />
                    </LoadingOverlay>
                </div>
            </div>
        </Accordion.Collapse>
    }

    public render() {
        let _self = this;
        return (
            <Accordion style={{ marginRight: "1px" }}>
                <div className="accordian accordian-panel" id="accordion" key={_self.props.uniqueId} style={{ margin: "0px" }}>
                    <div className="card panel">
                        {
                            this.renderHeading(_self.props.uniqueId)
                        }
                        {
                            this.renderContent(_self.props.uniqueId)
                        }
                    </div>
                </div>
            </Accordion>);
    }
}