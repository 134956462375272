import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '..//ActionTypes';
import { handleResponse } from '../Library';
import { API_BASE_URL } from '../../utils/contants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { logger } from 'src/routes/LoggedIn';
import { IOfficeLocation } from 'src/components/common/TaxReturn';

export interface IUserOfficeLocationState {
    userLocation: IOfficeLocation[];
}

export interface RequestUserOfficeLocationDropdownAction {
    type: actionTypes.REQUEST_USER_OFFICE_LOCATION;
}

export interface ReceiveUserOfficeLocationDropdownAction {
    type: actionTypes.RECEIVE_USER_OFFICE_LOCATION;
    userLocation: IOfficeLocation[];
}

const unloadedState: IUserOfficeLocationState = {
    userLocation: []
} as IUserOfficeLocationState;

type KnownAction=RequestUserOfficeLocationDropdownAction|ReceiveUserOfficeLocationDropdownAction|NotificationAction;

 export const actionCreators = {
    fetchUserOfficeLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const fetchTask = fetch(API_BASE_URL + "api/UserOfficeLocation/GetAsync", {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            dispatch({
                type: actionTypes.RECEIVE_USER_OFFICE_LOCATION, userLocation: response
            });
            // callback && callback();
        })
        .catch((error: any) => {
            logger.trackError(error);
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof (statusMessage) === "string")
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
        });
        
    addTask(fetchTask);
}
}

export const reducer: Reducer<IUserOfficeLocationState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.REQUEST_USER_OFFICE_LOCATION:
            return {
                userLocation: [...state.userLocation == undefined ? [] : state.userLocation],
            } as IUserOfficeLocationState;
        case actionTypes.RECEIVE_USER_OFFICE_LOCATION:
            return {
                userLocation: action.userLocation,
            } as IUserOfficeLocationState;
    }
    return state;
};