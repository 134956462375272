import React, { useEffect } from 'react'
import { signinRedirectCallback } from './userService'
import LoadingView from '../components/LoadingView'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string';
import { RouteConstants } from 'src/components/helper/Constants';

function SigninOidc() {
    const history = useHistory();
    useEffect(() => {
        signinRedirectCallback()
        return () => {
            if (history?.location?.search) {
                const params = queryString.parse(history.location.search);
                if (params.callback_args) {
                    history.push(params.callback_args)
                } else {
                    history.push(RouteConstants.EXTENSIONINPROCESS);
                }
            } else {
                history.push(RouteConstants.EXTENSIONINPROCESS);
            }
        }
    }, [])

    return <LoadingView />
}

export default SigninOidc
