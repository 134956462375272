export function ShowLoader(loaderTextString?: string) {
    if (loaderTextString == null) {
        loaderTextString=''
    }
    let loader = document.getElementById("divLoading");
    let loaderText = document.getElementById("loaderText");
    if (loader != null ) {
        loader.style.display = "block";
    }
    if (loaderText != null && loaderTextString != null) {
        loaderText.innerHTML = loaderTextString
    }
}

export function HideLoader() {
    let loader = document.getElementById("divLoading");
    if (loader != null) {
        loader.style.display = "none";
    }
}