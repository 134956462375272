export interface IColumnSettings {
    columnName: string;
    columnOrder: number;
    isActive: number;
}

export interface IColumnSettingsState {
    DeliveredReturnsColumnSettings: IColumnSettings[];
    ArchivedReturnsColumnSettings: IColumnSettings[];
}

export enum ReportType {
    None = 0,
    SendExtension = 1,
    InUseExtension = 2,
    Delivered = 3,
    Archived = 4
}

export const ColumnSettingsInitialState: IColumnSettingsState = {
    DeliveredReturnsColumnSettings: [],
    ArchivedReturnsColumnSettings: []
}