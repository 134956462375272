import React from  'react';
import { NavLink, Row } from 'react-bootstrap';
import * as Constants from '../../../helper/Constants';
import { ITaxReturn, IIndividualTaxReturn, EngagementType, TaxCaddyLookupResult, DocumentGroups } from '../../../common/TaxReturn';
import { IUserModel, IUserGroup } from '../../../../Core/ViewModels/User/UserViewModel';
import { ApproveForDelivery } from './ApproveForDelivery';
import { SendToERO } from './SendToERO';
import { SendForReview } from './SendForReview';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { DeliverToClient } from './DeliverToClient';
import { PrintForPaperDelivery } from './PrintForPaperDelivery';
import { DeliverToCompany } from './DeliverToCompany';
import { DeliverWithTaxCaddy } from './DeliverWithTaxCaddy'
import { validateClientEmailInfo } from '../../../helper/HelperFunctions';
import { TaxCaddyShort } from '../../../svg/LogoCollection';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { RBACKeys, RBACPermission } from 'src/components/helper/RbacConstants';
import { validator } from 'src/store/common/RBACStore';

export interface FinishPopoverProps {
    taxreturn: ITaxReturn;
    extensionUsers: IUserModel[];
    userProfile: IUserProfile;
    companySettings: ICompanySettings;
    sendToERO: () => void;
    sendForReview: (selectedUser: number) => void;
    approveForDelivery: (selectedUser: number) => void;
    deliverToClient: () => void;
    onPrintForPaperDelivery: () => void;
    getTaxCaddyLookupDetails: (taxpayerName: string, email: string, ssn: string, taxYear: number, taxClientId: string, docId: number) => void;
    deliverWithTaxcaddy: (taxCaddyLookupResult: TaxCaddyLookupResult) => void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
    companyData: CompanyStore.ICompanyData;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    extensionCompanySettings: ExtensionCompanySettings;
}
export interface FinishPopoverState {
    activeMenuItem: string
    selectedUser: number;
}
export class FinishPopover extends React.Component<FinishPopoverProps, FinishPopoverState>{

    constructor(props: FinishPopoverProps) {
        super(props);
        this.state = {
            activeMenuItem: "",
            selectedUser: 0,
        }

        this.sendForReview = this.sendForReview.bind(this);
        this.approveForDelivery = this.approveForDelivery.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.cancelFinish = this.cancelFinish.bind(this);
    }


    public cancelFinish() {
        document.body.click();
        this.setState({ activeMenuItem: "" })
    }
    public onChangeUser(event: any) {
        this.setState({ selectedUser: parseInt(event.currentTarget.value) })
    }
    public sendForReview() {
        this.props.sendForReview(this.state.selectedUser);
    }
    public approveForDelivery() {
        this.props.approveForDelivery(this.state.selectedUser);
    }
    public deliverWithTaxCaddy() {

    }

    menuToggleOpen = (event: any) => {

        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    menuToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }


    onMenuClick(menuName: string) {
        this.setState({ selectedUser: 0 });
        switch (menuName) {
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                this.setState({ activeMenuItem: menuName });
                break;
            case Constants.FinishProcessReturn.MenuItems.DeliverToClient:
            case Constants.FinishProcessReturn.MenuItems.DeliverToCompany:
            case Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy:
            case Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy:
                if (validateClientEmailInfo(this.props.taxreturn)) {
                    this.setState({ activeMenuItem: menuName });
                }
                break;
        }
    }
    getMenuContent() {
        switch (this.state.activeMenuItem) {
            case Constants.FinishProcessReturn.MenuItems.SendToEROSigner:
                return <div className="height-inherit">
                    <SendToERO
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        sendToERO={this.props.sendToERO}

                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.ApproveForDelivery:
                return <div className="height-inherit">
                    <ApproveForDelivery
                        allUsersList={this.props.extensionUsers}
                        cancelFinish={this.cancelFinish}
                        approveForDelivery={this.approveForDelivery}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.SendForReview:
                return <div className="height-inherit">
                    <SendForReview
                        allUsersList={this.props.extensionUsers}
                        cancelFinish={this.cancelFinish}
                        sendForReview={this.sendForReview}
                        selectedUser={this.state.selectedUser}
                        onChangeUser={this.onChangeUser}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToClient:
                return <div>
                    <DeliverToClient
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        deliverToClient={this.props.deliverToClient}
                        updateTaxDocument={this.props.updateTaxDocument}
                        extensionCompanySettings={this.props.extensionCompanySettings}
                        />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToCompany:
                return <div className="height-inherit">
                    <DeliverToCompany
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        deliverToClient={this.props.deliverToClient}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy:
            case Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy:
                let taxReturn: IIndividualTaxReturn = Object.assign({}, this.props.taxreturn as IIndividualTaxReturn);
                let accepted: boolean = false;
                if (taxReturn.taxCaddyLookupResultModel && taxReturn.taxCaddyLookupResultModel.isSuccess) {
                    if (taxReturn.taxpayer.name ===
                        taxReturn.taxCaddyLookupResultModel.resultObjectModel.taxpayerName
                        && (taxReturn.taxpayer.ssn && taxReturn.taxCaddyLookupResultModel.resultObjectModel.ssn
                            && taxReturn.taxpayer.ssn.replace(/[^0-9]/g, "").trim()
                            === taxReturn.taxCaddyLookupResultModel.resultObjectModel.ssn.replace(/[^0-9]/g, "").trim())
                        && taxReturn.taxpayer.email === taxReturn.taxCaddyLookupResultModel.resultObjectModel.emailAddress
                        && taxReturn.taxpayer.address === taxReturn.taxCaddyLookupResultModel.resultObjectModel.address) {
                        accepted = true;
                    }
                }

                return <div className="height-inherit">
                    <DeliverWithTaxCaddy
                        name={taxReturn.taxpayer.name}
                        ssn={taxReturn.taxpayer.ssn}
                        emailAddress={taxReturn.taxpayer.email}
                        address={taxReturn.taxpayer.address}
                        taxCaddyLookupResultModel={taxReturn.taxCaddyLookupResultModel}
                        deliverWithTaxCaddy={this.deliverWithTaxCaddy}
                        cancelFinish={this.cancelFinish}
                        deliverWithTaxcaddy={this.props.deliverWithTaxcaddy}
                        isAccepted={accepted}
                        companySetting={this.props.companySettings}
                        companyData={this.props.companyData}
                        requestCompanyLogo={this.props.requestCompanyLogo}
                    />
                </div>
            case Constants.FinishProcessReturn.MenuItems.PaperDelivery:
                return <div>
                    <PrintForPaperDelivery
                        taxreturn={this.props.taxreturn}
                        cancelFinish={this.cancelFinish}
                        onPrintForPaperDelivery={this.props.onPrintForPaperDelivery}
                        updateTaxDocument={this.props.updateTaxDocument}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        handleSaveAsDefaultOrderChange={this.props.handleSaveAsDefaultOrderChange}
                        isDefaultOrder={this.props.isDefaultOrder} />
                </div>
            case Constants.FinishProcessReturn.MenuItems.Empty:
                return <span>Please select an option to finish</span>
        }
    }


    render() {
        let engagementType: any = this.props.taxreturn.engagementType;
        
        return <div className="full-height">
            <div className="row height-inherit">

                <div className="col-lg-12 height-inherit">
                    <Row>
                        <div className="col-lg-4" style={{ paddingLeft: "0px", height: "98%" }}>
                            <div className="nav nav-tabs tabs-left height-inherit" style={{ height: "100%" }}>

                                <li className={"nav-item InternalRouting-dropdown-Menu"} style={{ marginLeft: "23px", width: "100%" }}>
                                    <span className=" fa nav-NavLink dropdown-toggle " style={{ backgroundColor: "#f7f7f7", marginLeft: "-15px", marginTop: "-6px", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "10px", width: "107%" }} role="button" data-toggle="InternalRouting" data-target="#InternalRouting-dropdown" aria-haspopup="true" aria-expanded="false">

                                        <span style={{ color: "#337ab7", fontSize: '16px' }}>Internal Routing</span>
                                        <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px', paddingRight: '10px' }}></span>
                                        <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ float: 'right', paddingLeft: '20px', paddingRight: '10px' }}></span>
                                    </span>

                                    <div className="InternalRouting nav nav-tabs tabs-left" id="InternalRouting-dropdown">

                                        <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendForReview ? "active finish-list" : "finish-list"} >
                                            <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.internalRouting} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendForReview) }}>
                                                <i className="fa fa-eye" style={{ paddingRight: "10px" }}></i>
                                                {Constants.FinishProcessReturn.MenuItems.SendForReview}</NavLink>
                                        </li>
                                        {
                                            this.props.userProfile.userId != this.props.taxreturn.partner.userId ?
                                                <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.SendToEROSigner ? "active finish-list" : "finish-list"} >
                                                    <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.internalRouting} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.SendToEROSigner) }}>
                                                        <i className='fa fa-user text-secondary' style={{ paddingRight: "10px" }}></i>
                                                        {Constants.FinishProcessReturn.MenuItems.SendToEROSigner}</NavLink>
                                                </li> : ""
                                        }
                                        {
                                            <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.ApproveForDelivery ? "active finish-list" : "finish-list"} >
                                                <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.internalRouting} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.ApproveForDelivery) }}>
                                                    <i className='fa fa-check text-secondary' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.ApproveForDelivery}</NavLink>
                                            </li>
                                        }
                                    </div>
                                </li>

                                <li className={"nav-item Delivery-dropdown-Menu "} style={{ marginLeft: "23px", width: "100%" }}>
                                    <span className=" fa nav-NavLink dropdown-toggle" role="button" style={{ backgroundColor: "#f7f7f7", marginLeft: "-15px", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "12px", width: "107%" }} data-toggle="Delivery" data-target="#Delivery-dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span onClick={this.menuToggleOpen} className="fa fa-angle-right" style={{ display: 'none', float: 'right', paddingLeft: '20px', paddingRight: '10px' }}></span>
                                        <span onClick={this.menuToggleClose} className="fa fa-angle-down" style={{ color: 'grey', float: 'right', paddingLeft: '20px', paddingRight: '10px' }}></span><span style={{ color: "#337ab7", fontSize: '16px' }}>Delivery</span>
                                    </span>

                                    <div className="Delivery tabs-left" id="Delivery-dropdown" >
                                        {
                                            engagementType == EngagementType[EngagementType.E1040] ?
                                                <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToClient ? "active finish-list" : "finish-list"} >
                                                    <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.deliveryButton} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToClient) }}>
                                                        <i className='fa fa-paper-plane text-secondary' style={{ paddingRight: "10px" }}></i>
                                                        {Constants.FinishProcessReturn.MenuItems.DeliverToClient}</NavLink>
                                                </li>
                                                :
                                                <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToCompany ? "active finish-list" : "finish-list"} >
                                                    <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.deliveryButton} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToCompany) }}>
                                                        <i className='fa fa-paper-plane text-secondary' style={{ paddingRight: "10px" }}></i>
                                                        {Constants.FinishProcessReturn.MenuItems.DeliverToCompany}</NavLink>
                                                </li>
                                        }
                                        {
                                            //(engagementType == EngagementType[EngagementType.E1040] &&
                                            //    this.props.companySettings.reportingChannelSettings[0].enabled && this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy == false) &&
                                            //<li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy ? "active finish-list" : "finish-list"} >
                                            //    <NavLink href="javascript:" onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy) }}>
                                            //        <img src="/dist/images/logos/TaxCaddy.svg" style={{ height: '16px' }} />
                                            //        <span style={{ paddingLeft: "9px" }}>{Constants.FinishProcessReturn.MenuItems.DeliverWithTaxCaddy}</span></NavLink>
                                            //</li>
                                        }
                                        {
                                            //(engagementType == EngagementType[EngagementType.E1040] &&
                                            //    this.props.companySettings.reportingChannelSettings[0].enabled && this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy == true) &&
                                            //<li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy ? "active finish-list" : "finish-list"} >
                                            //    <NavLink href="javascript:" onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy) }}>
                                            //        <img src="/dist/images/logos/TaxCaddy.svg" style={{ height: '16px' }} />
                                            //        <span style={{ paddingLeft: "9px" }}>{Constants.FinishProcessReturn.MenuItems.DeliverToTaxCaddy}</span></NavLink>
                                            //</li>
                                        }
                                        {
                                            this.props.extensionCompanySettings.deliverySettingsModel.isPrintToPDFEnabled &&
                                            <li className={this.state.activeMenuItem == Constants.FinishProcessReturn.MenuItems.PaperDelivery ? "active finish-list" : "finish-list"} >
                                                    <NavLink href="javascript:" data-resource-id={RBACKeys.extensionInProgress.deliveryButton} onClick={(e: any) => { this.onMenuClick(Constants.FinishProcessReturn.MenuItems.PaperDelivery) }}>
                                                    <i className='fa fa-print text-secondary' style={{ paddingRight: "10px" }}></i>
                                                    {Constants.FinishProcessReturn.MenuItems.PaperDelivery}
                                                </NavLink>
                                            </li>
                                        }
                                    </div>
                                </li>
                            </div>
                        </div>
                        <div className="col-lg-8 height-inherit">{
                            this.getMenuContent()
                        }

                        </div>
                    </Row>
                </div>

            </div>
        </div>
    }
}