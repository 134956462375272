import React from  'react';
import { getMaskedValue, getUnmaskedValue, getSeparators } from '../../../components/helper/HelperFunctions';

export interface ITaxIdentificationNumberProps {
    value: string;
    mask: string;
    placeholder: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    className?: string
}

interface ITaxIdentificationNumberState {
    value: string | null;
}

const MASK_PLACEHOLDER = '_';

export class TaxIdentificationNumber extends React.Component<ITaxIdentificationNumberProps, ITaxIdentificationNumberState> {
    constructor(props: any) {
        super(props);

        this.state = {
            value: null
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value, this.props.mask);
    }

    componentWillReceiveProps(nextProps: ITaxIdentificationNumberProps) {
        this.updateValue(nextProps.value, nextProps.mask);
    }

    private updateValue(value: string, mask: string) {
        const oldValue = this.state.value ? this.state.value : "";
        const newValue = getMaskedValue(value, mask);

        if (this.state.value == null || newValue != oldValue) {
            this.setState({
                value: newValue
            });
        }
    }

    private onChange = (event: any) => {
        const _self = this;

        let selectionStart = event.target.selectionStart;
        const target = event.target;

        let newValue = event.target.value;
        const separators = getSeparators(this.props.mask);

        const maskedValue = getMaskedValue(newValue, this.props.mask);

        const isAdded = _self.state.value && _self.state.value.length < newValue.length;
        const placeholderIndex = maskedValue.indexOf(MASK_PLACEHOLDER);
        const maskedValueWithoutPlaceholder = maskedValue.substring(0, placeholderIndex);

        if (isAdded && _self.state.value == maskedValue && separators.every((separator) => { return separator != maskedValue[selectionStart - 1] })) {
            selectionStart -= 1;
        }
        if (isAdded && _self.state.value != maskedValue && separators.some((separator) => { return separator == maskedValue[selectionStart - 1] })) {
            selectionStart += 1;
        }
        else if (placeholderIndex > -1 && selectionStart > maskedValueWithoutPlaceholder.length) {
            selectionStart = maskedValueWithoutPlaceholder.length;
        }

        _self.setState({
            value: maskedValue
        }, () => {
            target.setSelectionRange(selectionStart, selectionStart);

            if (_self.props.onChange) {
                _self.props.onChange(getUnmaskedValue(maskedValue, this.props.mask));
            }
        });
    }

    private onBlur = (event: any) => {
        if (this.props.onBlur) {
            this.props.onBlur(getUnmaskedValue(this.state.value ? this.state.value : '', this.props.mask));
        }
    }

    public render() {
        return <input
            type="text"
            style={{ fontSize: '12px' }}
            className={`form-control ${this.props.className}`}
            value={this.state.value ? this.state.value : ""}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled ? true : false}
            onChange={this.onChange}
            onBlur={this.onBlur}
            data-lpignore="true"
        />;
    }
}

export default TaxIdentificationNumber;
