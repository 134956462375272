import React from 'react'
import { AccessDenied, ScreenAccessDenied } from './svg/CustomGraphics'
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';

export enum UnauthorizedType {
    AccessDenied = 0,
    ScreenAccessDenied = 1,
}
interface IUnauthorizedProps {
    type: UnauthorizedType
}

class Unauthorized extends React.Component<IUnauthorizedProps & WithLogoutProps> {

    private getContentByType() {
        switch (this.props.type) {
            case UnauthorizedType.AccessDenied:
                return <><div>
                    <AccessDenied width={200} height={200} />
                </div>
                    <div>
                        <div className="accessdenied-text-header">Access Denied</div>
                        <div className='accessdenied-body' >
                            <div className="accessdenied-text-body">We are sorry, you do not have permission to access Safesend Extensions.</div>
                            <div className="accessdenied-text-body">Please contact your system administrator to update your user profile</div>
                            <button className="btn btn-primary button-sign-in" onClick={this.props.onLogout} type="button">
                                Sign in as Different User
                            </button>
                        </div>
                    </div></>
            case UnauthorizedType.ScreenAccessDenied:
                return <>
                    <div>
                        <ScreenAccessDenied width={200} height={200} />
                    </div>
                    <div>
                        <div className="accessdenied-text-header">Access Denied</div>
                        <div className='accessdenied-body' >
                            <div className="accessdenied-text-body">Please consult your system</div>
                            <div className="accessdenied-text-body">administrator for assistance.</div>
                        </div>
                    </div></>
            default:
                return <><div>
                    <AccessDenied width={200} height={200} />
                </div>
                    <div>
                        <div className="accessdenied-text-header">Access Denied</div>
                        <div className='accessdenied-body' >
                            <div className="accessdenied-text-body">We are sorry, you do not have permission to access Safesend Extensions.</div>
                            <div className="accessdenied-text-body">Please contact your system administrator to update your user profile</div>
                            <button className="btn btn-primary button-sign-in" onClick={this.props.onLogout} type="button">
                                Sign in as Different User
                            </button>
                        </div>
                    </div></>
        }
    }

    public render() {
        return (
            <div className='accessdenied-body' style={{ height: 'calc(100vh - 85px)' }}>
                {this.getContentByType()}
            </div>
        )
    }
}

export default WithLogout(Unauthorized);

