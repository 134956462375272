import React from 'react';
import { Col, Row, FormLabel } from 'react-bootstrap';
import ToggleSwitch from '../../common/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as ExtensionCompanySettingsStore } from '../../../store/settings/ExtensionCompanySettingsStore';
import { cloneDeep } from 'lodash';
import { ApplicationState } from 'src/store';

export const PrintToPDFSetting: React.FC<{}> = ({ }) => {

    const extensionCompanySettings = useSelector((state: ApplicationState) => state.extensionCompanyData.companySettings);
    const dispatch = useDispatch();

    const toggleDownloadPDF = (value: boolean) => {
        let newSettings = cloneDeep(extensionCompanySettings);
        newSettings.deliverySettingsModel.isPrintToPDFEnabled = value;
        dispatch(ExtensionCompanySettingsStore.updateExtensionCompanySettings(newSettings));
    }

    return <div className="new-main-content">
        <h3>Download PDF</h3>
        <div className="marL20">
            <Row>
                <Col className="text-left" sm={2} as={FormLabel} style={{ fontSize: "large", paddingTop: "3px" }}>
                    Enable
                </Col>
                <Col sm={4} md={3} lg={2}>
                    <ToggleSwitch
                        switched={extensionCompanySettings?.deliverySettingsModel?.isPrintToPDFEnabled ?? false}
                        handleChange={(e: any) => toggleDownloadPDF(e.currentTarget.checked)} />
                </Col>
                <Col sm={6} md={7} lg={8}>
                </Col>
            </Row>
        </div>
    </div>
}