import React from  'react'
import Autocomplete from 'react-autocomplete'

export interface IAutoSuggestTextboxProps {
    id: string;
    selectedValue: any;
    options: any;
    onChange: (selectedValue: any) => void;
    formMenuClass: string;
}

export class AutoSuggestTextbox extends React.Component<IAutoSuggestTextboxProps, {}> {

    public render() {
        return (
            <div>
                {
                    <Autocomplete
                        value={this.props.selectedValue}
                        inputProps={{ id: this.props.id + '-autocomplete', className: 'form-control' }}
                        items={this.props.options}
                        getItemValue={(item) => item.label}
                        onChange={(event, value) => this.props.onChange(value)}
                        onSelect={value => this.props.onChange(value)}
                        wrapperStyle={{ display: 'block' }}
                        renderMenu={children => (
                            <div className={this.props.formMenuClass}>
                                {children}
                            </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                            <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={item.value}
                            >{item.label}</div>
                        )}
                    />
                }</div>
        )
    }
}