import React from  'react';
import { Card, Accordion } from 'react-bootstrap';
import { PageListView } from './PageListView';
import { IBookmarkSection } from './ProcessReturnModels';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { ITaxReturn, IVoucher, ITaxingAuthority, VoucherProperty, IEFileProperty, IGroupFormBase, IFormBase } from '../../common/TaxReturn';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';
import { ExtensionCompanySettings } from './../../../Core/ViewModels/Extension/ExtensionComponentModels';

export interface IBookMarksPaneProps {
    docId: number;
    title: string;
    sections: IBookmarkSection[];
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    onPageSelect: (pageNo: number, voucherNo: number, isViewClientInfo?: boolean) => void;
    onPageDelete?: (pageNo: number) => void;
    getTitle?: (pageTitle: string, id: number) => void;
    tabType?: number;
    showAddIcon?: boolean;
    taxReturn?: ITaxReturn;
    onAddVoucher?: (voucher: IVoucher) => void;
    voucherStore?: IVoucherState;
    populateVoucherPanel?: (voucher: IVoucher) => void;
    handleDeleteZeroAmountVoucher?: () => void;
    showDeleteZeroAmountVoucherButton?: boolean;
    zeroAmountVouchersCount?: number | 0;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onAddMultipeVouchers?: (vouchers: IVoucher[]) => void;
    onUpdateFilingForm?: (callback?: () => void) => void;
    matchedForm?: IGroupFormBase;
    onEditFilingForm?: (pageNo: number) => void;
    handleSignatureChange?: (status: boolean, pageNumber: number) => void;
    handleVoucherChange?: (status: boolean, pageNumber: number) => void;
    onFilingEFileDataUpdate?: (propertyType: IEFileProperty, value: any, pageNumber: number) => void;
    onFilingVoucherDataUpdate?: (propertyType: VoucherProperty, value: any, pageNumber: number) => void;
    onResetFilingEFileData?: () => void;
    extensionCompanySettings?: ExtensionCompanySettings;
    removeDuplicateForm?: (duplicateForm: IFormBase) => void;
    duplicatePages?: number[];
}


export class BookmarksPane extends React.Component<IBookMarksPaneProps, {}> {
    constructor(props: IBookMarksPaneProps) {
        super(props);
    }

    public render() {

        const {
            docId,
            duplicatePages,
            getAllTaxingAuthorities,
            onPageSelect,
            pdfDocuments,
            sections,
            title,
            extensionCompanySettings,
            getTitle,
            handleDeleteZeroAmountVoucher,
            handleSignatureChange,
            handleVoucherChange,
            matchedForm,
            onAddMultipeVouchers,
            onAddVoucher,
            onEditFilingForm,
            onFilingEFileDataUpdate,
            onFilingVoucherDataUpdate,
            onPageDelete,
            onResetFilingEFileData,
            onUpdateFilingForm,
            populateVoucherPanel,
            removeDuplicateForm,
            showAddIcon,
            showDeleteZeroAmountVoucherButton,
            tabType,
            taxReturn,
            voucherStore,
            zeroAmountVouchersCount,
        } = this.props;

        return <div className="bookmarksPane">

            {sections.map((section, i) => {
                return <div>
                    {
                        <Accordion defaultActiveKey="0" style={{ marginRight: "1px" }}>
                            <Card>
                                <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                                    <Card.Title as="h4">
                                        <a aria-expanded="true" role="button" href="#">{section.heading}</a>
                                    </Card.Title>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body style={{ padding: 0 }}>
                                        <PageListView
                                            pages={section.pages}
                                            onPageSelect={onPageSelect}
                                            onPageDelete={onPageDelete}
                                            getTitle={getTitle}
                                            tabType={tabType}
                                            getAllTaxingAuthorities={getAllTaxingAuthorities}
                                            onUpdateFilingForm={onUpdateFilingForm}
                                            taxReturn={taxReturn}
                                            voucherStore={voucherStore}
                                            matchedForm={matchedForm}
                                            onEditFilingForm={onEditFilingForm}
                                            handleSignatureChange={handleSignatureChange}
                                            handleVoucherChange={handleVoucherChange}
                                            onFilingEFileDataUpdate={onFilingEFileDataUpdate}
                                            onFilingVoucherDataUpdate={onFilingVoucherDataUpdate}
                                            onResetFilingEFileData={onResetFilingEFileData}
                                            extensionCompanySettings={extensionCompanySettings}
                                            removeDuplicateForm={removeDuplicateForm}
                                            duplicatePages={duplicatePages ?? []}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>
                        </Accordion>
                    }
                </div>;
            })}
        </div>;
    }
};
