import React from  'react';
import { Modal, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export const ToggleModalHeader: string = "Use the toggle option to show or hide the columns in the report.";

interface IColumnSettingsProps {
    showCustomColumn: boolean;
    showLoader: boolean;
    getListItem: () => any;
    onDragStart: (event: any, listIndex: any) => void;
    onDropped: (event: any, listIndex: any) => void;
    activateOrDeactivateColumn: (event: any, name: string) => void;
    onReset: () => void;
    OnSaveCustomColumns: () => void;
}

interface IColumnSettingsState {
}

export class ColumnSettings extends React.Component<IColumnSettingsProps, IColumnSettingsState>{
    constructor(props:any) {
        super(props);
    }

    public render() {
        return (

            <Modal show={this.props.showCustomColumn} onHide={this.props.onReset} className="columnsettings-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {ToggleModalHeader}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        <ul id="item-list" className="moveable">
                            {this.props.getListItem()}
                        </ul>
                        <Loader loading={this.props.showLoader} text="Saving your changes. Please wait..." />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.OnSaveCustomColumns}
                        variant="info"
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                        <i className='fas fa-check'></i>Ok
                    </Button>
                    <Button onClick={this.props.onReset}
                        variant='default'
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                        <i className='fas fa-times'></i>Cancel
                    </Button>
                </Modal.Footer>
            </Modal>);
    }
}
