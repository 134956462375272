import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IPasswordState, IUserProfile, initialUserProfile } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { NotificationAction } from '../common/NotificationStore';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IUserProfileData {
    user: IUserProfile;
    isLoading: boolean;
}

export interface UserProfileState {
    user: IUserProfile;
}

interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_PROFILE;
}

export interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_PROFILE;
    user: IUserProfile;
}

interface UpdateUserProfileAction {
    type: actionTypes.UPDATE_USER_PROFILE;
    userDetails: IUserProfile;
}

interface UpdatePasswordAction {
    type: actionTypes.UPDATE_USER_PASSWORD;
}

type KnownAction =  RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction | NotificationAction | UpdatePasswordAction;
   

type DispatchActions =  RequestUserProfileAction | ReceiveUserProfileAction | UpdateUserProfileAction | UpdatePasswordAction ;
   

export const actionCreators = {
    requestUserProfile:
        (reload: boolean = false): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            if (reload || !getState().userProfile.emailAddress) {
                const fetchTask = fetch(`${SUITE_API_BASE_URL}api/user-management/user-details`, {
                    method: 'GET',
                    credentials: 'include',
                })
                    .then(handleResponse)
                    .then(response => {
                        return response as Promise<IUserProfile>
                    })
                    .then((data: IUserProfile) => {
                        dispatch({ type: actionTypes.RECEIVE_USER_PROFILE, user: data })
                    })
                    .catch(function (error) {
                        logger.trackError(`requestUserProfile failed with error ${error.message}`)
                    })
                addTask(fetchTask) // Ensure server-side prerendering waits for this to complete
                dispatch({ type: actionTypes.REQUEST_USER_PROFILE })
            }
        },
    saveMyAccount:
        (userInfo: IUserProfile, onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> =>
        dispatch => {
            dispatch({ type: actionTypes.REQUEST_USER_PROFILE })
            let body = userInfo
            let options: any = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                    RequestVerificationToken: (document.getElementById('RequestVerificationToken') as HTMLInputElement)
                        .value,
                },
                body: JSON.stringify(body),
            }
            fetch(`${SUITE_API_BASE_URL}api/user-management/user-details`, options)
                .then((response: any) => {
                    const data = response
                    if (onResponse) {
                        onResponse(data, null)
                    }
                })
                .catch(error => {
                    if (onResponse) {
                        onResponse(null, error)
                    }
                })
        },

    savePassword:
        (passwordState: IPasswordState, onResponse: (response: any, error: any) => void): AppThunkAction<KnownAction> =>
        dispatch => {
            let body = passwordState
            let options: any = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(body),
            }
            const fetchTask = fetch(SUITE_API_BASE_URL + 'api/user-management/user-password', options)
                .then(response => response.json())
                .then(response => {
                    dispatch({ type: actionTypes.UPDATE_USER_PASSWORD })
                    if (onResponse) {
                        onResponse(response, null)
                    }
                })
                .catch(error => {
                    if (onResponse) {
                        onResponse(null, error)
                    }
                })
            addTask(fetchTask)
        },
}

const unloadedState: IUserProfile = initialUserProfile

export const reducer: Reducer<IUserProfile> = (state: IUserProfile = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions
    switch (action.type) {
        case actionTypes.REQUEST_USER_PROFILE:
            return {
                ...unloadedState,
            } as IUserProfile
        case actionTypes.RECEIVE_USER_PROFILE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.user.userId === state.userId || state.firstName === '') {
                return {
                    ...action.user,
                    fullName() {
                        return action.user.firstName + ' ' + action.user.lastName
                    },
                } as IUserProfile;
            } else {
                return {
                    ...state,
                    fullName() {
                        return this.firstName + ' ' + this.lastName
                    },
                } as IUserProfile
            }
        case actionTypes.UPDATE_USER_PROFILE:
            let received: IUserProfile = { ...state };
            received = action.userDetails
            return received;
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
}
