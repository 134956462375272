import { UploadStatus } from '../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { ISignatureControl, SignatureControlTypes, SignatureControlRole } from '../../TaxReturn';
import { Guid } from 'src/Core/Utilities/Guid';

export interface DocumentUploadData {
    number?: number;
    name: string;
    progressBar?: number;
    size?: string;
    status?: UploadStatus;
    clientId?: string;
    uuid?: string;
    gridRowDisable: boolean;
    rowIndex?: number;
    file?: any;
    documentType: number;
    sasUrl: string;
    pdf?: IPdfDocumentFacade;
    isPDFloaded?: boolean;
    isDeleted: boolean;
}

export interface IDocumentData {
    id: number | 0;
    fileName: string,
    documentType: number,
    uploadedBy: number;
    uploadedOn: Date;
    isDeleted: boolean | false;
    documentControls: IAdditionalESignDocumentPage[];
    isModified: boolean | false;
}

export interface IOptions {
    label: any;
    value: any;
}

export interface IAdditionalESignDocumentControl extends ISignatureControl {
    required: boolean;
    tooltip: string;
}

export interface IAdditionalESignDocumentPage {
    pageNo: number,
    controls: IAdditionalESignDocumentControl[]
}

export const initialControl: IAdditionalESignDocumentControl = {
    type: SignatureControlTypes.None,
    controlGuid: "",
    top: 0,
    left: 0,
    signatureControlRole: SignatureControlRole.None,
    selectedSignStyle: "",
    selectedEroImage: "",
    tooltip: "",
    required: false
}

export class ChoosableControlData {

    id: string;
    name: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value: any;

    public static create(
        id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any
    ) {

        var data = new ChoosableControlData();
        data.id = id;
        data.name = name;
        data.top = top;
        data.left = left;
        data.width = width;
        data.height = height;
        data.value = value;

        return data;
    }

    public static createNullObject() {

        var data = new ChoosableControlData();
        data.id = "";
        data.name = "";
        data.top = 0;
        data.left = 0;
        data.width = 0;
        data.height = 0;
        data.value = { checked: false, value: '' };
        return data;
    }
}
export interface IAdditionalESignDocumentPage {
    pageNo: number,
    controls: IAdditionalESignDocumentControl[]
}

export interface ICheckBoxControl extends IAdditionalESignDocumentControl {
    page: number;
    disabled: boolean;
    width: number;
    height: number;
    items: ChoosableControlData[];
}

export interface IRadioButtonControl extends IAdditionalESignDocumentControl {
    page: number;
    disabled: boolean;
    width: number;
    height: number;
    items: ChoosableControlData[];
}

export class EsignControlHelper {

    public static getControlModal(type: SignatureControlTypes, role: SignatureControlRole, left: number, top: number, height?: number, width?: number, customData?: any, name?: string) {

        let control: IAdditionalESignDocumentControl = {} as IAdditionalESignDocumentControl;

        control = {
            controlGuid: Guid.newGuid().toString(),
            type: type,
            top: top,
            left: left,
            signatureControlRole: role,
            required: true,
            tooltip: ""
        }
        return control;
    }
}
