import * as React from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { ReportProblemProperty } from "./ReportProblemModel";

interface IParterProps {
    partnerName: string;
    partnerEmail: string;
    handleValueChange(item: ReportProblemProperty, value: string): void;
    disabled: boolean;
}

export const PartnerDetails = (props: IParterProps) => {
    return (
        <Row className="report-problem-field margin-bottom-20">
            <Col sm={2} >
                <div className="font700">Company Name</div>
            </Col>
            <Col sm={4}>
                <FormControl
                    type="text"
                    value={props.partnerName}
                    placeholder="Enter Partner Name"
                    onChange={(event: any) => {
                        props.handleValueChange(ReportProblemProperty.partnerName, event.target.value);
                    }}
                    onBlur={(event: any) => {
                        props.handleValueChange(ReportProblemProperty.partnerName, event.target.value);
                    }}
                    disabled={props.disabled}
                />
            </Col>

            <Col sm={2} >
                <div className="font700">Company Email</div>
            </Col>
            <Col sm={4}>
                <FormControl
                    type="text"
                    value={props.partnerEmail}
                    placeholder="Enter Partner Email"
                    onChange={(event: any) => {
                        props.handleValueChange(ReportProblemProperty.partnerEmail, event.target.value);
                    }}
                    onBlur={(event: any) => {
                        props.handleValueChange(ReportProblemProperty.partnerEmail, event.target.value);
                    }}
                    disabled={props.disabled}
                />
            </Col>
        </Row>
    );
};
