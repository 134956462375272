import { Log, UserManager } from 'oidc-client'
import { UserAutoLogoutConstants } from '../components/helper/Constants'
import { ILocalStore } from '../Core/Utilities/LocalStore';
import { container } from '../Startup/inversify.config';
import { TYPES } from '../Startup/types';
import config from './config'
import { ISessionLocalStore } from 'src/Core/Utilities/SessionStore';

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);
const sessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

Log.logger = console
Log.level = Log.ERROR

export const createUserManager = () => new UserManager({ ...config, monitorSession: false })

export const createUserManagerWithRedirect = (redirect_uri: string) => new UserManager({ ...config, monitorSession: false, redirect_uri: redirect_uri })

const tokenElementId = 'RequestVerificationToken'
/**
 * @deprecated remove all usages of this function during the tech debt sprint, instead get token from auth store or oidc-client's userManager
 * @param token access token to render on document root
 */
export const renderTokenElement = (token: string) => {
    const inputNode = document.getElementById(tokenElementId) as HTMLInputElement
    if (inputNode) {
        inputNode.value = token
    } else {
        const node = document.createElement('input') as HTMLInputElement
        node.setAttribute('type', 'hidden')
        node.setAttribute('id', tokenElementId)
        node.setAttribute('name', tokenElementId)
        node.value = token
        document.getElementById('root')?.appendChild(node)
    }
}

export function getRequestVerificationToken() {
    return (document.getElementById('RequestVerificationToken') as HTMLInputElement).value;
}
 export function getcookie(cookiename: string) {
        var cookiestring = document.cookie;
        var cookiearray = cookiestring.split(';');
        for (var i = 0; i < cookiearray.length; ++i) {
            if (cookiearray[i].trim().match('^' + cookiename + '=')) {
                return cookiearray[i].replace(`${cookiename}=`, '').trim();
            }
        } return null;
}
export function  resetCookie(cookiename: string, value: string){
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(';');
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match('^' + cookiename + '=')) {
            if (cookiearray[i])
                document.cookie = `${cookiename}=${value}`.trim();
        }
    }
}

export function setAutoLogout(value:any) {
    localStorage.set(UserAutoLogoutConstants.UserAutoLogoutCookie, value);
}
export function removeAutoLogout() {
    localStorage.remove(UserAutoLogoutConstants.UserAutoLogoutCookie);
}
export function getAutoLogout(){
    return localStorage.get(UserAutoLogoutConstants.UserAutoLogoutCookie);
}

export const preLogoutSesssionClear = () => {
    sessionStore.remove("isVisited");
}