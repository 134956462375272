import React, { FC, useEffect, useState } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Bowser from "bowser";
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { Button } from 'react-bootstrap';
import { actionCreators } from '../../../store/userManagement/UserLoginHistoryStore';
import Moment from 'moment';


const MyLoginHistoryTable: FC = () => {
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const dispatch = useDispatch();
    const userLoginHistory= useSelector((state: ApplicationState) => state.userLoginHistory);

    const defaultType=(cell: any, row: any) =>{
      return <div title={cell} className="ellipsis">{cell}</div>;
  }
  const renderShowsTotal=(start: number, to: number)=> {
    return <p>
        Showing <b>{start}</b> to <b>{to}</b> of <b>{userLoginHistory.loginHistoryCount}</b> entries
        </p>
  }
      
    const columns = [
      {
          header: '',
          key: 'index',
          isKey: true,
          dataFormat: defaultType,
          columnClassName: '',
          toolTip: false,
          isHidden: true

      },
      {
          header: 'Browser',
          key: 'clientApp',
          isKey: false,
          dataFormat:defaultType,
          columnClassName: 'width30p',
          toolTip: false,
          isHidden: false
      },
      {
          header: 'IP Address',
          key: 'ipAddress',
          isKey: false,
          dataFormat: defaultType,
          columnClassName: '',
          toolTip: false,
          isHidden: false
      },
      {
          header: 'LogIn Date',
          key: 'loggedInOn',
          isKey: false,
          dataFormat: defaultType,
          columnClassName: '',
          toolTip: false,
          isHidden: false
          
      },
      {
          header: 'LogOut Date',
          key: 'loggedOutOn',
          isKey: false,
          dataFormat: defaultType,
          columnClassName: '',
          toolTip: false,
          isHidden: false

      }];
  
    const getTableData=()=> {
       const deviceId=userLoginHistory.deviceId;
        const data = _.cloneDeep(userLoginHistory.loginHistories);
        data.forEach(loginHistory => {
            let browser = Bowser.getParser(loginHistory.clientApp);
            loginHistory.clientApp = browser.getBrowserName() + "(" + browser.getBrowserVersion() + ")" + " on " + browser.getOSName() + "(" + browser.getOSVersion() + ")";
            loginHistory.loggedInOn = Moment.utc(loginHistory.loggedInOn).local().format('MM/DD/YYYY hh:mm:ss A');
            if (loginHistory.deviceId === deviceId)
                loginHistory.loggedOutOn = "Current Device";
            else if (!loginHistory.loggedOutOn)
                loginHistory.loggedOutOn = "Logged In";
            else {
                loginHistory.loggedOutOn = Moment.utc(loginHistory.loggedOutOn).local().format('MM/DD/YYYY hh:mm:ss A');
            }
        })        
        return data;
    }

    const  setNoContent=()=>{
      return "No Login History"
  }

   
    const handlePageChange = (page:number) => {
        dispatch(actionCreators.requestUserLoginHistory(page))
        setCurrentPageNumber(page);
    };
    let pagingationPos: "both" | "top" | "bottom" | undefined = "bottom";

    const getColumnWidth=(cell: any, row: any, rowIndex: number, colIndex: number)=> {
      if(colIndex === 1)
          return { width: '30%' };
      return {}
  }

    const options = {
      sizePerPage: 15,
      page: currentPageNumber,
      onPageChange: (page: number) => handlePageChange(page),
      paginationShowsTotal: renderShowsTotal,
      noDataText: setNoContent(),
      paginationPosition: pagingationPos, // default is bottom, top and both is all available
      nextPage: <span className="fa fa-angle-right" />,
      prePage: <span className="fa fa-angle-left" />,
      firstPage: <span className="fa fa-angle-double-left" />,
      lastPage: <span className="fa fa-angle-double-right" />
  };
  

    return (
        <div>
        <div className='textalign-right'>
        <Button
                    onClick={()=>{handlePageChange(1)}}
                    className="btn-white btn-only marR10"
                    style={{ paddingLeft: '12px' }}>
                    <span className='fas fa-redo-alt' style={{ padding: '4px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}></span>
        </Button>
        </div>
        <div className="pos-relative">
            <div>
                <LoadingOverlay>

                <BootstrapTable
                    data={getTableData()}
                    remote={true}
                    fetchInfo={{ dataTotalSize: userLoginHistory.loginHistoryCount }}
                    options={options}
                    striped
                    hover={true}
                    pagination={true}
                    
                >
                    {columns.map((value, index) => {                      
                        return <TableHeaderColumn
                            key={index}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            tdStyle={getColumnWidth}
                        >{value.header}
                        </TableHeaderColumn>
                    })}
                </BootstrapTable>
                <Loader
                        classNamePrefix="sm"
                        text=""
                        loading={!userLoginHistory.isLoginHistoryLoaded}
                    />
                </LoadingOverlay>
            </div>
        </div >
        </div>
    )
}

export default MyLoginHistoryTable