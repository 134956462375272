import { API_BASE_URL } from 'src/utils/contants';
import { IVoucher, ITaxReturn } from '../../../common/TaxReturn';

export class VoucherProxy {

    public saveCustomVoucher(voucherInfo: IVoucher, taxReturn: ITaxReturn, PDFStream: any, isAdd: boolean): Promise<boolean> {
        return new Promise<boolean>((resolve) => { 
            const formData = new FormData();
            formData.append('files', PDFStream, 'customVoucher' + taxReturn.id + '_' + new Date().getTime() + '.pdf');
            let voucherData = JSON.stringify(voucherInfo);
            formData.append('customVoucher', voucherData);
            let taxReturnData = JSON.stringify(taxReturn);
            formData.append('taxDocument', taxReturnData);
            formData.append('isAddCustomVoucher', '' + isAdd);
            let options: any = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                },
                body: formData
            };
            fetch(`${API_BASE_URL}api/vouchers/ProcessCustomVoucher`, options).then((Response) => { resolve(Response.ok); });            
            });
    }    
}