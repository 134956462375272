import { DocumentStatus, initialTaxReturnState, ITaxReturn } from "../../../components/common/TaxReturn";
import { actionTypes } from "../../../store/ActionTypes";
import { GroupAccess, GroupedReturnsModel, GroupInfo, HeaderGroupAccess, initialGroupedReturnsModel } from "./ComponentModels";
import { IFilters } from '../../../components/reports/Filters';
import { SetAccessType } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { RecycleTaxReturns } from "../../../components/common/RecycleTaxReturns";
import { IUserBaseModel } from "../User/UserViewModel";

export interface GroupedExtensionStoreState {
    groupInfo: GroupInfo[];
    isLoading: boolean;
    message: string;
    groupAccess: GroupAccess[];
    groupData: GroupInfo;
}

export interface RequestGroupConfigurationAction {
    type: actionTypes.REQUEST_GROUP_NAME;
    message: string;
}

export interface ReceiveGroupConfigurationAction {
    type: actionTypes.RECEIVE_GROUP_NAME;
    groupInfo: GroupInfo[];
}

export interface RequestAddGroupNameAction {
    type: actionTypes.REQUEST_ADD_GROUP_NAME;
}

export interface ReceiveAddedGroupNameAction {
    type: actionTypes.RECEIVE_ADDED_GROUP_NAME;
    groupInfo: GroupInfo;
}
export interface ErrorGroupNameAction {
    type: actionTypes.ERROR_GROUP_NAME;
    reason: string;
}

export interface ReceiveGroupAccessAction {
    type: actionTypes.RECEIVE_GROUP_ACCESS;
    groupAccess: GroupAccess[];
}
export interface ErrorGroupAccessAction {
    type: actionTypes.ERROR_GROUP_ACCESS;
    reason: string;
}
export interface RequestGroupAccessAction {
    type: actionTypes.REQUEST_GROUP_ACCESS;
    message: string;
}
export interface RequestGroupInfoAction {
    type: actionTypes.REQUEST_GROUP_INFO;
    message: string;
}

export interface ReceiveGroupInfoAction {
    type: actionTypes.RECEIVE_GROUP_INFO;
    groupData: GroupInfo;
}

export interface GroupedExtensionsProcessStoreState {
    [index: number]: {
        groupLevelReturnsData: GroupedReturnsModel;
        isLoading: boolean;
        error: boolean;
        message: string;
    };
}

export const unloadedGroupedExtensionsProcessStoreState: GroupedExtensionsProcessStoreState = {
    [-1]: {
        groupLevelReturnsData: initialGroupedReturnsModel,
        isLoading: false,
        error: false,
        message: '',
    },
};

export interface SendGroupedExtensionsModel {
    groupId: number;
    groupName: string;
    taxSoftware: string;
    createdBy: string;
    createdOn: Date;
    status: string;
    setAccess: string;
    taxReturns: ITaxReturn[];
}

export interface SendGroupedExtensionsTableModel {
    count: number;
    sendGroupedExtensions: SendGroupedExtensionsModel[];
}

export interface SendGroupedExtensionsStoreState {
    sendGroupedExtensionsTableModel: SendGroupedExtensionsTableModel;
    query: string;
    totalRowCount: number;
    loading: boolean;
    error?: boolean;
}

export interface SendGroupedExtensionsPagesStoreState {
    [index: string]: SendGroupedExtensionsStoreState;
}

export interface SendGroupedExtensionsHeaderStoreState {
    setAccessData: SetGroupAccessState;
}

export const initialSendGroupedExtensionsHeaderStoreState: SendGroupedExtensionsHeaderStoreState = {
    setAccessData: {
        groupAccessList: [],
        isLoading: false,
    },
};

export interface SetGroupAccessState {
    groupAccessList: HeaderGroupAccess[];
    isLoading: boolean;
}

export interface GroupedExtensionsDocuments {
    assignments: ITaxReturn[];
    loading: boolean;
}

export interface RequestGroupedReturnsDocumentsAction {
    type: actionTypes.REQUEST_GROUPED_RETURNS_DOCUMENTS;
    groupId: number;
}

export interface ReceiveGroupedReturnsDocumentsAction {
    type: actionTypes.RECEIVE_GROUPED_RETURNS_DOCUMENTS;
    data: ITaxReturn[];
    groupId: number;
}

export interface RequestSendGroupedReturnsAction {
    type: actionTypes.REQUEST_SEND_GROUPED_RETURNS;
    query: string;
}

export interface ReceiveSendGroupedReturnsAction {
    type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS;
    query: string;
    table: SendGroupedExtensionsTableModel;
}

export interface ReceiveSelectedDocumentForDeliveryAction {
    type: actionTypes.RECEIVE_SELECTED_DOCUMENT_FOR_DELIVERY;
    documentIds: number[];
}

export interface RemoveSelectedDocumentForDeliveryAction {
    type: actionTypes.REMOVE_SELECTED_DOCUMENT_FOR_DELIVERY;
    documentIds: number[];
}

export interface ResetSendGroupedReturnsAction {
    type: actionTypes.RESET_SEND_GROUPED_RETURNS;
}

export const unloadedSendGroupedExtensionsStoreState: SendGroupedExtensionsStoreState = {
    sendGroupedExtensionsTableModel: {
        sendGroupedExtensions: [],
        count: 0,
    },
    loading: true,
    query: '',
    totalRowCount: 0,
    error: false,
};

export interface RequestSendGroupedReturnsPagesAction {
    type: actionTypes.REQUEST_SEND_GROUPED_RETURNS_PAGES;
    query: string;
}

export interface ReceiveSendGroupedReturnsPagesAction {
    type: actionTypes.RECEIVE_SEND_GROUPED_RETURNS_PAGES;
    query: string;
    table: SendGroupedExtensionsTableModel;
    totalRowCount: number;
}

export interface RequestGroupedAccessAction {
    type: actionTypes.REQUEST_GROUP_ACCESS_BY_GROUP_ID;
    groupIds: number[];
}

export interface ReceiveGroupedAccessAction {
    type: actionTypes.RECEIVE_GROUP_ACCESS_BY_GROUP_ID;
    data: HeaderGroupAccess[];
    groupIds: number[];
}

export interface DeleteGroupInfoAction {
    type: actionTypes.DELETE_GROUP_INFO;
    groupIds: number[];
}

export interface GroupedTaxDocumentStoreState {
    [index: number]: {
        taxReturn: ITaxReturn;
        isLoading: boolean;
        error: boolean;
        message: string;
        isFullyLoaded: boolean;
        fileName?: string;
    };
}

export const unloadedGroupedTaxDocumentStoreState: GroupedTaxDocumentStoreState = {
    [0]: {
        taxReturn: initialTaxReturnState,
        isLoading: false,
        error: false,
        message: '',
        isFullyLoaded: false,
    },
};

export interface RequestGroupControllerSaveAction {
    type: actionTypes.REQUEST_GROUPLEVEL_SAVE;
    updating: boolean;
}

export interface RecieveGroupControllerSaveAction {
    type: actionTypes.RECEIVE_GROUPLELVEL_SAVE_RESULT;
    success: boolean;
    updating: boolean;
}

export interface RequestGroupControllerInfoAction {
    type: actionTypes.REQUEST_GROUP_LEVEL_DATA;
    loading: boolean;
    groupId: number;
}

export interface RecieveGroupControllerInfoAction {
    type: actionTypes.RECEIVE_GROUP_LEVEL_DATA;
    loading: boolean;
    groupId: number;
    isModified: boolean;
    groupControllerInfoModel: GroupedReturnsModel;
}
export interface RequestGroupedTaxDocumentAction {
    type: actionTypes.REQUEST_GROUPED_TAX_DOCUMENT;
    id: number;
}

export interface ReceiveGroupedTaxDocumentAction {
    type: actionTypes.RECEIVE_GROUPED_TAX_DOCUMENT;
    id: number;
    taxDocument: ITaxReturn;
    isEditClientInfoRequest?: boolean;
}

export interface ErrorGroupedTaxDocumentAction {
    type: actionTypes.ERROR_GROUPED_TAX_DOCUMENT;
    ids: number[],
    message: string
}

export interface MarkAsReadyForDeliveryAction {
    type: actionTypes.MARK_AS_READY_FOR_DELIVERY;
    id: number;
}

export interface UpdateGroupedTaxDocumentStatusAction {
    type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_STATUS;
    ids: number[];
    status: DocumentStatus;
}

export interface UpdateSendGroupedReturnsDocumentStatusUpdate {
    type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_STATUS;
    ids: number[];
    status: DocumentStatus;
}
export interface UpdateGroupedTaxDocumentAssignAction {
    type: actionTypes.UPDATE_GROUPED_TAX_DOCUMENT_ASSIGN;
    ids: number[];
    assignTo: number;
    assignedUser: IUserBaseModel;
    status: DocumentStatus;
}

export interface UpdateSendGroupedReturnsDocumentAssignUpdate {
    type: actionTypes.UPDATE_SEND_GROUPED_DOCUMENT_ASSIGN;
    ids: number[];
    assignTo: number;
    assignedUser: IUserBaseModel;
    status: DocumentStatus;
}

export interface RecycleGroupedExtensionsState {
    recycleGroupedExtensionsTableModel: RecycleGroupedExtensionsTableModel;
    query: string;
    totalRowCount: number;
    loading: boolean;
    error: boolean;
}

export interface RecycleGroupedExtensionsTableModel {
    count: number,
    recycleGroupedExtensions: RecycleGroupedExtensionsModel[]
}

export interface RecycleGroupedExtensionsModel {
    groupId: number,
    groupName: string,
    taxSoftware: string,
    createdBy: string,
    createdOn: Date,
    status: string,
    setAccess: string,
    taxReturns: RecycleTaxReturns[]
}

export const unloadedRecycleGroupedExtensionsStoreState: RecycleGroupedExtensionsState = {
    recycleGroupedExtensionsTableModel: {
        recycleGroupedExtensions: [],
        count: 0
    } as RecycleGroupedExtensionsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0,
    error: false
} as RecycleGroupedExtensionsState;

export interface RequestRecycleGroupedExtensionsAction {
    type: actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS;
    query: string;
}

export interface ReceiveRecycleGroupedExtensionsAction {
    type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS;
    query: string;
    table: RecycleGroupedExtensionsTableModel;
}

export interface RequestRecycleGroupedExtensionsPagesAction {
    type: actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS_PAGES;
    query: string;
}

export interface ReceiveRecycleGroupedExtensionsPagesAction {
    type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS_PAGES;
    query: string;
    table: RecycleGroupedExtensionsTableModel;
    totalRowCount: number;
}

export interface RecycleGroupedExtensionsDictionary {
    [index: string]: RecycleGroupedExtensionsState;
}

export interface RecycleGroupedExtensionsPageState {

    page: number;
    pageSize: number;
    selectedRows: number[];
    filter: IFilters;
    searchString: string;
    sortName: string;
    sortOrder: string;
    saveFilterShow: boolean,
    deSelectedRows: number[];
    isSelectAllChecked: boolean;

    filterGroupName: string,
    filterSoftwareType: string,
    filterCreatedBy: string,
    filterSetAccess: SetAccessType,
    filterCreatedOn?: Date

    isBulkSelectionEnabled: boolean;
    showBulkSelectionMessage: boolean;

    selectedDocumentsId: number[];
    refreshDelay: boolean;
    selectedGroupIds: number[];
}

export interface RequestRecycleGroupedReturnsPagesAction {
    type: actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS_PAGES;
    query: string;
}

export interface ReceiveRecycleGroupedReturnsPagesAction {
    type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS_PAGES;
    query: string;
    table: RecycleGroupedExtensionsTableModel;
    totalRowCount: number;
}

export const unloadedRecycleGroupedExtensionsPagesStoreState: RecycleGroupedExtensionsState = {
    recycleGroupedExtensionsTableModel: {},
    query: '?',
    loading: false,
    totalRowCount: 0
} as RecycleGroupedExtensionsState;

