import { connect } from 'react-redux';
import * as  ClientMessageStore from '../../../store/settings/ClientMessageStore';
import * as  EmailMessageStore from '../../../store/settings/EmailMessageStore';
import * as Component from '../ClientInstruction';
import { ApplicationState } from '../../../store';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as ExtensionCompanySettingsStore from '../../../store/settings/ExtensionCompanySettingsStore';

export default connect(
    (state: ApplicationState) => ({
        clientMessageList: state.clientMessage,
        emailMessageList: state.emailMessage,
        companyData: state.companyData,
        extensionCompanyData: state.extensionCompanyData
    }),
    {
        ...ClientMessageStore.ActionCreators,
        ...EmailMessageStore.ActionCreators,
        ...CompanyStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...ExtensionCompanySettingsStore.ActionCreators
    }
)(Component.ClientInstruction as any);