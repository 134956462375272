import './Core/Services/FetchInterceptorService'
import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'jquery'
import 'bootstrap'
import "./index.css";
import './assets/scripts/polyfill.min.js'
import './assets/scripts/tinymce.min.js'
import App from "./App";
import reportWebVitals from './reportWebVitals'
import './assets/custom/custom.css'
import './assets/custom/site.css'
import './assets/custom/glyphicons.css'
import './assets/custom/all.css'

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (module.hot) {
    module.hot.accept();
  }