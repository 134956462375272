import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import { SignalRWebSocket } from './SignalRWebSocket';
import * as UserProfileStore from '../store/userManagement/UserProfileStore';
import * as CompanyStore from '../store/company/CompanyStore';
import * as TaxDocumentStore from '../store/common/TaxDocumentStore';
import * as DeliveredReturnsStore from '../store/reports/DeliveredReturnsStore';
import * as CustomColumnStore from '../store/common/CustomColumnStore';


export default connect(
    (state: ApplicationState) => ({
        profile: state.userProfile,
        companyData: state.companyData,
        taxDocuments: state.taxDocuments,
        deliveredReturns: state.deliveredReturns,
        customColumn: state.customColumn,
    }),
    {
        ...UserProfileStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...DeliveredReturnsStore.actionCreators,
        ...CustomColumnStore.actionCreators,
    }
)(SignalRWebSocket as any);