import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { DeliveredReturnsState, DeliveredReturnsTableModel } from './DeliveredReturnsState';
import { ISignerModel, ClientTypes, SignatureStatus } from '../../components/common/TaxReturn';
import { IDeliveredTaxDocument, IDeliveredTaxRetunrs } from '../../components/common/DeliveredTaxReturns';
import {
    RequestTaxDocumentAction, ReceiveTaxDocumentAction, ArchiveTaxDocumentAction, TaxReturnSource,
    UpdateTaxDocumentCustomColumnValueAction, SendReminderTaxDocumentAction, RecallTaxDocumentAction,
    ResetDeliveredReturnsAction, IProcessReturnViewModel, ReceiveTaxDocumentsAction
} from '../common/TaxDocumentStore';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { actionTypes } from '../ActionTypes';
import {
    RequestDeliveredReturnsAction, ReceiveDeliveredReturnsAction, ReceiveDeliveredReturnsPagesAction, RequestDeliveredReturnsPagesAction,
    ReceiveNextSignerDetailsAction, UnlockLockedDocument
} from "./KnownTypes";
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { DeliveredReturnsConstants, PreviewConstants } from '../../components/helper/Constants';
import { HideLoader } from '../../components/helper/Loader';
import { isInValidSignatureStatus, openWindowWithPostRequest } from '../../components/helper/HelperFunctions';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getRequestVerificationToken } from 'src/oidcClient/helpers';

type KnownAction = RequestDeliveredReturnsAction | ReceiveDeliveredReturnsAction | ArchiveTaxDocumentAction
    | RecallTaxDocumentAction | ReceiveTaxDocumentAction | UpdateTaxDocumentCustomColumnValueAction
    | ResetDeliveredReturnsAction | SendReminderTaxDocumentAction;

type AllKnownAction =
    RequestDeliveredReturnsAction |
    ReceiveDeliveredReturnsAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentsAction |
    ArchiveTaxDocumentAction |
    RecallTaxDocumentAction |
    RequestTaxDocumentAction |
    ReceiveDeliveredReturnsPagesAction |
    RequestDeliveredReturnsPagesAction |
    UpdateTaxDocumentCustomColumnValueAction |
    NotificationAction |
    ReceiveNextSignerDetailsAction |
    UnlockLockedDocument |
    ResetDeliveredReturnsAction;

let deliveredReturnsAbortController = new AbortController();

export const actionCreators = {
    requestDeliveredReturns: (query: string, reload: boolean = false, callback?: () => void, reloadOnNoDataFound?: () => void):
        AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            let state = getState();
            if (reload || query !== state.deliveredReturns.query) {

                const page = state.deliveredReturnsPages[query];
                if (!reload && page && page.deliveredReturnTableModel.hasOwnProperty('count')) {
                    dispatch({
                        type: actionTypes.REQUEST_DELIVERED_RETURNS,
                        query: query,
                    });
                    dispatch({
                        type: actionTypes.RECEIVE_DELIVERED_RETURNS,
                        query: query,
                        table: page.deliveredReturnTableModel,
                    });
                    return;
                }
                deliveredReturnsAbortController.abort();
                deliveredReturnsAbortController = new AbortController();

                const fetchTask = fetch(`${API_BASE_URL}api/Reports/DeliveredReturns/GetDeliveredReturns` + query, {
                    method: 'GET',
                    credentials: 'include',
                    signal: deliveredReturnsAbortController.signal
                })
                    .then(handleResponse)
                    .then(response => response as Promise<DeliveredReturnsTableModel>)
                    .then(data => {

                        if (data.count === 0 && reloadOnNoDataFound) {
                            reloadOnNoDataFound();
                        }
                        else {

                            let documents: ITaxReturn[] = [];

                            data.documents.forEach((model, i) => {
                                model.document.accessCode = {
                                    accessCodeDetails: [], clientEvents: []
                                };
                                documents.push(model.document);
                            });

                            dispatch({
                                type: actionTypes.RECEIVE_TAX_DOCUMENTS,
                                taxDocuments: documents,
                                source: TaxReturnSource.DeliveredReturns,
                            });

                            dispatch({
                                type: actionTypes.RECEIVE_DELIVERED_RETURNS,
                                query: query,
                                table: data,
                            });
                            dispatch({
                                type: actionTypes.RECEIVE_DELIVERED_RETURNS_PAGES,
                                query: query,
                                table: data,
                                totalRowCount: data.count,
                            });
                            if (callback) {
                                callback();
                            }
                        }
                    })
                    .catch((error) => {
                        dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                        if(error.name!=='AbortError'){
                            logger.trackError(`requestDeliveredReturns failed for query: ${query}, with error ${error.message}`);
                        }
                        if (callback) {
                            callback();
                        }
                    });
                addTask(fetchTask);
                dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS, query: query });
                dispatch({ type: actionTypes.REQUEST_DELIVERED_RETURNS_PAGES, query: query });
            }
        },
    updateTaxDocumentCustomColumnValue: (id: number, customColumn: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE, id: id, customColumn: customColumn });
    },

    exportDeliveredReturnsAsExcel: (
        query: string,
        callback: () => void,
        rbacKey: string): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/ExportExcelDeliveredReturns` + query, {
                credentials: 'include',
                headers: {
                    'X-Resource-Id': rbacKey
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    const urlParams = new URLSearchParams(query);
                    const fileName = urlParams.has('isArchived') && urlParams.get('isArchived') == 'true'
                        ? 'ArchivedExtensions.xlsx'
                        : 'DeliveredExtensions.xlsx';
                    link.setAttribute('download', fileName);
                    link.id = "download_link";
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    logger.trackError(`exportDeliveredReturnsAsExcel failed for query: ${query}, with error ${error.message}`);
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
        },
    generateTaxpayerView: (
        taxdocument: ITaxReturn,
        rbacKey: string,
        clientType?: ClientTypes):
        AppThunkAction<KnownAction> => (dispatch, getstate) => {
            const modelData: IProcessReturnViewModel = {
                taxDocument: taxdocument,
                parts: [],
                isK1Replaced: false,
                isK1Restored: false,
                isMFJChanged: false,
                clientType: ClientTypes.Undefied
            };
            modelData.taxDocument = taxdocument;
            modelData.parts = [];
            modelData.clientType = clientType;
            const formData = new FormData();
            formData.append('taxDocument', JSON.stringify(modelData));
            let options: any = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Resource-Id': rbacKey
                },
                body: JSON.stringify(modelData)
            };
            const fetchTask = fetch(`${API_BASE_URL}api/ProcessReturn/GenerateTaxpayerViewAsync`, options)
                .then(response => response.json())
                .then((data) => {
                    HideLoader();
                    openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView, true);
                });
            addTask(fetchTask);
        },
    requestNextSignerDetails: (taxDocumentId: number, signatureStatus: SignatureStatus, resourceId: string): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/DeliveredReturns/GetNextSignerDetailsAsync/` + taxDocumentId + '/' + signatureStatus, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Resource-Id': resourceId
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<ISignerModel>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_NEXT_SIGNER_DETAILS, id: taxDocumentId, nextSignerDetails: data });
            });

        addTask(fetchTask);
    },
    unlockDocument: (documentid: number, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/DeliveredReturns/UnlockDocument/` + documentid, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.UNLOCK_LOCKED_DOCUMENT, documentId: documentid });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.StatusMessage.UnlockDocumentSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`unlockDocument failed for documentid: ${documentid}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    updateDeliveredReturns: (): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.RESET_DELIVERED_RETURNS });
        dispatch(actionCreators.requestDeliveredReturns(state.deliveredReturns.query, true));
    },
    requestScreenShareUrl: (clientGuid: string): AppThunkAction<AllKnownAction> => (dispatch, getstate) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/GetScreenShareUrl/` + clientGuid, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                HideLoader();
                openWindowWithPostRequest(data.url, getRequestVerificationToken(), 'CPAToken', PreviewConstants.Scope.ClientView);
            });

        addTask(fetchTask);
    },
    generateTimeBasedOTP: (clientGUID: string, rowIndex: number, callBack?: (rowIdx: number, otp: string) => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/OTP/GetTimeBasedOtp/` + clientGUID, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<string>)
            .then(data => {
                if (callBack) {
                    callBack(rowIndex, data);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`generateTimeBasedOTP failed for clientGUID: ${clientGUID}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
};

const unloadedState: DeliveredReturnsState = {
    deliveredReturnTableModel: {
        documents: [],
        count: 0
    } as DeliveredReturnsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as DeliveredReturnsState;

const unloadedDeliveredTaxDocument: IDeliveredTaxDocument = {
    customColumn: '',
    document: {},
    downloadsCount: 0,
    lastReminderOn: undefined,
    retentionPeriod: undefined,
    signedCount: 0,
    bulkDownloadCount: 0
} as IDeliveredTaxDocument;


export const reducer: Reducer<DeliveredReturnsState> = (state: DeliveredReturnsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_DELIVERED_RETURNS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as DeliveredReturnsState;
        case actionTypes.RECEIVE_DELIVERED_RETURNS:
            return {
                query: action.query,
                deliveredReturnTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as DeliveredReturnsState;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
        case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            return updateState(action.type, state, action);

        case actionTypes.ARCHIVE_TAX_DOCUMENT:
        case actionTypes.RECALL_TAX_DOCUMENT:
        case actionTypes.RESET_DELIVERED_RETURNS:
            return clearTaxReturns(state);
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

export const nextSignerReducer: Reducer<ISignerModel> = (state: ISignerModel = unloadedNextSignerDetailsState, incomingAction: Action) => {
    const action = incomingAction as AllKnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_NEXT_SIGNER_DETAILS:
            var received = { ...state };
            received = action.nextSignerDetails;
            return received;
    }
    return state || unloadedNextSignerDetailsState;
}

const unloadedNextSignerDetailsState: ISignerModel =
    {

    } as ISignerModel;

function updateState(type: actionTypes, state: DeliveredReturnsState, action: KnownAction): DeliveredReturnsState {
    let i: number = -1;
    let oldDocument: IDeliveredTaxDocument = unloadedDeliveredTaxDocument;
    let document: IDeliveredTaxRetunrs = {} as IDeliveredTaxRetunrs;
    let customColumn: string = "";
    let id: number = 0;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            document = action.taxDocument as IDeliveredTaxRetunrs;
            id = action.id;
            break;
        case actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE:
            customColumn = action.customColumn;
            id = action.id;
            break;
        case actionTypes.SEND_REMINDER_TAX_DOCUMENT:
            id = action.id;
            break;
    }
    if (state.deliveredReturnTableModel.documents) {
        state.deliveredReturnTableModel.documents.forEach((value, index) => {
            if (value.document.id === id) {
                i = index;
                oldDocument = value;
                return;
            }
        });
    }
    if (i !== -1) {
        let deliveredTaxDocument: IDeliveredTaxDocument = {
            document: action.type == actionTypes.RECEIVE_TAX_DOCUMENT ? document : oldDocument.document,
            customColumn: action.type == actionTypes.UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE ? customColumn : oldDocument.customColumn,
            downloadsCount: oldDocument.downloadsCount,
            signedCount: oldDocument.signedCount,
            lastReminderOn: (action.type == actionTypes.SEND_REMINDER_TAX_DOCUMENT) && !isInValidSignatureStatus(oldDocument.document.signatureStatus) && !action.isScheduled ? new Date() : oldDocument.lastReminderOn,
            retentionPeriod: oldDocument.retentionPeriod,
            lockType: oldDocument.lockType,
            clientGuid: oldDocument.clientGuid,
            isDocumentLocked: oldDocument.isDocumentLocked,
            bulkDownloadCount: oldDocument.bulkDownloadCount,
            recieverCount: oldDocument.recieverCount,
            reviewedCount: oldDocument.reviewedCount,
            returnStatus: oldDocument.returnStatus
        };

        let documents = [
            ...state.deliveredReturnTableModel.documents.slice(0, i),
            deliveredTaxDocument,
            ...state.deliveredReturnTableModel.documents.slice(i + 1)];
        let deliveredReturnTableModel: DeliveredReturnsTableModel = {
            count: state.deliveredReturnTableModel.count,
            documents: documents
        }

        return {
            query: state.query,
            deliveredReturnTableModel: deliveredReturnTableModel,
            totalRowCount: state.totalRowCount,
            loading: false
        } as DeliveredReturnsState;
    }
    return state;
}

function clearTaxReturns(state: DeliveredReturnsState): DeliveredReturnsState {
    return {
        ...unloadedState,
        query: state.query,
        isLoading: true
    } as DeliveredReturnsState;
}