import { connect } from 'react-redux';
import { ApplicationState } from "../../../store";
import { SendGroupedExtensions } from './SendGroupedExtensions';
import * as FilterStore from '../../../store/reports/FilterStore';
import * as UserStore from '../../../store/userManagement/UserStore';
import * as UserSettingStore from '../../../store/userManagement/UserSettingStore';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as SendGroupedReturnsPageStore from '../../../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsPagesStore';
import * as SendGroupedExtensionsStore from '../../../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsStore';
import * as SendGroupedExtensionsHeaderStore from '../../../store/groupedExtensions/SendGroupedExtensions/SendGroupedExtensionsHeaderStore';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import * as GroupedReturnStore from '../../../store/groupedExtensions/GroupedExtensionsStore';
import * as UserProfileStore from '../../../store/userManagement/UserProfileStore';
import * as TaxDocumentStore from '../../../store/common/TaxDocumentStore';
import * as GroupedReturnProcessStore from '../../../store/groupedExtensions/GroupedExtensionsProcessStore';
import * as GroupedTaxDocumentStore from '../../../store/groupedExtensions/GroupedTaxDocumentStore';
import * as AttachmentsStore from '../../../store/ProcessReturn/AttachmentsStore';
import * as UserSignatureStore from '../../../store/common/UserSignatureStore';
import * as UploadStore from '../../../store/common/Upload';
import * as VoucherStore from '../../../store/ProcessReturn/voucher/VoucherStore';
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import * as DeliveredReturnsStore from '../../../store/reports/DeliveredReturnsStore';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';
import * as DownloadStore from '../../../store/common/Download';
import * as BusinessReturnsStore from '../../../store/settings/BusinessReturnsStore';
import * as CustomAuthorityStore from '../../../store/common/CustomAuthorityStore';
import { ICustomAuthorityState } from '../../../store/common/CustomAuthorityStore';
import * as AdditionalEsignDocumentStore from '../../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as AdditionalDocumentTypeStore from '../../../store/common/AdditionalDocumentTypeStore';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as TaxSoftwareStore from '../../../store/SuiteCompany/TaxSoftwareStore';
import * as ExtensionUserSettingsStore from '../../../store/userManagement/ExtensionUserSettingStore';

export default connect(
    (state: ApplicationState) => ({
        sendGroupedReturns: state.sendGroupedExtensionsStore, // Selects which state properties are merged into the component's props
        usersStore: state.usersStore,
        reportFilters: state.reportFilters,
        userSettings: state.userSettings,
        company: state.companyData,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        pageTitle: "Grouped Extensions",
        groupedReturnStore: state.groupedExtensionsStore,
        groupedReturnProcessState: state.groupedExtensionsProcessStore,
        sendGroupedReturnsHeaderData: state.sendGroupedExtensionsHeaderStore,
        profile: state.userProfile,
        taxDocuments: state.taxDocuments,
        groupedTaxDocuments: state.groupedTaxDocumentStore,
        attachments: state.attachments,
        userSignatures: state.userSignatures,
        pdfDocuments: state.pdfDocuments,
        uploadStore: state.UploadStore,
        voucherStore: state.voucherStore,
        mailingReturnAddresses: state.mailingReturnAddresses,
        savedMessages: state.savedMessage,
        downloadStore: state.DownloadStore,
        businessReturnInstructions: state.businessReturnInstructions,
        customAuthorities: state.customAuthority,
        additionalEsignDocument: state.additionalEsignDocument,
        additionalDocumentTypeData: state.additionalDocumentType,
        taxSoftwareData: state.taxSoftwareData,
        extensionUserSettings: state.extensionUserSettings,
        auth:state.auth
    }), {
    ...SendGroupedExtensionsStore.actionCreators,
    ...UserStore.actionCreators,
    ...FilterStore.actionCreators,
    ...UserSettingStore.actionCreators,
    ...CompanyStore.actionCreators,
    ...UploadTaxReturnStore.actionCreators,
    ...GroupedReturnStore.actionCreators,
    ...GroupedReturnProcessStore.actionCreators,
    ...SendGroupedExtensionsHeaderStore.actionCreators,
    ...UserProfileStore.actionCreators,
    ...TaxDocumentStore.actionCreators,
    ...GroupedTaxDocumentStore.actionCreators,
    ...AttachmentsStore.actionCreators,
    ...UserSignatureStore.actionCreators,
    ...PdfStore.actionCreators,
    ...UploadStore.actionCreators,
    ...VoucherStore.actionCreators,
    ...MailingReturnAddressStore.actionCreators,
    ...DeliveredReturnsStore.actionCreators,
    ...SavedMessageStore.actionCreators,
    ...DownloadStore.actionCreators,
    ...BusinessReturnsStore.actionCreators,
    ...CustomAuthorityStore.actionCreators,
    ...AdditionalEsignDocumentStore.actionCreators,
    ...AdditionalDocumentTypeStore.actionCreators,
    ...TaxSoftwareStore.actionCreators,
    ...ExtensionUserSettingsStore.actionCreators
},
)(SendGroupedExtensions as any);